import { Point2D } from "../Audit/types";
import { ENDPOINTS } from "./constants";
import {
  getMapDataFromJson,
  getMapSitesList,
  deserializeSite,
  deserializeSites,
  serializeMap,
  serializeSiteData,
} from "./serializers";
import { MapInterface, SVGMapInterface, SitesDict, SingleSite } from "./types";
import { APIInstance } from "services/ContentServer/APIInstance";
import serialize from "utils/ObjectToFormData";
import { SiteState } from "views/Sites/SiteManagement/siteReducer";

export const MapSWRKeys = {
  MAPS: "maps",
  SITES: "sites",
};
export class MapService implements MapInterface {
  private apiInstance: APIInstance;

  constructor({ apiInstance }: { apiInstance: APIInstance }) {
    this.apiInstance = apiInstance;
  }

  async getMap(mapID: number): Promise<SVGMapInterface> {
    const resp = await this.apiInstance.get(`${ENDPOINTS.MAPS}${mapID}/`);
    return getMapDataFromJson(resp.data);
  }

  async getMaps(siteId?: string): Promise<SVGMapInterface[]> {
    const endpoint = siteId ? `${ENDPOINTS.MAPS}?site=${siteId}` : ENDPOINTS.MAPS;
    const resp = await this.apiInstance.get(endpoint);
    return resp.data;
  }

  async getMapSites(): Promise<Set<string>> {
    const url = this.apiInstance.addFieldsToGetRequest(`${ENDPOINTS.MAPS}`, "site_id");
    const resp = await this.apiInstance.get(url.toString());
    return getMapSitesList(resp.data);
  }

  async createSite(site: SiteState): Promise<SingleSite> {
    const data = serializeSiteData(site);
    const siteData = serialize(data);
    const resp = await this.apiInstance.post(ENDPOINTS.SITES, siteData);
    return deserializeSite(resp.data);
  }

  async updateSite(id: string, site: SiteState): Promise<SingleSite> {
    const data = serializeSiteData(site);
    const siteData = serialize(data);
    const resp = await this.apiInstance.patch(`${ENDPOINTS.SITES}${id}/`, siteData);
    return deserializeSite(resp.data);
  }

  async getSites(): Promise<SitesDict> {
    const resp = await this.apiInstance.get(ENDPOINTS.SITES);
    return deserializeSites(resp.data);
  }

  async createMap(
    minPoint: Point2D,
    maxPoint: Point2D,
    mapScale: number,
    mapFile: File | null,
    siteId: string
  ): Promise<any> {
    const serializedMap = serializeMap(minPoint, maxPoint, mapScale, mapFile, siteId);

    const map = serialize(serializedMap);

    const resp = await this.apiInstance.post(ENDPOINTS.MAPS, map, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return resp.data;
  }
}
