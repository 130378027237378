import { MsalProvider } from "@azure/msal-react";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import Config from "Config";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "redux/store";
import { SWRConfig } from "swr";

import App from "./components/App";
import { ProvideAuth } from "./hooks/useAuth";
import { ProvideWebRTC } from "./hooks/useWebRTC";
import { ProvideAzureInsights, reactPlugin } from "hooks/useAzureInsights";
import { ProvideChat } from "hooks/useChat";
import { ProvideServerStatus } from "hooks/useServerStatus";
import { ProvideCallState } from "hooks/useWebRTCSession";
import { msalInstance } from "services";
import { ProvideRequestContext } from "utils/Contexts/Requests/RequestContext";
import { ProvideWebGLContext } from "utils/Contexts/WebGLContext";
import { ProvideChatContext } from "views/ChatView/ChatContext";
import ErrorPage from "views/ErrorPage/ErrorPage";

const FlattenedProviderTree = (providers: any): React.ReactNode => {
  if (providers.length === 1) {
    return providers[0];
  }
  const Outer = providers.shift();
  const Inner = providers.shift();
  return FlattenedProviderTree([
    ({ children }: any) => (
      <Outer>
        <Inner>{children}</Inner>
      </Outer>
    ),
    ...providers,
  ]);
};

const start = async () => {
  const Providers: any = FlattenedProviderTree([
    ProvideServerStatus,
    ProvideAuth,
    ProvideRequestContext,
    ProvideChat,
    ProvideWebRTC,
    ProvideCallState,
    ProvideChatContext,
    ProvideWebGLContext,
    ProvideAzureInsights,
  ]);

  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <SWRConfig value={Config.SWR_CONFIG}>
        <ReduxProvider store={store}>
          <Providers>
            <AppInsightsErrorBoundary onError={() => <ErrorPage></ErrorPage>} appInsights={reactPlugin}>
              <App />
            </AppInsightsErrorBoundary>
          </Providers>
        </ReduxProvider>
      </SWRConfig>
    </MsalProvider>,
    document.getElementById("root")
  );
};

start();
