import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

export const ExpandDropdown = ({
  expanded,
  setExpanded,
  style,
}: {
  expanded: boolean;
  setExpanded: (value: boolean | ((prev: boolean) => boolean)) => void;
  style: any;
}) => {
  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        setExpanded((prev) => !prev);
      }}
      style={{ ...style, padding: 0 }}
      disableRipple
      size="large">
      {expanded ? <KeyboardArrowUp style={style} /> : <KeyboardArrowDown style={style} />}
    </IconButton>
  );
};
