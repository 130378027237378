import { ENDPOINTS } from "./constants";
import { TenantFeatures } from "./TenantFeatures";
import { APIInstance } from "services/ContentServer/APIInstance";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export class TenantFeaturesService extends ServiceBase<TenantFeatures> {
  constructor(apiInstance: APIInstance) {
    super(TenantFeatures, apiInstance, ENDPOINTS.FEATURE_FLAGS);
  }
}
