import { Tooltip, Typography, TypographyProps } from "@mui/material";
import React, { useEffect, useRef, useState, ReactChild, useCallback } from "react";

import { useEventListener } from "hooks/useEventListener";

interface EllipsisTooltipProps extends TypographyProps {
  children: ReactChild;
}

/********************************
 Component - EllipsisTooltip

 Desc - enable Ellipsis and tooltip when content overflows
 ********************************/

export const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({ children, ...props }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const compareSize = useCallback(() => {
    if (ref.current) {
      setTooltipEnabled(ref.current.scrollWidth > ref.current.clientWidth);
    }
  }, []);

  useEventListener("resize", compareSize);

  useEffect(() => {
    compareSize();
  }, [compareSize]);

  return (
    <Tooltip title={children} disableHoverListener={!tooltipEnabled}>
      <Typography ref={ref} noWrap overflow="hidden" textOverflow="ellipsis" {...props}>
        {children}
      </Typography>
    </Tooltip>
  );
};
