import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { Autocomplete, Box, Typography } from "@mui/material";
import React, { ChangeEvent, useMemo } from "react";

import { CustomPaper } from "components/DalmatianDesignComponents/DropdownFilter";
import { SearchOption } from "components/DalmatianDesignComponents/SearchBar";
import { matchSorter } from "utils/SortRowsUtils";
import { SiteAction, SiteActionType, SiteState } from "views/Sites/SiteManagement/siteReducer";

const SiteReducerFilter = ({
  options,
  siteState,
  stateKey,
  dispatchSiteState,
  action,
  defaultValue,
  placeholderText,
}: {
  options: any[];
  siteState: SiteState;
  stateKey: "country" | "region" | "keyContact";
  action: SiteActionType;
  dispatchSiteState: React.Dispatch<SiteAction>;
  defaultValue?: SearchOption;
  placeholderText?: string;
}) => {
  const currValue = useMemo(() => {
    const val = siteState[stateKey];
    return {
      data: val.data || "",
      displayVal: val.displayVal || "",
    };
  }, [siteState, stateKey]);

  const filterOptions = (options: any[], { inputValue }: { inputValue: any }) => {
    if (!inputValue) {
      return options;
    }
    return matchSorter(options, inputValue, { keys: ["displayVal"] });
  };

  return (
    <Autocomplete
      id="search-bar"
      size="small"
      disableClearable={true}
      filterOptions={filterOptions}
      defaultValue={defaultValue ? defaultValue : undefined}
      value={currValue}
      limitTags={2}
      popupIcon={<KeyboardArrowDownOutlined />}
      sx={{
        width: "100%",
        ".MuiAutocomplete-inputRoot": {
          height: "36px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "140%",
          color: "#64676A",
        },
      }}
      PaperComponent={CustomPaper}
      options={options}
      getOptionLabel={(option: any) => option.displayVal}
      isOptionEqualToValue={(option, value) => {
        return option && value ? option.data === value.data : false;
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.index}>
          <Typography variant="body1">{option.displayVal}</Typography>
        </li>
      )}
      onChange={(_: ChangeEvent<any>, value: any | null) => {
        const updateObj = { type: action } as SiteAction;
        const parsedVal = { data: value.data, displayVal: value.displayVal };
        updateObj[stateKey] = parsedVal;
        dispatchSiteState(updateObj);
      }}
      renderInput={(params) => (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <TextField
            {...params}
            variant="outlined"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "140%",
              color: "rgba(100, 103, 106, 0.6)",
              height: "36px",
              ".MuiOutlinedInput-root": {
                paddingTop: 0,
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: false,
            }}
            placeholder={!siteState || placeholderText ? placeholderText : ""}
          />
        </Box>
      )}
      renderTags={(selectedItems: SearchOption[]) => {
        return selectedItems.length
          ? selectedItems
              .filter((item) => item !== undefined)
              .map((item: any) => {
                return (
                  <Typography key={item.id} variant="body1">
                    {selectedItems.length > 1 ? item.displayVal + ", " : item.displayVal}
                  </Typography>
                );
              })
          : [];
      }}
    />
  );
};

export default SiteReducerFilter;
