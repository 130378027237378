import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

/* eslint-disable @typescript-eslint/naming-convention */
export const serverToView: { [key: string]: keyof ImportStatus | undefined } = {
  id: "id",
  status: "status",
  created_at: "createdAt",
  created_by: "createdBy",
};
/* eslint-enable @typescript-eslint/naming-convention */

export class ImportStatus extends ISerialization {
  public id?: string | null;
  public status?: string | null;
  public createdAt?: string | null;
  public createdBy?: string | null;

  constructor(importObj?: ImportStatus) {
    super();
    if (importObj) {
      Object.assign(this, importObj);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<ImportStatus>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<ImportStatus>(this, serverToView);
    return request;
  }
}
