import Badge, { BadgeProps as MuiBadgeProps } from "@mui/material/Badge";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { Status } from "services/MessageHub";

interface Props extends MuiBadgeProps {
  status: Status;
}

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1200,
    margin: "5px",
  },
  badge: {
    backgroundColor: (props: Props) =>
      props.status === Status.Active ? theme.palette.success.main : theme.palette.warning.main,
  },
}));

export default function StatusBadge(props: Props) {
  const { children, ...other } = props;
  const classes = useStyles(props);
  return (
    <Badge classes={classes} {...other}>
      {children}
    </Badge>
  );
}
