import { createTheme } from "@mui/material/styles";

import ARoverrides from "./ARoverrides";
import ARoverridesMobile from "./ARoverridesMobile";
import ARpalette from "./ARpalette";
import ARtypography from "./ARtypography";
import overridesV5 from "./overridesV5";
import palette from "./palette";
import typography from "./typographyV5";

export const themeV5 = createTheme({
  palette,
  typography,
  zIndex: {
    topNavBar: 1300,
    appBar: 1200,
    drawer: 1200,
    modal: 1504,
    tooltip: 1504,
  },
  direction: "ltr",
  components: { ...overridesV5 },
  spacing: 8,
});

export const ARtheme = createTheme({
  palette: ARpalette,
  typography: ARtypography,
  zIndex: {
    topNavBar: 1300,
    appBar: 1200,
    drawer: 1200,
    modal: 1504,
    tooltip: 1504,
  },
  direction: "ltr",
  components: { ...ARoverrides },
  spacing: 8,
});

export const ARmobileTheme = createTheme({
  palette: ARpalette,
  typography: ARtypography,
  zIndex: {
    topNavBar: 1300,
    appBar: 1200,
    drawer: 1200,
    modal: 1504,
    tooltip: 1504,
  },
  direction: "ltr",
  components: { ...ARoverrides, ...ARoverridesMobile },
  spacing: 8,
});

export default themeV5;
