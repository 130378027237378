import { AllFeatures } from "./AllFeatures";
import { ENDPOINTS } from "./constants";
import { APIInstance } from "services/ContentServer/APIInstance";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export class AllFeaturesService extends ServiceBase<AllFeatures> {
  constructor(apiInstance: APIInstance) {
    super(AllFeatures, apiInstance, ENDPOINTS.FEATURES);
  }
}
