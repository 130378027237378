import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./AzureAD/authConfig";
import { HealthCheck } from "./HealthCheck/HealthCheck";
import { ServerVersion } from "./ServerVersion/ServerVersion";

const msalInstance = new PublicClientApplication(msalConfig);

const healthCheck = new HealthCheck();
const serverVersion = new ServerVersion();

export { healthCheck, serverVersion, msalInstance };
