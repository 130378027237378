import JSZip from "jszip";

export const POINT_CLOUD_FILE_EXTENSIONS = ["ply"];
export const SPATIAL_FILE_EXTENSIONS = ["arexperience"];
export const THREE_D_MODEL_FILE_EXTENSIONS = POINT_CLOUD_FILE_EXTENSIONS.concat([
  "gltf",
  "glb",
  "fbx",
  "FBX",
  "obj",
  "dae",
]);
export const AR_TARGET_FILE_EXTENSIONS = ["arobject"];
export const DRAWING_FILE_EXTENSIONS = ["aptixdrawing"];
// TODO: remove
export const ARROW_FILE_EXTENSION = ["gltf"];
export const ZIP_FILE_EXTENSION = ["zip"];
export const IMAGE_FILE_EXTENSIONS = [
  "jpg",
  "jpeg",
  "gif",
  "GIF",
  "jpe",
  "jif",
  "jfif",
  "jfi",
  "JPG",
  "JPEG",
  "png",
  "PNG",
];
export const VIDEO_FILE_EXTENSIONS = ["mp4", "MP4", "mpeg", "MPEG"];
export const PDF_FILE_EXTENSIONS = ["pdf", "PDF"];
export const SPLAT_FILE_EXTENSIONS = ["splat"];

export const getExtension = (name: string) => {
  try {
    const url = new URL(name);
    const path = url.pathname;
    return path.split(".").pop() || "";
  } catch (error) {
    return name.split(".").pop() || "";
  }
};

export const getFileName = (file: File | string) => {
  let name = "";
  if (typeof file === "string") {
    name = file;
  } else if (file instanceof File) {
    name = file.name;
  }
  return name;
};

export const getFileNameFromPath = (path: File | Blob | string) => {
  if (!(path instanceof Blob)) {
    const filePath = getFileName(path);
    return filePath.split("\\").pop()?.split("/").pop() ?? "";
  }
  return "";
};

export const isFileType = (file: File | string, extList: string[]) => {
  const name = getFileName(file);
  const ext = getExtension(name);

  if (!name.length || !ext.length) {
    return false;
  }

  return extList.includes(ext);
};

export const addToFileName = (file: File | string, suffix: string) => {
  const name = getFileName(file);
  const ext = getExtension(name);

  return `${name.slice(0, name.lastIndexOf(ext))}${suffix}${ext}`;
};

export const isPointCloudFile = (file: File | string) => {
  return isFileType(file, POINT_CLOUD_FILE_EXTENSIONS);
};

export const is3DObjectFile = (file: File | string) => {
  return isFileType(file, THREE_D_MODEL_FILE_EXTENSIONS);
};

export const isARObjectFile = (file: File | string) => {
  return isFileType(file, AR_TARGET_FILE_EXTENSIONS);
};

export const isSpatialFile = (file: File | string) => {
  return isFileType(file, SPATIAL_FILE_EXTENSIONS);
};

export const isDrawingFile = (file: File | string) => {
  return isFileType(file, DRAWING_FILE_EXTENSIONS);
};

// TODO: remove
export const isArrowFile = (file: File | string) => {
  return isFileType(file, ARROW_FILE_EXTENSION);
};

export const isZipFile = (file: File | string) => {
  return isFileType(file, ZIP_FILE_EXTENSION);
};

export const isImageFile = (file: File | string) => {
  return isFileType(file, IMAGE_FILE_EXTENSIONS);
};

export const isVideoFile = (file: File | string) => {
  return isFileType(file, VIDEO_FILE_EXTENSIONS);
};

export const isPDFFile = (file: File | string) => {
  return isFileType(file, PDF_FILE_EXTENSIONS);
};

export const isSplatFile = (file: File | string) => {
  return isFileType(file, SPLAT_FILE_EXTENSIONS);
};

export const readFileAndPreview = (file: File | Blob, setImageData: (data: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();

  reader.addEventListener(
    "load",
    function () {
      setImageData(this.result);
    },
    false
  );

  reader.readAsDataURL(file);
};

export const readFileAsText = async (file: File) => {
  return await new Response(file).text();
};

export const getFileObjectFromURLAsync = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName);
};

export const dataURItoBlob = (dataURI: string, fileName: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: string;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = unescape(dataURI.split(",")[1]);
  }

  // write the bytes of the string to a typed array
  const blobParts = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    blobParts[i] = byteString.charCodeAt(i);
  }

  return new File([blobParts], fileName, { type: "image/jpeg" });
};

export function dictionaryToJsonFile<T>(obj: T, type: any, name: string) {
  const keyPairs = Object.entries(type);
  const output: { [key: string]: any } = {};

  keyPairs.forEach((value) => {
    output[value[1] as string] = obj[value[0] as keyof T];
  });
  const blob = new Blob([JSON.stringify(output)], { type: "application/json" });

  return new File([blob], name);
}

export async function zipFiles(files: File[], fileName?: string) {
  const newZipFile: JSZip = new JSZip();
  files.forEach((file) => {
    newZipFile.file(file.name, file);
  });

  const blob = await newZipFile.generateAsync({ type: "blob" });
  return new File([new Blob([blob])], fileName ? fileName : "annotation.zip");
}

export function verifyModelFileCount(fileNames: string[], exts: string[], fileLimit = 1) {
  const filteredList = fileNames.filter((file) => {
    const splitFileName = file.split(".");
    return exts && exts.includes("." + splitFileName[splitFileName.length - 1]);
  });
  return filteredList.length === fileLimit;
}

export function getErrorMessage(status: number) {
  return `Request failed with status code ${status}`;
}
