import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof LODFile | undefined } = {
  id: "id",
  file_path: "path",
  default_file: "file",
  lod_level: "lodLevel",
  file_segment_id: "fileSegmentId",
  textured_file: "texturedFile",
};

export interface ILODFile {
  id?: string | null;
  path?: string | null;
  file?: string | Blob | File | null;
  lodLevel?: number | null;
  fileSegmentId?: string | null;
  meshFile?: string | Blob | File | null;
  hasTextured?: boolean | null;
  texturedFile?: string | Blob | File | null;
}

export class LODFile extends ISerialization {
  public id?: string | null;
  public path?: string | null;
  public file?: string | Blob | File | null;
  public lodLevel?: number | null;
  public fileSegmentId?: string | null;
  public meshFile?: string | Blob | File | null;
  public hasTextured?: boolean | null;
  public texturedFile?: string | Blob | File | null;

  constructor(lodFile?: LODFile) {
    super();
    if (lodFile) {
      Object.assign(this, lodFile);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<LODFile>(this, serverToView, json);
    this.hasTextured = json.textured_file ? true : false;
    this.meshFile = json.default_file || undefined;
    this.file = json.textured_file || json.default_file || undefined;
  }

  convertToJSON(): ILODFile {
    return {
      id: this.id,
      path: this.path,
      file: this.file,
      lodLevel: this.lodLevel,
      fileSegmentId: this.fileSegmentId,
      meshFile: this.meshFile,
      hasTextured: this.hasTextured,
      texturedFile: this.texturedFile,
    };
  }

  serialize(): any {
    const request = serializerHelper<LODFile>(this, serverToView);
    return request;
  }
}
