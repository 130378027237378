import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "./customFetchBase";
import { SORTBY, SORTORDER, STATE } from "./folderApi";
import { IAsset } from "services/ContentServer/Audit/serviceTypes/Asset";
import { FileSegment, IFileSegment } from "services/ContentServer/Audit/serviceTypes/FileSegment";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";

export interface ISnapshot {
  id: string;
  assetId: string;
  assetInfoId: string;
  name: string;
  description: string;
  preview: string;
  createdAt: string;
  lastModifiedAt: string;
  segments?: IFileSegment[];
  asset?: IAsset;
}

const deserializeSnaphot = (obj: any): ISnapshot => {
  const objMap: ISnapshot = {
    id: obj.id,
    assetId: obj["asset_id"],
    assetInfoId: obj["asset_info_id"],
    name: obj["name"],
    description: obj.description,
    preview: obj["preview"],
    createdAt: obj["created_at"],
    lastModifiedAt: obj["last_modified_at"],
  };

  if (obj.segments) {
    objMap.segments = [];
    obj.segments.forEach((segment: any) => {
      const newSegment = new FileSegment();
      newSegment.deserialize(segment);
      objMap.segments?.push(newSegment.convertToJSON());
    });
  }

  return objMap;
};

const serializeSnapshot = (item: Partial<ISnapshot>) => {
  const formData = new FormData();

  if (item.description) formData.append("description", item.description);

  return formData;
};

const fields = "id,asset_id,asset_info_id,name,description,preview,created_at,last_modified_at";
const fieldsWithModelData = fields + ",segments";

export type Param = {
  assetId: string;
  sortBy?: SORTBY;
  sortOrder?: SORTORDER;
  state?: STATE;
  getModelData?: boolean;
};

const buildSortBy = (param: Param) => {
  let query = "";
  let sortOrder = param.sortOrder;
  let sortBy = param.sortBy?.toString().toLocaleLowerCase();
  if (param.sortBy) {
    if (param.sortBy == SORTBY.ACTIVITY || param.sortBy == SORTBY.CREATED) {
      sortOrder = SORTORDER.DESCENDING;
    }
    if (param.sortBy == SORTBY.NAME) {
      sortBy = "description";
    }
    query = `&order_by=${(sortOrder === SORTORDER.ASCENDING ? "" : "-") + sortBy}&state=${param.state}`;
  }

  return query;
};

export const snapshotApi = createApi({
  reducerPath: "snapshotApi",
  baseQuery: customFetchBase,
  tagTypes: ["Snapshots"],
  endpoints: (builder) => ({
    getSnapshots: builder.query<ISnapshot[], Param>({
      query(param: Param) {
        return {
          url: `snapshots/?fields=${encodeURI(
            param.getModelData ? fieldsWithModelData : fields
          )}&is_template=false&asset=${param.assetId}${buildSortBy(param)}`,
        };
      },
      transformResponse: (response: any) => {
        return response.map((item: any) => deserializeSnaphot(item));
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Snapshots", id } as const)), { type: "Snapshots", id: "LIST" }]
          : [{ type: "Snapshots", id: "LIST" }],
    }),
    updateSnapshot: builder.mutation<any, Partial<ISnapshot>>({
      query({ id, ...patch }) {
        return {
          url: `snapshots/${id}/`,
          method: "PATCH",
          body: serializeSnapshot(patch),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Snapshots", id },
        { type: "Snapshots", id: "LIST" },
      ],
    }),
    getFacilitySnapshots: builder.query<Snapshot[], string>({
      query(id: string) {
        return {
          url: `facilities_snapshots/${id}`,
        };
      },
      transformResponse: (response: any) => {
        return response.map((item: any) => {
          const newSnapshot = new Snapshot();
          newSnapshot.deserialize(item);
          return newSnapshot;
        });
      },
    }),
  }),
});

export const { useLazyGetSnapshotsQuery, useUpdateSnapshotMutation, useLazyGetFacilitySnapshotsQuery } = snapshotApi;
