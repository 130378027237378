import { IVideoCallHub } from "../types";
import { ICommServer, MESSAGE_TYPE } from "services/CommServer";

export class CommServerVideoCallHub implements IVideoCallHub {
  private commServer?: ICommServer;

  constructor({ commServer }: { commServer?: ICommServer }) {
    this.commServer = commServer;
  }

  subscribeToVideoCallMessage(callback: (value: Record<string | number | symbol, unknown>) => void): () => void {
    if (!this.commServer) {
      return () => {};
    }

    const wrapper = (message: string) => {
      if (message && message.length > 0) {
        callback(JSON.parse(message));
      }
    };
    return this.commServer.subscribe(MESSAGE_TYPE.VIDEO_CALL, wrapper);
  }

  send(message: Record<string | number | symbol, unknown>): Promise<void> {
    if (!this.commServer) {
      return new Promise<void>(() => {});
    }

    return this.commServer.send(MESSAGE_TYPE.VIDEO_CALL, message);
  }
}
