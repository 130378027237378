import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { AlignSnapshots } from "../serviceTypes/AlignSnapshots";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const AlignSnapshotsSWRKeys = {
  AlignSnapshots: "AlignSnapshots",
};
export class AlignSnapshotsService extends ServiceBase<AlignSnapshots> {
  constructor(apiInstance: APIInstance) {
    super(AlignSnapshots, apiInstance, ENDPOINTS.ALIGN_SNAPSHOTS);
  }
}
