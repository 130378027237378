import React from "react";
import { useGetTenantQuery } from "redux/api/tenantApi";

import { useAuth } from "hooks/useAuth";

const ARLogo = () => {
  const auth = useAuth();
  const { data: tenantInfo } = useGetTenantQuery(auth.user?.tenantId ?? "");

  return tenantInfo && tenantInfo.logo !== "" ? (
    <img
      style={{ minHeight: "17px", maxHeight: "24px", maxWidth: "90%", margin: "auto" }}
      src={tenantInfo?.logo}
      alt="logo"
    />
  ) : (
    <></>
  );
};

export default ARLogo;
