import { Icon, IconProps } from "@mui/material";
import React from "react";

import icon from "assets/icons/CopyPage.svg";

export const CopyPageIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <img src={icon} />
    </Icon>
  );
};

export default CopyPageIcon;
