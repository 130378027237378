export default {
  MuiIconButton: {
    styleOverrides: {
      root: {
        justifyContent: "center",
        verticalAlign: "middle",
        borderRadius: 2,
        margin: 0,
        padding: "8px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        padding: "8px 14px 8px 10px",
        margin: "0",
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        verticalAlign: "middle",
        margin: 0,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        "&[data-popper-reference-hidden]": {
          visibility: "hidden",
          pointerEvents: "none",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "#B3B4B6",
        borderRadius: 0,
      },
      input: {
        paddingLeft: "8px",
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        scrollbarColor: "#6F6F6F #FFFFFF",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: "#F4F6F8",
          maxWidth: 8,
          maxHeight: 8,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: "#6F6F6F",
          maxHeight: 8,
          maxWidth: 8,
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
          backgroundColor: "#6F6F6F",
          maxWidth: 8,
          maxHeight: 8,
        },
        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
          background: "#FFFFFF",
        },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
          backgroundColor: "#6F6F6F",
          maxHeight: 8,
          maxWidth: 8,
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#6F6F6F",
          maxHeight: 8,

          maxWidth: 8,
        },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: "#6F6F6F",
          maxHeight: 8,
          maxWidth: 8,
        },
      },
    },
  },
};
