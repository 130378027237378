import React, { useState, useEffect, useContext, createContext } from "react";

import { useAuth } from "./useAuth";
import { Chat } from "services/Chat/Chat";
import { IChat } from "services/Chat/types";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

interface IContextProps {
  chat: IChat | undefined;
}

const chatContext = createContext({
  chat: undefined,
} as IContextProps);

export const ProvideChat = ({ children }: { children: React.ReactNode }) => {
  const chat = useProvideChat();
  return <chatContext.Provider value={chat}>{children}</chatContext.Provider>;
};

export const useChat = () => {
  return useContext(chatContext);
};

const useProvideChat = () => {
  const auth = useAuth();
  const [chat, setChat] = useState<IChat | undefined>();
  const { chatHub } = useContext(RequestContext);

  useEffect(() => {
    if (auth.user) {
      const chat = new Chat(chatHub, auth.user.id);
      chat.start();

      setChat(chat);
      return () => {
        chat.stop();
        setChat(undefined);
      };
    }
  }, [auth.user, chatHub]);

  return { chat };
};

export default useChat;
