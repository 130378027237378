import { TexturingImageCapture } from "../serviceTypes/TexturingImageCapture";
import { APIInstance } from "services/ContentServer/APIInstance";
import { ENDPOINTS } from "services/ContentServer/Audit/constants";
import { ISerializationPaginated } from "services/ContentServer/ISerialization";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export class FullTexturingImagePaginated extends ISerializationPaginated<TexturingImageCapture> {}
export class FullTexturingImageService extends ServiceBase<TexturingImageCapture> {
  constructor(apiInstance: APIInstance) {
    super(TexturingImageCapture, apiInstance, ENDPOINTS.TEXTURING_IMAGES);
  }
}
