import { styled } from "@mui/material";
import MenuMui from "@mui/material/Menu";

const Menu = styled(MenuMui)(({ theme }) => ({
  "&.MuiPaper-root": {
    boxShadow: "0px 4px 12px 0px #3537391A",
  },
}));

export default Menu;
