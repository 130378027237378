import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof UserInvite | undefined } = {
  id: "id",
  username: "username",
  created_at: "createdAt",
  sites: "sites",
  roles: "roles",
  teams: "teams",
};

export class UserInvite extends ISerialization {
  public id?: string | null;
  public username?: string | null;
  public createdAt?: string | null;
  public sites?: string[] | null;
  public roles?: string[] | null;
  public teams?: string[] | null;

  constructor(userInvite?: UserInvite) {
    super();
    if (userInvite) {
      Object.assign(this, userInvite);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<UserInvite>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<UserInvite>(this, serverToView);
    return request;
  }
}
