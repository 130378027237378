import React, { useContext } from "react";
import { useMemo } from "react";

import { defaultCamera, DeviceCameraInfo, useDeviceCamera } from "hooks/useDeviceCamera";
import { MediaCaptureContext } from "views/inspection/InspectionPage/MediaCaptureContext";

export interface DeviceContextType {
  deviceInfo: DeviceCameraInfo;
}

export const defaultContext: DeviceContextType = {
  deviceInfo: defaultCamera,
};

export const ProvideDeviceContext = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const { selectedImageObject } = useContext(MediaCaptureContext);
  const getCameraInstrinsics = useDeviceCamera(selectedImageObject?.device ? [selectedImageObject.device] : undefined);

  const deviceInfo = useMemo(() => {
    return selectedImageObject?.rotate == undefined ? defaultCamera : getCameraInstrinsics(selectedImageObject.rotate);
  }, [selectedImageObject, getCameraInstrinsics]);

  const contextValues: DeviceContextType = useMemo(
    () => ({
      deviceInfo: deviceInfo,
    }),
    [deviceInfo]
  );

  return <DeviceContext.Provider value={contextValues}>{children}</DeviceContext.Provider>;
};

export const DeviceContext = React.createContext(defaultContext);
