import { gql } from "graphql-request";
import { v4 as uuidv4 } from "uuid";

import { gqlApi } from "./gqlApi";
import { QueryRequestParams, buildGqlQueryRequest, deserializePageData, PageData } from "./gqlUtils";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";

export enum CUSTOM_FIELD_TYPE {
  SNAPSHOT = "SNAPSHOT",
  CAPTURE = "CAPTURE",
  READ_ONLY_CAPTURE = "READ_ONLY_CAPTURE",
}

export enum CUSTOM_FIELD_STATUS {
  NEW = "NEW",
  DELETED = "DELETED",
  PENDING = "PENDING",
  NORMAL = "NORMAL",
}

export enum CUSTOM_FIELD_OPTION_TYPE {
  TEXT = "TEXT",
  CHOICE = "CHOICE",
}

export interface ICustomField {
  id: string;
  title: string;
  lastModifiedAt: string;
}

export interface IBatchCustomField {
  addFields?: Partial<ICustomField>[];
  updateFields?: Partial<ICustomField>[];
  removeFields?: string[];
}

export interface UdfParams {
  id?: string;
  title?: string;
  typeOf?: CUSTOM_FIELD_OPTION_TYPE;
  udfGroupId?: string;
}

export interface CaptureUdf {
  id: string;
  title: string;
  typeOf: CUSTOM_FIELD_OPTION_TYPE;
  captureUdfInstance?: {
    id: string;
    value: string;
  };
  captureUdfOptions: {
    id: string;
    value: string;
  }[];
  udfGroup?: {
    id: string;
    name: string;
    colour: string;
  };
  state?: CUSTOM_FIELD_STATUS;
  createdAt?: string;
}

export interface SnapshotUdf {
  snapshotUdfInstance: {
    value: string;
    id: string;
  };
  snapshotUdfOptions: {
    id: string;
    value: string;
  }[];
  title: string;
  id: string;
  typeOf: CUSTOM_FIELD_OPTION_TYPE;
  udfGroup?: {
    id: string;
    name: string;
    colour: string;
  };
  state?: CUSTOM_FIELD_STATUS;
  createdAt?: string;
}

export interface CreateOptionParams {
  udfId: string;
  value: string;
}

export interface ApplyTemplateToSnapshotParams {
  groupId: string;
  snapshotId: string;
}

export interface ReportTemplateParams {
  id?: string;
  name?: string;
  colour?: string;
  description?: string;
}

export interface ReportsResponse {
  id?: string;
  name?: string;
  colour?: string;
  description?: string;
  captureUdf?: CaptureUdf[];
  snapshotUdf?: SnapshotUdf[];
  snapshots?: Snapshot[];
}

export interface ReportsPageData {
  data: ReportsResponse[];
  pageData: PageData;
}

const formatString = (str: string | undefined) => {
  return str ? str.split("\n").join("\\n") : "";
};

const buildUdfUdfGroupAndUdfQuery = (param: ReportsResponse) => {
  let query = gql`mutation {
    createUdfGroup(
      data: {id: "${param.id}", name: "${param.name}", description: "${param.description}", colour: "${param.colour}"}
    ) {
      ... on UdfGroup {
        name
        description
        id
      }
      ... on OperationInfo {
        __typename
        messages {
          kind
          message
          field
        }
      }
    }`;
  if (param.captureUdf) {
    param.captureUdf.forEach((captureUdf, capIdx) => {
      const captureUdfId = uuidv4();
      query += `cap${capIdx}: createCaptureUdf(
          data: {title: "${captureUdf.title}", typeOf: "${captureUdf.typeOf}", udfGroup: "${param.id}", id: "${captureUdfId}"}
        ) {
          ... on CaptureUdf {
            id
            title
            typeOf
          }
          ... on OperationInfo {
            messages {
              kind
              message
              field
            }
          }
        }`;
      if (captureUdf.captureUdfOptions) {
        captureUdf.captureUdfOptions.forEach((option, optIdx) => {
          query += `cap${capIdx}opt${optIdx}: createCaptureUdfOption(
              data: {captureField: "${captureUdfId}", value: "${option.value}"}
            ) {
              ... on CaptureUdfOption {
                id
                value
                captureField {
                  id
                }
              }
              ... on OperationInfo {
                messages {
                  kind
                  message
                  field
                }
              }
            }`;
        });
      }
    });
  }
  if (param.snapshotUdf) {
    param.snapshotUdf.forEach((snapshotUdf, snapIdx) => {
      const snapshotUdfId = uuidv4();
      query += `snap${snapIdx}: createSnapshotUdf(
        data: {title: "${snapshotUdf.title}", typeOf: "${snapshotUdf.typeOf}", udfGroup: "${param.id}", id: "${snapshotUdfId}"}
      ) {
        ... on SnapshotUdf {
          id
          title
          typeOf
        }
        ... on OperationInfo {
          messages {
            kind
            message
            field
          }
        }
      }`;
      if (snapshotUdf.snapshotUdfOptions) {
        snapshotUdf.snapshotUdfOptions.forEach((option, optIdx) => {
          query += `snap${snapIdx}opt${optIdx}: createSnapshotUdfOption(
            data: {snapshotField: "${snapshotUdfId}", value: "${option.value}"}
          ) {
            ... on SnapshotUdfOption {
              id
              value
              snapshotField {
                id
              }
            }
            ... on OperationInfo {
              messages {
                kind
                message
                field
              }
            }
          }`;
        });
      }
    });
  }
  return query + "}";
};

const deserializeTemplates = (template: any) => {
  return {
    ...template,
  } as ReportsResponse;
};
const deserializeCaptureUdf = (captureUdf: any) => {
  return {
    ...captureUdf,
  } as CaptureUdf;
};
const deserializeSnapshotUdf = (snapshotUdf: any) => {
  return {
    ...snapshotUdf,
  } as SnapshotUdf;
};

export const defaultCustomFieldsQueryData = `
  id
  name
  colour
  description
  captureUdf {
    id
    title
    typeOf
    captureUdfInstance {
      id
      value
    }
    captureUdfOptions {
      id
      value
    }
  }
  snapshotUdf {
    snapshotUdfInstance {
      value
      id
    }
    snapshotUdfOptions{
      value
      id
    }
    title
    id
    typeOf
  }
`;

export const minimalCustomFieldsQueryData = `
  id
  name
  colour
  snapshots {
    id
  }
`;

export const customFieldTemplateApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomFieldTemplates: builder.query<ReportsPageData, QueryRequestParams>({
      query: (param: QueryRequestParams) => ({
        document: gql`
          ${buildGqlQueryRequest("udfGroup", param.queryData, param.page, param.sort, param.filters)}
        `,
      }),
      transformResponse: async (response: any) => {
        const templates = response.udfGroup.map((template: any) => {
          return deserializeTemplates(template);
        });

        const pageData = deserializePageData(response.pageData.udfGroup);
        return { data: templates, pageData: pageData };
      },
    }),
    addCustomFieldTemplate: builder.query<ReportsResponse, ReportTemplateParams>({
      query: (param: ReportTemplateParams) => ({
        document: gql`
          mutation {
            createUdfGroup(data: {name: "${formatString(param.name)}", colour: "${
          param.colour
        }", description: "${formatString(param.description)}"}) {
              ... on UdfGroup {
                id
                name
                colour
                description
                snapshotUdf {
                  id
                  title
                }
                captureUdf {
                  title
                  id
                }
              }
              ... on OperationInfo {
                messages {
                  message
                  kind
                  field
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeTemplates(response.createUdfGroup);
      },
    }),
    updateCustomFieldTemplate: builder.query<ReportsResponse, ReportTemplateParams>({
      query: (param: ReportTemplateParams) => ({
        document: gql`
          mutation {
            updateUdfGroup(data: {id: "${param.id}", description: "${formatString(
          param.description
        )}", name: "${formatString(param.name)}", colour: "${param.colour}"}) {
              ... on UdfGroup {
                id
                name
                description
                colour
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeTemplates(response.updateUdfGroup);
      },
    }),
    getCustomFieldTemplate: builder.query<ReportsResponse, string>({
      query: (id: string) => ({
        document: gql`
        query {
          udfGroup(filters: {id: {exact: "${id}"}}) {
            id
            name
            colour
            description
            captureUdf {
              id
              title
              typeOf
              captureUdfInstance {
                id
                value
              }
              captureUdfOptions {
                id
                value
              }
              createdAt
            }
            snapshotUdf {
              snapshotUdfInstance {
                value
                id
              }
              snapshotUdfOptions{
                value
                id
              }
              title
              id
              typeOf
              createdAt
            }
          }
        }
      `,
      }),
      transformResponse: async (response: any) => {
        const templates = response.udfGroup.map((template: ReportsResponse[]) => {
          return deserializeTemplates(template);
        });

        return templates[0];
      },
    }),
    deleteCustomFieldTemplate: builder.query<string, string>({
      query: (id: string) => ({
        document: gql`
          mutation {
            deleteUdfGroup(data: { id: "${id}" }) {
              ... on UdfGroup {
                id
                colour
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "Custom Field deleted";
      },
    }),
    addCaptureUdf: builder.query<CaptureUdf, UdfParams>({
      query: (param: UdfParams) => ({
        document: gql`
          mutation {
            createCaptureUdf(data: { title: "${formatString(param.title)}", typeOf: "${param.typeOf}", udfGroup: "${
          param.udfGroupId
        }"}) {
              ... on CaptureUdf {
                id
                title
              }
              ... on OperationInfo {
                messages {
                  message
                  field
                  kind
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeCaptureUdf(response.createCaptureUdf);
      },
    }),
    updateCaptureUdf: builder.query<CaptureUdf, UdfParams>({
      query: (param: UdfParams) => ({
        document: gql`
          mutation {
            updateCaptureUdf(data: { title: "${formatString(param.title)}", typeOf: "${param.typeOf}", id: "${
          param.id
        }"}) {
              ... on CaptureUdf {
                id
                title
              }
              ... on OperationInfo {
                messages {
                  message
                  field
                  kind
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeCaptureUdf(response.updateCaptureUdf);
      },
    }),
    addSnapshotUdf: builder.query<SnapshotUdf, UdfParams>({
      query: (param: UdfParams) => ({
        document: gql`
          mutation {
            createSnapshotUdf(data: { title: "${formatString(param.title)}", typeOf: "${param.typeOf}", udfGroup: "${
          param.udfGroupId
        }"}) {
              ... on SnapshotUdf {
                id
                title
              }
              ... on OperationInfo {
                messages {
                  message
                  field
                  kind
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeSnapshotUdf(response.createSnapshotUdf);
      },
    }),
    updateSnapshotUdf: builder.query<SnapshotUdf, UdfParams>({
      query: (param: UdfParams) => ({
        document: gql`
          mutation {
            updateSnapshotUdf(data: { title: "${formatString(param.title)}", typeOf: "${param.typeOf}", id: "${
          param.id
        }"}) {
              ... on SnapshotUdf {
                id
                title
              }
              ... on OperationInfo {
                messages {
                  message
                  field
                  kind
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeSnapshotUdf(response.updateSnapshotUdf);
      },
    }),
    deleteCaptureUdfOption: builder.query<string, string>({
      query: (id: string) => ({
        document: gql`
          mutation {
            deleteCaptureUdfOption(data: { id: "${id}" }) {
              ... on CaptureUdfOption {
                id
              }
              ... on OperationInfo {
                messages {
                  field
                  kind
                  message
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "CaptureUdf Option deleted";
      },
    }),
    deleteSnapshotUdfOption: builder.query<string, string>({
      query: (id: string) => ({
        document: gql`
          mutation {
            deleteSnapshotUdfOption(data: { id: "${id}" }) {
              ... on SnapshotUdfOption {
                id
              }
              ... on OperationInfo {
                messages {
                  field
                  kind
                  message
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "SnapshotUdf Option deleted";
      },
    }),
    deleteCaptureUdf: builder.query<string, string>({
      query: (id: string) => ({
        document: gql`
          mutation {
            deleteCaptureUdf(data: { id: "${id}" }) {
              ... on CaptureUdf {
                id
              }
              ... on OperationInfo {
                messages {
                  field
                  kind
                  message
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "CaptureUdf deleted";
      },
    }),
    deleteSnapshotUdf: builder.query<string, string>({
      query: (id: string) => ({
        document: gql`
          mutation {
            deleteSnapshotUdf(data: { id: "${id}" }) {
              ... on SnapshotUdf {
                id
              }
              ... on OperationInfo {
                messages {
                  field
                  kind
                  message
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return "SnapshotUdf deleted";
      },
    }),
    createSnapshotUdfOption: builder.query<{ id: string; value: string }, CreateOptionParams>({
      query: (param: CreateOptionParams) => ({
        document: gql`
          mutation {
            createSnapshotUdfOption(
              data: {snapshotField: "${param.udfId}", value: "${param.value}"}
            ) {
              ... on SnapshotUdfOption {
                id
                value
              }
              ... on OperationInfo {
                messages {
                  kind
                  message
                  field
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return { id: response.createSnapshotUdfOption.id, value: response.createSnapshotUdfOption.value };
      },
    }),
    createCaptureUdfOption: builder.query<{ id: string; value: string }, CreateOptionParams>({
      query: (param: CreateOptionParams) => ({
        document: gql`
          mutation {
            createCaptureUdfOption(
              data: {captureField: "${param.udfId}", value: "${param.value}"}
            ) {
              ... on CaptureUdfOption {
                id
                value
              }
              ... on OperationInfo {
                messages {
                  kind
                  message
                  field
                }
              }
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return { id: response.createCaptureUdfOption.id, value: response.createCaptureUdfOption.value };
      },
    }),
    applyTemplateToSnapshot: builder.query<string, ApplyTemplateToSnapshotParams>({
      query: (param: ApplyTemplateToSnapshotParams) => ({
        document: gql`
          mutation {
            applyUdfGroup(groupId: "${param.groupId}", snapshotId: "${param.snapshotId}")
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return response.applyUdfGroup;
      },
    }),
    removeTemplateFromSnapshot: builder.query<string, ApplyTemplateToSnapshotParams>({
      query: (param: ApplyTemplateToSnapshotParams) => ({
        document: gql`
          mutation {
            removeUdfGroup(groupId: "${param.groupId}", snapshotId: "${param.snapshotId}")
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return response.removeUdfGroup;
      },
    }),
    createUdfGoupAndUdfs: builder.query<ReportsResponse, ReportsResponse>({
      query: (param: ReportsResponse) => ({
        document: buildUdfUdfGroupAndUdfQuery(param),
      }),
      transformResponse: async (response: any) => {
        return deserializeTemplates(response.createUdfGroup);
      },
    }),
  }),
});

export const {
  useGetCustomFieldTemplatesQuery,
  useLazyGetCustomFieldTemplatesQuery,
  useAddCustomFieldTemplateQuery,
  useLazyAddCustomFieldTemplateQuery,
  useLazyUpdateCustomFieldTemplateQuery,
  useLazyGetCustomFieldTemplateQuery,
  useLazyDeleteCustomFieldTemplateQuery,
  useLazyAddCaptureUdfQuery,
  useLazyUpdateCaptureUdfQuery,
  useLazyAddSnapshotUdfQuery,
  useLazyUpdateSnapshotUdfQuery,
  useLazyDeleteCaptureUdfOptionQuery,
  useLazyDeleteSnapshotUdfOptionQuery,
  useLazyCreateCaptureUdfOptionQuery,
  useLazyCreateSnapshotUdfOptionQuery,
  useLazyDeleteCaptureUdfQuery,
  useLazyDeleteSnapshotUdfQuery,
  useLazyApplyTemplateToSnapshotQuery,
  useLazyRemoveTemplateFromSnapshotQuery,
  useLazyCreateUdfGoupAndUdfsQuery,
} = customFieldTemplateApi;
