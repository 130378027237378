import { ClickAwayListener, Grow, MenuItem, Popper, PopperProps, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

interface StyledMenuProps extends PopperProps {
  children: React.ReactElement;
  onClose: () => void;
  compactPadding?: boolean;
}

export default function StyledMenu(props: StyledMenuProps) {
  const theme = useTheme();
  const { children, onClose, compactPadding, ...otherProps } = props;

  return (
    <Popper transition style={{ zIndex: theme.zIndex.modal }} {...otherProps}>
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
          <div
            style={{
              display: "flex",
              overflow: "auto",
              flexDirection: "column",
              fontSize: "large",
              padding: compactPadding ? theme.spacing(1) : theme.spacing(3),
              background: "#fff",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", // Add elevation effect using box-shadow
            }}
          >
            <ClickAwayListener onClickAway={onClose}>{children}</ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

const PrimaryColorMenuItem = styled(MenuItem)(({ theme }) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}));

export { PrimaryColorMenuItem };
