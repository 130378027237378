import { ICommServer } from "./types";
import {
  ConnectionState,
  ConnectionStateSubscription,
  IConnectionStatusManager,
} from "services/ConnectionStatusManager";

export class CommServerConnectionStatusManager implements IConnectionStatusManager {
  private commServer?: ICommServer;

  constructor({ commServer }: { commServer?: ICommServer }) {
    this.commServer = commServer;
  }

  getConnectionState(): ConnectionState {
    if (!this.commServer) {
      return ConnectionState.DISCONNECTED;
    }
    return this.commServer.getConnectionState();
  }

  retryConnection(): Promise<void> {
    if (!this.commServer) {
      return new Promise<void>(() => {});
    }
    return this.commServer.start();
  }

  subscribeToConnectionState(callback: ConnectionStateSubscription): () => void {
    if (!this.commServer) {
      return () => {};
    }
    return this.commServer.subscribeToConnectionState(callback);
  }
}
