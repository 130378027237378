import { User } from "services/ContentServer/Identity";

export interface TeamState {
  id: string;
  name: string;
  description: string;
  userprofile: string[];
  users: User[];
  addedUsers: User[];
  removedUsers: User[];
  folder: string[];
  colour: string;
}
export enum TeamActionType {
  RESET_ALL,
  UPDATE_USERS,
  UPDATE_ADDED_REMOVED_USERS,
  UPDATE_TEAM,
  UPDATE_TEAM_NAME,
}

export interface TeamAction {
  type: TeamActionType;
  id?: string;
  name?: string;
  description?: string;
  userprofile?: string[];
  users?: User[];
  addedUsers?: User[];
  removedUsers?: User[];
  folder?: string[];
}

export const teamReducer = (state: TeamState, action: TeamAction): TeamState => {
  switch (action.type) {
    case TeamActionType.RESET_ALL:
      return {
        ...initialTeamState,
        id: action.id || "",
        name: action.name || "",
        description: action.description || "",
        userprofile: action.userprofile || [],
        users: action.users || [],
      };
    case TeamActionType.UPDATE_ADDED_REMOVED_USERS:
      return {
        ...state,
        addedUsers: action.addedUsers || [],
        removedUsers: action.removedUsers || [],
      };
    case TeamActionType.UPDATE_USERS:
      return {
        ...state,
        users: action.users || [],
      };
    case TeamActionType.UPDATE_TEAM_NAME:
      return {
        ...state,
        name: action.name || "",
      };
    case TeamActionType.UPDATE_TEAM:
      return {
        ...state,
        id: action.id || "",
        name: action.name || "",
        description: action.description || "",
      };
    default:
      return state;
  }
};

export const initialTeamState: TeamState = {
  id: "",
  name: "New Team",
  description: "",
  userprofile: [],
  users: [],
  addedUsers: [],
  removedUsers: [],
  folder: [],
  colour: "lightblue",
};
