import { ENDPOINTS } from "./constants";
import { ChatGroup, ChatInterface } from "./types";
import { IChatMessage } from "services/Chat/serializers";
import { APIInstance } from "services/ContentServer/APIInstance";

export const ChatSWRKeys = {
  CAPTURE_HISTORIES: "captureHistories",
  CHAT_HISTORY: "chatHistory",
  CHAT_HISTORY_EXT: "chatHistoryExt",
  UNREAD_MESSAGES: "unreadMessages",
};
export class ChatService implements ChatInterface {
  private apiInstance: APIInstance;

  constructor({ apiInstance }: { apiInstance: APIInstance }) {
    this.apiInstance = apiInstance;
  }

  async readChatMessages(userId: string) {
    const endpoint = `${ENDPOINTS.READ_CHAT}${userId}/`;
    const resp = await this.apiInstance.post(endpoint);
    return resp.data;
  }

  async getChatHistory(userId: string) {
    const endpoint = `${ENDPOINTS.CHAT_HISTORY}${userId}/`;
    const resp = await this.apiInstance.get(endpoint);
    return resp.data.chat_groups as ChatGroup[];
  }

  async getExtendedHistory(messageId: string) {
    const url = this.apiInstance.addFieldsToGetRequest(`${ENDPOINTS.EXTENDED_CHAT_HISTORY}`);
    url.searchParams.append("message_id", messageId);
    const resp = await this.apiInstance.get(url.toString());
    return resp.data as IChatMessage[];
  }

  async getUnreadMessages() {
    const endpoint = `${ENDPOINTS.UNREAD_CHAT}`;
    const resp = await this.apiInstance.get(endpoint);
    return resp.data;
  }
}
