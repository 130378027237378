import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, Typography, Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useCallback, useMemo, useState } from "react";
import { useUpdateUserMutation } from "redux/api/userApi";

import RoleUsersDialog from "./RoleUsersDialog";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import LightTooltip from "components/DalmatianDesignComponents/LightTooltip";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { ADMIN_ROLE, Role, User, RoleIDList } from "services/ContentServer/Identity";
import { matchSorter } from "utils/SortRowsUtils";

const RoleUsersTab = ({
  role,
  setUserRowCount,
  setProcessing,
  setProcessingMsg,
}: {
  role: Role | undefined;
  setUserRowCount: (value: number) => void;
  setProcessing: (processing: boolean | ((prev: boolean) => boolean)) => void;
  setProcessingMsg: (processing: string | ((prev: string) => string)) => void;
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { users, currentUser } = useUsers();
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateUser] = useUpdateUserMutation();

  const roleUsers = useMemo(() => {
    if (role && role.id && users) {
      return users.filter((user) => user.roles.some((userRole) => userRole === role.id));
    }
    return undefined;
  }, [role, users]);

  const removeUser = useCallback(
    async (user: User, bulkDelete = false) => {
      if (!role) {
        console.error("Error retrieving role");
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error removing user from role. Role was not found",
        });
        return;
      }
      if (!bulkDelete) {
        setProcessingMsg("Removing user from role...");
        setProcessing(true);
      }
      const updatedRoleList: RoleIDList = [];
      Object.values(user.roles)
        .filter((userRole) => userRole !== role.id)
        .forEach((userRole) => {
          updatedRoleList.push(userRole);
        });
      const updatedUser = { ...user };
      updatedUser.roles = updatedRoleList;
      try {
        updateUser({ id: user.id, user: updatedUser, updatedRoles: true });
        if (!bulkDelete) {
          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Successfully removed user from role.",
          });
        }
      } catch (err) {
        console.error(err);
        if (!bulkDelete) {
          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Error removing user from role.",
          });
        }
      }
      if (!bulkDelete) {
        setProcessing(false);
        setProcessingMsg("");
      }
    },
    [updateUser, role, setProcessing, setProcessingMsg, snackbar]
  );

  const removeAllUsers = useCallback(() => {
    if (roleUsers === undefined) {
      console.error("Error retrieving users for role. Role or users undefined.");
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error removing users from role.",
      });
      return;
    }
    setProcessing(true);
    setProcessingMsg("Removing all users from role...");
    try {
      if (role?.name === ADMIN_ROLE) {
        roleUsers.filter((user) => user.id !== currentUser?.id).forEach((user) => removeUser(user, true));
      } else {
        roleUsers.forEach((user) => removeUser(user, true));
      }
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Successfully removed all users from role.",
      });
    } catch (err) {
      console.error(err);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error removing users from role.",
      });
    }
    handleClose();
    setProcessing(false);
    setProcessingMsg("");
  }, [currentUser?.id, removeUser, role?.name, roleUsers, setProcessing, setProcessingMsg, snackbar]);

  const applyFilters = useCallback(
    (rows: User[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const userRows = useMemo(() => {
    if (roleUsers !== undefined) {
      setUserRowCount(roleUsers.length);
      return applyFilters(Object.values(roleUsers));
    }
    return undefined;
  }, [applyFilters, roleUsers, setUserRowCount]);

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const removeButton = useCallback(
    (user: User) => {
      return (
        <span>
          <IconButton
            onClick={async () => await removeUser(user)}
            disabled={user.id === currentUser?.id && role?.name === "admin"}
            size="large"
          >
            <HighlightOffOutlinedIcon
              sx={{
                color: user.id === currentUser?.id && role?.name === "admin" ? "#95989B" : theme.palette.primary.main,
              }}
            />
          </IconButton>
        </span>
      );
    },
    [currentUser?.id, removeUser, role?.name, theme.palette.primary.main]
  );

  return (
    <>
      <Dialog
        open={confirmOpen}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
          },
          width: "100%",
        }}
      >
        <DialogTitle style={{ width: "100%", padding: "24px 0px 0px 0px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography variant="h3">Remove All Users</Typography>
            <IconButton onClick={handleClose} style={{ color: theme.palette.primary.main }} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Grid item xs={12} style={{ width: "100%", paddingBottom: "32px" }}>
              <Typography variant="body1">{`Are you sure you would like to remove all users from the ${role?.name} role?`}</Typography>
            </Grid>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: 0,
                gap: "8px",
                width: "65%",
              }}
            >
              <BaseButton variant="outlined" onClick={handleClose}>
                Cancel
              </BaseButton>
              <BaseButton variant="contained" onClick={() => removeAllUsers()}>
                Remove All Users
              </BaseButton>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
      {role !== undefined ? (
        <RoleUsersDialog
          role={role}
          open={open}
          setOpen={setOpen}
          setProcessing={setProcessing}
          setProcessingMsg={setProcessingMsg}
        />
      ) : (
        <></>
      )}
      <Grid item xs={12}>
        {role?.name && <Typography variant="body1">{`Select which users have the "${role.name}" role.`}</Typography>}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "flex-start", padding: "0px 0px 24px 0px", gap: "24px" }}>
          <SearchBar placeholderText={"Search users..."} query={query} setQuery={setQuery} />
          <BaseButton
            variant={"outlined"}
            startIcon={<AddIcon />}
            onClick={() => {
              setOpen(true);
            }}
          >
            Add Users
          </BaseButton>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
          paddingTop: 0,
          gap: "16px",
          height: "60vh",
          overflowY: "auto",
        }}
      >
        {roleUsers && roleUsers.length ? (
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: " 0px",
              gap: "16px",
            }}
          >
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: " 0px",
                gap: "8px",
              }}
            >
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <DeleteOutlineSharpIcon sx={{ color: theme.palette.primary.contrastText }} />
              </Avatar>
              <Typography variant="body1">Remove All</Typography>
            </Box>
            <IconButton color="primary" onClick={() => setConfirmOpen(true)} size="large">
              <DeleteOutlineSharpIcon />
            </IconButton>
          </Box>
        ) : (
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Typography variant="body1">No users have this role</Typography>
          </Box>
        )}
        {userRows?.map((user) => {
          return (
            <Box
              key={user.id}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: " 0px",
                gap: "16px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: " 0px",
                  gap: "8px",
                }}
              >
                {user.userProfile.profilePicture !== "" ? (
                  <Avatar
                    sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}
                    src={user.userProfile.profilePicture}
                  />
                ) : (
                  <Avatar sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}>
                    <UserPlaceholderIcon />
                  </Avatar>
                )}
                <Typography variant="body1">{user.name}</Typography>
              </Box>
              {user.id === currentUser?.id && role?.name === "admin" ? (
                <LightTooltip title="You cannot remove yourself from the default admin role.">
                  {removeButton(user)}
                </LightTooltip>
              ) : (
                removeButton(user)
              )}
            </Box>
          );
        })}
      </Grid>
    </>
  );
};

export default RoleUsersTab;
