import { useCallback, useContext, useMemo } from "react";
import useSWR, { mutate } from "swr";

import { MESSAGE_TYPE } from "services/CommServer";
import { TaskProgressSWRKeys } from "services/ContentServer/Audit/services/TaskprogressService";
import { useUpdateHandler } from "services/InspectionUpdate/useUpdateHandler";
import { UpdateMessage } from "services/MessageHub";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

export const useTaskProgress = (relatedObjectId?: string | null) => {
  const { contentServer } = useContext(RequestContext);
  const { data } = useSWR([TaskProgressSWRKeys, relatedObjectId], () =>
    relatedObjectId ? contentServer.taskProgressService.list([["object", relatedObjectId]]) : undefined
  );

  const progress = useMemo(() => {
    if (data && data.length >= 1) {
      return data[0];
    }
    return undefined;
  }, [data]);

  const taskProgressUpdate = useCallback(
    (update: UpdateMessage) => {
      if (progress && update.id === progress.id) {
        mutate(relatedObjectId ? [TaskProgressSWRKeys, relatedObjectId] : null);
      }
    },
    [progress, relatedObjectId]
  );

  useUpdateHandler(MESSAGE_TYPE.TASK_PROGRESS_UPDATE, taskProgressUpdate);

  return progress;
};
