import { Icon, IconProps } from "@mui/material";
import React from "react";

import icon from "assets/icons/RoleUsers.svg";

export const RoleUsersIcon = (props: IconProps) => {
  return (
    <Icon {...props} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <img src={icon} alt="Role Users Icon" />
    </Icon>
  );
};

export default RoleUsersIcon;
