import { folderApi } from "./folderApi";

export interface IUserProfile {
  id: string;
  user: string;
  name: string;
  displayName: string;
  team: string[];
  addTeam?: string[];
  removeTeam?: string[];
}

const deserialize = (obj: any): IUserProfile => {
  return {
    id: obj.id,
    user: obj.user,
    name: obj.name,
    displayName: obj["display_name"],
    team: obj.team,
  } as IUserProfile;
};

const serialize = (item: IUserProfile) => {
  const formData = new FormData();

  if (item.name) formData.append("name", item.name);
  if (item.displayName) formData.append("display_name", item.displayName);
  if (item.addTeam) {
    item.addTeam.forEach((id) => {
      formData.append("add_team", id);
    });
  }
  if (item.removeTeam) {
    item.removeTeam.forEach((id) => {
      formData.append("remove_team", id);
    });
  }

  return formData;
};

export const userProfileApi = folderApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getUserProfiles: builder.query<IUserProfile[], void>({
      query() {
        return {
          url: `userprofile/`,
        };
      },
      transformResponse: (response: any, meta, arg) => {
        return response.map((item: any) => deserialize(item));
      },
      providesTags: (result, error) =>
        result
          ? // successful query
            [...result.map(({ id }) => ({ type: "UserProfiles", id } as const)), { type: "UserProfiles", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "UserProfiles", id: "LIST" }],
    }),
    getUserProfileById: builder.query<IUserProfile, string>({
      query(id) {
        return {
          url: `userprofile/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return deserialize(response);
      },
      providesTags: (result, error, id) => [{ type: "UserProfiles", id }],
    }),
    updateUserProfileTeam: builder.mutation<any, IUserProfile>({
      query(body: IUserProfile) {
        return {
          url: `userprofile/${body.id}/`,
          method: "PATCH",
          body: serialize(body),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "UserProfiles", id },
        { type: "Teams", id: "LIST" },
        { type: "Folders", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetUserProfilesQuery,
  useGetUserProfileByIdQuery,
  useLazyGetUserProfileByIdQuery,
  useUpdateUserProfileTeamMutation,
} = userProfileApi;
