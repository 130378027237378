import { useState, useEffect, useCallback, useContext } from "react";

import {} from "services";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

const useMessagingServiceConnectionState = () => {
  const { messagingServiceStatusManager } = useContext(RequestContext);
  const [connectionState, setConnectionState] = useState(messagingServiceStatusManager.getConnectionState());

  const attemptRetryConnection = useCallback(async () => {
    try {
      await messagingServiceStatusManager.retryConnection();
    } catch (error) {
      console.error(error);
    }
  }, [messagingServiceStatusManager]);

  useEffect(() => {
    const unsubscribe = messagingServiceStatusManager.subscribeToConnectionState(setConnectionState);

    return () => unsubscribe();
  }, [connectionState, messagingServiceStatusManager]);

  return { connectionState, attemptRetryConnection };
};
export default useMessagingServiceConnectionState;
