import { Matrix4 } from "../types";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Device | undefined } = {
  id: "id",
  name: "name",
  projection_matrix: "projectionMatrix",
};

export class Device extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public projectionMatrix?: Matrix4 | null;

  constructor(device?: Device) {
    super();
    Object.assign(this, device);
  }

  deserialize(json: any): void {
    deserializerHelper<Device>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Device>(this, serverToView);
    return request;
  }
}
