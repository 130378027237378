import { ClickAwayListener, Collapse, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useState, useContext, useMemo } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { TenantFeatureEnum } from "redux/api/types";
import { getDrawerWidth, getOpenSettingsNav, setOpenSettingsNav, setSelectedNavItem } from "redux/features/appSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import useApp from "redux/hooks/useApp";

import PreferencesPage from "./PreferencesPage";
import ProfilePage from "./Profile/ProfilePage";
import RoleEditPage from "./Roles/RoleEditPage";
import RolesPage from "./Roles/RolesPage";
import SiteEditPage from "./Sites/SiteEditPage";
import SitesPage from "./Sites/SitesPage";
import TeamEditPage from "./Teams/TeamEditPage";
import TeamsPage from "./Teams/TeamsPage";
import UsersPage from "./Users/UsersPage";
import ARCopyright from "components/ARCopyright";
import ListButton from "components/Button/ListButton";
import { CommonPageContext } from "components/CommonPage/CommonPageContext";
import AccountDialog from "components/Dialog/AccountDialog";
import LoadingDialog from "components/Dialog/LoadingDialog";
import portalRoutes from "components/Routes";
import useARMediaQuery from "hooks/useARMediaQuery";
import { EDITED_PROFILE_CACHE_KEY } from "hooks/useAuth";
import { PERMISSION_NAME } from "hooks/usePermission";
import useUsers from "hooks/useUsers";
import { toTitleCase } from "utils/StringUtils";

interface SettingRoute {
  isNested: boolean;
  requiredPermission: PERMISSION_NAME;
  path: string;
  component: (props: any) => JSX.Element;
  pathRel: string;
  featureFlagName?: TenantFeatureEnum;
}

export enum SettingTab {
  PREFERENCES = "PREFERENCES",
  ROLES = "ROLES",
  ROLE_EDIT = "ROLE_EDIT",
  SITES = "SITES",
  SITE_EDIT = "SITE_EDIT",
  TEAMS = "TEAMS",
  TEAM_EDIT = "TEAM_EDIT",
  USERS = "USERS",
  PROFILE = "PROFILE",
}

export const SettingRoutesDict: Map<SettingTab, SettingRoute> = new Map([
  [
    SettingTab.PREFERENCES,
    {
      isNested: false,
      requiredPermission: PERMISSION_NAME.ADMIN,
      path: `${portalRoutes.systemSettingsPage.path}/preferences`,
      pathRel: "preferences",
      component: (setSelected: (value: SettingTab) => void) => <PreferencesPage setSelected={setSelected} />,
    },
  ],
  [
    SettingTab.ROLES,
    {
      isNested: false,
      requiredPermission: PERMISSION_NAME.ROLES,
      path: `${portalRoutes.systemSettingsPage.path}/roles`,
      component: (setSelected: (value: SettingTab) => void) => <RolesPage setSelected={setSelected} />,
      pathRel: "roles",
    },
  ],
  [
    SettingTab.ROLE_EDIT,
    {
      isNested: true,
      requiredPermission: PERMISSION_NAME.ROLES,
      path: `${portalRoutes.systemSettingsPage.path}/role_edit`,
      component: () => <RoleEditPage />,
      pathRel: "role_edit",
    },
  ],
  [
    SettingTab.SITES,
    {
      isNested: false,
      requiredPermission: PERMISSION_NAME.SITE,
      path: `${portalRoutes.systemSettingsPage.path}/sites`,
      component: (setSelected: (value: SettingTab) => void) => <SitesPage setSelected={setSelected} />,
      pathRel: "sites",
      featureFlagName: TenantFeatureEnum.AssetV1,
    },
  ],
  [
    SettingTab.SITE_EDIT,
    {
      isNested: true,
      requiredPermission: PERMISSION_NAME.SITE,
      path: `${portalRoutes.systemSettingsPage.path}/site_edit`,
      component: () => <SiteEditPage />,
      pathRel: "site_edit",
      featureFlagName: TenantFeatureEnum.AssetV1,
    },
  ],
  [
    SettingTab.TEAMS,
    {
      isNested: false,
      requiredPermission: PERMISSION_NAME.TEAM,
      path: `${portalRoutes.systemSettingsPage.path}/teams`,
      component: (setSelected: (value: SettingTab) => void) => <TeamsPage setSelected={setSelected} />,
      pathRel: "teams",
      featureFlagName: TenantFeatureEnum.AssetV2,
    },
  ],
  [
    SettingTab.TEAM_EDIT,
    {
      isNested: true,
      requiredPermission: PERMISSION_NAME.TEAM,
      path: `${portalRoutes.systemSettingsPage.path}/team_edit`,
      component: () => <TeamEditPage />,
      pathRel: "team_edit",
      featureFlagName: TenantFeatureEnum.AssetV2,
    },
  ],
  [
    SettingTab.USERS,
    {
      isNested: false,
      requiredPermission: PERMISSION_NAME.USER,
      path: `${portalRoutes.systemSettingsPage.path}/users`,
      component: (setSelected: (value: SettingTab) => void) => <UsersPage setSelected={setSelected} />,
      pathRel: "users",
    },
  ],
  [
    SettingTab.PROFILE,
    {
      isNested: true,
      requiredPermission: PERMISSION_NAME.USER,
      path: `${portalRoutes.systemSettingsPage.path}/profile`,
      component: () => <ProfilePage />,
      pathRel: "profile",
    },
  ],
]);

const SettingsBasePage = () => {
  const theme = useTheme();
  const { featureAccess } = useUsers();
  const { isSiteCreating } = useContext(CommonPageContext);
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const settingsNavOpen = useAppSelector(getOpenSettingsNav);
  const drawerWidth = useAppSelector(getDrawerWidth);
  const dispatch = useAppDispatch();
  const app = useApp();
  const [profileDialogOpen, setProfileDialogOpen] = useState<boolean>(
    localStorage.getItem(EDITED_PROFILE_CACHE_KEY) !== null
  );

  const { path } = useRouteMatch();

  const isVisible = useCallback(
    (requiredPermission: PERMISSION_NAME) => {
      if (featureAccess && requiredPermission in featureAccess) {
        return featureAccess[requiredPermission].read;
      } else {
        false;
      }
    },
    [featureAccess]
  );

  const tenantFeatureEnabled = useCallback(
    (route: SettingRoute) => {
      if (route.featureFlagName) {
        return app.isFeatureEnabled(route.featureFlagName);
      }

      return true;
    },
    [app]
  );

  const handleClose = useCallback(() => {
    localStorage.removeItem(EDITED_PROFILE_CACHE_KEY);
    setProfileDialogOpen(false);
  }, []);

  const [selectedTab, setSelectedTab] = useState<SettingTab | undefined>(undefined);

  const settingsNavTabs = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "top",
          borderRadius: 1,
          zIndex: 6,
          position: "relative",
          height: "100vh",
          width: "100%",
          borderRight: "1px solid",
          fontSize: "medium",
          borderColor: "#ededed",
          backgroundColor: "white",
        }}
      >
        <ListButton
          id="header"
          value={0}
          selected={false}
          disabled={true}
          style={{ border: "none", backgroundColor: "transparent" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              px: 5,
              height: drawerWidth,
            }}
            gap={1}
          >
            <Typography variant="h5" sx={{ px: 1 }}>
              {"Settings"}
            </Typography>
          </Box>
        </ListButton>

        {Array.from(SettingRoutesDict.entries())
          .filter(
            ([tab, route]) => isVisible(route.requiredPermission) && tenantFeatureEnabled(route) && !route.isNested
          )
          .map(([tab, route]) => (
            <Link key={`${tab}-page-link`} to={`${path}/${route.pathRel}`} style={{ textDecoration: "none" }}>
              <ListButton
                key={`${tab}-page-link`}
                id={`${tab}-page`}
                value={0}
                selected={selectedTab === tab}
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  dispatch(setSelectedNavItem(`${path}/${route.pathRel}`));
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: drawerWidth,
                    px: 5,
                  }}
                  gap={1}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      px: 1,
                    }}
                  >
                    {toTitleCase(`${tab}`)}
                  </Typography>
                </Box>
              </ListButton>
            </Link>
          ))}

        <ARCopyright name={"Interaptix"} linkTo={"http://interaptix.com/"} overlay={false} />
      </Box>
    );
  }, [isVisible, path, selectedTab, tenantFeatureEnabled, drawerWidth, dispatch]);

  const handleCloseSettingsNav = useCallback(() => {
    dispatch(setOpenSettingsNav(false));
  }, [dispatch]);

  return (
    <>
      <LoadingDialog processing={isSiteCreating} msg={"Creating site..."} />
      <AccountDialog
        useOpen={{ state: profileDialogOpen, setState: setProfileDialogOpen }}
        dialogTitle="Profile Information Updated"
        dialogContent="We see you have returned from editing your profile information. If any changes were made, it may take up to an hour to be reflected on your profile."
        buttonText="Okay"
        onConfirm={handleClose}
        onClose={handleClose}
        cancelButton={false}
      />
      <Box sx={{ display: "flex", flex: "1 1 auto", padding: 0, overflow: "hidden", height: "100%" }}>
        <Box sx={{ display: matchesMD ? "none" : "flex", width: "260px" }}>{settingsNavTabs}</Box>
        <Collapse
          orientation="horizontal"
          in={settingsNavOpen}
          onMouseLeave={handleCloseSettingsNav}
          sx={{ position: "absolute", zIndex: theme.zIndex.appBar - 1 }}
        >
          <ClickAwayListener onClickAway={handleCloseSettingsNav}>{settingsNavTabs}</ClickAwayListener>
        </Collapse>
        <Box
          sx={{
            px: 0,
            pt: 3,
            pb: 0,
            width: "100%",
            height: "100%",
            minWidth: "400px",
          }}
        >
          <Switch>
            <Route exact path={path}></Route>
            {Array.from(SettingRoutesDict.entries())
              .filter(([tab, route]) => isVisible(route.requiredPermission) && tenantFeatureEnabled(route))
              .map(([tab, route]) => (
                <Route key={`setting-route-${route.path}`} exact path={`${path}/${route.pathRel}`}>
                  {route.component(setSelectedTab)}
                </Route>
              ))}
          </Switch>
        </Box>
      </Box>
    </>
  );
};

const SettingsPage = () => {
  return <SettingsBasePage />;
};

export default SettingsPage;
