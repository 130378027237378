import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { LabelGroup } from "../serviceTypes/LabelGroup";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const LabelGroupSWRKeys = {
  LABELS_GROUPS: "label_groups",
};
export class LabelGroupService extends ServiceBase<LabelGroup> {
  constructor(apiInstance: APIInstance) {
    super(LabelGroup, apiInstance, ENDPOINTS.LABEL_GROUPS);
  }
}
