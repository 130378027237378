import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

import { DrawingPlane } from ".";

import { setLayers } from "components/CameraSelector/Common/ThreeUtils";
import { COLOR, RenderLayer } from "components/CameraSelector/Constants";
import { Point3D } from "services/ContentServer/Audit/types";
import { colorStringToInt } from "utils/ColorUtils";

export class Stroke3D {
  points: Point3D[] = [];
  drawingPlane: DrawingPlane | undefined = undefined;
  line: Line2 | null = null;

  add(point: Point3D, drawingPlane?: DrawingPlane, color?: COLOR, lineWidth?: number) {
    this.points.push(point);

    const points: number[] = [];
    this.points.forEach((point) => {
      points.push(point[0], point[1], point[2]);
    });

    const drawingLineMaterial = new LineMaterial({
      color: colorStringToInt(color ? color : COLOR.RED),
      linewidth: lineWidth || 0.005,
      worldUnits: true,
      transparent: true,
    });

    this.drawingPlane = drawingPlane;

    const geometry = new LineGeometry();
    geometry.setPositions(points);
    this.line = new Line2(geometry, drawingLineMaterial);
    this.line.computeLineDistances();
    this.line.geometry.computeBoundsTree();
    setLayers(this.line, RenderLayer.ANNOTATION_LAYER);
  }
  dispose() {
    this.line?.geometry?.dispose();
    this.line?.material?.dispose();
    this.line?.clear();
  }
  clear() {
    this.points = [];
    this.dispose();
    this.drawingPlane = undefined;
    this.line = null;
  }
  isEmpty() {
    return this.points.length === 0;
  }
}
