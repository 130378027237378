import { SORTBY, SORTORDER, folderApi } from "./folderApi";
import { IAsset } from "services/ContentServer/Audit/serviceTypes/Asset";
import { sortObjectsByString } from "utils/SortingUtils";

const serializeAsset = (obj: IAsset) => {
  const formData = new FormData();

  if (obj.name) formData.append("name", obj.name);
  if (obj.description !== undefined) formData.append("description", obj.description);
  if (obj.parentId !== undefined) formData.append("parent_id", obj.parentId ? obj.parentId : "");
  if (obj.assetInfoId !== undefined) formData.append("asset_info_id", obj.assetInfoId ? obj.assetInfoId : "");
  return formData;
};

const deserializeAsset = (obj: any): IAsset => {
  const objMap: IAsset = {
    id: obj.id,
    name: obj["name"],
    description: obj.description,
    parentId: obj["parent_id"],
    folderPath: obj["folder_path"],
    idPath: obj["id_path"],
    state: obj["state"],
    createdAt: obj["created_at"],
    createdBy: obj["created_by"],
    lastModifiedAt: obj["last_modified_at"],
    lastModifiedBy: obj["last_modified_by"],
    metadata: {
      assetInfo: obj["asset_info_id"],
      lastCreated: obj["last_created"],
    },
    teams: obj["team"],
    thumbnail: obj["thumbnail"],
    arFile: obj["ar_file"],
  };

  return objMap;
};

export type AssetParam = {
  facilityId: string;
  name?: string;
  sortBy?: SORTBY;
  sortOrder?: SORTORDER;
};

const prevParam: AssetParam = {
  facilityId: "",
  name: "",
  sortBy: SORTBY.ACTIVITY,
  sortOrder: SORTORDER.ASCENDING,
};

const buildParamString = (param: AssetParam): string => {
  const obj: AssetParam = { ...prevParam, ...param };
  const arrTmp = [];

  for (const [key, value] of Object.entries(obj)) {
    switch (key) {
      case "facilityId":
        if (value !== "") arrTmp.push(`facility_id=${value}`);
        break;
      case "name":
        if (value && value.toString().length > 0) arrTmp.push(`${key}=${encodeURIComponent(value)}`);
        break;
      case "sortBy":
        arrTmp.push(
          `order_by=${(obj.sortOrder === SORTORDER.ASCENDING ? "" : "-") + obj.sortBy?.toString().toLocaleLowerCase()}`
        );
        break;
      case "sortOrder":
        break;
      default:
        arrTmp.push(`${key}=${value?.toString()}`);
    }
  }

  return arrTmp.sort((a, b) => sortObjectsByString(a, b)).join("&");
};

export const assetApi = folderApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssetById: builder.query<IAsset, string>({
      query(id: string) {
        return { url: `/assets/${id}/` };
      },
      transformResponse: (response: any) => {
        return deserializeAsset(response);
      },
      providesTags: (result, error, id) => [{ type: "Assets", id }],
    }),
    getAssetsByFacility: builder.query<IAsset[], AssetParam>({
      query(param: AssetParam) {
        return { url: `/assets/?${buildParamString(param)}` };
      },
      transformResponse: (response: any) => {
        return response.map((item: any) => deserializeAsset(item));
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Assets", id } as const)), { type: "Assets", id: "LIST" }]
          : [{ type: "Assets", id: "LIST" }],
    }),
    updateAsset: builder.mutation<any, Partial<IAsset>>({
      query(body: IAsset) {
        return {
          url: `assets/${body.id}/`,
          method: "PATCH",
          body: serializeAsset(body),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Assets", id },
        { type: "Assets", id: "LIST" },
      ],
    }),
    addAsset: builder.mutation<any, Partial<IAsset>>({
      query(body: IAsset) {
        return {
          url: `assets/`,
          method: "POST",
          body: serializeAsset(body),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Assets", id },
        { type: "Assets", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAssetsByFacilityQuery,
  useLazyGetAssetsByFacilityQuery,
  useUpdateAssetMutation,
  useAddAssetMutation,
} = assetApi;
