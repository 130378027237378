import { ChevronRightOutlined } from "@mui/icons-material";
import { Typography, Box, useTheme, Button } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query/react";
import React, { useCallback, useEffect, useMemo, useState, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  useGetTeamByIdQuery,
  ITeam,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useGetTeamsQuery,
} from "redux/api/teamApi";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import TeamInfoTab from "./TeamInfoTab";
import { initialTeamState, TeamActionType, teamReducer, TeamState } from "./teamReducer";
import UsersTab from "./UsersTab";
import { HeaderTabs } from "components/DalmatianDesignComponents/HeaderTabs";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import LoadingDialog from "components/Dialog/LoadingDialog";
import portalRoutes from "components/Routes";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { User } from "services/ContentServer/Identity";
import { duplicateSuffixes } from "utils/StringUtils";

const TeamEditPage: React.FC = () => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const { getStringQuery, history } = useRouter();
  const snackbar = useSnackbar();
  const { users } = useUsers();
  const [teamId, setTeamId] = useState<string>();
  const [teamState, dispatchTeamState] = useReducer(teamReducer, initialTeamState);
  const [team, setTeam] = useState<TeamState>(initialTeamState);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(true);
  const [runOnce, setRunOnce] = useState<number>(0);
  const { data: teamData, isFetching } = useGetTeamByIdQuery(teamId ?? skipToken);
  const { data: teamsData } = useGetTeamsQuery();
  const [createTeam] = useCreateTeamMutation();
  const [updateTeam] = useUpdateTeamMutation();
  const [deleteTeam] = useDeleteTeamMutation();

  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setTeamId(getStringQuery("teamId"));
  }, [getStringQuery]);

  const resetVars = useCallback(
    (data: ITeam) => {
      const tmp: User[] = [];
      data.userprofile.forEach((id) => {
        const findUser = users.find((user) => user.userProfile.id === id);
        if (findUser) tmp.push(findUser);
      });
      setTeam({
        ...initialTeamState,
        id: data.id,
        name: data.name,
        description: data.description || "",
        userprofile: data.userprofile,
        users: [...tmp],
      });
      dispatchTeamState({
        id: data.id,
        name: data.name,
        description: data.description || "",
        userprofile: data.userprofile,
        users: [...tmp],
        type: TeamActionType.RESET_ALL,
      });
    },
    [users]
  );

  useEffect(() => {
    if (runOnce === 0 && teamData && users.length) {
      if (teamData.id) {
        resetVars(teamData);
      }
      setProcessing(false);
      setRunOnce(1);
    }
  }, [teamData, users, setTeam, setProcessing, runOnce, teamId, resetVars]);

  useEffect(() => {
    const added: User[] = [];
    const removed: User[] = [];

    teamState.users.forEach((user) => {
      if (!team.users.includes(user)) {
        added.push(user);
      }
    });
    team.users.forEach((user) => {
      if (!teamState.users.includes(user)) {
        removed.push(user);
      }
    });

    dispatchTeamState({ addedUsers: added, removedUsers: removed, type: TeamActionType.UPDATE_ADDED_REMOVED_USERS });
  }, [team.users, teamState.users]);

  const handleInfoChange = useCallback((team: ITeam) => {
    dispatchTeamState({
      id: team.id,
      name: team.name,
      description: team.description,
      type: TeamActionType.UPDATE_TEAM,
    });
  }, []);

  const handleUsersChange = useCallback((users: User[]) => {
    dispatchTeamState({
      users: users,
      type: TeamActionType.UPDATE_USERS,
    });
  }, []);

  const handleDelete = useCallback(() => {
    const teamsRoute = SettingRoutesDict.get(SettingTab.TEAMS);
    setProcessing(true);

    deleteTeam(teamState.id)
      .unwrap()
      .then(() => {
        setProcessing(false);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Team successfully deleted.",
        });
        if (teamsRoute) {
          history.push(teamsRoute.path);
        }
      })

      .catch(() => {
        setProcessing(false);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error deleting team.",
        });
      });
  }, [teamState.id, deleteTeam, snackbar, history]);

  const handleDiscardChanges = useCallback(() => {
    dispatchTeamState({
      id: team.id,
      name: team.name,
      description: team.description,
      userprofile: team.userprofile,
      users: team.users,
      type: TeamActionType.RESET_ALL,
    });

    if (getStringQuery("teamId") === "") {
      history.push(`${portalRoutes.systemSettingsPage.path}/teams`);
    }
  }, [team, getStringQuery, history]);

  const onSuccessResponse = useCallback(
    (newName: string) => {
      dispatchTeamState({ name: newName, type: TeamActionType.UPDATE_TEAM_NAME });
      dispatchTeamState({ addedUsers: [], removedUsers: [], type: TeamActionType.UPDATE_ADDED_REMOVED_USERS });
      setTeam({ ...teamState, name: newName });
      setProcessing(false);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Team changes successfully submitted.",
      });
    },
    [snackbar, teamState]
  );

  const onErrorResponse = useCallback(() => {
    setProcessing(false);
    snackbar.dispatch({
      type: SnackbarActionType.OPEN,
      message: "Error submitting team changes.",
    });
  }, [snackbar]);

  const handleUpdateTeam = useCallback(async () => {
    setProcessing(true);

    let newName = teamState.name;
    const suffixes = duplicateSuffixes(teamsData ?? [], {
      id: teamState.id,
      name: teamState.name,
    } as ITeam);
    if (suffixes.length > 0 && suffixes[0] !== 0) {
      newName += ` (${suffixes[0]})`;
    }

    if (teamState.id !== "") {
      updateTeam({
        id: teamState.id,
        name: newName,
        description: teamState.description,
        addUserProfile: teamState.addedUsers.map((user) => user.userProfile.id),
        removeUserProfile: teamState.removedUsers.map((user) => user.userProfile.id),
      } as ITeam)
        .unwrap()
        .then(() => {
          onSuccessResponse(newName);
        })
        .catch((error) => {
          console.error(error);
          onErrorResponse();
        });
    } else {
      createTeam({
        name: newName,
        description: teamState.description,
        addUserProfile: teamState.addedUsers.map((user) => user.userProfile.id),
      } as ITeam)
        .unwrap()
        .then((response) => {
          onSuccessResponse(newName);
          const teamEditRoute = SettingRoutesDict.get(SettingTab.TEAM_EDIT);
          if (teamEditRoute) {
            history.replace(`${teamEditRoute.path}?teamId=${response.id}`);
          }
          resetVars(response);
        })
        .catch((error) => {
          console.error(error);
          onErrorResponse();
        });
    }
  }, [onSuccessResponse, onErrorResponse, updateTeam, createTeam, teamState, history, resetVars, teamsData]);

  const didEdit = useMemo(() => {
    if (teamState.id !== "") {
      return (
        team.name !== teamState.name ||
        team.description !== teamState.description ||
        teamState.addedUsers.length > 0 ||
        teamState.removedUsers.length > 0
      );
    }
    return true;
  }, [team, teamState]);

  const userCount = useMemo(() => {
    return teamState.users.length;
  }, [teamState.users]);

  const InfoComponent = useMemo(() => {
    return (
      <TeamInfoTab
        team={teamState}
        onChange={handleInfoChange}
        onDelete={() => {
          setDeleteConfirmOpen(true);
        }}
        didModify={didEdit}
      />
    );
  }, [teamState, handleInfoChange, didEdit]);

  const UsersComponent = useMemo(() => {
    return (
      <UsersTab
        itemLabel={"team"}
        itemsLabel={"teams"}
        itemName={teamState.name}
        selectedUsers={teamState.users}
        onChange={handleUsersChange}
        listModified={didEdit}
      />
    );
  }, [teamState, handleUsersChange, didEdit]);

  const TabComponents = useMemo(() => {
    return [InfoComponent, UsersComponent];
  }, [InfoComponent, UsersComponent]);

  return (
    <>
      <LoadingDialog processing={isFetching || processing} msg={"Loading..."} />
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            height: "auto",
            width: "100%",
            display: "flex",
            alignItems: "center",
            pt: 3,
            pb: 3,
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            gap: 0.5,
          }}
        >
          <Link to={SettingRoutesDict.get(SettingTab.TEAMS)?.path ?? ""} style={{ textDecoration: "none" }}>
            <Typography variant="h1" sx={{ paddingBottom: 0, ":hover": { textDecoration: "underline" } }}>
              Teams
            </Typography>
          </Link>
          <ChevronRightOutlined style={{ color: theme.palette.primary.main }} />
          <Typography variant="h1" style={{ paddingBottom: 0 }}>
            {teamState.name}
          </Typography>
        </Box>

        <Box
          sx={{
            paddingBottom: 0,
            height: "50px",
            width: "100%",
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ borderBottom: "solid 1px #e1e2e4", width: "100%", height: "100%" }}>
            <HeaderTabs tab={tab} setTab={setTab} headers={["Information", `Users (${userCount})`]} />
          </Box>
        </Box>

        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          {TabComponents[tab]}
        </Box>
        {didEdit && (
          <SettingsBottomBar show={true} hasRequiredFields={teamState.name.length > 0}>
            <>
              <Button variant="outlined" onClick={handleDiscardChanges}>
                Cancel
              </Button>
              <Button variant="contained" disabled={false} onClick={async () => await handleUpdateTeam()}>
                {getStringQuery("teamId") === "" ? "Create Team" : "Save Changes"}
              </Button>
            </>
          </SettingsBottomBar>
        )}
      </Box>

      <ConfirmationDialog
        useOpen={{ state: deleteConfirmOpen, setState: setDeleteConfirmOpen }}
        onConfirm={handleDelete}
        dialogText={`Are you sure you would like to delete this team? Users will no longer have access to files associated with this team.`}
        title={"Delete Team"}
        confirmText={"Delete"}
      />
    </>
  );
};

export default TeamEditPage;
