import { Matrix4 } from "..";
import { BoxExtent } from "./Touchup";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Rescan | undefined } = {
  id: "id",
  snapshot_id: "snapshot",
  offset: "offset",
  extent: "extent",
  fulfilled: "fulfilled",
  notes: "notes",
  created_by: "createdBy",
  created_at: "createdAt",
};

export class Rescan extends ISerialization {
  public id?: string | null;
  public snapshot?: string | null;
  public offset?: Matrix4 | null;
  public extent?: BoxExtent | null;
  public fulfilled?: boolean | null;
  public notes?: string | null;
  public createdBy?: string | null;
  public createdAt?: string | null;

  constructor(rescan?: Rescan) {
    super();
    if (rescan) {
      Object.assign(this, rescan);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Rescan>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Rescan>(this, serverToView);
    if (this.offset !== undefined) {
      request.offset = JSON.stringify(this.offset);
    }
    if (this.extent !== undefined) {
      request.extent = JSON.stringify(this.extent);
    }

    return request;
  }
}
