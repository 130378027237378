import { Device } from "../ContentServer/Identity";
import { IVideoCallHub } from "../MessageHub";
import { DataChannelMessageType, TrackType } from "./constants";
import { WebRTCMessageType, serializeWebRTCToServerMessage } from "./serializers";
import { serializeServerToWebRTCMessage, IServerMessage } from "./serializers";
import { IWebRTCSession, ConnectionClosedType } from "./types";

const DATA_CHANNEL_LABEL = "data_channel";
const DATA_CHANNEL_ID = 2;
const DEFAULT_RTC_CONFIG: RTCConfiguration = {
  iceServers: [],
};
const PING_INTERVAL_MS = 1000;
const TIMEOUT_INTERVAL_MS = 1500;

export class WebRTCSession implements IWebRTCSession {
  readonly isReceiving: boolean;
  isClosed = false;
  isInitialized = false;

  peerID: string;
  peerDevice?: Device;
  private userID: string;
  private userDevice: Device;

  private unsubscribe: () => void;

  private videoCallHub: IVideoCallHub;

  private iceCandidateStore: RTCIceCandidate[] = [];
  private pc?: RTCPeerConnection;
  private dataChannel: RTCDataChannel | null = null;
  private stream?: MediaStream;

  private pingTimerId: number | undefined;
  private timeoutTimerId: number | undefined;

  constructor({
    videoCallHub,
    isReceiving,
    userID,
    userDevice,
    peerID,
    iceServers,
    peerDevice,
  }: {
    videoCallHub: IVideoCallHub;
    isReceiving: boolean;
    userID: string;
    userDevice: Device;
    peerID: string;
    iceServers: any;
    peerDevice?: Device;
  }) {
    this.videoCallHub = videoCallHub;
    this.peerID = peerID;
    this.peerDevice = peerDevice;
    this.userID = userID;
    this.userDevice = userDevice;
    this.isReceiving = isReceiving;
    this.setUpRelay(iceServers);

    this.unsubscribe = this.subscribeToVideoCallMessages();
  }

  onConnectionClosed: (type: ConnectionClosedType, reason?: string) => void = () => {};
  onConnectionPingReceived: () => void = () => {};
  onConnectionTimeout: () => void = () => {};

  onDataChannelOpen: () => void = () => {};

  onRemoteMediaStream: (stream: MediaStream) => void = () => {};

  onRemoteMediaStateMessage: (message: any) => void = () => {};

  onRemoteInspectorLocation: (message: any) => void = () => {};

  sendData(type: DataChannelMessageType, data: any = undefined): Promise<void> {
    if (this.dataChannel && this.dataChannel.readyState === "open") {
      this.dataChannel.send(JSON.stringify({ type: type, data: data }));
      return Promise.resolve();
    } else {
      return Promise.reject("Failed to send WebRTC data through data channel.");
    }
  }

  private async setUpRelay(iceServers: any) {
    DEFAULT_RTC_CONFIG.iceServers = iceServers;

    this.pc = new RTCPeerConnection(DEFAULT_RTC_CONFIG);
    this.stream = new MediaStream();

    this.pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (event.candidate) {
        this.sendMessage(WebRTCMessageType.ICE_CANDIDATE, event.candidate);
      }
    };

    this.pc.ontrack = (ev: RTCTrackEvent) => {
      if (ev.streams && ev.streams[0]) {
        this.onRemoteMediaStream(ev.streams[0]);
      } else {
        // TODO: If we start supporting adding new tracks in the middle of a call, we need to decide how to handle this
        //       Do we add all tracks, or make sure only one audio/video track is streamed at one time
        this.stream?.addTrack(ev.track);
        if (this.stream) {
          this.onRemoteMediaStream(this.stream);
        }
      }
    };

    this.dataChannel = this.pc.createDataChannel(DATA_CHANNEL_LABEL, { negotiated: true, id: DATA_CHANNEL_ID });

    this.dataChannel.onopen = () => {
      this.onDataChannelOpen();
      this.pingTimerId = window.setInterval(() => this.sendPing(), PING_INTERVAL_MS);
      this.timeoutTimerId = window.setTimeout(() => this.handleTimeout(), TIMEOUT_INTERVAL_MS);
    };

    this.dataChannel.onmessage = (ev: { data: string }) => {
      const message = JSON.parse(ev.data);
      switch (message.type) {
        case DataChannelMessageType.Media:
          this.onRemoteMediaStateMessage(message.data);
          break;
        case DataChannelMessageType.Annotation:
          console.warn(`No handler for data channel message of type: ${message.type}`);
          break;
        case DataChannelMessageType.Ping:
          this.handlePing();
          break;
        case DataChannelMessageType.Location:
          this.onRemoteInspectorLocation(message.data);
          break;
        default:
          console.warn(`Unknown data channel message type: ${message.type}`);
          break;
      }
    };
  }

  private handleTimeout() {
    this.onConnectionTimeout();
  }

  private handlePing() {
    this.onConnectionPingReceived();
    window.clearTimeout(this.timeoutTimerId);
    this.timeoutTimerId = window.setTimeout(() => this.handleTimeout(), TIMEOUT_INTERVAL_MS);
  }

  private sendPing() {
    this.sendData(DataChannelMessageType.Ping);
  }

  private async startConnection(): Promise<void> {
    if (!this.pc) {
      this.callFailed(ConnectionClosedType.OFFER_FAILED);
      return;
    }
    try {
      const desc = await this.pc.createOffer();
      await this.pc.setLocalDescription(desc);
      this.sendMessage(WebRTCMessageType.SDP_OFFER, desc);
    } catch (error) {
      this.callFailed(ConnectionClosedType.OFFER_FAILED);
    }
  }

  async answer(): Promise<void> {
    if (!this.pc) {
      return;
    }
    this.pc.onnegotiationneeded = async () => await this.startConnection();
    this.sendMessage(WebRTCMessageType.CALL_HANDLED, undefined, true).catch(console.error);
    await this.startConnection();
  }

  call(): void {
    this.sendMessage(WebRTCMessageType.CALL).catch(console.error);
  }

  hangup(): void {
    this.sendMessage(WebRTCMessageType.HANGUP).catch(console.error);
    this.closePeerConnection(ConnectionClosedType.USER_HANGUP);
  }

  busy(): void {
    this.sendMessage(WebRTCMessageType.BUSY).catch(console.error);
    this.sendMessage(WebRTCMessageType.CALL_HANDLED, undefined, true).catch(console.error);
    this.closePeerConnection(ConnectionClosedType.USER_BUSY);
  }

  decline(): void {
    this.sendMessage(WebRTCMessageType.DECLINE).catch(console.error);
    this.sendMessage(WebRTCMessageType.CALL_HANDLED, undefined, true).catch(console.error);
    this.closePeerConnection(ConnectionClosedType.USER_DECLINE);
  }

  callFailed(type: ConnectionClosedType, reason?: string): void {
    this.sendMessage(WebRTCMessageType.CALL_FAILED).catch(console.error);
    this.sendMessage(WebRTCMessageType.CALL_HANDLED, undefined, true).catch(console.error);
    this.closePeerConnection(type, reason);
  }

  replaceVideoTrack(track: MediaStreamTrack): void {
    if (!this.pc) {
      return;
    }
    this.pc.getSenders().forEach((sender) => {
      if (sender.track?.kind === TrackType.Video) {
        sender.replaceTrack(track).catch(console.error);
      }
    });
  }

  replaceAudioTrack(track: MediaStreamTrack): void {
    if (!this.pc) {
      return;
    }
    this.pc.getSenders().forEach((sender) => {
      if (sender.track?.kind === TrackType.Audio) {
        sender.replaceTrack(track).catch(console.error);
      }
    });
  }

  setLocalMediaStream(stream: MediaStream): void {
    stream.getTracks().forEach((track: MediaStreamTrack) => {
      if (!this.pc) {
        return;
      }
      this.pc.addTrack(track, stream);
    });
  }

  private processQueuedIceCandidates() {
    if (!this.pc) {
      return;
    }
    const promises: Promise<void>[] = [];
    this.iceCandidateStore.forEach((iceCandidate: RTCIceCandidate) => {
      if (!this.pc) {
        return;
      }
      promises.push(this.pc.addIceCandidate(iceCandidate).catch(console.error));
    });

    Promise.all(promises).finally(() => (this.iceCandidateStore = []));
  }

  private subscribeToVideoCallMessages(): () => void {
    const wrapper = (value: Record<string | number | symbol, unknown>) => {
      const message = serializeServerToWebRTCMessage(value as IServerMessage);
      if (message.offerer === this.peerID) {
        switch (message.type) {
          case WebRTCMessageType.CALL:
            break;
          case WebRTCMessageType.HANGUP:
            this.closePeerConnection(ConnectionClosedType.PEER_HANGUP);
            break;
          case WebRTCMessageType.BUSY:
            this.closePeerConnection(ConnectionClosedType.PEER_BUSY);
            break;
          case WebRTCMessageType.DECLINE:
            this.closePeerConnection(ConnectionClosedType.PEER_DECLINE);
            break;
          case WebRTCMessageType.CALL_FAILED:
            this.closePeerConnection(ConnectionClosedType.PEER_FAILED);
            break;
          case WebRTCMessageType.SDP_OFFER:
            this.peerDevice = message.device;
            this.onSDPOffer(message.data as RTCSessionDescriptionInit);
            break;
          case WebRTCMessageType.SDP_ANSWER:
            this.peerDevice = message.device;
            this.onSDPAnswer(message.data as RTCSessionDescriptionInit);
            break;
          case WebRTCMessageType.ICE_CANDIDATE:
            this.onIceCandidate(message.data as RTCIceCandidateInit);
            break;
          default:
            console.error("Received invalid video call message.");
            break;
        }
      } else if (message.offerer === this.userID) {
        switch (message.type) {
          case WebRTCMessageType.CALL_HANDLED:
            if (!this.pc) {
              return;
            }
            if (this.pc.onnegotiationneeded === null && this.isReceiving) {
              this.closePeerConnection(ConnectionClosedType.USER_HANDLED);
            }
            break;
          default:
            console.error("Received invalid video call message.");
            break;
        }
      }
    };

    return this.videoCallHub.subscribeToVideoCallMessage(wrapper);
  }

  private async onSDPOffer(sessionDescriptionInit: RTCSessionDescriptionInit): Promise<void> {
    if (!this.pc) {
      this.callFailed(ConnectionClosedType.ANSWER_FAILED);
      return;
    }
    try {
      await this.pc.setRemoteDescription(new RTCSessionDescription(sessionDescriptionInit));
      const desc = await this.pc.createAnswer();
      await this.pc.setLocalDescription(desc);
      await this.sendMessage(WebRTCMessageType.SDP_ANSWER, desc);
      this.processQueuedIceCandidates();
    } catch (error) {
      this.callFailed(ConnectionClosedType.ANSWER_FAILED);
    }
  }

  private async onSDPAnswer(sessionDescriptionInit: RTCSessionDescriptionInit): Promise<void> {
    if (!this.pc) {
      this.callFailed(ConnectionClosedType.UNKNOWN);
      return;
    }
    try {
      await this.pc.setRemoteDescription(new RTCSessionDescription(sessionDescriptionInit));
      this.processQueuedIceCandidates();
    } catch (error) {
      this.callFailed(ConnectionClosedType.UNKNOWN);
    }
  }

  private onIceCandidate(candidateInit: RTCIceCandidateInit): void {
    if (!this.pc) {
      return;
    }
    const iceCandidate = new RTCIceCandidate(candidateInit);
    // WebRTC can't add ICE candidates without a remote description
    // so if not set yet, add to queue for processing later
    if (this.pc.remoteDescription) {
      this.pc.addIceCandidate(iceCandidate).catch(console.error);
    } else {
      this.iceCandidateStore.push(iceCandidate);
    }
  }

  private closePeerConnection(type: ConnectionClosedType, reason?: string) {
    if (this.dataChannel) {
      this.dataChannel.close();
      this.dataChannel = null;
    }

    window.clearTimeout(this.timeoutTimerId);
    window.clearInterval(this.pingTimerId);

    if (this.pc) {
      this.pc.close();
    }

    this.unsubscribe();

    this.onConnectionClosed(type, reason);
    this.isClosed = true;
  }

  private sendMessage(
    type: WebRTCMessageType,
    data?: RTCSessionDescriptionInit | RTCIceCandidate,
    sendToSelf = false
  ): Promise<void> {
    return this.videoCallHub.send(
      serializeWebRTCToServerMessage({
        receiver: sendToSelf ? this.userID : this.peerID,
        offerer: this.userID,
        device: this.userDevice,
        type,
        data,
      })
    );
  }
}
