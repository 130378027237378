import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback } from "react";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import { UseState } from "utils/TypeUtils/ReactTypeUtils";

export default function AccountDialog({
  useOpen,
  dialogTitle,
  dialogContent,
  onConfirm,
  onClose,
  buttonText,
  deleteStyle = false,
  cancelButton = true,
}: {
  useOpen: UseState<boolean>;
  dialogTitle: string;
  dialogContent: string;
  onConfirm: () => void;
  onClose: () => void;
  buttonText?: string;
  deleteStyle?: boolean;
  cancelButton?: boolean;
}) {
  const theme = useTheme();
  const { state: open, setState: setOpen } = useOpen;

  const handleConfirm = useCallback(() => {
    setOpen(false);
    onConfirm();
  }, [onConfirm, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose, setOpen]);

  return (
    <>
      <Dialog
        open={open}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
          },
          width: "100%",
        }}
      >
        <DialogTitle style={{ width: "100%", padding: "24px 0px 0px 0px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography
              variant="dalmatianBase"
              sx={{
                fontWeight: 800,
                fontSize: "16px",
                lineHeight: "19px",
                color: theme.palette.dalmatianDesign.dark,
              }}
            >
              {dialogTitle}
            </Typography>
            <IconButton onClick={handleClose} style={{ color: theme.palette.dalmatianDesign.dark }} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Grid item xs={12} style={{ width: "100%", paddingBottom: "32px" }}>
              <Typography>{dialogContent}</Typography>
            </Grid>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: 0,
                gap: "8px",
                width: cancelButton ? "65%" : "35%",
              }}
            >
              {cancelButton && (
                <BaseButton variant="outlined" onClick={handleClose}>
                  Cancel
                </BaseButton>
              )}
              <BaseButton variant="contained" onClick={handleConfirm} deleteButton={deleteStyle}>
                {buttonText ? buttonText : "Confirm"}
              </BaseButton>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
