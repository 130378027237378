import React, { useContext, createContext, useReducer } from "react";

export interface SnackbarState {
  message: string;
  open: boolean;
  autoHide: boolean;
  onClose: () => void;
  children?: React.ReactElement<any, any>;
}

export enum SnackbarActionType {
  UPDATE,
  OPEN,
  RESET
}

export interface SnackbarAction {
  type: SnackbarActionType;
  message?: string;
  open?: boolean;
  autoHide?: boolean;
  onClose?: () => void;
  children?: React.ReactElement<any, any>; 
}

export interface ISnackbarContextProps {
  state: SnackbarState;
  dispatch: React.Dispatch<SnackbarAction>;
}

export const initialSnackbarState: SnackbarState = {
  message: "",
  open: false,
  autoHide: true,
  onClose: () => {}
};

export const snackbarReducer = (state: SnackbarState, action: SnackbarAction): SnackbarState => {
  switch (action.type) {
    case SnackbarActionType.UPDATE:
      return { ...state, ...action };
    case SnackbarActionType.OPEN:
      return { ...state, ...action, open: true };
    case SnackbarActionType.RESET:
      return initialSnackbarState;
    default:
      throw new Error();
  }
};

export const snackbarContext = createContext({
  state: initialSnackbarState,
  dispatch: () => {},
} as ISnackbarContextProps);

export const ProvideSnackbar = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(snackbarReducer, initialSnackbarState);
  const snackbar = { state, dispatch } as ISnackbarContextProps;
  return <snackbarContext.Provider value={snackbar}>{children}</snackbarContext.Provider>;
};

export const useSnackbar = () => {
  return useContext(snackbarContext);
};

export default useSnackbar;
