export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export enum Frequency {
  Hourly = "Hourly",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export const formatDate = (dateRange: DateRange) => {
  return (
    Intl.DateTimeFormat("en-US", { month: "short", day: "numeric", year: "numeric" }).format(dateRange.startDate) +
    " - " +
    Intl.DateTimeFormat("en-US", { month: "short", day: "numeric", year: "numeric" }).format(dateRange.endDate)
  );
};

export const toTwoDigits = (num: number) => {
  return ("0" + num).slice(-2);
};

export const toFourDigits = (num: number) => {
  const numLen = num.toString().length;
  if (numLen >= 4) {
    return num;
  } else {
    return num.toString().padStart(4, "0");
  }
};

export const msToTime = (s: number) => {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;
  const days = Math.floor(hrs / 24);
  const remainingHrs = hrs % 24;
  return toTwoDigits(days) + ":" + toTwoDigits(remainingHrs) + ":" + toTwoDigits(mins) + ":" + toTwoDigits(secs);
};

export function getFormattedDate(date: string, seconds?: boolean) {
  if (date) {
    const pad = function (val: number) {
      return String(val).padStart(2, "0");
    };
    const d = new Date(date);
    const formattedDate = `${d.toLocaleString("en-CA", {
      hour12: true,
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })}${seconds ? `:${pad(d.getSeconds())}` : ""}`.split(" ");

    if (formattedDate[1].split(":")[0] !== "24") {
      return formattedDate.join(" ");
    } else {
      return `${formattedDate[0]} 00:${formattedDate[1].split(":")[1]}`;
    }
  } else {
    return "";
  }
}

export function getMonthDayTime(date: string | null | undefined, seconds?: boolean, useComma?: boolean) {
  if (date) {
    const d = new Date(date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const day = `${d.toLocaleString("default", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      timeZone: timeZone,
    })}`;
    const time = `${d.toLocaleString("default", {
      hour: "2-digit",
      minute: "2-digit",
      second: seconds ? "2-digit" : undefined,
      timeZone: timeZone,
    })}`;

    return useComma ? `${day}, ${time}` : `${day} \u2022 ${time}`;
  } else {
    return "";
  }
}

export function formatLastLogin(date: string) {
  const formattedParts = new Intl.DateTimeFormat("en-gb", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  }).formatToParts(new Date(date));

  const month = formattedParts[4];
  formattedParts[4] = formattedParts[2];
  formattedParts[2] = month;

  return formattedParts
    .map((part) => part.value)
    .join("")
    .replaceAll(",", "");
}
