import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Snapshot } from "../serviceTypes/Snapshot";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const ReadOnlySnapshotSWRKeys = {
  SNAPSHOT: "read_only_snapshot",
};

export class ReadOnlySnapshotService extends ServiceBase<Snapshot> {
  constructor(apiInstance: APIInstance) {
    super(Snapshot, apiInstance, ENDPOINTS.READ_ONLY_SNAPSHOTS);
  }
}
