import { useMemo, useCallback, useState, useEffect } from "react";
import { useGetTeamsQuery } from "redux/api/teamApi";
import { useGetUserProfilesQuery } from "redux/api/userProfileApi";

function useTeams() {
  const { data: allTeams, isLoading, isFetching, isSuccess, isError } = useGetTeamsQuery();
  const {
    data: allProfiles,
    isLoading: profilesLoading,
    isFetching: profilesFetching,
    isSuccess: profilesSuccess,
  } = useGetUserProfilesQuery();
  const [loadingTeams, setLoadingTeams] = useState<boolean>(true);
  const getTeamList = useCallback(
    (userId: string) => {
      let teamIds: string[] = [];
      const names: string[] = [];
      if (allProfiles && allTeams) {
        teamIds = allProfiles.find((profile) => profile.id === userId)?.team || [];
        teamIds.forEach((id) => {
          const teamInfo = allTeams.find((team) => team.id === id);
          if (teamInfo) {
            names.push(teamInfo.name);
          }
        });
      }
      return {
        ids: teamIds,
        names: names.join(", "),
      };
    },
    [allProfiles, allTeams]
  );

  useEffect(() => {
    if (!profilesLoading && !profilesFetching && !isLoading && !isFetching && isSuccess && profilesSuccess) {
      setLoadingTeams(false);
    }
  }, [profilesSuccess, profilesLoading, profilesFetching, isLoading, isFetching, isSuccess]);

  return useMemo(() => {
    return {
      getTeamList,
      loadingTeams,
      allTeams,
      loadingTeamsError: isError,
    };
  }, [getTeamList, loadingTeams, allTeams, isError]);
}

export default useTeams;
