import { APIInstance } from "../../APIInstance";
import { ARCHIVE_ENDPOINTS, ENDPOINTS } from "../constants";
import { Asset } from "../serviceTypes/Asset";
import { ISerializationPaginated } from "services/ContentServer/ISerialization";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const getKey = (pageIndex: number, previousPageData: any) => {
  // reached the end
  if (previousPageData && !previousPageData.data) return null;

  // first page, we don't have `previousPageData`
  if (pageIndex === 0) return `/users?limit=10`;

  // add the cursor to the API endpoint
  return `/users?cursor=${previousPageData.nextCursor}&limit=10`;
};

export const AssetSWRKeys = {
  ASSETS: "assets",
  ASSETIDS: "assetIds",
  ARCHIVED: "archivedAssets",
};

export class AssetPaginated extends ISerializationPaginated<Asset> {}
export class AssetService extends ServiceBase<Asset, AssetPaginated> {
  constructor(apiInstance: APIInstance) {
    super(Asset, apiInstance, ENDPOINTS.ASSETS, AssetPaginated);
  }

  async updateArchive(id: string, archive: boolean) {
    return this.get(id, undefined, archive ? ARCHIVE_ENDPOINTS.ARCHIVE : ARCHIVE_ENDPOINTS.UNARCHIVE);
  }
}
