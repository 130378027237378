export const IMAGE_URLS = {
  LOADING_IMAGE: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
  NO_IMAGE: "/images/select_image.jpg",
};

export const BACKGROUND_SIZES = {
  COVER: "cover",
  CONTAIN: "contain",
};

export const ACTIVE_BUTTONS = {
  BACKGROUND_COLOUR: "rgba(0, 0, 0, 0.08)",
};
