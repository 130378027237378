import { useContext, useEffect, useState } from "react";

import { UpdateHandler } from "./UpdateHandler";
import { useAuth } from "hooks/useAuth";
import { ModelState } from "services/ContentServer/Audit";
import { UpdateCallback } from "services/MessageHub";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

export function updateCache<T extends { id?: string | null | undefined; state?: string | null }>(
  dataArray: T[],
  newData: T
): T[] {
  if (newData.state === ModelState.Deleted) {
    return dataArray.filter((data) => !(data.id === newData.id || data.state === ModelState.Deleted));
  }
  if (dataArray) {
    const exists = dataArray.find((data) => data.id === newData.id);
    if (exists) {
      return dataArray
        .map((data) => {
          if (data.id === newData.id) {
            return newData;
          } else {
            return data;
          }
        })
        .filter((data) => data.state !== ModelState.Deleted);
    } else {
      return [...dataArray, newData];
    }
  } else {
    return [newData];
  }
}

export function updateCacheAllState<T extends { id?: string | null | undefined; state?: string | null }>(
  dataArray: T[],
  newData: T
): T[] {
  if (dataArray) {
    const exists = dataArray.find((data) => data.id === newData.id);
    if (exists) {
      return dataArray.map((data) => {
        if (data.id === newData.id) {
          return newData;
        } else {
          return data;
        }
      });
    } else {
      return [...dataArray, newData];
    }
  } else {
    return [newData];
  }
}

export function useUpdateHandler(messageType: string, updateCallback: UpdateCallback) {
  const auth = useAuth();
  const { inspectionUpdateHub } = useContext(RequestContext);
  const [updateHandler, setUpdateHandler] = useState<UpdateHandler | undefined>();

  useEffect(() => {
    if (auth.user) {
      const updateHandler = new UpdateHandler(inspectionUpdateHub, messageType);
      updateHandler.start();

      setUpdateHandler(updateHandler);
      return () => {
        updateHandler.stop();
        setUpdateHandler(undefined);
      };
    }
  }, [auth.user, inspectionUpdateHub, messageType]);

  useEffect(() => {
    if (updateHandler) {
      const unsubscribe = updateHandler.subscribeToUpdates(updateCallback);
      return () => unsubscribe();
    }
  }, [auth, updateHandler, updateCallback]);
}
