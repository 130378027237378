import { Close, AddAPhotoOutlined } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Typography, Box, Grid, IconButton, Avatar, Badge } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useUpdateUserProfileDataMutation, useGetUserQuery } from "redux/api/userApi";

import { ImageFile } from "components/Annotation/types";
import ConfirmCancelButtons from "components/Button/ConfirmCancelButtons";
import FileDropzone from "components/dropzone/FileDropzone";
import { MAX_DROPZONE_SIZE } from "components/forms/PageDropzone";
import { SnackbarNames, SnackbarOptions } from "components/snackbar/Constants";
import { getSnackbarMessage } from "components/snackbar/SnackbarMessage";
import { PERMISSION_NAME } from "hooks/usePermission";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { User } from "services/ContentServer/Identity/types";

export const ProfileAvatar = ({ owner, user }: { owner: boolean; user: User | null | undefined }) => {
  const theme = useTheme();
  const { featureAccess } = useUsers();

  const [profilePic, setProfilePic] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<ImageFile | null>(null);
  const { dispatch: dispatchSnackbarState } = useSnackbar();
  const [updateUserProfile] = useUpdateUserProfileDataMutation();
  const { data } = useGetUserQuery(user?.id ?? "", { skip: !user });

  useEffect(() => {
    if (data) {
      setProfilePic(data.userProfile.profilePicture);
    }
  }, [data]);

  const adminAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ADMIN].read;
  }, [featureAccess]);

  const onDropFiles = (file: File[]) => {
    setImage(file[0] as ImageFile);
  };

  const updateProfilePicture = useCallback(async () => {
    if (user && user.userProfile && image) {
      try {
        updateUserProfile({
          id: user.userProfile.id,
          data: {
            id: user.userProfile.id,
            userId: user.id,
            site: user.userProfile.defaultSite.site,
            display_name: user.userProfile.displayName,
            active_until: user.userProfile.activeUntil,
            profile_picture: image,
            state: user.userProfile.state,
            read_terms: user.userProfile.readTerms,
          },
        });

        dispatchSnackbarState({
          type: SnackbarActionType.OPEN,
          message: getSnackbarMessage(SnackbarOptions.OBJECT_SUBMITTED, SnackbarNames.PROFILE_PICTURE_UPDATE),
        });
      } catch (error) {
        dispatchSnackbarState({
          type: SnackbarActionType.OPEN,
          message: getSnackbarMessage(SnackbarOptions.OBJECT_ERROR, SnackbarNames.PROFILE_PICTURE_UPDATE),
        });
        console.error(error);
      }
      setImage(null);
      setOpen(false);
    }
  }, [dispatchSnackbarState, image, user, updateUserProfile]);

  const onDeleteFile = () => {
    setImage(null);
  };

  return (
    <Box sx={{ width: "100%", display: "inline-block" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: 200, height: 200 }}>
            <Badge
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{ width: 168, height: 168, padding: "16px", alignItems: "center", justifyContent: "center" }}
              badgeContent={
                owner || adminAccess ? (
                  <IconButton color="primary" id="add-photo" onClick={() => setOpen(true)} size="large">
                    <AddAPhotoOutlined />
                  </IconButton>
                ) : (
                  <></>
                )
              }
            >
              {profilePic ? (
                <Avatar
                  src={profilePic}
                  alt={user?.name}
                  sx={{
                    position: "relative",
                    width: 168,
                    height: 168,
                    backgroundColor: theme.palette.secondary.dark,
                  }}
                />
              ) : (
                <Avatar
                  alt={user?.name}
                  sx={{
                    position: "relative",
                    width: 168,
                    height: 168,
                    backgroundColor: theme.palette.secondary.dark,
                  }}
                >
                  <UserPlaceholderIcon style={{ transform: "scale(5.5)" }} />
                </Avatar>
              )}
            </Badge>
          </Box>
        </Grid>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          sx={{
            ".MuiDialog-paper": {
              borderRadius: 0,
              boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px 8px 8px",
              gap: "8px",
            },
          }}
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              width: "100%",
              paddingTop: "24px",
              paddingBottom: "16px",
              borderBottom: "1px solid rgba(212, 214, 217, 0.7)",
            }}
          >
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h3">{"Upload Profile Image"}</Typography>
              </Box>

              <IconButton color="primary" onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                border: "2px dashed #c4c4c4",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <FileDropzone
                initialFiles={image ? [image as File] : []}
                maxFileSize={MAX_DROPZONE_SIZE}
                onDelete={onDeleteFile}
                onDrop={onDropFiles}
                acceptedFiles={["image/*"]}
                filesLimit={1}
              />
            </Box>
          </DialogContent>
          <ConfirmCancelButtons
            handleDiscardChanges={() => setOpen(false)}
            handleSubmit={updateProfilePicture}
            submitDisabled={image === null}
            hasPendingChanges={true}
            submitText={"Upload"}
          />
        </Dialog>
      </Grid>
    </Box>
  );
};
