import axios, { AxiosInstance } from "axios";
import JSZip from "jszip";

import { APIInstance } from "./APIInstance";
import { ENDPOINTS } from "./Audit/constants";
import { AlignSnapshotsService } from "./Audit/services/AlignSnapshotsService";
import { AnnotationFileTypesService, AnnotationService } from "./Audit/services/AnnotationService";
import { AssetInfoService } from "./Audit/services/AssetInfoService";
import { AssetService } from "./Audit/services/AssetService";
import { DeviceService } from "./Audit/services/DeviceService";
import { DifferenceService } from "./Audit/services/DifferenceService";
import { FacilityService } from "./Audit/services/FacilityService";
import { FullTexturingImageService } from "./Audit/services/FullTexturingImagesService";
import { ImportStatusService } from "./Audit/services/ImportStatusService";
import { JobDataService } from "./Audit/services/JobDataService";
import { LabelGroupService } from "./Audit/services/LabelGroupService";
import { LabelService } from "./Audit/services/LabelService";
import { ManufacturerService } from "./Audit/services/ManufacturerService";
import { MediaCaptureService } from "./Audit/services/MediaCaptureService";
import { MediaHistoryService } from "./Audit/services/MediaHistoryService";
import { PanoramaService } from "./Audit/services/PanoramaService";
import { ReadOnlySnapshotService } from "./Audit/services/ReadOnlySnapshotService";
import { RescanService } from "./Audit/services/RescanService";
import { SnapshotCollectionService } from "./Audit/services/SnapshotCollectionService";
import { SnapshotService } from "./Audit/services/SnapshotService";
import { SupplierService } from "./Audit/services/SupplierService";
import { TaskProgressService } from "./Audit/services/TaskprogressService";
import { TexturingImageService } from "./Audit/services/TexturingImagesService";
import { TouchupService } from "./Audit/services/TouchupService";
import { ChatService } from "./Chat";
import { AllFeaturesService } from "./Features/AllFeaturesService";
import { TenantFeaturesService } from "./Features/TenantFeaturesService";
import { IdentityService } from "./Identity";
import { UserInviteService } from "./Identity/services/UserInviteService";
import { MapService } from "./Map";
import { TenantService } from "./Tenant/Tenant";
import { ContentServerInterface } from "./types";

type TokenProvider = () => string | Promise<string>;

export class ContentServer implements ContentServerInterface {
  readonly identityService: IdentityService;
  readonly userInviteService: UserInviteService;
  readonly mapService: MapService;
  readonly annotationService: AnnotationService;
  readonly annotationFileTypeService: AnnotationFileTypesService;
  readonly chatService: ChatService;
  readonly tenantService: TenantService;
  readonly mediaCaptureService: MediaCaptureService;
  readonly mediaHistoryService: MediaHistoryService;
  readonly manufacturerService: ManufacturerService;
  readonly supplierService: SupplierService;
  readonly deviceService: DeviceService;
  readonly assetInfoService: AssetInfoService;
  readonly assetService: AssetService;

  readonly snapshotService: SnapshotService;
  readonly readOnlySnapshotService: ReadOnlySnapshotService;
  readonly snapshotCollectionService: SnapshotCollectionService;
  readonly axiosInstance: AxiosInstance;
  readonly featuresService: TenantFeaturesService;
  readonly allFeaturesService: AllFeaturesService;
  readonly jobDataService: JobDataService;
  readonly texturingImageService: TexturingImageService;
  readonly fullTexturingImageService: FullTexturingImageService;
  readonly rescanService: RescanService;
  readonly touchupService: TouchupService;
  readonly labelService: LabelService;
  readonly labelGroupService: LabelGroupService;
  readonly facilityService: FacilityService;
  readonly differenceService: DifferenceService;
  readonly importStatusService: ImportStatusService;
  readonly panoramaService: PanoramaService;
  readonly taskProgressService: TaskProgressService;
  readonly apiInstance: APIInstance;
  readonly alignSnapshotService: AlignSnapshotsService;

  constructor({
    baseURL,
    accessTokenFactory,
    apiVersion,
  }: {
    baseURL: string;
    accessTokenFactory: TokenProvider;
    apiVersion: string;
  }) {
    const apiInstance = new APIInstance({ baseURL, accessTokenFactory, apiVersion });
    const readOnlyInstance = new APIInstance({ baseURL, accessTokenFactory: null, apiVersion });

    this.apiInstance = apiInstance;
    this.identityService = new IdentityService({ apiInstance });
    this.userInviteService = new UserInviteService(apiInstance);
    this.mapService = new MapService({ apiInstance });
    this.chatService = new ChatService({ apiInstance });
    this.tenantService = new TenantService({ apiInstance });
    this.featuresService = new TenantFeaturesService(apiInstance);
    this.allFeaturesService = new AllFeaturesService(apiInstance);
    this.annotationService = new AnnotationService(apiInstance);
    this.annotationFileTypeService = new AnnotationFileTypesService(apiInstance);
    this.mediaCaptureService = new MediaCaptureService(apiInstance);
    this.mediaHistoryService = new MediaHistoryService(apiInstance);
    this.jobDataService = new JobDataService(apiInstance);
    this.fullTexturingImageService = new FullTexturingImageService(apiInstance);
    this.manufacturerService = new ManufacturerService(apiInstance);
    this.supplierService = new SupplierService(apiInstance);
    this.deviceService = new DeviceService(apiInstance);
    this.assetInfoService = new AssetInfoService(apiInstance);
    this.assetService = new AssetService(apiInstance);

    this.snapshotService = new SnapshotService(apiInstance);
    this.readOnlySnapshotService = new ReadOnlySnapshotService(readOnlyInstance);
    this.snapshotCollectionService = new SnapshotCollectionService(apiInstance);
    this.rescanService = new RescanService(apiInstance);
    this.touchupService = new TouchupService(apiInstance);
    this.facilityService = new FacilityService(apiInstance);
    this.axiosInstance = axios.create({ baseURL: baseURL });
    this.texturingImageService = new TexturingImageService(apiInstance);
    this.differenceService = new DifferenceService(apiInstance);
    this.labelService = new LabelService(apiInstance);
    this.labelGroupService = new LabelGroupService(apiInstance);
    this.importStatusService = new ImportStatusService(apiInstance);
    this.panoramaService = new PanoramaService(apiInstance);
    this.taskProgressService = new TaskProgressService(apiInstance);
    this.alignSnapshotService = new AlignSnapshotsService(apiInstance);
  }

  async downloadFile(fileUrl: string, fileName?: string) {
    this.axiosInstance.get(fileUrl, { responseType: "blob" }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileStringToUrl = new URL(fileUrl);
      const pathName = fileStringToUrl.pathname.split("/");
      const extension = pathName[pathName.length - 1].split(".").pop();

      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", fileName ? fileName + "." + extension : pathName[pathName.length - 1]);
      document.body.appendChild(link);
      link.click();
    });
  }

  async getIceServers() {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = ENDPOINTS.ICE_SERVERS;
    const resp = await this.apiInstance.get(url.toString());
    return resp.data;
  }

  async zipFile(zip: JSZip, fileUrlString: string, fileName?: string, folderPath?: string) {
    const fileStringToUrl = new URL(fileUrlString);
    const pathName = fileStringToUrl.pathname.split("/");
    const extension = pathName[pathName.length - 1].split(".").pop();
    const evaluatedFileName = fileName ? fileName + "." + extension : pathName[pathName.length - 1];
    const fullFileName = (folderPath ? folderPath + "/" : "") + evaluatedFileName;
    await this.axiosInstance.get(fileUrlString, { responseType: "blob" }).then(
      (response) => {
        zip.file(fullFileName, new Blob([response.data]));
      },
      (err) => {
        console.error("Network Error Occured:", err);
      }
    );
  }

  downloadZip(zip: JSZip, fileName: string) {
    zip.generateAsync({ type: "blob" }).then(function (content) {
      const url = window.URL.createObjectURL(new Blob([content]));

      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  async downloadMultipleFiles(zipFileName: string, fileUrls: string[], fileNames?: string[]) {
    const zip = new JSZip();

    for (let i = 0; i < fileUrls.length; i++) {
      const fileUrl = fileUrls[i];
      const fileName = fileNames ? fileNames[i] : undefined;
      await this.zipFile(zip, fileUrl, fileName);
    }

    this.downloadZip(zip, zipFileName + ".zip");
  }
}
