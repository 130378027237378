import useMediaQuery from "@mui/material/useMediaQuery";

const useARMediaQuery = (breakpoint: string) => {
  const matches = useMediaQuery(breakpoint);
  const userAgent = navigator.userAgent;
  switch (breakpoint) {
    case "mobile":
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          userAgent
        )
      ) {
        return true;
      }

      return false;

    case "tablet":
      if (userAgent.indexOf("Macintosh") > -1) {
        try {
          document.createEvent("TouchEvent");
          return true;
        } catch (e) {
          return false;
          //if touch is not supported, it's not an ipad
        }
      }
      return false;
    default:
      return matches;
  }
};

export default useARMediaQuery;
