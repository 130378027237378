import { PeopleOutlined, CheckCircle, AddCircleOutlineOutlined, Close } from "@mui/icons-material";
import { Avatar, Box, Dialog, DialogTitle, Grid, IconButton, Typography, Divider, DialogContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useCallback, useMemo, useState } from "react";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import useUsers from "hooks/useUsers";
import { User } from "services/ContentServer/Identity";
import { SingleSite } from "services/ContentServer/Map";
import { matchSorter } from "utils/SortRowsUtils";

const SiteUsersDialog = ({
  site,
  open,
  setOpen,
  setProcessing,
  setProcessingMsg,
}: {
  site: SingleSite | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessingMsg: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useTheme();
  const { users } = useUsers();
  const [query, setQuery] = useState<string>("");
  const [addedUsers, setAddedUsers] = useState<User[]>([]);
  const [allSelected, setAllSelected] = useState(false);

  const unassignedUsers = useMemo(() => {
    if (site !== undefined) {
      const assignedUserIds = Object.values(site.users).map((user) => user.id);
      return Object.values(users).filter((user) => !assignedUserIds.includes(user.id));
    }
  }, [site, users]);

  const updateAddedUsers = useCallback(
    (user: User) => {
      let newUsersState = [...addedUsers];
      if (addedUsers.includes(user)) {
        newUsersState = newUsersState.filter((prevUser) => prevUser.id !== user.id);
      } else {
        newUsersState.push(user);
      }
      setAddedUsers(newUsersState);
    },
    [addedUsers]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setQuery("");
    setAllSelected(false);
    setAddedUsers([]);
  }, [setOpen]);

  const applyFilters = useCallback(
    (rows: User[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const userRows = useMemo(() => {
    if (unassignedUsers !== undefined) {
      return applyFilters(unassignedUsers);
    }
  }, [applyFilters, unassignedUsers]);

  return (
    <>
      <Dialog
        open={open}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
            width: "100%",
            height: "65%",
          },
          width: "100%",
        }}
      >
        <DialogTitle sx={{ width: "100%", padding: "24px 0 0 0" }}>
          <Grid container direction="row" sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={11}>
              <Typography
                variant="h3"
                sx={{
                  padding: 0,
                }}
              >
                {`Add Users to ${site?.name}`}
              </Typography>
            </Grid>
            <Grid item xs={1} container sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                id="close-user-log-popup"
                onClick={handleClose}
                style={{ color: theme.palette.primary.main }}
                size="small"
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider sx={{ width: "100%" }} />
        <SearchBar placeholderText={"Search users..."} query={query} setQuery={setQuery} />
        <DialogContent sx={{ width: "100%", padding: "0 5px" }}>
          <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flexStart",
                gap: "16px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: " 0px",
                }}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: " 0px",
                    gap: "8px",
                  }}
                >
                  <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                    <PeopleOutlined sx={{ color: theme.palette.primary.contrastText }} />
                  </Avatar>
                  <Typography variant="body1">Select All</Typography>
                </Box>
                <IconButton
                  sx={{ color: theme.palette.primary.main }}
                  onClick={() => {
                    setAllSelected(!allSelected);
                    setAddedUsers(allSelected ? [] : unassignedUsers || []);
                  }}
                  size="large"
                >
                  {allSelected ? (
                    <CheckCircle />
                  ) : (
                    <AddCircleOutlineOutlined sx={{ color: theme.palette.primary.main }} />
                  )}
                </IconButton>
              </Box>
              {userRows?.map((user) => {
                return (
                  <Box
                    key={user.id}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: " 0px",
                      gap: "16px",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: " 0px",
                        gap: "8px",
                      }}
                    >
                      {user.userProfile.profilePicture !== "" ? (
                        <Avatar
                          sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}
                          src={user.userProfile.profilePicture}
                        />
                      ) : (
                        <Avatar sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}>
                          <UserPlaceholderIcon />
                        </Avatar>
                      )}
                      <Typography variant="body1">{user.name}</Typography>
                    </Box>
                    <IconButton
                      sx={{ color: theme.palette.primary.main }}
                      onClick={() => {
                        updateAddedUsers(user);
                      }}
                      size="large"
                    >
                      {addedUsers.includes(user) ? (
                        <CheckCircle />
                      ) : (
                        <AddCircleOutlineOutlined sx={{ color: theme.palette.primary.main }} />
                      )}
                    </IconButton>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "0px",
            gap: "16px",
            width: "100%",
          }}
        >
          <BaseButton variant={"outlined"} onClick={handleClose}>
            Cancel
          </BaseButton>
        </Box>
      </Dialog>
    </>
  );
};

export default SiteUsersDialog;
