import * as Bowser from "bowser";
import JSZip, { JSZipObject } from "jszip";
import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";

import { DAELoader } from "./DAELoader";
import { pointCloudMaterial } from "components/CameraSelector/Constants";
import { getExtension, isFileType, isImageFile, isZipFile, THREE_D_MODEL_FILE_EXTENSIONS } from "utils/FileUtils";
const plyMaterial = new THREE.MeshBasicMaterial({
  vertexColors: true,
  color: 0xffffff,
  side: THREE.FrontSide,
});

const isSafari = Bowser.getParser(window.navigator.userAgent).satisfies({ macos: { safari: ">10.1" } });

export const disposeObject = (object: THREE.Object3D<THREE.Event>, scene: THREE.Scene | THREE.Group) => {
  if (object.children) {
    object.children.forEach((child) => {
      disposeObject(child, scene);
    });
  }

  if (object instanceof THREE.Mesh) {
    if (object.geometry) {
      object.geometry?.dispose();

      object.geometry = undefined;
      if (object.material) {
        for (const key of Object.keys(object.material)) {
          const value = object.material[key];
          if (value && typeof value === "object" && "minFilter" in value) {
            value.dispose();
          }
        }
        object.material?.dispose();
        object.material = undefined;
      }
    }
    scene?.remove(object);
  }
};

export const getModelLoader = (ext: string, manager: THREE.LoadingManager | undefined = undefined) => {
  let loader: DRACOLoader;
  switch (ext) {
    case "gltf":
    case "glb":
      return new GLTFLoader(manager);
    case "dae":
      return new DAELoader(manager);
    case "fbx":
    case "FBX":
      return new FBXLoader(manager);
    case "obj":
      return new OBJLoader(manager);
    case "ply":
      return new PLYLoader(manager);
    case "drc":
      loader = new DRACOLoader(manager);
      loader.setDecoderPath("/draco/");
      if (isSafari) {
        loader.setDecoderConfig({ type: "js" });
      }
      loader.preload();
      return loader;
    default:
      return null;
  }
};

const loadImageAsObject = async (imagePath: string, manager: THREE.LoadingManager) => {
  const textureLoader = new THREE.TextureLoader(manager);
  const texture = await textureLoader.loadAsync(imagePath);

  const material = new THREE.MeshLambertMaterial({
    map: texture,
  });
  const aspectRatio = texture.image.width / texture.image.height;

  const geometry = new THREE.PlaneGeometry(aspectRatio, 1);
  return new THREE.Mesh(geometry, material);
};

const loadTexturedObj = async (objObj: JSZipObject, mtlObj: JSZipObject, manager: THREE.LoadingManager) => {
  const mtlloader = new MTLLoader(manager);
  const mtl = await mtlObj.async("text");
  const obj = await objObj.async("text");
  const materials = await mtlloader.parse(mtl, "");
  materials.preload();
  const objLoader = new OBJLoader();
  objLoader.setMaterials(materials);
  return await objLoader.parse(obj);
};

const loadObject = async (filePath: string, manager: THREE.LoadingManager | undefined, extension?: string) => {
  const ext = extension ? extension : getExtension(filePath);
  const loader = getModelLoader(ext, manager);
  const tempObject = await loader?.loadAsync(filePath);

  let object: THREE.Group | THREE.Scene | THREE.Points | undefined = undefined;
  if (tempObject) {
    if (tempObject instanceof THREE.Group) {
      object = tempObject;
    } else if (tempObject instanceof THREE.BufferGeometry) {
      if (tempObject.index) {
        object = new THREE.Group();
        const meshObj = new THREE.Mesh(tempObject, plyMaterial);
        object.add(meshObj);
      } else {
        object = new THREE.Points(tempObject, pointCloudMaterial);
      }
    } else {
      object = tempObject.scene;
    }
  }
  if (loader instanceof DRACOLoader) {
    loader.dispose();
  }
  return object;
};

export default class ObjectLoader {
  async load(
    file: File | string,
    fileName?: string,
    onLoad?: () => void,
    ext?: string,
    onProgress?: (url: string, loaded: number, total: number) => void
  ) {
    const manager = new THREE.LoadingManager(
      () => {
        if (onLoad) {
          onLoad();
        }
      },
      (url: string, loaded: number, total: number) => {
        if (onProgress) {
          onProgress(url, loaded, total);
        }
      }
    );
    const filePath = fileName ? fileName : file.toString();

    if (isZipFile(filePath)) {
      const zipFileContent = await JSZip.loadAsync(file);
      let mtlFile: string | null = null;
      let objFile: string | null = null;
      let imageFile: string | null = null;

      const promiseArray: { [key: string]: Promise<Uint8Array> } = {};
      const outputArray: { [key: string]: Uint8Array } = {};
      zipFileContent.forEach((relativePath, folderFile) => {
        if (isFileType(relativePath, THREE_D_MODEL_FILE_EXTENSIONS)) {
          objFile = relativePath;
        } else if (isFileType(relativePath, ["mtl"])) {
          mtlFile = relativePath;
        } else if (isImageFile(relativePath)) {
          imageFile = relativePath;
        }
        const promise = folderFile.async("uint8array");
        promiseArray[relativePath] = promise;
      });
      await Promise.all(Object.keys(promiseArray).map(async (key) => (outputArray[key] = await promiseArray[key])));
      manager.setURLModifier((url) => {
        const buffer = outputArray[url];
        if (buffer) {
          const blob = new Blob([buffer.buffer]);
          const NewUrl = URL.createObjectURL(blob);

          return NewUrl;
        }
        return url;
      });

      if (Object.keys(zipFileContent.files).length === 1 && imageFile) {
        return await loadImageAsObject(imageFile, manager);
      } else if (objFile && mtlFile && isFileType(objFile, ["obj", "OBJ"])) {
        return await loadTexturedObj(zipFileContent.files[objFile], zipFileContent.files[mtlFile], manager);
      } else if (objFile) {
        return await loadObject(objFile, manager);
      }
    }

    return await loadObject(filePath, manager, ext);
  }
}
