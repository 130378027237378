import { Matrix4 } from "../types";
import { VisualFeatures } from "./VisualFeatures";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof TexturingImageCapture | undefined } = {
  id: "id",
  requested_image_pose: undefined,
  image_pose: "pose",
  pose_type: undefined,
  image: "img",
  low_res_image: "lowResImg",
  video: "video",
  device: "device",
  timestamp: "timestamp",
  snapshot_id: "snapshotId",
  rotate: "rotate",
};

export class TexturingImageCapture extends ISerialization {
  public id?: string | null;
  public img?: string | Blob | File | null;
  public lowResImg?: string | Blob | File | null;
  public video?: string | Blob | File | null;
  public timestamp?: any | null;
  public pose?: Matrix4 | null;
  public rotate?: number | null;
  public snapshotId?: string | null;
  public device?: string | null;
  public context?: VisualFeatures | null;

  constructor(image?: TexturingImageCapture) {
    super();
    if (image) {
      Object.assign(this, image);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<TexturingImageCapture>(this, serverToView, json);
    if (json.context) {
      const visualFeatures = new VisualFeatures();
      visualFeatures.deserialize(json.context);
      this.context = visualFeatures;
    }
  }

  serialize(): any {
    const request = serializerHelper<TexturingImageCapture>(this, serverToView);

    if (this.pose !== undefined) {
      request.image_pose = JSON.stringify(this.pose);
    }

    return request;
  }
}
