export default class SubscriptionManager<T> {
  private _subscribers: { [id: number]: T } = {};
  private lastId = 0;

  get subscribers(): T[] {
    return Object.values(this._subscribers);
  }

  subscribe(callback: T): () => void {
    const id = this.lastId;
    this.lastId++;
    this._subscribers[id] = callback;
    return () => {
      delete this._subscribers[id];
    };
  }
}
