import { createApi } from "@reduxjs/toolkit/query/react";
import { unionBy } from "lodash";

import customFetchBase from "./customFetchBase";
import { convertURLtoFolderParam } from "./folderApi";
import {
  getUserFromJson,
  getUserDictFromJson,
  getRoleDictFromRolesList,
  getPermissionDictFromPermissionList,
  serializeUserData,
  serializeProfileData,
  deserializeRoleData,
} from "services/ContentServer/Identity/serializers";
import { User, RoleDict, Role, PermissionDict, Profile } from "services/ContentServer/Identity/types";
import serialize from "utils/ObjectToFormData";
import { sortObjectsByString } from "utils/SortingUtils";
import { SortDirection } from "utils/SortRowsUtils";

interface UserPageResponse {
  count: number;
  nextpage: number;
  pages: number;
  users: User[];
  param: UserParam;
}

export type UserParam = {
  page?: number;
  isActive?: boolean;
  order?: SortDirection;
  orderBy?: string;
  name?: string;
  sites?: string[];
  teams?: string[];
  roles?: string[];
};

const buildParamString = (param: UserParam): string => {
  const obj: UserParam = param;
  const arrTmp = [];

  for (const [key, value] of Object.entries(obj)) {
    switch (key) {
      case "page":
        arrTmp.push(`page=${value?.toString()}`);
        break;
      case "isActive":
        arrTmp.push(`is_active=${value}`);
        break;
      case "orderBy":
        arrTmp.push(
          `order_by=${(obj.order === SortDirection.ASC ? "" : "-") + obj.orderBy?.toString().toLocaleLowerCase()}`
        );
        break;
      case "order":
        break;
      case "sites":
        Array.isArray(value) &&
          value.forEach((siteId) => {
            arrTmp.push(`sites=${siteId}`);
          });
        break;
      case "teams":
        Array.isArray(value) &&
          value.forEach((teamId) => {
            arrTmp.push(`teams=${teamId}`);
          });
        break;
      case "roles":
        Array.isArray(value) &&
          value.forEach((roleId) => {
            arrTmp.push(`roles=${roleId}`);
          });
        break;
      default:
        arrTmp.push(`${key}=${value?.toString()}`);
    }
  }

  return arrTmp.sort((a, b) => sortObjectsByString(a, b)).join("&");
};

const fields = "id,is_active,display_name,userprofile_id,last_login,roles,team";

const rolesDisplayFields = "id,display_name,description,logo";

const deserializeRole = (obj: any): Role => {
  return {
    name: obj["display_name"],
    description: obj["description"],
    id: obj["id"],
    logo: obj["logo"],
    permissions: getPermissionDictFromPermissionList(obj["permissions"]),
  };
};

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBase,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query(id) {
        return {
          url: `/users/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return getUserFromJson(response);
      },
      providesTags: (result, error, id) => [{ type: "Users", id }],
    }),
    getUsers: builder.query<User[], void>({
      query() {
        return {
          url: `/usersdisplay/`,
        };
      },
      transformResponse: (response: any) => {
        return getUserDictFromJson(response);
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Users" as const, id })), { type: "Users", id: "LIST" }]
          : [{ type: "Users", id: "LIST" }],
    }),
    getUsersByPage: builder.query<UserPageResponse, UserParam>({
      query(param: UserParam) {
        return {
          url: `/usersdisplay/?fields=${fields}&paginate=true&${buildParamString(param)}`,
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCacheData, responseData) => {
        currentCacheData.count = responseData.count;
        currentCacheData.nextpage = responseData.nextpage;
        currentCacheData.pages = responseData.pages;
        currentCacheData.param = responseData.param;

        if (responseData.param.page === 1 && responseData.param.isActive) {
          currentCacheData.users = [...responseData.users];
        } else {
          currentCacheData.users = unionBy(currentCacheData.users, responseData.users, "id");
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: any, meta, arg) => {
        return {
          count: response.count,
          nextpage: response.next ? (convertURLtoFolderParam(response.next).page as number) : 0,
          pages: response["num_pages"],
          users: getUserDictFromJson(response.results),
          param: arg,
        };
      },
      providesTags: (result) =>
        result?.users
          ? [...result.users.map(({ id }) => ({ type: "Users" as const, id })), { type: "Users", id: "LIST" }]
          : [{ type: "Users", id: "LIST" }],
    }),
    getRoles: builder.query<RoleDict, void>({
      query() {
        return {
          url: `/userroles/?fields=${rolesDisplayFields}`,
        };
      },
      transformResponse: (response: any) => {
        return getRoleDictFromRolesList(response);
      },
    }),
    getRoleById: builder.query<Role, string>({
      query(id) {
        return {
          url: `/userroles/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return deserializeRole(response);
      },
    }),
    getDefaultMemberRole: builder.query<Role, void>({
      query() {
        return {
          url: `/userroles/?display_name=member`,
        };
      },
      transformResponse: (response: any) => {
        return deserializeRoleData(response)[0];
      },
    }),
    getPermissions: builder.query<PermissionDict, void>({
      query() {
        return {
          url: `/userpermissions/`,
        };
      },
      transformResponse: (response: any) => {
        return getPermissionDictFromPermissionList(response);
      },
    }),
    getUserPermissions: builder.query<PermissionDict, string>({
      query(id) {
        return {
          url: `/userpermissions/?username=${id}`,
        };
      },
      transformResponse: (response: any) => {
        return getPermissionDictFromPermissionList(response);
      },
    }),
    updateUser: builder.mutation<User, { id: string; user: User; updatedRoles?: boolean }>({
      query({ id, user, updatedRoles }) {
        return {
          url: `/users/${id}/`,
          method: "PATCH",
          body: serializeUserData(id, user, updatedRoles),
        };
      },
      async onQueryStarted({ id, user }, { dispatch }) {
        dispatch(
          userApi.util.updateQueryData("getUsers", undefined, (draft) => {
            const updUser = draft.find((user) => user.id === id);
            if (updUser) {
              updUser.roles = user.roles;
              updUser.isActive = user.isActive;
            }
          })
        );
      },
    }),
    deleteUser: builder.mutation<User, string>({
      query(id) {
        return {
          url: `/users/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Users", id: arg },
        { type: "Users", id: "LIST" },
      ],
    }),
    updateUserProfile: builder.mutation<Profile, { id: string; profile: Profile }>({
      query({ id, profile }) {
        return {
          url: `/userprofile/${id}/`,
          method: "PATCH",
          body: serializeProfileData(id, profile),
        };
      },
      onQueryStarted({ id, profile }, { dispatch }) {
        dispatch(
          userApi.util.updateQueryData("getUsers", undefined, (draft) => {
            const userToUpdate = draft.find((user) => user.id === id);
            if (userToUpdate) {
              userToUpdate.userProfile = profile;
            }
          })
        );
      },
    }),
    updateUserProfileData: builder.mutation<Profile, { id: string; data: any }>({
      query({ id, data }) {
        return {
          url: `/userprofile/${id}/`,
          method: "PATCH",
          body: serialize(data),
        };
      },
      invalidatesTags: (result, error, { data }) => [{ type: "Users", id: data.userId }],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useLazyGetUsersByPageQuery,
  useGetRolesQuery,
  useGetRoleByIdQuery,
  useGetPermissionsQuery,
  useLazyGetPermissionsQuery,
  useGetUserPermissionsQuery,
  useLazyGetDefaultMemberRoleQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserProfileMutation,
  useUpdateUserProfileDataMutation,
} = userApi;
