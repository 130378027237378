import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Dropzone from "react-dropzone";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import FileDropzone from "components/dropzone/FileDropzone";

export const MAX_DROPZONE_SIZE = Math.pow(1024, 4);

export default function PageDropzone({
  children,
  titleText,
  csvTemplate,
  open,
  onSubmit,
  onSubmitMultiple,
  setOpen,
  multiple = false,
}: {
  children: React.ReactNode | React.ReactNode[];
  titleText: string;
  csvTemplate: string;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onSubmit?: (file: File) => void;
  onSubmitMultiple?: (file: File[]) => void;
  multiple?: boolean;
}) {
  const [csvFile, setCSVFile] = useState<File>();
  const [multiFiles, setMultiFiles] = useState<File[]>([]);

  useEffect(() => {
    setCSVFile(undefined);
  }, [open]);

  const canSubmit = useMemo(() => {
    if (multiple && multiFiles.length > 0) {
      return true;
    } else if (!multiple && csvFile) {
      return true;
    }
    return false;
  }, [csvFile, multiFiles, multiple]);

  const fileObjects = useMemo(() => {
    if (multiple) {
      return multiFiles.map((f) => {
        return f as File;
      });
    } else if (csvFile) {
      return [csvFile as File];
    } else {
      return [] as File[];
    }
  }, [csvFile, multiFiles, multiple]);

  return (
    <>
      <Dropzone
        accept={[".csv"]}
        onDrop={(acceptedFiles) => (multiple ? setMultiFiles(acceptedFiles) : setCSVFile(acceptedFiles[0]))}
        onDragEnter={() => setOpen(true)}
      >
        {() => (
          <div>
            {children}
            <Dialog
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <DialogTitle>
                <Typography variant="h3">{titleText}</Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpen(false)}
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 5,
                  }}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    border: "2px dashed #c4c4c4",
                    padding: "16px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <FileDropzone
                    initialFiles={fileObjects}
                    maxFileSize={MAX_DROPZONE_SIZE}
                    dropzoneText={`Drag and drop CSV target file here, or click to browse`}
                    acceptedFiles={[".csv"]}
                    filesLimit={multiple ? 50 : 1}
                    onDrop={(files: File[]) => {
                      multiple ? setMultiFiles((prev) => [...prev, ...files]) : setCSVFile(files[0]);
                    }}
                    onDelete={(deletedFileObject: File, index?: number) => {
                      multiple
                        ? setMultiFiles((prev) => prev.filter((f, idx) => idx !== index))
                        : setCSVFile(undefined);
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <a
                  href={csvTemplate}
                  download
                  style={{
                    padding: "8px",
                    fontSize: "13px",
                  }}
                >
                  Download Template
                </a>
                <BaseButton
                  onClick={() => {
                    if (!multiple && csvFile && onSubmit) {
                      onSubmit(csvFile);
                    } else if (multiple && onSubmitMultiple) {
                      onSubmitMultiple(multiFiles);
                    }
                  }}
                  disabled={!canSubmit}
                  variant="contained"
                  disableElevation
                  color="primary"
                  sx={{ marginLeft: "16px" }}
                >
                  Submit
                </BaseButton>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </Dropzone>
    </>
  );
}
