export default {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        padding: "14px 20px 14px 16px",
        margin: "0",
      },
    },
  },
};
