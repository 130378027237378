// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import * as GaussianSplats3D from "@interaptix/gsv";
import * as THREE from "three";
import { Points } from "three";

import { COLOR, frustumGeometryLandscape, textureEncoding } from "../Constants";
import { frustumGeometryPortrait, RenderLayer } from "../Constants";
import { CameraObject } from "../types";
import { DeviceCameraInfo, defaultCamera } from "hooks/useDeviceCamera";
import { Matrix4 } from "services/ContentServer/Audit";
import { Device } from "services/ContentServer/Audit/serviceTypes/Device";
import { MediaCapture } from "services/ContentServer/Audit/serviceTypes/MediaCapture";
import { TexturingImageCapture } from "services/ContentServer/Audit/serviceTypes/TexturingImageCapture";
import { BoxExtent } from "services/ContentServer/Audit/serviceTypes/Touchup";
import { CaptureType, poselessKey, PoseType } from "services/ContentServer/Audit/types";
import { DrawingPlane } from "utils/Drawing";
import { poseMatrixToPositionVector, poseMatrixToRotationMatrix } from "utils/MathUtils";
import { sortObjects } from "utils/SortingUtils";
import { getCameraObjectScale } from "utils/Three/CameraObjectUtils";
import { getModels, SegmentData } from "utils/Three/useModels";

export const CaptureColors = new Map<string, string>([
  [PoseType.Request, COLOR.CYAN],
  [PoseType.Manual, COLOR.PURPLE],
  [PoseType.Template, COLOR.ORANGE],
  [PoseType.Automated, COLOR.YELLOW],
  [PoseType.Deleted, COLOR.DARK_GREY],
  [poselessKey, COLOR.GREEN],
]);

const CAPTURE_POINT_INCLUSION_RATIO = 0.5;

enum VectorComponent {
  X = 0,
  Y = 1,
  Z = 2,
}

enum Extent {
  min = 0,
  max = 1,
}
interface BoxBounds {
  max: THREE.Vector3;
  min: THREE.Vector3;
}

export interface SplatIntersection {
  point: THREE.Vector3;
  distance: number;
  object: GaussianSplats3D.SplatMesh;
  normal: THREE.Vector3;
}

export const setLayers = (object: THREE.Object3D | THREE.Raycaster, layer: RenderLayer) => {
  object.layers.enable(layer);
  object.layers.set(layer);
};

export const addGridAxes = (scene: THREE.Scene) => {
  const gridHelperXZ = new THREE.GridHelper(
    500,
    500,
    new THREE.Color(COLOR.DARK_GREY),
    new THREE.Color(COLOR.DARK_GREY)
  );
  (gridHelperXZ.material as THREE.Material).transparent = true;
  (gridHelperXZ.material as THREE.Material).opacity = 0.4;
  gridHelperXZ.name = "GridHelper";
  const axesHelper = new THREE.AxesHelper(1);
  axesHelper.name = "AxesHelper";
  setLayers(axesHelper, RenderLayer.SELECTOR_LAYER);
  setLayers(gridHelperXZ, RenderLayer.SELECTOR_LAYER);
  scene.add(gridHelperXZ);
  scene.add(axesHelper);
  return gridHelperXZ;
};

export const threeMatrix4ToMatrix4 = (matrix: THREE.Matrix4) => {
  const pose: Matrix4 = {
    m00: matrix.elements[0],
    m01: matrix.elements[4],
    m02: matrix.elements[8],
    m03: matrix.elements[12],
    m10: matrix.elements[1],
    m11: matrix.elements[5],
    m12: matrix.elements[9],
    m13: matrix.elements[13],
    m20: matrix.elements[2],
    m21: matrix.elements[6],
    m22: matrix.elements[10],
    m23: matrix.elements[14],
    m30: matrix.elements[3],
    m31: matrix.elements[7],
    m32: matrix.elements[11],
    m33: matrix.elements[15],
  };
  return pose;
};

export const Matrix4ToThreeMatrix4 = (matrix: Matrix4) => {
  const pose = new THREE.Matrix4();
  pose.set(
    matrix.m00,
    matrix.m01,
    matrix.m02,
    matrix.m03,
    matrix.m10,
    matrix.m11,
    matrix.m12,
    matrix.m13,
    matrix.m20,
    matrix.m21,
    matrix.m22,
    matrix.m23,
    matrix.m30,
    matrix.m31,
    matrix.m32,
    matrix.m33
  );
  return pose;
};

export const getMaterial = (poseType: PoseType) => {
  const newMaterial = new THREE.MeshBasicMaterial({
    color: CaptureColors.get(poseType),
    opacity: 0.5,
    transparent: true,
    side: THREE.DoubleSide,
  });
  return newMaterial;
};

export const equals = (u: THREE.Vector3 | THREE.Euler, v: THREE.Vector3 | THREE.Euler, epsilon = Number.EPSILON) => {
  return Math.abs(v.x - u.x) < epsilon && Math.abs(v.y - u.y) < epsilon && Math.abs(v.z - u.z) < epsilon;
};

export const hasSamePose = (poseList: THREE.Mesh[], camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) => {
  return poseList.some(
    (pose) =>
      equals(pose.position, camera.position, 0.001 * camera.position.length()) &&
      equals(pose.rotation, camera.rotation, 0.001 * camera.position.length())
  );
};

export const canvasToCameraCoord = (x: number, y: number, width: number, height: number) => {
  const xProj = (2 * x) / width - 1;
  const yProj = (-2 * y) / height + 1;
  return new THREE.Vector2(xProj, yProj);
};

export const cameraToCanvasCoord = (xProj: number, yProj: number, width: number, height: number) => {
  const x = (width * (xProj + 1)) / 2;
  const y = (height * (1 - yProj)) / 2;
  return { x: x, y: y };
};

export const setRay = (
  position: { x: number; y: number },
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
  canvas?: HTMLCanvasElement | HTMLDivElement | null,
  near?: number
) => {
  const ray = new THREE.Raycaster(undefined, undefined, near);
  if (canvas) {
    const rect = canvas.getBoundingClientRect();
    const width = rect.right - rect.left;
    const height = rect.bottom - rect.top;

    ray.setFromCamera(canvasToCameraCoord(position.x - rect.left, position.y - rect.top, width, height), camera);
  }
  return ray;
};

export const getDeviceInfo = (projectionMatrix: Matrix4) => {
  return {
    projectionMatrix: projectionMatrix,
    aspectRatio: projectionMatrix.m11 / projectionMatrix.m00,
    cx: projectionMatrix.m02,
    cy: projectionMatrix.m12,
  } as DeviceCameraInfo;
};

export const createCameraObject = (
  image: MediaCapture,
  maxObjectWidth?: number,
  isPLYOrSpatial?: boolean,
  inputCamera?: boolean,
  device?: Device
) => {
  const deviceInfo = device && device.projectionMatrix ? getDeviceInfo(device.projectionMatrix) : undefined;
  const geometry =
    (image.rotate ?? 0) % 2 === 0
      ? frustumGeometryLandscape(deviceInfo ? deviceInfo : defaultCamera)
      : frustumGeometryPortrait(deviceInfo ? deviceInfo : defaultCamera);
  const object = new THREE.Mesh(
    geometry,
    new THREE.MeshBasicMaterial({
      polygonOffset: true,
      polygonOffsetFactor: 1, // positive value pushes polygon further away
      polygonOffsetUnits: 1,
      wireframe: inputCamera,
    })
  );
  object.renderOrder = 1;
  // Scale
  const cameraObjScale = getCameraObjectScale(maxObjectWidth, isPLYOrSpatial);
  if (inputCamera) {
    object.scale.set(0.1, 0.1, 0.1);
  } else {
    object.scale.set(cameraObjScale, cameraObjScale, cameraObjScale);
  }

  if (image.pose) {
    transformObject(object, image.pose);
  }
  object.rotation.z += (image.rotate ?? 0) * (Math.PI / 2);

  // Layer
  setLayers(object, RenderLayer.SELECTOR_LAYER);

  // wireframe
  const geo = new THREE.EdgesGeometry(object.geometry);
  const mat = new THREE.LineBasicMaterial({ color: inputCamera ? 0xffff000 : 0xffffff, transparent: true });
  const wireframe = new THREE.LineSegments(geo, mat);
  wireframe.renderOrder = 1;
  setLayers(wireframe, RenderLayer.SELECTOR_LAYER);
  object.add(wireframe);

  const cameraObject = {
    object: object,
    wireframe: wireframe,
    id: image.id ? image.id : "",
    poseType: image.poseType ? image.poseType : PoseType.Manual,
  };

  return cameraObject;
};

export const transformObject = (object: THREE.Object3D | THREE.Group, transform: Matrix4, useScale = false) => {
  // Translation
  const pos = poseMatrixToPositionVector(transform);
  object.position.set(pos[0], pos[1], pos[2]);
  // Rotation
  const rot = poseMatrixToRotationMatrix(transform);
  const m = new THREE.Matrix4();
  m.set(
    rot[0][0],
    rot[0][1],
    rot[0][2],
    0,
    rot[1][0],
    rot[1][1],
    rot[1][2],
    0,
    rot[2][0],
    rot[2][1],
    rot[2][2],
    0,
    0,
    0,
    0,
    1
  );
  object.rotation.setFromRotationMatrix(m);
  if (useScale) object.scale.set(transform.m33, transform.m33, transform.m33);
  object.updateMatrixWorld();
};

export const transformPoint = (point: THREE.Vector3, transform: Matrix4) => {
  // Translation
  const pos = poseMatrixToPositionVector(transform);
  const p = new THREE.Vector3(pos[0], pos[1], pos[2]);
  // Rotation
  const rot = poseMatrixToRotationMatrix(transform);
  const m = new THREE.Matrix4();
  m.set(
    rot[0][0],
    rot[0][1],
    rot[0][2],
    0,
    rot[1][0],
    rot[1][1],
    rot[1][2],
    0,
    rot[2][0],
    rot[2][1],
    rot[2][2],
    0,
    0,
    0,
    0,
    1
  );
  point.applyMatrix4(m);
  point.add(p);
};

export const createAutoCaptureCameras = (
  images: MediaCapture[],
  contentParent: THREE.Group,
  getCameraDataWithOrientation: (rotate?: number | null) => {
    aspectRatio: number;
    verticalCameraFOV: number;
    cx: number;
    cy: number;
    projectionMatrix: Matrix4;
  }
) => {
  const texturedImageCameras: { [index: string]: THREE.PerspectiveCamera | THREE.OrthographicCamera } = {};
  images.forEach((image) => {
    const camera = getCameraDataWithOrientation(image.rotate);
    const currCamera = new THREE.PerspectiveCamera(camera.verticalCameraFOV, camera.aspectRatio, 0.001, 1000);

    if (image.pose) {
      transformObject(currCamera, image.pose);

      currCamera.rotation.z += ((image.rotate ?? 0) * Math.PI) / 2;
      currCamera.projectionMatrix.copy(Matrix4ToThreeMatrix4(camera.projectionMatrix));
      currCamera.projectionMatrixInverse.copy(currCamera.projectionMatrix).invert();
    }

    if (image.id) texturedImageCameras[image.id] = currCamera;

    setLayers(currCamera, RenderLayer.FRUSTUM_LAYER);
    contentParent.add(currCamera);
  });
  return texturedImageCameras;
};

export const createAutoCaptureCamera = (
  contentParent: THREE.Group,
  getCameraDataWithOrientation: (rotate?: number | null, device?: string | null) => DeviceCameraInfo,
  device?: string | null
) => {
  const camera = getCameraDataWithOrientation(0, device);
  const autoCaptureCamera = new THREE.PerspectiveCamera(camera.verticalCameraFOV, camera.aspectRatio, 0.001, 1000);
  autoCaptureCamera.projectionMatrix.copy(Matrix4ToThreeMatrix4(camera.projectionMatrix));
  autoCaptureCamera.projectionMatrixInverse.copy(autoCaptureCamera.projectionMatrix).invert();
  contentParent.add(autoCaptureCamera);
  setLayers(autoCaptureCamera, RenderLayer.FRUSTUM_LAYER);
  return autoCaptureCamera;
};

export const setFrustumPosition = (
  object: THREE.Mesh,
  wireFrustum: THREE.LineSegments,
  frustumCamera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
  traverserCamera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
  cameraParent: THREE.Group
) => {
  wireFrustum.position.copy(object.position);
  wireFrustum.rotation.copy(object.rotation);
  wireFrustum.scale.set(0.5, 0.5, 0.5);

  frustumCamera.position.copy(object.position);
  frustumCamera.rotation.copy(object.rotation);

  traverserCamera.position.copy(object.position);
  traverserCamera.rotation.copy(object.rotation);

  cameraParent.add(traverserCamera);
  cameraParent.add(wireFrustum);
};

export const getSelectorPlane = (
  selectedImageObject: MediaCapture | undefined,
  isObject: boolean,
  wireFrustum: THREE.LineSegments
) => {
  if (selectedImageObject !== undefined && selectedImageObject.captureType !== CaptureType.Video) {
    const wireFrustumClone = wireFrustum.clone();
    wireFrustumClone.updateMatrixWorld();
    const attribute = (wireFrustum.geometry as THREE.BufferGeometry).attributes.position as THREE.BufferAttribute;
    return new DrawingPlane(
      wireFrustumClone.localToWorld(new THREE.Vector3().fromBufferAttribute(attribute, 11)).toArray(),
      wireFrustumClone.localToWorld(new THREE.Vector3().fromBufferAttribute(attribute, 10)).toArray(),
      wireFrustumClone.localToWorld(new THREE.Vector3().fromBufferAttribute(attribute, 0)).toArray()
    );
  }
  return new DrawingPlane();
};

export const updateMaterialEncoding = (mats: THREE.Material[]) => {
  mats.forEach((mat) => {
    if (
      mat instanceof THREE.MeshLambertMaterial ||
      mat instanceof THREE.MeshStandardMaterial ||
      mat instanceof THREE.MeshPhongMaterial
    ) {
      if (mat.map) {
        mat.map.encoding = textureEncoding;
        mat.map.magFilter = THREE.NearestFilter;
        mat.map.minFilter = THREE.NearestFilter;
      }
      if (mat.emissiveMap) {
        mat.emissiveMap.encoding = textureEncoding;
        mat.emissive = new THREE.Color(0xffffff);
      }
      if (mat instanceof THREE.MeshStandardMaterial) {
        if (mat.metalness >= 1) {
          mat.metalness = 0.98;
        }
      }
      mat.needsUpdate = true;
    }
  });
  return mats.length > 1 ? mats : mats[0];
};

export const setSegmentOpacity = (segment: THREE.Mesh | THREE.Group, opacity: number) => {
  if (segment instanceof THREE.Mesh) {
    setMeshOpacity(segment, opacity);
  } else {
    segment.children.forEach((child) => {
      if (child instanceof THREE.Mesh) {
        setMeshOpacity(child, opacity);
      }
    });
  }
};

const setMeshOpacity = (object3D: THREE.Mesh, opacity: number) => {
  if (Array.isArray(object3D.material)) {
    (object3D.material as THREE.Material[]).forEach((material) => {
      material.transparent = opacity === 1 ? false : true;
      material.opacity = opacity;
    });
  } else {
    (object3D.material as THREE.Material).transparent = opacity === 1 ? false : true;
    (object3D.material as THREE.Material).opacity = opacity;
  }
};

export const getMeshOpacity = (object3D: THREE.Mesh) => {
  if (Array.isArray(object3D.material)) {
    return (object3D.material as THREE.Material[])[0].opacity;
  } else {
    return (object3D.material as THREE.Material).opacity;
  }
};
export const setBoxLineWidth = (object3D: THREE.BoxHelper, width: number) => {
  if (Array.isArray(object3D.material)) {
    (object3D.material as THREE.LineBasicMaterial[]).forEach((material) => (material.linewidth = width));
  } else {
    (object3D.material as THREE.LineBasicMaterial).linewidth = width;
  }
};

export const getNearestCameraObjectIntersectionAt = (
  objects: CameraObject[],
  ray: THREE.Raycaster,
  recursive = true
) => {
  const intersects = ray.intersectObjects(
    objects.map((cam) => cam.object),
    recursive
  );

  if (intersects.length) {
    const sorted = intersects.sort((a, b) => sortObjects(a, b, "distance"));
    const cameraObj = objects.find((cam) => cam.object === sorted[0].object);
    return cameraObj;
  }
  return undefined;
};

export const getModelIntersectionAtRay = (
  snapModelData: SegmentData[] | SegmentData[][],
  scene: THREE.Scene,
  raycaster: THREE.Raycaster,
  additionalIntersections: THREE.Intersection[] = [],
  ignorePly = false
) => {
  const models = getModels(snapModelData, scene);
  let intersections: THREE.Intersection[] = [];
  let objs: THREE.Object3D[] = [];
  models.forEach((model) => {
    objs = objs.concat(model ? model.children : []);
  });
  raycaster.firstHitOnly = true;
  intersections = raycaster.intersectObjects(objs, true);
  intersections = intersections.concat(additionalIntersections);
  if (ignorePly) {
    for (let i = 0; i < intersections.length; i++) {
      if (intersections[i].object instanceof Points) {
        continue;
      }

      return intersections[i];
    }
    return undefined;
  } else {
    return intersections.length ? intersections[0] : undefined;
  }
};

export const createColorBox = (box: THREE.Box3, name = "", color = new THREE.Color(COLOR.ORANGE)) => {
  const boxSize = box.getSize(new THREE.Vector3());
  const geometry = new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z).toNonIndexed();
  const material = new THREE.MeshBasicMaterial({
    vertexColors: true,
    wireframe: false,
    opacity: 0.3,
    transparent: true,
  });

  const positionAttribute = geometry.getAttribute("position");

  const colors = [];

  for (let i = 0; i < positionAttribute.count; i += 3) {
    colors.push(color.r, color.g, color.b);
    colors.push(color.r, color.g, color.b);
    colors.push(color.r, color.g, color.b);
  }

  geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

  const mesh = new THREE.Mesh(geometry, material);
  const center = new THREE.Vector3();
  box.getCenter(center);
  mesh.position.set(center.x, center.y, center.z);
  mesh.name = name;
  return mesh;
};

export const createColorBoxFromExtentAndOffset = (
  offset: Matrix4,
  extent: BoxExtent,
  name: string,
  color: THREE.Color
) => {
  const position = new THREE.Vector3(offset.m03, offset.m13, offset.m23);
  const halfExtent = new THREE.Vector3(extent.x, extent.y, extent.z).divideScalar(2.0);
  const min = position.clone().sub(halfExtent);
  const max = position.clone().add(halfExtent);

  const box3 = new THREE.Box3(min, max);
  return createColorBox(box3, name, color);
};

const compareOnAxis = (a: THREE.Vector3, b: THREE.Vector3, component: VectorComponent, extent: Extent) => {
  const aArr = a.toArray();
  const bArr = b.toArray();
  if (extent === Extent.max) {
    return aArr[component] >= bArr[component] ? a : b;
  } else {
    return aArr[component] < bArr[component] ? a : b;
  }
};

const boundsWithMinExtent = ({ min, max, extent }: { min: number; max: number; extent: number }) => {
  if (max - min >= extent) {
    return { max: max, min: min };
  }
  const mid = (max + min) / 2;
  return { max: mid + extent / 2, min: mid - extent / 2 };
};

export const getBoxBoundsFromPoints = (points: THREE.Vector3[], minExtent: number | undefined): BoxBounds => {
  const components = Object.values(VectorComponent);
  const min: number[] = [];
  const max: number[] = [];

  components.forEach((component) => {
    if (typeof component !== "string") {
      const minOnComp = points.reduce((prev, current) => compareOnAxis(prev, current, component, Extent.min)).toArray()[
        component
      ];
      const maxOnComp = points.reduce((prev, current) => compareOnAxis(prev, current, component, Extent.max)).toArray()[
        component
      ];
      if (minExtent) {
        const boundsOnComp = boundsWithMinExtent({ min: minOnComp, max: maxOnComp, extent: minExtent });
        min.push(boundsOnComp.min);
        max.push(boundsOnComp.max);
      } else {
        min.push(minOnComp);
        min.push(maxOnComp);
      }
    }
  });

  const minVec = new THREE.Vector3().fromArray(min);
  const maxVec = new THREE.Vector3().fromArray(max);

  return { min: minVec, max: maxVec } as BoxBounds;
};

export const createSphereAtPoint = (
  radius: number,
  point: THREE.Vector3,
  contentParent: THREE.Group | THREE.Scene,
  color: COLOR = COLOR.ORANGE
) => {
  const geometry = new THREE.SphereGeometry(radius);
  const material = new THREE.MeshBasicMaterial({
    color: color,
    side: THREE.DoubleSide,
    transparent: true,
  });
  const sphere = new THREE.Mesh(geometry, material);
  sphere.renderOrder = 1;
  const invMatrix = new THREE.Matrix4();
  const matrix = invMatrix.copy(contentParent.matrixWorld).invert();
  const pointClone = point.clone();
  pointClone.applyMatrix4(matrix);
  sphere.position.set(pointClone.x, pointClone.y, pointClone.z);
  contentParent.add(sphere);
  return sphere;
};

export const createTransformMatrix = (
  translation = new THREE.Vector3(),
  quaternion = new THREE.Quaternion().identity(),
  scale = new THREE.Vector3(1.0, 1.0, 1.0)
): Matrix4 => {
  const transform = new THREE.Matrix4().compose(translation, quaternion, scale);
  const transformMatrix4 = {
    m00: transform.elements[0],
    m01: transform.elements[4],
    m02: transform.elements[8],
    m03: transform.elements[12],
    m10: transform.elements[1],
    m11: transform.elements[5],
    m12: transform.elements[9],
    m13: transform.elements[13],
    m20: transform.elements[2],
    m21: transform.elements[6],
    m22: transform.elements[10],
    m23: transform.elements[14],
    m30: transform.elements[3],
    m31: transform.elements[7],
    m32: transform.elements[11],
    m33: transform.elements[15],
  };

  return transformMatrix4;
};

export const getImageDataAtModelIntersection = (
  models: (THREE.Object3D | undefined)[],
  modelIntersection: THREE.Intersection,
  autoCaptureImages: TexturingImageCapture[],
  maxDistance: number,
  projectionThreshold: number,
  contentParent: THREE.Group,
  getCameraInstrinsics: (rotate?: number | null) => DeviceCameraInfo
): {
  id: string;
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
  distance: number;
  visibilityInv: number;
  pointOnImage: { x: number; y: number };
}[] => {
  const autoCaptures: {
    id: string;
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    distance: number;
    visibilityInv: number;
    pointOnImage: { x: number; y: number };
  }[] = [];

  const firstIntersection = modelIntersection;
  if (autoCaptureImages) {
    for (let idx = 0; idx < autoCaptureImages.length; idx++) {
      const autoCaptureImage = autoCaptureImages[idx];
      const device = autoCaptureImage.device;
      const camera = createAutoCaptureCamera(contentParent, getCameraInstrinsics, device);
      if (autoCaptureImage.id) {
        if (autoCaptureImage.pose) {
          transformObject(camera, autoCaptureImage.pose);
          camera.updateMatrixWorld();
        }
        const targetPosition = firstIntersection.point;
        const distance = targetPosition.distanceTo(camera.position);
        if (distance > maxDistance) {
          continue;
        }
        const projection = targetPosition.clone().project(camera);
        if (
          projection.x > -CAPTURE_POINT_INCLUSION_RATIO &&
          projection.x < CAPTURE_POINT_INCLUSION_RATIO &&
          projection.y > -CAPTURE_POINT_INCLUSION_RATIO &&
          projection.y < CAPTURE_POINT_INCLUSION_RATIO
        ) {
          const ndcXY = new THREE.Vector2(projection.x, projection.y);
          const visibilityInv = ndcXY.length();
          const raycaster = new THREE.Raycaster();
          raycaster.setFromCamera(new THREE.Vector2(projection.x, projection.y), camera);
          setLayers(raycaster, RenderLayer.FRUSTUM_LAYER);
          let intersections: THREE.Intersection[] = [];
          models.forEach((model) => {
            intersections = intersections.concat(raycaster.intersectObjects(model ? model.children : [], true));
          });
          intersections.sort((a, b) => sortObjects(a, b, "distance"));
          if (intersections.length > 0 && targetPosition.distanceTo(intersections[0].point) < projectionThreshold) {
            let pointOnImage: { x: number; y: number };
            switch (autoCaptureImage.rotate) {
              case 1:
                pointOnImage = { x: projection.y, y: -projection.x };
                break;
              case 2:
                pointOnImage = { x: -projection.x, y: -projection.y };
                break;
              case 3:
                pointOnImage = { x: -projection.y, y: projection.x };
                break;
              default:
                pointOnImage = { x: projection.x, y: projection.y };
            }
            autoCaptures.push({
              id: autoCaptureImage.id,
              distance: distance,
              visibilityInv: visibilityInv,
              camera: camera.clone(),
              pointOnImage: pointOnImage,
            });
          }
        }
      }
      contentParent.remove(camera);
    }
  }
  return autoCaptures;
};

export const getMaxObjectDim = (modelBoundingBox: THREE.Box3 | null) => {
  return modelBoundingBox
    ? Math.sqrt(
        (modelBoundingBox.max.x - modelBoundingBox.min.x) ** 2 + (modelBoundingBox.max.z - modelBoundingBox.min.z) ** 2
      )
    : 0;
};

export const toggleVisibility = (
  cameraObjects: CameraObject[],
  wireFrustum: THREE.LineSegments,
  showPoses: boolean
) => {
  cameraObjects.forEach((camObj) => {
    camObj.object.visible = showPoses;
  });
  wireFrustum.visible = showPoses;
};
