export const ENDPOINTS = {
  ANNOTATION: "/api/annotations/",
  ARROW: "/api/inspectionannotation/arrowannotation/",
  ASSETS: "/api/assets/",
  ASSET_INFOS: "/api/assetinfos/",
  CAPTURE_HISTORY: "/api/capturehistory/",
  DIFFERENCE: "/api/difference/calculate/",
  DRAWING: "/api/inspectionannotation/drawingannotation/",
  DEVICE: "/api/devices/",
  FACILITY: "/api/facilities/",
  MEDIA_CAPTURE: "/api/mediaposes/",
  JOB_DATA: "/api/uploadjobdata/",
  MANUFACTURERS: "/api/manufacturers/",
  MESH_GEN: "/api/texturing/meshgen/",
  RESCAN: "/api/rescan/",
  SNAPSHOTS: "/api/snapshots/",
  READ_ONLY_SNAPSHOTS: "/api/snapshots/read/",
  SNAPSHOT_COLLECTIONS: "/api/snapshotcollection/",
  SUPPLIERS: "/api/suppliers/",
  TEXTURING_IMAGES: "/api/texturingimagecapture/",
  WORLDS: "/api/worlds/",
  LABELS: "/api/labels/",
  LABEL_GROUPS: "/api/labelgroups/",
  BULK_CHECKLIST_CREATION: "/api/bulkchecklistcreation/",
  ANNOTATION_TYPES: "/api/annotationtypes/",
  REQUEST_IMPORT: "/api/requestimport/",
  IMPORT_STATUS: "/api/imports/",
  PANORAMAS: "/api/panoramas/",
  TASK_PROGRESS: "/api/taskprogress/",
  ICE_SERVERS: "/api/iceservers/",
  OBJECT_PERMISSIONS: "/api/objectpermissions/",
  ALIGN_SNAPSHOTS: "/api/transform/compute/",
  TOUCHUP: "/api/touchups/",
};

export const LOCK_ENDPOINTS = {
  LOCK_MODEL: "lock_model/",
  UNLOCK_MODEL: "unlock_model/",
};

export const ARCHIVE_ENDPOINTS = {
  ARCHIVE: "archive/",
  UNARCHIVE: "unarchive/",
};

export const INVITE_KEY_ENDPOINTS = {
  GENERATE: "anonymous_access_key_generate/",
  CLEAR: "clear_access_key/",
};
