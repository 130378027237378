import { SnackbarNames, SnackbarOptions } from "./Constants";

export const getSnackbarMessage = (option: SnackbarOptions, name: SnackbarNames): string => {
  switch (option) {
    case SnackbarOptions.OBJECT_SUBMITTED:
      switch (name) {
        case SnackbarNames.ANNOTATION_DELETION_SUCCESS:
        case SnackbarNames.INSPECTION_DELETION_SUCCESS:
        case SnackbarNames.ARROW_DELETION_SUCCESS:
        case SnackbarNames.SNAPSHOT_LOADING:
        case SnackbarNames.DIFFERENCE_CALCULATION_SUCCESS:
          return name;
        default:
          return `${name} successfully submitted`;
      }
    case SnackbarOptions.OBJECT_ERROR:
      switch (name) {
        case SnackbarNames.INSPECTION_DELETION_ERROR:
        case SnackbarNames.ANNOTATION_DELETION_ERROR:
        case SnackbarNames.ARROW_DELETION_ERROR:
        case SnackbarNames.ARROW_EDITING_ERROR:
          return name;
        default:
          return `Error submitting ${name}`;
      }
    default:
      return "";
  }
};
