import { Point2D } from "../Audit/types";
import { SingleSite, SitesDict, SVGMapInterface } from "./types";
import { SiteState } from "views/Sites/SiteManagement/siteReducer";

export const getMapDataFromJson = (jsonObj: any): SVGMapInterface => {
  const xMin = jsonObj.x_min as number;
  const yMin = jsonObj.y_min as number;
  const xMax = jsonObj.x_max as number;
  const yMax = jsonObj.y_max as number;
  const mapScale = jsonObj.map_scale as number;
  const fileUrl = jsonObj.map_file as string;

  return {
    bounds: {
      xMin: xMin,
      yMin: yMin,
      xMax: xMax,
      yMax: yMax,
    },
    mapScale: mapScale,
    fileUrl: fileUrl,
  };
};

export const getMapSitesList = (jsonObj: any): Set<string> => {
  const sitesList = new Set<string>();

  jsonObj.forEach((obj: any) => {
    sitesList.add(obj.site_id);
  });
  return sitesList;
};

export const deserializeSites = (obj: any) => {
  const sites: SitesDict = {};

  for (let i = 0; obj && i < obj.length; i++) {
    const site: SingleSite = deserializeSite(obj[i]);
    sites[site.id] = site;
  }
  return sites;
};

export const deserializeSite = (obj: any) => {
  const site = {} as SingleSite;
  site.id = obj.id;
  site.name = obj.name;
  site.company = obj.company;
  site.address = obj.address;
  site.country = obj.country;
  site.region = obj.region;
  site.zipPostalCode = obj.zip_postal_code;
  site.active = obj.status;
  site.keyContact = obj.contact_id;
  site.createdBy = obj.created_by_id;
  site.createdDate = new Date(obj.created_on);
  return site;
};

export const serializeSiteData = (site: SiteState) => {
  return {
    name: site.name,
    company: site.company,
    address: site.address,
    country: site.country.data,
    region: site.region.data,
    zip_postal_code: site.zipPostalCode,
    contact_id: site.keyContact.data === "None" ? "" : site.keyContact.data,
    status: true,
  };
};

export const serializeMap = (
  minPoint: Point2D,
  maxPoint: Point2D,
  mapScale: number,
  mapFile: File | null,
  siteId: string
) => {
  return {
    x_min: minPoint[0],
    y_min: minPoint[1],
    x_max: maxPoint[0],
    y_max: maxPoint[1],
    map_scale: mapScale,
    map_file: mapFile,
    site_id: siteId,
  };
};
