import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Box, Checkbox, Dialog, FormControl, TableHead, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useGetSitesDictQuery } from "redux/api/appApi";
import { useLazyGetEmailInviteValidationQuery } from "redux/api/gql/gqlApi";
import { ITeam } from "redux/api/teamApi";
import { TenantFeatureEnum } from "redux/api/types";
import useApp from "redux/hooks/useApp";
import { mutate } from "swr";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import { FilterPopup } from "components/DalmatianDesignComponents/DropdownFilter";
import LightTooltip from "components/DalmatianDesignComponents/LightTooltip";
import { SearchOption } from "components/DalmatianDesignComponents/SearchBar";
import LoadingDialog from "components/Dialog/LoadingDialog";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { FieldLengthMsg } from "components/Typography/FieldLength";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { UserInviteRequest } from "services/ContentServer/Identity";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { validateEmail } from "utils/StringUtils";

export interface InvRowData {
  id: number;
  tempName: string;
  email: string;
  sites?: string[];
  teams?: string[];
  roles: string[];
  missingNameDisp: string;
  missingEmailDisp: string;
  emailAlreadyExists: string;
  invalidEmail: string;
}

const ButtonCells = ["TEMPORARY DISPLAY NAME", "ROLES"];

const UserInviteDialog = ({
  open,
  setOpen,
  allTeams,
}: {
  open: boolean;
  allTeams: ITeam[] | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const app = useApp();
  const { contentServer } = useContext(RequestContext);
  const [validateUnusedEmail] = useLazyGetEmailInviteValidationQuery();
  const snackbar = useSnackbar();
  const { roles } = useUsers();
  const { data: sitesDict } = useGetSitesDictQuery();
  const [sending, setSending] = useState(false);
  const [applyToAllSites, setApplyToAllSites] = useState(false);
  const [applyToAllTeams, setApplyToAllTeams] = useState(false);
  const [applyToAllRoles, setApplyToAllRoles] = useState(false);
  const [missingFields, setMissingFields] = useState<
    { name: boolean; email: boolean; newUserEmail: boolean; num: number; checkComplete: boolean }[] | null
  >(null);
  const [pendingValidation, setPendingValidation] = useState<boolean>(false);

  const MAX_TEMP_NAME_LENGTH = 128;
  const roleId = useCallback(
    (roleName: string) => {
      const roleId = Object.values(roles).find((role) => role.name === roleName)?.id;
      return roleId;
    },
    [roles]
  );

  const CellWidths = app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
    ? new Map<string, string>([
        ["TEMPORARY DISPLAY NAME", "25%"],
        ["EMAIL", "25%"],
        ["TEAMS", "25%"],
        ["ROLES", "25%"],
      ])
    : new Map<string, string>([
        ["TEMPORARY DISPLAY NAME", "25%"],
        ["EMAIL", "25%"],
        ["SITE PERMISSIONS", "25%"],
        ["ROLES", "25%"],
      ]);

  const memberId = useMemo(() => {
    return roleId("member");
  }, [roleId]);

  const defaultBlankRow = app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
    ? {
        id: 0,
        tempName: "",
        email: "",
        teams: [],
        roles: memberId ? [memberId] : [],
        missingNameDisp: "false",
        missingEmailDisp: "false",
        emailAlreadyExists: "false",
        invalidEmail: "false",
      }
    : {
        id: 0,
        tempName: "",
        email: "",
        sites: [],
        roles: memberId ? [memberId] : [],
        missingNameDisp: "false",
        missingEmailDisp: "false",
        emailAlreadyExists: "false",
        invalidEmail: "false",
      };

  const [invData, setInvData] = useState<InvRowData[]>([defaultBlankRow]);

  const blankInvRow = useCallback(() => {
    return app.isFeatureEnabled(TenantFeatureEnum.AssetV2)
      ? ({
          id: invData[invData.length - 1] ? invData[invData.length - 1].id + 1 : 0,
          tempName: "",
          email: "",
          teams: applyToAllTeams && invData[0] ? invData[0].teams : [],
          roles: applyToAllRoles && invData[0] ? invData[0].roles : memberId ? [memberId] : [],
          missingNameDisp: "false",
          missingEmailDisp: "false",
          invalidEmail: "false",
          emailAlreadyExists: "false",
        } as InvRowData)
      : ({
          id: invData[invData.length - 1] ? invData[invData.length - 1].id + 1 : 0,
          tempName: "",
          email: "",
          sites: applyToAllSites && invData[0] ? invData[0].sites : [],
          roles: applyToAllRoles && invData[0] ? invData[0].roles : memberId ? [memberId] : [],
          missingNameDisp: "false",
          missingEmailDisp: "false",
          invalidEmail: "false",
          emailAlreadyExists: "false",
        } as InvRowData);
  }, [memberId, invData, applyToAllSites, applyToAllRoles, applyToAllTeams, app]);

  const onApplyToAllRolesClick = useCallback(() => {
    setApplyToAllRoles((prev) => {
      if (!prev) {
        setInvData((prevInvData) => {
          const newData: InvRowData[] = [...prevInvData];
          if (newData && newData.length > 0) {
            const newRowData = newData[0];
            const updatedData = newData.map((currRow: InvRowData) => {
              const updatedRow: InvRowData = { ...currRow, roles: newRowData.roles };
              return updatedRow;
            });
            return updatedData;
          }
          return newData;
        });
      }
      return !prev;
    });
  }, []);

  const onApplyToAllTeamsClick = useCallback(() => {
    setApplyToAllTeams((prev) => {
      if (!prev) {
        setInvData((prevInvData) => {
          const newData: InvRowData[] = [...prevInvData];
          if (newData && newData.length > 0) {
            const newRowData = newData[0];
            const updatedData = newData.map((currRow: InvRowData) => {
              const updatedRow: InvRowData = { ...currRow, teams: newRowData.teams };
              return updatedRow;
            });
            return updatedData;
          }
          return newData;
        });
      }
      return !prev;
    });
  }, []);

  const onApplyToAllSitesClick = useCallback(() => {
    setApplyToAllSites((prev) => {
      if (!prev) {
        setInvData((prevInvData) => {
          const newData: InvRowData[] = [...prevInvData];
          if (newData && newData.length > 0) {
            const newRowData = newData[0];
            const updatedData = newData.map((currRow: InvRowData) => {
              const updatedRow: InvRowData = { ...currRow, sites: newRowData.sites };
              return updatedRow;
            });
            return updatedData;
          }
          return newData;
        });
      }
      return !prev;
    });
  }, []);

  const removeRow = useCallback((row: InvRowData) => {
    setInvData((prevInvData) => {
      const updatedData = [...prevInvData].filter((newRow) => row.id !== newRow.id);
      return updatedData;
    });
  }, []);

  const handleChange = useCallback(
    (value: number | string | string[], row: InvRowData, key: keyof InvRowData) => {
      setInvData((prevInvData) => {
        const newData: InvRowData[] = [...prevInvData];
        const updatedData = newData.map((currRow: InvRowData) => {
          if (
            currRow.id == row.id ||
            (applyToAllRoles && key === "roles") ||
            (applyToAllSites && key === "sites") ||
            (applyToAllTeams && key === "teams")
          ) {
            const updatedRow: InvRowData = { ...currRow, [key]: value };
            return updatedRow;
          }
          return currRow;
        });
        return updatedData;
      });
    },
    [applyToAllRoles, applyToAllSites, applyToAllTeams]
  );

  const newRow = useCallback(
    (row: InvRowData) => {
      return (
        <TableRow
          key={row.id}
          style={{
            width: "100%",
            height: "100%",
            border: 0,
            padding: 0,
          }}
        >
          <TableCell
            style={{
              width: "22.5%",
              paddingLeft: 0,
              paddingRight: "2.5%",
              border: 0,
            }}
          >
            <FormControl variant="outlined" required={true} sx={{ width: "100%" }}>
              <FixedHeightTextField
                key={row.id}
                variant="outlined"
                required={true}
                placeholder={"Firstname Lastname"}
                sx={{
                  width: "100%",
                  marginBottom: "2.5%",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor:
                        row["tempName"].length > MAX_TEMP_NAME_LENGTH ? theme.palette.dalmatianDesign.error : "black",
                    },
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor:
                      row["tempName"].length > MAX_TEMP_NAME_LENGTH ? theme.palette.dalmatianDesign.error : "black",
                  },
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event.target.value, row, "tempName")
                }
                error={!row || (row && row["missingNameDisp"] === "true") ? true : false}
              />
            </FormControl>
            <FieldLengthMsg maxLength={MAX_TEMP_NAME_LENGTH} curLength={row["tempName"].length} />
            <RequiredFieldMsg
              style={{ visibility: !row || (row && row["missingNameDisp"] === "true") ? "visible" : "hidden" }}
            />
          </TableCell>
          <TableCell
            style={{
              width: "22.5%",
              paddingLeft: 0,
              paddingRight: "2.5%",
              border: 0,
            }}
          >
            <FormControl variant="outlined" required={true} sx={{ width: "100%" }}>
              <FixedHeightTextField
                variant="outlined"
                required={true}
                placeholder={"email@company.com"}
                sx={{ width: "100%", marginBottom: "7.5%" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event.target.value, row, "email")
                }
                error={
                  !row || (row && row["missingEmailDisp"] === "true")
                    ? true
                    : row["invalidEmail"] === "true"
                    ? true
                    : row["emailAlreadyExists"] === "true"
                    ? true
                    : false
                }
              />
            </FormControl>
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "140%",
                color: theme.palette.dalmatianDesign.error,
                paddingTop: "2%",
                visibility:
                  row["invalidEmail"] === "true" ||
                  row["missingEmailDisp"] === "true" ||
                  row["emailAlreadyExists"] === "true"
                    ? "visible"
                    : "hidden",
              }}
            >
              {row["invalidEmail"] === "true"
                ? "*Invalid Email"
                : row["emailAlreadyExists"] === "true"
                ? "*A user with this email already exists"
                : "*Required Field"}
              .
            </Typography>
          </TableCell>
          {app.isFeatureEnabled(TenantFeatureEnum.AssetV2) ? (
            <TableCell
              style={{ width: "22.5%", paddingLeft: 0, paddingRight: "2.5%", border: 0, paddingBottom: "4.5%" }}
            >
              <FilterPopup
                options={
                  allTeams
                    ? allTeams.map((team) => {
                        return { id: team.id, label: team.name } as SearchOption;
                      })
                    : [{ id: "", label: "" }]
                }
                selectAll
                selectAllText="All Teams"
                placeholderText="Select Teams"
                currentValue={row && row.teams ? row.teams : []}
                setCurrentValue={(newTeams: string[]) => {
                  handleChange(newTeams, row, "teams");
                }}
                outlinedStyle
                disabled={invData[0].id !== row.id ? (applyToAllTeams ? true : false) : false}
              />
            </TableCell>
          ) : (
            <TableCell
              style={{ width: "22.5%", paddingLeft: 0, paddingRight: "2.5%", border: 0, paddingBottom: "4.5%" }}
            >
              <FilterPopup
                options={
                  sitesDict
                    ? Object.values(sitesDict).map((site) => {
                        return { id: site.id, label: site.name } as SearchOption;
                      })
                    : [{ id: "", label: "" }]
                }
                selectAll
                selectAllText="All Sites"
                placeholderText="Select Sites"
                currentValue={row && row.sites ? row.sites : []}
                setCurrentValue={(newSites: string[]) => {
                  handleChange(newSites, row, "sites");
                }}
                outlinedStyle
                disabled={invData[0].id !== row.id ? (applyToAllSites ? true : false) : false}
              />
            </TableCell>
          )}
          <TableCell style={{ width: "25%", paddingLeft: 0, border: 0, paddingBottom: "4.5%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                columnGap: "6%",
              }}
            >
              <FilterPopup
                options={
                  roles
                    ? Object.values(roles).map((role) => {
                        return { id: role.id, label: role.name } as SearchOption;
                      })
                    : [{ id: "", label: "" }]
                }
                selectAll={false}
                selectAllText="All Roles"
                placeholderText="Select Role"
                currentValue={row ? row.roles : []}
                setCurrentValue={(newRoles: string[]) => {
                  handleChange(newRoles, row, "roles");
                }}
                outlinedStyle
                disabled={invData[0].id !== row.id ? (applyToAllRoles ? true : false) : false}
              />
              <HighlightOffIcon onClick={() => removeRow(row)} />
            </div>
          </TableCell>
        </TableRow>
      );
    },
    [
      theme.palette.dalmatianDesign.error,
      app,
      allTeams,
      invData,
      applyToAllTeams,
      sitesDict,
      applyToAllSites,
      roles,
      applyToAllRoles,
      handleChange,
      removeRow,
    ]
  );

  const addInvRow = useCallback(() => {
    setInvData((prevData) => {
      const updatedData = [...prevData, blankInvRow()];
      return updatedData;
    });
  }, [blankInvRow]);

  const handleClose = useCallback(() => {
    const updatedData = [blankInvRow()];
    setInvData(updatedData);
    setOpen(false);
  }, [blankInvRow, setOpen]);

  const handleValidation = useCallback(() => {
    const reset = [...invData];
    reset.forEach((field) => {
      field["missingEmailDisp"] = "false";
      field["missingNameDisp"] = "false";
      field["invalidEmail"] = "false";
      field["emailAlreadyExists"] = "false";
    });
    setInvData(reset);
    setMissingFields(() =>
      invData.map(() => {
        return { name: true, email: true, newUserEmail: true, num: -1, checkComplete: false };
      })
    );
    setPendingValidation(true);
  }, [invData]);

  useEffect(() => {
    if (missingFields !== null && pendingValidation) {
      invData.forEach((inv, i) => {
        const currentField = missingFields[i];
        if (inv.tempName === "" || inv.tempName.length > MAX_TEMP_NAME_LENGTH) {
          currentField.name = false;
        }
        if (inv.email.indexOf(" ") !== -1) {
          inv.email = inv.email.replace(/\s/g, "");
        }
        validateUnusedEmail(inv.email)
          .unwrap()
          .then((resp: { valid: boolean }) => {
            if (inv.email === "" || !validateEmail(inv.email)) {
              currentField.email = false;
            } else if (!resp.valid) {
              currentField.newUserEmail = false;
            }
            if (!currentField.name || !currentField.email || !currentField.newUserEmail) {
              currentField.num = i;
            }
            currentField.checkComplete = true;
            setMissingFields((prev) => {
              if (prev !== null) {
                const tmp = [...prev];
                tmp[i] = currentField;
                return tmp;
              }
              return prev;
            });
          })
          .catch(() => {
            currentField.email = false;
            currentField.num = i;
            currentField.checkComplete = true;
            setMissingFields((prev) => {
              if (prev !== null) {
                const tmp = [...prev];
                tmp[i] = currentField;
                return tmp;
              }
              return prev;
            });
          });
      });
      setPendingValidation(false);
    }
  }, [missingFields, invData, validateUnusedEmail, pendingValidation]);

  const handleSubmit = useCallback(async () => {
    const requestData = invData.map((inv) => {
      return {
        username: inv.tempName,
        email: inv.email,
        roles: inv.roles,
        teams: inv.teams,
        sites: inv.sites,
        postRedeemUrl: `${window.location.origin}/login`,
        additionalClaims: { role: inv.roles.find((role) => role === roleId("admin")) ? "admin" : "member" },
      } as UserInviteRequest;
    });
    try {
      setSending(true);
      await contentServer.identityService.createBatchInvite(requestData);
      handleClose();
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "User invites sent successfully.",
      });
    } catch (err) {
      console.error(err);
      handleClose();
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error sending user invites.",
      });
    }
    mutate([UserInviteSWRKeys.USER_INVITES]);
    setSending(false);
  }, [contentServer.identityService, handleClose, invData, roleId, snackbar]);

  useEffect(() => {
    const checkIncomplete = missingFields?.filter((field) => !field.checkComplete);
    if (missingFields !== null && !pendingValidation && checkIncomplete && checkIncomplete.length === 0) {
      const fieldsWithErrors = missingFields?.filter((field) => field.num > -1);
      if (fieldsWithErrors.length > 0) {
        const updatedData = [...invData];
        fieldsWithErrors.forEach((field) => {
          updatedData[field.num]["missingEmailDisp"] = invData[field.num]["email"].length === 0 ? "true" : "false";
          updatedData[field.num]["missingNameDisp"] =
            !field.name && invData[field.num]["tempName"].length === 0 ? "true" : "false";
          updatedData[field.num]["invalidEmail"] =
            !validateEmail(invData[field.num]["email"]) && invData[field.num]["email"].length > 0 ? "true" : "false";
          updatedData[field.num]["emailAlreadyExists"] = !field.newUserEmail ? "true" : "false";
        });
        setInvData(updatedData);
      } else {
        handleSubmit();
      }
      setMissingFields(null);
    }
  }, [missingFields, invData, pendingValidation, handleSubmit]);

  return (
    <>
      <LoadingDialog processing={missingFields !== null} msg={"Validating invites..."} />
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
            width: "100%",
            height: "65%",
          },
          width: "100%",
        }}
      >
        <DialogTitle
          style={{ width: "100%", padding: "24px 0px 16px 0px", borderBottom: "1px solid rgba(212, 214, 217, 0.7)" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Typography variant="h3">Invite User</Typography>
            <CloseIcon onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent style={{ width: "100%", padding: "0", overflowY: "hidden" }}>
          <LoadingDialog processing={sending} msg={"Sending invites..."} />
          <TableContainer
            sx={{
              height: "100%",
              width: "100%",
              "&::-webkit-scrollbar": {
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#a2a4a6",
                height: "12vh",
                maxHeight: "12vh",
                minHeight: "12vh",
                width: "8px",
                borderRadius: "100px",
              },
            }}
          >
            <Table style={{ width: "100%" }} stickyHeader>
              <TableHead
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                }}
              >
                <TableRow style={{ width: "100%", height: "100%" }}>
                  {Array.from(CellWidths.entries()).map((value) => {
                    const label = value[0];
                    const width = value[1];

                    return (
                      label && (
                        <TableCell
                          key={`Header-${label}`}
                          style={{
                            width: width,
                            paddingLeft: "0px",
                            paddingTop: "0px",
                            paddingBottom: "7px",
                            paddingRight: label === "ROLES" ? "0px" : "2.5%",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 700,
                            fontSize: "12px",
                            lineHeight: "15px",
                            color: "#1C1F22",
                          }}
                        >
                          {ButtonCells.includes(label) ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              {label}
                              {label === "ROLES" ? (
                                <BaseButton
                                  variant="text"
                                  sx={{ width: "50%" }}
                                  startIcon={<AddIcon />}
                                  onClick={addInvRow}
                                >
                                  Add User
                                </BaseButton>
                              ) : (
                                <LightTooltip title="The user will have this display name until they create and update their account information.">
                                  <InfoOutlinedIcon style={{ color: "#555758" }} />
                                </LightTooltip>
                              )}
                            </div>
                          ) : (
                            label
                          )}
                        </TableCell>
                      )
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody style={{ width: "100%", paddingTop: "40px" }}>{invData.map((row) => newRow(row))}</TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Box style={{ padding: 0, gap: "16px", width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Box style={{ display: "flex", justifyContent: "space-between", gap: "24px" }}>
            {app.isFeatureEnabled(TenantFeatureEnum.AssetV2) ? (
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
                <Checkbox onClick={onApplyToAllTeamsClick} checked={applyToAllTeams} />
                <Typography>Apply the same teams for all users</Typography>
                <LightTooltip title="This will apply the same teams as determined by the first row to all users in this list. This will override any other selections in this list. ">
                  <InfoOutlinedIcon style={{ color: "#555758" }} />
                </LightTooltip>
              </Box>
            ) : (
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
                <Checkbox onClick={onApplyToAllSitesClick} checked={applyToAllSites} />
                <Typography>Apply the same site permissions for all users</Typography>
                <LightTooltip title="This will apply the same sites as determined by the first row to all users in this list. This will override any other selections in this list. ">
                  <InfoOutlinedIcon style={{ color: "#555758" }} />
                </LightTooltip>
              </Box>
            )}
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}>
              <Checkbox onClick={onApplyToAllRolesClick} checked={applyToAllRoles} />
              <Typography>Apply the same roles for all users</Typography>
              <LightTooltip title="This will apply the same roles as determined by the first row to all users in this list. This will override any other selections in this list. ">
                <InfoOutlinedIcon style={{ color: "#555758" }} />
              </LightTooltip>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}>
            <BaseButton variant="outlined" onClick={handleClose}>
              Cancel
            </BaseButton>
            <BaseButton variant="contained" onClick={handleValidation}>
              Submit
            </BaseButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default UserInviteDialog;
