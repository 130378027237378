import { ProgressType, ProgressTypeServer } from "../types";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof TaskProgress | undefined } = {
  id: "id",
  progress_state: "state",
  estimated_time: "estimatedTime",
  start_time: "startTime",
  completion_time: "completionTime",
  message: "message",
  percent_complete: "percentComplete",
};

const ProgressTypeServerToView = new Map<ProgressTypeServer, ProgressType>([
  [ProgressTypeServer.PreStart, ProgressType.PreStart],
  [ProgressTypeServer.Processing, ProgressType.Processing],
  [ProgressTypeServer.Error, ProgressType.Error],
  [ProgressTypeServer.Complete, ProgressType.Complete],
]);

export class TaskProgress extends ISerialization {
  public id?: string | null;
  public state?: ProgressType | null;
  public estimatedTime?: string | null;
  public startTime?: any | null;
  public completionTime?: any | null;
  public percentComplete?: number | null;
  public message?: string | null;

  constructor(progress?: TaskProgress) {
    super();
    if (progress) {
      Object.assign(this, progress);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<TaskProgress>(this, serverToView, json);
    this.state = json.progress_state ? ProgressTypeServerToView.get(json.progress_state) : ProgressType.PreStart;
  }

  serialize(): any {
    const request = serializerHelper<TaskProgress>(this, serverToView);
    return request;
  }
}
