export const MESSAGE_TYPE = {
  CLIENT_CONNECTION: "client_connection",
  VIDEO_CALL: "video_call_signal",
  CHAT: "chat",
  ANNOTATION_UPDATE: "annotation_update",
  LABEL_GROUP_UPDATE: "label_group_update",
  LABEL_UPDATE: "labelitem_update",
  MODEL_UPDATE: "model_update",
  DELETE_UPDATE: "delete_update",
  POSE: "pose",
  READ_RECEIPT: "read_receipt",
  TASK_PROGRESS_UPDATE: "taskprogress_update",
  ASSET_UPDATE: "asset_update",
  ASSET_INFO_UPDATE: "assetinfo_update",
  MEDIA_CAPTURE_UPDATE: "mediacapture_update",
  IMPORT_STATUS_UPDATE: "importstatus_update",
  USER_DISABLED: "user_disabled",
  SNAPSHOT_UPDATE: "snapshot_update",
  SITE_UPDATE: "site_update",
  RESCAN_UPDATE: "rescanrequest_update",
  TEAM_UPDATE: "team_update",
  USERPROFILE_UPDATE: "userprofile_update",
};
