import { Button, ButtonProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import React from "react";

interface BaseButtonProps extends ButtonProps {
  deleteButton?: boolean;
}

function But(
  props: BaseButtonProps,
  ref?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null
) {
  const theme = useTheme();
  const { deleteButton, children, variant, ...other } = props;
  return (
    <Button
      ref={ref}
      variant={variant}
      {...other}
      sx={{
        color: variant === "contained" ? "white" : theme.palette.dalmatianDesign.dark,
        backgroundColor: deleteButton
          ? theme.palette.dalmatianDesign.error
          : variant === "contained"
          ? theme.palette.dalmatianDesign.dark
          : "inherit",
      }}
    >
      <div style={{ paddingRight: other.startIcon ? "14px" : undefined }}>{children}</div>
    </Button>
  );
}

const BaseButton = styled(React.forwardRef(But))(({ theme }) => ({
  height: "40px",
  width: "fit-content",
  whiteSpace: "nowrap",
  letterSpacing: "0.0125em",
  borderRadius: "0px",
  boxShadow: "none",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  textTransform: "uppercase",
  ".MuiButton-startIcon": { paddingLeft: "14px" },
  "&:hover": { transition: "none", backgroundColor: theme.palette.dalmatianDesign.accent, borderRadius: 0 },
}));

interface SquareToggleButtonProps extends ButtonProps {
  selected: boolean;
}

export const SquareToggleButton = (props: SquareToggleButtonProps) => {
  const theme = useTheme();
  const { selected, ...rest } = props;
  return (
    <BaseButton
      {...rest}
      variant="contained"
      sx={{
        height: "40px",
        width: "42px",
        color: !selected ? theme.palette.dalmatianDesign.dark : "white",
        backgroundColor: selected ? theme.palette.dalmatianDesign.dark : "white",
        letterSpacing: "1.25px",
      }}
    />
  );
};

export default BaseButton;
