import { ArrowUpward, Refresh, InsertEmoticon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CardActions,
  CardContent,
  ClickAwayListener,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmojiPicker from "emoji-picker-react";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useSWR, { cache } from "swr";
import { v4 as uuidv4 } from "uuid";

import ChatBubble from "./ChatBubble";
import { ChatContext } from "./ChatContext";
import CustomCircularProgress from "components/CustomProgress/CustomCircularProgress";
import { useAuth } from "hooks/useAuth";
import useChat from "hooks/useChat";
import { useNotifyRef } from "hooks/useNotifyRef";
import useUser from "hooks/useUser";
import useUsers from "hooks/useUsers";
import { IChatMessage } from "services/Chat/serializers";
import { MESSAGE_TYPE } from "services/CommServer";
import { ChatSWRKeys } from "services/ContentServer/Chat";
import { useUpdateHandler } from "services/InspectionUpdate/useUpdateHandler";
import { UpdateMessage } from "services/MessageHub";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

const Chat = () => {
  const theme = useTheme();
  const {
    userSelectedId,
    lastMsgs,
    chatBox,
    setChatBox,
    currentMessages,
    setCurrentMessages,
    setChatUserList,
    chatUserList,
    setLastMsgs,
  } = useContext(ChatContext);
  const [message, setMessage] = useState("");
  const { contentServer } = useContext(RequestContext);
  const auth = useAuth();
  const { users } = useUsers();
  const { user } = useUser(auth.user ? auth.user.id : "");
  const { chat } = useChat();
  const [emojiSelectOpen, setEmojiSelectOpen] = useState(false);
  const [messageCardContent, messageCardContentRef] = useNotifyRef<HTMLDivElement | null>();
  const messageFieldRef = useRef<HTMLDivElement | null>(null);
  const [scrollSize, setScrollSize] = useState(0);
  const [fetchedMessages, setFetchedMessages] = useState<IChatMessage[] | undefined>(undefined);
  const [fetchMessageId, setfetchMessageId] = useState("");
  const [fetchPeerId, setfetchPeerId] = useState("");
  const [_, setKeyboardVisible] = useState(false);

  const {
    data: initialChatHistory,
    error: initialMessageError,
    mutate: initialMessageMutate,
    isValidating: isFetchingInitialMessages,
  } = useSWR(
    auth.user ? [ChatSWRKeys.CHAT_HISTORY, auth.user] : null,
    () => (auth.user ? contentServer.chatService.getChatHistory(auth.user.id) : undefined),
    { shouldRetryOnError: false }
  );

  const {
    data: prevChatHistory,
    error: prevMessageError,
    mutate: prevMessageMutate,
    isValidating: isFetchingPrevMessages,
  } = useSWR(
    auth.user && fetchMessageId !== "" ? [ChatSWRKeys.CHAT_HISTORY_EXT, fetchMessageId] : null,
    () =>
      auth.user && fetchMessageId !== "" && !cache.has([ChatSWRKeys.CHAT_HISTORY_EXT, fetchMessageId])
        ? contentServer.chatService.getExtendedHistory(fetchMessageId)
        : undefined,
    { shouldRetryOnError: false }
  );

  const Message = ({ messages }: { messages: IChatMessage[] }) => (
    <>
      {messages.map((message, idx) => (
        <ChatBubble
          key={idx}
          message={message.text}
          sent={message.sender === user?.id}
          timestamp={message.timestamp}
          read={message.read}
        />
      ))}
    </>
  );

  useEffect(() => {
    setFetchedMessages(prevChatHistory);
  }, [prevChatHistory, setFetchedMessages]);

  useEffect(() => {
    if (fetchedMessages) {
      const reachedFirstMessage = fetchedMessages.length === 0;
      const orderedMessages = fetchedMessages.reverse();
      setChatBox((prevChatBox) => {
        prevChatBox[fetchPeerId] = orderedMessages.concat(prevChatBox[fetchPeerId]);
        if (reachedFirstMessage && prevChatBox[fetchPeerId] && prevChatBox[fetchPeerId].length > 0) {
          prevChatBox[fetchPeerId][0].id = "";
        }
        return prevChatBox;
      });
      if (userSelectedId === fetchPeerId) {
        setCurrentMessages((prevMessages) => {
          if (reachedFirstMessage && prevMessages.length > 0) {
            prevMessages[0].id = "";
          }
          return orderedMessages.concat(prevMessages);
        });
      }
    }
  }, [fetchedMessages, fetchPeerId, userSelectedId, setCurrentMessages, setChatBox, setFetchedMessages]);

  useEffect(() => {
    if (initialChatHistory && userSelectedId) {
      contentServer.chatService.readChatMessages(userSelectedId);
    }
  }, [userSelectedId, initialChatHistory, contentServer.chatService]);

  const chatUpdateCallback = useCallback(
    (update: UpdateMessage) => {
      let newCurrentMessages: IChatMessage[] = [];
      setChatBox((prevChatBox) => {
        if (update.receiver && prevChatBox[update.receiver]) {
          prevChatBox[update.receiver] = prevChatBox[update.receiver].map((message) => {
            message.read = true;
            return message;
          });
        }
        if (update.receiver === userSelectedId) {
          newCurrentMessages = prevChatBox[userSelectedId];
        }
        return prevChatBox;
      });
      if (update.receiver === userSelectedId) {
        setCurrentMessages(newCurrentMessages);
      }
    },
    [setChatBox, userSelectedId, setCurrentMessages]
  );

  useUpdateHandler(MESSAGE_TYPE.READ_RECEIPT, chatUpdateCallback);

  useEffect(() => {
    if (messageCardContent) {
      const scrollOffset = messageCardContent.scrollHeight - scrollSize;
      if (scrollOffset > 0) {
        messageCardContent.scrollTop = messageCardContent.scrollTop + scrollOffset;
      }
      setScrollSize(messageCardContent.scrollHeight);
    }
  }, [currentMessages, scrollSize, messageCardContent, setScrollSize]);

  useEffect(() => {
    setCurrentMessages(chatBox[userSelectedId]);
    scrollToBottom(messageCardContent);
  }, [userSelectedId, chatBox, setCurrentMessages, messageCardContent]);

  const handleKeywordKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.key === "Enter") {
      send();
    }
  };

  const scrollToBottom = (element: HTMLDivElement | null) => {
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const fetchPreviousMessages = () => {
    setfetchPeerId(userSelectedId);
    setfetchMessageId(currentMessages[0]?.id ? currentMessages[0].id : "");
  };

  const send = () => {
    if (userSelectedId !== "" && user && message !== "") {
      const messageId = uuidv4();
      chat?.chat(messageId, userSelectedId, message);
      let newMessages: IChatMessage[] = [];
      setChatBox((prevChatBox) => {
        const chatData = {
          id: messageId,
          receiver: userSelectedId,
          sender: user.id,
          text: message,
          timestamp: new Date().toUTCString(),
        };
        if (userSelectedId && !Object.prototype.hasOwnProperty.call(chatBox, userSelectedId)) {
          prevChatBox[userSelectedId] = [chatData];
        } else {
          prevChatBox[userSelectedId] = [...prevChatBox[userSelectedId], chatData];
        }
        newMessages = prevChatBox[userSelectedId];
        return prevChatBox;
      });
      if (newMessages.length !== 0) {
        setCurrentMessages(newMessages);
      }
      setMessage("");
    }
  };

  const updateChatUserList = useCallback(
    (message: IChatMessage, msgUserId: string) => {
      const msgedUser = users.find((u) => u.id === msgUserId);
      const newChatUserList = [...chatUserList];
      const newMsgList = [...lastMsgs];
      if (msgedUser && chatUserList.includes(msgedUser)) {
        const originalIdx = chatUserList.findIndex((u) => u.id === msgUserId);
        newChatUserList.splice(0, 0, newChatUserList.splice(originalIdx, 1)[0]);
        newMsgList.splice(0, 0, newMsgList.splice(originalIdx, 1)[0]);
        newMsgList[0] = message.text;
      } else if (msgedUser) {
        newChatUserList.unshift(msgedUser);
        newMsgList.unshift(message.text);
      }
      setChatUserList(newChatUserList);
      setLastMsgs(newMsgList);
    },
    [chatUserList, lastMsgs, setChatUserList, setLastMsgs, users]
  );

  useEffect(() => {
    if (chat) {
      const unsubscribe = chat.subscribeToChat((message: IChatMessage) => {
        message.timestamp = new Date().toUTCString();
        let newMessages: IChatMessage[] = [];
        if (message.sender !== user?.id) {
          setChatBox((prevChatBox) => {
            if (!Object.prototype.hasOwnProperty.call(chatBox, message.sender)) {
              prevChatBox[message.sender] = [message];
            } else {
              prevChatBox[message.sender] = [...prevChatBox[message.sender], message];
            }
            if (userSelectedId === message.sender) {
              newMessages = prevChatBox[message.sender];
            }
            return prevChatBox;
          });
          if (userSelectedId === message.sender) {
            setCurrentMessages(newMessages);
          }
          updateChatUserList(message, message.sender);
        }
        scrollToBottom(messageCardContent);
      });

      return () => unsubscribe();
    }
  }, [
    chat,
    messageCardContent,
    chatBox,
    contentServer.chatService,
    setChatBox,
    setCurrentMessages,
    user?.id,
    userSelectedId,
    auth.user,
    initialChatHistory,
    setChatUserList,
    lastMsgs,
    setLastMsgs,
    chatUserList,
    users,
    updateChatUserList,
  ]);

  const fetchPrevMessagesBanner = (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: currentMessages.length < 50 ? "0px" : "60px",
      }}
    >
      <Box>
        {isFetchingInitialMessages || (isFetchingPrevMessages && fetchPeerId === userSelectedId) ? (
          currentMessages.length === 0 && chatUserList.find((u) => u.id === userSelectedId) ? (
            <CustomCircularProgress />
          ) : (
            <></>
          )
        ) : initialMessageError || (prevMessageError && fetchPeerId === userSelectedId) ? (
          <Alert
            severity="error"
            action={
              <Button
                startIcon={<Refresh />}
                onClick={() => (initialMessageError ? initialMessageMutate() : prevMessageMutate())}
              >
                RETRY
              </Button>
            }
          >
            Error loading previous messages.
          </Alert>
        ) : (
          currentMessages &&
          currentMessages[0]?.id &&
          currentMessages.length >= 50 && (
            <Button
              variant="contained"
              disableElevation
              onClick={fetchPreviousMessages}
              startIcon={<ArrowUpward sx={{ color: theme.palette.primary.contrastText }} />}
            >
              LOAD PREVIOUS MESSAGES
            </Button>
          )
        )}
      </Box>
    </div>
  );

  return (
    <>
      {emojiSelectOpen && (
        <ClickAwayListener onClickAway={() => setEmojiSelectOpen(false)}>
          <Box sx={{ position: "absolute", bottom: 60, right: 25, zIndex: 100 }}>
            <EmojiPicker
              onEmojiClick={(event, emojiObject) => {
                setMessage((prevState) => prevState + emojiObject.emoji);
                setEmojiSelectOpen(false);
                messageFieldRef.current?.focus();
              }}
            />
          </Box>
        </ClickAwayListener>
      )}
      <CardContent
        sx={{
          position: "absolute",
          top: 60,
          bottom: 53,
          width: "97%",
          margin: "1.5%",
          borderWidth: "medium",
          overflowY: "auto",
          overflowX: "hidden",
          zIndex: 1,
        }}
        ref={messageCardContentRef}
      >
        {fetchPrevMessagesBanner}
        {currentMessages.length !== 0 ? (
          <Message messages={currentMessages} />
        ) : chatUserList.find((u) => u.id === userSelectedId) ? (
          <CustomCircularProgress />
        ) : (
          <Typography variant="h6" style={{ marginTop: 10, textAlign: "center" }}>
            Send a message to start the chat!
          </Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "absolute",
          bottom: 0,
          left: 0,
          height: 60,
          width: "95%",
          marginLeft: 1,
        }}
      >
        <TextField
          id="type-message"
          inputRef={messageFieldRef}
          placeholder="Send a message"
          autoFocus
          variant="standard"
          value={message}
          autoComplete="off"
          onKeyPress={handleKeywordKeyPress}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onFocus={() => setKeyboardVisible(true)}
          onBlur={() => setKeyboardVisible(false)}
          inputProps={{
            style: {
              width: "275px",
              lineHeight: "22.4px",
              fontSize: "16px",
              borderRadius: "3px",
              border: "1.75px solid #D4D6D9",
              height: 25,
              paddingLeft: "10px",
            },
          }}
          InputProps={{ style: { padding: "2%" }, disableUnderline: true }}
        />

        <IconButton
          id="add-emoji"
          onClick={() => {
            setEmojiSelectOpen((prevState) => !prevState);
          }}
          disableRipple
          sx={{
            justifyContent: "center",
            transform: "scale(1.5)",
            marginLeft: "10px",
            color: theme.palette.primary.main,
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <InsertEmoticon />
        </IconButton>
        <IconButton
          id="send-message"
          onClick={send}
          sx={{
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "3px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "100px",

            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
            "&.Mui-disabled": {
              backgroundColor: "#bdbdbd",
              color: "white",
            },
          }}
          disabled={message === ""}
        >
          <ArrowUpward sx={{ color: theme.palette.primary.contrastText }} />
        </IconButton>
      </CardActions>
    </>
  );
};

export default Chat;
