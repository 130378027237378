export function deserializerHelper<T>(self: T, serverToView: { [key: string]: keyof T | undefined }, json: any) {
  Object.entries(serverToView).forEach(([serverField, viewField]) => {
    if (viewField) {
      self[viewField] = json[serverField];
    }
  });
}

export function serializerHelper<T>(self: T, serverToView: { [key: string]: keyof T | undefined }): any {
  const request: any = {};
  Object.entries(serverToView).forEach(([serverField, viewField]) => {
    if (viewField && self[viewField] !== undefined) {
      request[serverField] = self[viewField];
    }
  });
  return request;
}
