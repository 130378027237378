export const CM_IN_M = 100;
export const IN_IN_M = 39.3701;
export const FT_IN_M = 3.28084;
export const M_IN_M = 1;
export const M_IN_FT = 0.3048;

export const MS_IN_SEC = 1000;
export const SEC_IN_MIN = 60;
export const MIN_IN_HOUR = 60;
export const HOUR_IN_DAY = 24;
