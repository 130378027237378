import { TableCell as TableCellMui, TableCellProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";

import useARMediaQuery from "hooks/useARMediaQuery";

interface RowCellProps extends TableCellProps {
  index: number;
  alwaysShow?: boolean;
}

const ARRowCell = ({ index, alwaysShow, children, ...other }: RowCellProps) => {
  const theme = useTheme();
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useARMediaQuery(theme.breakpoints.down("lg"));

  const display = useMemo(() => {
    if (!alwaysShow && ((matchesSM && index >= 1) || (matchesMD && index >= 2) || (matchesLG && index >= 3)))
      return "none";

    return "table-cell";
  }, [matchesMD, matchesSM, matchesLG, alwaysShow, index]);

  const StyledRowCell = styled(TableCellMui)({
    border: 0,
    margin: 0,
    display: display,
  });

  return <StyledRowCell {...other}>{children}</StyledRowCell>;
};

export default ARRowCell;
