import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Annotation } from "../serviceTypes/Annotation";
import { AnnotationFileTypes } from "../serviceTypes/AnnotationTypes";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const AnnotationSWRKeys = {
  SUPERVISION_ANNOTATION: "annotation",
  ANNOTATIONS: "annotations",
  ANNOTATION_FILE_TYPES: "annotationFileTypes",
};

export class AnnotationService extends ServiceBase<Annotation> {
  constructor(apiInstance: APIInstance) {
    super(Annotation, apiInstance, ENDPOINTS.ANNOTATION);
  }
}

export class AnnotationFileTypesService extends ServiceBase<AnnotationFileTypes> {
  constructor(apiInstance: APIInstance) {
    super(AnnotationFileTypes, apiInstance, ENDPOINTS.ANNOTATION_TYPES);
  }
}
