import { useCallback, useContext } from "react";
import { mutate } from "swr";

import useSnackbar, { SnackbarActionType } from "./useSnackbar";
import useUsers from "./useUsers";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { UserInvite } from "services/ContentServer/Identity/serviceTypes/UserInvite";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

export const useInvites = () => {
  const { contentServer } = useContext(RequestContext);
  const { roles } = useUsers();
  const snackbar = useSnackbar();

  const resendInv = useCallback(
    async (invites: UserInvite[] | undefined, userId: string | undefined, email: string) => {
      if (userId && invites) {
        try {
          const prevInv = invites.find((inv) => inv && inv.id === userId);
          const url = `${window.location.origin}/login`;

          if (prevInv) {
            const inv = {
              username: prevInv.username || "",
              reinviteId: prevInv.id || "",
              email: email,
              roles: prevInv.roles || [],
              sites: prevInv.sites || [],
              additionalClaims: {
                role: prevInv?.roles?.find((role) => roles["admin"] && role === roles["admin"].id) ? "admin" : "member",
              },
              postRedeemUrl: url,
            };

            await contentServer.identityService.createBatchInvite([inv]);
            mutate([UserInviteSWRKeys.USER_INVITES]);
            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: "Successfully resent invite.",
            });
          } else {
            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: "Error resending invite.",
            });
          }
        } catch (err) {
          console.error(err);
          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Error resending invite.",
          });
          mutate([UserInviteSWRKeys.USER_INVITES]);
        }
      }
    },
    [contentServer.identityService, snackbar, roles]
  );

  return { resendInv };
};
