import { FileLoader, LoaderUtils } from "three";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";

export class DAELoader extends ColladaLoader {
  load(url: string, onLoad: any, onProgress?: any, onError?: any) {
    /* eslint-disable @typescript-eslint/no-this-alias */
    const scope = this;
    /* eslint-enable @typescript-eslint/no-this-alias */

    let path = scope.path;
    try {
      const urlObject = new URL(url);
      const urlWithNoSas = urlObject.origin + urlObject.pathname;

      path = scope.path === "" ? LoaderUtils.extractUrlBase(urlWithNoSas) : scope.path;
    } catch (e) {
      console.error(e);
    }

    const loader = new FileLoader(scope.manager);
    loader.setPath(scope.path);
    loader.setRequestHeader(scope.requestHeader);
    loader.setWithCredentials(scope.withCredentials);
    loader.load(
      url,
      function (text) {
        try {
          if (text as string) {
            onLoad(scope.parse(text as string, path));
          } else if (text as ArrayBuffer) {
            onLoad(scope.parse((text as ArrayBuffer).toString(), path));
          }
        } catch (e) {
          if (onError) {
            onError(e);
          } else {
            console.error(e);
          }

          scope.manager.itemError(url);
        }
      },
      onProgress,
      onError
    );
  }
}
