import React, { useState, useEffect, useContext, createContext } from "react";

import { useAuth } from "./useAuth";
import { Device } from "services/ContentServer/Identity";
import { WebRTC, IWebRTC } from "services/WebRTC";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

interface IContextProps {
  webRTC: IWebRTC | null;
}

const webRTCContext = createContext({
  webRTC: null,
} as IContextProps);

export const ProvideWebRTC = ({ children }: { children: React.ReactNode }) => {
  const webRTC = useProvideWebRTC();
  return <webRTCContext.Provider value={webRTC}>{children}</webRTCContext.Provider>;
};

export const useWebRTC = () => {
  return useContext(webRTCContext);
};

const useProvideWebRTC = () => {
  const auth = useAuth();
  const [webRTC, setWebRTC] = useState<IWebRTC | null>(null);
  const { iceServers, videoCallHub } = useContext(RequestContext);

  useEffect(() => {
    if (auth.user && iceServers) {
      const webRTC = new WebRTC(videoCallHub, auth.user.id, Device.Desktop, iceServers);
      webRTC.start();

      setWebRTC(webRTC);
      return () => {
        webRTC.stop();
        setWebRTC(null);
      };
    }
  }, [auth.user, videoCallHub, iceServers]);

  return { webRTC };
};

export default useWebRTC;
