import { Avatar, AvatarProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React from "react";

import StatusBadge from "./StatusBadge";
import { User, Status } from "services/ContentServer/Identity";

interface UserAvatarProps extends AvatarProps {
  user: User | null | undefined;
  small?: boolean;
  xsmall?: boolean;
  tooltip?: boolean;
  noBadge?: boolean;
  white?: boolean;
  contrast?: boolean;
}

const UserAvatar = ({
  white = false,
  contrast = false,
  user,
  small = false,
  xsmall = false,
  tooltip = true,
  noBadge = false,
  ...other
}: UserAvatarProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: small ? "scale(0.8)" : "scale(1)",
        width: "100%",
        height: "100%",
      }}
    >
      {noBadge ? (
        <>
          {user?.userProfile?.profilePicture ? (
            <Avatar
              src={user?.userProfile?.profilePicture}
              alt={user?.name}
              sx={{
                width: xsmall ? "25px" : small ? "40px" : "60px",
                height: xsmall ? "25px" : small ? "40px" : "60px",
              }}
              {...other}
            />
          ) : (
            <div
              style={{
                backgroundColor: contrast ? "white" : "transparent",
                width: xsmall ? "25px" : small ? "auto" : "90px",
                height: xsmall ? "25px" : small ? "auto" : "90px",
                display: "flex",
                padding: "10px",
                borderRadius: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UserPlaceholderIcon
                style={{
                  scale: xsmall ? "1" : small ? "1.5" : "3",
                  filter: white
                    ? "invert(100%) sepia(93%) saturate(2%) hue-rotate(256deg) brightness(100%) contrast(100%)"
                    : "none",
                }}
              />
            </div>
          )}
        </>
      ) : (
        <StatusBadge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
          status={user?.status || Status.Inactive}
        >
          {user?.userProfile?.profilePicture ? (
            <div
              style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px" }}
            >
              <Avatar
                src={user.userProfile.profilePicture}
                alt={user?.name}
                sx={{
                  width: xsmall ? "25px" : small ? "40px" : theme.spacing(6),
                  height: xsmall ? "25px" : small ? "40px" : theme.spacing(6),
                }}
                {...other}
              />
            </div>
          ) : (
            <Avatar
              alt={user?.name}
              sx={{
                width: xsmall ? "25px" : small ? "40px" : theme.spacing(6),
                height: xsmall ? "25px" : small ? "40px" : theme.spacing(6),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={{ background: white ? "transparent" : "#ebebeb" }}
              {...other}
            >
              <UserPlaceholderIcon
                style={{
                  scale: "1.3",
                  filter: white
                    ? "invert(100%) sepia(93%) saturate(2%) hue-rotate(256deg) brightness(100%) contrast(100%)"
                    : "none",
                }}
              />
            </Avatar>
          )}
        </StatusBadge>
      )}
    </div>
  );
};

export default UserAvatar;
