import { toLower, startCase } from "lodash";

/**
 * Convert string to Pascal Case
 *
 * @param {string} str - A string (e.g. "HELLO world!")
 * @returns `str` in Pascal Case (e.g. "Hello World!")
 */
export const toPascalCase = (str: string) =>
  str
    .toLowerCase()
    .split(" ")
    .map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join(" ");

/**
 * Convert string to Kebab Case
 *
 * @param {string} str - A string (e.g. "HELLO world!")
 * @returns `str` in Pascal Case (e.g. "hello-world!")
 */
export const toKebabCase = (str: string) => str.toLowerCase().split(" ").join("-");

/**
 * Non-cryptographic hash function based on Java's hashCode()
 *
 * Source: {@link https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript | StackOverflow}
 *
 * @param {string} str - A string to be hashed
 * @returns A non-cryptographic hash of `str`.
 */
export const hashCode = (str: string) => {
  return str
    .toString()
    .split("")
    .map((char) => char.charCodeAt(0))
    .reduce((hash, char: any) => ((hash << 5) - hash + char) | 0, 0);
};

/**
 * Provide a string formatting function similar to Python's
 *
 * @param {string} str - the format string
 * @returns A non-cryptographic hash of `str`.
 */
export const formatUnicorn = (formatStr: string, ...args: string[]): string => {
  return formatStr.replace(/{(\d+)}/g, (match, number) => {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

/**
 * Remove trailing / from url
 *
 * @param {string} urlString - the url string
 * @returns the url string without trailing /.
 */
export const removeTrailingSlash = (urlString: string): string => {
  return urlString.replace(/\/$/, "");
};

export function hasJsonStructure(str: any) {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
}

declare type UniqueName = {
  id: string;
  name: string;
};

export function duplicateSuffixes<T extends UniqueName>(objects: T[], newObj: T): number[] {
  return objects
    .map((obj: any) => {
      if (obj?.id !== newObj.id && (obj.name || typeof obj === "string")) {
        const objName = typeof obj === "string" ? obj : obj.name;
        const suffixNum = objName.match("\\([0-9]+\\)$");

        const newName = newObj.name.trim();
        if (objName === newName) {
          return 1;
        } else if (suffixNum?.index && objName.slice(0, suffixNum.index - 1) === newName) {
          return Number(objName.slice(suffixNum.index + 1, objName.length - 1)) + 1;
        }
      }
      return 0;
    })
    .sort((a: any, b: any) => b - a);
}

export const validateEmail = (address: string) => {
  const emailRegex =
    "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?[.][a-z0-9]+";
  const valid = address.toLowerCase().match(emailRegex);
  return valid ? valid.length : false;
};

export const toTitleCase = (str: string) => {
  return startCase(toLower(str));
};

export const percentToDecimal = (str: string) => {
  return Number(str.slice(0, -1)) / 100;
};

export const findSubstring = (str: string, query: string) => {
  const firstSection = [];
  for (let i = 0; i < str.length; i++) {
    if (str.slice(i, i + query.length).toLowerCase() === query.toLowerCase()) {
      break;
    } else {
      firstSection.push(str[i]);
    }
  }

  return {
    firstSection: firstSection.join(""),
    query: str.slice(firstSection.length, firstSection.length + query.length),
    lastSection: str.slice(firstSection.length + query.length, str.length),
  };
};

// Returns the actual id (for the case of "facilityId | assetId")
export const getActualId = (idStr: string) => {
  return idStr.includes("|") ? idStr.split("|")[1] : idStr;
};
