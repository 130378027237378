import * as THREE from "three";

import { Matrix4 } from "..";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export class BoxExtent extends THREE.Vector3 {}

export const serverToView: { [key: string]: keyof Touchup | undefined } = {
  id: "id",
  rescan_request: "rescanId",
  segment: "segmentId",
  offset: "offset",
  extent: "extent",
};

export class Touchup extends ISerialization {
  public id?: string | null;
  public rescanId?: string | null;
  public segmentId?: string | null;
  public offset?: Matrix4 | null;
  public extent?: BoxExtent | null;

  constructor(touchup?: Touchup) {
    super();
    if (touchup) {
      Object.assign(this, touchup);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Touchup>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Touchup>(this, serverToView);
    if (this.offset !== undefined) {
      request.offset = JSON.stringify(this.offset);
    }
    if (this.extent !== undefined) {
      request.extent = JSON.stringify(this.extent);
    }
    return request;
  }
}
