export const insertSpace = (s: string) => {
  return s.replace(/([A-Z])/g, " $1").trim();
};

export const commaSeparate = (sArr: string[]) => {
  return sArr.map((x, id, arr) => (id === arr.length - 1 ? x : x + ", "));
};

export const capitalizeAndRemoveLast = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1, -1);
};
