import { Check, DoneAll } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import Linkify from "react-linkify";

import { getFormattedDate } from "utils/DateUtils";

const ClassBubble = ({
  message,
  sent,
  timestamp,
  read,
}: {
  message: string;
  sent: boolean;
  timestamp?: string;
  read?: boolean;
}) => {
  return (
    <Box
      sx={{
        whiteSpace: "normal",
        display: "inline-block",
        position: "relative",
        float: sent ? "right" : "left",
        clear: "both",
        fontFamily: "Inter",
        fontSize: "15px",
        lineHeight: "22px",
        borderRadius: "4px",
        padding: "6px 12px",
        textAlign: "left",
        maxWidth: "80%",
        marginTop: "2%",
        marginBottom: "2%",
        wordBreak: "break-word",
        backgroundColor: sent ? "#1C1F22" : "#DCDCDC",
        color: sent ? "white" : "black",
        "&::before": {
          content: '""',
          width: "0px",
          height: "0px",
          borderRight: sent ? "12px solid #fff" : length > 1 ? "12px solid transparent" : "6px solid transparent",
          borderLeft: sent ? (length > 1 ? "12px solid transparent" : "6px solid transparent") : "12px solid #fff",
          borderTop: "12px solid #fff",
          borderBottom: "12px solid transparent",
          right: sent ? "-5px" : undefined,
          left: sent ? undefined : "-5px",
          bottom: 0,
          backgroundColor: sent ? "#1C1F22" : "#DCDCDC",
          position: "absolute",
          zIndex: -1,
        },
      }}
    >
      <div>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a style={{ color: sent ? "white" : "black" }} href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {message}
        </Linkify>
      </div>
      <div
        style={{
          marginTop: "-3px",
        }}
      >
        <Typography variant="caption" sx={{ textAlign: sent ? "right" : "left", color: sent ? "#EBEBEB" : "#555758" }}>
          {getFormattedDate(timestamp || "")}
        </Typography>
        {sent ? (
          read ? (
            <DoneAll sx={{ marginLeft: 1, height: 12, width: 12, fill: "#EBEBEB" }} />
          ) : (
            <Check sx={{ marginLeft: 1, height: 12, width: 12, fill: "#EBEBEB" }} />
          )
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
};

export default React.memo(ClassBubble);
