import { TenantInfo, TenantLabels } from "./types";
import serialize from "utils/ObjectToFormData";
import { TenantInfoState } from "views/AppearancePage/tenantInfoReducer";

export function deserializeTenantInfo(jsonObj: any[]): TenantInfo {
  return {
    name: jsonObj[0].name,
    domain: jsonObj[0].domain,
    applicationTitle: jsonObj[0].application_title,
    logo: jsonObj[0].logo,
    priority: jsonObj[0]?.priority || undefined,
    tenant: jsonObj[0].tenant,
    idleTimeout: jsonObj[0].idle_timeout_mins,
    snapshotExpiryDays: jsonObj[0].snapshot_expiry_days,
  };
}

export function deserializeTenantLabels(jsonObj: any[]): TenantLabels {
  return {
    asset: jsonObj[0].asset,
    snapshot: jsonObj[0].snapshot,
    collection: jsonObj[0].collection,
  };
}

export function serializeTenantLabels(labels: TenantLabels) {
  return {
    asset: labels.asset,
    snapshot: labels.snapshot,
    collection: labels.collection,
  };
}

export function deserializeTenantLogo(jsonObj: any[]): string {
  return jsonObj[0].logo || "";
}

export function serializeTenantInfo(tenantInfo: TenantInfoState) {
  if (tenantInfo.logo === null) {
    return serialize({
      application_title: tenantInfo.applicationTitle,
      priority: tenantInfo.priority,
      logo: "",
      snapshot_expiry_days: tenantInfo.snapshotExpiryDays,
    });
  }
  if (tenantInfo.logo === undefined) {
    return serialize({
      application_title: tenantInfo.applicationTitle,
      priority: tenantInfo.priority,
      snapshot_expiry_days: tenantInfo.snapshotExpiryDays,
    });
  }

  return serialize(tenantInfo);
}
