import { CardContent, Paper } from "@mui/material";
import React, { useState } from "react";

import UserStatusList from "components/userList/UserStatusList";
import useMessagingServiceConnectionState from "hooks/useMessagingServiceConnectionState";
import { ConnectionState } from "services/ConnectionStatusManager";

const ContactsTab = () => {
  const { connectionState } = useMessagingServiceConnectionState();
  const [searchedUserID, setSearchedUserID] = useState("" as string);

  return (
    <>
      <CardContent
        sx={{
          height: connectionState === ConnectionState.CONNECTED ? `calc(100% - 60px)` : `calc(100% - 120px)`,
          overflowY: "scroll",
          paddingTop: "11px",
        }}
      >
        <Paper elevation={0}>
          <UserStatusList searchedUserID={searchedUserID} handleSetSearchedUserID={setSearchedUserID} />
        </Paper>
      </CardContent>
    </>
  );
};

export default ContactsTab;
