import { Close, Save } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

const ConfirmCancelButtons = ({
  id,
  submitDisabled,
  submitText,
  handleSubmit,
  cancelText,
  handleDiscardChanges,
  hasPendingChanges,
  small,
  tertiaryOption,
  tertiaryText,
  handleTertiary,
  confirmFirst = false,
  cancelIcon,
  noIcon = false,
}: {
  id?: string;
  submitDisabled: boolean;
  submitText: string;
  handleSubmit?: () => void;
  cancelText?: string;
  handleDiscardChanges?: (isDiscardingChanges: boolean | ((prevState: boolean) => boolean)) => void;
  hasPendingChanges?: number | boolean | null;
  small?: boolean;
  tertiaryOption?: boolean;
  tertiaryText?: string;
  handleTertiary?: () => void;
  confirmFirst?: boolean;
  cancelIcon?: React.ReactNode;
  noIcon?: boolean;
}) => {
  const theme = useTheme();

  const confirmButton = (
    <Button
      id={`confirm-button`}
      size={small ? "small" : "large"}
      variant="contained"
      color="primary"
      onClick={handleSubmit}
      disabled={submitDisabled}
      startIcon={<Save sx={{ color: theme.palette.primary.contrastText }} />}
    >
      {submitText}
    </Button>
  );

  const cancelButton = (
    <Button
      id={`cancel-button`}
      size={small ? "small" : "large"}
      variant="outlined"
      onClick={handleDiscardChanges ? () => handleDiscardChanges(true) : undefined}
      disabled={hasPendingChanges === false}
      startIcon={noIcon ? null : cancelIcon ?? <Close />}
    >
      {cancelText ?? "Cancel"}
    </Button>
  );
  const buttons = confirmFirst ? (
    <>
      {confirmButton} {cancelButton}
    </>
  ) : (
    <>
      {cancelButton} {confirmButton}
    </>
  );
  return (
    <>
      <Box
        sx={{
          m: 0,
          p: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 2,
        }}
      >
        {buttons}
        {tertiaryOption && (
          <Button
            id={id}
            size={small ? "small" : "large"}
            variant="contained"
            color="primary"
            onClick={handleTertiary}
            disabled={submitDisabled}
          >
            {tertiaryText}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ConfirmCancelButtons;
