import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Manufacturer | undefined } = {
  id: "id",
  name: "name",
};

export class Manufacturer extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  constructor(manufacturer?: Manufacturer) {
    super();
    if (manufacturer) {
      Object.assign(this, manufacturer);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Manufacturer>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Manufacturer>(this, serverToView);
    return request;
  }
}
