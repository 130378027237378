import { List } from "@mui/material";
import React from "react";

import ARNavItem from "./ARNavItem";
import { IARNavType } from "./ARNavType";
const ARNavGroup = ({ children, item }: { item: IARNavType; children: React.ReactNode }) => {
  return (
    <List sx={{ p: 0 }}>
      {!item.hidden && <ARNavItem item={item} />}
      {children}
    </List>
  );
};

export default ARNavGroup;
