import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "./customFetchBase";
import { ITenantFeature } from "./types";
import { Profile, User } from "services/ContentServer/Identity";

export declare type SitesDict = { [id: string]: SingleSite };

export declare type SingleSite = {
  name: string;
  id: string;
  image?: string;
  company: string;
  address: string;
  country: string;
  region: string;
  zipPostalCode: string;
  active: boolean;
  keyContact: string;
  keyContactObj: Profile | undefined;
  createdBy: string;
  createdDate: string;
  users: User[];
};

export const deserializeSite = (obj: any) => {
  const site = {} as SingleSite;
  site.id = obj.id;
  site.name = obj.name;
  site.company = obj.company;
  site.address = obj.address;
  site.country = obj.country;
  site.region = obj.region;
  site.zipPostalCode = obj.zip_postal_code;
  site.active = obj.status;
  site.keyContact = obj.contact_id;
  site.createdBy = obj.created_by_id;
  site.createdDate = obj.created_on;
  return site;
};

const deserializeSites = (obj: any) => {
  const sites: SitesDict = {};

  for (let i = 0; obj && i < obj.length; i++) {
    const site: SingleSite = deserializeSite(obj[i]);
    sites[site.id] = site;
  }
  return sites;
};

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getTenantFeatures: builder.query<ITenantFeature, void>({
      query() {
        return {
          url: "/tenantfeatures/",
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getSitesDict: builder.query<SitesDict, void>({
      query() {
        return {
          url: "/sites/",
        };
      },
      transformResponse(response: any[]) {
        return deserializeSites(response);
      },
    }),
  }),
});

export const { useGetTenantFeaturesQuery, useGetSitesDictQuery, useLazyGetSitesDictQuery } = appApi;
