import { Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { SyntheticEvent, useEffect, useMemo, useRef } from "react";

import useWindowSize from "hooks/useWindowSize";

export const HeaderTabs = ({
  tab,
  setTab,
  headers,
  whiteStyle = false,
}: {
  tab: number;
  setTab: (value: number | ((prev: number) => number)) => void;
  headers: string[];
  whiteStyle?: boolean;
}) => {
  const theme = useTheme();
  const tabRefs = useRef<Array<HTMLDivElement | null>>([]);

  const windowSize = useWindowSize();

  useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, headers.length);
  }, [headers]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const disabledArrowOpacity = useMemo(() => {
    const scrollWidth = document.querySelector(".MuiTabs-flexContainer")?.scrollWidth;
    const clientWidth = document.querySelector(".MuiTabs-flexContainer")?.clientWidth;
    if (windowSize.width && scrollWidth && clientWidth && scrollWidth > clientWidth) {
      return 0.3;
    } else {
      return 0;
    }
  }, [windowSize.width]);

  return (
    <Tabs
      value={tab}
      variant="scrollable"
      allowScrollButtonsMobile
      scrollButtons={true}
      onChange={handleTabChange}
      classes={{
        flexContainer: "flexContainer",
        indicator: "indicator",
      }}
      sx={{
        "& .indicator": {
          display: "flex",
          justifyContent: "left",
          backgroundColor: "transparent",
          height: "2.45px",
          "& > span": {
            backgroundColor: whiteStyle ? theme.palette.primary.contrastText : theme.palette.primary.main,
            width: `${tabRefs.current[tab]?.children[0].clientWidth}px`,
          },
        },
        ".MuiTabs-scrollButtons.Mui-disabled": {
          display: disabledArrowOpacity ? "flex" : "none",
          opacity: disabledArrowOpacity,
        },
      }}
      TabIndicatorProps={{ children: <span /> }}
    >
      {headers.map((title, idx) => (
        <Tab
          key={title}
          ref={(el) => (tabRefs.current[idx] = el)}
          sx={{
            "&.MuiTab-root": {
              display: whiteStyle ? "flex" : "block",
              alignItems: whiteStyle ? "center" : "auto",
              color: whiteStyle ? theme.palette.primary.contrastText : theme.palette.primary.main,
              letterSpacing: "0",
              paddingBottom: "21px",
              paddingRight: "24px",
              paddingLeft: 0,
            },
            "&.MuiButtonBase-root": {
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            },
          }}
          label={
            <Typography
              variant={whiteStyle ? "h5" : "h4"}
              sx={{
                width: "100%",
                textAlign: "center",
                textTransform: "none",
                color: whiteStyle ? theme.palette.primary.contrastText : theme.palette.primary.main,
              }}
            >
              {title}
            </Typography>
          }
          disableRipple
        />
      ))}
    </Tabs>
  );
};
