import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Typography, Box, useTheme, Button } from "@mui/material";
import React, { useMemo } from "react";

import { RoleState } from "./roleReducer";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { ADMIN_ROLE, MEMBER_ROLE } from "services/ContentServer/Identity";
import { toTitleCase } from "utils/StringUtils";

export interface RoleInfoTabProps {
  role: RoleState;
  onChange: (role: RoleState) => void;
  onDelete: () => void;
  didModify?: boolean;
}

const RoleInfoTab: React.FC<RoleInfoTabProps> = ({ role, onChange, onDelete, didModify }) => {
  const { featureAccess } = useUsers();

  const deleteAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ROLES].delete;
  }, [featureAccess]);

  const protectedRole = useMemo(() => role.name === ADMIN_ROLE || role.name === MEMBER_ROLE, [role]);

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        pt: 3,
        px: matchesSM ? 6 : matchesMD ? 8 : 13,
        overflowY: "scroll",
        height: "100%",
        maxHeight: useWindowSize().height - (didModify ? 220 : 150),
      }}
    >
      <Box sx={{ pb: 3, height: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            py: 0,

            gap: 1,
          }}
        >
          <Typography variant="subtitle1">Name</Typography>
          {!protectedRole ? (
            <FixedHeightTextField
              variant="outlined"
              required={true}
              placeholder={role.name !== "" ? role.name : "Role name"}
              value={role.name}
              sx={{ width: "100%" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange({ ...role, name: event.target.value });
              }}
            />
          ) : (
            <Typography variant="body1">
              {role.name === ADMIN_ROLE || role.name === MEMBER_ROLE ? toTitleCase(role.name) : role.name}
            </Typography>
          )}

          <RequiredFieldMsg style={{ display: role.name.length > 0 ? "none" : "" }} />
        </Box>
      </Box>
      <Box sx={{ pb: 3, height: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            py: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
            }}
          >
            <Typography variant="subtitle1">Description</Typography>
            <Typography
              variant="subtitle2"
              style={{
                color: "#555758",
              }}
            >
              (Optional)
            </Typography>
          </Box>
          {!protectedRole ? (
            <FixedHeightTextField
              variant="outlined"
              required={true}
              placeholder={
                role.desc !== "" ? role.desc : "E.g. Administrator account has access to all locations as default"
              }
              value={role.desc}
              sx={{ width: "100%", height: "36px" }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange({ ...role, desc: event.target.value });
              }}
            />
          ) : (
            <Typography variant="body1">{role.desc}</Typography>
          )}
        </Box>
      </Box>
      {!protectedRole && deleteAccess && (
        <Box sx={{ pb: 3, height: "auto" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              py: 0,
              gap: 1,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={onDelete}
              style={{ display: role.id !== "" ? "flex" : "none" }}
            >
              Delete Role
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RoleInfoTab;
