import { ModelState, Matrix4 } from "../types";
import { ARFile } from "./ARFile";
import { Asset } from "./Asset";
import { FileSegment } from "./FileSegment";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { MediaCapture } from "services/ContentServer/Audit/serviceTypes/MediaCapture";
import { ISerialization } from "services/ContentServer/ISerialization";
import { isSplatFile } from "utils/FileUtils";

export const serverToView: { [key: string]: keyof Snapshot | undefined } = {
  id: "id",
  name: "name",
  description: "description",
  asset_id: "assetId",
  preview: "preview",
  is_template: "isTemplate",
  parent: "parent",
  is_modifiable: "isModifiable",
  site: "siteId",
  site_name: "siteName",
  specifier: "specifier",
  asset_info_id: "assetInfoId",
  created_at: "createdAt",
  archived_date: "archivedDate",
  state: "state",
  pose_height_limit: "poseHeightLimit",
  snapshot_to_facility_transform: "snapshotToFacilityTransform",
  facility_id: "facility",
  anonymous_access_key: "accessKey",
  access_key_generated_by: "accessKeyBy",
  created_by: "createdBy",
  label_groups: "labelGroups",
  last_modified_at: "lastModifiedAt",
  last_modified_by: "lastModifiedBy",
  expected_images: "expectedImages",
  scale: "scale",
  segments: "segments",
  ar_file: "arFile",
  transform: "transform",
};

export class Snapshot extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public description?: string | null;
  public asset?: Asset | null;
  public assetId?: string | null;
  public preview?: string | Blob | File | null;
  public isTemplate?: boolean | null;
  public parent?: string | null;
  public isModifiable?: boolean | null;
  public siteId?: string | null;
  public isSpatial?: boolean | null;
  public specifier?: string | null;
  public poses?: MediaCapture[] | null;
  public assetInfoId?: string | null;
  public siteName?: string | null;
  public createdAt?: string | null;
  public createdBy?: string | null;
  public archivedDate?: any | null;
  public state? = ModelState.Normal;
  public poseHeightLimit?: number | null;
  public facility?: string | null;
  public snapshotToFacilityTransform?: Matrix4 | null;
  public accessKey?: string | null;
  public accessKeyBy?: string | null;
  public labelGroups?: string[] | null;
  public lastModifiedBy?: string | null;
  public lastModifiedAt?: string | null;
  public segments?: FileSegment[] | null;
  public expectedImages?: number | null;
  public scale?: number | null;
  public arFile?: ARFile | null;
  public transform?: Matrix4 | null;

  constructor(snapshot?: Snapshot) {
    super();
    if (snapshot) {
      Object.assign(this, snapshot);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Snapshot>(this, serverToView, json);
    this.asset = json.asset ? new Asset() : undefined;
    this.asset?.deserialize(json.asset);
    this.siteId = this.asset?.site;
    this.specifier = this.asset?.specifier;
    this.isSpatial = true;

    if (json.ar_file) {
      const arfile = new ARFile();
      arfile.deserialize(json.ar_file);
      this.arFile = arfile;
      if (this.asset) {
        this.asset.arFile = arfile;
      }
    }

    if (json.segments) {
      this.segments = [];
      json.segments.forEach((segment: any) => {
        const newSegment = new FileSegment();
        newSegment.deserialize(segment);
        this.segments?.push(newSegment);
      });
    }

    this.poses = json.images?.map((img: any) => {
      const pose = new MediaCapture();
      pose.deserialize(img);
      return pose;
    });

    if (!this.name) {
      if (this.isTemplate) {
        this.name = `Unnamed Template ${this.id}`;
      } else {
        this.name = `Snapshot ${this.id}`;
      }
    }
  }

  serialize(): any {
    const request = serializerHelper<Snapshot>(this, serverToView);

    if (this.snapshotToFacilityTransform !== undefined) {
      request.snapshot_to_facility_transform = JSON.stringify(this.snapshotToFacilityTransform);
    }
    if (this.transform !== undefined) {
      request.transform = JSON.stringify(this.transform);
    }
    if (this.poses) {
      request.images = this.poses.map((pose) => pose.serialize());
    } else {
      request.images = this.poses;
    }
    if (this.segments) {
      request.segments = this.segments.map((segment) => segment.serialize());
    }

    request.ar_file = this?.arFile?.serialize();
    if (this.labelGroups !== undefined) {
      request.label_group_ids =
        this.labelGroups && this.labelGroups.length > 0 ? this.labelGroups.map((gr) => gr.toString()) : [];
    }

    return request;
  }

  getFileSegments(): FileSegment[] {
    return this.segments ? this.segments : [];
  }

  hasTextured(): boolean {
    const allFilesTextured = this.segments?.every((segment) => segment.hasTextured);
    return Boolean(allFilesTextured);
  }

  hasMesh() {
    let hasMeshOutput = false;
    this?.segments?.forEach((segment: FileSegment) => {
      if (segment.meshFile) {
        hasMeshOutput = true;
        return;
      }
    });
    return hasMeshOutput;
  }

  isExternalModel() {
    if (this.segments && this.segments.length > 0) {
      return this.segments[0].externalFile || false;
    }
  }

  isSplat() {
    if (
      this.segments &&
      this.segments.length > 0 &&
      this.segments[0].lodFiles &&
      this.segments[0].lodFiles.length > 0 &&
      this.segments[0].lodFiles[0].texturedFile
    ) {
      return isSplatFile(this.segments[0].lodFiles[0].texturedFile as string);
    }
  }
}
