import React, { useContext, createContext, useEffect, useState } from "react";
import useSWR from "swr";

import { healthCheck } from "services";
import { ServerType } from "services/AzureAD";

interface IContextProps {
  available: boolean;
  error: any;
}

const serverStatusContext = createContext({
  available: false,
  error: "",
} as IContextProps);

export function ProvideServerStatus({ children }: { children: React.ReactNode }) {
  const serverStatus = useProvideServerStatus();
  return <serverStatusContext.Provider value={serverStatus}>{children}</serverStatusContext.Provider>;
}

export const useServerStatus = () => {
  return useContext(serverStatusContext);
};

function useProvideServerStatus() {
  const [didError, setDidError] = useState(false);
  const { data: available, error } = useSWR("AVAILABLE", () => healthCheck.getServerStatus(ServerType.CONTENT_SERVER), {
    onErrorRetry: (error, key, config, revalidate) => {
      // Retry after 5 seconds.
      setTimeout(() => {
        setDidError(true);
        revalidate({});
      }, 5000);
    },
  });

  useEffect(() => {
    if (available) {
      setDidError(false);
    }
  }, [available]);

  return {
    available: available !== undefined,
    error: didError ? error : "",
  };
}
