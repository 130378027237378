import { useGetTenantFeaturesQuery } from "redux/api/appApi";
import { ITenantFeature } from "redux/api/types";

const useApp = () => {
  const { data: featureFlags } = useGetTenantFeaturesQuery();

  function isFeatureEnabled(feature: keyof ITenantFeature) {
    return featureFlags ? featureFlags[feature] : false;
  }

  return {
    isFeatureEnabled,
  };
};

export default useApp;
