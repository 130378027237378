import { gql } from "graphql-request";

import { gqlApi } from "./gqlApi";

export interface UserSubscriptionResponse {
  id: string;
  emailSubscription: Record<string, boolean>;
}

const deserializerUserSubscription = (subs: any) => {
  return {
    ...subs,
    emailSubscription: JSON.parse(subs["emailSubscription"]),
  } as UserSubscriptionResponse;
};

export const usersApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSubscriptionData: builder.query<UserSubscriptionResponse, string>({
      query: (id: string) => ({
        document: gql`
          query userProfiles {
            userProfiles(filters: {id: {exact: "${id}"}}) {
                emailSubscription
                id
              }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        const usersSubscriptions = response.userProfiles.map((profile: any) => {
          return deserializerUserSubscription(profile);
        });

        return usersSubscriptions[0];
      },
    }),
    updateUserSubscription: builder.query<UserSubscriptionResponse, { subType: string; enabled: boolean }>({
      query: (param: { subType: string; enabled: boolean }) => ({
        document: gql`
          mutation MyMutation {
            updateGlobalEmailSubscription(
              subscriptions: {subscription: ${param.subType}, isEnabled: ${param.enabled}}
            ) {
              id
              emailSubscription
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializerUserSubscription(response.updateGlobalEmailSubscription);
      },
    }),
  }),
});

export const { useLazyGetUserSubscriptionDataQuery, useLazyUpdateUserSubscriptionQuery } = usersApi;
