import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { Box, IconButton, Typography, useTheme, Button } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useUpdateUserProfileMutation, useGetUserQuery } from "redux/api/userApi";

import { CommonPageContext } from "components/CommonPage/CommonPageContext";
import { ProfileAvatar } from "components/profile/ProfileAvatar";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { FieldLengthMsg } from "components/Typography/FieldLength";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useAuth } from "hooks/useAuth";
import { PERMISSION_NAME } from "hooks/usePermission";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { hasProfile, Profile, User, UserPending, UserTypes } from "services/ContentServer/Identity";

const ProfileMainTab = ({
  user,
  userProfile,
  userType,
}: {
  user: User | UserPending | undefined;
  userProfile: Profile | false | undefined;
  userType: UserTypes | undefined;
}) => {
  const auth = useAuth();
  const { currentUser, featureAccess, loaded: usersLoaded, error: usersError } = useUsers();
  const { data: fullUser, isLoading: userLoading } = useGetUserQuery(user?.id ?? "", {
    skip: userType === UserTypes.Pending,
  });
  const snackbar = useSnackbar();
  const [displayName, setDisplayName] = useState<string>("");
  const [showBtns, setShowBtns] = useState<boolean>(false);

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  const { setIsPageLoading } = useContext(CommonPageContext);

  const isInvite = useMemo(() => userType === UserTypes.Pending && user && !userProfile, [userType, user, userProfile]);

  const MAX_DISPLAY_NAME_LENGTH = 128; // set in #1747

  const owner = useMemo(() => {
    return currentUser ? currentUser.id === user?.id : false;
  }, [currentUser, user?.id]);

  const modifyAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER].update;
  }, [featureAccess]);

  const adminAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ADMIN].read;
  }, [featureAccess]);

  const displayNameTooLong = useMemo(() => {
    return displayName ? (displayName.length > MAX_DISPLAY_NAME_LENGTH ? true : false) : false;
  }, [displayName]);

  const hasDisplayName = useMemo(() => {
    return displayName && displayName !== "" && displayName.length <= MAX_DISPLAY_NAME_LENGTH ? true : false;
  }, [displayName]);

  const [updateUserProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    setIsPageLoading(!(usersLoaded || usersError));
  }, [setIsPageLoading, usersLoaded, usersError]);

  const resetDisplayName = useCallback(() => {
    if (userProfile) {
      setDisplayName(userProfile.displayName);
    } else if (user) {
      setDisplayName(user.name);
    }
    setShowBtns(false);
  }, [user, userProfile]);
  useEffect(() => {
    resetDisplayName();
  }, [resetDisplayName]);

  const updateUserDisplayName = useCallback(async () => {
    if (user && displayName && userProfile && !displayNameTooLong) {
      try {
        const updatedUser = { ...user } as User;
        const updatedProfile = { ...userProfile };
        updatedProfile.displayName = displayName;
        updatedUser.userProfile = updatedProfile;

        updateUserProfile({ id: user.id, profile: updatedUser.userProfile });

        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Successfully saved display name.",
        });
      } catch (error) {
        console.error(error);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error saving display name.",
        });
      }
    }
    setShowBtns(false);
  }, [userProfile, displayName, snackbar, user, displayNameTooLong, updateUserProfile]);

  const avatar = useMemo(() => {
    if (!user) {
      return <></>;
    }
    const userWithProfile = hasProfile(user);
    if (userWithProfile) {
      return <ProfileAvatar owner={owner} user={userWithProfile} />;
    }
    return <></>;
  }, [user, owner]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          maxHeight: useWindowSize().height - (showBtns ? 220 : 150),
          pt: 3,
          px: matchesSM ? 6 : matchesMD ? 8 : 13,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            pt: 3,
            gap: 3,
            minWidth: "320px",
            maxWidth: "500px",
            width: "100%",
          }}
        >
          {!isInvite ? avatar : <></>}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: 0,
              gap: 1,
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">Display Name</Typography>
            {adminAccess || owner ? (
              <>
                <FixedHeightTextField
                  variant="outlined"
                  required={true}
                  placeholder={displayName ? "" : "Display Name"}
                  value={displayName}
                  sx={{
                    width: "100%",
                    padding: "0px 0px 8px 0px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: displayNameTooLong ? theme.palette.error.main : theme.palette.primary.main,
                      },
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: displayNameTooLong ? theme.palette.error.main : theme.palette.primary.main,
                    },
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDisplayName(event.target.value);
                    setShowBtns(true);
                  }}
                  disabled={isInvite}
                />
                <FieldLengthMsg maxLength={MAX_DISPLAY_NAME_LENGTH} curLength={displayName ? displayName.length : 0} />
                <RequiredFieldMsg style={{ display: hasDisplayName ? "none" : "" }} />
              </>
            ) : (
              <Typography variant="body1" sx={{ width: "100%", padding: "8px 0px 8px 0px" }}>
                {displayName || ""}
              </Typography>
            )}
          </Box>
          {!isInvite && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: 0,
                gap: 1,
                width: "100%",
              }}
            >
              <Typography variant="subtitle1">Profile Information</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  py: 2,
                  px: 0,
                  gap: 2,
                  width: "100%",
                  backgroundClip: "content-box",
                  backgroundColor: "#FAFAFA",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: 2,
                    gap: 3,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                      gap: 1,
                      width: "100%",
                    }}
                  >
                    <PersonOutlineOutlinedIcon />
                    <Typography variant="body1">
                      {userLoading
                        ? "Loading..."
                        : fullUser?.firstName
                        ? `${fullUser.firstName} ${fullUser.lastName}`
                        : "No data available"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                      gap: 1,
                      width: "100%",
                    }}
                  >
                    <MailOutlinedIcon />
                    <Typography variant="body1">
                      {userLoading ? "Loading..." : fullUser?.email ? fullUser.email : "No data available"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px",
                      gap: 1,
                      width: "100%",
                    }}
                  >
                    <PhoneOutlinedIcon />
                    <Typography variant="body1">
                      {userLoading ? "Loading..." : fullUser?.phoneNumber ? fullUser.phoneNumber : "No data available"}
                    </Typography>
                  </Box>
                </Box>
                {owner && (
                  <IconButton color="primary" onClick={auth.editProfile} sx={{ padding: 2 }}>
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {!isInvite && (adminAccess || owner || modifyAccess) && (
        <SettingsBottomBar show={showBtns} hasRequiredFields={hasDisplayName}>
          <>
            <Button variant="outlined" onClick={resetDisplayName}>
              Cancel
            </Button>
            <Button variant="contained" onClick={updateUserDisplayName}>
              Save Changes
            </Button>
          </>
        </SettingsBottomBar>
      )}
    </>
  );
};

export default ProfileMainTab;
