import axios, { AxiosInstance } from "axios";
import Config from "Config";

import { ServerType } from "services/AzureAD";

const HEALTH_ENDPOINT = "/health/";

export class HealthCheck {
  readonly axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create();
  }

  async getServerStatus(serverType: ServerType) {
    const start = Date.now();
    const resp = await this.axiosInstance.get(Config.STATUS_URLS[serverType] + HEALTH_ENDPOINT);
    const finish = Date.now();

    const time = (finish - start) / 1000;
    return { data: resp.data, time: time };
  }
}
