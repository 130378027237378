import { Icon, IconProps } from "@mui/material";
import React from "react";

import icon from "assets/icons/CheckedOptionBox.svg";

export const CheckedOptionBox = (props: IconProps) => {
  return (
    <Icon {...props}>
      <img src={icon} />
    </Icon>
  );
};

export default CheckedOptionBox;
