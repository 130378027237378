import { CardContent, Paper, Typography, List } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import useSWR from "swr";

import UserListItem from "components/userList/UserListItem";
import { useAuth } from "hooks/useAuth";
import useMessagingServiceConnectionState from "hooks/useMessagingServiceConnectionState";
import useUser from "hooks/useUser";
import useUsers from "hooks/useUsers";
import { IChatMessage } from "services/Chat/serializers";
import { ConnectionState } from "services/ConnectionStatusManager";
import { ChatSWRKeys } from "services/ContentServer/Chat";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { ChatContext } from "views/ChatView/ChatContext";

const ChatTab = () => {
  const { connectionState } = useMessagingServiceConnectionState();
  const { contentServer } = useContext(RequestContext);
  const {
    chatUserList,
    isInitialHistoryFetched,
    setIsInitialHistoryFetched,
    setChatBox,
    userSelectedId,
    setCurrentMessages,
    chatBox,
    setChatUserList,
    lastMsgs,
    setLastMsgs,
  } = useContext(ChatContext);
  const { users, error } = useUsers();
  const auth = useAuth();
  const { user } = useUser(auth.user ? auth.user.id : "");

  const { data: initialChatHistory } = useSWR(
    auth.user ? [ChatSWRKeys.CHAT_HISTORY, auth.user] : null,
    () => (auth.user ? contentServer.chatService.getChatHistory(auth.user.id) : undefined),
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    if (initialChatHistory && user && !isInitialHistoryFetched) {
      let peerId = "";
      let orderedMessages: IChatMessage[] = [];
      setChatBox((prevChatBox) => {
        initialChatHistory.forEach((chatGroup) => {
          const peerUsers = chatGroup.users.filter((userId) => userId.id !== user.id);
          peerId = peerUsers.length > 0 ? peerUsers[0].id : user.id;
          orderedMessages = chatGroup.messages.reverse();
          prevChatBox[peerId] = orderedMessages;
        });
        return prevChatBox;
      });
      if (userSelectedId === peerId) {
        setCurrentMessages(orderedMessages);
      }

      setIsInitialHistoryFetched(true);
    }
  }, [
    initialChatHistory,
    user,
    userSelectedId,
    setChatBox,
    chatBox,
    setCurrentMessages,
    setIsInitialHistoryFetched,
    isInitialHistoryFetched,
    auth.user,
    setChatUserList,
    setLastMsgs,
    users,
  ]);

  const items = useMemo(() => {
    const chatListItems: React.ReactElement[] = [];
    chatUserList.forEach((person, idx) => {
      if (idx !== 0 && person.id !== chatUserList[idx - 1].id) {
        chatListItems.push(<UserListItem key={person.id} userId={person.id} msg={lastMsgs[idx]} />);
      } else if (idx === 0) {
        chatListItems.push(<UserListItem key={person.id} userId={person.id} msg={lastMsgs[idx]} />);
      }
    });
    return chatListItems;
  }, [chatUserList, lastMsgs]);

  return (
    <CardContent
      sx={{
        height: connectionState === ConnectionState.CONNECTED ? `calc(100% - 60px)` : `calc(100% - 120px)`,
        overflowY: "scroll",
        paddingTop: "15px",
      }}
    >
      <Paper elevation={0}>
        {Object.values(chatUserList).length > 0 ? (
          <List component="div" disablePadding style={{ maxHeight: "100%", overflow: "auto" }}>
            {items}
          </List>
        ) : error ? (
          <Typography variant="h6" component="h6" gutterBottom style={{ textAlign: "center" }}>
            Error fetching users ...
          </Typography>
        ) : (
          <Typography variant="h6" component="h6" gutterBottom style={{ textAlign: "center", paddingTop: "20px" }}>
            No active chats
          </Typography>
        )}
      </Paper>
    </CardContent>
  );
};

export default ChatTab;
