import { Palette } from "@mui/material/styles/createPalette";

const ARtypography = (palette: Palette) => {
  return {
    h1: {
      fontFamily: "Inter",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 700,
      color: palette.primary.main,
    },
    h2: {
      fontFamily: "Inter",
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: "normal",
      color: palette.primary.main,
    },
    h3: {
      fontFamily: "Inter",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 800,
      lineHeight: "normal",
      color: palette.primary.main,
    },
    h4: {
      //equals to  h5 in Figma designs
      fontFamily: "Inter",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 800,
      color: palette.primary.main,
    },
    h5: {
      ///equals to  h6 in Figma designs
      fontFamily: "Inter",
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "normal",
      color: palette.primary.main,
    },
    h6: {
      ///equals to  h7 & h8 in Figma designs
      fontFamily: "Inter",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 500,
      color: palette.primary.main,
    },
    subtitle1: {
      fontFamily: "Inter",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "uppercase" as const,
      color: palette.primary.main,
    },
    subtitle2: {
      fontFamily: "Inter",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      textTransform: "uppercase" as const,
      color: palette.primary.main,
    },
    body1: {
      fontFamily: "Inter",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: palette.primary.main,
    },
    body2: {
      fontFamily: "Inter",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400,
      color: palette.primary.main,
    },
    button: {
      fontFamily: "Inter",
      fontSize: "0.875rem",
      fontStyle: "normal",
      fontWeight: 400,
      color: palette.primary.main,
    },
    caption: {
      fontFamily: "Inter",
      fontSize: "0.6875rem",
      fontStyle: "normal",
      fontWeight: 400,
      color: palette.primary.main,
    },
    overline: {
      fontFamily: "Inter",
      color: palette.primary.main,
    },
  };
};
export default ARtypography;
