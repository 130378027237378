import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Grid } from "@mui/material";
import React from "react";

const NotFoundPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <main>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              <WarningRoundedIcon color="secondary" sx={{ width: 250, height: 250 }} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ fontSize: 24, marginTop: 125, marginBottom: 3 }}>
              <strong>ERROR 404</strong> <br />
            </div>
            PAGE NOT FOUND
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default NotFoundPage;
