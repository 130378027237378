import {
  MoreHoriz,
  Menu,
  MenuOpen,
  HelpOutlineOutlined,
  LogoutOutlined,
  PermIdentityOutlined,
} from "@mui/icons-material/";
import { Box, Drawer, IconButton, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChatSharp from "icons/ChatSharp";
import React, { useState, useCallback, useMemo } from "react";
import {
  getDrawerWidth,
  getOpenChat,
  setOpenChat,
  setSelectedNavItem,
  getSelectedNavItem,
  setOpenSettingsNav,
  getOpenSettingsNav,
} from "redux/features/appSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import useApp from "redux/hooks/useApp";

import ARAvatar from "components/ARAvatar";
import ARLogo from "components/ARLogo";
import { ARNavCollapse, ARNavGroup, ARNavItem, ARNavMenu, NavItemType } from "components/ARNav";
import ContactHelpDialog from "components/Dialog/ContactHelp";
import routes, { PortalRoute } from "components/Routes";
import userProfileRoutes, { UserProfileRoute } from "components/UserProfile/UserProfileRoutes";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useAuth } from "hooks/useAuth";
import { PERMISSION_NAME } from "hooks/usePermission";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import ChatModalWithContext from "views/ChatView/ChatModal";
import { SettingRoutesDict, SettingTab } from "views/SettingsPage/SettingsBasePage";

interface Props {
  window?: () => Window;
}

function ARNav(props: Props) {
  const theme = useTheme();
  const { window } = props;
  const { currentUser, featureAccess } = useUsers();
  const app = useApp();
  const auth = useAuth();
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const drawerWidth = useAppSelector(getDrawerWidth);
  const openChat = useAppSelector(getOpenChat);
  const openSettingsNav = useAppSelector(getOpenSettingsNav);
  const selectedNavItem = useAppSelector(getSelectedNavItem);
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useARMediaQuery("mobile");
  const isTablet = useARMediaQuery("tablet");
  const isHorizontal = useMemo(() => {
    return windowSize.height < windowSize.width;
  }, [windowSize]);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const routeObjects = useMemo(() => {
    const navItems = Object.values(routes)
      .filter((route: PortalRoute) => {
        if (!isMobile && !isTablet && featureAccess[PERMISSION_NAME.ADMIN].read) {
          return (
            (route.highLevel || route.name === "Settings") &&
            (!route.featureFlagName || app.isFeatureEnabled(route.featureFlagName)) &&
            (!route.requiredPermissions?.length ||
              route.requiredPermissions.filter((perm) => featureAccess[perm].read).length)
          );
        } else if (!isMobile && !isTablet) {
          return (
            route.highLevel &&
            (!route.featureFlagName || app.isFeatureEnabled(route.featureFlagName)) &&
            (!route.requiredPermissions?.length ||
              route.requiredPermissions.filter((perm) => featureAccess[perm].read).length)
          );
        } else {
          return (
            route.highLevel &&
            (!route.featureFlagName || app.isFeatureEnabled(route.featureFlagName)) &&
            (!route.requiredPermissions?.length ||
              route.requiredPermissions.filter((perm) => featureAccess[perm].read).length) &&
            route.name !== "Archive" &&
            route.name !== "Checklists"
          );
        }
      })
      .map((route) => {
        if (route.name !== "Settings") {
          return {
            ...route,
            type: NavItemType.ITEM,
            onClick: () => {
              dispatch(setSelectedNavItem(route.path));
            },
          };
        } else {
          const pageWithPermissions =
            Object.values(SettingTab).find((tab) => {
              const requiredPerm = SettingRoutesDict.get(tab)?.requiredPermission;
              return requiredPerm ? featureAccess[requiredPerm].read : false;
            }) || SettingTab.PROFILE;

          const settingsChildren = Object.values(SettingTab)
            .filter((tab) => {
              const requiredPerm = SettingRoutesDict.get(tab)?.requiredPermission;
              return requiredPerm ? featureAccess[requiredPerm].read : false;
            })
            .map((tab) => ({ path: SettingRoutesDict.get(tab)?.path }));

          return {
            ...route,
            type: NavItemType.ITEM,
            path: SettingRoutesDict.get(pageWithPermissions)?.path || "",
            children: settingsChildren,
            onClick: () => {
              if (matchesMD) dispatch(setOpenSettingsNav(true));
              dispatch(setSelectedNavItem(SettingRoutesDict.get(pageWithPermissions)?.path || ""));
            },
          };
        }
      });
    return navItems;
  }, [featureAccess, app, dispatch, isMobile, isTablet, matchesMD]);

  const profileObjects = useMemo(() => {
    const allProfileRoutes = userProfileRoutes
      .filter(
        (route: UserProfileRoute) =>
          !route.requiredPermissions.length ||
          route.requiredPermissions.filter((perm) => featureAccess[perm].read).length
      )
      .map((route) => {
        let linkPath = route.path;
        if (route.name === "Profile" && currentUser && currentUser.id) {
          linkPath += `?userId=${currentUser.id}`;
        } else if (route.name === "Settings") {
          const pageWithPermissions =
            Object.values(SettingTab).find((tab) => {
              const requiredPerm = SettingRoutesDict.get(tab)?.requiredPermission;
              return requiredPerm ? featureAccess[requiredPerm].read : false;
            }) || SettingTab.PROFILE;
          linkPath = SettingRoutesDict.get(pageWithPermissions)?.path || "";
        }

        return {
          ...route,
          path: linkPath,
          type: NavItemType.ITEM,
          onClick: () => {
            if (matchesMD) dispatch(setOpenSettingsNav(true));
          },
        };
      });

    if (isMobile || isTablet) {
      return allProfileRoutes.filter((route) => route.name !== "Settings" && route.name !== "Profile");
    } else if (featureAccess[PERMISSION_NAME.ADMIN].read) {
      return allProfileRoutes.filter((route) => route.name !== "Settings");
    }
    return allProfileRoutes;
  }, [featureAccess, currentUser, isMobile, isTablet, dispatch, matchesMD]);

  const handleDrawerToggle = useCallback(() => {
    if (openSettingsNav) {
      dispatch(setOpenSettingsNav(false));
    }
    setMobileOpen(!mobileOpen);
  }, [mobileOpen, openSettingsNav, dispatch]);

  const handleChatMenuToggle = useCallback(() => dispatch(setOpenChat(!openChat)), [openChat, dispatch]);
  const handleLogout = useCallback(() => auth.logout(), [auth]);

  const topItems = useMemo(
    () => [
      {
        id: "dashboard",
        name: "AptixAR",
        type: NavItemType.GROUP,
        path: "",
        hidden: false,
        icon:
          isMobile || (isTablet && !isHorizontal) ? (
            <>
              <MenuOpen />
            </>
          ) : (
            <ARLogo />
          ),
        onClick: handleDrawerToggle,
        children: [...routeObjects],
      },
    ],
    [handleDrawerToggle, isHorizontal, isMobile, isTablet, routeObjects]
  );

  const bottomItems = useMemo(() => {
    const childrenItems = [
      {
        id: "help",
        name: "Help",
        type: NavItemType.ITEM,
        path: null,
        icon: (
          <>
            <HelpOutlineOutlined />
          </>
        ),
        onClick: () => {
          setHelpDialogOpen((prev) => !prev);
        },
      },
      {
        id: "signout",
        name: "Sign Out",
        type: NavItemType.ITEM,
        path: null,
        icon: (
          <>
            <LogoutOutlined />
          </>
        ),
        onClick: handleLogout,
      },
    ];
    return [
      {
        id: "bottom-group",
        name: "",
        type: NavItemType.GROUP,
        icon: null,
        path: "",
        hidden: true,
        onClick: () => {},
        children:
          isMobile || isTablet
            ? childrenItems
            : [
                {
                  id: "chat",
                  name: "Chat",
                  type: NavItemType.ITEM,
                  path: null,
                  icon: (
                    <>
                      <ChatSharp />
                    </>
                  ),
                  onClick: handleChatMenuToggle,
                  darkBg: true,
                  showBadge: true,
                },
                {
                  id: "help",
                  name: "Help",
                  type: NavItemType.ITEM,
                  path: null,
                  icon: (
                    <>
                      <HelpOutlineOutlined />
                    </>
                  ),
                  onClick: () => {
                    setHelpDialogOpen((prev) => !prev);
                  },
                },
                {
                  id: "accountInfo",
                  name: "Account",
                  type: isMobile || isTablet ? NavItemType.COLLAPSE : NavItemType.MENU,
                  icon:
                    currentUser && currentUser.id !== "" ? (
                      <ARAvatar user={currentUser} small noBadge={true} />
                    ) : (
                      <>
                        <PermIdentityOutlined />
                      </>
                    ),
                  children: [
                    ...profileObjects,
                    {
                      id: "signout",
                      name: "Sign Out",
                      type: NavItemType.ITEM,
                      path: null,
                      icon: (
                        <>
                          <LogoutOutlined />
                        </>
                      ),
                      onClick: handleLogout,
                    },
                  ],
                },
              ],
      },
    ];
  }, [isMobile, isTablet, handleChatMenuToggle, currentUser, handleLogout, profileObjects]);

  const buildNav = useCallback(
    (arr: any[], maxButtons: number) => {
      return arr.map((item, index) => {
        switch (item.type) {
          case NavItemType.GROUP:
            return (
              <ARNavGroup key={index} item={item}>
                {buildNav(item.children, maxButtons)}
              </ARNavGroup>
            );
          case NavItemType.COLLAPSE:
            return <ARNavCollapse key={index} item={item} handleDrawerToggle={handleDrawerToggle} />;
          case NavItemType.ITEM:
            if (index < maxButtons) {
              return <ARNavItem key={index} item={item} handleDrawerToggle={handleDrawerToggle} />;
            } else if (index === maxButtons) {
              const children = [...arr];
              children.splice(0, maxButtons);
              const modifiedItem = { ...item, name: "" };
              modifiedItem.children = children;
              modifiedItem.icon = (
                <>
                  <MoreHoriz />
                </>
              );
              return (
                <ARNavMenu key={`more-menu-${index}`} item={modifiedItem} handleDrawerToggle={handleDrawerToggle} />
              );
            }
            break;
          case NavItemType.MENU:
            return <ARNavMenu key={index} item={item} subMenuItems={true} handleDrawerToggle={handleDrawerToggle} />;
        }
      });
    },
    [handleDrawerToggle]
  );

  const maxButtons = useMemo(() => {
    if (isMobile || isTablet) return Math.floor((windowSize.height - 65 * 4) / 65);
    return Math.floor((windowSize.height - 65 * 5) / 65) < 0 ? 0 : Math.floor((windowSize.height - 65 * 5) / 65);
  }, [windowSize.height, isMobile, isTablet]);

  const navItems = useMemo(() => buildNav(topItems, maxButtons), [buildNav, maxButtons, topItems]);
  const userItems = useMemo(() => buildNav(bottomItems, 100), [buildNav, bottomItems]);

  const drawer = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>{navItems}</Box>
        <Box sx={{ width: "100%" }}>{userItems}</Box>
      </Box>
    ),
    [navItems, userItems]
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const handleNavBarHover = useCallback(() => {
    if (matchesMD && selectedNavItem.includes("systemsettings")) {
      dispatch(setOpenSettingsNav(true));
    } else if (openSettingsNav) {
      dispatch(setOpenSettingsNav(false));
    }
  }, [dispatch, matchesMD, selectedNavItem, openSettingsNav]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: isMobile || (isTablet && !isHorizontal) ? "0px" : drawerWidth,
          alignItems: "flex-start",
        }}
      >
        <Toolbar style={{ height: `${drawerWidth}px`, width: `${drawerWidth}px` }}>
          <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} color="primary">
            <Menu />
          </IconButton>
        </Toolbar>

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            zIndex: theme.zIndex.drawer + 2,
            display: isMobile || (isTablet && !isHorizontal) ? "block" : "none",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              maxWidth: isMobile || (isTablet && !isHorizontal) ? "80%" : `${drawerWidth}px`,
              width: "400px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          onMouseEnter={handleNavBarHover}
          sx={{
            zIndex: theme.zIndex.drawer,
            display: isMobile || (isTablet && !isHorizontal) ? "none" : "block",
            width: `${drawerWidth}px`,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: `${drawerWidth}px`,
              overflow: "hidden",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <ChatModalWithContext />
      <ContactHelpDialog useOpen={{ state: helpDialogOpen, setState: setHelpDialogOpen }} />
    </>
  );
}

export default ARNav;
