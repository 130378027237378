import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

type TokenProvider = () => string | Promise<string>;

export class APIInstance {
  readonly axiosInstance: AxiosInstance;
  readonly baseURL: string;

  constructor({
    baseURL,
    accessTokenFactory,
    apiVersion,
  }: {
    baseURL: string;
    accessTokenFactory: TokenProvider | null;
    apiVersion: string;
  }) {
    this.baseURL = baseURL;
    this.axiosInstance = axios.create({ baseURL });

    if (accessTokenFactory) {
      this.axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
        const token = await accessTokenFactory();
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Version = apiVersion;
        config.withCredentials = true;
        return config;
      });
    }
  }

  addFieldsToGetRequestURL(url: URL, ...args: string[]) {
    url.searchParams.append("fields", args.join(","));
    return url;
  }

  addFieldsToGetRequest(pathname: string, ...args: string[]) {
    const url = new URL(this.baseURL);
    url.pathname = pathname;
    this.addFieldsToGetRequestURL(url, ...args);
    return url;
  }

  addSearchParamsToURL(url: URL, ...args: [string, string][]) {
    args.forEach(([key, val]) => url.searchParams.append(key, val));
    return url;
  }

  addSearchParamsListToURL(url: URL, ...args: [string, string[]][]) {
    args.forEach(([key, val]) => url.searchParams.append(key, val.toString()));
    return url;
  }

  addSearchParams(pathname: string, ...args: [string, string][]) {
    const url = new URL(this.baseURL);
    url.pathname = pathname;
    this.addSearchParamsToURL(url, ...args);
    return url;
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<R> {
    return this.axiosInstance.put(url, data, config);
  }
  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig | undefined): Promise<R> {
    return this.axiosInstance.get(url, config);
  }
  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<R> {
    return this.axiosInstance.post(url, data, config);
  }
  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<R> {
    return this.axiosInstance.patch(url, data, config);
  }
  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig | undefined): Promise<R> {
    return this.axiosInstance.delete(url, config);
  }
}
