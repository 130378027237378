import { Annotation } from "services/ContentServer/Audit/serviceTypes/Annotation";
import { AnnotationIntersectionType } from "utils/Drawing";

export enum ModelInteractionMode {
  NoAction,
  Drawing,
  Erase,
  Model,
  Annotation3D,
  AddFile,
  Ruler,
  AutoCapture,
  ManualCapture,
  ModelTouchup,
  EditFacility,
}
export interface InteractionContentState {
  showInspectionDrawings: boolean;
  isDrawing: boolean;
  interactionMode: ModelInteractionMode;
  selectedAnnotation: Annotation | undefined;
  intersectionType: AnnotationIntersectionType;
  annotationsToSubmit: Annotation[];
  firstRulerPoint: THREE.Vector3 | undefined;
  secondRulerPoint: THREE.Vector3 | undefined;
  isPlaced: boolean;
}

export enum InteractionActionType {
  SHOW_INSPECTION_DRAWINGS,
  HIDE_INSPECTION_DRAWINGS,
  DISABLE_DRAWING,
  UPDATE_MODEL_INTERACTION_MODE,
  UPDATE_SELECTED_ANNOTATION,
  TOGGLE_MODEL_ANNOTATIONS,
  TOGGLE_PLANE_ANNOTATIONS,
  ADD_ANNOTATION_TO_SUBMIT,
  CLEAR_ANNOTATIONS_TO_SUBMIT,
  UPDATE_FIRST_RULER_POINT,
  UPDATE_SECOND_RULER_POINT,
  UPDATE_DRAWING,
  RESET_STATE,
  HIDE_ANNOTAITON_PANEL,
}

export interface InteractionContentStateAction {
  type: InteractionActionType;
  showInspectionDrawings?: boolean;
  isDrawing?: boolean;
  interactionMode?: ModelInteractionMode;
  selectedAnnotation?: Annotation;
  annotationToSubmit?: Annotation;
  firstRulerPoint?: THREE.Vector3;
  secondRulerPoint?: THREE.Vector3;
  isPlaced?: boolean;
}

export const initialInteractionState: InteractionContentState = {
  showInspectionDrawings: false,
  isDrawing: false,
  interactionMode: ModelInteractionMode.NoAction,
  selectedAnnotation: undefined,
  intersectionType: AnnotationIntersectionType.Model,
  annotationsToSubmit: [],
  firstRulerPoint: undefined,
  secondRulerPoint: undefined,
  isPlaced: false,
};

export const modelInteractionReducer = (
  state: InteractionContentState,
  action: InteractionContentStateAction
): InteractionContentState => {
  switch (action.type) {
    case InteractionActionType.SHOW_INSPECTION_DRAWINGS:
      return {
        ...state,
        showInspectionDrawings: true,
      };
    case InteractionActionType.HIDE_INSPECTION_DRAWINGS:
      return {
        ...state,
        showInspectionDrawings: false,
        interactionMode: state.interactionMode <= 4 ? ModelInteractionMode.NoAction : state.interactionMode,
      };
    case InteractionActionType.HIDE_ANNOTAITON_PANEL:
      return {
        ...state,
        interactionMode: state.interactionMode <= 4 ? ModelInteractionMode.NoAction : state.interactionMode,
      };
    case InteractionActionType.UPDATE_MODEL_INTERACTION_MODE:
      return {
        ...state,
        interactionMode: !action.interactionMode ? ModelInteractionMode.NoAction : action.interactionMode,
        showInspectionDrawings: action.showInspectionDrawings ?? state.showInspectionDrawings,
        firstRulerPoint: undefined,
        secondRulerPoint: undefined,
        isDrawing: false,
      };
    case InteractionActionType.UPDATE_SELECTED_ANNOTATION:
      return {
        ...state,
        selectedAnnotation: action.selectedAnnotation,
      };
    case InteractionActionType.DISABLE_DRAWING:
      return {
        ...state,
        interactionMode: ModelInteractionMode.NoAction,
        firstRulerPoint: undefined,
        secondRulerPoint: undefined,
      };
    case InteractionActionType.TOGGLE_MODEL_ANNOTATIONS:
      return {
        ...state,
        intersectionType: AnnotationIntersectionType.Model,
      };
    case InteractionActionType.TOGGLE_PLANE_ANNOTATIONS:
      return {
        ...state,
        intersectionType: AnnotationIntersectionType.Plane,
      };
    case InteractionActionType.ADD_ANNOTATION_TO_SUBMIT:
      return {
        ...state,
        annotationsToSubmit: action.annotationToSubmit
          ? [...state.annotationsToSubmit, action.annotationToSubmit]
          : state.annotationsToSubmit,
      };
    case InteractionActionType.CLEAR_ANNOTATIONS_TO_SUBMIT:
      return {
        ...state,
        annotationsToSubmit: [],
      };
    case InteractionActionType.UPDATE_FIRST_RULER_POINT:
      return {
        ...state,
        firstRulerPoint: action.firstRulerPoint,
      };
    case InteractionActionType.UPDATE_SECOND_RULER_POINT:
      return {
        ...state,
        secondRulerPoint: action.secondRulerPoint,
        isPlaced: action.isPlaced || false,
      };
    case InteractionActionType.UPDATE_DRAWING:
      return {
        ...state,
        isDrawing: action.isDrawing || false,
      };
    case InteractionActionType.RESET_STATE:
      return initialInteractionState;
    default:
      throw new Error();
  }
};
