import "@microsoft/applicationinsights-shims";

import { ReactPlugin, AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Config from "Config";
import { createBrowserHistory } from "history";
import React from "react";

const browserHistory = createBrowserHistory({ basename: "" });
export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: Config.INSIGHTS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export const insightsTracker = appInsights.appInsights;
export function ProvideAzureInsights({ children }: { children: React.ReactNode }) {
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
}
