import React, { useMemo, useState } from "react";

export interface CommonPageContextType {
  isPageLoading: boolean;
  setIsPageLoading: (isLoading: boolean) => void;
  isSiteCreating: boolean;
  setIsSiteCreating: (isCreating: boolean) => void;
  isAlignmentPage: boolean;
  setIsAlignmentPage: (isAlignment: boolean) => void;
}

const defaultContext: CommonPageContextType = {
  isPageLoading: false,
  setIsPageLoading: (isLoading: boolean) => {},
  isSiteCreating: false,
  setIsSiteCreating: (isCreating: boolean) => {},
  isAlignmentPage: false,
  setIsAlignmentPage: (isAlignment: boolean) => {},
};

export const ProvideCommonPageContext = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isAlignmentPage, setIsAlignmentPage] = useState<boolean>(false);

  const contextValues: CommonPageContextType = useMemo(
    () => ({
      isPageLoading: isLoading,
      setIsPageLoading: setIsLoading,
      isSiteCreating: isCreating,
      setIsSiteCreating: setIsCreating,
      isAlignmentPage: isAlignmentPage,
      setIsAlignmentPage: setIsAlignmentPage,
    }),
    [isLoading, isCreating, isAlignmentPage]
  );

  return <CommonPageContext.Provider value={contextValues}>{children}</CommonPageContext.Provider>;
};

export const CommonPageContext = React.createContext(defaultContext);
