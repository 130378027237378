export abstract class ISerialization {
  private requiredFields = ["id"];
  constructor(requiredFields?: string[]) {
    if (requiredFields) {
      this.requiredFields = requiredFields;
    }
  }
  abstract deserialize(json: any): void;
  abstract serialize(): any;
  validate(): boolean {
    const diff = this.requiredFields.filter((field) => !Object.keys(this).includes(field));
    if (diff.length) {
      console.error("Request is not valid, missing fields: " + diff);
    }
    return !diff.length;
  }
}

export class ISerializationPaginated<T extends ISerialization> {
  numPages?: number | null;
  count?: number | null;
  nextPage?: number | null;
  previousPage?: number | null;
  data?: T[];

  private extractPageSearchParam(url: string) {
    const pageSearchParam = url.match(/page=[0-9]+/);
    if (pageSearchParam && pageSearchParam.length > 0) {
      return parseInt(pageSearchParam[0].substring(5));
    }
  }

  deserializePaginated(json: any, createInstance: new () => T) {
    this.numPages = json["num_pages"];
    this.count = json["count"];
    const next = json["next"] ? (json["next"] as string) : null;
    if (next) {
      this.nextPage = this.extractPageSearchParam(next);
    }
    const previous = json["previous"] ? (json["previous"] as string) : null;
    if (previous) {
      this.previousPage = this.extractPageSearchParam(previous);
    }

    this.data = json["results"].map((entry: any) => {
      const object = new createInstance();
      object.deserialize(entry);
      return object;
    });
  }
}
