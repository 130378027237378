import { AnnotationBase } from "../types";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof ARFile | undefined } = {
  id: "id",
  target: "target",
  anchor_image: "anchorImage",
  target_offset: "targetOffset",
  target_box_extent: "targetBoxExtent",
};

export class ARFile extends ISerialization implements AnnotationBase {
  public id?: string | null;
  public target?: string | Blob | File | null;
  public anchorImage?: string | Blob | File | null;
  public targetOffset?: string | null;
  public targetBoxExtent?: string | null;

  constructor(arFile?: ARFile) {
    super();
    if (arFile) {
      Object.assign(this, arFile);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<ARFile>(this, serverToView, json);
    this.id = undefined;
  }

  serialize(): any {
    const request = serializerHelper<ARFile>(this, serverToView);
    return request;
  }
}
