import { DataChannelMessageType } from "./constants";
import { Device } from "services/ContentServer/Identity";

export declare type CallSubscriptionFn = (session: IWebRTCSession) => void;

export interface IWebRTC {
  call(peerID: string): Promise<void>;
  subscribeToCalls(callback: CallSubscriptionFn): () => void;
  start(): void;
  stop(): void;
}

export interface IWebRTCSession {
  isReceiving: boolean;
  isClosed: boolean;
  isInitialized: boolean;
  peerID: string;
  peerDevice?: Device;

  sendData(type: DataChannelMessageType, data: any): Promise<void>;

  onConnectionClosed: (type: ConnectionClosedType, reason?: string) => void;
  onConnectionPingReceived: () => void;
  onConnectionTimeout: () => void;

  onDataChannelOpen: () => void;

  onRemoteMediaStream: (stream: MediaStream) => void;
  onRemoteMediaStateMessage: (message: any) => void;
  onRemoteInspectorLocation: (message:any) => void;

  call(): void;
  answer(): void;
  hangup(): void;
  busy(): void;
  decline(): void;
  callFailed(type: ConnectionClosedType, reason?: string): void;

  replaceVideoTrack(track: MediaStreamTrack): void;
  replaceAudioTrack(track: MediaStreamTrack): void;
  setLocalMediaStream(stream: MediaStream): void;
}

export enum ConnectionClosedType {
  USER_HANGUP,
  USER_BUSY,
  USER_DECLINE,
  USER_HANDLED,

  PEER_HANGUP,
  PEER_BUSY,
  PEER_DECLINE,
  PEER_FAILED,

  OFFER_FAILED,
  ANSWER_FAILED,

  MEDIA_UNAVAILABLE,

  UNKNOWN,
}

export enum MediaErrorType {
  MEDIA_IN_USE,
  PERMISSION_DENIED,
  MEDIA_NOT_FOUND,
  OVERCONSTRAINED,
  UNKNOWN,
}
