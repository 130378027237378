import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { TaskProgress } from "../serviceTypes/TaskProgress";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const TaskProgressSWRKeys = {
  TASK_PROGRESS: "task_progress",
};

export class TaskProgressService extends ServiceBase<TaskProgress> {
  constructor(apiInstance: APIInstance) {
    super(TaskProgress, apiInstance, ENDPOINTS.TASK_PROGRESS);
  }
}
