import CssBaseline from "@mui/material/CssBaseline";
import { Theme } from "@mui/material/styles";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import React, { useMemo, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import CommonPageWithContext from "./CommonPage/CommonPage";
import CustomCircularProgress from "./CustomProgress/CustomCircularProgress";
import ProtectedRoute from "./ProtectedRoute";
import routes from "./Routes";
import { ARtheme, ARmobileTheme } from "assets/theme";
import useARMediaQuery from "hooks/useARMediaQuery";
import useReactiveTheme from "hooks/useReactiveTheme";
import AccountNotFoundPage from "views/AccountNotFoundPage";
import HomePage from "views/HomePage";
import InvalidInvitePage from "views/InvalidInvitePage";
import InvalidPermissionsPage from "views/InvalidPermissionsPage";
import LogoutPage from "views/LogoutPage.tsx/LogoutPage";
import NotSupportedPage from "views/NotSupportedPage/NotSupportedPage";
import OAuthCallbackView from "views/OAuthCallbackView";
import UserAlreadyExistsPage from "views/UserAlreadyExistsPage";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const LoginPage = lazy(() => import("views/LoginView"));
const ServerDownPage = lazy(() => import("views/ServerDownPage"));
const ReadOnlyPage = lazy(() => import("views/ReadOnlyPage/ReadOnlyPage"));

const App: React.FC = () => {
  useReactiveTheme();

  const isMobile = useARMediaQuery("mobile");
  const isTablet = useARMediaQuery("tablet");

  const protectedRoutes = useMemo(
    () =>
      Object.entries(routes).map(([key, value]) => (
        <Switch key={key}>
          <ProtectedRoute key={`${key}-exact`} path={value.path} component={value.component} />
        </Switch>
      )),
    []
  );

  return useMemo(
    () => (
      <div
        style={{
          float: "right",
          maxHeight: "100%",
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <ThemeProviderV5 theme={isMobile || isTablet ? ARmobileTheme : ARtheme}>
          <CssBaseline />
          <Router>
            <Suspense fallback={<CustomCircularProgress />}>
              <Switch>
                <Route exact path="/mobile">
                  <NotSupportedPage />
                </Route>
                <Route exact path="/logout">
                  <LogoutPage />
                </Route>
                <Route exact path="/.aad-callback">
                  <OAuthCallbackView />
                </Route>
                <Route exact path="/">
                  <HomePage />
                </Route>
                <Route exact path="/login">
                  <LoginPage />
                </Route>
                <Route exact path="/unavailable">
                  <ServerDownPage />
                </Route>
                <Route exact path="/accountnotfound">
                  <AccountNotFoundPage />
                </Route>
                <Route exact path="/invalidpermissions">
                  <InvalidPermissionsPage />
                </Route>
                <Route exact path="/invalidinvite">
                  <InvalidInvitePage />
                </Route>
                <Route exact path="/userexists">
                  <UserAlreadyExistsPage />
                </Route>
                <Route exact key={"readOnlyPage"} path={"/read"} component={ReadOnlyPage} />
                <CommonPageWithContext>
                  <Suspense
                    fallback={
                      <>
                        <CustomCircularProgress />
                        <div style={{ marginTop: "55%" }}></div>
                      </>
                    }
                  >
                    <Route component={() => <>{protectedRoutes}</>} />
                  </Suspense>
                </CommonPageWithContext>
              </Switch>
            </Suspense>
          </Router>
        </ThemeProviderV5>
      </div>
    ),
    [protectedRoutes, isMobile, isTablet]
  );
};

export default App;
