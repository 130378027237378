import { dot, cross } from "mathjs";
import { useState, useCallback, useMemo, useEffect } from "react";
import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";

import { drawingToPreviousDrawingStroke } from "./helpers";
import { Stroke3D } from "./stroke";
import { I3DDrawer, DrawingPlane, DrawingStroke3D, PreviousDrawingStroke3D } from "./types";
import { setLayers } from "components/CameraSelector/Common/ThreeUtils";
import { COLOR, RenderLayer } from "components/CameraSelector/Constants";
import { Annotation } from "services/ContentServer/Audit/serviceTypes/Annotation";
import { AnnotationType, Point2D, Point3D } from "services/ContentServer/Audit/types";

export const useDrawing = (contentParent: THREE.Group): I3DDrawer => {
  const currStroke = useMemo(() => new Stroke3D(), []);
  const [previousDrawings, setPreviousDrawings] = useState<PreviousDrawingStroke3D[]>([]);
  const [currentDrawingPlane, setCurrentDrawingPlane] = useState(new DrawingPlane());

  const cleanLine = (line?: Line2) => {
    line?.material?.dispose();
    line?.geometry?.dispose();
    line?.clear();
  };

  const project3DPoint = useCallback(
    (selectorPlane: DrawingPlane, point: Point3D, size: { width: number; height: number }) => {
      const upVector = selectorPlane.upVertex.map((vertex, i) => vertex - selectorPlane.originVertex[i]);
      const rightVector = selectorPlane.rightVertex.map((vertex, i) => vertex - selectorPlane.originVertex[i]);
      const pointVector = point.map((vertex, i) => vertex - selectorPlane.originVertex[i]);
      const y = dot(pointVector, upVector) / dot(upVector, upVector);
      const x = dot(pointVector, rightVector) / dot(rightVector, rightVector);
      return [x * size.width, size.height - y * size.height] as Point2D;
    },
    []
  );

  const planeNormal = useCallback((selectorPlane: DrawingPlane) => {
    const upVector = selectorPlane.upVertex.map((vertex, i) => vertex - selectorPlane.originVertex[i]);
    const rightVector = selectorPlane.rightVertex.map((vertex, i) => vertex - selectorPlane.originVertex[i]);
    const normal = cross(upVector, rightVector) as number[];
    return new THREE.Vector3(normal[0], normal[1], normal[3]);
  }, []);

  const deproject2DPoint = useCallback((plane: DrawingPlane, point: Point2D) => {
    const upVector = plane.upVertex.map((vertex, i) => vertex - plane.originVertex[i]);
    const rightVector = plane.rightVertex.map((vertex, i) => vertex - plane.originVertex[i]);
    const scaledRightVector = rightVector.map((e) => e * point[0]);
    const scaledUpVector = upVector.map((e) => e * point[1]);
    return plane.originVertex.map((vertex, i) => vertex + scaledUpVector[i] + scaledRightVector[i]);
  }, []);

  const isValidPlane = (originPlane: DrawingPlane, raycaster: THREE.Raycaster, point: THREE.Vector3) => {
    const originPoint = originPlane.originVertex;
    const upVector = originPlane.upVertex;
    const originVector = new THREE.Vector3(originPoint[0], originPoint[1], originPoint[2]);
    const upThreeVector = new THREE.Vector3(upVector[0], upVector[1], upVector[2]);
    const hyp = originVector.distanceTo(point);

    const dir = new THREE.Vector3();
    dir.subVectors(originVector, point);
    const angle = dir.angleTo(upThreeVector);
    const distance = Math.cos(angle) * hyp;
    upThreeVector.multiplyScalar(distance);

    const output = new THREE.Vector3();
    output.addVectors(point, upThreeVector);

    const d1 = new THREE.Vector3();
    d1.subVectors(point, raycaster.ray.origin).normalize();

    const d2 = new THREE.Vector3();
    d2.subVectors(output, originVector).normalize();

    const p1 = raycaster.ray.origin;
    const p2 = originVector;

    const b = (d1.x * p1.y - d1.x * p2.y + d1.y * p2.x - d1.y * p1.x) / (d1.x * d2.y - d1.y * d2.x);
    const a = (p2.y - p1.y + d2.y * b) / d1.y;

    let planePoint = new THREE.Vector3();
    planePoint = p1.addScaledVector(d1, a);
    const planeDist = planePoint.distanceTo(point);
    if (planeDist > 0.1) {
      return false;
    }
    return true;
  };

  const addToStroke = useCallback(
    async (
      intersection: THREE.Intersection,
      raycaster: THREE.Raycaster,
      color?: COLOR,
      lineWidth?: number,
      planeCheck = false
    ) => {
      if (intersection.face) {
        let valid = true;
        const point = intersection.point.addScaledVector(intersection.face.normal, 0.02);
        const invMatrix = new THREE.Matrix4();
        const matrix = invMatrix.copy(contentParent.matrixWorld).invert();
        point.applyMatrix4(matrix);
        const drawingPlane = new DrawingPlane(
          point.toArray(),
          intersection.face.normal.toArray(),
          intersection.face.normal.cross(new THREE.Vector3(1, 0, 0)).toArray()
        );

        if (currStroke.line) {
          contentParent.remove(currStroke.line);
        }
        if (currStroke.points.length > 0 && planeCheck) {
          const lastPoint = currStroke.points[currStroke.points.length - 1];
          const dist = point.distanceTo(new THREE.Vector3(lastPoint[0], lastPoint[1], lastPoint[2]));
          if (dist > 0.1 && currStroke.drawingPlane) {
            valid = isValidPlane(currStroke.drawingPlane, raycaster, point);
          }
        }
        if (valid) {
          currStroke.add(point.toArray(), drawingPlane, color, lineWidth);
        }
        if (currStroke.line) {
          contentParent.add(currStroke.line);
          setLayers(currStroke.line, RenderLayer.ANNOTATION_LAYER);
          currStroke.line.layers.enable(RenderLayer.ALL_LAYERS);
        }
      }
    },
    [currStroke, contentParent]
  );

  const clearCurrentStroke = useCallback(() => {
    if (currStroke.line) {
      contentParent.remove(currStroke.line);
    }
    currStroke.clear();
  }, [currStroke, contentParent]);

  const popCurrentStroke = useCallback((): DrawingStroke3D | undefined => {
    const strokeToSubmit =
      currStroke.points.length && currStroke.line
        ? { drawingLine: { points: currStroke.points, line: currStroke.line }, plane: currentDrawingPlane }
        : undefined;
    clearCurrentStroke();
    return strokeToSubmit;
  }, [currStroke, currentDrawingPlane, clearCurrentStroke]);

  const peekCurrentStroke = useCallback((): DrawingStroke3D | undefined => {
    const strokeToSubmit =
      currStroke.points.length && currStroke.line
        ? { drawingLine: { points: currStroke.points, line: currStroke.line }, plane: currentDrawingPlane }
        : undefined;
    return strokeToSubmit;
  }, [currStroke, currentDrawingPlane]);

  useEffect(() => {
    previousDrawings
      .filter((drawing) => drawing.isDisplayed)
      .forEach((stroke) => {
        contentParent.add(stroke.drawingLine.line);
        setLayers(stroke.drawingLine.line, RenderLayer.ANNOTATION_LAYER);
      });
    return () => {
      previousDrawings.forEach((stroke) => {
        contentParent.remove(stroke.drawingLine.line);
        cleanLine(stroke.drawingLine.line);
        cleanLine(stroke.drawingLine.highlight);
      });
    };
  }, [previousDrawings, contentParent]);

  const updatePreviousDrawings = useCallback((inspectionDrawings: Annotation[]) => {
    setPreviousDrawings(
      inspectionDrawings
        .filter((drawing) => drawing.type === AnnotationType.DRAWING)
        .map((drawing) => drawingToPreviousDrawingStroke(drawing))
    );
  }, []);

  const highlightStrokeGroup = useCallback(
    (id: string) => {
      previousDrawings.forEach((drawing: PreviousDrawingStroke3D) => {
        if (drawing.drawingLine.highlight) {
          if (id === drawing.id) {
            drawing.drawingLine.highlight.visible = true;
            return;
          }
        }
      });
    },
    [previousDrawings]
  );

  const removeHighlight = useCallback(() => {
    previousDrawings.forEach((drawing: PreviousDrawingStroke3D) => {
      if (drawing.drawingLine.highlight) {
        drawing.drawingLine.highlight.visible = false;
      }
    });
  }, [previousDrawings]);

  return {
    clearCurrentStroke,
    addToStroke,
    project3DPoint,
    deproject2DPoint,
    planeNormal,
    updatePreviousDrawings,
    popCurrentStroke,
    peekCurrentStroke,
    setCurrentDrawingPlane,
    highlightStrokeGroup,
    removeHighlight,
  };
};
