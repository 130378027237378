import queryString from "query-string";
import { useMemo, useCallback } from "react";
import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { ISnapshot } from "redux/api/snapshotApi";

import portalRoutes from "components/Routes";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { removeTrailingSlash } from "utils/StringUtils";
import { addOrReplaceQuery, AddToQuery, removeFromURL } from "utils/urlUtils";

export enum URLAction {
  ADD,
  REMOVE,
  RESET,
}

export enum URLField {
  VIEW = "VIEW",
  SITE = "SITE",
  FACILITY = "FACILITY",
  ASSET = "ASSET",
  ITEM = "ITEM",
  NAME = "NAME",
  SORTBY = "SORTBY",
  SORTORDER = "SORTORDER",
  SCOPE = "SCOPE",
  SEARCHTYPE = "SEARCHTYPE",
  TAB = "TAB",
  LABELS = "LABELS",
}

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const handleChangeSnapshot = useCallback(
    (newSnapshot?: Snapshot | ISnapshot) => {
      let url = portalRoutes.assetPage.path;
      const newSnapshotId = newSnapshot?.id;
      if (newSnapshotId) {
        url = AddToQuery(url, ["id", newSnapshotId]);
      }
      history.push(url);
    },
    [history]
  );

  const updateURL = useCallback(
    (args: [URLField, any][], actions: URLAction[], isPush = true) => {
      let search = location.search;
      if (actions[0] === URLAction.RESET) {
        search = "";
      }

      args.forEach((arg, idx) => {
        if (actions[idx] === URLAction.ADD || actions[idx] === URLAction.RESET) {
          search = addOrReplaceQuery(search, [URLField[arg[0]].toLowerCase(), arg[1]]);
        } else {
          if (arg[1]) {
            search = removeFromURL(search, [URLField[arg[0]].toLowerCase(), arg[1]]);
          }
        }
      });

      isPush ? history.push(location.pathname + search) : history.replace(location.pathname + search);
    },
    [location, history]
  );

  const query = useMemo(() => {
    return {
      ...queryString.parse(location.search),
      ...params,
    } as {
      [key: string]: string | string[] | null | undefined;
    };
  }, [location.search, params]);

  const getStringQuery = useCallback(
    (key: string) => {
      if (key in query) {
        const queryValue = query[key];
        if (typeof queryValue === "string") return queryValue;
      }
    },
    [query]
  );

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: removeTrailingSlash(location.pathname),
      query: query,
      match,
      location,
      history,
      handleChangeSnapshot: handleChangeSnapshot,
      updateURL: updateURL,
      getStringQuery: getStringQuery,
    };
  }, [history, location, query, match, handleChangeSnapshot, updateURL, getStringQuery]);
}
