import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Rescan } from "../serviceTypes/Rescan";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const RescanSWRKeys = {
  RESCAN: "rescan",
};

export class RescanService extends ServiceBase<Rescan> {
  constructor(apiInstance: APIInstance) {
    super(Rescan, apiInstance, ENDPOINTS.RESCAN);
  }
}
