import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Difference } from "../serviceTypes/Difference";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const DifferenceSWRKeys = {
  Difference: "Difference",
};
export class DifferenceService extends ServiceBase<Difference> {
  constructor(apiInstance: APIInstance) {
    super(Difference, apiInstance, ENDPOINTS.DIFFERENCE);
  }
}
