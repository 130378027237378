// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import * as GaussianSplats3D from "@interaptix/gsv";
import React, { useCallback, useContext, useMemo, useState } from "react";
import * as THREE from "three";

import { DeviceContext } from "./DeviceContext";
import { FacilityContext } from "./FacilityContext";
import { MeshToggleContext } from "./MeshToggleContext";
import { SnapshotContext } from "./SnapshotContext";
import { getMaxObjectDim } from "components/CameraSelector/Common/ThreeUtils";
import FrustumBufferGeometry from "components/CameraSelector/ThreeWrappers/Frustum";
import { FrustumScene, ModelViewerScene } from "utils/Three/types";
import { useFrustumScene } from "utils/Three/useFrustumScene";
import { getSnapshotModelData } from "utils/Three/useModels";
import { SegmentData } from "utils/Three/useModels";
import { useModelViewerScene } from "utils/Three/useModelViewerScene";

export enum CameraMode {
  Persp,
  Ortho,
}

export interface SceneContextType {
  showStats: boolean;
  setShowStats: (show: boolean | ((prev: boolean) => boolean)) => void;
  modelScene: ModelViewerScene;
  frustumScene: FrustumScene;
  selectedObjects: THREE.Object3D[];
  maxObjectDim: number;
  snapModelData: SegmentData[] | SegmentData[][];
  setSelectedObjects: React.Dispatch<React.SetStateAction<THREE.Object3D[]>>;
  modelBoundingBox: THREE.Box3 | null;
  setModelBoundingBox: React.Dispatch<React.SetStateAction<THREE.Box3 | null>>;
  showFacility: boolean;
  setShowFacility: (show: boolean) => void;
  showAllSnapshots: boolean;
  setShowAllSnapshots: (show: boolean) => void;
  showPoses: boolean;
  setShowPoses: (updating: boolean) => void;
  jumpToCapture: boolean;
  setJumpToCapture: (updating: boolean) => void;
  cameraMode: CameraMode;
  setCameraMode: (mode: CameraMode) => void;
  splatMesh: GaussianSplats3D.SplatMesh | undefined;
  setSplatMesh: (mesh: GaussianSplats3D.SplatMesh | undefined) => void;
  modelBoundingTreeComputed: boolean;
  setModelBoundingTreeComputed: (computed: boolean | ((prev: boolean) => boolean)) => void;
  resetToggles: () => void;
  visibleSnaps: string[];
  setVisibleSnaps: (visibleSnaps: string[]) => void;
  resetStats: () => void;
  cursorLocation: THREE.Intersection | undefined;
  setCursorLocation: (location: THREE.Intersection | undefined) => void;
}

export const defaultContext: SceneContextType = {
  showStats: false,
  setShowStats: (show: boolean | ((prev: boolean) => boolean)) => {},
  modelScene: {
    scene: new THREE.Scene(),
    camera: new THREE.PerspectiveCamera(),
    gridHelper: new THREE.GridHelper(),
    contentParent: new THREE.Group(),
    resetScene: () => {},
  },
  frustumScene: {
    frustumGeometryNoFarPlanes: new FrustumBufferGeometry(0, 0, 0, 0, 0, false),
    frustumCamera: new THREE.PerspectiveCamera(),
    traverserCamera: new THREE.PerspectiveCamera(),
    frustum: new THREE.Mesh(),
    wireFrustum: new THREE.LineSegments(),
  },
  selectedObjects: [],
  maxObjectDim: 0,
  snapModelData: [],
  setSelectedObjects: (box: React.SetStateAction<THREE.Object3D[]>) => {},
  modelBoundingBox: null,
  setModelBoundingBox: (box: React.SetStateAction<THREE.Box3 | null>) => {},
  showFacility: false,
  setShowFacility: (show: boolean) => {},
  showAllSnapshots: false,
  setShowAllSnapshots: (show: boolean) => {},
  showPoses: true,
  setShowPoses: (updating: boolean) => {},
  jumpToCapture: false,
  setJumpToCapture: (updating: boolean) => {},
  cameraMode: CameraMode.Ortho,
  setCameraMode: (mode: CameraMode) => {},
  splatMesh: undefined,
  setSplatMesh: (mesh: GaussianSplats3D.SplatMesh | undefined) => {},
  modelBoundingTreeComputed: false,
  setModelBoundingTreeComputed: (computed: boolean | ((prev: boolean) => boolean)) => {},
  resetToggles: () => {},
  visibleSnaps: [],
  setVisibleSnaps: (visibleSnaps: string[]) => {},
  resetStats: () => {},
  cursorLocation: undefined,
  setCursorLocation: (location: THREE.Intersection | undefined) => {},
};

export const ProvideSceneContext = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const [showStats, setShowStats] = useState<boolean>(false);
  const [selectedObjects, setSelectedObjects] = useState<THREE.Object3D<THREE.Event>[]>([]);
  const { textureEnabled } = useContext(MeshToggleContext);
  const { deviceInfo } = useContext(DeviceContext);
  const { selectedSnapshot: snapshot } = useContext(SnapshotContext);
  const [modelBoundingBox, setModelBoundingBox] = useState<THREE.Box3 | null>(null);
  const maxObjectDim = useMemo(() => getMaxObjectDim(modelBoundingBox), [modelBoundingBox]);
  const [cameraMode, setCameraMode] = useState(CameraMode.Ortho);
  const [splatMesh, setSplatMesh] = useState<GaussianSplats3D.SplatMesh | undefined>(undefined);
  const [modelBoundingTreeComputed, setModelBoundingTreeComputed] = useState(false);
  const [showAllSnapshots, setShowAllSnapshots] = useState(false);
  const [visibleSnaps, setVisibleSnaps] = useState<string[]>([]);
  const [cursorLocation, setCursorLocation] = useState<THREE.Intersection | undefined>(undefined);

  const modelScene = useModelViewerScene(textureEnabled, cameraMode);

  const frustumScene = useFrustumScene(deviceInfo, maxObjectDim, modelScene.contentParent);
  const [showFacility, setShowFacility] = useState<boolean>(false);
  const [showPoses, setShowPoses] = useState<boolean>(true);
  const [jumpToCapture, setJumpToCapture] = useState<boolean>(false);
  const { facilitySnapshotData } = useContext(FacilityContext);

  const resetToggles = useCallback(() => {
    setShowFacility(defaultContext.showFacility);
    setShowPoses(defaultContext.showPoses);
    setJumpToCapture(defaultContext.jumpToCapture);
    setShowAllSnapshots(defaultContext.showAllSnapshots);
  }, [setShowFacility, setShowPoses, setJumpToCapture, setShowAllSnapshots]);

  const resetStats = useCallback(() => {
    const tweakPane = document.querySelector(".tp-dfwv") as HTMLDivElement;
    const infoDiv = document.querySelector("#three-perf-ui") as HTMLDivElement;
    if (tweakPane) {
      tweakPane.remove();
    }
    if (infoDiv) {
      infoDiv.remove();
    }
    setShowStats(false);
  }, [setShowStats]);

  const snapModelData = useMemo(() => {
    if (showFacility) {
      return facilitySnapshotData;
    } else {
      return getSnapshotModelData(snapshot, textureEnabled);
    }
  }, [snapshot, textureEnabled, showFacility, facilitySnapshotData]);

  const contextValues: SceneContextType = useMemo(
    () => ({
      showStats: showStats,
      setShowStats: setShowStats,
      modelScene: modelScene,
      frustumScene: frustumScene,
      selectedObjects: selectedObjects,
      maxObjectDim: maxObjectDim,
      snapModelData: snapModelData,
      setSelectedObjects: setSelectedObjects,
      modelBoundingBox: modelBoundingBox,
      setModelBoundingBox: setModelBoundingBox,
      showFacility: showFacility,
      setShowFacility: setShowFacility,
      showAllSnapshots: showAllSnapshots,
      setShowAllSnapshots: setShowAllSnapshots,
      showPoses: showPoses,
      setShowPoses: setShowPoses,
      jumpToCapture: jumpToCapture,
      setJumpToCapture: setJumpToCapture,
      cameraMode: cameraMode,
      setCameraMode: setCameraMode,
      splatMesh: splatMesh,
      setSplatMesh: setSplatMesh,
      modelBoundingTreeComputed: modelBoundingTreeComputed,
      setModelBoundingTreeComputed: setModelBoundingTreeComputed,
      resetToggles: resetToggles,
      visibleSnaps: visibleSnaps,
      setVisibleSnaps: setVisibleSnaps,
      resetStats: resetStats,
      cursorLocation: cursorLocation,
      setCursorLocation: setCursorLocation,
    }),
    [
      showStats,
      modelScene,
      frustumScene,
      selectedObjects,
      maxObjectDim,
      snapModelData,
      showFacility,
      showAllSnapshots,
      showPoses,
      jumpToCapture,
      cameraMode,
      modelBoundingBox,
      modelBoundingTreeComputed,
      visibleSnaps,
      splatMesh,
      cursorLocation,
      resetToggles,
      resetStats,
    ]
  );
  return <SceneContext.Provider value={contextValues}>{children}</SceneContext.Provider>;
};

export const SceneContext = React.createContext(defaultContext);
