import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

export default function PersistentDialog({
  open,
  dialogTitle,
  dialogContent,
}: {
  open: boolean;
  dialogTitle: string;
  dialogContent: string;
}) {
  const theme = useTheme();
  return (
    <>
      <Dialog
        open={open}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
          },
          width: "100%",
        }}
      >
        <DialogTitle style={{ width: "100%", padding: "24px 0px 0px 0px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography
              variant="dalmatianBase"
              sx={{
                fontWeight: 800,
                fontSize: "16px",
                lineHeight: "19px",
                color: theme.palette.dalmatianDesign.dark,
              }}
            >
              {dialogTitle}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Grid item xs={12} style={{ width: "100%", paddingBottom: "32px" }}>
              <Typography>{dialogContent}</Typography>
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <CircularProgress color="inherit" size={50} thickness={3} />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
