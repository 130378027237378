import { MailOutlineSharp } from "@mui/icons-material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TenantFeatureEnum } from "redux/api/types";
import { useUpdateUserMutation, useUpdateUserProfileMutation, useDeleteUserMutation } from "redux/api/userApi";
import useApp from "redux/hooks/useApp";
import useSWR, { mutate } from "swr";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import ResendInviteDialog from "../Users/ResendInviteDialog";
import DatePicker from "components/DalmatianDesignComponents/DatePicker";
import { FilterPopup } from "components/DalmatianDesignComponents/DropdownFilter";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import AccountDialog from "components/Dialog/AccountDialog";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import { SettingsSwitch } from "components/Switch/StyledSwitch";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { hasProfile, Profile, User, UserPending, UserTypes } from "services/ContentServer/Identity";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { UserInvite } from "services/ContentServer/Identity/serviceTypes/UserInvite";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { HOUR_IN_DAY, MIN_IN_HOUR, MS_IN_SEC, SEC_IN_MIN } from "utils/ConversionConstants";
import { msToTime } from "utils/DateUtils";

const ProfileAccountTab = ({
  user,
  userProfile,
  userType,
  setProcessing,
  setProcessingMsg,
}: {
  user: User | UserPending | undefined;
  userProfile: Profile | false | undefined;
  userType: UserTypes | undefined;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessingMsg: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const app = useApp();
  const [updatedUserType, setUpdatedUserType] = useState<UserTypes[]>([UserTypes.Active]);
  const { contentServer } = useContext(RequestContext);
  const snackbar = useSnackbar();
  const { featureAccess, currentUser } = useUsers();
  const timePickerRef = useRef<HTMLButtonElement>(null);
  const [invCountdown, setInvCountdown] = useState(-1);
  const [deleting, setDeleting] = useState(false);
  const [expiryDate, setExpiryDate] = useState<Date | null | undefined>();
  const [expiryToggle, setExpiryToggle] = useState<boolean>(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resendId, setResendId] = useState<string | undefined>(undefined);
  const [showBtns, setShowBtns] = useState<boolean>(false);
  const [updateUser] = useUpdateUserMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [delUser] = useDeleteUserMutation();
  const [createdDate, setCreatedDate] = useState<string>("");
  const { history } = useRouter();

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  const { data: invites } = useSWR([UserInviteSWRKeys.USER_INVITES], () => contentServer.userInviteService.list());

  const deleteInvite = useCallback(
    (inviteId: string) => {
      try {
        mutate(
          [UserInviteSWRKeys.USER_INVITES],
          (prevInvs: UserInvite[] | undefined) => (prevInvs ? [...prevInvs].filter((inv) => inv.id !== inviteId) : []),
          false
        );
        contentServer.userInviteService.delete(inviteId);
      } catch (err) {
        mutate([UserInviteSWRKeys.USER_INVITES]);
        console.error();
      }
    },
    [contentServer.userInviteService]
  );
  useEffect(() => {
    if (user && invites) {
      const similarInvites = invites?.filter((invitedUsers) => invitedUsers.username === user.name);
      let recentInvite: UserInvite | undefined = similarInvites.shift();
      const excessInvites = similarInvites.map((invite) => {
        if (
          recentInvite &&
          invite.createdAt &&
          recentInvite.createdAt &&
          Date.parse(invite.createdAt) > Date.parse(recentInvite.createdAt)
        ) {
          const extra = recentInvite;
          recentInvite = invite;
          return extra;
        } else {
          return invite;
        }
      });
      setCreatedDate(recentInvite && recentInvite.createdAt ? recentInvite.createdAt : "");
      const profileRoute = SettingRoutesDict.get(SettingTab.PROFILE);
      if (profileRoute && recentInvite) {
        history.push(`${profileRoute.path}?userId=${recentInvite.id}&isPending=true`);
      }
      for (const inv of excessInvites) {
        if (inv.id) {
          deleteInvite(inv.id);
        }
      }
    }
  }, [user, invites, deleteInvite, history]);

  useEffect(() => {
    setInvCountdown(
      createdDate
        ? new Date(createdDate).getTime() +
            7 * HOUR_IN_DAY * MIN_IN_HOUR * SEC_IN_MIN * MS_IN_SEC -
            new Date().getTime()
        : -1
    );
  }, [createdDate]);

  useEffect(() => {
    if (userProfile) {
      setExpiryToggle(userProfile.activeUntil !== null);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile) {
      setExpiryDate(userProfile.activeUntil ? new Date(userProfile.activeUntil) : null);
    } else {
      setExpiryDate(null);
    }
  }, [userProfile]);

  useEffect(() => {
    setUpdatedUserType([userType || UserTypes.Active]);
  }, [userType]);

  const owner = useMemo(() => {
    if (user === undefined) {
      return false;
    }
    return user.id === currentUser?.id;
  }, [currentUser?.id, user]);

  const modifyAccess = useMemo(() => featureAccess[PERMISSION_NAME.USER].update, [featureAccess]);
  const userAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER];
  }, [featureAccess]);
  const invExpiryDate = useMemo(() => {
    if (user === undefined) {
      return undefined;
    }
    if (userType === UserTypes.Pending && user.createdAt) {
      const createdAt = new Date(user.createdAt);
      createdAt.setDate(createdAt.getDate() + 1);
      return createdAt;
    }
  }, [user, userType]);

  const invExpiryCountdown = useMemo(() => {
    if (userType === UserTypes.Pending && invExpiryDate !== undefined) {
      return msToTime(invCountdown).toString();
    }
  }, [invCountdown, invExpiryDate, userType]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (invCountdown > 0) {
        setInvCountdown(invCountdown - 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [invCountdown]);

  const removeActiveUntil = useCallback(
    async (userWithProfile: User) => {
      if (!userProfile) {
        console.error("User profile is undefined.");
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error removing account expiry date. User was not found.",
        });
        return;
      }
      try {
        const updatedProfile = { ...userProfile };
        updatedProfile.activeUntil = null;

        updateUserProfile({ id: userWithProfile.id, profile: updatedProfile });
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Successfully updated account expiry date.",
        });
      } catch (err) {
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error updating account expiry date.",
        });
      }
    },
    [snackbar, userProfile, updateUserProfile]
  );

  const deleteUser = useCallback(
    async (isInv?: boolean) => {
      if (user === undefined) {
        console.error("User is undefined.");
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error deleting user. User was not found.",
        });
        return;
      }
      setProcessingMsg(`Deleting ${isInv ? "invite" : "user"}...`);
      setProcessing(true);
      try {
        if (isInv) {
          mutate(
            [UserInviteSWRKeys.USER_INVITES],
            (prevInvs: UserInvite[]) => {
              const updatedInvs = [...prevInvs].filter((inv) => inv.id !== user.id);
              return updatedInvs;
            },
            false
          );
          await contentServer.userInviteService.delete(user.id).catch((error) => {
            console.error(error);
            mutate([UserInviteSWRKeys.USER_INVITES]);
            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: `Error deleting invite.`,
            });
          });
        } else {
          await delUser(user.id).catch((error) => {
            console.error(error);
            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: `Error deleting user.`,
            });
          });
        }

        const profileTab = SettingRoutesDict.get(SettingTab.USERS);
        if (profileTab) {
          history.push(profileTab.path);

          setTimeout(() => {
            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: `Successfully deleted ${isInv ? "invite" : "user"}`,
            });
          }, 0);
        }
      } finally {
        setDeleting(false);
        setProcessing(false);
        setProcessingMsg("");
      }
    },
    [delUser, contentServer.userInviteService, setProcessing, setProcessingMsg, snackbar, user, history]
  );

  const changeUserActiveStatus = useCallback(
    async (userWithProfile: User) => {
      if (!userProfile) {
        console.error("User profile is undefined.");
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error updating user status.",
        });
        return;
      }

      try {
        const newUser = { ...userWithProfile, isActive: !userWithProfile.isActive };
        if (!userWithProfile.isActive) {
          newUser.userProfile = { ...newUser.userProfile, activeUntil: null };
        }

        updateUser({ id: userWithProfile.id, user: newUser });
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: `Successfully ${updatedUserType[0] === UserTypes.Deactivated ? "deactivated" : "activated"} user.`,
        });
      } catch (error) {
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: `Error ${updatedUserType[0] === UserTypes.Deactivated ? "deactivating" : "activating"} user.`,
        });
        console.error(error);
      }
    },
    [updateUser, snackbar, updatedUserType, userProfile]
  );

  const updateActiveUntil = useCallback(async () => {
    if (user === undefined || !userProfile) {
      console.error("User profile is undefined.");
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error updating account expiry date. User is not found.",
      });
      return;
    }
    const userWithProfile = hasProfile(user);
    if (expiryDate && userProfile && userWithProfile) {
      try {
        const updatedProfile = { ...userProfile };
        updatedProfile.activeUntil = expiryDate.toISOString();
        updateUserProfile({ id: user.id, profile: updatedProfile });

        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Successfully updated account expiry date.",
        });
      } catch (e) {
        console.error(e);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error updating account expiry date.",
        });
      }
    } else {
      setExpiryToggle(false);
    }
  }, [expiryDate, snackbar, user, userProfile, updateUserProfile]);

  const handleDiscardChanges = useCallback(() => {
    if (userProfile) {
      setExpiryDate(userProfile.activeUntil ? new Date(userProfile.activeUntil) : null);
      setExpiryToggle(userProfile.activeUntil !== null);
    } else {
      setExpiryDate(null);
      setExpiryToggle(false);
    }
    setShowBtns(false);
  }, [userProfile]);

  const handleSaveChange = useCallback(async () => {
    if (app.isFeatureEnabled(TenantFeatureEnum.AccountExpiry) && user) {
      const userWithProfile = hasProfile(user);
      if (expiryToggle) {
        await updateActiveUntil();
      } else {
        if (userWithProfile) {
          await removeActiveUntil(userWithProfile);
        }
      }
    }
    setShowBtns(false);
  }, [expiryToggle, app, removeActiveUntil, updateActiveUntil, user]);

  useEffect(() => {
    setDeleteDialogOpen(deleting);
  }, [deleting]);

  const expirationComponent = useMemo(() => {
    if (user === undefined) {
      return <></>;
    }
    const userWithProfile = hasProfile(user);
    if (!userWithProfile) {
      return <></>;
    }
    if (modifyAccess && !owner) {
      return (
        <SettingsSwitch
          checked={expiryToggle}
          onChange={() => {
            setExpiryToggle((prev) => !prev);
            setShowBtns(true);
          }}
        />
      );
    } else {
      return (
        <Typography variant="body1">
          {expiryDate
            ? `${owner ? "Your" : "This"} account will deactivate at: ${expiryDate.toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}`
            : `${owner ? "Your" : "This"} account has no expiry date set.`}
        </Typography>
      );
    }
  }, [expiryDate, expiryToggle, modifyAccess, owner, user]);

  const statusDialogContent = useMemo(() => {
    let text = "Are you sure you would like to ";
    if (updatedUserType[0] === UserTypes.Deactivated) {
      text += "deactivate this account? This user will no longer have access to AptixAR.";
    } else {
      text += "activate this account?";
    }
    return text;
  }, [updatedUserType]);

  return (
    <>
      <AccountDialog
        useOpen={{ state: deleteDialogOpen, setState: setDeleteDialogOpen }}
        dialogTitle={"Delete Account"}
        dialogContent={"Are you sure you would like to delete this account?"}
        buttonText={"Delete"}
        onConfirm={async () => await deleteUser()}
        onClose={() => setDeleting(false)}
        deleteStyle={true}
      />
      <AccountDialog
        useOpen={{ state: statusDialogOpen, setState: setStatusDialogOpen }}
        dialogTitle={`${updatedUserType[0] === UserTypes.Deactivated ? "Deactivate" : "Activate"} Account`}
        dialogContent={statusDialogContent}
        buttonText={updatedUserType[0] !== UserTypes.Active ? "Deactivate" : "Activate"}
        onConfirm={async () => {
          if (!user) {
            return;
          }
          const userWithProfile = hasProfile(user);
          if (userWithProfile) {
            await changeUserActiveStatus(userWithProfile);
          }
        }}
        onClose={() => setUpdatedUserType([userType || UserTypes.Active])}
      />
      <ResendInviteDialog resendId={resendId} setResendId={setResendId} />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 3,
            gap: 3,
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            overflowY: "scroll",
            maxHeight:
              useWindowSize().height -
              (userType === UserTypes.Active && (owner || modifyAccess) && showBtns ? 240 : 190),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: 0,
              gap: 1,
              minWidth: "300px",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">Account Status</Typography>
            {userAccess.delete && !owner && userType && userType !== UserTypes.Pending ? (
              <FilterPopup
                options={[
                  { id: "Active", label: "Active" },
                  { id: "Deactivated", label: "Deactivated" },
                ]}
                placeholderText={""}
                currentValue={updatedUserType}
                setCurrentValue={(values: string[]) => {
                  const newUserType = values as UserTypes[];

                  if (newUserType[0] && newUserType[0] !== updatedUserType[0]) {
                    setUpdatedUserType(newUserType);
                    setStatusDialogOpen(true);
                  }
                }}
                singleSelect={true}
                disableClear={true}
                selectAll={false}
                selectAllText={""}
                outlinedStyle={true}
              />
            ) : (
              <Typography variant="body1">{userType === UserTypes.Pending ? "Pending Invite" : userType}</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              padding: 0,
              gap: 4,
              minWidth: "300px",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            {userType !== UserTypes.Deactivated &&
            (userType === UserTypes.Pending || app.isFeatureEnabled(TenantFeatureEnum.AccountExpiry)) ? (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: modifyAccess && !owner ? "row" : "column",
                    alignItems: "flex-start",
                    gap: modifyAccess && !owner ? 4 : 1,
                    padding: 0,
                  }}
                >
                  <Typography variant="subtitle1">
                    {userType === UserTypes.Pending
                      ? "Invite Expiry Date"
                      : modifyAccess && !owner
                      ? "Set Account Expiry Date"
                      : "Account Expiry Date"}
                  </Typography>
                  {expirationComponent}
                </Box>
                {userType !== UserTypes.Pending ? (
                  expiryToggle && !owner && userType === UserTypes.Active ? (
                    <>
                      <Box
                        sx={{ display: "flex", alignItems: "flex-start", gap: 2, height: "36px" }}
                        ref={timePickerRef}
                      >
                        <DatePicker value={expiryDate} setValue={setExpiryDate} timePickerRef={timePickerRef} />
                      </Box>
                      <Typography variant="body1">
                        Set a timer to automatically deactivate account at a certain date and time.
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      style={{
                        color: invCountdown > 0 ? theme.palette.primary.main : theme.palette.error.main,
                      }}
                    >
                      {invCountdown > 0
                        ? `This invite link will expire in: ${invExpiryCountdown}`
                        : "This invite link has expired. Resend invitation to provide user access."}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        paddingTop: 1,
                        gap: 2,
                        minWidth: "300px",
                        maxWidth: "500px",
                        width: "100%",
                      }}
                    >
                      {userAccess.delete && (
                        <Button
                          variant="text"
                          startIcon={<DeleteForeverOutlinedIcon />}
                          onClick={async () => await deleteUser(true)}
                        >
                          <EllipsisTooltip>Delete Invite</EllipsisTooltip>
                        </Button>
                      )}
                      {userAccess.add && (
                        <Button
                          variant="outlined"
                          startIcon={<MailOutlineSharp />}
                          onClick={() => setResendId(user?.id)}
                        >
                          <EllipsisTooltip>Resend Invite</EllipsisTooltip>
                        </Button>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <>
                {app.isFeatureEnabled(TenantFeatureEnum.DeleteUser) && userAccess.delete && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setDeleting(true);
                    }}
                    startIcon={<DeleteForeverOutlinedIcon />}
                  >
                    Delete Account
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
        {userType === UserTypes.Active && (owner || modifyAccess) && showBtns && (
          <SettingsBottomBar show={true}>
            <>
              <Button variant="outlined" style={{ width: "100px" }} onClick={handleDiscardChanges}>
                Cancel
              </Button>
              <Button variant="contained" style={{ width: "150px" }} disabled={false} onClick={handleSaveChange}>
                Save Changes
              </Button>
            </>
          </SettingsBottomBar>
        )}
      </Box>
    </>
  );
};

export default ProfileAccountTab;
