import CloseIcon from "@mui/icons-material/Close";
import { DialogContent, DialogTitle, FormControl, IconButton } from "@mui/material";
import { Box, Dialog, Typography } from "@mui/material";
import React, { useCallback, useContext, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { useInvites } from "hooks/useInvites";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { UserInvite } from "services/ContentServer/Identity/serviceTypes/UserInvite";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { validateEmail } from "utils/StringUtils";

export interface InvRowData {
  id: number;
  tempName: string;
  email: string;
  sites: string[];
  roles: string[];
  missingNameDisp: string;
  missingEmailDisp: string;
}

const ResendInviteDialog = ({
  resendId,
  setResendId,
  fromUserList,
}: {
  resendId?: string;
  setResendId: (open: string) => void;
  fromUserList?: boolean;
}) => {
  const { resendInv } = useInvites();
  const [email, setEmail] = useState("");
  const { contentServer } = useContext(RequestContext);
  const { data: invites } = useSWR([UserInviteSWRKeys.USER_INVITES], () => contentServer.userInviteService.list());

  const deleteInvite = useCallback(
    (inviteId: string) => {
      try {
        mutate(
          [UserInviteSWRKeys.USER_INVITES],
          (prevInvs: UserInvite[] | undefined) => (prevInvs ? [...prevInvs].filter((inv) => inv.id !== inviteId) : []),
          false
        );
        contentServer.userInviteService.delete(inviteId);
      } catch (err) {
        mutate([UserInviteSWRKeys.USER_INVITES]);
        console.error();
      }
    },
    [contentServer.userInviteService]
  );

  const invalidFields = useMemo(() => {
    return !validateEmail(email);
  }, [email]);

  const handleClose = useCallback(() => {
    setResendId("");
  }, [setResendId]);

  const handleSubmit = useCallback(async () => {
    resendInv(invites, resendId, email);
    if (fromUserList && resendId) {
      deleteInvite(resendId);
    }
    handleClose();
  }, [invites, resendId, email, resendInv, handleClose, fromUserList, deleteInvite]);

  const open: boolean = useMemo(() => {
    return resendId ? true : false;
  }, [resendId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiDialog-paper": {
          borderRadius: 0,
          boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 3,
          gap: 3,
        },
        width: "100%",
      }}
    >
      <DialogTitle
        sx={{
          p: 0,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Resend Invite</Typography>
        <IconButton color="primary" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: "100%", p: 0, m: 0 }}>
        <Typography variant="body1">{"Please enter an email destination to resend the invite."}</Typography>
        <FormControl variant="outlined" required={true} sx={{ width: "100%", pt: 2, pb: 0, px: 0 }}>
          <FixedHeightTextField
            variant="outlined"
            required={true}
            placeholder={"email@company.com"}
            sx={{ width: "100%" }}
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
          />
        </FormControl>
      </DialogContent>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center", p: 0, gap: 2 }}>
        <BaseButton variant="outlined" onClick={handleClose}>
          Cancel
        </BaseButton>
        <BaseButton variant="contained" onClick={handleSubmit} disabled={invalidFields}>
          Submit
        </BaseButton>
      </Box>
    </Dialog>
  );
};

export default ResendInviteDialog;
