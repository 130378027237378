import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { MediaHistory } from "../serviceTypes/MediaHistory";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const MediaHistorySWRKeys = {
  CAPTURE_HISTORIES: "captureHistories",
  CAPTURE_HISTORY: "captureHistory",
};
export class MediaHistoryService extends ServiceBase<MediaHistory> {
  constructor(apiInstance: APIInstance) {
    super(MediaHistory, apiInstance, ENDPOINTS.CAPTURE_HISTORY);
  }
}
