import { IAssetMetadata, STATE } from "redux/api/folderApi";

import { ModelState } from "../types";
import { ARFile } from "./ARFile";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Asset | undefined } = {
  id: "id",
  specifier: "specifier",
  site_id: "site",
  asset_info_id: "assetInfoId",
  is_template: "isTemplate",
  parent: "parent",
  snapshots: "snapshots",
  archived_date: "archivedDate",
  last_created: "lastCreated",
  state: "state",
  created_at: "createdAt",
  ar_file: "arFile",
  folderId: "folderId",
  facilityId: "facilityId",
};

export interface IAsset {
  id: string;
  name: string;
  description?: string;
  parentId: string;
  folderPath: string;
  idPath: string;
  state: STATE;
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  teams: string[];
  thumbnail: string;
  specifier?: string | null;
  site?: string | null;
  assetInfoId?: string | null;
  isTemplate?: boolean | null;
  arFile?: ARFile | null;
  metadata?: IAssetMetadata;
}

export class Asset extends ISerialization {
  public id?: string | null;
  public specifier?: string | null;
  public site?: string | null;
  public assetInfoId?: string | null;
  public isTemplate?: boolean | null;
  public parent?: string | null;
  public snapshots?: string[] | null;
  public archivedDate?: any | null;
  public lastCreated?: any | null;
  public state? = ModelState.Normal;
  public createdAt?: string | null;
  public arFile?: ARFile | null;
  public folderId?: string | null;
  public facilityId?: string | null;

  constructor(asset?: Asset) {
    super();
    if (asset) {
      Object.assign(this, asset);
    }
  }

  toJSON() {
    const request: any = {};
    Object.entries(serverToView).forEach(([key, value]) => {
      if (value !== undefined) {
        request[value] = this[value];
      }
    });

    return request as IAsset;
  }

  deserialize(json: any): void {
    deserializerHelper<Asset>(this, serverToView, json);

    if (!this.specifier) {
      this.specifier = "Undefined Asset ID";
    }
  }

  serialize(): any {
    const request = serializerHelper<Asset>(this, serverToView);
    return request;
  }
}
