import { useState, useCallback } from "react";

export function useNotifyRef<T>(): [T | null, (node: T | null) => void] {
  const [element, setElement] = useState<T | null>(null);

  const ref = useCallback((node) => setElement(node), []);
  return [element, ref];
}

export function useNotifyRefArray<T>(): [T[], (nodes: T) => void] {
  const [elements, setElements] = useState<T[]>([]);

  const addRef = useCallback((node) => setElements((prev) => [...prev, node]), []);

  return [elements, addRef];
}
