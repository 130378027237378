export function sortObjects<T>(a: T, b: T, key: keyof T): number {
  if (a[key] < b[key]) {
    return -1;
  } else if (a[key] > b[key]) {
    return 1;
  } else {
    return 0;
  }
}

export const sortObjectsByTime = (a: string | undefined | null, b: string | undefined | null) => {
  if (a && b) {
    return new Date(b).getTime() - new Date(a).getTime();
  } else if (b) {
    return 1;
  } else if (a) {
    return -1;
  } else {
    return 0;
  }
};

export const sortObjectsByString = (a: string | undefined | null, b: string | undefined | null) => {
  if (a && b) {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
  }
  return 0;
};
