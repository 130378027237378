import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";

import { COLOR } from "components/CameraSelector/Constants";
import { Annotation } from "services/ContentServer/Audit/serviceTypes/Annotation";
import { Point2D, Point3D } from "services/ContentServer/Audit/types";

export interface DrawingLine {
  line: Line2;
  points: Point3D[];
  highlight?: Line2;
}
export interface StrokeOptions {
  strokeColor: string | CanvasGradient | CanvasPattern;
  lineWidth: number;
}

export declare type DrawingStroke3D = {
  drawingLine: DrawingLine;
  plane: DrawingPlane;
};

export declare type PreviousDrawingStroke3D = {
  drawingLine: DrawingLine;
  plane: DrawingPlane;
  id: string;
  isDisplayed: boolean;
  annotationGroupId?: number;
};

export interface I3DDrawer {
  clearCurrentStroke(): void;
  addToStroke(intersection: THREE.Intersection, raycaster: THREE.Raycaster, color?: COLOR, lineWidth?: number): void;
  project3DPoint(
    plane: DrawingPlane,
    point: Point3D,
    size: { width: number; height: number },
    isUnity?: boolean
  ): Point2D;
  planeNormal(plane: DrawingPlane): THREE.Vector3;
  deproject2DPoint(plane: DrawingPlane, point: Point2D): Point3D;
  updatePreviousDrawings(drawing: Annotation[]): void;
  popCurrentStroke(): DrawingStroke3D | undefined;
  peekCurrentStroke(): DrawingStroke3D | undefined;
  setCurrentDrawingPlane(plane: DrawingPlane | ((prev: DrawingPlane) => DrawingPlane)): void;
  highlightStrokeGroup(id: string): void;
  removeHighlight(): void;
}

export interface I2DDrawer {
  drawLine(point1: Point2D, point2: Point2D, strokeOptions: StrokeOptions): void;
  drawMultiLine(points: Point2D[], strokeOptions: StrokeOptions): void;
  erase(): void;
}

export enum MouseAction {
  Up,
  Down,
  Move,
  Out,
  Click,
  DoubleClick,
}

export enum AnnotationIntersectionType {
  Plane,
  Model,
}

export enum CursorMode {
  Pointer = "pointer",
  Default = "default",
  Pen = "url(/cursors/pen.png),auto",
  Eraser = "url(/cursors/eraser.png),auto",
  Arrow = "copy",
  Translate = "move",
  Rotate = "url(/cursors/rotate.png),auto",
  NotAllowed = "not-allowed",
  CrossHair = "crosshair",
  HandGrabOpen = "grab",
  HandGrabClosed = "grabbing",
}

export class DrawingPlane {
  originVertex: Point3D;
  upVertex: Point3D;
  rightVertex: Point3D;

  constructor(originVertex?: Point3D, upVertex?: Point3D, rightVertex?: Point3D) {
    this.originVertex = originVertex ? originVertex : [];
    this.upVertex = upVertex ? upVertex : [];
    this.rightVertex = rightVertex ? rightVertex : [];
  }

  isDefined() {
    return this.originVertex.length > 0 && this.upVertex.length > 0 && this.rightVertex.length > 0;
  }
}
