import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Device } from "../serviceTypes/Device";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const DeviceSWRKeys = {
  DEVICE: "Device",
};
export class DeviceService extends ServiceBase<Device> {
  constructor(apiInstance: APIInstance) {
    super(Device, apiInstance, ENDPOINTS.DEVICE);
  }
}
