import React, { useCallback, useEffect, useRef, useState } from "react";

import { useEventListener } from "hooks/useEventListener";
import { Point2D } from "services/ContentServer/Audit/types";
import { MouseAction, StrokeOptions, I2DDrawer } from "utils/Drawing";
import { ModelInteractionMode } from "views/inspection/InspectionPage/modelInteractionReducer";

interface CanvasProps {
  interactionMode: ModelInteractionMode;
  size: { width: number; height: number };
  drawer2D: I2DDrawer;
  onCursorMove: (point: Point2D | null, isDrawing: boolean) => void;
  onBeginDrawing: () => boolean;
  onFinishDrawing: () => void;
  onEraseStroke: (point: Point2D) => void;
  strokeOptions: StrokeOptions;
  setContext: (context: CanvasRenderingContext2D | null) => void;
}

const DrawingCanvas = ({
  interactionMode,
  size,
  drawer2D,
  onBeginDrawing,
  onCursorMove,
  onFinishDrawing,
  onEraseStroke,
  strokeOptions,
  setContext,
}: CanvasProps) => {
  const drawingCanvas = useRef<HTMLCanvasElement>(null);

  const [currPoint, setCurrPoint] = useState([0, 0]);
  const [prevPoint, setPrevPoint] = useState([0, 0]);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);

  const { drawLine } = drawer2D;

  const draw = useCallback(() => {
    drawLine(prevPoint, currPoint, strokeOptions);
  }, [drawLine, prevPoint, currPoint, strokeOptions]);

  const processMouseEvent = useCallback(
    (type: MouseAction, event: PointerEvent) => {
      switch (interactionMode) {
        case ModelInteractionMode.Erase: {
          switch (type) {
            case MouseAction.Down:
              onEraseStroke([event.offsetX, event.offsetY]);
          }
          break;
        }
        case ModelInteractionMode.Drawing: {
          switch (type) {
            case MouseAction.Down:
              if (onBeginDrawing()) {
                setIsDrawing(true);
                setPrevPoint([event.offsetX, event.offsetY]);
                setCurrPoint([event.offsetX, event.offsetY]);
              }
              break;
            case MouseAction.Move:
              onCursorMove([event.offsetX / size.width, 1 - event.offsetY / size.height], isDrawing);
              if (isDrawing) {
                setPrevPoint(currPoint);
                setCurrPoint([event.offsetX, event.offsetY]);
                draw();
              }
              break;
            case MouseAction.Up:
              if (isDrawing) {
                setIsDrawing(false);
                onFinishDrawing();
              }
              break;
            case MouseAction.Out:
              if (isDrawing) {
                setIsDrawing(false);
                onFinishDrawing();
              }
              onCursorMove(null, false);
              break;
          }
          break;
        }
      }
    },
    [interactionMode, currPoint, draw, size, onBeginDrawing, onCursorMove, onFinishDrawing, onEraseStroke, isDrawing]
  );

  useEffect(() => {
    if (drawingCanvas.current) {
      setContext(drawingCanvas.current.getContext("2d"));
    }
  }, [drawingCanvas, setContext]);

  useEventListener(
    "pointermove",
    (event) => processMouseEvent(MouseAction.Move, event as PointerEvent),
    drawingCanvas.current,
    false
  );
  useEventListener(
    "pointerdown",
    (event) => processMouseEvent(MouseAction.Down, event as PointerEvent),
    drawingCanvas.current,
    false
  );
  useEventListener(
    "pointerup",
    (event) => processMouseEvent(MouseAction.Up, event as PointerEvent),
    drawingCanvas.current,
    false
  );
  useEventListener(
    "pointerleave",
    (event) => processMouseEvent(MouseAction.Out, event as PointerEvent),
    drawingCanvas.current,
    false
  );
  useEventListener(
    "dblclick",
    (event) => processMouseEvent(MouseAction.DoubleClick, event as PointerEvent),
    drawingCanvas.current,
    false
  );

  return (
    <>
      <canvas
        ref={drawingCanvas}
        width={size.width}
        height={size.height}
        style={{
          zIndex: 2,
          position: "absolute",
          top: "0px",
          left: "0px",
          touchAction: interactionMode === ModelInteractionMode.Drawing ? "none" : "auto",
        }}
        draggable={false}
        onDragStart={(e) => e.preventDefault()}
      />
    </>
  );
};

export default DrawingCanvas;
