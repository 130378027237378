import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

import { DrawingLine, PreviousDrawingStroke3D } from ".";

import { Annotation } from "services/ContentServer/Audit/serviceTypes/Annotation";
import { Point2D } from "services/ContentServer/Audit/types";
import { colorStringToInt, shade } from "utils/ColorUtils";

export const equals = (u: Point2D, v: Point2D, epsilon = Number.EPSILON) => {
  return Math.sqrt((u[1] - v[1]) ** 2 + (u[0] - v[0]) ** 2) < epsilon;
};

export const drawingToPreviousDrawingStroke = (drawing: Annotation) => {
  const points: number[] = [];
  drawing.points?.forEach((point) => {
    points.push(point[0], point[1], point[2]);
  });

  const geometry = new LineGeometry();
  try {
    geometry.setPositions(points);
  } catch (e) {
    geometry.setPositions([0, 0, 0]);
    console.error(e);
  }
  const line = new Line2(
    geometry,
    new LineMaterial({
      color: drawing.color ? colorStringToInt(drawing.color) : 0,
      linewidth: drawing.radius ? drawing.radius : 0,
      worldUnits: true,
    })
  );
  line.computeLineDistances();
  const highlightMaterial = new LineMaterial({
    color: drawing.color ? colorStringToInt(shade(drawing.color, 0.5)) : 0,
    linewidth: drawing.radius ? drawing.radius * 1.05 : 0.00525,
    worldUnits: true,
  });

  const highlight = new Line2(geometry, highlightMaterial);
  highlight.computeLineDistances();
  highlight.visible = false;
  line.add(highlight);
  const drawLine: DrawingLine = { line: line, points: drawing.points ? drawing.points : [], highlight: highlight };

  return {
    drawingLine: drawLine,
    plane: drawing.plane,
    id: drawing.id,
    isDisplayed: drawing.isActive,
  } as PreviousDrawingStroke3D;
};
