import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

const ProtectedRoute = (props: any) => {
  const auth = useAuth();
  return auth.user ? <Route {...props} /> : <Redirect to="/login" />;
};

export default ProtectedRoute;
