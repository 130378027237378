import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MenuProps, IconButton } from "@mui/material";
import React, { useRef } from "react";

import Menu from "./Menu";
import Tooltip from "components/Tooltip/Tooltip";

const ITEM_HEIGHT = 48;

interface MoreMenuProps extends Partial<MenuProps> {
  open: boolean;
  setOpen: (anchorEl: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
  horizontal?: boolean;
  disabled?: boolean;
  left?: boolean;
  size?: "medium" | "small";
  icon?: React.ReactNode;
}

export default function MoreMenu({
  open,
  setOpen,
  children,
  horizontal,
  disabled,
  left,
  size = "medium",
  icon = horizontal ? <MoreHorizIcon /> : <MoreVertIcon />,
  ...other
}: MoreMenuProps) {
  const anchorEl = useRef(null);

  // const icon = horizontal ? <MoreHorizIcon /> : <MoreVertIcon />;
  return (
    <>
      <Tooltip title="More" placement="top">
        <IconButton
          id="view-more"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
          ref={anchorEl}
          size={size}
          disabled={disabled}
          style={{ borderRadius: 0, padding: "5px 10px" }}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl?.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5.5,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 0,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: left ? "left" : "right",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        elevation={1}
        {...other}
      >
        {children}
      </Menu>
    </>
  );
}
