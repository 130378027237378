import { ExpandMore } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  ButtonBase,
  Checkbox,
  InputAdornment,
  Paper,
  Popover,
  PopperProps,
  TextField,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import CheckedOptionBox from "icons/CheckedOptionBox";
import DropdownFilterCheckBoxIcon from "icons/DropdownFilterCheckBoxIcon";
import React, { useCallback, useMemo, useState, useEffect } from "react";

import { ALL_RESULTS, CLEAR_ALL, getUniqueValues, SearchOption } from "./SearchBar";
import { matchSorter } from "utils/SortRowsUtils";

export const AutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    minWidth: 300,
    maxWidth: "160px",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#a2a4a6",
      height: "12vh",
      maxHeight: "12vh",
      minHeight: "12vh",
      width: "8px",
      borderRadius: "100px",
    },
    [`& .${autocompleteClasses.option}`]: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "140%",
      color: "#1C1F22",

      '&[aria-selected="true"]': {
        backgroundColor: "rgba(143, 143, 143, 0.2)",
      },
      '&[data-focus="true"]': {
        backgroundColor: "rgba(143, 143, 143, 0.2)",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: "rgba(143, 143, 143, 0.2)",
      },
    },
  },
}));

export function CustomPopper(props: PopperProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return (
    <AutocompletePopper
      {...other}
      sx={{ borderRadius: 0, boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)", width: "auto!important" }}
    />
  );
}

export const CustomPaper = (props: any) => {
  return <Paper {...props} sx={{ borderRadius: 0, boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)" }} />;
};

export const FilterPopup = ({
  options,
  placeholderText,
  currentValue,
  setCurrentValue,
  selectAll,
  selectAllText,
  singleSelect = false,
  disableClear = false,
  outlinedStyle = false,
  clearAllOption = false,
  disabledIds = [],
  disabled = false,
  viewOnly = false,
}: {
  options: SearchOption[];
  placeholderText: string;
  currentValue: string[];
  setCurrentValue: (value: string[]) => void;
  selectAll: boolean;
  selectAllText: string;
  singleSelect?: boolean;
  disableClear?: boolean;
  outlinedStyle?: boolean;
  clearAllOption?: boolean;
  disabledIds?: string[];
  disabled?: boolean;
  viewOnly?: boolean;
}) => {
  const theme = useTheme();
  const [selectedItems, setSelectedItems] = useState<SearchOption[]>([]);
  const [open, setOpen] = useState(outlinedStyle ? false : true);
  const [selectedString, setSelectedString] = useState("");
  const [allOptions, setAllOptions] = useState<SearchOption[]>([]);

  const allSelected = useMemo(() => {
    return selectedItems.length === options.length;
  }, [selectedItems, options]);

  const filterOptions = (options: SearchOption[], { inputValue }: { inputValue: any }) => {
    let result: SearchOption[] = [];

    if (inputValue.length === 0) {
      return allOptions;
    }
    if (selectAll) {
      const optionsWithoutAll = options.filter((opt) => opt.id !== ALL_RESULTS);
      const filteredOptions = matchSorter(optionsWithoutAll, inputValue, {
        keys: ["label"],
      });
      result = [{ id: ALL_RESULTS, label: selectAllText } as SearchOption].concat(filteredOptions);
    } else {
      result = matchSorter(options, inputValue, { keys: ["label"] });
    }
    if (clearAllOption) {
      result = [{ id: CLEAR_ALL, label: "Clear All" } as SearchOption].concat(result);
    }

    return result;
  };

  const selectedState = useMemo(() => {
    const state = currentValue.map(
      (item: string) => allOptions.find((opt) => opt.id === item) || { id: "", label: "" }
    );
    setSelectedItems(state);

    const selectedStringItems: string[] = [];
    const orderedTags: SearchOption[] = [];
    if (state) {
      state.forEach((item) => {
        selectedStringItems.push(item.label);
        orderedTags.push(item);
      });
    }
    setSelectedString(selectedStringItems.join(", "));

    return orderedTags;
  }, [allOptions, currentValue]);

  useEffect(() => {
    if (currentValue) {
      setAllOptions(
        selectAll
          ? [{ id: ALL_RESULTS, label: selectAllText } as SearchOption].concat(
              options.sort((a, b) => +currentValue.includes(b.id) - +currentValue.includes(a.id))
            )
          : options.sort((a, b) => +currentValue.includes(b.id) - +currentValue.includes(a.id))
      );
    } else {
      setAllOptions(selectAll ? [{ id: ALL_RESULTS, label: selectAllText } as SearchOption].concat(options) : options);
    }
  }, [options, selectAll, selectAllText, currentValue]);

  return (
    <>
      {viewOnly ? (
        <Box>
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
            }}
          >
            {selectedItems.map((item, idx) => {
              return idx === selectedItems.length - 1 || selectedItems.length === 1 ? item.label : item.label + ", ";
            })}
          </Typography>
        </Box>
      ) : (
        <Autocomplete
          id="search-bar"
          size="small"
          multiple
          disableCloseOnSelect={!singleSelect}
          disableClearable={disableClear}
          disabled={disabled}
          open={open}
          filterOptions={filterOptions}
          value={selectedItems && selectedItems.length ? selectedItems : selectedState}
          limitTags={1}
          popupIcon={outlinedStyle ? <ArrowDropDownIcon /> : <></>}
          fullWidth={outlinedStyle}
          PaperComponent={CustomPaper}
          PopperComponent={outlinedStyle ? undefined : CustomPopper}
          options={allOptions}
          onBlur={() => setOpen(false)}
          getOptionLabel={(option: SearchOption) => option.label}
          getOptionDisabled={(option: SearchOption) => disabledIds.includes(option.id)}
          isOptionEqualToValue={(option, value) => {
            if (value) {
              return option.id === value.id;
            }
            if (option) {
              return false;
            }
            return true;
          }}
          renderOption={(props, option, { selected }) =>
            option.id === CLEAR_ALL ? (
              <ButtonBase
                onClick={() => {
                  setSelectedItems([]);
                  setCurrentValue([]);
                  setOpen(false);
                }}
                style={{ width: "100%" }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "6px 16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "42px",
                      height: "42px",
                    }}
                  >
                    <FilterAltOffIcon />
                  </div>
                  <Typography variant="body1">Clear All</Typography>
                </div>
              </ButtonBase>
            ) : (
              <li {...props} key={option.id}>
                <Checkbox
                  icon={<DropdownFilterCheckBoxIcon />}
                  checkedIcon={<CheckedOptionBox />}
                  checked={
                    option.id === ALL_RESULTS
                      ? selectedItems
                        ? selectedItems?.length === options.length
                          ? true
                          : false
                        : false
                      : currentValue.find((item: any) => item === option.id) !== undefined
                  }
                />
                <Typography variant="body1">{option.label}</Typography>
              </li>
            )
          }
          onClickCapture={() => {
            if (outlinedStyle && !disabled) {
              setOpen((open) => !open);
            }
          }}
          onChange={(_, value: SearchOption[]) => {
            if (!singleSelect) {
              const selectAll = value?.find((val) => val.id === ALL_RESULTS);
              const enabledSelected = value
                ? getUniqueValues(value.filter((option) => !disabledIds.includes(option.id)))
                : [];
              const disabledSelected = selectedItems.filter((item) => disabledIds.includes(item.id));

              if (selectAll && !allSelected) {
                setSelectedItems([...new Set(options)]);
                setCurrentValue(options.map((val) => val.id));
              } else if (selectAll && allSelected) {
                setSelectedItems(disabledSelected);
                setCurrentValue(disabledSelected.map((item) => item.id));
              } else {
                setSelectedItems([...enabledSelected, ...disabledSelected]);
                setCurrentValue([...enabledSelected, ...disabledSelected].map((val) => val.id));
              }
            } else {
              const newFilter = value.filter(
                (item) => item.id && !currentValue.includes(item.id) && !disabledIds.includes(item.id)
              );
              setSelectedItems(newFilter);
              setCurrentValue(newFilter.map((value) => value.id));
            }
            if (outlinedStyle && singleSelect) {
              setOpen(false);
            }
          }}
          renderInput={(params) =>
            outlinedStyle ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <TextField
                  {...params}
                  variant="outlined"
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "140%",
                    color: "rgba(100, 103, 106, 0.6)",
                    caretColor: open ? "black" : "transparent",
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: false,
                  }}
                  placeholder={!(selectedItems?.length || selectedState.length) ? placeholderText : ""}
                />
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "12px 24px",
                  gap: "16px",
                  borderBottom: "1px solid #EBEBEB",
                  backgroundColor: "white",
                  height: "6vh",
                }}
              >
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "140%",
                    color: "rgba(100, 103, 106, 0.6)",
                    caretColor: open ? "black" : "transparent",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: (
                      <>
                        <>
                          <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                            <SearchIcon />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: false,
                  }}
                  placeholder={!(selectedItems?.length || selectedState.length) ? placeholderText : ""}
                />
              </Box>
            )
          }
          renderTags={(selectedItems?: SearchOption[]) => {
            const optionsSet = [...new Set(selectedState)];
            return selectedItems?.length ? (
              selectedItems.length == options.length && selectAll ? (
                <Typography key={ALL_RESULTS} variant="body1">
                  {selectAllText}
                </Typography>
              ) : open ? (
                <>
                  <Typography
                    key={1}
                    variant="body1"
                    sx={{
                      maxWidth: "calc(70px + 3.25vw)",
                      maxHeight: "36px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      direction: "rtl",
                    }}
                  >
                    {selectedString}
                  </Typography>
                  <span>&#44;</span>
                </>
              ) : (
                optionsSet.map((item: SearchOption, idx) => {
                  if (item.label.length > 0 && idx === 0) {
                    return (
                      <Typography
                        key={item.id}
                        variant="body1"
                        sx={{
                          maxWidth: "80%",
                          maxHeight: "36px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {optionsSet.length <= 1 ? item.label : item.label + ", "}
                      </Typography>
                    );
                  }
                  return <></>;
                })
              )
            ) : (
              []
            );
          }}
        />
      )}
    </>
  );
};

const DropdownFilter = ({
  options,
  placeholderText,
  refElement,
  currentValue,
  setCurrentValue,
  selectAll = true,
  selectAllText = "",
  clearAllOption = false,
  disabled = false,
}: {
  options: SearchOption[];
  placeholderText: string;
  refElement: React.MutableRefObject<HTMLHRElement | null>;
  currentValue: string[];
  setCurrentValue: (value: string[] | ((prev: string[]) => string[])) => void;
  selectAll?: boolean;
  selectAllText?: string;
  clearAllOption?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback(() => {
    setAnchorEl(refElement.current);
  }, [refElement]);

  return (
    <>
      <IconButton
        id="sites-filter-dropdown"
        disableRipple
        onClick={handleClick}
        style={{ padding: "0 2px", color: theme.palette.primary.main }}
      >
        <ExpandMore
          style={{
            width: 22.3,
            height: 22.3,
            transform: anchorEl ? "rotate(0.5turn)" : "none",
          }}
        />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          style: {
            background: theme.palette.primary.contrastText,
            border: "none",
            padding: 0,
            minWidth: 300,
          },
        }}
      >
        <FilterPopup
          options={options}
          placeholderText={placeholderText}
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          selectAll={selectAll}
          selectAllText={selectAllText}
          clearAllOption={clearAllOption}
          disabled={disabled}
        />
      </Popover>
    </>
  );
};

export default DropdownFilter;
