import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { JobData } from "../serviceTypes/JobData";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const JobDataSWRKeys = {
  JOB_DATAS: "jobdatas",
};
export class JobDataService extends ServiceBase<JobData> {
  constructor(apiInstance: APIInstance) {
    super(JobData, apiInstance, ENDPOINTS.JOB_DATA);
  }

  startMeshGen(configFile: File, files: File[], assetFileId: number) {
    const request = { config_file: configFile, files: files, asset_file_id: assetFileId };

    this.postCustomFormData(request, ENDPOINTS.MESH_GEN);
  }
}
