import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Touchup } from "../serviceTypes/Touchup";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const RescanSWRKeys = {
  TOUCHUP: "touchup",
};

export class TouchupService extends ServiceBase<Touchup> {
  constructor(apiInstance: APIInstance) {
    super(Touchup, apiInstance, ENDPOINTS.TOUCHUP);
  }
}
