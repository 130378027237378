import { IChatMessage } from "./serializers";
import { IChat } from "./types";
import { IChatHub } from "services/MessageHub/types";
import SubscriptionManager from "utils/SubscriptionManager";

export class Chat implements IChat {
  private chatHub: IChatHub;
  private userID: string;
  private unsubscribe: (() => void) | null = null;
  private subscriptionManager: SubscriptionManager<(message: IChatMessage) => void>;

  constructor(chatHub: IChatHub, userID: string) {
    this.chatHub = chatHub;
    this.userID = userID;
    this.subscriptionManager = new SubscriptionManager();
  }

  start(): void {
    const wrapper = (value: Record<string | number | symbol, unknown>) => {
      const message = value as IChatMessage;
      this.onMessage(message);
    };

    if (this.unsubscribe === null) {
      this.unsubscribe = this.chatHub.subscribeToChatMessage(wrapper);
    }
  }

  subscribeToChat(callback: (message: IChatMessage) => void): () => void {
    return this.subscriptionManager.subscribe(callback);
  }

  async chat(id: string, peerID: string, text: string): Promise<void> {
    if (!peerID) return Promise.reject("A user ID is required to chat.");

    await this.send({
      id: id,
      receiver: peerID,
      sender: this.userID,
      text: text,
    });
  }

  stop(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  private onMessage(message: IChatMessage): void {
    this.subscriptionManager.subscribers.forEach((callback) => callback(message));
  }

  private send(message: IChatMessage): Promise<void> {
    return this.chatHub.send(message);
  }
}
