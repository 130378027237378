import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Supplier } from "../serviceTypes/Supplier";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const SupplierSWRKeys = {
  SUPPLIERS: "suppliers",
};
export class SupplierService extends ServiceBase<Supplier> {
  constructor(apiInstance: APIInstance) {
    super(Supplier, apiInstance, ENDPOINTS.SUPPLIERS);
  }
}
