import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { Manufacturer } from "services/ContentServer/Audit/serviceTypes/Manufacturer";
import { Supplier } from "services/ContentServer/Audit/serviceTypes/Supplier";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof AssetInfo | undefined } = {
  id: "id",
  name: "name",
  manufacturer: "manufacturer",
  supplier: "supplier",
  description: "description",
  comments: "comments",
  asset_code: "assetCode",
  asset_type: "assetType",
  style: "style",
  created_at: "createdAt",
  created_by: "createdBy",
};

export class AssetInfo extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public manufacturer?: Manufacturer | null;
  public supplier?: Supplier | null;
  public description?: string | null;
  public comments?: string | null;
  public assetCode?: string | null;
  public assetType?: string | null;
  public style?: string | null;
  // Add created/modified by/at
  public createdAt?: string | null;
  public createdBy?: string | null;

  constructor(info?: AssetInfo) {
    super();
    if (info) {
      Object.assign(this, info);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<AssetInfo>(this, serverToView, json);

    if (json.manufacturer) {
      this.manufacturer = new Manufacturer({ id: json.manufacturer } as Manufacturer);
    }
    if (json.supplier) {
      this.supplier = new Supplier({ id: json.supplier } as Supplier);
    }

    if (!this.name) {
      this.name = "Unnamed Asset Info";
    }
  }

  serialize(): any {
    const request = serializerHelper<AssetInfo>(this, serverToView);
    request.manufacturer = this.manufacturer?.id;
    request.supplier = this.supplier?.id;
    return request;
  }
}
