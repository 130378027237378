import { TableCell as TableCellMui, TableCellProps, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";

import useARMediaQuery from "hooks/useARMediaQuery";

interface ColumnCellProps extends TableCellProps {
  index: number;
  width?: string;
  minWidth?: string;
  alwaysShow?: boolean;
}

const ARColumnCell = ({ index, width, minWidth, alwaysShow, children, ...other }: ColumnCellProps) => {
  const theme = useTheme();
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useARMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useARMediaQuery("mobile");
  const display = useMemo(() => {
    if (!alwaysShow && ((matchesSM && index >= 1) || (matchesMD && index >= 2) || (matchesLG && index >= 3)))
      return "none";

    return "table-cell";
  }, [matchesMD, matchesSM, matchesLG, alwaysShow, index]);

  const StyledColumnCell = styled(TableCellMui)({
    borderBottom: isMobile ? "none" : "1px #000 solid",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    margin: 0,
    width: width ? width : "auto",
    minWidth: minWidth ? minWidth : "auto",
    display: display,
  });

  return <StyledColumnCell {...other}>{children}</StyledColumnCell>;
};

export default ARColumnCell;
