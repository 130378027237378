import { Box, Grid, Typography, SelectChangeEvent, useTheme } from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import NumericInput from "react-numeric-input";
import { useUpdateTenantMutation, useGetTenantQuery } from "redux/api/tenantApi";
import { TenantFeatureEnum } from "redux/api/types";
import useApp from "redux/hooks/useApp";

import { SettingTab } from "./SettingsBasePage";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import SelectFieldForm from "components/forms/SelectFieldForm";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import { SettingsSwitch } from "components/Switch/StyledSwitch";
import FieldMessage from "components/Typography/FieldMessage";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useAuth } from "hooks/useAuth";
import { PERMISSION_NAME, PERMISSION_TYPE } from "hooks/usePermission";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";

const TIME_UNITS = ["Day(s)", "Week(s)", "Month(s)", "Year(s)"];
const DEFAULT_EXPIRY_DAYS = 30;

const PreferencesPage = ({ setSelected }: { setSelected: (value: SettingTab) => void }) => {
  const [autoArchiveToggle, setAutoArchiveToggle] = useState(false);
  const [autoArchiveNumber, setAutoArchiveNumber] = useState<number | undefined>(undefined);
  const [autoArchiveUnit, setAutoArchiveUnit] = useState(0);
  const [initValues, setInitValues] = useState(true);
  const { featureAccess, getPermissionMessage } = useUsers();
  const [showBtns, setShowBtns] = useState<boolean>(false);
  const [hasRequiredFields, setHasRequiredFields] = useState<boolean>(true);

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  const app = useApp();
  const { user } = useAuth();
  const snackbar = useSnackbar();

  const { data: tenantInfo } = useGetTenantQuery(user?.tenantId ?? "");
  const [updateTenant] = useUpdateTenantMutation();

  const formatExpiryDays = (days: number, checkOnly?: boolean) => {
    if (days !== 0) {
      if (days % 365 === 0) {
        if (!checkOnly) {
          setAutoArchiveUnit(3);
          setAutoArchiveNumber(days / 365);
        }
        return { unit: 3, value: days / 365 };
      } else if (days % 30 === 0) {
        if (!checkOnly) {
          setAutoArchiveUnit(2);
          setAutoArchiveNumber(days / 30);
        }
        return { unit: 2, value: days / 30 };
      } else if (days % 7 === 0) {
        if (!checkOnly) {
          setAutoArchiveUnit(1);
          setAutoArchiveNumber(days / 7);
        }
        return { unit: 1, value: days / 7 };
      } else {
        if (!checkOnly) {
          setAutoArchiveUnit(0);
          setAutoArchiveNumber(days);
        }
        return { unit: 0, value: days };
      }
    }
    return { unit: 0, value: 30 };
  };

  useEffect(() => {
    requestAnimationFrame(() => setSelected(SettingTab.PREFERENCES));
  }, [setSelected]);

  const adminAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ADMIN].read;
  }, [featureAccess]);

  useEffect(() => {
    if (initValues) {
      const expiryDays = tenantInfo?.snapshotExpiryDays;
      if (expiryDays && expiryDays > 0) {
        setAutoArchiveToggle(true);
        formatExpiryDays(expiryDays);
      }
    }
    setInitValues(false);
  }, [tenantInfo, initValues]);

  const saveAutoArchive = useCallback(
    async (days: number | undefined) => {
      if (days !== undefined && hasRequiredFields) {
        try {
          if (user?.tenantId) {
            const formData = new FormData();
            formData.append("snapshot_expiry_days", days.toString());
            updateTenant({ id: user?.tenantId, data: formData });

            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: "Successfully updated auto-archive preferences.",
            });
          }
        } catch (error) {
          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Error updating auto-archive preferences.",
          });
        }
        setShowBtns(false);
      } else {
        setHasRequiredFields(false);
      }
    },
    [hasRequiredFields, user, snackbar, updateTenant]
  );

  const toggleArchive = useCallback(() => {
    if (autoArchiveToggle && tenantInfo?.snapshotExpiryDays === 0) {
      setAutoArchiveNumber(0);
      setShowBtns(false);
    } else if (autoArchiveToggle && tenantInfo?.snapshotExpiryDays !== 0) {
      setAutoArchiveNumber(0);
      setShowBtns(true);
    } else if (tenantInfo?.snapshotExpiryDays === 0) {
      setHasRequiredFields(true);
      formatExpiryDays(DEFAULT_EXPIRY_DAYS);
      setShowBtns(true);
    } else if (tenantInfo?.snapshotExpiryDays) {
      formatExpiryDays(tenantInfo.snapshotExpiryDays);
      setHasRequiredFields(true);
      setShowBtns(false);
    }
    setAutoArchiveToggle((prev) => !prev);
  }, [autoArchiveToggle, tenantInfo?.snapshotExpiryDays]);

  const expiryDaysFromMonth = useCallback(
    (unit: number, days?: number) => {
      const expiryDays = days !== undefined ? days : autoArchiveNumber;
      if (expiryDays !== undefined) {
        switch (TIME_UNITS[unit]) {
          case "Day(s)":
            return expiryDays;
          case "Week(s)":
            return expiryDays * 7;
          case "Month(s)":
            return expiryDays * 30;
          case "Year(s)":
            return expiryDays * 365;
          default:
            return expiryDays;
        }
      }
    },
    [autoArchiveNumber]
  );

  const handleDiscardChanges = useCallback(() => {
    const expiryDays = tenantInfo?.snapshotExpiryDays;
    setHasRequiredFields(true);
    if (expiryDays && expiryDays > 0) {
      setAutoArchiveToggle(true);
      setAutoArchiveUnit(0);
      setAutoArchiveNumber(expiryDays);
    } else if (!expiryDays || expiryDays === 0) {
      setAutoArchiveToggle(false);
    }
    setShowBtns(false);
  }, [tenantInfo]);
  return (
    <>
      {adminAccess ? (
        <>
          <Grid container direction="column" spacing={4} sx={{ px: matchesSM ? 6 : matchesMD ? 8 : 13 }}>
            <Grid item xs={12}>
              <Box sx={{ pt: 3, pb: 3 }}>
                <Typography variant="h1">Preferences</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "0px",
                  gap: "24px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "0px",
                    gap: "32px",
                  }}
                >
                  <Typography variant="subtitle1">{"Auto-archive"}</Typography>
                  <SettingsSwitch
                    checked={autoArchiveToggle}
                    onChange={toggleArchive}
                    disabled={!app.isFeatureEnabled(TenantFeatureEnum.AutoArchive)}
                  />
                </Box>
                {autoArchiveToggle && (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      padding: "0px",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        "& span": {
                          "& b": {
                            background: "white !important",
                            boxShadow: "none !important",
                            borderWidth: "0px !important",
                            marginRight: "2px !important",
                            bottom: "11px !important",
                          },
                        },
                      }}
                    >
                      <NumericInput
                        value={autoArchiveNumber}
                        placeholder={" -- "}
                        min={1}
                        onBlur={() => {
                          if (!autoArchiveNumber || autoArchiveNumber < 1 || autoArchiveNumber === 0) {
                            setAutoArchiveNumber(1);
                          }
                          setHasRequiredFields(true);
                        }}
                        disabled={!app.isFeatureEnabled(TenantFeatureEnum.AutoArchive)}
                        onChange={async (value) => {
                          if (value !== null) {
                            setAutoArchiveNumber(value);
                          }

                          if (tenantInfo && tenantInfo.snapshotExpiryDays) {
                            const originalState = formatExpiryDays(tenantInfo.snapshotExpiryDays, true);
                            value === originalState.value &&
                            tenantInfo?.snapshotExpiryDays !== 0 &&
                            autoArchiveUnit === originalState.unit
                              ? setShowBtns(false)
                              : setShowBtns(true);
                          }

                          if ((value && value < 1) || value === 0) {
                            setHasRequiredFields(false);
                          } else {
                            setHasRequiredFields(true);
                          }
                        }}
                        style={{
                          input: {
                            color: "black",
                            padding: "0.1ex 1ex 0ex 8px",
                            display: "block",
                            fontSize: "15px",
                            width: "70px",
                            height: "40px",
                            borderRadius: "0px !important",
                            border: hasRequiredFields ? "1px solid #ccc!important" : "1px solid #C1170A!important",
                          },
                          "input:focus": {
                            border: hasRequiredFields ? "1px inset black" : "1px solid #C1170A",
                            outline: "none",
                          },
                        }}
                      />
                    </Box>

                    <SelectFieldForm
                      id="auto-archive"
                      value={autoArchiveUnit}
                      choices={TIME_UNITS}
                      onChange={async (event: SelectChangeEvent<unknown>) => {
                        setAutoArchiveUnit(event.target.value as number);
                        if (tenantInfo && tenantInfo.snapshotExpiryDays) {
                          const originalState = formatExpiryDays(tenantInfo.snapshotExpiryDays, true);
                          if (event.target.value === originalState.unit && autoArchiveNumber === originalState.value) {
                            setShowBtns(false);
                          } else {
                            setHasRequiredFields(true);
                            setAutoArchiveNumber(undefined);
                            setShowBtns(true);
                          }
                        }
                      }}
                      disabled={!app.isFeatureEnabled(TenantFeatureEnum.AutoArchive)}
                    />
                  </Box>
                )}
                <FieldMessage style={{ paddingTop: 0, marginTop: "-1%" }}>
                  {!autoArchiveToggle ? "" : hasRequiredFields ? "" : "Auto-archive must be greater than 0"}
                </FieldMessage>
                <Typography variant="body1">
                  {"Set a timer to automatically archive assets after a certain period of inactivity."}
                </Typography>
                {!app.isFeatureEnabled(TenantFeatureEnum.AutoArchive) ? (
                  <Typography variant="body1">
                    {"Please contact system administer to activate auto-archive."}
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          </Grid>
          <SettingsBottomBar show={showBtns}>
            <>
              <BaseButton variant="outlined" style={{ width: "100px" }} onClick={handleDiscardChanges}>
                Cancel
              </BaseButton>
              <BaseButton
                variant="contained"
                onClick={async () => {
                  await saveAutoArchive(expiryDaysFromMonth(autoArchiveUnit));
                }}
              >
                Save Changes
              </BaseButton>
            </>
          </SettingsBottomBar>
        </>
      ) : (
        <Typography variant="h4">{getPermissionMessage(PERMISSION_TYPE.READ)}</Typography>
      )}
    </>
  );
};

export default PreferencesPage;
