import { IReconnectPolicy } from "./types";

const reconnectIntervalsInMs: number[] = [0, 5000, 10000, 15000, 30000];

export class DefaultReconnectPolicy implements IReconnectPolicy {
  private reconnectAttempt = 0;

  getNextReconnectIntervalInMs(): number | undefined {
    if (this.reconnectAttempt < reconnectIntervalsInMs.length) {
      const reconnectInterval = reconnectIntervalsInMs[this.reconnectAttempt];
      this.reconnectAttempt++;
      return reconnectInterval;
    } else {
      return undefined;
    }
  }

  didFinishReconnecting() {
    this.reconnectAttempt = 0;
  }
}
