import { useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import React from "react";

import LinearProgress from "components/CustomProgress/LinearProgress";
import { useAuth } from "hooks/useAuth";
import { useRouter } from "hooks/useRouter";
import { getStoredPath, clearStoredPath } from "services/AzureAD/LocalRedirectUrlStorage";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

const OAuthCallbackView = () => {
  const auth = useAuth();
  const router = useRouter();
  const { instance } = useMsal();
  const { contentServer } = useContext(RequestContext);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    async function callSetup() {
      try {
        await contentServer.identityService.initialUserSetup();
        auth.setSetupComplete(true);
      } catch (err) {
        router.push("/accountnotfound");
      }
    }
    if (auth.account) {
      callSetup();
      const url = getStoredPath();
      clearStoredPath();
      router.push(url || "/");
    } else {
      // since route .aad-callback is called couple times and auth.account is not set yet, we use timer is differentiate
      // if it page is called directly or rediect from MSAL login
      timerId = setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [auth, auth.account, contentServer.identityService, instance, router]);

  return <LinearProgress color="secondary" />;
};

export default OAuthCallbackView;
