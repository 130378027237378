import { ListItemButton, ListItemIcon, ListItemText, Box, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactElement, useMemo } from "react";
import { getDrawerWidth, getSelectedNavItem } from "redux/features/appSlice";
import { useAppSelector } from "redux/hooks";

import { IARNavType, NavItemType } from "./ARNavType";
import ARBadge from "components/ARBadge";
import useARMediaQuery from "hooks/useARMediaQuery";
import { useRouter } from "hooks/useRouter";
import useWindowSize from "hooks/useWindowSize";

const ARNavItem = ({
  item,
  icon,
  subMenu,
  indent,
  handleDrawerToggle,
}: {
  item: IARNavType;
  icon?: ReactElement;
  subMenu?: boolean;
  indent?: boolean;
  handleDrawerToggle?: () => void;
}) => {
  const drawerWidth = useAppSelector(getDrawerWidth);
  const selectedNavItem = useAppSelector(getSelectedNavItem);
  const windowSize = useWindowSize();
  const checkMobile = useARMediaQuery("mobile");
  const checkTablet = useARMediaQuery("tablet");
  const isHorizontal = useMemo(() => {
    return windowSize.height < windowSize.width;
  }, [windowSize]);

  const isMobile = checkMobile || (checkTablet && !isHorizontal);
  const notClickable = item.type === NavItemType.GROUP ? !isMobile : false;
  const noText = item.type === NavItemType.GROUP ? !isMobile : false;
  const theme = useTheme();
  const { history } = useRouter();

  const buttonBackgroundColor = useMemo(() => {
    if (item.darkBg) {
      return "black";
    } else if (
      selectedNavItem === item.path ||
      ((item.type === NavItemType.MENU || (item.type === NavItemType.ITEM && item.children)) &&
        item.children.find((child) => child.path === selectedNavItem))
    ) {
      return "#EBEBEB";
    } else {
      return "transparent";
    }
  }, [item, selectedNavItem]);

  const StyledListItemButton = styled(ListItemButton)({
    margin: 0,
    minHeight: subMenu ? "auto" : drawerWidth,
    minWidth: drawerWidth,
    padding: subMenu ? (indent ? "16px 16px 16px 64px" : "8px 100px 8px 16px") : 1,
    background: buttonBackgroundColor,
    boxShadow: item.darkBg ? "2px 4px 10px 0px rgba(0, 0, 0, 0.24), 1px 1px 6px 0px rgba(208, 208, 208, 0.32)" : "none",
    "&:hover": {
      backgroundColor: notClickable ? "transparent" : item.darkBg ? "#404245" : "#EBEBEB",
    },
    cursor: notClickable ? "default" : "pointer",
  });

  const StyledListItemIcon = styled(ListItemIcon)({
    minWidth: isMobile ? (subMenu ? "20px" : drawerWidth) : "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    marginRight: subMenu ? theme.spacing(1) : 0,
    svg: { color: item.darkBg ? "white" : theme.palette.primary.main, fontSize: "24px" },
  });

  const StyledListItemText = styled(ListItemText)({
    display: "flex",
    justifyContent: isMobile || subMenu ? "flex-start" : "center",
    alignItems: "center",
    padding: 0,
    margin: 0,
  });

  const StyledButtonContainer = styled(Box)({
    display: "flex",
    flexDirection: isMobile || subMenu ? "row" : "column",
    alignItems: "space-between",
    justifyContent: "space-between",
    width: "100%",
  });

  const StyledTypography = styled(Typography)({
    fontFamily: "Inter",
    lineHeight: "140%",
    paddingTop: 0.5,
    fontSize: item.type === NavItemType.GROUP ? "16px" : isMobile || subMenu ? "14px" : "11px",
    fontWeight: item.type === NavItemType.GROUP ? 700 : isMobile ? (subMenu ? 400 : 500) : 400,
    color: item.darkBg ? "white" : "black",
  });

  return (
    <StyledListItemButton
      disableRipple
      onClick={() => {
        if (!notClickable) {
          item.type === NavItemType.GROUP && item.onClick();
          item.path && history.push(item.path);
          item.onClick && item.onClick();
          handleDrawerToggle && item.type === NavItemType.ITEM && handleDrawerToggle();
        }
      }}
    >
      <StyledButtonContainer>
        {!item.hidden ? (
          <>
            <StyledListItemIcon>
              <ARBadge notifyStatus={true} invisible={!item.showBadge}>
                {item.icon}
              </ARBadge>
            </StyledListItemIcon>
            {!noText && (
              <StyledListItemText>
                <StyledTypography>{item.name}</StyledTypography>
              </StyledListItemText>
            )}
          </>
        ) : (
          <></>
        )}
        {isMobile && icon}
      </StyledButtonContainer>
    </StyledListItemButton>
  );
};

export default ARNavItem;
