import { string } from "mathjs";

import { PermissionDict, RoleDict } from ".";

import { User, UserState, Status, Device, Site, Profile, Role, Permission } from "./types";

export const deserializePermissionData = (obj: any[]): Permission[] => {
  return obj
    ? obj.map((permission) => {
        return {
          id: permission.id,
          name: permission.name,
          codename: permission.codename,
        } as Permission;
      })
    : [];
};

export const deserializeRoleData = (obj: any[]): Role[] => {
  return obj
    ? obj.map((role) => {
        return {
          id: string(role.id),
          name: role.display_name,
          description: role.description,
          logo: role.logo,
          permissions: getPermissionDictFromPermissionList(role.permissions),
        } as Role;
      })
    : [];
};

export const serializeUserData = (userId: string, user: User, updatedRoles = false) => {
  return {
    id: userId,
    userroles: updatedRoles ? serializeProfileRoleData(user) : null,
    is_active: user.isActive,
  };
};

export const serializeRoleData = (role: Role) => {
  return {
    id: role.id,
    display_name: role.name,
    description: role.description,
    logo: role.logo !== "" ? role.logo : null,
    permissions: Object.keys(role.permissions),
  };
};

export const serializeProfileData = (userId: string, data: Profile) => {
  return {
    id: data.id,
    userId: userId,
    site: data.defaultSite.site,
    display_name: data.displayName,
    active_until: data.activeUntil,
  };
};

export const serializeProfileRoleData = (data: User) => {
  return Object.values(data.roles).map((role) => {
    return role;
  });
};

export const getRoleDictFromRolesList = (roles: Role[]): RoleDict => {
  const roleDict: RoleDict = {};
  roles = deserializeRoleData(roles);
  for (let i = 0; roles && i < roles.length; i++) {
    roleDict[roles[i].id] = roles[i];
  }
  return roleDict;
};

export const getPermissionDictFromPermissionList = (permissions: Permission[]): PermissionDict => {
  const permissionDict: PermissionDict = {};
  permissions = deserializePermissionData(permissions);
  for (let i = 0; permissions && i < permissions.length; i++) {
    permissionDict[permissions[i].codename] = permissions[i];
  }
  return permissionDict;
};

export const deserializeProfileData = (obj: any, name: string) => {
  const profileData: Profile =
    obj !== null
      ? {
          id: obj.id,
          defaultSite: { site: obj.site },
          displayName: obj.display_name ? obj.display_name : name,
          profilePicture: obj.profile_picture ? obj.profile_picture : "",
          activeUntil: obj.active_until ? obj.active_until : null,
          // activeUntil: obj.active_until ? new Date(obj.active_until) : undefined,
          state: obj.state ? obj.state : UserState.Normal,
          readTerms: obj.read_terms ? obj.read_terms : false,
        }
      : {
          id: undefined,
          defaultSite: { site: null },
          displayName: "",
          profilePicture: "",
          activeUntil: null,
          state: UserState.Normal,
          readTerms: false,
        };

  return profileData;
};

export const getUserDictFromJson = (obj: any) => {
  const users: User[] = [];

  for (let i = 0; obj && i < obj.length; i++) {
    const user: User = getUserFromJson(obj[i]);
    users.push(user);
  }
  return users;
};

export const getUserFromJson = (obj: any) => {
  const user = {} as User;
  user.id = obj.id;
  user.email = obj.email;

  if (obj.display_name !== "") {
    user.name = obj.display_name;
  } else if (obj.first_name !== "" && obj.last_name !== "") {
    user.name = obj.first_name + " " + obj.last_name;
  } else if (obj.last_name !== "") {
    user.name = obj.last_name;
  } else if (obj.first_name !== "") {
    user.name = obj.first_name;
  } else {
    user.name = "User " + obj.id;
  }

  user.isActive = obj.is_active;
  user.firstName = obj.first_name;
  user.lastName = obj.last_name;
  user.phoneNumber = obj.phone_number;

  user.status = (obj.status as string) === "ONLINE" ? Status.Active : Status.Inactive;
  user.lastLogin = obj.last_login ? new Date(obj.last_login).toString() : "Invalid Date";

  user.connection = obj.connection;
  if (user.connection) {
    user.connection.status = obj.connection.status === "ONLINE" ? Status.Active : Status.Inactive;
  }

  if (obj.userprofile) {
    user.userProfile = deserializeProfileData(obj.userprofile, user.name);
  }

  if (obj.userprofile_id) {
    user.userProfileId = obj.userprofile_id;
  }

  if (obj.roles) {
    user.roles = obj.roles;
  } else {
    user.roles = [];
  }

  if (obj.team) {
    user.teams = obj.team;
  } else {
    user.teams = [];
  }

  // mock these fields for now
  user.img = "";
  user.device = Device.Desktop;
  user.sites = [Site.SiteA, Site.SiteB];
  return user;
};
