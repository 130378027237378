import { folderApi } from "./folderApi";

export interface ITeam {
  id: string;
  name: string;
  description?: string;
  colour: string;
  userprofile: string[];
  addUserProfile?: string[];
  removeUserProfile?: string[];
  folder: string[];
  addFolder?: string[];
  removeFolder?: string[];
}

const deserialize = (obj: any): ITeam => {
  return {
    id: obj.id,
    name: obj.name,
    description: obj.description,
    userprofile: obj.userprofile ?? [],
    folder: obj.folder,
    colour: obj.colour,
  } as ITeam;
};

const serializeCreate = (item: ITeam) => {
  const formData = new FormData();

  if (item.name) formData.append("name", item.name);
  if (item.description) formData.append("description", item.description);
  if (item.addUserProfile) {
    item.addUserProfile.forEach((item) => {
      formData.append("userprofile", item);
    });
  }

  return formData;
};

const serializeUpdate = (item: ITeam) => {
  const formData = new FormData();

  formData.append("id", item.id);
  if (item.name) formData.append("name", item.name);
  if (item.description !== undefined) formData.append("description", item.description);
  if (item.addUserProfile) {
    item.addUserProfile.forEach((item) => {
      formData.append("add_userprofile", item);
    });
  }
  if (item.removeUserProfile) {
    item.removeUserProfile.forEach((item) => {
      formData.append("remove_userprofile", item);
    });
  }

  return formData;
};

export const teamApi = folderApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<ITeam[], void>({
      query() {
        return {
          url: `teams/`,
        };
      },
      transformResponse: (response: any, meta, arg) => {
        return response.map((item: any) => deserialize(item));
      },
      providesTags: (result) =>
        result
          ? // successful query
            [...result.map(({ id }) => ({ type: "Teams", id } as const)), { type: "Teams", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Teams", id: "LIST" }],
    }),
    getTeamById: builder.query<ITeam, string>({
      query(id) {
        return {
          url: `teams/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return deserialize(response);
      },
      providesTags: (result, error, id) => [
        { type: "Teams", id },
        { type: "Folders", id: "LIST" },
      ],
    }),
    createTeam: builder.mutation<any, ITeam>({
      query(body: ITeam) {
        return {
          url: "teams/",
          method: "POST",
          body: serializeCreate(body),
        };
      },
      invalidatesTags: [{ type: "Teams", id: "LIST" }],
    }),
    updateTeam: builder.mutation<any, ITeam>({
      query(body: ITeam) {
        return {
          url: `teams/${body.id}/`,
          method: "PATCH",
          body: serializeUpdate(body),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Teams", id },
        { type: "Teams", id: "LIST" },
        { type: "UserProfiles", id: "LIST" },
      ],
    }),
    deleteTeam: builder.mutation<any, string>({
      query(id) {
        return {
          url: `teams/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: "Teams", id: "LIST" },
        { type: "Folders", id: "LIST" },
      ],
    }),
  }),
});
export const {
  useGetTeamsQuery,
  useLazyGetTeamsQuery,
  useGetTeamByIdQuery,
  useLazyGetTeamByIdQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
} = teamApi;
