import { Panorama } from "../serviceTypes/Panorama";
import { threeMatrix4ToMatrix4 } from "components/CameraSelector/Common/ThreeUtils";
import { APIInstance } from "services/ContentServer/APIInstance";
import { ENDPOINTS } from "services/ContentServer/Audit/constants";
import { ServiceBase } from "services/ContentServer/ServiceBase";
import { PanoSphere } from "utils/Contexts/PanoramaContext";

export const PanoramaSWRKeys = {
  PANAORMAS: "panoramas",
};

export class PanoramaService extends ServiceBase<Panorama> {
  constructor(apiInstance: APIInstance) {
    super(Panorama, apiInstance, ENDPOINTS.PANORAMAS);
  }

  async postPanorama(pano: PanoSphere, snapshotId: string) {
    const requestedPose = pano.mesh.clone();
    requestedPose.scale.set(1, 1, 1);
    requestedPose.updateMatrix();

    const mediaPoseMatrix = threeMatrix4ToMatrix4(requestedPose.matrix);

    const image = await fetch(pano.image);
    const blob = await image.blob();
    const file = new File([blob], "panorama.jpg", { type: blob.type });

    const mediaPose = new Panorama({
      img: file,
      timestamp: new Date().toISOString(),
      pose: mediaPoseMatrix,
      snapshotId: snapshotId,
    } as Panorama);
    return super.post(mediaPose);
  }
}
