import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import React, { useMemo } from "react";

type FieldLengthProps = TypographyProps & {
  displayConditions?: boolean;
  maxLength: number;
  curLength: number;
};

export const FieldLengthMsg = (props: FieldLengthProps) => {
  const theme = useTheme();
  const { children, ...other } = props;
  const validLength = useMemo(() => {
    const goodLength = other.curLength <= other.maxLength;
    if (other.displayConditions !== undefined) {
      return goodLength && other.displayConditions;
    } else return goodLength;
  }, [other.curLength, other.maxLength, other.displayConditions]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="body2"
        style={{
          alignSelf: "flex-start",
          color: theme.palette.error.main,
          visibility: validLength ? "hidden" : "visible",
          ...other.style,
        }}
      >
        Please only use {other.maxLength} characters.
      </Typography>
      <Typography
        variant="body2"
        style={{
          alignSelf: "flex-end",

          color: validLength ? theme.palette.primary.main : theme.palette.error.main,
          ...other.style,
        }}
      >
        {other.curLength + "/" + other.maxLength}{" "}
      </Typography>
    </Box>
  );
};
