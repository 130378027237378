import * as THREE from "three";

class FrustumBufferGeometry extends THREE.BufferGeometry {
  constructor(width: number, length: number, height: number, cx: number, cy: number, farPlane: boolean) {
    super();
    const vertices = [
      new THREE.Vector3(cx / 2, -cy / 2, 0), // 0
      new THREE.Vector3(-0.5, -0.5, -1), // 1
      new THREE.Vector3(-0.5, 0.5, -1), // 2
      new THREE.Vector3(0.5, 0.5, -1), // 3
      new THREE.Vector3(0.5, -0.5, -1), // 4
    ];

    let pointsOrder: number[] = [];
    if (farPlane) {
      pointsOrder = [1, 2, 3, 4, 1, 3];
    } else {
      pointsOrder = [0, 1, 4, 0, 4, 3, 0, 3, 2, 0, 2, 1];
    }

    const points: THREE.Vector3[] = [];
    pointsOrder.forEach((idx) => {
      points.push(new THREE.Vector3(vertices[idx].x, vertices[idx].y, vertices[idx].z));
    });

    this.setFromPoints(points);

    const transformation = new THREE.Matrix4().makeScale(width, length, height);
    const translation = new THREE.Matrix4().makeTranslation((-width / 2) * cx, (-length / 2) * -cy, 0);

    this.applyMatrix4(transformation);
    this.applyMatrix4(translation);
  }
}

export default FrustumBufferGeometry;
