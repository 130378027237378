import { Icon, IconProps } from "@mui/material";
import React from "react";

import icon from "assets/icons/SharpEdit.svg";

export const SharpEditIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <img src={icon} alt="Edit Icon" />
    </Icon>
  );
};

export default SharpEditIcon;
