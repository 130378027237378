import * as msal from "@azure/msal-browser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

import packageJson from "../../../package.json";

export interface IAuthState {
  token: string;
  account: msal.AccountInfo | undefined;
  apiVersion: string;
}

const initialState: IAuthState = {
  token: "",
  account: undefined,
  apiVersion: packageJson.apiVersion,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setAccount: (state, action: PayloadAction<msal.AccountInfo>) => {
      state.account = action.payload;
    },
    setApiVersion: (state, action: PayloadAction<string>) => {
      state.apiVersion = action.payload;
    },
  },
});

export const { setToken, setAccount, setApiVersion } = authSlice.actions;

export const getToken = (state: RootState) => state.auth.token;
export const getAccount = (state: RootState) => state.auth.account;

export default authSlice;
