export const colorStringToInt = (color: string) => {
  return parseInt(color.substr(1), 16);
};

const colorToHexString = (color: number) => {
  color = Math.round(color);
  if (color < 0) color = 0;
  if (color > 255) color = 255;

  let s = color.toString(16);
  if (s.length < 2) {
    s = "0" + s;
  }

  return s;
};

const HexToColorString = (r: number, g: number, b: number) => {
  return "#" + colorToHexString(r) + colorToHexString(g) + colorToHexString(b);
};

/**
 * @param shade Shade is any number between -1 and 1, -1 = black, 0 = initial color, 1 = white
 */
export const shade = (colour: string, shade: number) => {
  let r = parseInt(colour.substr(1, 2), 16);
  let g = parseInt(colour.substr(3, 2), 16);
  let b = parseInt(colour.substr(5, 2), 16);

  if (shade < 0) {
    r = (1 + shade) * r;
    g = (1 + shade) * g;
    b = (1 + shade) * b;
  } else {
    r = (1 - shade) * r + shade * 255;
    g = (1 - shade) * g + shade * 255;
    b = (1 - shade) * b + shade * 255;
  }

  return HexToColorString(r, g, b);
};
