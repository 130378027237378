import { KeyboardArrowDownOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";
import React from "react";

import ARNavItem from "./ARNavItem";
import { IARNavType } from "./ARNavType";

const ARNavCollapse = ({ item, handleDrawerToggle }: { item: IARNavType; handleDrawerToggle?: () => void }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const menuItems = item.children.map(
    (item: IARNavType) => {
      return (
        <ARNavItem key={item.id} item={item} subMenu={true} indent={true} handleDrawerToggle={handleDrawerToggle} />
      );
    },
    [item]
  );
  return (
    <Box>
      <Box onClick={handleClick}>
        <ARNavItem
          item={item}
          handleDrawerToggle={handleDrawerToggle}
          icon={
            open ? (
              <KeyboardArrowDownOutlined sx={{ my: "auto", mx: 2, fontSize: "20px" }} />
            ) : (
              <KeyboardArrowRightOutlined sx={{ my: "auto", mx: 2, fontSize: "20px" }} />
            )
          }
        />
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {menuItems}
      </Collapse>
    </Box>
  );
};

export default ARNavCollapse;
