import { useRef, useEffect } from "react";

declare type EventHandler = (event: Event) => void;

export function useEventListener(
  eventName: string,
  handler: EventHandler,
  element: EventTarget | null = window,
  options?: boolean | AddEventListenerOptions
) {
  const savedHandler = useRef<EventHandler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (element && element.addEventListener) {
      const eventListener = (event: Event) => savedHandler.current && savedHandler.current(event);
      element.addEventListener(eventName, eventListener, options);

      return () => {
        element.removeEventListener(eventName, eventListener, options);
      };
    }
  }, [eventName, element, options]);
}

export function useAddEventListener(
  eventName: string,
  handler: EventHandler,
  element: EventTarget | null = window,
  shouldAdd: boolean,
  options?: boolean | EventListenerOptions
) {
  const savedHandler = useRef<EventHandler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: Event) => savedHandler.current && savedHandler.current(event);
    if (element && shouldAdd && element.addEventListener) {
      element.addEventListener(eventName, eventListener, options);
    }
    return () => {
      if (element) {
        element.removeEventListener(eventName, eventListener, options);
      }
    };
  }, [eventName, element, options, shouldAdd]);
}
