import React, { useContext, useEffect, useMemo, useState } from "react";
import { AssetTransform, useLazyGetFacilityByIdQuery } from "redux/api/facilityApi";
import { FOLDERTYPE, IFacilityMetadata, STATE, useLazyGetFoldersQuery } from "redux/api/folderApi";
import { useLazyGetFacilitySnapshotsQuery } from "redux/api/snapshotApi";
import { getSelectedSiteId } from "redux/features/appSlice";
import { useAppSelector } from "redux/hooks";
import useSWR from "swr";

import { SnapshotContext } from "./SnapshotContext";
import usePrevious from "hooks/usePrevious";
import { identityMatrix, Matrix4 } from "services/ContentServer/Audit";
import { FacilitySWRKeys } from "services/ContentServer/Audit/services/FacilityService";
import { Facility } from "services/ContentServer/Audit/serviceTypes/Facility";
import { FileSegment } from "services/ContentServer/Audit/serviceTypes/FileSegment";
import { LODFile } from "services/ContentServer/Audit/serviceTypes/LODFile";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { StitchPoint } from "utils/Drawing/useStitch";
import { getSnapshotModelData, SegmentData } from "utils/Three/useModels";

interface SnapshotWithTransform {
  snapshot: Snapshot;
  transform: Matrix4;
}

export interface SnapshotWithIdentifiers {
  snapshot: Snapshot;
  identifiers: string[];
}

function isFacilityMetadata(object: any): object is IFacilityMetadata {
  if (object) {
    return "mapFile" in object;
  }
  return false;
}

export interface FacilityContextType {
  facilityId?: string;
  facility?: Facility;
  facilities?: Facility[];
  siteFacilities?: Facility[];
  facilitySnapshotData: SegmentData[][];
  facilitySnapshots: Snapshot[];
  facilityTransforms: Matrix4[];
  snapshotIdentifiers: SnapshotWithIdentifiers[];
  intersectedSnapshot?: Snapshot;
  addToFacilityDialogOpen: boolean;
  removeFromFacilityDialogOpen: boolean;
  editFacilityDetailsDialogOpen: boolean;
  editFloorplanDialogOpen: boolean;
  viewFloorplanDialogOpen: boolean;
  isUpdatingSnapshot: boolean;
  pointPairs: StitchPoint[][];
  facilityLoading: boolean;
  facilitiesLoading: boolean;
  refetchSnapshots: boolean;
  addingAssetToFacility: boolean;
  appendingFloorplan: any;
  setAppendingFloorplan: (selected: any | ((selected: any) => any)) => void;
  undoPointsPair: () => void;
  clearPoints: () => void;
  setUndoPointsPair: (callback: () => void) => void;
  setClearPoints: (callback: () => void) => void;
  setAddToFacilityDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => void;
  setRemoveFromFacilityDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => void;
  setEditFacilityDetailsDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => void;
  setEditFloorplanDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => void;
  setViewFloorplanDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => void;
  setIsUpdatingSnapshot: (prev: boolean | ((prev: boolean) => boolean)) => void;
  setFacilityId: (id: string | undefined) => void;
  setIntersectedSnapshot: (snapshot: Snapshot | undefined) => void;
  setPointPairs: (prev: StitchPoint[][] | ((prev: StitchPoint[][]) => StitchPoint[][])) => void;
  setRefetchSnapshots: (refetch: boolean) => void;
  setRefetchSiteFacilities: (refetch: boolean) => void;
  setAddingAssetToFacility: (isAdding: boolean) => void;
}

export const defaultContext: FacilityContextType = {
  facilitySnapshotData: [],
  facilitySnapshots: [],
  facilityTransforms: [],
  snapshotIdentifiers: [],
  addToFacilityDialogOpen: false,
  removeFromFacilityDialogOpen: false,
  editFacilityDetailsDialogOpen: false,
  editFloorplanDialogOpen: false,
  viewFloorplanDialogOpen: false,
  isUpdatingSnapshot: false,
  pointPairs: [],
  facilityLoading: false,
  facilitiesLoading: false,
  refetchSnapshots: false,
  addingAssetToFacility: false,
  appendingFloorplan: undefined,
  setAppendingFloorplan: (selected: any | ((selected: any) => any)) => {},
  undoPointsPair: () => {},
  clearPoints: () => {},
  setUndoPointsPair: (callback: () => void) => {},
  setClearPoints: (callback: () => void) => {},
  setAddToFacilityDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => {},
  setRemoveFromFacilityDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => {},
  setEditFacilityDetailsDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => {},
  setEditFloorplanDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => {},
  setViewFloorplanDialogOpen: (prev: boolean | ((prev: boolean) => boolean)) => {},
  setIsUpdatingSnapshot: (prev: boolean | ((prev: boolean) => boolean)) => {},
  setFacilityId: (id: string | undefined) => {},
  setIntersectedSnapshot: (snapshot: Snapshot | undefined) => {},
  setPointPairs: (prev: StitchPoint[][] | ((prev: StitchPoint[][]) => StitchPoint[][])) => {},
  setRefetchSnapshots: (refetch: boolean) => {},
  setRefetchSiteFacilities: (refetch: boolean) => {},
  setAddingAssetToFacility: (isAdding: boolean) => {},
};

export const ProvideFacilityContext = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const { contentServer } = useContext(RequestContext);
  const { appendingSnapshot, setAppendingSnapshot } = useContext(SnapshotContext);
  const [intersectedSnapshot, setIntersectedSnapshot] = useState<Snapshot>();
  const [facilityId, setFacilityId] = useState<string>();
  const [addToFacilityDialogOpen, setAddToFacilityDialogOpen] = useState<boolean>(false);
  const [removeFromFacilityDialogOpen, setRemoveFromFacilityDialogOpen] = useState<boolean>(false);
  const [editFacilityDetailsDialogOpen, setEditFacilityDetailsDialogOpen] = useState<boolean>(false);
  const [editFloorplanDialogOpen, setEditFloorplanDialogOpen] = useState<boolean>(false);
  const [viewFloorplanDialogOpen, setViewFloorplanDialogOpen] = useState<boolean>(false);
  const [isUpdatingSnapshot, setIsUpdatingSnapshot] = useState<boolean>(false);
  const [undoPointsPair, setUndoPointsPair] = useState<() => void>(() => {});
  const [clearPoints, setClearPoints] = useState<() => void>(() => {});
  const [pointPairs, setPointPairs] = useState<StitchPoint[][]>([]);
  const [addingAssetToFacility, setAddingAssetToFacility] = useState<boolean>(false);
  const [refetchSnapshots, setRefetchSnapshots] = useState<boolean>(true);
  const [snapshots, setSnapshots] = useState<Snapshot[]>([]);
  const [partialFacility, setPartialFacility] = useState<Facility>();
  const [assetTransforms, setAssetTransforms] = useState<AssetTransform[]>([]);
  const [refetchSiteFacilities, setRefetchSiteFacilities] = useState<boolean>(true);
  const [siteFacilities, setSiteFacilities] = useState<Facility[]>();
  const [getFacilitySnapshots, { isFetching: isFetchingSnaps }] = useLazyGetFacilitySnapshotsQuery();
  const [getFacilityById, { isFetching: isFetchingFacilities }] = useLazyGetFacilityByIdQuery();
  const [getFolders, { isError: errorGettingFolders }] = useLazyGetFoldersQuery();
  const [appending, setAppending] = useState<any>(undefined);

  const selectedSiteId = useAppSelector(getSelectedSiteId);
  const prevSiteId = usePrevious(selectedSiteId);

  const { data: facilities } = useSWR(selectedSiteId ? [FacilitySWRKeys.FACILITY] : null, () =>
    contentServer.facilityService.list([["site", selectedSiteId ?? ""]], ["id", "name"])
  );

  useEffect(() => {
    if (selectedSiteId) {
      if (refetchSiteFacilities || prevSiteId !== selectedSiteId) {
        getFolders({
          state: STATE.NORMAL,
          type: FOLDERTYPE.FACILITY,
          parentId: selectedSiteId,
          page: 1,
        })
          .unwrap()
          .then((data) => {
            setSiteFacilities(
              data.folders.map((item) => {
                return new Facility({
                  id: item.id,
                  name: item.name,
                  mapFile: isFacilityMetadata(item.metadata) ? item.metadata?.mapFile : undefined,
                  mapScale: isFacilityMetadata(item.metadata) ? item.metadata?.mapScale : undefined,
                  mapOpacity: isFacilityMetadata(item.metadata) ? item.metadata?.mapOpacity : undefined,
                  description: item.description,
                } as Facility);
              })
            );
            setRefetchSiteFacilities(false);
          });
      }
    } else {
      setSiteFacilities([]);
      setRefetchSiteFacilities(true);
    }
  }, [selectedSiteId, getFolders, refetchSiteFacilities, prevSiteId]);

  const siteFacilitiesLoading = useMemo(
    () => siteFacilities === undefined && !errorGettingFolders,
    [siteFacilities, errorGettingFolders]
  );

  useEffect(() => {
    if (facilityId) {
      if (refetchSnapshots) {
        getFacilitySnapshots(facilityId)
          .unwrap()
          .then((snapData) => {
            setSnapshots(snapData);
            getFacilityById(facilityId)
              .unwrap()
              .then((facData) => {
                setPartialFacility({
                  id: facData.id,
                  mapFile: facData.mapFile,
                  mapScale: facData.mapScale,
                  mapTransform: facData.mapTransform,
                  mapOpacity: facData.mapOpacity,
                  name: facData.name,
                  description: facData.description,
                } as Facility);
                setAssetTransforms(facData.assetTransforms ?? []);
                setRefetchSnapshots(false);
                setAddingAssetToFacility((prev) => {
                  if (prev) {
                    setAppendingSnapshot(undefined);
                    return !prev;
                  }
                  return prev;
                });
              });
          });
      }
    } else {
      setSnapshots([]);
      setPartialFacility(undefined);
      setAssetTransforms([]);
      setRefetchSnapshots(true);
    }
  }, [facilityId, refetchSnapshots, getFacilitySnapshots, getFacilityById, setAppendingSnapshot]);

  const facility = useMemo(() => {
    return partialFacility ? new Facility({ ...partialFacility, snapshots: snapshots } as Facility) : undefined;
  }, [partialFacility, snapshots]);

  const facilityLoading = useMemo(() => {
    return isFetchingFacilities || isFetchingSnaps;
  }, [isFetchingFacilities, isFetchingSnaps]);

  const prevFacility = usePrevious(facility);

  useEffect(() => {
    if (facility !== prevFacility) {
      setIntersectedSnapshot(undefined);
    }
  }, [facility, prevFacility]);

  const facilitySnapshotsWithTransform: SnapshotWithTransform[] = useMemo(() => {
    if (facility?.snapshots) {
      const validSnapshots: SnapshotWithTransform[] = [];
      facility.snapshots.forEach((snapshot: Snapshot) => {
        if (!appendingSnapshot || (appendingSnapshot && appendingSnapshot.id !== snapshot.id)) {
          const assetTransform = assetTransforms.find((item) => item.assetId === snapshot.assetId)?.transform;

          validSnapshots.push({
            snapshot: snapshot,
            transform: assetTransform ? assetTransform : identityMatrix,
          });
        }
      });
      return validSnapshots;
    } else {
      return [];
    }
  }, [facility, appendingSnapshot, assetTransforms]);

  const facilitySnapshots: Snapshot[] = useMemo(() => {
    return facilitySnapshotsWithTransform.map((snapshot: SnapshotWithTransform) => snapshot.snapshot);
  }, [facilitySnapshotsWithTransform]);

  const facilityTransforms: Matrix4[] = useMemo(
    () => facilitySnapshotsWithTransform.map((snap: SnapshotWithTransform) => snap.transform),
    [facilitySnapshotsWithTransform]
  );

  const facilitySnapshotData: SegmentData[][] = useMemo(() => {
    return facilitySnapshots.map((snapshot: Snapshot) => getSnapshotModelData(snapshot, true));
  }, [facilitySnapshots]);

  const snapshotsWithIdentifiers: SnapshotWithIdentifiers[] = useMemo(() => {
    if (facility?.snapshots) {
      const identifierMap: SnapshotWithIdentifiers[] = [];
      facility.snapshots.find((snapshot: Snapshot) => {
        const identifiers: string[] = [];
        snapshot?.segments?.forEach((segment: FileSegment) => {
          if (segment.lodFiles && segment.lodFiles.length > 0) {
            segment.lodFiles.forEach((lod: LODFile) => {
              if (lod.file) {
                identifiers.push(lod.file.toString());
              }
            });
          } else {
            if (segment.file) {
              identifiers.push(segment.file.toString());
            }
          }
        });
        identifierMap.push({ snapshot: snapshot, identifiers: identifiers });
      });
      return identifierMap;
    } else {
      return [];
    }
  }, [facility]);

  const contextValues: FacilityContextType = useMemo(
    () => ({
      facilityId: facilityId,
      facility: facility,
      facilities: facilities,
      siteFacilities: siteFacilities,
      facilitySnapshotData: facilitySnapshotData,
      facilitySnapshots: facilitySnapshots,
      facilityTransforms: facilityTransforms,
      snapshotIdentifiers: snapshotsWithIdentifiers,
      intersectedSnapshot: intersectedSnapshot,
      addToFacilityDialogOpen: addToFacilityDialogOpen,
      removeFromFacilityDialogOpen: removeFromFacilityDialogOpen,
      editFacilityDetailsDialogOpen: editFacilityDetailsDialogOpen,
      editFloorplanDialogOpen: editFloorplanDialogOpen,
      viewFloorplanDialogOpen: viewFloorplanDialogOpen,
      isUpdatingSnapshot: isUpdatingSnapshot,
      pointPairs: pointPairs,
      facilityLoading: facilityLoading,
      facilitiesLoading: siteFacilitiesLoading,
      refetchSnapshots: refetchSnapshots,
      addingAssetToFacility: addingAssetToFacility,
      appendingFloorplan: appending,
      setAppendingFloorplan: setAppending,
      undoPointsPair: undoPointsPair,
      clearPoints: clearPoints,
      setUndoPointsPair: setUndoPointsPair,
      setClearPoints: setClearPoints,
      setEditFacilityDetailsDialogOpen: setEditFacilityDetailsDialogOpen,
      setEditFloorplanDialogOpen: setEditFloorplanDialogOpen,
      setViewFloorplanDialogOpen: setViewFloorplanDialogOpen,
      setRemoveFromFacilityDialogOpen: setRemoveFromFacilityDialogOpen,
      setAddToFacilityDialogOpen: setAddToFacilityDialogOpen,
      setIsUpdatingSnapshot: setIsUpdatingSnapshot,
      setFacilityId: setFacilityId,
      setIntersectedSnapshot: setIntersectedSnapshot,
      setPointPairs: setPointPairs,
      setRefetchSnapshots: setRefetchSnapshots,
      setRefetchSiteFacilities: setRefetchSiteFacilities,
      setAddingAssetToFacility: setAddingAssetToFacility,
    }),
    [
      facility,
      facilityId,
      facilities,
      siteFacilities,
      facilitySnapshotData,
      facilitySnapshots,
      facilityTransforms,
      snapshotsWithIdentifiers,
      intersectedSnapshot,
      addToFacilityDialogOpen,
      removeFromFacilityDialogOpen,
      editFacilityDetailsDialogOpen,
      editFloorplanDialogOpen,
      viewFloorplanDialogOpen,
      isUpdatingSnapshot,
      pointPairs,
      facilityLoading,
      siteFacilitiesLoading,
      refetchSnapshots,
      addingAssetToFacility,
      appending,
      undoPointsPair,
      clearPoints,
    ]
  );
  return <FacilityContext.Provider value={contextValues}>{children}</FacilityContext.Provider>;
};

export const FacilityContext = React.createContext(defaultContext);
