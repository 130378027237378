import { ISerialization } from "services/ContentServer/ISerialization";

export class AnnotationFileTypes extends ISerialization {
  constructor(annotation?: AnnotationFileTypes) {
    super();
    if (annotation) {
      Object.assign(this, annotation);
    }
  }

  deserialize(json: any): void {
    // Just a list - no deserialization needed.
    return json;
  }

  serialize(): any {
    // Read-only for now
  }
}
