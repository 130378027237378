import { useEffect } from "react";

import useMedia from "./useMedia";

export interface StyleData {
  name: string;
  value: any;
  valueType?: string;
}

export interface SizeStyle {
  data: StyleData[];
}

const queries = [
  "(max-width: 600px)",
  "(min-width: 601px) and (max-width: 960px)",
  "(min-width: 961px) and (max-width: 1280px)",
  "(min-width: 1281px) and (max-width: 1920px)",
  "(min-width: 1921px)",
];

const xsStyle: StyleData[] = [
  { name: "fontSize", value: 12, valueType: "px" },
  { name: "iconSize", value: "large" },
  { name: "drawerSize", value: 220, valueType: "px" },
  { name: "closedDrawerWidth", value: 50, valueType: "px" },
  { name: "dashboardMapWidth", value: "100%" },
];

const smStyle: StyleData[] = [
  { name: "fontSize", value: 13, valueType: "px" },
  { name: "iconSize", value: "large" },
  { name: "drawerSize", value: 230, valueType: "px" },
  { name: "closedDrawerWidth", value: 50, valueType: "px" },
  { name: "dashboardMapWidth", value: "100%" },
];

const mdStyle: StyleData[] = [
  { name: "fontSize", value: 14, valueType: "px" },
  { name: "iconSize", value: "x-large" },
  { name: "drawerSize", value: 240, valueType: "px" },
  { name: "closedDrawerWidth", value: 64, valueType: "px" },
  { name: "dashboardMapWidth", value: "90%" },
];

const lgStyle: StyleData[] = [
  { name: "fontSize", value: 15, valueType: "px" },
  { name: "iconSize", value: "x-large" },
  { name: "drawerSize", value: 250, valueType: "px" },
  { name: "closedDrawerWidth", value: 64, valueType: "px" },
  { name: "dashboardMapWidth", value: "100%" },
];

const xlStyle: StyleData[] = [
  { name: "fontSize", value: 16, valueType: "px" },
  { name: "iconSize", value: "xx-large" },
  { name: "drawerSize", value: 250, valueType: "px" },
  { name: "closedDrawerWidth", value: 64, valueType: "px" },
  { name: "dashboardMapWidth", value: "100%" },
];

const values: SizeStyle[] = [
  { data: xsStyle },
  { data: smStyle },
  { data: mdStyle },
  { data: lgStyle },
  { data: xlStyle },
];

const useReactiveTheme = () => {
  const mediaData = useMedia(queries, values, values[0]);

  useEffect(() => {
    mediaData.data.forEach((data) => {
      document.documentElement.style.setProperty(
        "--" + data.name,
        data.value.toString() + (data.valueType === undefined ? "" : data.valueType)
      );
    });
  }, [mediaData]);
};

export default useReactiveTheme;
