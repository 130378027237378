import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { SnapshotCollection } from "../serviceTypes/SnapshotCollection";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const SnapshotCollectionSWRKeys = {
  TEMPLATE_WORK_ORDERS: "templateWorkOrders",
  WORK_ORDERS: "workOrders",
};
export class SnapshotCollectionService extends ServiceBase<SnapshotCollection> {
  constructor(apiInstance: APIInstance) {
    super(SnapshotCollection, apiInstance, ENDPOINTS.SNAPSHOT_COLLECTIONS);
  }

  createFromTemplate(name: string, id: string, site: string, snapshotData: { name: string; specifier: string }[]) {
    const names: string[] = [];
    const specifiers: string[] = [];

    snapshotData.forEach((data) => {
      names.push(data.name);
      specifiers.push(data.specifier);
    });

    const request = { name: name, parent: id, site: site, snapshot_names: names, specifiers: specifiers };

    return super.postCustomFormData(request);
  }
}
