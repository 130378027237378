import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { styled } from "@mui/system";

const CloseButton = styled(CloseOutlinedIcon)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  zIndex: (theme as any).zIndex.modal,
  margin: 0,
  color: theme.palette.common.white,
}));

export default CloseButton;
