import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { RefObject, useCallback, useEffect, useMemo, useState } from "react";

import TimePicker from "./TimePicker";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { toTwoDigits } from "utils/DateUtils";

interface DatePickerProps {
  className?: string;
  value: Date | null | undefined;
  setValue: (value: Date | null | undefined | ((prev: Date | null | undefined) => Date | null | undefined)) => void;
  timePickerRef: RefObject<HTMLButtonElement>;
}

const DatePicker = ({ value, setValue, timePickerRef }: DatePickerProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [hour, setHour] = useState<number>(0); // 24
  const [minute, setMinute] = useState<number>(0); // 60

  const handleDateChange = useCallback(
    (date: moment.Moment | null) => {
      if (date) {
        date.hour(hour);
        date.minute(minute);
        setValue(date.toDate());
      } else {
        setValue(null);
      }
    },
    [hour, minute, setValue]
  );

  const handleTimeChange = useCallback(
    (date: moment.Moment | undefined) => {
      if (date && value) {
        date.set({ year: value.getFullYear(), month: value.getMonth(), date: value.getDate() });
        setValue(date.toDate());
      }
      setAnchorEl(null);
    },
    [setValue, value]
  );

  const timeToString = useMemo(() => {
    const pm = hour >= 12 ? "  PM" : "  AM";
    const hourString = toTwoDigits(hour % 12 || 12);
    const minString = toTwoDigits(minute);
    return hourString + " : " + minString + pm;
  }, [hour, minute]);

  useEffect(() => {
    setHour(value?.getHours() || 0);
    setMinute(value?.getMinutes() || 0);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        inputFormat="MM-DD-YYYY"
        value={value}
        onChange={handleDateChange}
        minDate={moment().subtract(5, "weeks")}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "MM - DD - YYYY",
            }}
            sx={{
              "&.MuiFormControl-root": {
                height: "100%",
              },
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "140%",
              color: theme.palette.dalmatianDesign.dark,
              ".MuiOutlinedInput-root": {
                height: "100%",
                paddingRight: "0px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "13px",
              },
              ".MuiOutlinedInput-input": {
                height: "100%",
              },
              ["@media (max-width:1366px)"]: {
                width: "170px",
              },
              width: "170px",
            }}
          />
        )}
      />
      <FixedHeightTextField
        variant="outlined"
        value={timeToString}
        onClick={() => value && setAnchorEl(timePickerRef.current)}
        disabled={!value}
        sx={{
          ["@media (max-width:1366px)"]: {
            width: "130px",
          },
          width: "130px",
          ".MuiOutlinedInput-root": {
            height: "100%!important",
            paddingRight: "0px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "13px",
          },
          ".MuiOutlinedInput-input": {
            height: "100%!important",
          },
          "&.MuiFormControl-root": {
            height: "100%",
          },
        }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="start" sx={{ color: theme.palette.dalmatianDesign.dark, margin: "0px" }}>
                <IconButton id="expand-more-icon-button" size="small" ref={timePickerRef} disabled={!value}>
                  <ExpandMoreOutlinedIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      {Boolean(anchorEl) && (
        <TimePicker
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          hour={hour}
          min={minute}
          handleClick={handleTimeChange}
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
