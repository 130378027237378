import { folderApi, FolderParam, LOCATION_TYPE } from "./folderApi";
// import { createApi } from "@reduxjs/toolkit/query/react";

// import customFetchBase from "./customFetchBase";

export interface ISite {
  id: string;
  parentId: string;
  name: string;
  contactId: string;
  description?: string;
  company?: string;
  address?: string;
  country?: string;
  region?: string;
  zipPostalCode?: string;
  status?: boolean;
  locationType: LOCATION_TYPE;
  folderPath: string;
  idPath: string;
  param: FolderParam;
}

const deserialize = (obj: any): ISite => {
  return {
    id: obj.id,
    parentId: obj["parent_id"],
    name: obj.name,
    contactId: obj["contact_id"],
    description: obj.description,
    company: obj.company,
    address: obj.address,
    country: obj.country,
    region: obj.region,
    locationType: obj["location_type"],
    zipPostalCode: obj["zip_postal_code"],
    folderPath: obj["folder_path"],
    idPath: obj["id_path"],
  } as ISite;
};

const mapLocationType = (type: LOCATION_TYPE) => {
  switch (type) {
    case LOCATION_TYPE.BUILDING:
      return "building";
    case LOCATION_TYPE.CITY:
      return "city";
    case LOCATION_TYPE.COUNTRY:
      return "country";
    case LOCATION_TYPE.AREA:
      return "floor";
    case LOCATION_TYPE.REGION:
      return "region";
    case LOCATION_TYPE.SITE:
      return "site";
    case LOCATION_TYPE.STATE:
      return "state/province";
    default:
      return "site";
  }
};
const serialize = (item: ISite) => {
  const obj: any = {
    id: item.id,
  };

  if (item.parentId) obj["parent_id"] = item.parentId;
  if (item.name) obj.name = item.name;
  if (item.contactId) obj["contact_id"] = item.contactId;
  if (item.description !== undefined) obj.description = item.description;
  if (item.company) obj.company = item.company;
  if (item.address) obj.address = item.address;
  if (item.country) obj.country = item.country;
  if (item.region) obj.region = item.region;
  if (item.locationType) obj["location_type"] = mapLocationType(item.locationType);
  if (item.zipPostalCode) obj["zip_postal_code"] = item.zipPostalCode;
  return obj;
};

export const siteApi = folderApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSiteById: builder.query<ISite, string>({
      query(id) {
        return {
          url: `sites/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return deserialize(response);
      },
      providesTags: (result, error, id) => [
        { type: "Sites", id },
        { type: "Folders", id },
      ],
    }),
    addSite: builder.mutation<any, ISite>({
      query(body: ISite) {
        return {
          url: "sites/",
          method: "POST",
          body: serialize(body),
        };
      },
      invalidatesTags: [
        { type: "Sites", id: "LIST" },
        // { type: "Folders", id: "LIST" },
      ],
    }),
    updateSite: builder.mutation<any, ISite>({
      query(body: ISite) {
        return {
          url: `sites/${body.id}/`,
          method: "PUT",
          body: serialize(body),
        };
      },
      async onQueryStarted({ id, name, param }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          folderApi.util.updateQueryData("getFolders", param, (draft) => {
            draft.folders = draft.folders.map((item) => (item.id === id ? { ...item, name } : item));
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(apfolderApii.util.invalidateTags(['Post']))
           */
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Sites", id },
        { type: "Folders", id },
      ],
    }),
    deleteSite: builder.mutation<{ success: boolean; id: string }, string>({
      query(id) {
        return {
          url: `sites/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: "Sites", id: "LIST" },
        { type: "Folders", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetSiteByIdQuery,
  useLazyGetSiteByIdQuery,
  useAddSiteMutation,
  useUpdateSiteMutation,
  useDeleteSiteMutation,
} = siteApi;
