import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Box, Typography, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useGetTeamsQuery } from "redux/api/teamApi";
import { useGetUserProfileByIdQuery } from "redux/api/userProfileApi";
import useSWR from "swr";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import ProfileAccountTab from "./ProfileAccountTab";
import ProfileMainTab from "./ProfileMainTab";
import ProfilePermissionsTab from "./ProfilePermissionsTab";
import ProfileSubscriptionsTab from "./ProfileSubscriptionsTab";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import { HeaderTabs } from "components/DalmatianDesignComponents/HeaderTabs";
import LoadingDialog from "components/Dialog/LoadingDialog";
import useARMediaQuery from "hooks/useARMediaQuery";
import { URLAction, URLField, useRouter } from "hooks/useRouter";
import useUsers from "hooks/useUsers";
import { hasProfile, Profile, RoleIDList, User, UserPending, UserTypes } from "services/ContentServer/Identity";
import { UserInviteSWRKeys } from "services/ContentServer/Identity/services/UserInviteService";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { TeamData } from "views/Audit/TeamSelectionField";

export interface ProfileProps {
  user: User;
  userType: UserTypes;
}

const HEADERS = ["Profile", "Permissions", "Account", "Notifications"];

const ProfilePage = () => {
  const { users, roles } = useUsers();
  const [tab, setTab] = useState(0);
  const [currentTab, setCurrentTab] = useState(HEADERS[0].toLowerCase());
  const [processing, setProcessing] = useState(true);
  const [processingMsg, setProcessingMsg] = useState("Loading...");
  const { getStringQuery, query, updateURL } = useRouter();
  const [isPending, setIsPending] = useState<boolean | undefined>(undefined);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined);
  const [selectedTeams, setSelectedTeams] = useState<TeamData[]>([]);
  const { contentServer } = useContext(RequestContext);
  const { data: allTeams } = useGetTeamsQuery();
  const { data: invites } = useSWR([UserInviteSWRKeys.USER_INVITES], () => contentServer.userInviteService.list());
  const [readyToLoad, setReadyToLoad] = useState(false);

  const queryTab = typeof query.tab === "string" ? query.tab.toLowerCase() : "";

  useEffect(() => {
    const tabName = HEADERS[tab].toLowerCase();
    if (tabName === currentTab) {
      return;
    }
    setCurrentTab(tabName);
    updateURL([[URLField.TAB, tabName]], [URLAction.ADD]);
  }, [currentTab, tab, updateURL]);

  useEffect(() => {
    const tabName = queryTab;
    HEADERS.forEach((header, index) => {
      if (header.toLowerCase() === tabName) {
        setTab(index);
      }
    });
  }, [currentTab, queryTab, updateURL]);

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const isPendingQ = getStringQuery("isPending");
    if (isPendingQ) {
      setIsPending(isPendingQ === "true");
    } else {
      setIsPending(undefined);
    }

    const userIdQ = getStringQuery("userId");
    if (userIdQ) {
      setSelectedUserId(userIdQ);
    } else {
      setSelectedUserId(undefined);
    }
  }, [getStringQuery]);

  const getPendingUserRoles = useCallback(
    (roleIds: string[]) => {
      const roleList = [] as RoleIDList;
      roleIds.forEach((id) => {
        if (roles[id]) {
          roleList.push(roles[id].id);
        }
      });
      return roleList;
    },
    [roles]
  );

  const user = useMemo(() => {
    let selectedUser: User | UserPending | undefined;
    if (isPending === true) {
      const invite = invites?.find((inv) => inv.id === selectedUserId);
      if (invite) {
        selectedUser = {
          id: invite.id,
          name: invite.username,
          isActive: false,
          email: "",
          phoneNumber: "",
          createdAt: invite.createdAt,
          roles: invite.roles ? getPendingUserRoles(invite.roles) : {},
          sites: invite.sites,
          teams: invite.teams,
        } as UserPending;
      } else {
        selectedUser = undefined;
      }
    } else {
      selectedUser = users.find((u) => u.id === selectedUserId);
    }
    return selectedUser;
  }, [isPending, invites, selectedUserId, getPendingUserRoles, users]);

  const userProfile: Profile | false | undefined = useMemo(() => {
    if (user) {
      const userWithProfile = hasProfile(user);
      if (userWithProfile) {
        return userWithProfile.userProfile;
      } else {
        return false;
      }
    }
    return undefined;
  }, [user]);

  const { data: userProfileTeams } = useGetUserProfileByIdQuery(userProfile ? userProfile.id : "", {
    skip: !userProfile,
  });

  const initialTeams = useMemo(() => {
    const teamList: TeamData[] = [];
    if (userProfileTeams && userProfileTeams.team && !isPending) {
      userProfileTeams.team.forEach((id) => {
        const teamObj = allTeams?.find((obj) => obj.id === id);
        if (teamObj) {
          teamList.push({ data: id, displayVal: teamObj.name } as TeamData);
        }
      });
    } else if (isPending && user && user.teams) {
      user.teams.forEach((id) => {
        const teamObj = allTeams?.find((obj) => obj.id === id);
        if (teamObj) {
          teamList.push({ data: id, displayVal: teamObj.name } as TeamData);
        }
      });
    }
    return teamList;
  }, [userProfileTeams, allTeams, isPending, user]);

  useEffect(() => {
    if (user && userProfileTeams && !isPending) {
      setReadyToLoad(true);
      setProcessing(false);
    } else if (user && user.roles && user.roles.length !== 0 && isPending) {
      setReadyToLoad(true);
      setProcessing(false);
    }
  }, [user, isPending, userProfileTeams]);

  const userStatus = useMemo(() => {
    if (isPending) {
      return UserTypes.Pending;
    }
    if (user && user.isActive) {
      return UserTypes.Active;
    } else if (user && !user.isActive) {
      return UserTypes.Deactivated;
    }
    return undefined;
  }, [isPending, user]);

  const displayName = useMemo(() => {
    if (userProfile === undefined || user === undefined) return "";
    if (userProfile) {
      return userProfile.displayName;
    }
    return user.name;
  }, [user, userProfile]);

  const MainTab = useMemo(
    () => <ProfileMainTab key={0} user={user} userProfile={userProfile} userType={userStatus} />,
    [user, userStatus, userProfile]
  );

  const PermissionsTab = useMemo(
    () => (
      <ProfilePermissionsTab
        user={user}
        userProfile={userProfile}
        userType={userStatus}
        key={1}
        setProcessing={setProcessing}
        setProcessingMsg={setProcessingMsg}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        initialTeams={initialTeams}
      />
    ),
    [user, userStatus, userProfile, selectedTeams, setSelectedTeams, initialTeams]
  );
  const AccountTab = useMemo(
    () => (
      <ProfileAccountTab
        user={user}
        userProfile={userProfile}
        userType={userStatus}
        key={2}
        setProcessing={setProcessing}
        setProcessingMsg={setProcessingMsg}
      />
    ),
    [user, userStatus, userProfile]
  );

  const SubsTab = useMemo(
    () => (
      <ProfileSubscriptionsTab
        key={3}
        user={user}
        userProfile={userProfile}
        setProcessing={setProcessing}
        setProcessingMsg={setProcessingMsg}
        userType={userStatus}
      />
    ),
    [user, userStatus, userProfile]
  );

  const TabComponents = useMemo(() => {
    return [MainTab, PermissionsTab, AccountTab, SubsTab];
  }, [MainTab, PermissionsTab, AccountTab, SubsTab]);

  return (
    <>
      <LoadingDialog processing={processing} msg={processingMsg} />
      {readyToLoad && (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              height: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 3,
              pb: 3,
              px: matchesSM ? 6 : matchesMD ? 8 : 13,
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", maxWidth: "70%", gap: 0.5 }}>
              <Link to={`${SettingRoutesDict.get(SettingTab.USERS)?.path}`} style={{ textDecoration: "none" }}>
                <Typography variant="h1" sx={{ "&:hover": { textDecoration: "underline" }, paddingBottom: 0 }}>
                  Users
                </Typography>
              </Link>
              <ChevronRightOutlinedIcon />
              <EllipsisTooltip variant="h1" sx={{ paddingBottom: 0 }}>
                {displayName}
              </EllipsisTooltip>
            </Box>
            <Typography variant="body1">
              {isPending
                ? "Pending Invite"
                : userStatus === UserTypes.Active
                ? "Active Account"
                : "Deactivated Account"}
            </Typography>
          </Box>

          <Box
            sx={{
              paddingBottom: 0,
              height: "50px",
              width: "100%",
              px: matchesSM ? 6 : matchesMD ? 8 : 13,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ borderBottom: "solid 1px #e1e2e4", width: "100%", height: "100%" }}>
              <HeaderTabs tab={tab} setTab={setTab} headers={HEADERS} />
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            {TabComponents[tab]}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfilePage;
