export enum ScreenState {
  MINIMIZED,
  NORMAL,
  FULLSCREEN,
}

export enum VideoOrientation {
  LANDSCAPE,
  PORTRAIT,
}
