import {
  BadgeOutlined,
  MailOutlined,
  OpenInNewOutlined,
  PersonOutlineOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { CardContent, CardActions, Grid, Box, Typography } from "@mui/material";
import React, { useContext, useMemo, useCallback } from "react";
import { useGetUserQuery } from "redux/api/userApi";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRouter } from "hooks/useRouter";
import useUser from "hooks/useUser";
import useUsers from "hooks/useUsers";
import { User, RoleDict } from "services/ContentServer/Identity";
import { ChatContext } from "views/ChatView/ChatContext";
import { SettingRoutesDict, SettingTab } from "views/SettingsPage/SettingsBasePage";

const ContactsTab = () => {
  const { userSelectedId } = useContext(ChatContext);
  const { history } = useRouter();
  const { user } = useUser(userSelectedId);
  const { roles, users, featureAccess } = useUsers();
  const { data: fullUser, isLoading: userLoading } = useGetUserQuery(userSelectedId);
  const ICON_SIZE = 24;

  const fullUserRole = useMemo(() => users.find((u) => u.id === userSelectedId), [userSelectedId, users]);

  const modifyAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER].update;
  }, [featureAccess]);

  const userAccessView: boolean = useMemo(() => {
    return featureAccess[PERMISSION_NAME.USER].read;
  }, [featureAccess]);

  const roleAccessRead: boolean = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ROLES].read;
  }, [featureAccess]);

  const getRoleDict = useCallback((roles: RoleDict) => {
    const displayRoles = Object.entries(roles).map(([_, value]) => {
      return { id: value.id, name: value.name };
    });
    return {
      ids: Object.values(displayRoles).map((role) => role.id),
      names: Object.values(displayRoles)
        .map((role) => role.name)
        .join(", "),
    };
  }, []);

  const getUserRoles = useCallback((user: User, roles: RoleDict) => {
    const userRoles = {} as RoleDict;
    for (const role of user.roles) {
      if (roles && roles[role]) {
        userRoles[role] = roles[role];
      }
    }
    return userRoles;
  }, []);

  const userRoles = useMemo(() => {
    const getRoles = fullUserRole ? getUserRoles(fullUserRole, roles) : ({} as RoleDict);
    const displayRole = roleAccessRead
      ? getRoleDict(getRoles)
      : { ids: [], names: "You do not have permission to view." };
    return displayRole.names;
  }, [roleAccessRead, getRoleDict, fullUserRole, getUserRoles, roles]);

  return (
    <>
      <CardContent
        style={{
          height: "68%",
          overflowY: userAccessView ? "scroll" : "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          padding: "30px 20px",
        }}
      >
        <Grid container xs={12} spacing={3} justifyContent="center" alignItems="start">
          <Grid item xs={2}>
            <PersonOutlineOutlined style={{ fontSize: ICON_SIZE }} />
          </Grid>
          <Grid item xs={10} sx={{ "&.MuiGrid-item": { paddingLeft: "20px" } }}>
            <Box>
              <Typography variant="subtitle1">Name</Typography>
            </Box>
            <EllipsisTooltip variant="body1">
              {userLoading
                ? "Loading..."
                : fullUser?.firstName
                ? `${fullUser?.firstName} ${fullUser?.lastName}`
                : fullUser?.name
                ? fullUser.name
                : "No data available"}
            </EllipsisTooltip>
          </Grid>
          <Grid item xs={2}>
            <MailOutlined style={{ fontSize: ICON_SIZE }} />
          </Grid>
          <Grid item xs={10} sx={{ "&.MuiGrid-item": { paddingLeft: "20px" } }}>
            <Box>
              <Typography variant="subtitle1">Email</Typography>
            </Box>
            <EllipsisTooltip variant="body1">
              {userLoading ? "Loading..." : fullUser?.email ? fullUser.email : "No data available"}
            </EllipsisTooltip>
          </Grid>
          <Grid item xs={2}>
            <PhoneOutlined style={{ fontSize: ICON_SIZE }} />
          </Grid>
          <Grid item xs={10} sx={{ "&.MuiGrid-item": { paddingLeft: "20px" } }}>
            <Box>
              <Typography variant="subtitle1">Phone</Typography>
            </Box>
            <EllipsisTooltip variant="body1">
              {userLoading ? "Loading..." : fullUser?.phoneNumber ? fullUser.phoneNumber : "No data available"}
            </EllipsisTooltip>
          </Grid>
          <Grid item xs={2}>
            <BadgeOutlined style={{ fontSize: ICON_SIZE }} />
          </Grid>
          <Grid item xs={10} sx={{ "&.MuiGrid-item": { paddingLeft: "20px" } }}>
            <Box>
              <Typography variant="subtitle1">Role</Typography>
            </Box>
            <Box>
              <EllipsisTooltip variant="body1">{userLoading ? "Loading...." : userRoles}</EllipsisTooltip>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {userAccessView && (
        <CardActions style={{ justifyContent: "center" }}>
          <BaseButton
            variant="outlined"
            style={{ width: "90%", border: "1.75px solid" }}
            onClick={() => {
              const profileTab = SettingRoutesDict.get(SettingTab.PROFILE);
              if (profileTab) {
                history.push(`${profileTab.path}?userId=${user?.id}`);
              }
            }}
          >
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <OpenInNewOutlined style={{ margin: "0 10px" }} />
              {modifyAccess ? "Edit Profile" : "View Profile"}
            </Box>
          </BaseButton>
        </CardActions>
      )}
    </>
  );
};

export default ContactsTab;
