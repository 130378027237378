import React, { useContext, useEffect, useMemo, useState } from "react";
import { getSelectedSiteId } from "redux/features/appSlice";
import { useAppSelector } from "redux/hooks";
import useSWR from "swr";

import { getSegmentFieldCount } from "components/CameraSelector/Common/SegmentIndicator";
import { defaultCamera } from "hooks/useDeviceCamera";
import { PERMISSION_NAME } from "hooks/usePermission";
import useUsers from "hooks/useUsers";
import { Matrix4, ModelStateSearch } from "services/ContentServer/Audit";
import { AssetInfoSWRKeys } from "services/ContentServer/Audit/services/AssetInfoService";
import { SnapshotSWRKeys } from "services/ContentServer/Audit/services/SnapshotService";
import { AssetInfo } from "services/ContentServer/Audit/serviceTypes/AssetInfo";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

export interface SnapshotContextType {
  selectedSnapshot: Snapshot | undefined;
  setSelectedSnapshot: (
    selected: Snapshot | undefined | ((selected: Snapshot | undefined) => Snapshot | undefined)
  ) => void;
  appendingSnapshot: Snapshot | undefined;
  setAppendingSnapshot: (
    selected: Snapshot | undefined | ((selected: Snapshot | undefined) => Snapshot | undefined)
  ) => void;
  snapshots: Snapshot[] | undefined;
  assetSnapshots: Snapshot[] | undefined;
  setAssetSnapshots: (
    prev: Snapshot[] | undefined | ((prev: Snapshot[] | undefined) => Snapshot[] | undefined)
  ) => void;
  filteredSnapshotList: Snapshot[];
  setFilteredSnapshotList: (prev: Snapshot[] | ((prev: Snapshot[]) => Snapshot[])) => void;
  isSpatial: boolean;
  isTexturingInProgress: boolean;
  assetInfo: AssetInfo | undefined;
  snapshotsLoading: boolean;
  isArchive: boolean;
  path: Matrix4[];
  setPath: (path: Matrix4[] | ((prev: Matrix4[]) => Matrix4[])) => void;
  projectionMatrix: Matrix4;
  setProjectionMatrix: (path: Matrix4) => void;
  rotations: { [key: string]: number }[];
  setRotations: (rotations: { [key: string]: number }[]) => void;
  deviceRotation: number;
  setDeviceRotation: (rotation: number) => void;
}

const defaultContext: SnapshotContextType = {
  selectedSnapshot: undefined,
  setSelectedSnapshot: (
    selected: Snapshot | undefined | ((selected: Snapshot | undefined) => Snapshot | undefined)
  ) => {},
  appendingSnapshot: undefined,
  setAppendingSnapshot: (
    selected: Snapshot | undefined | ((selected: Snapshot | undefined) => Snapshot | undefined)
  ) => {},
  snapshots: undefined,
  assetSnapshots: undefined,
  setAssetSnapshots: (prev: Snapshot[] | undefined | ((prev: Snapshot[] | undefined) => Snapshot[] | undefined)) => {},
  filteredSnapshotList: [],
  setFilteredSnapshotList: (prev: Snapshot[] | ((prev: Snapshot[]) => Snapshot[])) => {},
  isSpatial: true,
  isTexturingInProgress: false,
  assetInfo: undefined,
  snapshotsLoading: true,
  isArchive: false,
  path: [],
  setPath: (path: Matrix4[] | ((prev: Matrix4[]) => Matrix4[])) => {},
  projectionMatrix: defaultCamera.projectionMatrix,
  setProjectionMatrix: (path: Matrix4 | ((prev: Matrix4) => Matrix4)) => {},
  rotations: [{ rotation: 0, frameNumber: 0 }],
  setRotations: (rotations: { [key: string]: number }[]) => {},
  deviceRotation: 0,
  setDeviceRotation: (rotation: number) => {},
};

export const ProvideSnapshotContext = (props: any) => {
  const { contentServer } = useContext(RequestContext);
  const [selected, setSelected] = useState<Snapshot | undefined>(undefined);
  const [appending, setAppending] = useState<Snapshot | undefined>(undefined);
  const [list, setList] = useState<Snapshot[] | undefined>(undefined);
  const [filteredList, setFilteredList] = useState<Snapshot[]>([]);
  const selectedSiteId = useAppSelector(getSelectedSiteId);
  const { featureAccess } = useUsers();
  const [path, setPath] = useState<Matrix4[]>([]);
  const [projectionMatrix, setProjectionMatrix] = useState<Matrix4>(defaultCamera.projectionMatrix);
  const [rotations, setRotations] = useState<{ [key: string]: number }[]>([{ rotation: 0, frameNumber: 0 }]);
  const [deviceRotation, setDeviceRotation] = useState<number>(0);

  useEffect(() => {
    setDeviceRotation(rotations[rotations.length - 1].rotation);
  }, [rotations]);

  useEffect(() => {
    const imgRotation = rotations.find((obj: { [key: string]: number }) => obj.frameNumber < path.length - 1);
    if (imgRotation) {
      setDeviceRotation(imgRotation.rotation);
    }
  }, [path.length, rotations]);

  const selectedAssetInfoId = useMemo(() => selected?.assetInfoId, [selected]);

  const { data: assetInfo } = useSWR(
    selectedAssetInfoId ? [AssetInfoSWRKeys.ASSET_INFO] : null,
    selectedAssetInfoId ? () => contentServer.assetInfoService.get(selectedAssetInfoId) : undefined
  );

  const isArchive = location.pathname === "/archive";

  const searchParams = useMemo(() => {
    const params: [string, string][] = [];
    params.push(["is_template", false.toString()]);
    params.push(["site", selectedSiteId]);
    if (isArchive) {
      params.push(["state", ModelStateSearch.Archived]);
    }

    return params;
  }, [selectedSiteId, isArchive]);

  const { data: snapshots, error: snapshotError } = useSWR(
    selectedSiteId ? [SnapshotSWRKeys.SIMPLE_SNAPSHOTS, "site", selectedSiteId, "main"] : null,
    () =>
      featureAccess[PERMISSION_NAME.SNAPSHOT].read
        ? contentServer.snapshotService.list(searchParams, [
            "id",
            "name",
            "preview",
            "asset_info_id",
            "created_at",
            "asset_id",
            "facility_id",
            "last_modified_at",
            "description",
            "ar_file",
          ])
        : []
  );

  const snapshotsLoading = useMemo(() => !snapshotError && snapshots === undefined, [snapshotError, snapshots]);

  const isSpatial = useMemo(() => Boolean(selected?.isSpatial), [selected]);
  const isTexturingInProgress = useMemo(
    () => getSegmentFieldCount(selected, "isTexturing").foundSegments > 0,
    [selected]
  );

  const contextValues: SnapshotContextType = useMemo(
    () => ({
      selectedSnapshot: selected,
      setSelectedSnapshot: setSelected,
      appendingSnapshot: appending,
      setAppendingSnapshot: setAppending,
      snapshots: snapshots,
      assetSnapshots: list,
      setAssetSnapshots: setList,
      filteredSnapshotList: filteredList,
      setFilteredSnapshotList: setFilteredList,
      isSpatial: isSpatial,
      isTexturingInProgress: isTexturingInProgress,
      assetInfo: assetInfo,
      snapshotsLoading: snapshotsLoading,
      isArchive: isArchive,
      path: path,
      setPath: setPath,
      projectionMatrix: projectionMatrix,
      setProjectionMatrix: setProjectionMatrix,
      rotations: rotations,
      setRotations: setRotations,
      deviceRotation: deviceRotation,
      setDeviceRotation: setDeviceRotation,
    }),
    [
      path,
      projectionMatrix,
      rotations,
      selected,
      list,
      filteredList,
      isSpatial,
      isTexturingInProgress,
      assetInfo,
      appending,
      snapshots,
      snapshotsLoading,
      isArchive,
      deviceRotation,
    ]
  );
  return <SnapshotContext.Provider value={contextValues}>{props.children}</SnapshotContext.Provider>;
};

export const SnapshotContext = React.createContext(defaultContext);
