import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import { Box, TableContainer, Table, TableBody, TableRow, useTheme, Button } from "@mui/material";
import { Skeleton, Typography } from "@mui/material";
import CopyPageIcon from "icons/CopyPageIcon";
import RoleUsersIcon from "icons/RoleUsersIcon";
import SharpEditIcon from "icons/SharpEditIcon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { useGetTeamsQuery, useCreateTeamMutation, useDeleteTeamMutation, ITeam } from "redux/api/teamApi";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import { ARRowCell } from "components/ARTable";
import ContextButton, { MenuOption } from "components/DalmatianDesignComponents/ContextButton";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import LoadingDialog from "components/Dialog/LoadingDialog";
import { EnhancedTableHead } from "components/table/EnhancedTable";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRefResizeObserver } from "hooks/useResizeObserver";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { StableSort, GetComparatorFcn, SortDirection } from "utils/SortRowsUtils";
import { matchSorter } from "utils/SortRowsUtils";
import { duplicateSuffixes } from "utils/StringUtils";

export enum PageState {
  Pending,
  Loaded,
  NoSearchMatch,
  Empty,
  Error,
}

interface TeamRowData {
  id: string;
  Team: string;
  Description: string;
  Users: number;
  ContextMenu?: JSX.Element;
}

const SKELETON_ROWS = 8;
const headCells: { id: keyof TeamRowData; label: string }[] = [
  { id: "Team", label: "Team" },
  { id: "Description", label: "Description" },
  { id: "Users", label: "Users" },
  { id: "ContextMenu", label: "" },
];

const cellWidths = new Map<string, string>([
  ["Team", "30%"],
  ["Description", "60%"],
  ["Users", "10%"],
  ["ContextMenu", "5"],
]);

export const MsgPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      style={{
        height: "90%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: " 16px 0px",
        gap: "40px",
      }}
    >
      <Typography variant="body1" style={{ paddingTop: "16px" }}>
        {children}
      </Typography>
    </Box>
  );
};

const TeamsPage = ({ setSelected }: { setSelected: (value: SettingTab) => void }) => {
  const snackbar = useSnackbar();
  const { featureAccess, users } = useUsers();
  const { history } = useRouter();
  const [pageState, setPageState] = useState<PageState>(PageState.Pending);
  const { data: teamsData, isFetching, isLoading } = useGetTeamsQuery();
  const [order, setOrder] = useState<SortDirection | undefined>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState("None");
  const [query, setQuery] = useState<string>("");
  const [tableSize, setTableSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { ref: tableRef } = useRefResizeObserver(setTableSize);
  const [processing, setProcessing] = useState(false);
  const [processingMsg, setProcessingMsg] = useState("");
  const [selTeamId, setSelTeamId] = useState<string>();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteTeam] = useDeleteTeamMutation();
  const [createTeam] = useCreateTeamMutation();
  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    requestAnimationFrame(() => setSelected(SettingTab.TEAMS));
  }, [setSelected]);

  const teamAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.TEAM];
  }, [featureAccess]);

  const navigateToEditPage = useCallback(
    (teamId: string) => {
      history.push(`${SettingRoutesDict.get(SettingTab.TEAM_EDIT)?.path}?teamId=${teamId}`);
    },
    [history]
  );

  const handleRequestSort = useCallback(
    (event: any, property: string) => {
      const isAsc = orderBy === property && order === SortDirection.ASC;
      setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const renderSkeletonRow = useCallback(() => {
    const cells = headCells.map((headCell, idx) => (
      <ARRowCell
        id={`cell-col-${idx}`}
        key={idx}
        index={idx}
        alwaysShow={headCell.id === "ContextMenu" || headCell.id === "Team"}
        align="left"
        sx={{
          width: cellWidths.get(headCell.id),
          border: 0,
        }}
      >
        <Skeleton key={idx} sx={{ borderRadius: "2px", width: "100%", height: "30px" }} />
      </ARRowCell>
    ));
    return cells;
  }, []);

  const applyFilters = useCallback(
    (rows: TeamRowData[]) => {
      if (query.length > 0) {
        const matchResults = matchSorter(rows, query, { keys: ["Team"] });
        setPageState(matchResults.length ? PageState.Loaded : PageState.NoSearchMatch);
        return matchResults;
      } else {
        if (!isFetching && !isLoading) setPageState(PageState.Loaded);
        return rows;
      }
    },
    [query, isFetching, isLoading]
  );

  const duplicateTeam = useCallback(
    (team: ITeam) => {
      if (team) {
        setProcessingMsg("Duplicating team...");
        setProcessing(true);

        let newName = team.name + " - Copy";
        const suffixes = duplicateSuffixes(teamsData ?? [], {
          id: team.id,
          name: team.name + " - Copy",
        } as ITeam);
        if (suffixes.length > 0 && suffixes[0] !== 0) {
          newName += ` (${suffixes[0]})`;
        }

        createTeam({ ...team, name: newName, addUserProfile: [] })
          .unwrap()
          .then(() => {
            setProcessing(false);
            setProcessingMsg("");

            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: "Team successfully duplicated.",
            });
          })
          .catch(() => {
            setProcessing(false);
            setProcessingMsg("");

            snackbar.dispatch({
              type: SnackbarActionType.OPEN,
              message: "Error duplicating team.",
            });
          });
      }
    },
    [createTeam, snackbar, teamsData]
  );

  const handleDeleteTeam = useCallback(() => {
    if (selTeamId) {
      setProcessingMsg("Deleting team...");
      setProcessing(true);
      deleteTeam(selTeamId)
        .unwrap()
        .then(() => {
          setProcessing(false);
          setProcessingMsg("");
          setSelTeamId(undefined);

          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Team successfully deleted.",
          });
        })
        .catch(() => {
          setProcessing(false);
          setProcessingMsg("");
          setSelTeamId(undefined);

          snackbar.dispatch({
            type: SnackbarActionType.OPEN,
            message: "Error deleting team.",
          });
        });
    }
  }, [selTeamId, deleteTeam, snackbar]);

  const contextMenuOptions = useCallback(
    (team: ITeam) => {
      const options: MenuOption[] = [];

      if (teamAccess.update) {
        options.push({
          name: "Edit Team",
          onClick: () => {
            navigateToEditPage(team.id);
          },
          icon: <SharpEditIcon />,
        });

        if (teamAccess.add) {
          options.push({
            name: "Duplicate Team",
            onClick: async () => {
              duplicateTeam(team);
            },
            icon: <CopyPageIcon />,
          });
        }
      }

      if (teamAccess.delete) {
        options.push({
          name: "Delete Team",
          onClick: () => {
            setSelTeamId(team.id);
            setDeleteConfirmOpen(true);
          },
          icon: <DeleteOutlineSharpIcon />,
        });
      }

      return options;
    },
    [teamAccess, navigateToEditPage, duplicateTeam]
  );

  const teamRows = useMemo(() => {
    if (teamsData) {
      if (teamsData.length) {
        setPageState(PageState.Loaded);
        return teamsData.map((team) => {
          const options = contextMenuOptions(team);
          return {
            id: team.id,
            Team: team.name,
            Description: team.description ?? "",
            Users: team.userprofile.reduce(
              (acc, curr) => acc + (users.find((user) => user.userProfile.id === curr) ? 1 : 0),
              0
            ),
            ContextMenu: options.length ? <ContextButton items={options} /> : <></>,
          };
        });
      }
      setPageState(PageState.Empty);
      return [];
    }
    return [];
  }, [contextMenuOptions, teamsData, users]);

  const tableData = useMemo(() => {
    return applyFilters(teamRows);
  }, [applyFilters, teamRows]);

  const tableRows = useMemo(() => {
    return StableSort(tableData, GetComparatorFcn(order, orderBy, false));
  }, [order, orderBy, tableData]);

  const tableRow = useCallback(
    (rowIdx, row) => {
      return (
        <>
          {headCells.map((headCell, idx) => {
            const widthPercent = cellWidths.get(headCell.id) || 0;
            return (
              <ARRowCell
                key={idx}
                index={headCell.id === "Description" ? 2 : idx}
                alwaysShow={headCell.id === "ContextMenu" || headCell.id === "Users"}
                align="left"
                padding="normal"
                sx={{
                  width: widthPercent,
                  maxWidth: (tableSize.width - (matchesSM ? 6 : matchesMD ? 8 : 13) * 16) * 0.3,
                  border: 0,
                  paddingLeft: idx === 0 ? "16px" : "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={(e) => {
                  if (headCell.id !== "ContextMenu" && teamAccess.update) {
                    navigateToEditPage(row.id);
                  }
                }}
              >
                {headCell.id === "Users" ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <RoleUsersIcon />
                      <Typography variant="body1">{row[headCell.id]}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ maxHeight: "24px", display: "flex", alignItems: "center" }}>
                    <EllipsisTooltip variant="body1">{row[headCell.id]}</EllipsisTooltip>
                  </Box>
                )}
              </ARRowCell>
            );
          })}
        </>
      );
    },
    [teamAccess, navigateToEditPage, tableSize.width, matchesSM, matchesMD]
  );

  return (
    <>
      <LoadingDialog processing={isFetching || isLoading || processing} msg={processingMsg} />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "flex-start" }}>
        <Box sx={{ py: 3, height: "auto", width: "100%", px: matchesSM ? 6 : matchesMD ? 8 : 13 }}>
          <Typography variant="h1" sx={{ py: 0 }}>
            Teams
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            py: 3,
            gap: "24px",
            width: "100%",
            height: "auto",
          }}
        >
          <SearchBar placeholderText={"Search teams..."} query={query} setQuery={setQuery} />
          {teamAccess.add && (
            <Button
              variant={"outlined"}
              startIcon={<AddIcon />}
              onClick={() => {
                navigateToEditPage("");
              }}
              style={{ maxHeight: "36px", minWidth: "130px" }}
            >
              New Team
            </Button>
          )}
        </Box>
        <Box sx={{ height: "100%", width: "100%" }}>
          <TableContainer
            ref={tableRef}
            sx={{
              height: "100%",
              width: "100%",
              "&::-webkit-scrollbar": {
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#a2a4a6",
                width: "8px",
                borderRadius: "100px",
              },
              table: {
                px: matchesSM ? 6 : matchesMD ? 8 : 13,
              },
            }}
          >
            {pageState === PageState.Error ? (
              <MsgPage>Error fetching teams.</MsgPage>
            ) : pageState === PageState.NoSearchMatch ? (
              <MsgPage>No teams matched this search.</MsgPage>
            ) : pageState === PageState.Pending ? (
              <Table
                size="small"
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  height: tableSize.height,
                  px: matchesSM ? 6 : matchesMD ? 8 : 13,
                }}
                stickyHeader
              >
                <TableBody style={{ width: "100%", paddingTop: "40px" }}>
                  {Array(SKELETON_ROWS)
                    .fill(1)
                    .map((ele, idx) => {
                      return <TableRow key={idx}>{renderSkeletonRow()}</TableRow>;
                    })}
                </TableBody>
              </Table>
            ) : pageState === PageState.Empty ? (
              <MsgPage>No teams loaded.</MsgPage>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <TableVirtuoso
                  data={tableRows}
                  style={{ height: "100%" }}
                  itemContent={(index, rowData) => tableRow(index, rowData)}
                  fixedHeaderContent={() => (
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      headCells={headCells}
                      cellWidths={cellWidths}
                      width={tableSize.width}
                    />
                  )}
                />
              </div>
            )}
          </TableContainer>
        </Box>
      </Box>
      <ConfirmationDialog
        useOpen={{ state: deleteConfirmOpen, setState: setDeleteConfirmOpen }}
        onConfirm={() => {
          handleDeleteTeam();
        }}
        dialogText={`Are you sure you would like to delete this team? Users will no longer have access to files associated with this team.`}
        title={"Delete Team"}
        confirmText={"Delete"}
      />
    </>
  );
};

export default TeamsPage;
