import React, { useMemo, useState } from "react";

import { IChatMessage } from "services/Chat/serializers";
import { User } from "services/ContentServer/Identity";

export type ChatBox = { [id: string]: IChatMessage[] };

export interface ChatContextType {
  chatOpen: boolean;
  setChatOpen: (chatOpen: boolean | ((chatOpen: boolean) => boolean)) => void;
  userSelectedId: string;
  setUserSelectedId: (userSelectedId: string | ((userSelectedId: string) => string)) => void;
  userSelectedName: string;
  setUserSelectedName: (userSelectedName: string | ((userSelectedName: string) => string)) => void;
  chatBox: ChatBox;
  setChatBox: (chatBox: ChatBox | ((chatBox: ChatBox) => ChatBox)) => void;
  messageNotification: string;
  setMessageNotification: (messageNotification: string | ((messageNotification: string) => string)) => void;
  missedMessages: ChatBox;
  setMissedMessages: (chatBox: ChatBox | ((chatBox: ChatBox) => ChatBox)) => void;
  unreadMessages: { [id: string]: number };
  setUnreadMessages: (
    messageDict: { [id: string]: number } | ((messageDict: { [id: string]: number }) => { [id: string]: number })
  ) => void;
  chatUserList: User[];
  setChatUserList: (chatUserList: User[]) => void;
  tab: number;
  setTab: (value: number | ((prev: number) => number)) => void;
  profileOpen: boolean;
  setProfileOpen: (profileOpen: boolean | ((profileOpen: boolean) => boolean)) => void;
  prevLocation: string;
  setPrevLocation: (prevLocation: string | ((prevLocation: string) => string)) => void;
  numNewMsgs: number;
  setNumNewMsgs: (value: number | ((prev: number) => number)) => void;
  currentMessages: IChatMessage[];
  setCurrentMessages: (value: IChatMessage[] | ((prev: IChatMessage[]) => IChatMessage[])) => void;
  isInitialHistoryFetched: boolean;
  setIsInitialHistoryFetched: (value: boolean | ((value: boolean) => boolean)) => void;
  lastMsgs: string[];
  setLastMsgs: (lastMsgs: string[] | ((lastMsgs: string[]) => string[])) => void;
}

const defaultContext: ChatContextType = {
  chatOpen: false,
  setChatOpen: (_: boolean | ((chatOpen: boolean) => boolean)) => {},
  userSelectedId: "",
  setUserSelectedId: (_: string | ((userSelectedId: string) => string)) => {},
  userSelectedName: "",
  setUserSelectedName: (_: string | ((userSelectedName: string) => string)) => {},
  chatBox: {} as ChatBox,
  setChatBox: (_: ChatBox | ((chatBox: ChatBox) => ChatBox)) => {},
  messageNotification: "",
  setMessageNotification: (_: string | ((messageNotification: string) => string)) => {},
  missedMessages: {} as ChatBox,
  setMissedMessages: (_: ChatBox | ((chatBox: ChatBox) => ChatBox)) => {},
  unreadMessages: {} as { [id: string]: number },
  setUnreadMessages: (
    _: { [id: string]: number } | ((messageDict: { [id: string]: number }) => { [id: string]: number })
  ) => {},
  chatUserList: [],
  setChatUserList: (chatUserList: User[]) => {},
  tab: 0,
  setTab: (value: number | ((prev: number) => number)) => {},
  profileOpen: false,
  setProfileOpen: (profileOpen: boolean | ((profileOpen: boolean) => boolean)) => {},
  prevLocation: "",
  setPrevLocation: (prevLocation: string | ((prevLocation: string) => string)) => {},
  numNewMsgs: 0,
  setNumNewMsgs: (value: number | ((prev: number) => number)) => {},
  currentMessages: [],
  setCurrentMessages: (value: IChatMessage[] | ((prev: IChatMessage[]) => IChatMessage[])) => {},
  isInitialHistoryFetched: false,
  setIsInitialHistoryFetched: (
    isInitialHistoryFetched: boolean | ((isInitialHistoryFetched: boolean) => boolean)
  ) => {},
  lastMsgs: [],
  setLastMsgs: (lastMsgs: string[] | ((lastMsgs: string[]) => string[])) => {},
};

export const ProvideChatContext = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [userSelectedId, setUserSelectedId] = useState("");
  const [userSelectedName, setUserSelectedName] = useState("");
  const [chatBox, setChatBox] = useState<ChatBox>({});
  const [messageNotification, setMessageNotification] = useState("");
  const [missedMessages, setMissedMessages] = useState<ChatBox>({});
  const [unreadMessages, setUnreadMessages] = useState<{ [id: string]: number }>({});
  const [chatUserList, setChatUserList] = useState<User[]>([]);
  const [tab, setTab] = useState<number>(0);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [prevLocation, setPrevLocation] = useState<string>("chatList");
  const [numNewMsgs, setNumNewMsgs] = useState<number>(0);
  const [currentMessages, setCurrentMessages] = useState<IChatMessage[]>([]);
  const [isInitialHistoryFetched, setIsInitialHistoryFetched] = useState(false);
  const [lastMsgs, setLastMsgs] = useState<string[]>([]);

  const contextValues: ChatContextType = useMemo(
    () => ({
      chatOpen: open,
      setChatOpen: setOpen,
      userSelectedId: userSelectedId,
      setUserSelectedId: setUserSelectedId,
      userSelectedName: userSelectedName,
      setUserSelectedName: setUserSelectedName,
      chatBox: chatBox,
      setChatBox: setChatBox,
      messageNotification: messageNotification,
      setMessageNotification: setMessageNotification,
      missedMessages: missedMessages,
      setMissedMessages: setMissedMessages,
      unreadMessages: unreadMessages,
      setUnreadMessages: setUnreadMessages,
      chatUserList: chatUserList,
      setChatUserList: setChatUserList,
      tab: tab,
      setTab: setTab,
      profileOpen: profileOpen,
      setProfileOpen: setProfileOpen,
      prevLocation: prevLocation,
      setPrevLocation: setPrevLocation,
      numNewMsgs: numNewMsgs,
      setNumNewMsgs: setNumNewMsgs,
      currentMessages: currentMessages,
      setCurrentMessages: setCurrentMessages,
      isInitialHistoryFetched: isInitialHistoryFetched,
      setIsInitialHistoryFetched: setIsInitialHistoryFetched,
      lastMsgs: lastMsgs,
      setLastMsgs: setLastMsgs,
    }),
    [
      open,
      userSelectedId,
      userSelectedName,
      chatBox,
      messageNotification,
      missedMessages,
      unreadMessages,
      chatUserList,
      tab,
      profileOpen,
      prevLocation,
      numNewMsgs,
      currentMessages,
      isInitialHistoryFetched,
      lastMsgs,
    ]
  );
  return <ChatContext.Provider value={contextValues}>{props.children}</ChatContext.Provider>;
};

export const ChatContext = React.createContext(defaultContext);
