import { Device } from "../ContentServer/Identity";
import { IVideoCallHub } from "../MessageHub";
import { WebRTCMessageType, serializeWebRTCToServerMessage } from "./serializers";
import { serializeServerToWebRTCMessage, IServerMessage, IWebRTCMessage } from "./serializers";
import { IWebRTC, IWebRTCSession, CallSubscriptionFn } from "./types";
import { WebRTCSession } from "./WebRTCSession";
import SubscriptionManager from "utils/SubscriptionManager";

export class WebRTC implements IWebRTC {
  private videoCallHub: IVideoCallHub;
  private userID: string;
  private device: Device;
  private iceServers: any;
  private unsubscribe: (() => void) | null = null;
  private subscriptionManager: SubscriptionManager<CallSubscriptionFn>;

  constructor(videoCallHub: IVideoCallHub, userID: string, device: Device, iceServers: any) {
    this.videoCallHub = videoCallHub;
    this.userID = userID;
    this.device = device;
    this.iceServers = iceServers;

    this.subscriptionManager = new SubscriptionManager();
  }

  subscribeToCalls(callback: CallSubscriptionFn): () => void {
    return this.subscriptionManager.subscribe(callback);
  }

  async call(peerID: string): Promise<void> {
    if (!peerID) return Promise.reject("A user ID is required to make a call.");

    this.onCall(
      new WebRTCSession({
        videoCallHub: this.videoCallHub,
        userID: this.userID,
        userDevice: this.device,
        peerID: peerID,
        isReceiving: false,
        iceServers: this.iceServers,
      })
    );
  }

  start(): void {
    const wrapper = (value: Record<string | number | symbol, unknown>) => {
      const message = serializeServerToWebRTCMessage(value as IServerMessage);
      if (message.type === WebRTCMessageType.CALL) {
        this.onCall(
          new WebRTCSession({
            videoCallHub: this.videoCallHub,
            userID: this.userID,
            userDevice: this.device,
            peerID: message.offerer,
            peerDevice: message.device,
            isReceiving: true,
            iceServers: this.iceServers,
          })
        );
      }
    };

    if (this.unsubscribe === null) {
      this.unsubscribe = this.videoCallHub.subscribeToVideoCallMessage(wrapper);
    }
  }

  stop(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  private onCall(session: IWebRTCSession): void {
    this.subscriptionManager.subscribers.forEach((callback) => callback(session));
  }

  private send(message: IWebRTCMessage): Promise<void> {
    return this.videoCallHub.send(serializeWebRTCToServerMessage(message));
  }
}
