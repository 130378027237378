import { styled } from "@mui/material";
import MenuItemMui from "@mui/material/MenuItem";

const MenuItem = styled(MenuItemMui)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.light,
  },
  "&.MuiMenuItem-root": {
    padding: "10px 18px",
    color: theme.palette.primary.main,
  },
}));

export default MenuItem;
