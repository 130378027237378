import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useMemo } from "react";

import { useSplatProgress } from "hooks/useSplatProgress";
import { useTaskProgress } from "hooks/useTaskProgress";
import { FileSegment } from "services/ContentServer/Audit/serviceTypes/FileSegment";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { ProgressType } from "services/ContentServer/Audit/types";
import { getFormattedDate } from "utils/DateUtils";

export interface SegmentCount {
  foundSegments: number;
  allSegments: number;
}

const ESTIMATION_TOLERANCE_HRS = 12; // 12 hours past the original estimate

export const getSegmentFieldCount = (snapshot: Snapshot | undefined, fieldName: keyof FileSegment): SegmentCount => {
  let foundSegments = 0;
  let allSegments = 0;
  if (snapshot) {
    snapshot?.segments?.forEach((segment: FileSegment) => {
      if (segment[fieldName]) {
        foundSegments++;
      }
      allSegments++;
    });
  }
  return { foundSegments, allSegments };
};

const SegmentIndicator = ({
  width,
  snapshot,
  field,
  progressText,
}: {
  width: number;
  snapshot?: Snapshot;
  field: keyof FileSegment;
  progressText: string;
}) => {
  const progress = useTaskProgress(snapshot?.id);
  const splatProgress = useSplatProgress(snapshot?.id);

  const showTaskIndicator = useMemo(() => {
    return progress && progress.state != ProgressType.Complete;
  }, [progress]);

  const showSplatIndicator = useMemo(() => {
    return splatProgress && splatProgress.state != "COMPLETE";
  }, [splatProgress]);

  const progressMessage = useMemo(() => {
    let message = "";
    if (showSplatIndicator) {
      message = splatProgress?.message || "";
      if (splatProgress?.state == "ERROR") {
        message = "ERROR : " + message;
      }
      return message;
    }

    if (progress?.state === ProgressType.PreStart) {
      message = "Scan is currently in queue to be processed.";
    } else if (progress?.state == ProgressType.Error) {
      message = "Error : " + progress?.message;
    } else if (progress?.state == ProgressType.Processing) {
      if (progress?.estimatedTime) {
        const estimatedDateTime = new Date(progress.estimatedTime);
        const currentTime = new Date(Date.now());
        const pastDueTime = new Date();
        pastDueTime.setHours(estimatedDateTime.getHours() + ESTIMATION_TOLERANCE_HRS);
        if (pastDueTime < currentTime) {
          message = "Mesh Generation is taking longer than expected, please contact a system admin if this persists.";
        } else if (estimatedDateTime < currentTime) {
          message = "Finishing up... ";
        } else {
          message = "Estimated Completion at " + getFormattedDate(progress.estimatedTime);
        }
      } else {
        message = "Job picked up, calculating Estimated Completion...";
      }
    }

    return message;
  }, [progress, splatProgress, showSplatIndicator]);

  const progressPercent = useMemo(() => {
    let percent = 0;
    if (showTaskIndicator) {
      percent = progress?.percentComplete || 0;
    } else {
      percent = splatProgress?.progress || 0;
    }
    return percent;
  }, [showTaskIndicator, splatProgress, progress]);

  const createIndicator = useMemo(() => {
    return (
      <Box
        sx={{
          width: width,
          top: 0,
          left: 0,
          position: "absolute",
          display: "inline-flex",
          zIndex: 0,
        }}
      >
        <LinearProgress
          color={progress?.state == ProgressType.Error || splatProgress?.state == "ERROR" ? "secondary" : "primary"}
          variant="determinate"
          sx={{
            width: width,
            height: 15,
            zIndex: 0,
          }}
          value={progressPercent}
        />
        <Box
          sx={{
            top: -1,
            bottom: 0,
            left: 10,
            position: "absolute",
            display: "flex",
            paddingRight: 5,
          }}
        >
          <Typography style={{ fontWeight: "bold", color: "white" }} variant="body2">
            {progressMessage}
          </Typography>
        </Box>
      </Box>
    );
  }, [progress, splatProgress, width, progressMessage, progressPercent]);

  return showTaskIndicator || showSplatIndicator ? createIndicator : <></>;
};

export default SegmentIndicator;
