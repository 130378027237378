import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof AlignSnapshots | undefined } = {
  source_snapshot_id: "sourceSnapshotId",
  target_snapshot_id: "targetSnapshotId",
};

export class AlignSnapshots extends ISerialization {
  public sourceSnapshotId?: string | null;
  public targetSnapshotId?: string | null;

  constructor(AlignSnapshots?: AlignSnapshots) {
    super();
    Object.assign(this, AlignSnapshots);
  }

  deserialize(json: any): void {
    deserializerHelper<AlignSnapshots>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<AlignSnapshots>(this, serverToView);
    return request;
  }
}
