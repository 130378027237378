import { KeyboardBackspace, Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Button, IconButton, InputAdornment } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";

import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { useDebounce } from "hooks/useUtils";

const PaginationSearchBar = ({
  placeholderText,
  inSearchMode,
  query,
  updateQuery,
  handleSearch,
}: {
  placeholderText: string;
  inSearchMode: boolean;
  query: string;
  updateQuery: (value: string, exitSearch?: boolean) => void;
  handleSearch: () => void;
}) => {
  const theme = useTheme();
  const [activeInput, setActiveInput] = useState(false);
  const debounceOnChange = useDebounce<string>(query, 1500);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceOnChange]);

  return (
    <FixedHeightTextField
      id="search-bar"
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minWidth: "50px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "140%",
        color: "#64676A",
        ".MuiOutlinedInput-root": {
          height: "40px",
          padding: query ? 0 : "",
        },
      }}
      value={query}
      onFocus={() => {
        setActiveInput(true);
      }}
      onBlur={() => {
        setActiveInput(false);
      }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          handleSearch();
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {query || inSearchMode ? (
              <IconButton onClick={() => updateQuery("", true)} size="large" sx={{ width: "36px", height: "36px" }}>
                <KeyboardBackspace
                  sx={{
                    width: "20px",
                    height: "20px",
                    margin: "0 5px",
                    color: theme.palette.dalmatianDesign.dark,
                  }}
                />
              </IconButton>
            ) : (
              <SearchIcon style={{ color: theme.palette.dalmatianDesign.dark }} />
            )}
          </InputAdornment>
        ),
        endAdornment: query && (
          <InputAdornment position="end">
            <IconButton onClick={() => updateQuery("")} size="large" sx={{ width: "36px", height: "36px" }}>
              <CloseIcon style={{ color: theme.palette.dalmatianDesign.dark }} />
            </IconButton>
            <Button
              onClick={() => {
                handleSearch();
              }}
              sx={{
                minWidth: "40px",
                width: "40x",
                height: "36px",
                padding: 0,
                borderRadius: 0,
                background: activeInput ? "black" : "#EBEBEB",
              }}
            >
              <Search sx={{ width: "20px", height: "20px", color: activeInput ? "white" : "black" }} />
            </Button>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: false,
      }}
      placeholder={placeholderText}
      onChange={(event: ChangeEvent<any>) => {
        updateQuery(event.target.value);
      }}
    />
  );
};

export default PaginationSearchBar;
