import { Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";

import useARMediaQuery from "hooks/useARMediaQuery";
import useWindowSize from "hooks/useWindowSize";

const ARCopyright = ({
  name,
  linkTo,
  overlay,
  fullscreen,
  style,
}: {
  name: string;
  linkTo: string;
  overlay?: boolean;
  fullscreen?: boolean;
  style?: React.CSSProperties;
}) => {
  const theme = useTheme();
  const matchesSM = useARMediaQuery("sm");
  const isMobile = useARMediaQuery("mobile");
  const isTablet = useARMediaQuery("tablet");
  const windowSize = useWindowSize();
  const isHorizontal = useMemo(() => {
    return windowSize.width > windowSize.height;
  }, [windowSize]);

  return (
    <Typography
      variant="caption"
      align="center"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        position: "fixed",
        display: "block",
        px: 1,
        py: 0.5,
        left: fullscreen ? "16px" : isMobile || (isTablet && !isHorizontal) || matchesSM ? "16px" : "80px",
        bottom: overlay ? "60px" : "16px",
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: "100px",
        boxShadow: "0px 4px 12px 0px rgba(53, 55, 57, 0.10)",
        ...style,
      }}
    >
      {" © "}
      <Link color="inherit" href={linkTo} target="_blank" underline="hover" rel="noopener">
        {new Date().getFullYear() + " " + name}
      </Link>
    </Typography>
  );
};

export default ARCopyright;
