import { IInspectionUpdateHub, UpdateCallback, UpdateMessage } from "services/MessageHub/types";
import SubscriptionManager from "utils/SubscriptionManager";

export class UpdateHandler {
  private updateHub: IInspectionUpdateHub;
  private messageType: string;
  private unsubscribe: (() => void) | null = null;
  private subscriptionManager: SubscriptionManager<UpdateCallback>;

  constructor(updateHub: IInspectionUpdateHub, messageType: string) {
    this.updateHub = updateHub;
    this.subscriptionManager = new SubscriptionManager();
    this.messageType = messageType;
  }

  start(): void {
    const wrapper = (value: UpdateMessage) => {
      this.onUpdate(value);
    };

    if (this.unsubscribe === null) {
      this.unsubscribe = this.updateHub.subscribeToUpdates(wrapper, this.messageType);
    }
  }

  subscribeToUpdates(callback: UpdateCallback): () => void {
    return this.subscriptionManager.subscribe(callback);
  }

  stop(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  private onUpdate(update: UpdateMessage): void {
    this.subscriptionManager.subscribers.forEach((callback) => callback(update));
  }
}
