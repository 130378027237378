import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLazyGetUserSubscriptionDataQuery, useLazyUpdateUserSubscriptionQuery } from "redux/api/gql/usersApi";

import { SettingsSwitch } from "components/Switch/StyledSwitch";
import useARMediaQuery from "hooks/useARMediaQuery";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { Profile, User, UserPending, UserTypes } from "services/ContentServer/Identity";

const KEY_TO_READABLE_FIELD: Record<string, string> = {
  MODEL_GENERATED: "Model generation complete",
  SNAPSHOT_CREATED: "Snapshot creation",
};

const ProfileSubscriptionsTab = ({
  user,
  userProfile,
  userType,
  setProcessing,
  setProcessingMsg,
}: {
  user: User | UserPending | undefined;
  userProfile: Profile | false | undefined;
  userType: UserTypes | undefined;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessingMsg: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { currentUser } = useUsers();
  const [getUserSubscriptionData] = useLazyGetUserSubscriptionDataQuery();
  const [updateUserSubscription] = useLazyUpdateUserSubscriptionQuery();

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  const [subscriptions, setSubscriptions] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (userProfile) {
      getUserSubscriptionData(userProfile.id)
        .unwrap()
        .then((res) => {
          setSubscriptions(res.emailSubscription);
        });
    }
  }, [getUserSubscriptionData, userProfile]);

  const owner = useMemo(() => {
    if (user === undefined) {
      return false;
    }
    return user.id === currentUser?.id;
  }, [currentUser?.id, user]);

  const subscriptionRows = useMemo(() => {
    const rows = Object.keys(subscriptions).map((key) => {
      return (
        <Grid item xs={3} key={key}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              padding: "0px",
              gap: "32px",
            }}
          >
            <Typography variant="subtitle1">
              {key in KEY_TO_READABLE_FIELD ? KEY_TO_READABLE_FIELD[key] : key}
            </Typography>
            <SettingsSwitch
              checked={subscriptions[key]}
              onChange={() => {
                setSubscriptions((prev) => {
                  const newSubs = {
                    ...prev,
                  };
                  newSubs[key] = !prev[key];
                  updateUserSubscription({ subType: key, enabled: newSubs[key] });
                  return newSubs;
                });
              }}
              disabled={!owner}
            />
          </Box>
        </Grid>
      );
    });
    return (
      <Grid container spacing={1} style={{ overflow: "hidden" }}>
        {rows}
      </Grid>
    );
  }, [owner, subscriptions, updateUserSubscription]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: 3,
            gap: 3,
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            overflowY: "scroll",
            maxHeight: useWindowSize().height - (userType === UserTypes.Active && owner ? 240 : 190),
          }}
        >
          {subscriptionRows}
        </Box>
      </Box>
    </>
  );
};

export default ProfileSubscriptionsTab;
