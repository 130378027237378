import { Box, Divider } from "@mui/material";
import React from "react";

export const SettingsBottomBar = ({
  show,
  hasRequiredFields = true,
  children,
}: {
  show: boolean;
  hasRequiredFields?: boolean;
  children?: JSX.Element;
}) => {
  return (
    <Box
      style={{
        position: "relative",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "auto",
        display: show && hasRequiredFields ? "flex" : "none",
        backgroundColor: "white",
        zIndex: 4,
      }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <Divider />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%",
            height: "100%",
            py: 2,
            px: 3,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
