import { Email, HelpOutline } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Box, ListItem, Typography, Divider, ListItemButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Config from "Config";
import React, { useState } from "react";

import { UseState } from "utils/TypeUtils/ReactTypeUtils";

export function ContactHelpDrawerItem() {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <ListItem
        key={`list-user-profile`}
        disablePadding
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          backgroundColor: theme.palette.primary.light,
        }}
        onClick={() => setDialogOpen((prev) => !prev)}
      >
        <ListItemButton
          sx={{
            minHeight: 64,
            justifyContent: "center",
            px: 2.5,
            padding: "0px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HelpOutline style={{ color: theme.palette.primary.main, width: "27px", height: "27px" }} />
          <Typography
            style={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "11px",
              lineHeight: "15px",
              paddingTop: "4px",
              color: theme.palette.primary.main,
            }}
          >
            {"Help"}
          </Typography>
        </ListItemButton>
      </ListItem>
      <ContactHelpDialog useOpen={{ state: dialogOpen, setState: setDialogOpen }} />
    </>
  );
}

export default function ContactHelpDialog({ useOpen }: { useOpen: UseState<boolean> }) {
  const { state: dialogOpen, setState: setDialogOpen } = useOpen;

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">{"Help"}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            <Email />
            <a id="contact-help" style={{ paddingLeft: 10 }} href="mailto: support@interaptix.com">
              support@interaptix.com
            </a>
          </Typography>
          <Divider />
          <Typography id="version" variant="h6">
            Version: {Config.VERSION}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
