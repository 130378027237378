import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const FeatureSWRKeys = {
  FEATURES: "features",
};

export const serverToView: { [key: string]: keyof AllFeatures | undefined } = {
  id: "id",
  feature_name: "featureName",
  tenant_block_list: "tenantBlockList",
  tenant_allow_list: "tenantAllowList",
  percent_enabled: "percentEnabled",
  feature_notes: "featureNotes",
};

export class AllFeatures extends ISerialization {
  public id?: string | null;
  public featureName?: string | null;
  public tenantBlockList?: string | null;
  public tenantAllowList?: string | null;
  public percentEnabled?: number | null;
  public featureNotes?: string | null;

  constructor(features?: AllFeatures) {
    super();
    if (features) {
      Object.assign(this, features);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<AllFeatures>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<AllFeatures>(this, serverToView);
    return request;
  }
}
