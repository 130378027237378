import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";

import graphqlFetchBase from "./graphqlFetchBase";

export const gqlApi = createApi({
  baseQuery: graphqlFetchBase,
  endpoints: (builder) => ({
    getEmailInviteValidation: builder.query<{ valid: boolean }, string>({
      query: (email: string) => ({
        document: gql`
          query {
            validateUnusedEmail(email:"${email}")
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return response.validateUnusedEmail;
      },
    }),
  }),
});

export const { useLazyGetEmailInviteValidationQuery } = gqlApi;
