import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";

import { PERMISSION_NAME } from "hooks/usePermission";
import { useRouter } from "hooks/useRouter";
import useUsers from "hooks/useUsers";
import { MESSAGE_TYPE } from "services/CommServer";
import { PoseType } from "services/ContentServer/Audit";
import { MediaSWRKeys } from "services/ContentServer/Audit/services/MediaCaptureService";
import { MediaCapture } from "services/ContentServer/Audit/serviceTypes/MediaCapture";
import { MediaHistory } from "services/ContentServer/Audit/serviceTypes/MediaHistory";
import { useUpdateHandler } from "services/InspectionUpdate/useUpdateHandler";
import { UpdateMessage } from "services/MessageHub";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { SnapshotContext } from "utils/Contexts/SnapshotContext";
import { sortObjects } from "utils/SortingUtils";

export const LOCATION_OFF = "Location Off";
export const FLAGGED_CAPTURE = "Flagged Captures";
export const CAPTURE_HISTORY = "Has Capture History";

export interface MediaCaptureWithHistory {
  capture: MediaCapture;
  history: MediaHistory[];
}

export interface MediaCaptureContextType {
  captureSave: boolean;
  setCaptureSave: (saving: boolean) => void;
  selectedImage?: string;
  setSelectedImage: (selectedImage?: string) => void;
  selectedImageIdx?: number;
  selectedImageObject?: MediaCapture;
  rawImages: MediaCapture[] | undefined;
  images: MediaCapture[];
  filteredImages: MediaCapture[];
  setFilteredImages: (prev: MediaCapture[] | ((prev: MediaCapture[]) => MediaCapture[])) => void;
  types: string[];
  setTypes: React.Dispatch<React.SetStateAction<string[]>>;
  labelFilters: string[];
  setLabelFilters: React.Dispatch<React.SetStateAction<string[]>>;
  isEditingCapture: boolean;
  setIsEditingCapture: (open: boolean) => void;
  captureHistories: string[];
  setCaptureHistories: React.Dispatch<React.SetStateAction<string[]>>;
  showDeleted: boolean;
  setShowDeleted: (prev: boolean | ((prev: boolean) => boolean)) => void;
  deletedImages: MediaCapture[];
  toggleCaptureFlag: () => void;
}

export const defaultContext: MediaCaptureContextType = {
  captureSave: false,
  setCaptureSave: () => {},
  selectedImage: undefined,
  setSelectedImage: (_?: string) => {},
  selectedImageIdx: undefined,
  selectedImageObject: undefined,
  rawImages: undefined,
  images: [],
  filteredImages: [],
  setFilteredImages: (_: MediaCapture[] | ((prev: MediaCapture[]) => MediaCapture[])) => {},
  types: [],
  setTypes: () => {},
  labelFilters: [],
  setLabelFilters: () => {},
  isEditingCapture: false,
  setIsEditingCapture: (_: boolean) => {},
  captureHistories: [],
  setCaptureHistories: () => {},
  showDeleted: false,
  setShowDeleted: () => {},
  deletedImages: [],
  toggleCaptureFlag: () => {},
};

export const ProvideMediaCaptureContext = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const { query } = useRouter();
  const { currentUser, featureAccess } = useUsers();
  const [captureSave, setCaptureSave] = useState<boolean>(false);
  const [isEditingCapture, setIsEditingCapture] = useState(false);
  const pendingMutations = useRef<UpdateMessage[]>([]);

  const adminAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ADMIN].read;
  }, [featureAccess]);

  const { contentServer } = useContext(RequestContext);

  const { selectedSnapshot } = useContext(SnapshotContext);

  const [showDeleted, setShowDeleted] = useState(false);

  const snapshotId = useMemo(() => {
    return selectedSnapshot?.id || "";
  }, [selectedSnapshot]);

  const { data: rawImages } = useSWR(snapshotId ? [MediaSWRKeys.MEDIA_CAPTURES, "snapshot", snapshotId] : null, () =>
    contentServer.mediaCaptureService.list([
      ["snapshot", snapshotId],
      adminAccess ? ["state", "ALL"] : ["state", "NORMAL"],
    ])
  );

  const deletedImages = useMemo(() => {
    if (adminAccess) {
      return rawImages
        ? [...rawImages]
            .filter((img) => img.state === "DELETED")
            .map((img: MediaCapture) => {
              return { ...img, poseType: PoseType.Deleted } as MediaCapture;
            })
        : [];
    } else {
      return [];
    }
  }, [adminAccess, rawImages]);

  const images = useMemo(() => {
    const validImages = rawImages ? [...rawImages].filter((img) => img.state === "NORMAL") : [];

    const validDeleted = deletedImages && showDeleted ? [...deletedImages] : [];

    const allImages = validImages.concat(validDeleted);

    return allImages.sort((a, b) => sortObjects(a, b, "createdAt")).reverse();
  }, [rawImages, deletedImages, showDeleted]);

  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    typeof query.capture === "string" ? query.capture : undefined
  );

  const imageUpdate = useCallback(
    (update: UpdateMessage) => {
      if (update.id != selectedImage || (!captureSave && !isEditingCapture)) {
        mutate([MediaSWRKeys.MEDIA_CAPTURES, "snapshot", snapshotId]);
      } else {
        pendingMutations.current = [...pendingMutations.current, update];
      }
    },
    [selectedImage, captureSave, isEditingCapture, snapshotId]
  );

  useEffect(() => {
    const unprocessedMutations: UpdateMessage[] = [];

    pendingMutations.current.forEach((update: UpdateMessage) => {
      if (update.id != selectedImage || (!captureSave && !isEditingCapture)) {
        mutate([MediaSWRKeys.MEDIA_CAPTURES, "snapshot", snapshotId]);
      } else {
        unprocessedMutations.push(update);
      }
    });

    pendingMutations.current = unprocessedMutations;
  }, [captureSave, isEditingCapture, selectedImage, snapshotId]);

  useUpdateHandler(MESSAGE_TYPE.MEDIA_CAPTURE_UPDATE, imageUpdate);

  const [filteredImages, setFilteredImages] = useState<MediaCapture[]>([]);

  const selectedImageIdx = useMemo(() => {
    if (filteredImages && selectedImage) {
      return filteredImages.findIndex((img) => img.id === selectedImage);
    }
  }, [filteredImages, selectedImage]);

  const selectedImageObject = useMemo(() => {
    return filteredImages?.find((image) => image.id === selectedImage);
  }, [filteredImages, selectedImage]);

  const toggleCaptureFlag = useCallback(async () => {
    setCaptureSave(true);
    if (selectedImage && selectedImageObject) {
      try {
        const prevFlagStatus = selectedImageObject.flag;
        mutate(
          [MediaSWRKeys.MEDIA_CAPTURES, "snapshot", selectedSnapshot?.id],
          (prevCaptures: MediaCapture[]) => {
            const newCaptures = [...prevCaptures];
            const captureIdx = newCaptures.findIndex((capture) => capture.id === selectedImage);
            newCaptures[captureIdx] = new MediaCapture({
              ...newCaptures[captureIdx],
              flag: !prevFlagStatus,
              lastModifiedAt: new Date(Date.now()).toISOString(),
              lastModifiedBy: currentUser?.userProfile.id,
            } as MediaCapture);
            return newCaptures;
          },
          false
        );
        await contentServer.mediaCaptureService.patch(
          selectedImage,
          new MediaCapture({
            flag: !prevFlagStatus,
          } as MediaCapture)
        );
      } catch (error) {
        console.error(error);
        mutate([MediaSWRKeys.MEDIA_CAPTURES, "snapshot", selectedSnapshot?.id]);
      }
      mutate([MediaSWRKeys.MEDIA_CAPTURES_WITH_HIST, "snapshot", selectedSnapshot?.id]);
    }
    setCaptureSave(false);
  }, [
    contentServer.mediaCaptureService,
    currentUser?.userProfile.id,
    selectedImage,
    selectedImageObject,
    selectedSnapshot?.id,
    setCaptureSave,
  ]);

  const [types, setTypes] = useState<string[]>([]);
  const [labelFilters, setLabelFilters] = useState<string[]>([]);
  const [captureHistories, setCaptureHistories] = useState<string[]>([]);

  useEffect(() => {
    if (
      filteredImages &&
      filteredImages.length > 0 &&
      (selectedImageIdx === undefined || selectedImageIdx < 0 || !selectedImage)
    ) {
      const id = filteredImages[filteredImages.length - 1].id;
      if (id) {
        setSelectedImage(id);
      }
    }
  }, [filteredImages, selectedImage, selectedImageIdx, setSelectedImage]);

  useEffect(() => {
    if (images && (types.length > 0 || labelFilters.length > 0)) {
      const filteredImgs = images.filter((img) => {
        const imgHistory = captureHistories?.length > 0 && captureHistories.find((mediaId) => mediaId === img.id);
        if (imgHistory && types.includes(CAPTURE_HISTORY)) {
          return true;
        }
        if (img.poseType && types.includes(img.poseType)) {
          return true;
        }
        if (img.captureType && types.includes(img.captureType)) {
          return true;
        }
        if (img.hidePose && types.includes(LOCATION_OFF)) {
          return true;
        }
        if (img.flag && types.includes(FLAGGED_CAPTURE)) {
          return true;
        }
        if (img.labels) {
          return img.labels.some((labelId) => {
            if (labelFilters.includes(labelId)) {
              return true;
            } else {
              return false;
            }
          });
        }
        return false;
      });
      if (filteredImgs.length > 0) {
        setFilteredImages(filteredImgs);
      } else {
        setFilteredImages(images);
        setTypes([]);
        setLabelFilters([]);
      }
    } else {
      setFilteredImages(images);
    }
  }, [images, labelFilters, setFilteredImages, types, setTypes, setLabelFilters, captureHistories]);

  const contextValues: MediaCaptureContextType = useMemo(
    () => ({
      captureSave: captureSave,
      setCaptureSave: setCaptureSave,
      selectedImage: selectedImage,
      setSelectedImage: setSelectedImage,
      selectedImageIdx: selectedImageIdx,
      selectedImageObject: selectedImageObject,
      rawImages: rawImages,
      images: images,
      filteredImages: filteredImages,
      setFilteredImages: setFilteredImages,
      types: types,
      setTypes: setTypes,
      labelFilters: labelFilters,
      setLabelFilters: setLabelFilters,
      captureHistories: captureHistories,
      setCaptureHistories: setCaptureHistories,
      showDeleted: showDeleted,
      setShowDeleted: setShowDeleted,
      deletedImages: deletedImages || [],
      isEditingCapture: isEditingCapture,
      setIsEditingCapture: setIsEditingCapture,
      toggleCaptureFlag: toggleCaptureFlag,
    }),
    [
      captureSave,
      selectedImage,
      selectedImageIdx,
      selectedImageObject,
      rawImages,
      images,
      filteredImages,
      types,
      labelFilters,
      captureHistories,
      showDeleted,
      deletedImages,
      isEditingCapture,
      toggleCaptureFlag,
    ]
  );

  return <MediaCaptureContext.Provider value={contextValues}>{children}</MediaCaptureContext.Provider>;
};

export const MediaCaptureContext = React.createContext(defaultContext);
