export const FILTER_BOX_HEIGHT = 47;
export const FILTER_BOX_HEIGHT_NO_BORDER = FILTER_BOX_HEIGHT - 2;
export const BORDER_RADIUS = 0;

//tableStyle
export const PRIMARY_LIGHT_RGB = "#FFF";
export const SELECTED_OPACITY = 0.2;
export const SELECTED_ROW_COLOUR = "#EFF8FF";
export const HOVER_OPACITY = 0.05;
export const HOVER_ROW_COLOR = "#F4F6F8";
export const HOVER_SELECTED_OPACITY = 0.3;
export const HOVER_SELECTED_ROW_COLOR = "#F4F6F8";

//Capture History style

export const CARD_PADDING = "20px";
export const BORDER_RADIUS_CAPTURES = "8px";

//webportal
export const APP_BAR_HEIGHT = 65;
export const LOADING_BAR_HEIGHT = 4;
export const TEXT_BOX_OUTLINE = "#BBDCF5";
