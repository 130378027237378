import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { ImportStatus } from "../serviceTypes/ImportStatus";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const ImportStatusSWRKeys = {
  IMPORT_REQUEST: "import_request",
};

export class ImportStatusService extends ServiceBase<ImportStatus> {
  constructor(apiInstance: APIInstance) {
    super(ImportStatus, apiInstance, ENDPOINTS.IMPORT_STATUS);
  }
}
