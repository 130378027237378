import { ErrorRounded, WarningRounded } from "@mui/icons-material/";
import Badge, { BadgeProps as MuiBadgeProps } from "@mui/material/Badge";
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { getChatMsgs } from "redux/features/appSlice";
import { useAppSelector } from "redux/hooks";

import useMessagingServiceConnectionState from "hooks/useMessagingServiceConnectionState";
import { ConnectionState } from "services/ConnectionStatusManager";
import { Status } from "services/MessageHub";

interface Props extends MuiBadgeProps {
  userStatus?: Status;
  notifyStatus?: boolean;
}

const ARBadge = ({ notifyStatus, userStatus, children, ...other }: Props) => {
  const { connectionState } = useMessagingServiceConnectionState();
  const numChatMsgs = useAppSelector(getChatMsgs);
  const theme = useTheme();

  const badgeContent = useMemo(() => {
    if (notifyStatus) {
      if (connectionState === ConnectionState.DISCONNECTED) {
        return <ErrorRounded sx={{ fill: theme.palette.error.main }} />;
      } else if (connectionState === ConnectionState.RECONNECTING) {
        return <WarningRounded sx={{ fill: theme.palette.warning.main }} />;
      } else {
        return numChatMsgs;
      }
    }
  }, [connectionState, notifyStatus, numChatMsgs, theme]);

  const badgeColor = useMemo(() => {
    if (notifyStatus && typeof badgeContent === "number") {
      return "error";
    } else if (userStatus === Status.Active) {
      return "success";
    } else if (userStatus === Status.Inactive) {
      return "warning";
    }
    return "default";
  }, [notifyStatus, userStatus, badgeContent]);

  const badgeVariant = useMemo(() => {
    if (userStatus) return "dot";
    return "standard";
  }, [userStatus]);

  return (
    <Badge overlap="circular" variant={badgeVariant} max={99} badgeContent={badgeContent} {...other} color={badgeColor}>
      {children}
    </Badge>
  );
};

export default ARBadge;
