import { ModelState } from "../types";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof LabelGroup | undefined } = {
  id: "id",
  name: "name",
  description: "description",
  colour: "colour",
  created_at: "createdAt",
  state: "state",
  last_modified_by: "lastModifiedBy",
};

export class LabelGroup extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public description?: string | null;
  public colour?: string | null;
  public createdAt?: string | null;
  public state?: ModelState | null;
  public lastModifiedBy?: string | null;

  constructor(labelGroup?: LabelGroup) {
    super();
    if (labelGroup) {
      Object.assign(this, labelGroup);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<LabelGroup>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<LabelGroup>(this, serverToView);
    return request;
  }
}
