import { ChatOutlined, VideocamOutlined } from "@mui/icons-material";
import { ListItem, ListItemAvatar, ListItemText, IconButton, Box, ListItemSecondaryAction } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useContext } from "react";
import { setOpenChat, setChatMsgs } from "redux/features/appSlice";
import { useAppDispatch } from "redux/hooks";

import ARAvatar from "components/ARAvatar";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import { useAuth } from "hooks/useAuth";
import useUser from "hooks/useUser";
import useWebRTC from "hooks/useWebRTC";
import { useCallState } from "hooks/useWebRTCSession";
import { ChatContext } from "views/ChatView/ChatContext";

const UserListItem = ({ userId, msg }: { userId: string; msg?: string }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useUser(userId);
  const auth = useAuth();
  const { webRTC } = useWebRTC();
  const callState = useCallState();
  const {
    setChatOpen,
    setUserSelectedId,
    setUserSelectedName,
    chatBox,
    setChatBox,
    missedMessages,
    unreadMessages,
    setMissedMessages,
    setUnreadMessages,
    tab,
    setTab,
    setProfileOpen,
    setPrevLocation,
    setNumNewMsgs,
    numNewMsgs,
  } = useContext(ChatContext);

  const totalUnreadMissedMessages = useCallback(() => {
    let count = 0;
    Object.values(missedMessages).forEach((chat) => {
      if (user && chat[0].sender === user.id) {
        count += chat.length;
      }
    });
    for (const pair in unreadMessages) {
      if (user && pair === user.id) {
        count += unreadMessages[pair];
      }
    }
    return count;
  }, [missedMessages, unreadMessages, user]);

  return (
    <>
      {user && (
        <ListItem sx={{ padding: "2px 0", minHeight: "50px" }} divider={true}>
          <ListItemAvatar>
            <div
              onClick={() => {
                setUserSelectedId(user?.id);
                setUserSelectedName(user?.name);
                setProfileOpen(true);
                if (tab === 0) {
                  setPrevLocation("chatList");
                } else if (tab === 1) {
                  setPrevLocation("contacts");
                }
              }}
            >
              <ARAvatar small={true} noBadge={false} user={user} />
            </div>
          </ListItemAvatar>
          <ListItemText
            primary={
              <EllipsisTooltip
                variant="body1"
                style={{
                  width: "65%",
                }}
              >
                {user.name}
              </EllipsisTooltip>
            }
            secondary={msg ? msg : ""}
            secondaryTypographyProps={{
              width: "65%",
              variant: "body2",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              if (user.id !== auth.user?.id) {
                setTab(0);
                setChatOpen(true);
                setUserSelectedId(user?.id);
                setUserSelectedName(user?.name);
                if (user && !Object.prototype.hasOwnProperty.call(chatBox, user.id)) {
                  const newChatBox = chatBox;
                  newChatBox[user.id] = [];
                  setChatBox(newChatBox);
                }
                setMissedMessages((prevMissedMessages) => {
                  if (Object.prototype.hasOwnProperty.call(prevMissedMessages, user.id)) {
                    delete prevMissedMessages[user.id];
                  }
                  return prevMissedMessages;
                });
                setUnreadMessages((prevUnreadMessages) => {
                  if (Object.prototype.hasOwnProperty.call(prevUnreadMessages, user.id)) {
                    delete prevUnreadMessages[user.id];
                  }
                  return prevUnreadMessages;
                });
                setNumNewMsgs(numNewMsgs - totalUnreadMissedMessages());
                dispatch(setChatMsgs(numNewMsgs - totalUnreadMissedMessages()));
              }
            }}
          />

          <ListItemSecondaryAction style={{ display: "flex", flexDirection: "row", right: "5px" }}>
            <IconButton
              id={`video-call-${user.name.toLowerCase().replace(/ /g, "-")}`}
              sx={{
                verticalAlign: "middle",
                color: theme.palette.primary.main,
                "&.Mui-disabled": {
                  color: theme.palette.action.disabled,
                },
              }}
              onClick={() => user?.id && webRTC?.call(user.id) && dispatch(setOpenChat(false))}
              disabled={
                !user ||
                !webRTC ||
                user.id === auth.user?.id ||
                callState.isCalling ||
                callState.isInCall ||
                callState.isReceivingCall
              }
              size="large"
            >
              <VideocamOutlined style={{ fontSize: "23px" }} />
            </IconButton>
            <Box display="flex" margin={0} padding={0}>
              {totalUnreadMissedMessages() !== 0 && (
                <div
                  style={{
                    width: theme.typography.h4.fontSize,
                    lineHeight: theme.typography.h4.fontSize,
                    borderRadius: "50%",
                    textAlign: "center",
                    fontSize: theme.typography.caption.fontSize,
                    zIndex: theme.zIndex.appBar + 2,
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  {totalUnreadMissedMessages()}
                </div>
              )}
              <IconButton
                id={`message-${user.name.toLowerCase().replace(/ /g, "-")}`}
                sx={{
                  verticalAlign: "middle",
                  color: theme.palette.primary.main,
                  "&.Mui-disabled": {
                    color: theme.palette.action.disabled,
                  },
                }}
                onClick={() => {
                  setTab(0);
                  setChatOpen(true);
                  setUserSelectedId(user?.id);
                  setUserSelectedName(user?.name);
                  if (user && !Object.prototype.hasOwnProperty.call(chatBox, user.id)) {
                    const newChatBox = chatBox;
                    newChatBox[user.id] = [];
                    setChatBox(newChatBox);
                  }
                  setMissedMessages((prevMissedMessages) => {
                    if (Object.prototype.hasOwnProperty.call(prevMissedMessages, user.id)) {
                      delete prevMissedMessages[user.id];
                    }
                    return prevMissedMessages;
                  });
                  setUnreadMessages((prevUnreadMessages) => {
                    if (Object.prototype.hasOwnProperty.call(prevUnreadMessages, user.id)) {
                      delete prevUnreadMessages[user.id];
                    }
                    return prevUnreadMessages;
                  });
                  setNumNewMsgs(numNewMsgs - totalUnreadMissedMessages());
                  dispatch(setChatMsgs(numNewMsgs - totalUnreadMissedMessages()));
                }}
                disabled={user.id === auth.user?.id}
                size="large"
              >
                <ChatOutlined style={{ fontSize: "23px" }} />
              </IconButton>
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </>
  );
};

export default React.memo(UserListItem);
