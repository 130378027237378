import { Close, CloudUploadOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import "assets/styles/webportal/components/FileDropzone.css";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";

interface FileDropzoneProps {
  onDrop: (files: File[]) => void;
  onDelete?: (file: File, index?: number) => void;
  acceptedFiles?: string[];
  maxFileSize?: number;
  filesLimit?: number;
  initialFiles?: File[];
  dropzoneText?: string;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onDrop,
  onDelete,
  acceptedFiles,
  maxFileSize,
  filesLimit,
  initialFiles,
  dropzoneText,
}) => {
  const theme = useTheme();
  const [files, setFiles] = useState<File[]>(initialFiles || []);

  const onDropCallback = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > (filesLimit ? filesLimit : 1)) {
        return;
      }

      for (const file of acceptedFiles) {
        if (maxFileSize && file.size > maxFileSize) {
          console.error(`File ${file.name} is larger than the max file size of ${maxFileSize}`);
          return;
        }
      }

      setFiles([...acceptedFiles]);
      onDrop(acceptedFiles);
    },
    [onDrop, maxFileSize, filesLimit]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    accept: acceptedFiles,
    maxSize: maxFileSize,
    maxFiles: filesLimit,
  });

  const removeFile = (file: File) => (event: React.MouseEvent) => {
    event.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);

    if (onDelete) {
      onDelete(file);
    }
  };

  return (
    <div {...getRootProps()} className="dropzone-container">
      <div className="dropzone-root">
        <input {...getInputProps()} className="dropzone-input" />
        <div className="dropzone-content">
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div>
              <Typography variant="body1">
                {dropzoneText ? dropzoneText : "Click to choose file or drag and drop file into box."}
              </Typography>
              <CloudUploadOutlined sx={{ fontSize: "30px", color: "#95989B", mt: 1 }} />
            </div>
          )}
        </div>
        <ul className="dropzone-file-list">
          {files.map((file, index) => (
            <li key={index} className="dropzone-file-list-item">
              <div className="dropzone-file-display">
                <EllipsisTooltip variant="body1">{file.name}</EllipsisTooltip>
                <button onClick={removeFile(file)} className="delete-button" aria-label="Delete file">
                  <Close sx={{ color: theme.palette.primary.contrastText, padding: "5px" }} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FileDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string.isRequired),
  maxFileSize: PropTypes.number,
  filesLimit: PropTypes.number,
};

export default FileDropzone;
