import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "./customFetchBase";
import { deserializeTenantInfo } from "services/ContentServer/Tenant/serializers";
import { TenantInfo } from "services/ContentServer/Tenant/types";

// export interface ITenant {
//   application_title: string;
//   config: string;
//   domain: string;
//   idle_timeout_mins: number;
//   logo: string;
//   name: string;
//   priority: number;
//   tenant_id: string;
//   snapshot_expiry_days: number;
// }

export const tenantApi = createApi({
  reducerPath: "tenantApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getTenant: builder.query<TenantInfo, string>({
      query(id) {
        return {
          url: `/tenant/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return deserializeTenantInfo(response);
      },
    }),
    updateTenant: builder.mutation<any, { id: string; data: FormData }>({
      query({ id, data }) {
        return {
          url: `/tenant/${id}/`,
          method: "PATCH",
          body: data,
        };
      },
    }),
  }),
});

export const { useGetTenantQuery, useUpdateTenantMutation } = tenantApi;
