import { ENDPOINTS } from "./constants";
import {
  deserializeTenantInfo,
  deserializeTenantLabels,
  deserializeTenantLogo,
  serializeTenantInfo,
  serializeTenantLabels,
} from "./serializers";
import { TenantInfo, TenantInterface, TenantLabels } from "./types";
import { APIInstance } from "services/ContentServer/APIInstance";
import { TenantInfoState } from "views/AppearancePage/tenantInfoReducer";

export const TenantSWRKeys = {
  TENANTS: "tenants",
  TENANT_INFO: "tenantInfo",
  TENANT_LABELS: "tenantLabels",
};
export class TenantService implements TenantInterface {
  private apiInstance: APIInstance;

  constructor({ apiInstance }: { apiInstance: APIInstance }) {
    this.apiInstance = apiInstance;
  }

  async getTenants(): Promise<TenantInfo[]> {
    const endpoint = `${ENDPOINTS.TENANTINFO}`;
    const resp = await this.apiInstance.get(endpoint);
    return resp.data.map((tenantInfo: any[]) => {
      return deserializeTenantInfo([tenantInfo]);
    });
  }

  async getTenantInfo(tenantId: string): Promise<TenantInfo> {
    const endpoint = `${ENDPOINTS.TENANTINFO}${tenantId}/`;
    const resp = await this.apiInstance.get(endpoint);
    return deserializeTenantInfo(resp.data);
  }

  async getTenantLogo(tenantId: string): Promise<string> {
    const endpoint = `${ENDPOINTS.TENANTINFO}${tenantId}/`;
    const resp = await this.apiInstance.get(endpoint);
    return deserializeTenantLogo(resp.data);
  }

  async deleteTenantLogo(tenantId: string): Promise<any> {
    const endpoint = `${ENDPOINTS.TENANTINFO}${tenantId}/`;
    const resp = await this.apiInstance.patch(endpoint, { logo: "" });
    return resp.data;
  }

  async editTenantInfo(tenantId: string, tenantInfo: TenantInfoState): Promise<any> {
    const data = serializeTenantInfo(tenantInfo);
    const endpoint = `${ENDPOINTS.TENANTINFO}${tenantId}/`;
    const resp = await this.apiInstance.patch(endpoint, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return deserializeTenantInfo([resp.data]);
  }

  async getTenantWhiteLabels(tenantId: string): Promise<TenantLabels> {
    const endpoint = `${ENDPOINTS.WHITELABELS}${tenantId}/`;
    const resp = await this.apiInstance.get(endpoint);
    return deserializeTenantLabels(resp.data);
  }

  async editTenantWhiteLabels(tenantId: string, tenantWhiteLabels: TenantLabels): Promise<any> {
    const endpoint = `${ENDPOINTS.WHITELABELS}${tenantId}/`;
    const data = serializeTenantLabels(tenantWhiteLabels);
    const resp = await this.apiInstance.patch(endpoint, data);
    return resp.data;
  }

  async copyTenantData(newTenantName: string): Promise<any> {
    const endpoint = `${ENDPOINTS.COPYTENANT}`;
    const resp = await this.apiInstance.post(endpoint, { "new_tenant_name": newTenantName});
    return resp.data;
  }
}
