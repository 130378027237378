import SwitchUnstyled, { switchUnstyledClasses } from "@mui/base/SwitchUnstyled";
import { styled } from "@mui/material/styles";
import React from "react";

const Root = styled("span")(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: block;
  width: 32px;
  height: 16px;
  margin: 0;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #64676A99;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 12px;
    height: 12px;
    top: 2px;
    left: 3px;
    border-radius: 66px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: #64676A99;
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 18px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: ${theme.palette.primary.main};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `
);

const settings = styled(Root)(
  () => `
  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.track} {
      background: #000;
    }
  }
  `
);

const StyledSwitch = ({
  checked = true,
  onChange = () => {},
  disabled = false,
}: {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) => {
  return <SwitchUnstyled component={Root} checked={checked} onChange={onChange} disabled={disabled} />;
};

export const SettingsSwitch = ({
  checked = true,
  onChange = () => {},
  disabled = false,
}: {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) => {
  return <SwitchUnstyled component={settings} checked={checked} onChange={onChange} disabled={disabled} />;
};

export default StyledSwitch;
