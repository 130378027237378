import { DefaultReconnectPolicy } from "./DefaultReconnectPolicy";
import { getCommServerMessageFromJSON, serializeCommServerMessage } from "./serializers";
import { IConnectionOptions, ICommServer, MessageCallback } from "./types";
import { ConnectionStateSubscription, ConnectionState } from "services/ConnectionStatusManager";
import SubscriptionManager from "utils/SubscriptionManager";

const DEFAULT_OPTIONS = {
  reconnectionPolicy: new DefaultReconnectPolicy(),
  renewGroupsIntervalInMs: 3600000,
};

export class CommServer implements ICommServer {
  private socketConnection?: WebSocket;
  private hasAttemptedFirstConnection: boolean;
  private renewGroupsTimerId: number | undefined;
  private connectionState: ConnectionState;
  private connectionStateSubscription: SubscriptionManager<ConnectionStateSubscription>;
  private messageSubscriptions: Map<string, SubscriptionManager<MessageCallback>>;
  private baseURL: string;
  private options: IConnectionOptions;

  constructor({ baseURL, options }: { baseURL: string; options: IConnectionOptions }) {
    this.baseURL = baseURL;
    this.options = { ...DEFAULT_OPTIONS, ...options };
    this.connectionState = ConnectionState.DISCONNECTED;

    this.connectionStateSubscription = new SubscriptionManager();
    this.messageSubscriptions = new Map<string, SubscriptionManager<MessageCallback>>();
    this.hasAttemptedFirstConnection = false;
  }

  getConnectionState(): ConnectionState {
    return this.connectionState;
  }

  subscribeToConnectionState(callback: ConnectionStateSubscription): () => void {
    return this.connectionStateSubscription.subscribe(callback);
  }

  async start(): Promise<void> {
    if (this.connectionState === ConnectionState.CONNECTED || this.connectionState === ConnectionState.CONNECTING) {
      return Promise.resolve();
    }
    if (this.connectionState !== ConnectionState.RECONNECTING) {
      this.updateConnectionState(ConnectionState.CONNECTING);
    }
    try {
      const token = await this.options.accessTokenFactory();
      const baseURLWithToken = `${this.baseURL}/?token=${token}`;

      this.stop();
      this.socketConnection = new WebSocket(baseURLWithToken);

      this.socketConnection.onopen = () => {
        console.info("Successfully connected to Comm Server");
        this.options.reconnectionPolicy?.didFinishReconnecting();
        this.updateConnectionState(ConnectionState.CONNECTED);
        this.renewGroupsTimerId = window.setInterval(() => this.renewGroups(), this.options.renewGroupsIntervalInMs);
      };

      this.socketConnection.onmessage = (event: any) => {
        if (event) {
          this.handleMessageReceived(event.data as string);
        }
      };

      this.socketConnection.onclose = () => {
        window.clearInterval(this.renewGroupsTimerId);
        this.renewGroupsTimerId = undefined;
        this.onConnectionClose();
      };
    } catch (error) {
      console.error(error);
      this.onConnectionClose();
    }
  }

  stop() {
    if (this.socketConnection) {
      this.socketConnection.onclose = null;
      this.socketConnection.close();
      this.socketConnection = undefined;
    }
  }

  send(messageType: string, message: Record<string | number | symbol, unknown>): Promise<void> {
    if (this.socketConnection?.readyState !== WebSocket.OPEN) {
      return Promise.reject("Refusing to send Comm Server message: Inappropriate connection state");
    }
    this.socketConnection.send(serializeCommServerMessage(messageType, message));
    return Promise.resolve();
  }

  subscribe(messageType: string, handler: (message: string) => void): () => void {
    if (!this.hasAttemptedFirstConnection) {
      this.hasAttemptedFirstConnection = true;
      this.start().catch(console.error);
    }
    const subscription = this.messageSubscriptions.get(messageType);
    if (subscription) {
      return subscription.subscribe(handler);
    } else {
      const newSubscription = new SubscriptionManager<MessageCallback>();
      this.messageSubscriptions.set(messageType, newSubscription);
      return newSubscription.subscribe(handler);
    }
  }

  private renewGroups(): void {
    if (this.socketConnection?.readyState !== WebSocket.OPEN) {
      console.warn("Refusing to renew Comm Server groups: Inappropriate connection state");
      return;
    }
    this.socketConnection.send(JSON.stringify({ message_type: "renew_groups" }));
  }

  private onConnectionClose(): void {
    this.updateConnectionState(ConnectionState.RECONNECTING);
    const reconnectInterval = this.options.reconnectionPolicy?.getNextReconnectIntervalInMs();
    if (reconnectInterval !== undefined) {
      console.warn(`Comm Server connection failed, retrying in ${reconnectInterval / 1000} seconds`);
      setTimeout(() => this.start().catch(console.error), reconnectInterval);
    } else {
      console.error("Failed to reconnect to Comm Server. Closing connection");
      this.updateConnectionState(ConnectionState.DISCONNECTED);
      this.options.reconnectionPolicy?.didFinishReconnecting();
    }
  }

  private handleMessageReceived(messageText: string): void {
    const message = getCommServerMessageFromJSON(messageText);
    if (message) {
      const subscription = this.messageSubscriptions.get(message.type);
      if (subscription) {
        subscription.subscribers.forEach((callback) => callback(message.data));
      } else {
        console.warn(`No handler found for Comm Server message of type: ${message.type}`);
      }
    } else {
      console.error(`Unable to process message Comm Server message: ${messageText}`);
    }
  }

  private updateConnectionState(state: ConnectionState): void {
    this.connectionState = state;
    this.connectionStateSubscription.subscribers.forEach((callback) => callback(state));
  }
}
