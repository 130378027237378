import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import Config from "Config";
import { setToken } from "redux/features/authSlice";
import type { RootState } from "redux/store";

import { msalInstance } from "services";

const baseQuery = fetchBaseQuery({
  baseUrl: Config.BACKEND_BASE_URL + "/api",
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.auth.token;
    const apiVersion = state.auth.apiVersion;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("version", apiVersion);
    }

    return headers;
  },
  credentials: "include",
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    try {
      // try to get a new token
      const resp = await msalInstance.acquireTokenSilent({
        scopes: Config.AZURE_AD_CONFIG.backendScopes,
        account: (api.getState() as RootState).auth.account,
        redirectUri: Config.AZURE_AD_CONFIG.redirectUri,
      });

      api.dispatch(setToken(resp.accessToken));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } catch (error) {
      console.error(error);
    }
  }

  return result;
};

export default baseQueryWithReauth;
