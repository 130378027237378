import { Palette } from "@mui/material/styles/createPalette";

const typography = (palette: Palette) => {
  return {
    h1: {
      color: palette.neutral.emphasizedTextIcons,
      fontWeight: 500,
      fontSize: "35px",
      letterSpacing: "-0.24px",
      lineHeight: "40px",
    },
    h2: {
      color: palette.neutral.emphasizedTextIcons,
      fontWeight: 500,
      fontSize: "29px",
      letterSpacing: "-0.24px",
      lineHeight: "32px",
    },
    h3: {
      color: palette.neutral.emphasizedTextIcons,
      fontWeight: 500,
      fontSize: "23px",
      letterSpacing: "-0.06px",
      lineHeight: "26px",
    },
    h4: {
      color: palette.neutral.emphasizedTextIcons,
      fontWeight: 500,
      fontSize: "20px",
      letterSpacing: "-0.06px",
      lineHeight: "24px",
    },
    h5: {
      color: palette.neutral.emphasizedTextIcons,
      fontWeight: 500,
      fontSize: "17px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
    h6: {
      color: palette.neutral.emphasizedTextIcons,
      fontWeight: 500,
      fontSize: "15px",
      letterSpacing: "-0.05px",
      lineHeight: "20px",
    },
    subtitle1: {
      color: palette.neutral.secondaryTextButtons,
      fontSize: "16px",
      letterSpacing: "-0.05px",
      lineHeight: "25px",
    },
    subtitle2: {
      color: palette.neutral.secondaryTextButtons,
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "-0.05px",
      lineHeight: "21px",
    },
    body1: {
      color: palette.neutral.defaultTextIcons,
      fontSize: `var(--fontSize)`,
      letterSpacing: "-0.05px",
      lineHeight: "21px",
    },
    body2: {
      color: palette.neutral.defaultTextIcons,

      fontSize: `calc(var(--fontSize) - 2px)`,
      letterSpacing: "-0.04px",
      lineHeight: "18px",
    },
    button: {
      color: palette.neutral.secondaryTextButtons,
      fontSize: "14px",
    },
    caption: {
      color: palette.neutral.secondaryTextButtons,

      fontSize: "11px",
      letterSpacing: "0.33px",
      lineHeight: "13px",
    },
    dalmatianBase: {
      fontFamily: "Inter!important",
      fontStyle: "normal!important",
      color: palette.dalmatianDesign.dark + "!important",
    },
    dalmatianH1: {
      fontFamily: "Inter!important",
      fontStyle: "normal!important",
      fontWeight: 700,
      fontSize: "20px!important",
      lineHeight: "140%!important",
      color: palette.dalmatianDesign.dark + "!important",
      paddingBottom: "16px",
    },
    dalmatianH1Link: {
      fontFamily: "Inter!important",
      fontStyle: "normal!important",
      fontWeight: 700,
      fontSize: "20px!important",
      lineHeight: "140%!important",
      color: palette.dalmatianDesign.dark + "!important",
      paddingBottom: "16px",
      "&:hover": {
        textDecoration: "underline",
        textUnderlineOffset: "20%",
      },
    },
    dalmatianP: {
      fontFamily: "Inter!important",
      fontStyle: "normal!important",
      fontWeight: 400,
      fontSize: "14px!important",
      lineHeight: "140%!important",
      color: palette.dalmatianDesign.dark + "!important",
    },
    dalmatianError: {
      fontFamily: "Inter!important",
      fontStyle: "normal!important",
      fontWeight: 600,
      fontSize: "12px!important",
      lineHeight: "15%!important",
      color: palette.dalmatianDesign.error + "!important",
    },
  };
};
export default typography;
