import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Difference | undefined } = {
  id: "id",
  capture_id: "captureId",
  history_id: "historyId",
};

export class Difference extends ISerialization {
  public id?: string | null;
  public captureId?: string | null;
  public historyId?: string | null;

  constructor(difference?: Difference) {
    super();
    Object.assign(this, difference);
  }

  deserialize(json: any): void {
    deserializerHelper<Difference>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Difference>(this, serverToView);
    return request;
  }
}
