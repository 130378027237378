import { APIInstance } from "./APIInstance";
import { ISerialization, ISerializationPaginated } from "./ISerialization";
import serialize from "utils/ObjectToFormData";

export abstract class ServiceBase<
  T extends ISerialization,
  PagType extends ISerializationPaginated<T> = ISerializationPaginated<T>
> {
  constructor(
    protected createInstance: new () => T,
    protected apiInstance: APIInstance,
    protected endpoint: string,
    protected createPaginatedInstance?: new () => PagType
  ) {}

  async getResponseData(
    id: string,
    searchParams?: [string, string][],
    urlPath?: string,
    getFields?: string[]
  ): Promise<any> {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = urlPath ? `${this.endpoint}${id}/${urlPath}` : `${this.endpoint}${id}/`;
    if (getFields) {
      this.apiInstance.addFieldsToGetRequestURL(url, ...getFields);
    }
    if (searchParams) {
      this.apiInstance.addSearchParamsToURL(url, ...searchParams);
    }
    const resp = await this.apiInstance.get(url.toString());
    return resp.data;
  }

  async get(id: string, searchParams?: [string, string][], urlPath?: string, getFields?: string[]): Promise<T> {
    const resp = await this.getResponseData(id, searchParams, urlPath, getFields);
    const object = new this.createInstance();
    object.deserialize(resp);
    return object;
  }

  async listResponseData(searchParams?: [string, string][], getFields?: string[]): Promise<any[]> {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = this.endpoint;
    if (getFields) {
      this.apiInstance.addFieldsToGetRequestURL(url, ...getFields);
    }
    if (searchParams) {
      this.apiInstance.addSearchParamsToURL(url, ...searchParams);
    }
    const resp = await this.apiInstance.get(url.toString());
    return resp.data;
  }

  async listMultipleParams(searchParams: [string, string[]][]): Promise<T[]> {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = this.endpoint;
    this.apiInstance.addSearchParamsListToURL(url, ...searchParams);
    const resp = await this.apiInstance.get(url.toString());
    return this.deserializeListResp(resp.data);
  }

  async list(searchParams?: [string, string][], getFields?: string[]): Promise<T[]> {
    const data = await this.listResponseData(searchParams, getFields);
    return this.deserializeListResp(data);
  }

  async post(obj: T) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = this.endpoint;
    const request = obj.serialize();
    const requestForm = request instanceof FormData ? request : serialize(request);

    const resp = await this.apiInstance.post(url.toString(), requestForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    obj.deserialize(resp.data);
    return obj;
  }

  async postArray(obj: T[]) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = this.endpoint;
    const requests = obj.map((o) => serialize(o.serialize()));

    const requestForm = new FormData();
    for (let i = 0; i < requests.length; i++) {
      for (const pair of requests[i].entries()) {
        requestForm.append(`[${i}]${pair[0]}`, pair[1]);
      }
    }

    const resp = await this.apiInstance.post(url.toString(), requestForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return resp.data;
  }

  async postCustomFormData(data: any, customEndpoint?: string, headers?: Record<string, string | number>) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = customEndpoint ? customEndpoint : this.endpoint;
    const resp = await this.apiInstance.post(url.toString(), serialize(data), {
      headers: { "Content-Type": "multipart/form-data", ...headers },
    });
    const obj = new this.createInstance();
    obj.deserialize(resp.data);
    return { object: obj, data: resp.data };
  }

  async bulkPost(objs: T[]) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = this.endpoint;
    const request = objs.map((obj) => obj.serialize());
    const resp = await this.apiInstance.post(url.toString(), request);
    return resp.data.map((elem: any, index: number) => {
      const object = objs[index];
      object.deserialize(elem);
      return object;
    });
  }

  async put(id: string, obj: T, searchParams?: [string, string][]) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = `${this.endpoint}${id}/`;
    if (searchParams) {
      this.apiInstance.addSearchParamsToURL(url, ...searchParams);
    }
    if (obj.validate()) {
      const request = obj.serialize();
      const resp = await this.apiInstance.put(url.toString(), serialize(request), {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const object = new this.createInstance();
      object.deserialize(resp.data);
      return object;
    }
    throw Error();
  }

  async patch(id: string, obj: T, searchParams?: [string, string][]): Promise<T> {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = `${this.endpoint}${id}/`;
    if (searchParams) {
      this.apiInstance.addSearchParamsToURL(url, ...searchParams);
    }
    const request = obj.serialize();
    const resp = await this.apiInstance.patch(url.toString(), serialize(request), {
      headers: { "Content-Type": "multipart/form-data" },
    });
    const object = new this.createInstance();
    object.deserialize(resp.data);
    return object;
  }

  async patchCustomFormData(id: string, data: any, runSerialize = true) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = `${this.endpoint}${id}/`;
    const resp = await this.apiInstance.patch(url.toString(), runSerialize ? serialize(data) : data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    const obj = new this.createInstance();
    obj.deserialize(resp.data);
    return obj;
  }

  async delete(id: string) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = `${this.endpoint}${id}/`;
    const resp = await this.apiInstance.delete(url.toString());
    return resp;
  }

  deserializeListResp(data: any[]) {
    if (Array.isArray(data)) {
      return data.map((elem: any) => {
        const object = new this.createInstance();
        object.deserialize(elem);
        return object;
      }) as T[];
    } else {
      return data;
    }
  }

  buildURL(searchParams?: [string, string][], urlPath?: string, getFields?: string[]) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = urlPath ? `${this.endpoint}${urlPath}` : `${this.endpoint}`;
    if (getFields) {
      this.apiInstance.addFieldsToGetRequestURL(url, ...getFields);
    }
    if (searchParams) {
      this.apiInstance.addSearchParamsToURL(url, ...searchParams);
    }
    return url;
  }

  getURL(searchParams?: [string, string][], getFields?: string[], pageNumber?: number) {
    const url = new URL(this.apiInstance.baseURL);
    url.pathname = this.endpoint;

    if (getFields) {
      this.apiInstance.addFieldsToGetRequestURL(url, ...getFields);
    }
    if (searchParams) {
      this.apiInstance.addSearchParamsToURL(url, ...searchParams);
    }
    if (pageNumber !== undefined) {
      this.apiInstance.addSearchParamsToURL(url, ["paginate", "true"]);
      this.apiInstance.addSearchParamsToURL(url, ["page", pageNumber.toString()]);
    }
    return url.toString();
  }

  // You can only use this function when the child class supports pagination.
  async fetcherPaginated(url: string) {
    let object: PagType;
    if (!this.createPaginatedInstance) {
      console.error("This service does not explicitly support pagination. Will attempt to deserialize the response.");
      object = new ISerializationPaginated<T>() as PagType;
    } else {
      object = new this.createPaginatedInstance();
    }
    const resp = await this.apiInstance.get(url);
    object.deserializePaginated(resp.data, this.createInstance);
    return object;
  }
}
