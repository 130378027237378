import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Config from "Config";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetTenantFeaturesQuery } from "redux/api/appApi";
import { setToken, setApiVersion as setVersion } from "redux/features/authSlice";
import { useAppDispatch } from "redux/hooks";

import packageJson from "../../../../package.json";
import { useAuth } from "hooks/useAuth";
import { CommServer, CommServerConnectionStatusManager } from "services/CommServer";
import { IConnectionStatusManager } from "services/ConnectionStatusManager";
import { ContentServer } from "services/ContentServer";
import { IChatHub, IInspectionUpdateHub, IPoseHub, IVideoCallHub } from "services/MessageHub";
import {
  CommServerChatHub,
  CommServerInspectionUpdateHub,
  CommServerPoseHub,
  CommServerVideoCallHub,
} from "services/MessageHub/CommServer";
export interface RequestContextType {
  contentServer: ContentServer;
  commServer: CommServer;
  messagingServiceStatusManager: IConnectionStatusManager;
  poseHub: IPoseHub;
  videoCallHub: IVideoCallHub;
  chatHub: IChatHub;
  inspectionUpdateHub: IInspectionUpdateHub;
  iceServers: any;
}

const defaultContext: RequestContextType = {
  contentServer: new ContentServer({
    baseURL: Config.BACKEND_BASE_URL,
    accessTokenFactory: async () => {
      return "";
    },
    apiVersion: packageJson.apiVersion,
  }),
  commServer: new CommServer({
    baseURL: Config.COMM_SERVER_BASE_URL,
    options: {
      accessTokenFactory: async () => {
        return "";
      },
    },
  }),
  messagingServiceStatusManager: new CommServerConnectionStatusManager({}),
  poseHub: new CommServerPoseHub({}),
  videoCallHub: new CommServerVideoCallHub({}),
  chatHub: new CommServerChatHub({}),
  inspectionUpdateHub: new CommServerInspectionUpdateHub({}),
  iceServers: {},
};

export const ProvideRequestContext = (props: any) => {
  const { account, login, setupComplete } = useAuth();
  const { instance } = useMsal();
  const [iceServers, setIceServers] = useState<any>(undefined);
  const [accessToken, setAccessToken] = useState<any>(undefined);
  const dispatch = useAppDispatch();
  const { data: featureFlags } = useGetTenantFeaturesQuery(undefined, { skip: accessToken === undefined });
  const [apiVersion, setApiVersion] = useState<string>(packageJson.apiVersion);

  useEffect(() => {
    if (featureFlags) {
      const version = apiVersion;
      setApiVersion(version);

      dispatch(setVersion(version));
    }
  }, [featureFlags, apiVersion, dispatch]);

  const revokeAccessToken = useCallback(() => {
    setAccessToken(undefined);
  }, []);

  const getTokenResp = useCallback(
    async (scopes?: string[]) => {
      return await instance
        .acquireTokenSilent({
          scopes: scopes ? scopes : Config.AZURE_AD_CONFIG.backendScopes,
          account: account,
          redirectUri: Config.AZURE_AD_CONFIG.redirectUri,
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            login(account);
          }
        });
    },
    [account, instance, login]
  );

  const parseTokenResp = useCallback(
    async (resp: void | AuthenticationResult, updateToken = false) => {
      if (resp) {
        const result = {
          accessToken: resp.accessToken,
          scopes: resp.scopes,
        };

        if (updateToken) {
          setAccessToken(result.accessToken);
          dispatch(setToken(result.accessToken));

          if (resp.account?.idTokenClaims?.exp) {
            const d = new Date(0);
            d.setUTCSeconds(resp.account?.idTokenClaims?.exp);
            // Give 60 seconds extra leeway to invalidate the token
            setTimeout(revokeAccessToken, d.getTime() - Date.now() - 60 * 1000);
          }
        }

        return Promise.resolve(result.accessToken);
      } else {
        if (updateToken) {
          setAccessToken(undefined);
        }
        throw new Error("There was an error retrieving the token.");
      }
    },
    [revokeAccessToken, dispatch]
  );

  const commServerTokenFactory = useCallback(
    async (scopes?: string[]): Promise<string> => {
      const resp = await getTokenResp(scopes);
      return parseTokenResp(resp);
    },
    [getTokenResp, parseTokenResp]
  );

  const accessTokenFactory = useCallback(
    async (scopes?: string[]): Promise<string> => {
      let decodedToken: any = undefined;
      let expired = false;
      if (accessToken) {
        decodedToken = jwt_decode(accessToken);
        const d = new Date(0);
        d.setUTCSeconds(decodedToken["exp"]);
        const timeDiff = d.getTime() - Date.now();
        expired = timeDiff < 60 * 1000;
        if (!expired) {
          return accessToken;
        }
      }
      const resp = await getTokenResp(scopes);
      return parseTokenResp(resp, !expired);
    },
    [accessToken, getTokenResp, parseTokenResp]
  );

  const contentServer = useMemo(() => {
    return new ContentServer({
      baseURL: Config.BACKEND_BASE_URL,
      accessTokenFactory: () => accessTokenFactory(Config.AZURE_AD_CONFIG.backendScopes),
      apiVersion,
    });
  }, [accessTokenFactory, apiVersion]);

  const commServer = useMemo(() => {
    return new CommServer({
      baseURL: Config.COMM_SERVER_BASE_URL,
      options: {
        accessTokenFactory: () => commServerTokenFactory(Config.AZURE_AD_CONFIG.backendScopes),
      },
    });
  }, [commServerTokenFactory]);

  const messagingServiceStatusManager: IConnectionStatusManager = useMemo(() => {
    return new CommServerConnectionStatusManager({ commServer });
  }, [commServer]);

  const poseHub: IPoseHub = useMemo(() => {
    return new CommServerPoseHub({ commServer });
  }, [commServer]);

  const videoCallHub: IVideoCallHub = useMemo(() => {
    return new CommServerVideoCallHub({ commServer });
  }, [commServer]);

  const chatHub: IChatHub = useMemo(() => {
    return new CommServerChatHub({ commServer });
  }, [commServer]);

  const inspectionUpdateHub: IInspectionUpdateHub = useMemo(() => {
    return new CommServerInspectionUpdateHub({ commServer });
  }, [commServer]);

  useEffect(() => {
    const getIceSevers = async () => {
      const response = await contentServer.getIceServers();
      setIceServers(response);
    };
    if (!iceServers && accessToken && setupComplete) {
      getIceSevers();
    }
  }, [accessToken, contentServer, iceServers, setIceServers, setupComplete]);

  const contextValues: RequestContextType = useMemo(
    () => ({
      contentServer,
      commServer,
      messagingServiceStatusManager,
      poseHub,
      videoCallHub,
      chatHub,
      inspectionUpdateHub,
      iceServers,
    }),
    [
      chatHub,
      commServer,
      contentServer,
      inspectionUpdateHub,
      messagingServiceStatusManager,
      poseHub,
      videoCallHub,
      iceServers,
    ]
  );

  return <RequestContext.Provider value={contextValues}>{props.children}</RequestContext.Provider>;
};

export const RequestContext = React.createContext(defaultContext);
