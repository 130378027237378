import { VideocamOutlined, ArrowBack, Refresh } from "@mui/icons-material";
import { IconButton, Grow, Box, Card, CardHeader, Button, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { getOpenChat, setOpenChat, setChatMsgs } from "redux/features/appSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import useSWR from "swr";
import useSound from "use-sound";

import ARAvatar from "components/avatar/UserAvatar";
import CloseButton from "components/Button/CloseButton";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import { HeaderTabs } from "components/DalmatianDesignComponents/HeaderTabs";
import ContactInfo from "components/Users/ContactInfo";
import ContactsTab from "components/Users/ContactsTab";
import { useAuth } from "hooks/useAuth";
import useChat from "hooks/useChat";
import useMessagingServiceConnectionState from "hooks/useMessagingServiceConnectionState";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUser from "hooks/useUser";
import useUsers from "hooks/useUsers";
import useWebRTC from "hooks/useWebRTC";
import { useCallState } from "hooks/useWebRTCSession";
import { IChatMessage } from "services/Chat/serializers";
import { ConnectionState } from "services/ConnectionStatusManager";
import { ChatGroup, ChatSWRKeys } from "services/ContentServer/Chat";
import { User } from "services/ContentServer/Identity";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { sortObjectsByTime } from "utils/SortingUtils";
import Chat from "views/ChatView/Chat";
import { ChatContext, ProvideChatContext } from "views/ChatView/ChatContext";
import ChatTab from "views/ChatView/ChatTab";

const receivingSound = "/sounds/message_received.mp3";
const NOTIFICATION_VOLUME = 0.25;

const ChatModal = () => {
  const theme = useTheme();
  const openChat = useAppSelector(getOpenChat);
  const dispatch = useAppDispatch();
  const { connectionState, attemptRetryConnection } = useMessagingServiceConnectionState();
  const { contentServer } = useContext(RequestContext);
  const {
    userSelectedId,
    userSelectedName,
    setUserSelectedId,
    setUserSelectedName,
    chatOpen,
    setChatOpen,
    setMessageNotification,
    missedMessages,
    messageNotification,
    unreadMessages,
    setUnreadMessages,
    tab,
    setTab,
    profileOpen,
    setProfileOpen,
    prevLocation,
    setPrevLocation,
    setChatBox,
    chatBox,
    setMissedMessages,
    numNewMsgs,
    setNumNewMsgs,
    currentMessages,
    setCurrentMessages,
    isInitialHistoryFetched,
    setIsInitialHistoryFetched,
    setChatUserList,
    chatUserList,
    setLastMsgs,
    lastMsgs,
  } = useContext(ChatContext);
  const { users } = useUsers();
  const { webRTC } = useWebRTC();
  const callState = useCallState();
  const { user } = useUser(userSelectedId);
  const { chat } = useChat();
  const [playReceivedMessage] = useSound(receivingSound, { volume: NOTIFICATION_VOLUME, interrupt: true });
  const [isInitialChatListFetched, setIsInitialChatListFetched] = useState<boolean>(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);

  const CONNECTION_ALERT_HEIGHT = 65;
  const CHAT_MENU_WIDTH = 375;
  const CHAT_MENU_HEIGHT = 440;
  const USERS_MENU_ICON_BOTTOM = 24;
  const USERS_MENU_ICON_RIGHT = 64;
  const USER_MENU_HEADER_HEIGHT = 60;

  const positionTrack = (e: DraggableEvent, ui: DraggableData): void => {
    setDragging(true);
    setPosition({ x: position.x + ui.deltaX, y: position.y + ui.deltaY });
  };

  const snackbar = useSnackbar();
  const auth = useAuth();

  const { data: unread } = useSWR(
    auth.user ? [ChatSWRKeys.UNREAD_MESSAGES] : null,
    () => (auth.user ? contentServer.chatService.getUnreadMessages() : undefined),
    { shouldRetryOnError: false }
  );
  const { data: initialChatHistory } = useSWR(
    auth.user ? [ChatSWRKeys.CHAT_HISTORY, auth.user] : null,
    () => (auth.user ? contentServer.chatService.getChatHistory(auth.user.id) : undefined),
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    if (!isInitialChatListFetched && openChat && tab === 0) {
      const latestMsgs: string[] = [];
      const sortedChats: ChatGroup[] = [];
      const chatList: User[] = [];
      if (initialChatHistory) {
        const initialChats = [...initialChatHistory].sort((a, b) =>
          sortObjectsByTime(a.messages[0].timestamp, b.messages[0].timestamp)
        );
        initialChats.forEach((group) => {
          sortedChats.push(group);
          group.users.forEach((person) => {
            const found = users.find((u) => u.id === person.id);
            if (auth.user && person.id !== auth.user.id && found !== undefined) {
              chatList.push(found);
              latestMsgs.push(group.messages[0].text);
            }
          });
        });
        setChatUserList(chatList);
        setLastMsgs(latestMsgs);
        setIsInitialChatListFetched(true);
      }
    }
  }, [auth.user, initialChatHistory, users, setChatUserList, isInitialChatListFetched, openChat, tab, setLastMsgs]);

  useEffect(() => {
    let count = 0;
    Object.values(missedMessages).forEach((user) => {
      count += user.length;
    });
    Object.values(unreadMessages).forEach((val) => {
      count += val;
    });
    setNumNewMsgs(count);
    dispatch(setChatMsgs(count));
  }, [missedMessages, unreadMessages, setNumNewMsgs, dispatch]);

  const updateChatUserList = useCallback(
    (message: IChatMessage, msgUserId: string) => {
      if (isInitialHistoryFetched) {
        const msgedUser = users.find((u) => u.id === msgUserId);
        const newChatUserList = [...chatUserList];
        const newMsgList = [...lastMsgs];
        if (msgedUser && chatUserList.find((u) => u.id === msgUserId)) {
          const originalIdx = chatUserList.findIndex((u) => u.id === msgUserId);
          newChatUserList.splice(originalIdx, 1);
          newChatUserList.unshift(msgedUser);
          newMsgList.splice(originalIdx, 1);
          newMsgList.unshift(message.text);
        } else if (msgedUser) {
          newChatUserList.unshift(msgedUser);
          newMsgList.unshift(message.text);
        }
        const finalizedList = new Set([...newChatUserList]);
        setChatUserList(Array.from(finalizedList));
        setLastMsgs(newMsgList);
      }
    },
    [chatUserList, lastMsgs, setChatUserList, setLastMsgs, users, isInitialHistoryFetched]
  );

  useEffect(() => {
    if (chat) {
      const unsubscribe = chat.subscribeToChat((message: IChatMessage) => {
        message.timestamp = new Date().toUTCString();
        let newMessages: IChatMessage[] = [];
        if (message.sender === user?.id) {
          setChatBox((prevChatBox) => {
            if (!Object.prototype.hasOwnProperty.call(chatBox, message.receiver)) {
              prevChatBox[message.receiver] = [message];
            } else {
              if (prevChatBox[message.receiver].find((msg) => msg.id === message.id)) {
                return prevChatBox;
              }
              prevChatBox[message.receiver] = [...prevChatBox[message.receiver], message];
            }
            if (userSelectedId === message.receiver) {
              newMessages = prevChatBox[message.receiver];
            }
            return prevChatBox;
          });
          if (userSelectedId === message.receiver) {
            setCurrentMessages(newMessages);
          }
        }
        if (
          auth.user?.id !== message.sender &&
          userSelectedId !== message.sender &&
          message.sender !== message.receiver
        ) {
          playReceivedMessage();
          setMessageNotification(`${users.find((u) => u.id === message.sender)?.name} says... ${message.text}`);
          setChatBox((prevChatBox) => {
            if (!Object.prototype.hasOwnProperty.call(chatBox, message.sender)) {
              prevChatBox[message.sender] = [message];
            } else {
              if (!userSelectedId) {
                prevChatBox[message.sender] = [...prevChatBox[message.sender], message];
              }
            }
            return prevChatBox;
          });
          setIsInitialHistoryFetched(true);
          updateChatUserList(message, message.sender);
          setMissedMessages((prevMissedMessages) => {
            if (!Object.prototype.hasOwnProperty.call(prevMissedMessages, message.sender)) {
              prevMissedMessages[message.sender] = [message];
            } else {
              prevMissedMessages[message.sender] = [...prevMissedMessages[message.sender], message];
            }
            return prevMissedMessages;
          });
          let count = 0;
          Object.values(missedMessages).forEach((user) => {
            count += user.length;
          });
          Object.values(unreadMessages).forEach((val) => {
            count += val;
          });
          setNumNewMsgs(count);
          dispatch(setChatMsgs(count));
        }
        if (userSelectedId === message.sender) {
          contentServer.chatService.readChatMessages(userSelectedId);
        }
        if (!Object.prototype.hasOwnProperty.call(chatBox, message.sender)) {
          chatBox[message.sender] = [];
          setChatBox(chatBox);
        }
        if (message.receiver === user?.id) {
          updateChatUserList(message, userSelectedId);
        }
      });
      return () => unsubscribe();
    }
  }, [
    dispatch,
    auth.user,
    lastMsgs,
    setLastMsgs,
    chat,
    chatBox,
    setChatBox,
    userSelectedId,
    setMessageNotification,
    missedMessages,
    setChatUserList,
    setMissedMessages,
    chatUserList,
    user,
    users,
    playReceivedMessage,
    contentServer.chatService,
    openChat,
    unreadMessages,
    setNumNewMsgs,
    numNewMsgs,
    setCurrentMessages,
    currentMessages,
    setIsInitialHistoryFetched,
    updateChatUserList,
  ]);

  useEffect(() => {
    if (unread) {
      setUnreadMessages(unread);
    }
  }, [setUnreadMessages, unread]);

  useEffect(() => {
    if (messageNotification && !snackbar.state.open) {
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: messageNotification,
        onClose: () => {
          setMessageNotification("");
        },
      });
    }
  }, [messageNotification, setMessageNotification, snackbar]);

  const handleMenuButton = useCallback(() => {
    dispatch(setOpenChat(!openChat));
    setPosition({ x: 0, y: 0 });
    setDragging(false);
    setTab(0);
    setChatOpen(false);
    setProfileOpen(false);
    setUserSelectedName("");
    setUserSelectedId("");
  }, [setChatOpen, dispatch, openChat, setTab, setProfileOpen, setUserSelectedId, setUserSelectedName]);

  const connectingMessage = (
    <Alert severity="info" style={{ height: CONNECTION_ALERT_HEIGHT, fontSize: "15px" }}>
      Connecting...
    </Alert>
  );

  const reconnectingMessage = (
    <Alert severity="warning" style={{ height: CONNECTION_ALERT_HEIGHT, fontSize: "15px", wordWrap: "break-word" }}>
      <div>Reconnecting...</div>
      <div>Please check your internet connection.</div>
    </Alert>
  );

  const disconnectedMessage = useMemo(
    () => (
      <Alert
        severity="error"
        style={{ height: CONNECTION_ALERT_HEIGHT, fontSize: "15px" }}
        action={
          <Button id="retry" startIcon={<Refresh />} onClick={attemptRetryConnection}>
            RETRY
          </Button>
        }
      >
        <div>Unable to connect. Please</div>
        <div>check your internet connection.</div>
      </Alert>
    ),
    [attemptRetryConnection]
  );

  const ContactsTabComponent = useMemo(() => {
    return <ContactsTab />;
  }, []);

  const ChatTabComponent = useMemo(() => {
    if (isInitialChatListFetched) {
      return <ChatTab />;
    }
  }, [isInitialChatListFetched]);

  const TabComponents = useMemo(() => {
    return [ChatTabComponent, ContactsTabComponent];
  }, [ChatTabComponent, ContactsTabComponent]);

  const chatBounds = {
    left: 0,
    top: -(window.innerHeight - CHAT_MENU_HEIGHT - USERS_MENU_ICON_BOTTOM),
    right: window.innerWidth - CHAT_MENU_WIDTH - USERS_MENU_ICON_RIGHT,
    bottom: 0,
  };

  return (
    <>
      {openChat ? (
        <Draggable
          handle="#draggable-dialog-title"
          cancel='[class*="MuiDialogContent-root"]'
          bounds={chatBounds}
          onDrag={positionTrack}
          position={position}
        >
          <Grow in={openChat} style={{ transformOrigin: "0 100% 0" }} timeout={250}>
            <Card
              sx={{
                overflowY: "hidden",
                height: CHAT_MENU_HEIGHT,
                minWidth: CHAT_MENU_WIDTH,
                maxWidth: CHAT_MENU_WIDTH,
                position: "fixed",
                overflow: "auto",
                bottom: USERS_MENU_ICON_BOTTOM,
                left: USERS_MENU_ICON_RIGHT,
                zIndex: theme.zIndex.modal,
                paddingBottom: "7px",
                transition: dragging ? "0s!important" : "",
              }}
              elevation={1}
            >
              {chatOpen && user ? (
                <CardHeader
                  title={
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      color={theme.palette.common.white}
                      id="draggable-dialog-title"
                    >
                      <IconButton
                        id="back-arrow-to-users-menu"
                        onClick={() => {
                          setChatOpen(false);
                          setTab(0);
                          setUserSelectedName("");
                          setUserSelectedId("");
                        }}
                      >
                        <ArrowBack style={{ color: theme.palette.common.white }} />
                      </IconButton>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        color={theme.palette.common.white}
                        sx={{ margin: "0 auto" }}
                      >
                        <div
                          onClick={() => {
                            setProfileOpen(true);
                            setChatOpen(false);
                            setPrevLocation("chat");
                          }}
                          style={{ width: "40px" }}
                        >
                          <ARAvatar
                            user={users.find((u) => u.id === userSelectedId)}
                            small={true}
                            tooltip={false}
                            noBadge={false}
                            white={true}
                          />
                        </div>
                        <EllipsisTooltip
                          id="user-name"
                          align="center"
                          color="inherit"
                          style={{
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "140%",
                            maxWidth: "170px",
                          }}
                        >
                          {userSelectedName}
                        </EllipsisTooltip>
                      </Box>
                      <IconButton
                        id={`video-call-${user.name.toLowerCase().replace(/ /g, "-")}`}
                        onClick={() => user?.id && webRTC?.call(user.id) && dispatch(setOpenChat(false))}
                        disabled={
                          !user ||
                          !webRTC ||
                          user.id === auth.user?.id ||
                          callState.isCalling ||
                          callState.isInCall ||
                          callState.isReceivingCall
                        }
                      >
                        <VideocamOutlined style={{ fontSize: "25px", color: theme.palette.common.white }} />
                      </IconButton>

                      <IconButton id="close-chat" onClick={handleMenuButton}>
                        <CloseButton />
                      </IconButton>
                    </Box>
                  }
                  sx={{ backgroundColor: theme.palette.primary.main, height: USER_MENU_HEADER_HEIGHT }}
                />
              ) : profileOpen ? (
                <CardHeader
                  sx={{ backgroundColor: theme.palette.primary.main, height: USER_MENU_HEADER_HEIGHT }}
                  title={
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      color={theme.palette.common.white}
                      id="draggable-dialog-title"
                    >
                      <IconButton
                        id="back-arrow-to-users-menu"
                        onClick={() => {
                          setProfileOpen(false);
                          if (prevLocation === "chat") {
                            setChatOpen(true);
                          } else if (prevLocation === "contacts") {
                            setTab(1);
                            setChatOpen(false);
                          } else if (prevLocation === "chatList") {
                            setTab(0);
                            setChatOpen(false);
                          }
                        }}
                      >
                        <ArrowBack style={{ color: theme.palette.common.white }} />
                      </IconButton>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        width="70%"
                        color={theme.palette.common.white}
                        sx={{ margin: "0 auto" }}
                      >
                        <div style={{ width: "40px" }}>
                          <ARAvatar
                            user={users.find((u) => u.id === userSelectedId)}
                            small={true}
                            tooltip={false}
                            noBadge={false}
                            white={true}
                          />
                        </div>

                        <EllipsisTooltip
                          id="user-name"
                          color="inherit"
                          style={{
                            paddingRight: "30px",
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "140%",
                            maxWidth: "170px",
                          }}
                        >
                          {userSelectedName}
                        </EllipsisTooltip>
                      </Box>
                      <IconButton id="close-chat" onClick={handleMenuButton}>
                        <CloseButton />
                      </IconButton>
                    </Box>
                  }
                />
              ) : (
                <CardHeader
                  title={
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        height: USER_MENU_HEADER_HEIGHT,
                      }}
                      id="draggable-dialog-title"
                    >
                      <HeaderTabs tab={tab} setTab={setTab} headers={["Chat", "Contacts"]} whiteStyle={true} />
                      <IconButton id="close-users-menu" onClick={handleMenuButton} style={{ height: "100%" }}>
                        <CloseButton />
                      </IconButton>
                    </Box>
                  }
                  sx={{ backgroundColor: theme.palette.primary.main, height: USER_MENU_HEADER_HEIGHT }}
                />
              )}

              {connectionState === ConnectionState.CONNECTING && connectingMessage}
              {connectionState === ConnectionState.RECONNECTING && reconnectingMessage}
              {connectionState === ConnectionState.DISCONNECTED && disconnectedMessage}
              {connectionState === ConnectionState.CONNECTING ||
              connectionState === ConnectionState.RECONNECTING ||
              connectionState === ConnectionState.DISCONNECTED ? (
                <></>
              ) : chatOpen ? (
                <Chat />
              ) : profileOpen ? (
                <ContactInfo />
              ) : (
                TabComponents[tab]
              )}
            </Card>
          </Grow>
        </Draggable>
      ) : (
        <></>
      )}
    </>
  );
};

const ChatModalWithContext = () => {
  return (
    <ProvideChatContext>
      <ChatModal />
    </ProvideChatContext>
  );
};

export default ChatModalWithContext;
