import React, { useCallback, useMemo } from "react";

const MAX_NUM_OF_CONTEXTS = 5;

export interface WebGLData {
  canvas: HTMLCanvasElement;
  id: number;
}

export interface WebGLContextType {
  getContext: () => WebGLData | undefined;
  freeContext: (dataId: number) => void;
}

const defaultContext: WebGLContextType = {
  getContext: () => {
    return undefined;
  },
  freeContext: (dataId: number) => {},
};

const makeGLData = () => {
  const data: WebGLData[] = [];
  for (let i = 0; i < MAX_NUM_OF_CONTEXTS; i++) {
    const canvas = document.createElement("canvas");

    data.push({ id: i, canvas: canvas });
  }
  return data;
};

export const ProvideWebGLContext = (props: any) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeContexts: WebGLData[] = [];
  const availableContexts: WebGLData[] = makeGLData();

  const getContext = useCallback((): WebGLData | undefined => {
    if (availableContexts.length === 0) {
      return undefined;
    } else {
      const data = availableContexts[0];
      activeContexts.push(data);
      availableContexts.shift();
      return data;
    }
  }, [activeContexts, availableContexts]);

  const freeContext = useCallback(
    (dataId: number | undefined) => {
      const canvasIndex = activeContexts.findIndex((c) => c.id === dataId);
      if (canvasIndex !== -1) {
        const data = activeContexts[canvasIndex];
        availableContexts.push(data);
        activeContexts.splice(canvasIndex, 1);
      }
    },
    [activeContexts, availableContexts]
  );

  const contextValues: WebGLContextType = useMemo(
    () => ({
      getContext: getContext,
      freeContext: freeContext,
    }),
    [freeContext, getContext]
  );
  return <WebGLContext.Provider value={contextValues}>{props.children}</WebGLContext.Provider>;
};

export const WebGLContext = React.createContext(defaultContext);
