import { Object3D, Vector3, Mesh } from "three";

const _v1 = /*@__PURE__*/ new Vector3();
const _v2 = /*@__PURE__*/ new Vector3();

class LOD extends Object3D {
  constructor() {
    super();

    this._currentLevel = 0;

    this.type = "LOD";

    this.offset = new Vector3();

    Object.defineProperties(this, {
      levels: {
        enumerable: true,
        value: [],
      },
      isLOD: {
        value: true,
      },
    });

    this.autoUpdate = true;
  }

  copy(source) {
    super.copy(source, false);

    const levels = source.levels;

    for (let i = 0, l = levels.length; i < l; i++) {
      const level = levels[i];

      this.addLevel(level.object.clone(), level.distance);
    }

    this.autoUpdate = source.autoUpdate;

    return this;
  }

  calculateCenterOfBounds(mesh) {
    mesh.geometry.computeBoundingBox();

    const boundingBox = mesh.geometry.boundingBox;

    const position = new Vector3();
    position.subVectors(boundingBox.max, boundingBox.min);
    position.multiplyScalar(0.5);
    position.add(boundingBox.min);

    position.applyMatrix4(mesh.matrixWorld);

    return position;
  }

  addLevel(object, distance = 0) {
    distance = Math.abs(distance);

    const levels = this.levels;

    let l;

    for (l = 0; l < levels.length; l++) {
      if (distance < levels[l].distance) {
        break;
      }
    }

    levels.splice(l, 0, { distance: distance, object: object });

    this.add(object);

    const positions = [];
    object.traverse((child) => {
      if (child instanceof Mesh) {
        positions.push(this.calculateCenterOfBounds(child));
      }
    });

    let x = 0;
    let y = 0;
    let z = 0;
    let total = 0;

    positions.forEach((pos) => {
      if (!isNaN(pos.x) && !isNaN(pos.y) && !isNaN(pos.z)) {
        x += pos.x;
        y += pos.y;
        z += pos.z;
        total++;
      }
    });

    if (total !== 0) {
      x = x / total;
      y = y / total;
      z = z / total;

      this.offset.set(x, y, z);
    }

    return this;
  }

  getCurrentLevel() {
    return this._currentLevel;
  }

  getObjectForDistance(distance) {
    const levels = this.levels;

    if (levels.length > 0) {
      let i, l;

      for (i = 1, l = levels.length; i < l; i++) {
        if (distance < levels[i].distance) {
          break;
        }
      }

      return levels[i - 1].object;
    }

    return null;
  }

  raycast(raycaster, intersects) {
    const levels = this.levels;

    if (levels.length > 0) {
      _v1.setFromMatrixPosition(this.matrixWorld);
      _v1.add(this.offset);

      const distance = raycaster.ray.origin.distanceTo(_v1);

      this.getObjectForDistance(distance).raycast(raycaster, intersects);
    }
  }

  update(camera) {
    const levels = this.levels;

    if (levels.length > 1) {
      _v1.setFromMatrixPosition(camera.matrixWorld);
      _v2.setFromMatrixPosition(this.matrixWorld);
      _v2.add(this.offset);

      if (camera.name !== "sceneCamera") {
        return;
      }

      const distance = _v1.distanceTo(_v2) / camera.zoom;

      levels[0].object.visible = true;

      let i, l;

      for (i = 1, l = levels.length; i < l; i++) {
        if (distance >= levels[i].distance) {
          levels[i - 1].object.visible = false;
          levels[i].object.visible = true;
        } else {
          break;
        }
      }

      this._currentLevel = i - 1;

      for (; i < l; i++) {
        levels[i].object.visible = false;
      }
    }
  }

  toJSON(meta) {
    const data = super.toJSON(meta);

    if (this.autoUpdate === false) data.object.autoUpdate = false;

    data.object.levels = [];

    const levels = this.levels;

    for (let i = 0, l = levels.length; i < l; i++) {
      const level = levels[i];

      data.object.levels.push({
        object: level.object.uuid,
        distance: level.distance,
      });
    }

    return data;
  }
}

export { LOD };
