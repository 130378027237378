import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUpdateUserMutation } from "redux/api/userApi";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { Role, RoleIDList, User } from "services/ContentServer/Identity";
import { matchSorter } from "utils/SortRowsUtils";

const RoleUsersDialog = ({
  role,
  open,
  setOpen,
  setProcessing,
  setProcessingMsg,
}: {
  role: Role;
  open: boolean;
  setOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  setProcessing: (value: boolean | ((prev: boolean) => boolean)) => void;
  setProcessingMsg: (value: string | ((prev: string) => string)) => void;
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const { users } = useUsers();
  const [query, setQuery] = useState<string>("");
  const [addedUsers, setAddedUsers] = useState<User[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [unassignedUsers, setUnassignedUsers] = useState<User[]>([]);
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (open) {
      setUnassignedUsers(Object.values(users).filter((user) => user.roles.find((id) => id === role.id) === undefined));
    }
  }, [role.id, users, open]);

  const updateAddedUsers = useCallback(
    (user: User) => {
      let newUsersState = [...addedUsers];
      if (addedUsers.includes(user)) {
        newUsersState = newUsersState.filter((prevUser) => prevUser.id !== user.id);
      } else {
        newUsersState.push(user);
      }
      setAddedUsers(newUsersState);
    },
    [addedUsers]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setQuery("");
    setAllSelected(false);
    setAddedUsers([]);
    setUnassignedUsers([]);
  }, [setOpen]);

  const addUser = useCallback(
    async (user: User) => {
      const updatedUser = { ...user };
      const updatedRoleList: RoleIDList = [...user.roles];
      if (!updatedRoleList.find((id) => id === role.id)) {
        updatedRoleList.push(role.id);
      }
      updatedUser.roles = updatedRoleList;
      try {
        updateUser({ id: user.id, user: updatedUser, updatedRoles: true });
      } catch (err) {
        console.error(err);
      }
    },
    [updateUser, role]
  );

  const addAllUsers = useCallback(async () => {
    setProcessing(true);
    setProcessingMsg("Adding users to role...");
    try {
      addedUsers.forEach(async (addedUser) => {
        await addUser(addedUser);
      });
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Successfully added users to role.",
      });
    } catch (err) {
      console.error(err);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error adding users to role.",
      });
    }
    setProcessing(false);
    setProcessingMsg("");
    handleClose();
  }, [setProcessing, setProcessingMsg, handleClose, snackbar, addedUsers, addUser]);

  const applyFilters = useCallback(
    (rows: User[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const userRows = useMemo(() => {
    return applyFilters(unassignedUsers);
  }, [applyFilters, unassignedUsers]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
            width: "100%",
            minHeight: "70%",
          },
          width: "100%",
        }}
      >
        <DialogTitle
          style={{ width: "100%", padding: "24px 0px 16px 0px", borderBottom: "1px solid rgba(212, 214, 217, 0.7)" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography variant="h4">{`Add Users to ${role.name} Role`}</Typography>
            <IconButton color="primary" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flexStart",
            padding: "0px",
            gap: "24px",
          }}
        >
          <SearchBar placeholderText={"Search users..."} query={query} setQuery={setQuery} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px 8px 0px 0px",
              gap: "16px",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px",
                gap: "16px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0px",
                  gap: "8px",
                }}
              >
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <PeopleOutlinedIcon sx={{ color: theme.palette.primary.contrastText }} />
                </Avatar>
                <Typography variant="body1">Select All</Typography>
              </Box>
              <IconButton
                color="primary"
                onClick={() => {
                  setAllSelected(!allSelected);
                  setAddedUsers(allSelected ? [] : Object.values(unassignedUsers));
                }}
                size="large"
              >
                {allSelected ? <CheckCircleIcon /> : <AddCircleOutlineOutlinedIcon />}
              </IconButton>
            </Box>
            {userRows.map((user) => {
              return (
                <Box
                  key={user.id}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0px",
                    gap: "16px",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "0px",
                      gap: "8px",
                    }}
                  >
                    {user.userProfile.profilePicture !== "" ? (
                      <Avatar
                        sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}
                        src={user.userProfile.profilePicture}
                      />
                    ) : (
                      <Avatar sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}>
                        <UserPlaceholderIcon />
                      </Avatar>
                    )}
                    <Typography variant="dalmatianP">{user.name}</Typography>
                  </Box>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      updateAddedUsers(user);
                    }}
                    size="large"
                  >
                    {addedUsers.includes(user) ? <CheckCircleIcon /> : <AddCircleOutlineOutlinedIcon />}
                  </IconButton>
                </Box>
              );
            })}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: "0px",
              gap: "16px",
              width: "100%",
            }}
          >
            <BaseButton variant={"outlined"} onClick={handleClose}>
              Cancel
            </BaseButton>
            <BaseButton
              variant={"contained"}
              onClick={async () => {
                await addAllUsers();
              }}
            >
              Add
            </BaseButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RoleUsersDialog;
