import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof unknown | undefined } = {};

export class TenantFeatures extends ISerialization {
  constructor(_features?: TenantFeatures) {
    super();
  }

  deserialize(json: any): void {
    deserializerHelper<TenantFeatures>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<TenantFeatures>(this, serverToView);
    return request;
  }
}
