import { gql } from "graphql-request";

import { gqlApi } from "./gql/gqlApi";
import { Matrix4 } from "services/ContentServer/Audit/types";

export interface ScanParams {
  id: string;
}

export interface ScanAnalyticResponse {
  scanPath: string;
  rotations: string;
  projectionMatrix: Matrix4;
}

const deserializeAnalytic = (analytic: any) => {
  const projMatrix = JSON.parse(analytic.projectionMatrix);
  return {
    scanPath: analytic.scanPath,
    rotations: analytic.rotations,
    projectionMatrix: projMatrix,
  };
};

export const scanApi = gqlApi.injectEndpoints({
  endpoints: (builder) => ({
    getScanAnalytics: builder.query<ScanAnalyticResponse, ScanParams>({
      query: (param: ScanParams) => ({
        document: gql`
          query {
            scanAnalytic(filters: { snapshot: { pk: "${param.id}" } }) {
              scanPath
              rotations
              projectionMatrix
            }
          }
        `,
      }),
      transformResponse: async (response: any) => {
        return deserializeAnalytic(response.scanAnalytic[0]);
      },
    }),
  }),
});

export const { useGetScanAnalyticsQuery } = scanApi;
