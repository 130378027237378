export const ENDPOINTS = {
  USERSDISPLAY: "/api/usersdisplay/",
  USERS: "/api/users/",
  USERPROFILE: "/api/userprofile/",
  USERROLES: "/api/userroles/",
  PERMISSIONS: "/api/userpermissions/",
  CREATEINVITE: "/api/createinvite/",
  BULKINVITE: "/api/bulkinvite/",
  USERINVITE: "/api/userinvite/",
  USERLOGS: "/api/userlogs/",
  USERSETUP: "/api/usersetup/",
  EXPORTCSV: "/api/exportuserroles/",
};

export const UsersSWRKeys = {
  USERS_DISPLAY: "usersdisplay",
  USERS: "users",
  ROLES: "userroles",
  PERMISSIONS: "permissions",
  USERPERMISSIONS: "userpermissions",
};
