import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useCallback, useMemo, useState } from "react";

import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import { User } from "services/ContentServer/Identity";
import { matchSorter } from "utils/SortRowsUtils";

interface IAddUsersDialogProps {
  itemLabel: string;
  itemsLabel: string;
  itemName: string;
  availableUsers: User[];
  open: boolean;
  onClose: () => void;
  onAdd: (addUsers: "all" | User[]) => void;
}
const AddUsersDialog: React.FC<IAddUsersDialogProps> = ({
  itemLabel,
  itemsLabel,
  itemName,
  availableUsers,
  open,
  onClose,
  onAdd,
}) => {
  const theme = useTheme();
  const [query, setQuery] = useState<string>("");
  const [addedUsers, setAddedUsers] = useState<User[]>([]);
  const [allSelected, setAllSelected] = useState(false);

  const toggleUser = (user: User) => {
    setAllSelected(false);

    let newUsersState = [...addedUsers];
    if (addedUsers.includes(user)) {
      newUsersState = newUsersState.filter((prevUser) => prevUser.id !== user.id);
    } else {
      newUsersState.push(user);
    }
    setAddedUsers(newUsersState);
  };

  const applyFilters = useCallback(
    (rows: User[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const userRows = useMemo(() => {
    return applyFilters(availableUsers);
  }, [applyFilters, availableUsers]);

  return (
    <>
      <Dialog
        open={open}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            pt: 3,
            pb: 0,
            px: 0,
            width: "100%",
            minHeight: "70%",
            maxHeight: "70%",
          },
          width: "100%",
        }}
      >
        <DialogTitle sx={{ width: "100%", pt: 0, pb: 2, px: 3, borderBottom: "1px solid rgba(212, 214, 217, 0.7)" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <EllipsisTooltip variant="h4">{`Add Users to ${itemName} ${itemLabel}`}</EllipsisTooltip>
            <IconButton color="primary" onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flexStart",
            p: 0,
          }}
        >
          <Box
            sx={{
              p: 3,
              width: "100%",
            }}
          >
            <SearchBar placeholderText={`Search ${itemsLabel}...`} query={query} setQuery={setQuery} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              pr: 1,
              pl: 3,
              width: "100%",
              overflowY: "scroll",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: 1,
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#F6F6F6",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  pl: 0.5,
                  gap: "8px",
                }}
              >
                <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "30px", height: "30px" }}>
                  <PeopleOutlinedIcon sx={{ fontSize: "20px", color: theme.palette.primary.contrastText }} />
                </Avatar>
                <Typography variant="body1">Select All</Typography>
              </Box>
              <IconButton
                color="primary"
                sx={{
                  padding: "3px",
                  svg: { width: "24px", height: "24px" },
                }}
                onClick={() => {
                  setAllSelected(!allSelected);
                  setAddedUsers(allSelected ? [] : Object.values(availableUsers));
                }}
                size="large"
              >
                {allSelected ? <CheckCircleIcon /> : <AddCircleOutlineOutlinedIcon />}
              </IconButton>
            </Box>
            {userRows.map((user) => {
              return (
                <Box
                  key={user.id}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 1,
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#F6F6F6",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "90%",
                      maxWidth: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "3px",
                      gap: "8px",
                    }}
                  >
                    {user.userProfile.profilePicture !== "" ? (
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.light,
                          color: "#404245",
                          width: "30px",
                          height: "30px",
                        }}
                        src={user.userProfile.profilePicture}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.light,
                          color: "#404245",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <UserPlaceholderIcon />
                      </Avatar>
                    )}
                    <EllipsisTooltip variant="body1">{user.name}</EllipsisTooltip>
                  </Box>
                  <IconButton
                    color="primary"
                    sx={{
                      padding: "3px",
                      svg: { width: "24px", height: "24px" },
                    }}
                    onClick={() => {
                      toggleUser(user);
                    }}
                    size="large"
                  >
                    {addedUsers.includes(user) ? <CheckCircleIcon /> : <AddCircleOutlineOutlinedIcon />}
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            pr: 3,
            py: 3,
            gap: 2,
            width: "100%",
          }}
        >
          <Button variant={"outlined"} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={"contained"}
            onClick={async () => {
              onAdd(allSelected ? "all" : addedUsers);
              onClose();
            }}
          >
            Add
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default AddUsersDialog;
