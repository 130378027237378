import {
  DialogActions,
  Button,
  DialogTitle,
  Dialog,
  AppBar,
  DialogContent,
  Typography,
  Snackbar,
  Portal,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useTheme } from "@mui/material/styles";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import { SwitchProps } from "react-router-dom";
import { StickyContainer } from "react-sticky-17";
import { useGetTenantQuery } from "redux/api/tenantApi";
import { useUpdateUserProfileDataMutation } from "redux/api/userApi";
import { setSelectedNavItem } from "redux/features/appSlice";
import { getToken } from "redux/features/authSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import useApp from "redux/hooks/useApp";
import { mutate } from "swr";

const terms = "/terms/terms.txt";
import LinearProgress from "../CustomProgress/LinearProgress";
import routes from "../Routes";
import {
  autoHideDuration,
  snackbarAnchorOrigin,
  snackbarCloseAction,
} from "assets/styles/webportal/components/snackbarStyle";
import ARCopyright from "components/ARCopyright";
import { CommonPageContext, ProvideCommonPageContext } from "components/CommonPage/CommonPageContext";
import PersistentDialog from "components/Dialog/PersistentDialog";
import { useAuth } from "hooks/useAuth";
import useConnectionUpdate from "hooks/useConnectionUpdate";
import { useRouter } from "hooks/useRouter";
import { useServerStatus } from "hooks/useServerStatus";
import { useSnackbar, ProvideSnackbar, SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { storeCurrentPath } from "services/AzureAD/LocalRedirectUrlStorage";
import { MESSAGE_TYPE } from "services/CommServer";
import { MapSWRKeys } from "services/ContentServer/Map";
import { useUpdateHandler } from "services/InspectionUpdate/useUpdateHandler";
import { UpdateMessage } from "services/MessageHub";
import { MS_IN_SEC, SEC_IN_MIN } from "utils/ConversionConstants";
import { removeTrailingSlash } from "utils/StringUtils";
import ARNav from "views/Audit/Layout/Nav";
import NotFoundPage from "views/NotFoundPage/NotFoundPage";
import VideoCall from "views/VideoCallView/VideoCall";

const PROMPT_TIMER = MS_IN_SEC * 10;

function CommonPage({ location, children }: SwitchProps) {
  const { user, setupComplete, logout } = useAuth();
  const { currentUser, featureAccess } = useUsers();
  const { isPageLoading, isAlignmentPage } = useContext(CommonPageContext);
  const { available, error } = useServerStatus();
  const { history, query } = useRouter();
  const snackbar = useSnackbar();
  const [activeTime, setActiveTime] = useState<Date>(new Date());
  const [currentTimeLeft, setCurrentTimeLeft] = useState<number>(0);
  const [idleOpen, setIdleOpen] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const [termsText, setTermsText] = useState<string>("");
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const { data: tenantInfo } = useGetTenantQuery(user?.tenantId ?? "");
  const app = useApp();
  const dispatch = useAppDispatch();
  const [updateUserProfile] = useUpdateUserProfileDataMutation();
  const theme = useTheme();

  useEffect(() => {
    dispatch(setSelectedNavItem(location?.pathname || ""));
  }, [location?.pathname, dispatch]);

  const updateSites = useCallback(() => {
    mutate(MapSWRKeys.SITES);
  }, []);

  useUpdateHandler(MESSAGE_TYPE.SITE_UPDATE, updateSites);

  const setTermsCheckBoxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsChecked(event.target.checked);
  }, []);

  useConnectionUpdate();

  const tenantIdleTimer = useMemo(() => {
    if (tenantInfo) {
      return tenantInfo.idleTimeout * SEC_IN_MIN * MS_IN_SEC;
    }
    return 30 * SEC_IN_MIN * MS_IN_SEC;
  }, [tenantInfo]);

  useEffect(() => {
    if (currentUser && !currentUser.userProfile.readTerms) {
      setTermsOpen(true);
    }
    return () => setTermsOpen(false);
  }, [currentUser]);

  useEffect(() => {
    fetch(terms)
      .then((r) => r.text())
      .then((text) => {
        setTermsText(text);
      });
  }, []);

  const onTermsRead = useCallback(async () => {
    if (termsChecked && currentUser && currentUser.userProfile) {
      updateUserProfile({
        id: currentUser.userProfile.id,
        data: {
          userId: currentUser.id,
          read_terms: true,
        },
      });
    }

    setTermsOpen(false);
  }, [currentUser, termsChecked, updateUserProfile]);

  useEffect(() => {
    if (error) {
      history.push("/unavailable");
    }
  }, [available, error, history]);

  const handleUserDisabled = useCallback(
    (update: UpdateMessage) => {
      if (update.user_id === currentUser?.id) {
        logout();
      }
    },
    [currentUser, logout]
  );

  useUpdateHandler(MESSAGE_TYPE.USER_DISABLED, handleUserDisabled);

  const route = useMemo(() => {
    const pathName = removeTrailingSlash(location?.pathname || "");
    return Object.values(routes).find((r) => pathName.startsWith(r.path));
  }, [location]);

  const isDalmatianDesign = useMemo(() => {
    if (route?.dalmatianDesign) {
      return true;
    }
    return false;
  }, [route]);

  const canView = useMemo(() => {
    if (route) {
      if (route.requiredPermissions) {
        return route.requiredPermissions.filter((perm) => featureAccess[perm].read).length;
      }
      return true;
    } else {
      return false;
    }
  }, [featureAccess, route]);

  const pageEnabled = useMemo(() => {
    if (!route?.featureFlagName || app.isFeatureEnabled(route?.featureFlagName)) {
      return true;
    }

    return false;
  }, [app, route]);

  const handleOnIdle = () => {
    setIdleOpen(false);
    reset();
    logout();
  };

  const openPrompt = useCallback(() => {
    setCurrentTimeLeft(PROMPT_TIMER / MS_IN_SEC);
    setActiveTime(new Date());
    setIdleOpen(true);
  }, []);

  const { reset } = useIdleTimer({
    timers: workerTimers,
    timeout: tenantIdleTimer,
    promptTimeout: PROMPT_TIMER,
    onPrompt: openPrompt,
    onIdle: handleOnIdle,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    crossTab: true,
    throttle: 1000,
    syncTimers: 1,
  });

  const calculateTimeLeft = useCallback(() => {
    const difference = PROMPT_TIMER - (new Date().getTime() - activeTime.getTime());
    return (difference - (difference % MS_IN_SEC)) / MS_IN_SEC + 1;
  }, [activeTime]);

  useEffect(() => {
    if (idleOpen) {
      const timeout = setTimeout(() => {
        setCurrentTimeLeft(calculateTimeLeft());
      }, MS_IN_SEC);

      return () => {
        clearTimeout(timeout);
      };
    }
  });

  const resetIdleTime = useCallback(() => {
    setIdleOpen(false);
    reset();
  }, [reset]);

  useEffect(() => {
    if (currentTimeLeft < 0) {
      setIdleOpen(false);
      reset();
      logout();
    }
  }, [currentTimeLeft, logout, reset]);

  useEffect(() => {
    reset();
  }, [reset]);

  return !setupComplete ? (
    <PersistentDialog
      open={!setupComplete}
      dialogTitle="Initial User Setup"
      dialogContent="Performing first time user setup, please be patient."
    />
  ) : currentUser ? (
    <StickyContainer>
      <Dialog open={idleOpen} aria-labelledby="pose-limit-dialog-title">
        <DialogTitle>
          <b>{`Are you still here?`}</b>
        </DialogTitle>
        <DialogContent>
          <Typography>{`You will be automatically logged out in ${currentTimeLeft} seconds...`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={resetIdleTime}>
            Continue Session
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={termsOpen} aria-labelledby="pose-limit-dialog-title">
        <DialogContent>
          <Typography>{termsText}</Typography>
        </DialogContent>
        <DialogActions>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={termsChecked} onChange={setTermsCheckBoxChange} />}
              label="Do not show message again in the future"
            />
          </FormGroup>
          <Button variant="contained" color="primary" onClick={onTermsRead}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          background: isDalmatianDesign ? "white" : "",
          display: "flex",
          fontSize: "large",
          width: "auto",
          padding: 0,
          overflowX: "hidden",
          zIndex: 0,
        }}
      >
        {isPageLoading && (
          <AppBar sx={{ boxShadow: "none" }}>
            <LinearProgress color="secondary" />
          </AppBar>
        )}

        <Portal>
          <Snackbar
            anchorOrigin={snackbarAnchorOrigin}
            sx={{
              zIndex: 1600,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "right",
              textAnchor: "middle",
              color: "primary",
            }}
            key="traverser-snackbar"
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={
              snackbar.state.children ? snackbar.state.children : <span id="message-id">{snackbar.state.message}</span>
            }
            open={snackbar.state.open}
            autoHideDuration={snackbar.state.autoHide ? autoHideDuration : null}
            onClose={() => {
              snackbar.state.onClose();
              snackbar.dispatch({ type: SnackbarActionType.RESET });
            }}
            action={snackbarCloseAction(() => {
              snackbar.state.onClose();
              snackbar.dispatch({ type: SnackbarActionType.RESET });
            }, theme.palette.primary.contrastText)}
          />
        </Portal>

        {!isAlignmentPage && <ARNav />}

        <main
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflowY: "hidden",
            position: "static",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          {canView && pageEnabled ? children : <NotFoundPage />}
          {!isDalmatianDesign && !query.name && (
            <div style={{ padding: 0 }}>
              <ARCopyright
                name={"Interaptix"}
                linkTo={"http://interaptix.com/"}
                overlay={false}
                fullscreen={isAlignmentPage}
              />
            </div>
          )}
        </main>
      </div>
    </StickyContainer>
  ) : (
    <></>
  );
}

// mike > used the wrapper component to make sure token and user are available before render any protected routes
const GetReady = ({ children }: SwitchProps) => {
  const token = useAppSelector(getToken);
  const { login, getAccounts, user, readyToFetchData, userPermError } = useAuth();
  const { history } = useRouter();
  useEffect(() => {
    if (!user) {
      const accounts = getAccounts(true);

      if (accounts.length === 1) {
        login(accounts[0]);
      } else {
        storeCurrentPath();
        history.push("/login");
      }
    }
    if (userPermError && "status" in userPermError && (userPermError as FetchBaseQueryError).status == 403) {
      history.push("/invalidpermissions");
    }
  }, [user, history, getAccounts, login, userPermError]);

  return readyToFetchData && token ? <>{children}</> : <LinearProgress color="secondary" />;
};

const CommonPageWithContext = ({ location, children }: SwitchProps) => {
  return (
    <ProvideCommonPageContext>
      <ProvideSnackbar>
        <GetReady>
          <CommonPage location={location}>{children}</CommonPage>
          {process.env.REACT_APP_ENV !== "test" && <VideoCall />}
        </GetReady>
      </ProvideSnackbar>
    </ProvideCommonPageContext>
  );
};

export default CommonPageWithContext;
