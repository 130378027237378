import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select, Button, SelectChangeEvent } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

const SelectFieldForm = ({
  id,
  value,
  choices,
  label,
  onChange,
  disabled = false,
  required = false,
  addNewEnabled = false,
  addNewText = "Add new",
  onAddNewClick,
}: {
  id?: string;
  value: any;
  choices?: string[] | number[];
  label?: string;
  onChange?: (event: SelectChangeEvent<unknown>) => void;
  disabled?: boolean;
  required?: boolean;
  addNewEnabled?: boolean;
  addNewText?: string;
  onAddNewClick?: () => void;
}) => {
  const theme = useTheme();
  const menuRef = useRef<HTMLHRElement | null>(null);
  const [currentValue, setCurrentValue] = useState(value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const updateValue = (event: SelectChangeEvent<unknown>) => {
    setCurrentValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <FormControl variant="outlined" required={required} onClick={handleClick}>
      <InputLabel sx={{ color: "black" }}>{label}</InputLabel>
      <Select
        id={id}
        ref={menuRef}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            // borderColor: "transparent",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
            borderWidth: "1px",
          },
          width: "100px",
          height: "40px",
          borderRadius: "0px!important",
        }}
        required={required}
        value={currentValue}
        variant="outlined"
        onChange={updateValue}
        disabled={disabled}
        label={label}
        IconComponent={() => (
          <Box
            style={{
              paddingRight: "8px",
            }}
          >
            <ExpandMoreIcon
              style={{
                color: theme.palette.dalmatianDesign.dark,
                transform: open ? "rotate(180deg)" : undefined,
              }}
            />
          </Box>
        )}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          anchorEl: menuRef.current,
          open: open,
          PaperProps: {
            style: {
              borderRadius: 0,
              boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "8px",
              marginLeft: "50px",
            },
          },
        }}
        SelectDisplayProps={{
          style: { backgroundColor: "transparent", padding: "8px 0px 8px 12px" },
        }}
      >
        {choices?.map((choice, index) => (
          <MenuItem
            id={`select-${index}`}
            key={`${choice}-${index}`}
            value={index}
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "140%",
              color: theme.palette.dalmatianDesign.dark,
            }}
          >
            {choice}
          </MenuItem>
        ))}
        {addNewEnabled && <Button onClick={onAddNewClick}>{addNewText}</Button>}
      </Select>
    </FormControl>
  );
};

export default SelectFieldForm;
