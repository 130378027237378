import { EffectCallback, DependencyList, useRef, useEffect } from "react";

const useEffectWhen = (effect: EffectCallback, deps: DependencyList, whenDeps: DependencyList) => {
  const whenRef = useRef(whenDeps || []);
  const initial = whenRef.current === whenDeps;
  const whenDepsChanged = initial || !whenRef.current.every((w, i) => w === whenDeps[i]);
  whenRef.current = whenDeps;
  const nullDeps = deps.map(() => null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(whenDepsChanged ? effect : () => {}, whenDepsChanged ? deps : nullDeps);
};

export default useEffectWhen;
