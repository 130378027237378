import { getMarkerDataFromJSON, getConnectionUpdateFromJSON } from "../serializers";
import { IPoseHub, ConnectionUpdate, MarkerData } from "../types";
import { ICommServer, MESSAGE_TYPE } from "services/CommServer";

export class CommServerPoseHub implements IPoseHub {
  private commServer?: ICommServer;

  constructor({ commServer }: { commServer?: ICommServer }) {
    this.commServer = commServer;
  }

  subscribeToPoses(callback: (value: MarkerData) => void): () => void {
    if (!this.commServer) {
      return () => {};
    }

    const wrapper = (poseJSON: string) => {
      if (poseJSON) {
        const poseData = getMarkerDataFromJSON(poseJSON);
        if (poseData) {
          callback(poseData);
        }
      }
    };
    return this.commServer.subscribe(MESSAGE_TYPE.POSE, wrapper);
  }

  subscribeToClientConnections(callback: (value: ConnectionUpdate) => void): () => void {
    if (!this.commServer) {
      return () => {};
    }

    const wrapper = (connectionUpdateJSON: string) => {
      if (connectionUpdateJSON) {
        const connectionUpdate = getConnectionUpdateFromJSON(connectionUpdateJSON);
        if (connectionUpdate) {
          callback(connectionUpdate);
        }
      }
    };
    return this.commServer.subscribe(MESSAGE_TYPE.CLIENT_CONNECTION, wrapper);
  }
}
