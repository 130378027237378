import { Avatar, AvatarProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useMemo } from "react";

import ARBadge from "./ARBadge";
import { User, Status } from "services/ContentServer/Identity";

interface ARAvatarProps extends AvatarProps {
  user: User | null | undefined;
  small?: boolean;
  xsmall?: boolean;
  large?: boolean;
  noBadge?: boolean;
  white?: boolean;
  contrast?: boolean;
}

const ARAvatar = ({
  white = false,
  contrast = false,
  user,
  small = false,
  xsmall = false,
  large = false,
  noBadge = false,
  ...other
}: ARAvatarProps) => {
  const avatarSize = useMemo(() => {
    if (xsmall) return "24px";
    else if (small) return "30px";
    else if (large) return "100px";
    else return "auto";
  }, [small, xsmall, large]);

  const transformScale = useMemo(() => {
    if (xsmall) return "scale(0.8)";
    else if (large) return "scale(3)";
    else return "scale(1)";
  }, [xsmall, large]);

  const theme = useTheme();

  return (
    <ARBadge
      invisible={noBadge}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      userStatus={user?.status || Status.Inactive}
    >
      <Avatar src={user?.userProfile?.profilePicture} sx={{ width: avatarSize, height: avatarSize }} {...other}>
        <div
          style={{
            backgroundColor: contrast ? theme.palette.common.white : grey[200],
            display: "flex",
            padding: "10px",
            borderRadius: "100%",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <UserPlaceholderIcon
            sx={{
              transform: transformScale,
              filter: white
                ? "invert(100%) sepia(93%) saturate(2%) hue-rotate(256deg) brightness(100%) contrast(100%)"
                : "none",
            }}
          />
        </div>
      </Avatar>
    </ARBadge>
  );
};

export default ARAvatar;
