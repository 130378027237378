import Config from "Config";

import { DEFAULT_CONFIG } from "./constants";

const backendScopes = Config.AZURE_AD_CONFIG.backendScopes;
const authorities = Config.AZURE_AD_CONFIG.authorities;
const redirectUri = Config.AZURE_AD_CONFIG.redirectUri;
const redirectState = "redirect=" + window.location.origin;

export const msalConfig = {
  ...DEFAULT_CONFIG,
  auth: {
    ...DEFAULT_CONFIG.auth,
    ...Config.AZURE_AD_CONFIG,
    authority: Config.AZURE_AD_CONFIG.authorities.signUpSignIn,
    postLogoutRedirectUri: window.location.origin,
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const loginRequest = {
  authority: authorities.signUpSignIn,
  scopes: backendScopes,
  redirectUri: redirectUri,
  state: redirectState,
};

export const editProfileRequest = {
  scopes: backendScopes,
  authority: authorities.editProfile,
  redirectUri: redirectUri,
  state: redirectState,
};

export const passwordResetRequest = {
  scopes: backendScopes,
  authority: authorities.passwordReset,
  redirectUri: redirectUri,
  state: redirectState,
};

export const inviteRequest = {
  scopes: backendScopes,
  authority: authorities.invite,
  redirectUri: redirectUri,
  state: redirectState,
};
