import { useTheme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import React from "react";

/****************************
 * FieldMessage
 * Desc - display erorr message
 ****************************/
const FieldMessage = (props: TypographyProps) => {
  const theme = useTheme();
  const { children, ...other } = props;

  if ((typeof children === "string" && children.trim().length) || typeof children === "object") {
    return (
      <Typography
        {...other}
        variant="body2"
        style={{
          color: theme.palette.error.main,
          paddingTop: "2%",
          ...other.style,
        }}
      >
        {children}
      </Typography>
    );
  }

  return <></>;
};

export default FieldMessage;
