import React, { useCallback, useEffect, useRef, useState } from "react";

import { DRAWING_COLORS } from "components/CameraSelector/Constants";
import DrawingCanvas from "components/canvas/DrawingCanvas";
import { DrawingOptions } from "components/drawer/types";
import { useResizeObserver } from "hooks/useResizeObserver";
import { Point2D } from "services/ContentServer/Audit/types";
import { DataChannelMessageType } from "services/WebRTC/constants";
import { StrokeOptions } from "utils/Drawing";
import { useDrawing2D } from "utils/Drawing/useDrawing2D";
import { ModelInteractionMode } from "views/inspection/InspectionPage/modelInteractionReducer";

enum DrawingMessageType {
  Clear = "clear",
  Undo = "undo",
  RemoveCursor = "remove cursor",
}

const POINTS_BUFFER_SIZE = 1000;

const VideoCallCanvas = ({
  video,
  drawingCount,
  setDrawingCount,
  setClearDisabledHandler,
  setUndoDisabledHandler,
  setClearHandler,
  setUndoHandler,
  cursorDisabled,
  sendData,
  drawingOptions,
}: {
  video: HTMLVideoElement;
  drawingCount: number;
  setDrawingCount: (drawingCount: number | ((prevDrawingCount: number) => number)) => void;
  setClearDisabledHandler: (value: boolean) => void;
  setUndoDisabledHandler: (value: boolean) => void;
  setClearHandler: (handler: () => void) => void;
  setUndoHandler: (handler: () => void) => void;
  cursorDisabled: boolean;
  sendData: (type: DataChannelMessageType, data: any) => Promise<void>;
  drawingOptions: DrawingOptions;
}) => {
  const mediaCapture = useRef<HTMLImageElement>(null);
  const mediaCaptureCanvas = useRef<HTMLCanvasElement>(null);

  const [points, setPoints] = useState<number[][]>([]);
  const [size, setSize] = useState({ width: 1, height: 1 });
  const [cursorPosition, setCursorPosition] = useState<number[]>([0, 0]);

  useResizeObserver(video, setSize);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const drawer2D = useDrawing2D(size, context);
  const { erase } = drawer2D;
  const sendDrawingMessage = useCallback(
    (data: any) => {
      sendData(DataChannelMessageType.Annotation, data).catch(console.error);
    },
    [sendData]
  );

  const freezeVideoFrame = useCallback(() => {
    if (mediaCaptureCanvas.current && mediaCapture.current) {
      const imageContext = mediaCaptureCanvas.current.getContext("2d");
      if (imageContext) {
        imageContext.drawImage(video, 0, 0, size.width, size.height);
        const data = mediaCaptureCanvas.current.toDataURL("image/png");
        mediaCapture.current.setAttribute("src", data);
        mediaCapture.current.style.visibility = "visible";
        sendDrawingMessage(
          JSON.stringify({
            startPosition: [cursorPosition[0], cursorPosition[1]],
            color: DRAWING_COLORS[drawingOptions.selectedColor].code.split("#")[1],
          })
        );
        return true;
      }
    }
    return false;
  }, [mediaCaptureCanvas, mediaCapture, video, size, sendDrawingMessage, cursorPosition, drawingOptions.selectedColor]);

  const resumeVideo = useCallback(() => {
    if (mediaCapture.current) {
      mediaCapture.current.style.visibility = "hidden";
    }
  }, [mediaCapture]);

  const sendCurrentCursorPosition = useCallback(
    (cursorPoint: number[]) => {
      const cursorX = cursorPoint[0];
      const cursorY = cursorPoint[1];
      sendDrawingMessage(JSON.stringify({ cursorPosition: [cursorX, cursorY] }));
    },
    [sendDrawingMessage]
  );

  const sendLineData = useCallback(() => {
    if (points.length) {
      sendDrawingMessage(
        JSON.stringify({
          values: [...points],
          color: DRAWING_COLORS[drawingOptions.selectedColor].code.split("#")[1],
          size: drawingOptions.drawingSize,
        })
      );
      setPoints([]);
      setDrawingCount((prevCount) => prevCount + 1);
    }
  }, [
    points,
    sendDrawingMessage,
    setPoints,
    setDrawingCount,
    drawingOptions.selectedColor,
    drawingOptions.drawingSize,
  ]);

  const updateLineData = useCallback(
    (currPoint: number[]) => {
      const pointsToSend = [...points, [currPoint[0], currPoint[1]]];
      setPoints(pointsToSend);

      if (pointsToSend.length > POINTS_BUFFER_SIZE) {
        sendLineData();
        erase();
      }
    },
    [points, erase, sendLineData, setPoints]
  );

  const onCursorMove = useCallback(
    (point: number[] | null, isDrawing: boolean) => {
      if (point) {
        setCursorPosition(point);
        if (isDrawing) {
          updateLineData(point);
        } else if (!cursorDisabled) {
          sendCurrentCursorPosition(point);
        }
      } else {
        sendDrawingMessage(DrawingMessageType.RemoveCursor);
      }
    },
    [cursorDisabled, sendCurrentCursorPosition, sendDrawingMessage, updateLineData]
  );

  const onBeginDrawing = useCallback(() => {
    setPoints([[cursorPosition[0], cursorPosition[1]]]);
    return freezeVideoFrame();
  }, [freezeVideoFrame, setPoints, cursorPosition]);

  const onFinishDrawing = useCallback(() => {
    resumeVideo();
    sendLineData();
    erase();
    setPoints([]);
  }, [resumeVideo, erase, sendLineData, setPoints]);

  const onEraseStroke = useCallback((_: Point2D) => {}, []);

  useEffect(() => {
    const clearHandler = () => {
      if (drawingCount > 0) {
        sendDrawingMessage(DrawingMessageType.Clear);
        setDrawingCount(0);
      }
    };

    const undoHandler = () => {
      if (drawingCount > 0) {
        sendDrawingMessage(DrawingMessageType.Undo);
        setDrawingCount((count) => count - 1);
      }
    };

    setClearHandler(() => clearHandler);
    setUndoHandler(() => undoHandler);
  }, [drawingCount, setDrawingCount, sendDrawingMessage, setClearHandler, setUndoHandler]);

  useEffect(() => {
    setUndoDisabledHandler(drawingCount <= 0);
    setClearDisabledHandler(drawingCount <= 0);
  }, [drawingCount, setUndoDisabledHandler, setClearDisabledHandler]);

  return (
    <>
      <img
        ref={mediaCapture}
        width={size.width}
        height={size.height}
        style={{ zIndex: 1, position: "absolute", top: "0px", left: "0px" }}
        alt=""
      />
      <DrawingCanvas
        interactionMode={ModelInteractionMode.Drawing}
        size={size}
        strokeOptions={
          {
            strokeColor: DRAWING_COLORS[drawingOptions.selectedColor].code,
            lineWidth: drawingOptions.drawingSize * 100,
          } as StrokeOptions
        }
        drawer2D={drawer2D}
        onBeginDrawing={onBeginDrawing}
        onCursorMove={onCursorMove}
        onFinishDrawing={onFinishDrawing}
        onEraseStroke={onEraseStroke}
        setContext={setContext}
      ></DrawingCanvas>
      <canvas hidden ref={mediaCaptureCanvas} width={size.width} height={size.height} />
    </>
  );
};

export default VideoCallCanvas;
