import { useCallback, useMemo } from "react";

import { Access, FeatureAccessDict, PermissionDict } from "services/ContentServer/Identity";

export const SUPERUSER = "superuser";

export enum PERMISSION_NAME {
  SITE = "Site",
  ASSET = "Asset",
  SNAPSHOT = "Snapshot",
  CAPTURE = "Capture",
  ANNOTATION = "Annotation",
  TOUCHUP = "Touch Up",
  FACILITY = "Facility",
  ROLES = "Roles",
  USER = "User Management",
  CHECKLISTS = "Checklists",
  SUPERUSER = "Superuser",
  ARCHIVE = "Archive",
  ADMIN = "Admin",
  FOLDER = "Location",
  TEAM = "Team",
}

export interface Feature {
  name: string;
  subsets: string[];
  editable: boolean;
}

export const FEATURES: Feature[] = [
  { name: PERMISSION_NAME.SITE, subsets: ["site"], editable: true },
  { name: PERMISSION_NAME.ASSET, subsets: ["asset", "assetinfo"], editable: true },
  { name: PERMISSION_NAME.SNAPSHOT, subsets: ["snapshot"], editable: true },
  { name: PERMISSION_NAME.CAPTURE, subsets: ["mediacapture"], editable: true },
  { name: PERMISSION_NAME.ANNOTATION, subsets: ["annotation"], editable: true },
  { name: PERMISSION_NAME.TOUCHUP, subsets: ["touchup"], editable: true },
  { name: PERMISSION_NAME.FACILITY, subsets: ["facility"], editable: true },
  { name: PERMISSION_NAME.ROLES, subsets: ["userroles"], editable: true },
  { name: PERMISSION_NAME.USER, subsets: ["userinvite", "group"], editable: true },
  { name: PERMISSION_NAME.CHECKLISTS, subsets: ["labelgroup", "labelitem"], editable: true },
  { name: PERMISSION_NAME.SUPERUSER, subsets: ["superuser"], editable: false },
  { name: PERMISSION_NAME.ADMIN, subsets: ["admin"], editable: false },
  { name: PERMISSION_NAME.TEAM, subsets: ["team"], editable: true },
  { name: PERMISSION_NAME.FOLDER, subsets: ["folder", "folderedge", "site"], editable: true },
];

export enum PERMISSION_TYPE {
  ADD = "add_",
  READ = "view_",
  DELETE = "delete_",
  UPDATE = "change_",
}

const usePermission = (permissions: PermissionDict) => {
  const hasPermission = useCallback(
    (permissionNames: string[], permissionType: PERMISSION_TYPE, isFeature: boolean) => {
      for (let i = 0; i < permissionNames.length; i++) {
        const permissionName = permissionNames[i];
        const permissionId = isFeature ? permissionType + permissionName : permissionName;
        if (permissions[permissionId] == undefined) {
          return false;
        }
      }
      return true;
    },
    [permissions]
  );

  const featureAccess = useMemo(() => {
    const accessDict: FeatureAccessDict = {};
    FEATURES.forEach((feature: Feature) => {
      const permissionSet: Access = { read: false, add: false, delete: false, update: false };
      Object.values(PERMISSION_TYPE).forEach((permissionType: PERMISSION_TYPE) => {
        if (hasPermission(feature.subsets, permissionType, feature.editable)) {
          switch (permissionType) {
            case PERMISSION_TYPE.READ:
              permissionSet.read = true;
              break;
            case PERMISSION_TYPE.ADD:
              permissionSet.add = true;
              break;
            case PERMISSION_TYPE.DELETE:
              permissionSet.delete = true;
              break;
            case PERMISSION_TYPE.UPDATE:
              permissionSet.update = true;
              break;
          }
        }
      });
      accessDict[feature.name] = permissionSet;
    });
    return accessDict;
  }, [hasPermission]);

  return { featureAccess };
};

export const GetUserPermissions = (permissions: PermissionDict | undefined) => {
  const perms = permissions ? permissions : {};
  const { featureAccess } = usePermission(perms);
  return featureAccess;
};

export default usePermission;
