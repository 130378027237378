import { CommServerMessage } from "./types";

export const getCommServerMessageFromJSON = (commServerMessage: string) => {
  try {
    const commServerMessageJSON = JSON.parse(commServerMessage);
    const messageType = commServerMessageJSON.message_type;
    const message = commServerMessageJSON.message;
    return { type: messageType, data: message } as CommServerMessage;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const serializeCommServerMessage = (type: string, data: Record<string | number | symbol, unknown>) => {
  const messageContent = { message_type: type, message: JSON.stringify(data) };
  return JSON.stringify(messageContent);
};
