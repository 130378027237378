import React, { useState, useEffect, useReducer, useCallback, createContext, useContext, useRef } from "react";

import { useWebRTC } from "./useWebRTC";
import { Matrix4ToThreeMatrix4 } from "components/CameraSelector/Common/ThreeUtils";
import { Device } from "services/ContentServer/Identity";
import { IWebRTCSession, ConnectionClosedType, MediaErrorType } from "services/WebRTC";
import { DataChannelMessageType } from "services/WebRTC/constants";
import { MediaActionType, MediaState, mediaStateReducer } from "services/WebRTC/mediaStateReducer";

const getConnectionClosedMessage = (type: ConnectionClosedType, reason?: string): string => {
  switch (type) {
    case ConnectionClosedType.USER_HANGUP:
      return "Call ended due to user hangup.";
    case ConnectionClosedType.USER_BUSY:
      return "Call was declined because user was in another call.";
    case ConnectionClosedType.USER_DECLINE:
      return "Call was declined by the user.";
    case ConnectionClosedType.USER_HANDLED:
      return "Call was handled on a different device.";
    case ConnectionClosedType.PEER_HANGUP:
      return "Call was ended due to peer hangup.";
    case ConnectionClosedType.PEER_BUSY:
      return "Call was declined because peer was busy.";
    case ConnectionClosedType.PEER_DECLINE:
      return "Call was declined by the peer.";
    case ConnectionClosedType.PEER_FAILED:
      return "Call failed on peer's end";
    case ConnectionClosedType.OFFER_FAILED:
      return `Call failed due to an unexpected error`;
    case ConnectionClosedType.ANSWER_FAILED:
      return `Call failed due to an unexpected error`;
    case ConnectionClosedType.MEDIA_UNAVAILABLE:
      return `Failed to access camera/microphone: Please ensure permission has been granted in your browser settings and they are not currently used by other applications`;
    case ConnectionClosedType.UNKNOWN:
      return `Call failed due to an unexpected error`;
    default:
      throw new Error(`Invalid connection closed event type ${type} with reason: ${reason}`);
  }
};

const getMediaErrorType = (errorName: string): MediaErrorType => {
  switch (errorName) {
    case "NotReadableError":
      return MediaErrorType.MEDIA_IN_USE;
    case "NotAllowedError":
      return MediaErrorType.PERMISSION_DENIED;
    case "NotFoundError":
      return MediaErrorType.MEDIA_NOT_FOUND;
    case "OverconstrainedError":
      return MediaErrorType.OVERCONSTRAINED;
    default:
      return MediaErrorType.UNKNOWN;
  }
};

const getMediaErrorDescription = (errorType: MediaErrorType, device: string): string => {
  switch (errorType) {
    case MediaErrorType.MEDIA_IN_USE:
      return `Unable to access ${device}: ${device} may already be in use`;
    case MediaErrorType.PERMISSION_DENIED:
      return `Unable to access ${device}: Permission denied`;
    case MediaErrorType.MEDIA_NOT_FOUND:
      return `Unable to access ${device}: ${device} not found`;
    case MediaErrorType.OVERCONSTRAINED:
      return `Unable to access ${device}: ${device} may be disconnected`;
    case MediaErrorType.UNKNOWN:
      return `Unable to access ${device}: Unknown error`;
  }
};

export enum ActionType {
  RECEIVING_CALL,
  MAKING_CALL,
  CALL_STARTED,
  CALL_ENDED,
  CONNECTION_ESTABLISHED,
  CONNECTION_TIMEOUT,
}

export enum ConnectionStatus {
  CONNECTING,
  CONNECTED,
  TIMEOUT,
}

enum MediaStateMessageType {
  MIC_OFF = "mic off",
  MIC_ON = "mic on",
  VIDEO_OFF = "video off",
  VIDEO_ON = "video on",
}

interface Action {
  type: ActionType;
  peerId?: string;
  peerDevice?: Device;
  closedMsg?: string;
}

export interface State {
  isInCall: boolean;
  isReceivingCall: boolean;
  isCalling: boolean;
  connectionStatus: ConnectionStatus;
  peerId?: string;
  peerDevice?: Device;
  closedMsg?: string;
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.RECEIVING_CALL:
      return {
        ...state,
        isInCall: false,
        isReceivingCall: true,
        isCalling: false,
        peerId: action.peerId,
        closedMsg: undefined,
      };
    case ActionType.MAKING_CALL:
      return {
        ...state,
        isInCall: false,
        isReceivingCall: false,
        isCalling: true,
        peerId: action.peerId,
        closedMsg: undefined,
      };
    case ActionType.CALL_STARTED:
      return {
        ...state,
        isInCall: true,
        isReceivingCall: false,
        isCalling: false,
        peerDevice: action.peerDevice,
        closedMsg: undefined,
      };
    case ActionType.CALL_ENDED:
      return {
        ...state,
        isInCall: false,
        isReceivingCall: false,
        isCalling: false,
        connectionStatus: ConnectionStatus.CONNECTING,
        closedMsg: action.closedMsg,
      };
    case ActionType.CONNECTION_ESTABLISHED:
      return {
        ...state,
        connectionStatus: ConnectionStatus.CONNECTED,
      };
    case ActionType.CONNECTION_TIMEOUT:
      return {
        ...state,
        connectionStatus: ConnectionStatus.TIMEOUT,
      };
    default:
      throw new Error();
  }
};

const initialCallState: State = {
  isInCall: false,
  isReceivingCall: false,
  isCalling: false,
  connectionStatus: ConnectionStatus.CONNECTING,
};

const initialMediaState: MediaState = {
  isVideoEnabled: false,
  isAudioEnabled: false,
  isPeerVideoEnabled: true,
  isPeerAudioEnabled: true,
  shouldSendMediaState: false,
};

const VIDEO_RESOLUTION = {
  width: 320,
  height: 240,
};

const callState = createContext(initialCallState);

const dispatchCallState = createContext((value: Action) => {});

export const useCallState = () => {
  return useContext(callState);
};

const useDispatchCallState = () => {
  return useContext(dispatchCallState);
};

export const ProvideCallState = ({ children }: { children: React.ReactNode }) => {
  const [callingState, dispatchCallingState] = useReducer(reducer, initialCallState);
  return (
    <callState.Provider value={callingState}>
      <dispatchCallState.Provider value={dispatchCallingState}>{children}</dispatchCallState.Provider>
    </callState.Provider>
  );
};

const useWebRTCSession = () => {
  const callState = useCallState();
  const dispatchCallState = useDispatchCallState();
  const [mediaState, dispatchMediaState] = useReducer(mediaStateReducer, initialMediaState);
  const [mediaErrorDescription, setMediaErrorDescription] = useState<string | null>(null);
  const [mediaDeviceList, setMediaDeviceList] = useState<MediaDeviceInfo[] | null>(null);
  const [remoteMediaStream, setRemoteMediaStream] = useState<MediaStream | null>(null);
  const [localMediaStream, setLocalMediaStream] = useState<MediaStream | null>(null);
  const [session, setSession] = useState<IWebRTCSession | null>(null);
  const [inspectorPosition, setInspectorPosition] = useState<any>(undefined);

  const { webRTC } = useWebRTC();

  useEffect(() => {
    if (webRTC) {
      const unsubscribe = webRTC.subscribeToCalls((newSession: IWebRTCSession) => {
        if (session) {
          newSession.busy();
        } else {
          setSession(newSession);
          dispatchCallState({
            type: newSession.isReceiving ? ActionType.RECEIVING_CALL : ActionType.MAKING_CALL,
            peerId: newSession.peerID,
            peerDevice: newSession.peerDevice,
          });
        }
      });

      return () => unsubscribe();
    }
  }, [webRTC, session, dispatchCallState]);

  const micState = useRef<string>("prompt");
  const camState = useRef<string>("prompt");
  useEffect(() => {
    if (session && !session.isInitialized) {
      session.onRemoteMediaStream = (stream: MediaStream) => {
        setRemoteMediaStream(stream);
        // TODO: right now we're using a remote stream to signal peer answer. Ther has to be a better way ...
        dispatchCallState({ type: ActionType.CALL_STARTED, peerDevice: session.peerDevice });
      };

      session.onDataChannelOpen = () => {
        dispatchCallState({ type: ActionType.CONNECTION_ESTABLISHED });
        dispatchMediaState({ type: MediaActionType.START_SENDING_STATE });
      };

      session.onConnectionPingReceived = () => {
        dispatchCallState({ type: ActionType.CONNECTION_ESTABLISHED });
      };

      session.onConnectionTimeout = () => {
        dispatchCallState({ type: ActionType.CONNECTION_TIMEOUT });
      };

      session.onRemoteMediaStateMessage = (message: any) => {
        switch (message) {
          case MediaStateMessageType.VIDEO_OFF:
            dispatchMediaState({ type: MediaActionType.DISABLE_REMOTE_VIDEO });
            break;
          case MediaStateMessageType.VIDEO_ON:
            dispatchMediaState({ type: MediaActionType.ENABLE_REMOTE_VIDEO });
            break;
          case MediaStateMessageType.MIC_OFF:
            dispatchMediaState({ type: MediaActionType.DISABLE_REMOTE_AUDIO });
            break;
          case MediaStateMessageType.MIC_ON:
            dispatchMediaState({ type: MediaActionType.ENABLE_REMOTE_AUDIO });
            break;
          default:
            console.warn(`Unknown media state message: ${message}`);
            break;
        }
      };

      session.onRemoteInspectorLocation=(message:any)=>{
        const msg = JSON.parse(message);
        const position = Matrix4ToThreeMatrix4(JSON.parse(msg.POSE));
        const snapshotId = msg.SNAPSHOT || "";
        setInspectorPosition({position, snapshotId});
      }

      session.onConnectionClosed = (type: ConnectionClosedType, reason?: string) => {
        const closedMsg = getConnectionClosedMessage(type, reason);
        if (reason) {
          console.error(reason);
        }

        setMediaErrorDescription(null);
        setLocalMediaStream((stream) => {
          stream?.getTracks().forEach((track) => track.stop());
          return null;
        });
        setRemoteMediaStream(null);
        setSession(null);
        dispatchCallState({ type: ActionType.CALL_ENDED, closedMsg });
        dispatchMediaState({ type: MediaActionType.CALL_ENDED });
      };
      // Guide the user to accept camera and audio permissions
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const hasCamera = devices.some((device) => device.kind === "videoinput");
        const hasMicrophone = devices.some((device) => device.kind === "audioinput");
        if (
          !navigator.permissions ||
          !navigator.permissions.query ||
          !navigator.mediaDevices ||
          !navigator.mediaDevices.getUserMedia
        ) {
          // Permission API or getUserMedia not supported
          setMediaErrorDescription("Your browser does not support media device access.");
        } else if (micState.current === "prompt" && camState.current === "prompt") {
          if (hasCamera && hasMicrophone) {
            setMediaErrorDescription("Please grant camera and audio permission.");
          } else if (hasCamera) {
            setMediaErrorDescription("Please grant camera permission.");
          } else if (hasMicrophone) {
            setMediaErrorDescription("Please grant audio permissions.");
          }
        }
          // Ask user for permission via getUserMedia
          navigator.mediaDevices
            .getUserMedia({
              audio: { deviceId: mediaState.selectedAudioDevice },
              video: {
                width: { exact: VIDEO_RESOLUTION.width },
                height: { exact: VIDEO_RESOLUTION.height },
                deviceId: mediaState.selectedVideoDevice,
              },
            })
            .then((stream) => {
              if (session.isClosed) {
                return;
              }
              if (!session.isReceiving) {
                session.call();
              }
              micState.current = "granted";
              camState.current = "granted";
              setMediaErrorDescription(null);
              setLocalMediaStream(stream);
              dispatchMediaState({
                type: MediaActionType.ALL_LOCAL_MEDIA,
                videoDeviceId: stream.getVideoTracks()[0].getSettings().deviceId || "",
                audioDeviceId: stream.getAudioTracks()[0].getSettings().deviceId || "",
              });
            })
            .catch((error) => {
              //Considering case where user did not deny permission
              if (error.name !== "NotAllowedError") {
                navigator.mediaDevices
                  .getUserMedia({
                    audio: { deviceId: mediaState.selectedAudioDevice },
                  })
                  .then((stream) => {
                    camState.current = "denied";
                    micState.current = "granted";
                    console.error(`Error accessing video device: ${error}`);
                    const errorType = getMediaErrorType(error.name);
                    setMediaErrorDescription(getMediaErrorDescription(errorType, "Camera"));
                    if (session.isClosed) {
                      return;
                    }
                    if (!session.isReceiving) {
                      session.call();
                    }
                    stream.addTrack(noVideo());
                    setLocalMediaStream(stream);
                    dispatchMediaState({
                      type: MediaActionType.LOCAL_AUDIO_ONLY,
                      audioDeviceId: stream.getAudioTracks()[0].getSettings().deviceId,
                    });
                  })
                  .catch((error) => {
                    camState.current = "denied";
                    micState.current = "denied";
                    // If everything fails, start with no media (likely a permissions issue)
                    console.error(`Error accessing media devices: ${error}`);
                    const errorType = getMediaErrorType(error.name);
                    setMediaErrorDescription(getMediaErrorDescription(errorType, "Camera/Microphone"));
                    if (session.isClosed) {
                      return;
                    }
                    if (!session.isReceiving) {
                      session.call();
                    }
                    setLocalMediaStream(new MediaStream([noVideo(), noAudio()]));
                    dispatchMediaState({ type: MediaActionType.NO_LOCAL_MEDIA });
                  });
              } else {
                camState.current = "denied";
                micState.current = "denied";
                console.error(`Error accessing media devices: ${error}`);
                const errorType = getMediaErrorType(error.name);
                setMediaErrorDescription(getMediaErrorDescription(errorType, "Camera/Microphone"));
                if (session.isClosed) {
                  return;
                }
                if (!session.isReceiving) {
                  session.call();
                }
                setLocalMediaStream(new MediaStream([noVideo(), noAudio()]));
                dispatchMediaState({ type: MediaActionType.NO_LOCAL_MEDIA });
              }
            });

        session.isInitialized = true;
      });
    }
  }, [micState, camState, session, mediaState.selectedAudioDevice, mediaState.selectedVideoDevice, dispatchCallState]);

  useEffect(() => {
    if (session && localMediaStream) {
      session.setLocalMediaStream(localMediaStream);
    }
  }, [localMediaStream, session]);

  const answer = useCallback(() => {
    if (session) {
      session.answer();
      dispatchCallState({ type: ActionType.CALL_STARTED, peerDevice: session.peerDevice });
    }
  }, [session, dispatchCallState]);

  const decline = useCallback(() => {
    session?.decline();
  }, [session]);

  const hangup = useCallback(() => {
    session?.hangup();
  }, [session]);

  const sendData = useCallback(
    (type: DataChannelMessageType, data: any): Promise<void> => {
      return session ? session.sendData(type, data) : Promise.reject("No session found for sending data.");
    },
    [session]
  );

  useEffect(() => {
    if (mediaState.shouldSendMediaState && session) {
      sendData(
        DataChannelMessageType.Media,
        mediaState.isAudioEnabled ? MediaStateMessageType.MIC_ON : MediaStateMessageType.MIC_OFF
      );
    }
  }, [mediaState.shouldSendMediaState, mediaState.isAudioEnabled, session, sendData]);

  useEffect(() => {
    if (mediaState.shouldSendMediaState && session) {
      sendData(
        DataChannelMessageType.Media,
        mediaState.isVideoEnabled ? MediaStateMessageType.VIDEO_ON : MediaStateMessageType.VIDEO_OFF
      );
    }
  }, [mediaState.shouldSendMediaState, mediaState.isVideoEnabled, session, sendData]);

  const loadMediaDevices = useCallback(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(setMediaDeviceList)
      .catch((error) => {
        console.error(`Error listing media devices: ${error}`);
      });
  }, []);

  const selectVideoDevice = useCallback(
    (device: MediaDeviceInfo) => {
      if (localMediaStream) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              width: { exact: VIDEO_RESOLUTION.width },
              height: { exact: VIDEO_RESOLUTION.height },
              deviceId: { exact: device.deviceId },
            },
          })
          .then((stream) => {
            setMediaErrorDescription(null);
            localMediaStream.getVideoTracks().forEach((track) => {
              track.stop();
              localMediaStream.removeTrack(track);
            });
            localMediaStream.addTrack(stream.getVideoTracks()[0]);
            session?.replaceVideoTrack(stream.getVideoTracks()[0]);
            dispatchMediaState({ type: MediaActionType.ENABLE_LOCAL_VIDEO, videoDeviceId: device.deviceId });
          })
          .catch((error) => {
            console.error(`Error accessing video resource: ${error}`);
            const errorType = getMediaErrorType(error.name);
            setMediaErrorDescription(getMediaErrorDescription(errorType, "Camera"));
          });
      }
    },
    [session, localMediaStream]
  );

  const selectMicDevice = useCallback(
    (device: MediaDeviceInfo) => {
      if (localMediaStream) {
        navigator.mediaDevices
          .getUserMedia({
            audio: { deviceId: { exact: device.deviceId } },
          })
          .then((stream) => {
            setMediaErrorDescription(null);
            localMediaStream.getAudioTracks().forEach((track) => {
              track.stop();
              localMediaStream.removeTrack(track);
            });
            localMediaStream.addTrack(stream.getAudioTracks()[0]);
            session?.replaceAudioTrack(stream.getAudioTracks()[0]);
            dispatchMediaState({ type: MediaActionType.ENABLE_LOCAL_AUDIO, audioDeviceId: device.deviceId });
          })
          .catch((error) => {
            console.error(`Error accessing audio resource: ${error}`);
            const errorType = getMediaErrorType(error.name);
            setMediaErrorDescription(getMediaErrorDescription(errorType, "Microphone"));
          });
      }
    },
    [session, localMediaStream]
  );

  const toggleVideo = useCallback(() => {
    if (localMediaStream) {
      if (mediaState.isVideoEnabled) {
        localMediaStream.getVideoTracks().forEach((track) => {
          track.stop();
          localMediaStream.removeTrack(track);
        });
        const blankVideoTrack = noVideo();
        localMediaStream.addTrack(blankVideoTrack);
        session?.replaceVideoTrack(blankVideoTrack);
        dispatchMediaState({ type: MediaActionType.DISABLE_LOCAL_VIDEO });
      } else {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              width: { exact: VIDEO_RESOLUTION.width },
              height: { exact: VIDEO_RESOLUTION.height },
              deviceId: mediaState.selectedVideoDevice,
            },
          })
          .then((stream) => {
            setMediaErrorDescription(null);
            localMediaStream.getVideoTracks().forEach((track) => {
              track.stop();
              localMediaStream.removeTrack(track);
            });
            localMediaStream.addTrack(stream.getVideoTracks()[0]);
            session?.replaceVideoTrack(stream.getVideoTracks()[0]);
            dispatchMediaState({
              type: MediaActionType.ENABLE_LOCAL_VIDEO,
              videoDeviceId: stream.getVideoTracks()[0].getSettings().deviceId,
            });
          })
          .catch((error) => {
            console.error(`Error accessing video resource: ${error}`);
            const errorType = getMediaErrorType(error.name);
            setMediaErrorDescription(getMediaErrorDescription(errorType, "Camera"));
          });
      }
    }
  }, [session, localMediaStream, mediaState.isVideoEnabled, mediaState.selectedVideoDevice]);

  const toggleMic = useCallback(() => {
    if (localMediaStream) {
      if (mediaState.isAudioEnabled) {
        localMediaStream.getAudioTracks().forEach((track) => {
          track.stop();
          localMediaStream.removeTrack(track);
        });
        const blankAudioTrack = noAudio();
        localMediaStream.addTrack(blankAudioTrack);
        session?.replaceAudioTrack(blankAudioTrack);
        dispatchMediaState({ type: MediaActionType.DISABLE_LOCAL_AUDIO });
      } else {
        navigator.mediaDevices
          .getUserMedia({
            audio: { deviceId: mediaState.selectedAudioDevice },
          })
          .then((stream) => {
            setMediaErrorDescription(null);
            localMediaStream.getAudioTracks().forEach((track) => {
              track.stop();
              localMediaStream.removeTrack(track);
            });
            localMediaStream.addTrack(stream.getAudioTracks()[0]);
            session?.replaceAudioTrack(stream.getAudioTracks()[0]);
            dispatchMediaState({
              type: MediaActionType.ENABLE_LOCAL_AUDIO,
              audioDeviceId: stream.getAudioTracks()[0].getSettings().deviceId,
            });
          })
          .catch((error) => {
            console.error(`Error accessing audio resource: ${error}`);
            const errorType = getMediaErrorType(error.name);
            setMediaErrorDescription(getMediaErrorDescription(errorType, "Microphone"));
          });
      }
    }
  }, [session, localMediaStream, mediaState.isAudioEnabled, mediaState.selectedAudioDevice]);

  const noVideo = ({ width = VIDEO_RESOLUTION.width, height = VIDEO_RESOLUTION.height } = {}) => {
    const canvas: any = Object.assign(document.createElement("canvas"), { width, height });
    canvas.getContext("2d")?.fillRect(0, 0, width, height);
    const stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], { enabled: true });
  };

  const noAudio = () => {
    const ctx = new AudioContext(),
      oscillator = ctx.createOscillator();
    const dst: any = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
  };

  return {
    camState: camState.current,
    micState: micState.current,
    callState,
    mediaState,
    inspectorPosition,
    answer,
    decline,
    hangup,
    sendData,
    selectVideoDevice,
    selectMicDevice,
    toggleVideo,
    toggleMic,
    localMediaStream,
    remoteMediaStream,
    mediaErrorDescription,
    setMediaErrorDescription,
    loadMediaDevices,
    mediaDeviceList,
  };
};

export default useWebRTCSession;
