import { Popover as PopoverMui, styled } from "@mui/material";

const Popover = styled(PopoverMui)(({ theme }) => ({
  "&.	.MuiPopover-paper": {
    boxShadow: "0px 4px 12px 0px #3537391A",
    borderRadius: "5px",
  },
}));

export default Popover;
