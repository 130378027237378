export declare type AuthenticationResult = {
  accessToken: string;
  scopes: string[];
};

export declare type AzureADConfig = {
  clientId: string;
  authorities: { signUpSignIn: string; editProfile: string; passwordReset: string; invite: string };
  knownAuthorities?: Array<string>;
  redirectUri: string;
  postLogoutRedirectUri: string;
  backendScopes: Array<string>;
};

export interface AzureADInterface {
  login(username?: string): Promise<void>;
  loginNoPath(): Promise<void>;
  editProfile(): Promise<void>;
  logout(): Promise<void>;
  acquireTokenSilent(scopes: string[]): Promise<AuthenticationResult>;
  handleRedirect(): void;
  silentLogin(): Promise<void>;
  onAuthStateChanged(callback: (userID: UserInfo | null) => void): () => void;
  getUserInfo(): UserInfo | null;
}

export declare type IdTokenClaims = {
  appTenantId: string;
  idp: string;
};

export declare type UserInfo = {
  id: string;
  tenantId: string;
};

export enum ServerType {
  VISION_SERVER = "Vision Server",
  CONTENT_SERVER = "Database Server",
  COMM_SERVER = "Communication Server",
}
