import { PaletteOptions } from "@mui/material";

const ARpalette: PaletteOptions = {
  primary: {
    main: "#1C1F22",
    light: "#D4D6D9",
  },
  secondary: {
    main: "#F6F6F6",
    light: "#FAFAFA",
    dark: "#EBEBEB",
  },
  error: {
    main: "#C1170A",
  },
  success: {
    main: "#2e7d32",
  },
  action: {
    disabled: "#555758",
  },
  annotations: {
    red: "#FF2616",
    orange: "#FF9B18",
    blue: "#0D95FF",
    green: "#3DDC06",
    black: "#000000",
  },
  frustum: {
    selected: {
      main: "#0000FF",
      light: "#0000FF80",
    },
    capture: {
      main: "#FFFF00",
      light: "#FFFF0080",
    },
    request: {
      main: "#00838F",
      light: "#00838F80",
    },
  },
  model: {
    modelHighlight: {
      edge: "#0000FF",
      hidden: "#FF9B18",
    },
    boundingBox: {
      main: "#FF9B18",
      light: "#FF9B184D",
    },
  },
  dalmatianDesign: {
    contrastText: "#FFFFFF",
    dark: "#1C1F22",
    main: "#FFFFFF",
    accent: "#EBEBEB",
    error: "#C1170A",
  },
  neutral: {
    defaultBackground: "#F4F6F8",
    modelBackground: "#F2F3F3",
    bordersDividers: "#E2E3E4",
    topNavigationSeparator: "#D4D6D9",
    defaultTextIcons: "#64676A",
    secondaryTextButtons: "#404245",
    emphasizedTextIcons: "#1C1F22",
    activeButton: "#D4D6D9B2",
    scrollBars: "#64676A99",
    titleText: "#404245",
    darkGreyText: "#424242",
  },
  accent: {
    light: "#EFF8FF",
    main: "#9ACFF9",
    contrastText: "#0F70BC",
  },
};

export default ARpalette;
