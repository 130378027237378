import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SnackbarOrigin } from "@mui/material";
import React from "react";

export const snackbarAnchorOrigin = {
  vertical: "top",
  horizontal: "center",
} as SnackbarOrigin;

export const autoHideDuration = 4000;

export const snackbarCloseAction = (closeSnackbar: () => void, color: string) => (
  <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
    <CloseIcon fontSize="small" sx={{ color }} />
  </IconButton>
);
