import { SortDirection } from "utils/SortRowsUtils";

export interface PageParams {
  limit: number;
  offset: number;
}

export interface SortParams {
  direction: SortDirection;
  field: string;
}

export interface QueryRequestParams {
  sort?: SortParams;
  page?: PageParams;
  filters?: any;
  queryData: any;
}

export interface PageData {
  count: number;
  nextOffset: number | null;
  prevOffset: number | null;
  totalPages: number;
}

export const deserializePageData = (pageData: any) => {
  if (!pageData) {
    return {
      count: 0,
      nextOffset: null,
      prevOffset: null,
      totalPages: 0,
    };
  }
  return {
    count: pageData.count,
    nextOffset: pageData.nextOffset,
    prevOffset: pageData.prevOffset,
    totalPages: pageData.totalPages,
  };
};

const generateFilter = (filterParams: any) => {
  let filterString = ``;
  for (const key in filterParams) {
    if (key && filterParams[key] !== undefined) {
      if (filterString.length > 0) {
        filterString += `,`;
      }
      if (filterParams[key] instanceof Object && !Array.isArray(filterParams[key])) {
        filterString += `${key}: {${generateFilter(filterParams[key])}}`;
      } else if (Array.isArray(filterParams[key])) {
        filterString += `${key}: ${JSON.stringify(filterParams[key])}`;
      } else if (typeof filterParams[key] === "string") {
        filterString += `${key}: "${filterParams[key]}"`;
      } else {
        filterString += `${key}: ${filterParams[key]}`;
      }
    }
  }

  return filterString;
};

export const buildGqlQueryRequest = (
  query: string,
  returnData: any,
  pageParams?: PageParams,
  sortParams?: SortParams,
  filterParams?: any
) => {
  const queryParams = [];
  if (pageParams) {
    queryParams.push(`pagination: {limit: ${pageParams.limit}, offset: ${pageParams.offset}}`);
  }
  if (sortParams) {
    queryParams.push(
      `sort: {fields: {field: "${sortParams.field}", direction: ${sortParams?.direction.toUpperCase()}}}`
    );
  }

  if (filterParams) {
    queryParams.push(`filters: {${generateFilter(filterParams)}}`);
  }

  let params = ``;
  if (queryParams.length > 0) {
    params = `(${queryParams.join(",")})`;
  }
  return `query{${query}${params}{${returnData}}}`;
};
