import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import { Avatar, Box, Dialog, DialogContent, Typography, Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useCallback, useMemo, useState } from "react";

import { SiteEditProps } from "../Sites/SiteEditPage";
import SiteUsersDialog from "../Sites/SiteUsersDialog";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import LoadingDialog from "components/Dialog/LoadingDialog";
import { User } from "services/ContentServer/Identity";
import { matchSorter } from "utils/SortRowsUtils";

const SiteUsersTab = ({
  siteEditProps,
  setProcessing,
  setProcessingMsg,
}: {
  siteEditProps?: SiteEditProps;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setProcessingMsg: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const theme = useTheme();
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const site = useMemo(() => {
    if (siteEditProps) {
      return siteEditProps.site;
    }
    return undefined;
  }, [siteEditProps]);

  const applyFilters = useCallback(
    (rows: User[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const userRows = useMemo(() => {
    if (site !== undefined) {
      return applyFilters(Object.values(site.users));
    }
  }, [applyFilters, site]);

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return (
    <>
      {site ? (
        Object.values(site?.users).length === 0 ? (
          <LoadingDialog processing={true} msg={"Loading..."} />
        ) : (
          <LoadingDialog processing={false} msg={""} />
        )
      ) : (
        <LoadingDialog processing={true} msg={"Loading..."} />
      )}
      <Dialog
        open={confirmOpen}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
          },
          width: "100%",
        }}
      >
        <DialogContent>
          <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Grid item xs={12} style={{ width: "100%", paddingBottom: "32px" }}>
              <Typography variant="body1">{`Are you sure you would like to remove all users from ${site?.name}? The site's key contact will not be removed.`}</Typography>
            </Grid>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: 0,
                gap: "8px",
                width: "65%",
              }}
            >
              <BaseButton variant="outlined" onClick={handleClose}>
                Cancel
              </BaseButton>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
      <SiteUsersDialog
        site={site}
        open={open}
        setOpen={setOpen}
        setProcessing={setProcessing}
        setProcessingMsg={setProcessingMsg}
      />
      <Grid item xs={12}>
        <Typography variant="body1">{`Select which users have access to the site ${site?.name}.`}</Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: "2%" }}>
        <Box sx={{ display: "flex", alignItems: "center", padding: "0px", gap: "16px" }}>
          <SearchBar placeholderText={"Search users..."} query={query} setQuery={setQuery} />
          <BaseButton
            variant={"outlined"}
            startIcon={<AddIcon />}
            onClick={() => {
              setOpen(true);
            }}
            sx={{ maxHeight: "36px" }}
          >
            Add Users
          </BaseButton>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
          padding: "0px 8px 48px 0px",
          gap: "16px",
          height: "60vh",
          overflowY: "auto",
        }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: " 0px",
            gap: "16px",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: " 0px",
              gap: "8px",
            }}
          >
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
              <DeleteOutlineSharpIcon sx={{ color: theme.palette.primary.contrastText }} />
            </Avatar>
            <Typography variant="body1">Remove All</Typography>
          </Box>
          <IconButton color="primary" onClick={() => setConfirmOpen(true)} size="large">
            <DeleteOutlineSharpIcon />
          </IconButton>
        </Box>
        {userRows?.map((user) => {
          return (
            <Box
              key={user.id}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: " 0px",
                gap: "16px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: " 0px",
                  gap: "8px",
                }}
              >
                {user.userProfile.profilePicture !== "" ? (
                  <Avatar
                    sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}
                    src={user.userProfile.profilePicture}
                  />
                ) : (
                  <Avatar sx={{ bgcolor: theme.palette.secondary.dark, color: "#404245" }}>
                    <UserPlaceholderIcon />
                  </Avatar>
                )}
                <Typography variant="body1">{user.name}</Typography>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </>
  );
};

export default SiteUsersTab;
