import { HelpOutline } from "@mui/icons-material";
import { Box, Container, Grid, Typography } from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React from "react";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";

function AccountNotFoundPage() {
  const theme = useTheme();

  return (
    <>
      <img
        src="/images/aptixAR-logov2.png"
        style={{
          top: "16px",
          left: "24px",
          width: "112px",
          height: "48px",
          position: "absolute",
        }}
      />

      <Container
        sx={{
          // border: "1px red solid",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "95vw",
        }}
      >
        <Grid container spacing={2} sx={{ flex: 1, ml: 0 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            order={{ xs: 2, sm: 1 }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: 30, lg: 55 },
                  lineHeight: { xs: "40px", lg: "57px" },
                  mt: -7,
                }}
              >
                {"Sorry, we can't"}
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: 30, lg: 55 },
                  lineHeight: { xs: "40px", lg: "57px" },
                }}
              >
                {"find that account."}
              </Typography>

              <Typography variant="h5" sx={{ fontSize: 14, fontWeight: 400, lineHeight: "16.94px", mt: "16px" }}>
                {"Please contact your system administrator to request an invite."}
              </Typography>
              <BaseButton variant="contained" sx={{ mt: { xs: 5, md: 8, lg: 10 } }} href="/">
                SIGN IN
              </BaseButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} pl={4} order={{ xs: 1, sm: 2 }}>
            <img src="/images/error.png" style={{ width: "90%", margin: "0 auto", display: "block" }} />
          </Grid>
        </Grid>
      </Container>
      <a
        id="contact-help"
        href="mailto: support@interaptix.com"
        style={{ position: "absolute", right: "24px", bottom: "24px" }}
      >
        <HelpOutline style={{ color: theme.palette.primary.main }} />
      </a>
    </>
  );
}

export default AccountNotFoundPage;
