import React from "react";

import UserAlreadyExistsDialog from "components/Dialog/UserExistsDialog";

const UserAlreadyExistsPage = () => {
  return (
    <>
      <UserAlreadyExistsDialog open={true}></UserAlreadyExistsDialog>
    </>
  );
};

export default UserAlreadyExistsPage;
