import { Device } from "../ContentServer/Identity";

export enum WebRTCMessageType {
  CALL = "CALL",
  HANGUP = "HANGUP",
  BUSY = "BUSY",
  DECLINE = "DECLINE",
  CALL_HANDLED = "CALL_HANDLED",
  CALL_FAILED = "CALL_FAILED",
  SDP_OFFER = "SDP_OFFER",
  SDP_ANSWER = "SDP_ANSWER",
  ICE_CANDIDATE = "ICE_CANDIDATE",
}

export declare type IWebRTCMessage = {
  receiver: string;
  offerer: string;
  device: Device;
  type: WebRTCMessageType;
  data?: RTCSessionDescriptionInit | RTCIceCandidateInit;
};

export declare type IServerMessage = {
  receiver: string;
  offerer: string;
  device: string;
  text: string;
};

export const serializeWebRTCToServerMessage = (message: IWebRTCMessage): IServerMessage => {
  let text = "";
  switch (message.type) {
    case WebRTCMessageType.CALL:
      text = "call";
      break;
    case WebRTCMessageType.HANGUP:
      text = "hangup";
      break;
    case WebRTCMessageType.BUSY:
      text = "busy";
      break;
    case WebRTCMessageType.DECLINE:
      text = "decline";
      break;
    case WebRTCMessageType.CALL_HANDLED:
      text = "call handled";
      break;
    case WebRTCMessageType.CALL_FAILED:
      text = "call failed";
      break;
    case WebRTCMessageType.SDP_OFFER:
      text = JSON.stringify({
        MessageType: 1,
        Data: JSON.stringify(message.data),
      });
      break;
    case WebRTCMessageType.SDP_ANSWER:
      text = JSON.stringify({
        MessageType: 2,
        Data: JSON.stringify(message.data),
      });
      break;
    case WebRTCMessageType.ICE_CANDIDATE:
      text = JSON.stringify({
        MessageType: 3,
        Data: JSON.stringify(message.data),
      });
      break;
  }

  return {
    receiver: message.receiver,
    offerer: message.offerer,
    device: Device[message.device],
    text: text,
  };
};

export const serializeServerToWebRTCMessage = (message: IServerMessage): IWebRTCMessage => {
  const extractRTCMessage = (text: string) => {
    const rtcMessage = JSON.parse(text);
    switch (rtcMessage.MessageType) {
      case 1:
        return {
          type: WebRTCMessageType.SDP_OFFER,
          data: JSON.parse(rtcMessage.Data) as RTCSessionDescriptionInit,
        };
      case 2:
        return {
          type: WebRTCMessageType.SDP_ANSWER,
          data: JSON.parse(rtcMessage.Data) as RTCSessionDescriptionInit,
        };
      case 3:
        return {
          type: WebRTCMessageType.ICE_CANDIDATE,
          data: JSON.parse(rtcMessage.Data) as RTCIceCandidateInit,
        };
      default:
        throw new Error("Invalid video call message type");
    }
  };

  let type: WebRTCMessageType | undefined = undefined;
  let data: RTCSessionDescriptionInit | RTCIceCandidateInit | undefined = undefined;

  switch (message.text) {
    case "call":
      type = WebRTCMessageType.CALL;
      break;
    case "hangup":
      type = WebRTCMessageType.HANGUP;
      break;
    case "busy":
      type = WebRTCMessageType.BUSY;
      break;
    case "decline":
      type = WebRTCMessageType.DECLINE;
      break;
    case "call handled":
      type = WebRTCMessageType.CALL_HANDLED;
      break;
    case "call failed":
      type = WebRTCMessageType.CALL_FAILED;
      break;
    default:
      ({ type, data } = extractRTCMessage(message.text));
      break;
  }

  return {
    receiver: message.receiver,
    offerer: message.offerer,
    device: message.device as Device,
    type: type,
    data: data,
  };
};
