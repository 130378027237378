import { Box, IconButton, Typography, Slider, Grid, Tooltip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { DrawingOptions } from "./types";
import { COLOR, DRAWING_COLORS } from "components/CameraSelector/Constants";
import StyledMenu from "components/menu/StyledMenu";
import { AnnotationIntersectionType } from "utils/Drawing";

const FILTER_BOX_HEIGHT = 47;

export default function DrawingMenu({
  open,
  anchorEl,
  drawingOptions,
  setDrawingOptions,
  handleClose,
  isVideoCall,
  intersectionType,
  handleIntersectionType,
  disablePlane,
}: {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  drawingOptions: DrawingOptions;
  setDrawingOptions: (prev: DrawingOptions | ((prev: DrawingOptions) => DrawingOptions)) => void;
  handleClose: () => void;
  isVideoCall: boolean;
  intersectionType?: AnnotationIntersectionType;
  handleIntersectionType?: (event: any, newIntersectionType: AnnotationIntersectionType) => void;
  disablePlane?: boolean | null;
}) {
  const theme = useTheme();
  return (
    <StyledMenu onClose={handleClose} open={open} anchorEl={anchorEl} placement={isVideoCall ? "bottom-end" : "bottom"}>
      <Box display="flex" flexDirection="column">
        {!isVideoCall && (
          <>
            <Typography align="left" variant="h4" style={{ paddingTop: "1px", paddingBottom: "2px" }} gutterBottom>
              Annotate on
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <ToggleButtonGroup
                value={intersectionType}
                exclusive
                onChange={handleIntersectionType}
                aria-label="text alignment"
              >
                <ToggleButton value={AnnotationIntersectionType.Model} aria-label="model" color="secondary">
                  <Tooltip title={"Annotate on object"} key={"annotate-object-switch"}>
                    <Typography variant="button">Object</Typography>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={AnnotationIntersectionType.Plane}
                  aria-label="plane"
                  color="secondary"
                  disabled={disablePlane ? disablePlane : false}
                >
                  <Tooltip title={"Annotate on plane"} key={"annotate-plane-switch"}>
                    <Typography variant="button">Plane</Typography>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </>
        )}
        <Typography
          align="left"
          variant="h4"
          style={
            isVideoCall
              ? { paddingTop: "1px", paddingBottom: "2px" }
              : { paddingTop: theme.spacing(4), paddingBottom: "2px" }
          }
          gutterBottom
        >
          Colours
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <>
            {DRAWING_COLORS.map((color, index) => (
              <IconButton
                style={{
                  backgroundColor: color.code,
                  marginRight: theme.spacing(2),
                  minWidth: 0.75 * FILTER_BOX_HEIGHT,
                  aspectRatio: "1/1",
                }}
                onClick={(e) => {
                  setDrawingOptions((prev) => ({ selectedColor: color.id, drawingSize: prev.drawingSize }));
                  handleClose();
                }}
                key={index}
                size="large"
              />
            ))}
          </>
        </Box>
        <Typography
          align="left"
          variant="h4"
          style={{ paddingTop: theme.spacing(4), paddingBottom: "2px" }}
          gutterBottom
        >
          Size
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1} md={1} lg={1}>
            <IconButton style={{ backgroundColor: COLOR.BLACK, marginTop: 0.375 * FILTER_BOX_HEIGHT }} size="small" />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Slider
              orientation="horizontal"
              style={{ marginTop: 0.15 * FILTER_BOX_HEIGHT }}
              value={drawingOptions.drawingSize}
              min={0.0025}
              step={0.0001}
              max={0.05}
              onChange={(_, value) =>
                setDrawingOptions((prev) => ({
                  selectedColor: prev.selectedColor,
                  drawingSize: value as number,
                }))
              }
              onChangeCommitted={() => handleClose()}
              aria-labelledby="continuous-slider"
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <IconButton
              style={{ backgroundColor: COLOR.BLACK, minWidth: 0.75 * FILTER_BOX_HEIGHT, aspectRatio: "1/1" }}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledMenu>
  );
}
