import { deserializerHelper, serializerHelper } from "../APITypeBase";
import { ISerialization } from "../ISerialization";

export interface IdentityInterface {
  updateUser(userId: string, user: User): Promise<any>;
  createInvite(email: string, username: string, isAdmin: boolean, siteId: string, userInviteId?: string): Promise<any>;
  updateUserProfile(userId: string, data: Profile): Promise<any>;
  getUser(id: string): Promise<User>;
  getUsers(): Promise<User[]>;
  getRoles(): Promise<RoleDict>;
  createBulkInvite(file: any): Promise<any>;
}

export enum Site {
  SiteA = "Site A",
  SiteB = "Site B",
  SiteC = "Site C",
  SiteD = "Site D",
  ShopFloor = "ShopFloor",
}

export enum Device {
  iOS = "iOS",
  Hololens = "Hololens",
  Desktop = "Desktop",
}

export enum Status {
  Active = "Active",
  Inactive = "Inactive",
}

export enum UserState {
  Normal = "NORMAL",
  Archive = "ARCHIVE",
  Deleted = "DELETED",
}

export enum UserTypes {
  Active = "Active",
  Deactivated = "Deactivated",
  Pending = "Pending",
}

export declare type Access = {
  read: boolean;
  add: boolean;
  delete: boolean;
  update: boolean;
};

export interface DefaultSiteData {
  site: string;
}
export declare type Permission = {
  id: string;
  name: string;
  codename: string;
};

export declare type Component = {
  id: string;
  name: string;
  permission: Permission;
};

export declare type Role = {
  id: string;
  name: string;
  description: string;
  logo: string;
  permissions: PermissionDict;
};

export declare type Connection = {
  id: string;
  timestamp: string;
  status: Status;
};

export const ADMIN_ROLE = "admin";

export const MEMBER_ROLE = "member";

export declare type Profile = {
  id: string;
  defaultSite: DefaultSiteData;
  displayName: string;
  profilePicture: string;
  activeUntil: string | null;
  state: UserState;
  readTerms: boolean;
};
export declare type Components = {
  id: string;
  name: string;
  permission: number;
};

export declare type UserPending = {
  id: string;
  name: string;
  isActive: boolean;
  email: string;
  phoneNumber: string;
  createdAt?: string;
  sites?: string[];
  roles?: RoleIDList;
  teams?: string[];
};

export declare type User = UserPending & {
  id: string;
  name: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  img: string | undefined;
  status: Status;
  lastLogin: string;
  device: Device;
  sites: string[];
  teams?: string[];
  connection: Connection | undefined;
  userProfile: Profile;
  userProfileId: string;
  roles: RoleIDList;
  isActive: boolean;
  createdAt?: string;
  // teams?:ITeams[]
};

export const hasProfile = (user: User | UserPending): User | false => {
  if ("userProfile" in user) {
    return user;
  }
  return false;
};
export interface UserRowData {
  id: string;
  User: string;
  UserTeam: { ids: string[]; names: string };
  UserRole: { ids: string[]; names: string };
  Site: { ids: string[]; names: string };
  LastLogin: string;
  ContextMenu: JSX.Element;
  roleIds?: string[];
  userType: UserTypes;
}

export interface UserInviteRequest {
  username: string;
  email: string;
  roles: string[];
  sites: string[];
  postRedeemUrl: string;
  additionalClaims: { role: string };
  reinviteId?: string;
}

export declare type ProfileDict = { [id: string]: Profile };

export declare type RoleDict = { [id: string]: Role };

export declare type PermissionDict = { [id: string]: Permission };

export declare type RoleIDList = string[];

export declare type FeatureAccessDict = { [feature: string]: Access };

export const serverToView: { [key: string]: keyof DisabledUser | undefined } = {
  user_id: "userId",
};

export class DisabledUser extends ISerialization {
  public userId?: string | null;

  constructor(user?: DisabledUser) {
    super();
    if (user) {
      Object.assign(this, user);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<DisabledUser>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<DisabledUser>(this, serverToView);
    return request;
  }
}
export declare type PaginatedUserResp = {
  next: string;
  num_pages: number; // eslint-disable-line @typescript-eslint/naming-convention
  previous: string;
  results: User[];
};
