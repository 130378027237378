import { PortalRoute } from "components/Routes";

export enum NavItemType {
  ITEM = "item",
  GROUP = "group",
  MENU = "menu",
  COLLAPSE = "collapse",
}

export interface IARNavType extends Partial<PortalRoute> {
  type: NavItemType;
  hidden?: boolean;
  darkBg?: boolean;
  showBadge?: boolean;
  children: IARNavType[];
  onClick: () => void;
}
