import isEqual from "lodash.isequal";
import { ReactText, useCallback, useContext, useEffect, useState } from "react";
import { mutate } from "swr";
import * as THREE from "three";

import { LOD } from "./LOD";
import ObjectLoader from "./ObjectLoader";
import { setLayers, transformObject } from "components/CameraSelector/Common/ThreeUtils";
import { getMaxObjectDim } from "components/CameraSelector/Common/ThreeUtils";
import { RenderLayer, invisibleMaterial } from "components/CameraSelector/Constants";
import { insightsTracker } from "hooks/useAzureInsights";
import useEffectWhen from "hooks/useEffectWhen";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import { MESSAGE_TYPE } from "services/CommServer";
import { Matrix4 } from "services/ContentServer/Audit";
import { LODFile } from "services/ContentServer/Audit/serviceTypes/LODFile";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { useUpdateHandler } from "services/InspectionUpdate/useUpdateHandler";
import { FacilityContext } from "utils/Contexts/FacilityContext";
import { MeshToggleContext } from "utils/Contexts/MeshToggleContext";
import { SceneContext } from "utils/Contexts/SceneContext";
import { SnapshotContext } from "utils/Contexts/SnapshotContext";
import { addToFileName, getExtension, is3DObjectFile } from "utils/FileUtils";

export const MODEL_NAME = "MODEL";

interface InsightProperties {
  ext?: string;
  asset?: string;
  modelId?: string | null | undefined;
  correlation?: number;
  snapshot?: Snapshot;
}

export interface SegmentData {
  id: string | null | undefined;
  lodFiles: LODFile[];
  scale: number;
  identifiers: string[];
}

export function getSnapshotModelData(snapshot: Snapshot | null | undefined, textureEnabled: boolean, suffix?: string) {
  const segmentData: SegmentData[] = [];
  // This is a temporary fix for an issue where Isnapshot is being interepreted as a Snapshot
  let isSplat: boolean | undefined = undefined;
  try {
    isSplat = snapshot?.isSplat();
  } catch {
    console.warn("snapshot definition does not include isSplat()");
  }
  snapshot?.segments?.forEach((segment) => {
    const lodFiles = segment.lodFiles ? segment.lodFiles.map((lod) => lod) : [];
    if (!textureEnabled || isSplat) {
      lodFiles.forEach((lod) => {
        lod.file = lod.meshFile;
      });
    }

    const identifiers = lodFiles
      ? (lodFiles.map((lod) => {
          let modelIdentifier = lod.file?.toString();
          if (suffix && modelIdentifier) {
            modelIdentifier = addToFileName(modelIdentifier, suffix);
          }
          return modelIdentifier;
        }) as string[])
      : [];

    if (lodFiles.length > 0) {
      segmentData.push({
        id: segment.id,
        lodFiles: lodFiles,
        identifiers: identifiers,
        scale: snapshot.scale || 1,
      });
    } else {
      // TODO: Kept in For backwards compatibility, To be Removed when segments no longer store data
      let modelIdentifier = textureEnabled ? segment.file?.toString() : segment.meshFile?.toString();
      if (is3DObjectFile(modelIdentifier || "")) {
        if (suffix && modelIdentifier) {
          modelIdentifier = addToFileName(modelIdentifier, suffix);
        }
        segmentData.push({
          id: segment.id,
          lodFiles: [
            new LODFile({
              path: segment.path,
              file: textureEnabled ? segment.file : segment.meshFile,
              lodLevel: 0,
            } as LODFile),
          ],
          identifiers: [modelIdentifier || ""],
          scale: snapshot.scale || 1,
        });
      }
    }
  });

  return segmentData;
}

export const getModels = (snapModelData: SegmentData[] | SegmentData[][], scene: THREE.Scene | THREE.Group) => {
  const models: THREE.Object3D[] = [];
  snapModelData.flat().forEach((data) => {
    data.identifiers.forEach((identifier) => {
      const obj = scene.getObjectByName(identifier || "");
      if (obj) {
        models.push(obj);
      }
    });
  });
  return models;
};

export function useLoadModel(
  snap: Snapshot | Snapshot[] | undefined | null,
  mutateKey: ReactText[],
  scene: THREE.Scene,
  updateMaterialEncoding: (mats: THREE.Material[]) => THREE.Material | THREE.Material[],
  setModelBoundingBox?: (state: THREE.Box3 | ((prevState: THREE.Box3 | null) => THREE.Box3 | null) | null) => void,
  setIsLoading?: (isLoading: boolean) => void,
  alertView?: (messsage: string) => void,
  multiObj = false,
  floorplanObj?: THREE.Group | undefined
) {
  const [snapModelObjects, setSnapModelObjects] = useState<THREE.Group[]>([]);
  const { textureEnabled } = useContext(MeshToggleContext);
  const { dispatch: dispatchSnackbarState } = useSnackbar();
  const { selectedSnapshot: snapshot } = useContext(SnapshotContext);
  const { facility } = useContext(FacilityContext);
  const { setModelBoundingTreeComputed } = useContext(SceneContext);
  const [_, setLodsAdded] = useState(0);
  const [snapModelData, setSnapModelData] = useState<SegmentData[][] | undefined>(undefined);

  useEffect(() => {
    setSnapModelData((prev) => {
      const segmentData: SegmentData[][] = [];
      if (Array.isArray(snap)) {
        snap.forEach((currSnap: Snapshot) => {
          segmentData.push(getSnapshotModelData(currSnap, textureEnabled));
        });
      } else {
        segmentData.push(getSnapshotModelData(snap, textureEnabled));
      }
      return isEqual(prev, segmentData) ? prev : segmentData;
    });
  }, [snap, textureEnabled, snapshot]);

  const [hotload, setHotload] = useState<boolean>(false);

  const offsetModel = useCallback((offset: Matrix4 | undefined | null, target: any) => {
    if (offset && target) {
      transformObject(target, offset, true);
    }
  }, []);

  const modelUpdateCallback = useCallback(() => {
    setHotload(true);
    mutate(mutateKey);
  }, [mutateKey]);

  useUpdateHandler(MESSAGE_TYPE.MODEL_UPDATE, modelUpdateCallback);

  const resetScene = useCallback(
    (allSegments: boolean) => {
      const segments = scene.children.filter((obj) => {
        if (obj.userData.name) {
          return (
            obj.userData.name &&
            obj.userData.name === MODEL_NAME &&
            (allSegments ||
              (!multiObj
                ? Array.isArray(snap)
                  ? !snap.map((snap: Snapshot) => snap.id).includes(obj.userData.id)
                  : obj.userData.id !== snap?.id
                : !facility?.snapshots?.map((snap: Snapshot) => snap.id).includes(obj.userData.id)))
          );
        } else {
          return false;
        }
      });
      if (segments) {
        segments.forEach((modelChild) => {
          if (modelChild instanceof THREE.Mesh) {
            modelChild.material?.dispose();
            modelChild.geometry?.dispose();
          }
          scene.remove(modelChild);
        });
      }
    },
    [scene, facility, multiObj, snap]
  );

  useEffectWhen(
    () => {
      // This is a temporary fix for an issue where Isnapshot is being interepreted as a Snapshot
      let isSplat: boolean | undefined = undefined;
      try {
        isSplat = snapshot?.isSplat();
      } catch {
        console.warn("snapshot definition does not include isSplat()");
      }
      const onComplete = (insightProperties: InsightProperties) => {
        if (setIsLoading) setIsLoading(false);
        insightsTracker.trackEvent({
          name: "Model Load Ends",
          properties: insightProperties,
        });
      };

      const onFailure = (
        insightProperties?: InsightProperties,
        message = "There was an error loading the model associated to this snapshot"
      ) => {
        if (setIsLoading) setIsLoading(false);
        dispatchSnackbarState({
          type: SnackbarActionType.OPEN,
          message: message,
        });
        if (insightProperties) {
          insightsTracker.trackEvent({
            name: "Model Load Failed",
            properties: insightProperties,
          });
        }
      };
      if (!snapModelData) {
        if (setIsLoading) setIsLoading(false);
        return;
      }

      resetScene(true);

      if (setModelBoundingBox) setModelBoundingBox(null);

      if (setIsLoading && !isSplat) {
        setIsLoading(true);
      }

      try {
        setSnapModelObjects([]);
        setLodsAdded(0);
        setModelBoundingTreeComputed(false);
        const numSegments = snapModelData.reduce((currentCount, currSnapData) => currentCount + currSnapData.length, 0);
        snapModelData.forEach((currSnapData: SegmentData[], snapIdx) => {
          const modelObj = new THREE.Group();
          modelObj.userData = {
            name: MODEL_NAME,
            id: multiObj
              ? facility?.snapshots
                ? facility.snapshots[snapIdx].id
                : undefined
              : Array.isArray(snap) && snap[snapIdx]
              ? snap[snapIdx].id
              : snapshot?.id,
          };
          currSnapData?.forEach((modelData, segmentIdx) => {
            const lod = new LOD();
            modelData.lodFiles.forEach((lodFile, idx) => {
              const currModelName = lodFile.file?.toString();
              const identifier = modelData.identifiers[idx];
              const segmentFile = lodFile.file;
              if (snap && segmentFile && modelData.scale && identifier) {
                const modelScale = modelData.scale;
                const model = segmentFile.toString();
                if (!lod.name) {
                  lod.name = model;
                }
                const ext = getExtension(model);
                const loader = new ObjectLoader();

                const insightProperties = {
                  ext: ext,
                  asset: model,
                  modelId: modelData.id,
                  correlation: Math.floor(Math.random() * 100000), // something random to join beginnings with ends
                  snapshot: snapshot,
                };
                insightsTracker.trackEvent({
                  name: "Model Load Begins",
                  properties: insightProperties,
                });

                if (loader) {
                  loader
                    .load(model, currModelName, () => {})
                    .then(
                      (
                        object:
                          | THREE.Group
                          | THREE.Scene
                          | THREE.Mesh<THREE.PlaneGeometry, THREE.MeshLambertMaterial>
                          | THREE.Points<THREE.BufferGeometry, THREE.Material | THREE.Material[]>
                          | undefined
                      ) => {
                        if (object) {
                          object.traverse((child) => {
                            setLayers(child, RenderLayer.FRUSTUM_LAYER);
                            if (child instanceof THREE.Mesh) {
                              if (isSplat) {
                                child.material = invisibleMaterial;
                                child.renderOrder = 0;
                              }
                              child.material = updateMaterialEncoding(
                                child.material instanceof THREE.Material
                                  ? [child.material]
                                  : (child.material as THREE.Material[])
                              );
                            }
                          });

                          object.scale.set(modelScale, modelScale, modelScale);
                          object.name = identifier;
                          lod.addLevel(object, Math.pow(lodFile.lodLevel || 0, 2) * 4);
                          modelObj.add(lod);
                          if (setModelBoundingBox) {
                            setModelBoundingBox((prev) => {
                              const newBox = new THREE.Box3().setFromObject(modelObj);
                              if (
                                (prev &&
                                  Math.abs(newBox.max.x) !== Infinity &&
                                  getMaxObjectDim(prev) >= getMaxObjectDim(newBox)) ||
                                Math.abs(newBox.max.x) === Infinity
                              ) {
                                return prev;
                              }
                              return newBox;
                            });
                          }
                          setLodsAdded((prev) => {
                            if (prev >= numSegments - 1) {
                              onComplete(insightProperties);
                              return 0;
                            } else {
                              return prev + 1;
                            }
                          });
                          setHotload((prev) => {
                            if (prev && setIsLoading) {
                              setIsLoading(false);
                            }
                            return prev;
                          });
                        } else {
                          onFailure(insightProperties);
                        }
                      }
                    )
                    .catch((e) => {
                      console.error(e);
                      onFailure(insightProperties);
                    });
                } else {
                  onFailure(insightProperties);
                }
              }
            });
            modelObj.add(lod);
          });
          if (Array.isArray(snap) && snap[snapIdx]) {
            offsetModel(snap[snapIdx]?.transform, modelObj);
          }
          scene.add(modelObj);
          setSnapModelObjects((prev) => [...prev, modelObj]);
        });
        if (floorplanObj) {
          setSnapModelObjects((prev) => [...prev, floorplanObj]);
        }
        if (
          (!snapModelData || snapModelData.flat().length === 0) &&
          snap &&
          !Array.isArray(snap) &&
          (snap.segments?.length === 0 ||
            !snap.segments?.filter(
              (segment) => segment.file !== null || (segment.lodFiles && segment.lodFiles.length > 0)
            ))
        ) {
          onFailure(undefined, "There is no model associated to this snapshot");
        }
      } catch (error) {
        if (alertView) {
          alertView("There was an error loading the model.");
        }
        const insightProperties = { snapshot: snapshot };
        onFailure(insightProperties);

        setHotload(false);
      }

      return () => {
        resetScene(false);
      };
    },
    [
      alertView,
      offsetModel,
      scene,
      setIsLoading,
      setModelBoundingBox,
      snap,
      updateMaterialEncoding,
      multiObj,
      dispatchSnackbarState,
      snapshot,
      resetScene,
      facility,
      setModelBoundingTreeComputed,
      snapModelData,
      hotload,
      textureEnabled,
      floorplanObj,
    ],
    [snapModelData, hotload, textureEnabled, floorplanObj]
  );

  return { snapModelObjects, setSnapModelObjects, hotload };
}
