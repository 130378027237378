import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export default function LoadingDialog({ processing, msg }: { processing: boolean; msg: string }) {
  return (
    <Dialog open={processing} style={{ borderRadius: 0 }}>
      <DialogTitle variant="h1">{msg}</DialogTitle>
      <DialogContent>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <CircularProgress color="inherit" size={50} thickness={3} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
