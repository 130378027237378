import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { AssetInfo } from "../serviceTypes/AssetInfo";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const AssetInfoSWRKeys = {
  SIMPLE_ASSET_INFOS: "simpleAssetInfos",
  ASSET_INFOS: "assetInfos",
  ASSET_INFO: "assetInfo",
};

export class AssetInfoService extends ServiceBase<AssetInfo> {
  constructor(apiInstance: APIInstance) {
    super(AssetInfo, apiInstance, ENDPOINTS.ASSET_INFOS);
  }
}
