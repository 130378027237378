import { matchSorter as ms, MatchSorterOptions } from "match-sorter";

import { descendingComparator, descendingDatesComparator } from "./DescendingComparatorUtils";

export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
}

export function GetComparatorFcn(
  order: SortDirection | undefined,
  orderBy: string,
  isMoment: boolean,
  secondaryKey?: string
) {
  // Returns the proper comparator function based on the sort order specified (asc or desc) and the property to be sorted.
  if (isMoment) {
    return (a: any, b: any) => (order === SortDirection.DESC ? 1 : -1) * descendingDatesComparator(a, b, orderBy);
  }
  return (a: any, b: any) =>
    (order === SortDirection.DESC ? 1 : -1) * descendingComparator(a, b, orderBy, secondaryKey, order);
}

export function StableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  // Sorts a given array with a given comparator function.

  const arrayWithIndex = array.map((val, index) => {
    return { value: val, index: index };
  });
  arrayWithIndex.sort((a, b) => {
    const order = comparator(a.value, b.value);
    if (order) {
      return order;
    }
    return a.index - b.index; // when a[0] == b[0]
  });
  return arrayWithIndex.map((el) => el.value); // Only returns the values, removes the index
}

/******************************
 * desc - match sorting function used in autocomplete
 ******************************/
export function matchSorter<ItemType = string>(
  items: ReadonlyArray<ItemType>,
  value: string,
  options?: MatchSorterOptions<ItemType>
): Array<ItemType> {
  return ms(items, value, { ...options, threshold: ms.rankings.CONTAINS });
}
