import { OrderedSnapshot } from "../types";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof SnapshotCollection | undefined } = {
  id: "id",
  name: "name",
  date_started: "startDate",
  date_completed: "endDate",
  completed: "completed",
  current_step: "currentStep",
  is_template: "isTemplate",
  parent: "parent",
};

export const DEFAULT_WORKORDER_NAME = "Unnamed Workorder";

export class SnapshotCollection extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public startDate?: string | null;
  public endDate?: string | null;
  public completed?: boolean | null;
  public snapshots?: OrderedSnapshot[] | null;
  public currentStep?: string | null;
  public isTemplate?: boolean | null;
  public parent?: string | null;

  constructor(asset?: SnapshotCollection) {
    super();
    if (asset) {
      Object.assign(this, asset);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<SnapshotCollection>(this, serverToView, json);
    if (json.snapshots) {
      this.snapshots = [];
      json.snapshots.forEach((element: any) => {
        if (this.snapshots) {
          this.snapshots.push({ id: element.snapshot_id, step: element.step_number });
        }
      });
      this.name = json.name || DEFAULT_WORKORDER_NAME;
    }
  }

  serialize(): any {
    const request = serializerHelper<SnapshotCollection>(this, serverToView);
    request.snapshots = this.snapshots
      ? this.snapshots.map((ordered) => {
          return { snapshot_id: ordered.id, step_number: ordered.step };
        })
      : this.snapshots;
    return request;
  }
}
