import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { MediaCapture } from "../serviceTypes/MediaCapture";
import { threeMatrix4ToMatrix4 } from "components/CameraSelector/Common/ThreeUtils";
import { CameraObject } from "components/CameraSelector/types";
import { CaptureType, PoseType } from "services/ContentServer/Audit";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const MediaSWRKeys = {
  TEMPLATE_MEDIA_CAPTURES: "templateImages",
  TEMPLATE_MEDIA_CAPTURE: "templateImage",
  MEDIA_CAPTURES: "images",
  MEDIA_CAPTURES_WITH_HIST: "images_with_hist",
  MEDIA_CAPTURE: "image",
};

export class MediaCaptureService extends ServiceBase<MediaCapture> {
  constructor(apiInstance: APIInstance) {
    super(MediaCapture, apiInstance, ENDPOINTS.MEDIA_CAPTURE);
  }

  postMediaCapture(poses: CameraObject, media: MediaCapture) {
    const requestedPose = poses.object.clone();
    requestedPose.scale.set(1, 1, 1);
    requestedPose.updateMatrix();

    const mediaPoseMatrix = threeMatrix4ToMatrix4(requestedPose.matrix);

    const mediaPose = new MediaCapture({
      ...media,
      timestamp: new Date().toISOString(),
      pose: mediaPoseMatrix,
    } as MediaCapture);
    return super.post(mediaPose);
  }

  async retakeCapture(prevCapture: MediaCapture) {
    if (prevCapture.id) {
      const newCapture = new MediaCapture();
      newCapture.pose = prevCapture.pose;
      newCapture.img = null;
      newCapture.video = null;
      newCapture.timestamp = new Date().toISOString();
      newCapture.retakePose = true;
      newCapture.hidePose = prevCapture.hidePose;
      newCapture.poseType = PoseType.Request;
      const image = await fetch(prevCapture.img);
      const blob = await image.blob();
      const file = new File([blob], "capture_request.jpg", { type: blob.type });
      newCapture.requestedImg = file;
      newCapture.captureType = CaptureType.None;
      return super.patch(prevCapture.id, newCapture);
    }
    return Promise.resolve(prevCapture);
  }

  async postAutoCapture(id: string, snapshotId: string) {
    const data = { id: id, snapshot_id: snapshotId };
    const resp = await super.postCustomFormData(data, "api/autocaptures/");
    return resp.object;
  }
}
