import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Label } from "../serviceTypes/Label";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const LabelSWRKeys = {
  LABELS: "labels",
  LABEL: "label",
};
export class LabelService extends ServiceBase<Label> {
  constructor(apiInstance: APIInstance) {
    super(Label, apiInstance, ENDPOINTS.LABELS);
  }

  async createBulkChecklist(file: any) {
    const data = { file: file };
    const resp = await this.postCustomFormData(data, `${ENDPOINTS.BULK_CHECKLIST_CREATION}`);
    return resp.data;
  }
}
