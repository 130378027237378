import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { Autocomplete, Box, Checkbox, Paper, PaperProps, TextField, Typography } from "@mui/material";
import CheckedOptionBox from "icons/CheckedOptionBox";
import DropdownFilterCheckBoxIcon from "icons/DropdownFilterCheckBoxIcon";
import React, { useCallback, useMemo, useState, useEffect } from "react";

import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";

export interface TeamData {
  data: string;
  displayVal: string;
  usedInParent: boolean;
  colour: string;
}

const selectAllOption = { data: "ALL", displayVal: "Select all", usedInParent: false } as TeamData;

function PaperComponent(props: PaperProps) {
  return <Paper {...props} sx={{ borderRadius: 0, boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)", height: "auto" }} />;
}

interface ITeamSelectionProps {
  viewOnly: boolean;
  options?: TeamData[];
  placeholderText?: string;
  values: TeamData[];
  textOnly?: boolean;
  limitTags?: number;
  setSelectedTeams?: React.Dispatch<React.SetStateAction<TeamData[]>>;
}

const TeamSelectionField: React.FC<ITeamSelectionProps> = ({
  viewOnly,
  options,
  placeholderText,
  values,
  textOnly,
  limitTags,
  setSelectedTeams,
}) => {
  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    if (options && values.length === options.length && options.length !== 0) {
      setAllSelected(true);
    }
  }, [values, options]);

  const teamChip = useCallback(
    (item: TeamData) => (
      <Box
        key={item.data}
        sx={{
          padding: "6px 12px",
          borderRadius: "100px",
          backgroundColor: item.colour ? item.colour : "lightblue",
          margin: "5px 8px 5px 0",
          maxWidth: "93%",
        }}
      >
        <EllipsisTooltip
          variant="body2"
          sx={{
            filter: "invert(1) grayscale(1) contrast(10)",
            color: item.colour ? item.colour : "lightblue",
          }}
        >
          {item.displayVal}
        </EllipsisTooltip>
      </Box>
    ),
    []
  );

  const allOptions = useMemo(() => {
    if (options && options.length) {
      return [selectAllOption].concat(options);
    }
    return [];
  }, [options]);

  const teamField = useMemo(() => {
    if (viewOnly) {
      if (textOnly) {
        return (
          <Box>
            <Typography variant="body1">
              {values.map((item, idx) => {
                return idx === values.length - 1 || values.length === 1 ? item.displayVal : item.displayVal + ", ";
              })}
            </Typography>
          </Box>
        );
      }
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {values.map((item) => {
            return teamChip(item);
          })}
        </Box>
      );
    }
    return (
      <Autocomplete
        id="search-bar"
        size="small"
        multiple={true}
        value={values}
        onOpen={() => {
          if (setSelectedTeams && values.length === allOptions.length - 1) {
            if (!values.find((option) => !option.usedInParent)) {
              selectAllOption.usedInParent = true;
            }
            options && setSelectedTeams(options);
            setAllSelected(true);
          }
        }}
        disableClearable={true}
        disableCloseOnSelect={true}
        clearOnBlur={true}
        limitTags={limitTags ? (allSelected ? limitTags + 1 : limitTags) : 3}
        popupIcon={textOnly ? <ArrowDropDownIcon /> : <KeyboardArrowDownOutlined />}
        sx={{
          width: "100%",
          ".MuiAutocomplete-inputRoot": {
            padding: !textOnly ? "0 0 0 8px!important" : "",
            minHeight: "36px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "140%",
            color: "#64676A",
            input: {
              marginRight: "25px",
            },
          },
        }}
        PaperComponent={PaperComponent}
        options={allOptions}
        getOptionLabel={(option: any) => option.displayVal}
        isOptionEqualToValue={(option, value) => {
          return option && value ? option.data === value.data : false;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.data}>
            {textOnly ? (
              <>
                <Checkbox
                  icon={<DropdownFilterCheckBoxIcon />}
                  checkedIcon={<CheckedOptionBox />}
                  checked={option.data === "ALL" && allSelected ? true : selected}
                />
                <EllipsisTooltip variant="dalmatianP">{option.displayVal}</EllipsisTooltip>
              </>
            ) : (
              <>
                <Checkbox
                  icon={<CheckBoxOutlineBlankSharpIcon sx={{ fontSize: "20px", color: "black" }} />}
                  checkedIcon={<CheckBoxSharpIcon sx={{ fontSize: "20px" }} />}
                  style={{ width: "20px", height: "20px", marginRight: 8 }}
                  checked={option.data === "ALL" && allSelected ? true : selected}
                />
                <EllipsisTooltip sx={{ fontSize: "14px", fontFamily: "Inter", weight: 400, color: "black" }}>
                  {option.displayVal}
                </EllipsisTooltip>
              </>
            )}
          </li>
        )}
        getOptionDisabled={(option) => {
          if (option.usedInParent) return true;
          return false;
        }}
        onChange={(_, newSelectedItems, reason, details) => {
          // don't allow ability to remove an inherited team
          if (reason === "removeOption" && details?.option.usedInParent) {
            return;
          }

          if (setSelectedTeams) {
            if (details?.option.data === "ALL") {
              // click select all when not all options are selected
              if (reason === "selectOption" && newSelectedItems.length !== allOptions.length) {
                options && setSelectedTeams(options);
                setAllSelected(true);
              }
              // click select all when all options are selected
              else {
                setSelectedTeams((prev: TeamData[]) => prev.filter((option) => option.usedInParent));
                setAllSelected(false);
              }
            } else {
              // click a team to add but it is the only remaining unselected team
              if (reason === "selectOption" && newSelectedItems.length === allOptions.length - 1) {
                options && setSelectedTeams(options);
                setAllSelected(true);
              }
              // click a team to remove when everything is selected
              else if (reason === "removeOption" && newSelectedItems.length === allOptions.length - 1) {
                setSelectedTeams(newSelectedItems.filter((option) => option.data !== "ALL"));
                setAllSelected(false);
              }
              // click on a team to add/remove
              else {
                setSelectedTeams(newSelectedItems);
                setAllSelected(false);
              }
            }
          }
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholderText && values.length === 0 ? placeholderText : ""} />
        )}
        renderTags={(selectedItems) => {
          return !allSelected && selectedItems.length ? (
            selectedItems.map((item: any, idx) => {
              if (item.data === "ALL") return;
              if (textOnly) {
                return (
                  <EllipsisTooltip key={item.data} variant="body1">
                    {idx === selectedItems.length - 1 || selectedItems.length === 1
                      ? item.displayVal
                      : item.displayVal + ", "}
                  </EllipsisTooltip>
                );
              }
              return teamChip(item);
            })
          ) : allSelected && !viewOnly ? (
            <Typography variant="body1">All Teams</Typography>
          ) : (
            []
          );
        }}
      />
    );
  }, [
    options,
    setSelectedTeams,
    placeholderText,
    viewOnly,
    values,
    teamChip,
    textOnly,
    limitTags,
    allOptions,
    allSelected,
  ]);

  return teamField;
};

export default TeamSelectionField;
