import {
  PoseType,
  PoseTypeServer,
  Matrix4,
  CaptureType,
  CaptureTypeServerToView,
  CaptureTypeViewToServer,
} from "../types";
import { VisualFeatures } from "./VisualFeatures";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof MediaCapture | undefined } = {
  id: "id",
  name: "name",
  notes: "notes",
  requested_image_pose: undefined,
  image_pose: "pose",
  pose_type: undefined,
  image: "img",
  low_res_image: "lowResImg",
  requested_image: "requestedImg",
  video: "video",
  depth_map: "depthMap",
  last_modified_by: "lastModifiedBy",
  last_modified_at: "lastModifiedAt",
  created_at: "createdAt",
  created_by: "createdBy",
  device: "device",
  timestamp: "timestamp",
  snapshot_id: "snapshot",
  update_to_id: "revertId",
  guidance: "guidance",
  hide_pose: "hidePose",
  flag: "flag",
  is_template: "isTemplate",
  parent: "parent",
  snapshot_name: "snapshotName",
  rotate: "rotate",
  labels: "labels",
  state: "state",
  has_history: "hasHistory",
};

export const PoseTypeServerToView = new Map<PoseTypeServer, PoseType>([
  [PoseTypeServer.Manual, PoseType.Manual],
  [PoseTypeServer.Request, PoseType.Request],
  [PoseTypeServer.Template, PoseType.Template],
  [PoseTypeServer.Automated, PoseType.Automated],
]);
export const PoseTypeViewToServer = new Map([...PoseTypeServerToView.entries()].map(([key, value]) => [value, key]));

export class MediaCapture extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public notes?: string | null;
  public img?: any | null;
  public lowResImg?: any | null;
  public requestedImg?: any | null;
  public depthMap?: any | null;
  public video?: any | null;
  public timestamp?: any | null;
  public pose?: Matrix4 | null;
  public retakePose?: boolean | null;
  public fulfillPose?: boolean | null;
  public poseType?: PoseType | null;
  public captureType?: CaptureType | null;
  public snapshot?: string | null;
  public title?: string | null;
  public lastModifiedBy?: string | null;
  public lastModifiedAt?: string | null;
  public createdAt?: string | null;
  public createdBy?: string | null;
  public device?: string | null;
  public revertId?: string | null;
  public guidance?: string | null;
  public hidePose?: boolean | null;
  public flag?: boolean | null;
  public isTemplate?: boolean | null;
  public parent?: string | null;
  public snapshotName?: string | null;
  public requirementId?: string | null;
  public rotate?: number | null;
  public labels?: string[] | null;
  public context?: VisualFeatures | null;
  public captureRequest?: boolean;
  public state?: string | null;
  public hasHistory?: boolean;

  constructor(image?: MediaCapture) {
    super();
    if (image) {
      Object.assign(this, image);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<MediaCapture>(this, serverToView, json);
    this.pose = json.image_pose ? json.image_pose : json.requested_image_pose;
    this.poseType = PoseTypeServerToView.get(json.pose_type);
    this.captureType = json.capture_type ? CaptureTypeServerToView.get(json.capture_type) : CaptureType.None;
    this.captureRequest = this.captureType !== CaptureType.Image && this.captureType !== CaptureType.Video;
    this.title = this.id;
    if (json.context) {
      const visualFeatures = new VisualFeatures();
      visualFeatures.deserialize(json.context);
      this.context = visualFeatures;
    }
  }

  serialize(): any {
    const request = serializerHelper<MediaCapture>(this, serverToView);
    if (this.pose !== undefined) {
      request.requested_image_pose = JSON.stringify(this.pose);
    }
    if (this.fulfillPose) {
      request.image_pose = JSON.stringify(this.pose);
    }
    if (this.retakePose) {
      request.image_pose = null;
    }
    if (this.poseType !== undefined) {
      request.pose_type = this.poseType !== null ? PoseTypeViewToServer.get(this.poseType) : null;
    }
    if (this.captureType !== undefined) {
      request.capture_type = this.captureType !== null ? CaptureTypeViewToServer.get(this.captureType) : null;
    }
    if (this.labels !== undefined) {
      request.label_ids = this.labels && this.labels.length > 0 ? this.labels.map((l) => l.toString()) : [];
    }

    return request;
  }
}
