import CheckBoxOutlineBlankSharpIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckBoxSharpIcon from "@mui/icons-material/CheckBoxSharp";
import { Box, Checkbox, Typography, Button, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { TenantFeatureEnum } from "redux/api/types";
import { useLazyGetPermissionsQuery, useGetRoleByIdQuery, useLazyGetDefaultMemberRoleQuery } from "redux/api/userApi";
import useApp from "redux/hooks/useApp";

import { initialRoleState, RoleActionType, roleReducer } from "./roleReducer";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import { SettingsBottomBar } from "components/Settings/SettingsBottomBar";
import useARMediaQuery from "hooks/useARMediaQuery";
import usePermission, { Feature, FEATURES, PERMISSION_NAME, PERMISSION_TYPE } from "hooks/usePermission";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { ADMIN_ROLE, MEMBER_ROLE, PermissionDict, Role } from "services/ContentServer/Identity";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { capitalizeAndRemoveLast } from "utils/NameUtils";

const RolePermissionsTab = ({
  role,
  setProcessing,
  setProcessingMsg,
}: {
  role: Role | undefined;
  setProcessing: (value: boolean | ((prev: boolean) => boolean)) => void;
  setProcessingMsg: (value: string | ((prev: string) => string)) => void;
}) => {
  const theme = useTheme();
  const { contentServer } = useContext(RequestContext);
  const snackbar = useSnackbar();
  const { featureAccess: currentUserAccess } = useUsers();
  const [roleState, dispatchRoleState] = useReducer(roleReducer, initialRoleState);
  const [showBtns, setShowBtns] = useState<boolean>(false);
  const { refetch: refetchRoles } = useGetRoleByIdQuery((role && role.id) ?? skipToken);
  const { featureAccess } = usePermission(roleState.permissions);
  const app = useApp();
  const [getDefaultMember] = useLazyGetDefaultMemberRoleQuery();

  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  const [permissions, setAllPermissions] = useState<PermissionDict>({});
  const [getPermissions] = useLazyGetPermissionsQuery(undefined);

  const roleAccess = useMemo(() => {
    return currentUserAccess[PERMISSION_NAME.ROLES];
  }, [currentUserAccess]);

  useEffect(() => {
    if (role && Object.keys(role.permissions).length === 0) {
      getDefaultMember()
        .unwrap()
        .then((memberData) => {
          dispatchRoleState({
            permissions: { ...memberData.permissions },
            type: RoleActionType.UPDATE_PERMISSIONS,
          });
        });
    }
  }, [role, getDefaultMember, dispatchRoleState]);

  useEffect(() => {
    getPermissions()
      .unwrap()
      .then((permissionData) => {
        setAllPermissions(permissionData);
      });
  }, [getPermissions]);

  const resetPermissions = useCallback((role?: Role) => {
    if (role) {
      dispatchRoleState({
        role: role,
        type: RoleActionType.RESET_ALL,
      });
    }
    setShowBtns(false);
  }, []);

  useEffect(() => {
    resetPermissions(role);
  }, [resetPermissions, role]);

  const blockEditing = useMemo(() => {
    return !role || role.name === ADMIN_ROLE || role.name === MEMBER_ROLE || !roleAccess.update;
  }, [role, roleAccess]);

  function not(a: PermissionDict, b: PermissionDict) {
    return Object.assign(
      {},
      ...Object.entries(a)
        .filter(([k, _]) => !(k in b))
        .map(([k, v]) => ({ [k]: v }))
    );
  }

  const union = useCallback((a: PermissionDict, b: PermissionDict) => {
    return { ...a, ...not(b, a) };
  }, []);

  const getAccess = useCallback(
    (feature: Feature, type: PERMISSION_TYPE) => {
      switch (type) {
        case PERMISSION_TYPE.READ:
          return featureAccess[feature.name].read;
        case PERMISSION_TYPE.ADD:
          return featureAccess[feature.name].add;
        case PERMISSION_TYPE.DELETE:
          return featureAccess[feature.name].delete;
        case PERMISSION_TYPE.UPDATE:
          return featureAccess[feature.name].update;
      }
    },
    [featureAccess]
  );

  const setPermissions = useCallback(
    (permissionNames: string[], permissionType: PERMISSION_TYPE, value: boolean) => {
      const newPermissions: PermissionDict = {};
      permissionNames.forEach((permissionName: string) => {
        const permissionId = permissionType + permissionName;
        if (value && !(permissionType + permissionName in roleState.permissions)) {
          newPermissions[permissionId] = permissions[permissionType + permissionName];
        }
        if (!value && permissionType + permissionName in roleState.permissions) {
          newPermissions[permissionId] = permissions[permissionType + permissionName];
        }
      });
      if (value) {
        dispatchRoleState({
          permissions: union(roleState.permissions, newPermissions),
          type: RoleActionType.UPDATE_PERMISSIONS,
        });
      }
      if (!value) {
        dispatchRoleState({
          permissions: not(roleState.permissions, newPermissions),
          type: RoleActionType.UPDATE_PERMISSIONS,
        });
      }
    },
    [roleState.permissions, permissions, union]
  );

  const applyPermsToRole = useCallback(async () => {
    if (role === undefined) {
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error updating role permissions. Role was not found",
      });
      console.error("Role is undefined.");
      return;
    }
    setProcessingMsg("Updating role permissions...");
    setProcessing(true);
    const newRole = { ...role, permissions: roleState.permissions };
    try {
      await contentServer.identityService.updateRole(newRole);
      refetchRoles();

      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Successfully updated role permissions.",
      });
    } catch (err) {
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error updating role permissions.",
      });
      console.error(err);
    }
    setProcessing(false);
    setProcessingMsg("");
  }, [
    contentServer.identityService,
    setProcessing,
    setProcessingMsg,
    snackbar,
    role,
    roleState.permissions,
    refetchRoles,
  ]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          pt: 3,
          height: "100%",
          width: "100%",
        }}
      >
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box
            sx={{
              minHeight: "40px",
              height: "40px",
              overflowY: "scroll",
              width: "100%",
              px: matchesSM ? 6 : matchesMD ? 8 : 13,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ minWidth: "100px", width: "20%", maxWidth: "160px" }}>
                <Typography variant="body1" align="center"></Typography>
              </Box>

              {Object.values(PERMISSION_TYPE).map((permissionType: PERMISSION_TYPE, typeIdx: number) => {
                return (
                  <Box
                    key={typeIdx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0px",
                      width: "60px",
                      maxWidth: "60px",
                    }}
                  >
                    <Typography variant="h5">{capitalizeAndRemoveLast(permissionType)}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              maxHeight: useWindowSize().height - (!blockEditing && showBtns ? 280 : 210),
              overflowY: "scroll",
              width: "100%",
              px: matchesSM ? 6 : matchesMD ? 8 : 13,
              pb: 3,
            }}
          >
            {FEATURES.map((feature, idx) => {
              if (feature.name === "Site" && !app.isFeatureEnabled(TenantFeatureEnum.AssetV1)) {
                return;
              }

              if (!app.isFeatureEnabled(TenantFeatureEnum.AssetV2)) {
                if (feature.name === "Team" || feature.name == "Folder") return;
              }

              return (
                feature.editable && (
                  <Box
                    key={feature.name}
                    sx={{
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      py: 2,
                      borderBottom: idx !== FEATURES.length - 2 ? `1px solid ${theme.palette.secondary.dark}` : "",
                    }}
                  >
                    <Box sx={{ minWidth: "100px", width: "20%", maxWidth: "160px", height: "100%" }}>
                      <EllipsisTooltip variant="body1" align="center">
                        {feature.name}
                      </EllipsisTooltip>
                    </Box>

                    {Object.values(PERMISSION_TYPE).map((permissionType: PERMISSION_TYPE, typeIdx: number) => {
                      return (
                        <Box
                          key={typeIdx}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px",
                            width: "60px",
                            height: "100%",
                          }}
                        >
                          <Checkbox
                            disableRipple
                            disabled={blockEditing}
                            style={{ color: blockEditing ? "#95989B" : theme.palette.primary.main }}
                            checked={getAccess(feature, permissionType)}
                            onClick={() => {
                              setPermissions(feature.subsets, permissionType, !getAccess(feature, permissionType));
                              setShowBtns(true);
                            }}
                            icon={<CheckBoxOutlineBlankSharpIcon />}
                            checkedIcon={<CheckBoxSharpIcon />}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>

        {!blockEditing && showBtns && (
          <SettingsBottomBar show={true}>
            <>
              <Button variant="outlined" onClick={() => resetPermissions(role)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={async () => await applyPermsToRole()}>
                Save Changes
              </Button>
            </>
          </SettingsBottomBar>
        )}
      </Box>
    </>
  );
};

export default RolePermissionsTab;
