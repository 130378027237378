import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { Matrix4 } from "services/ContentServer/Audit";
import { Snapshot } from "services/ContentServer/Audit/serviceTypes/Snapshot";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Facility | undefined } = {
  id: "id",
  name: "name",
  map_file: "mapFile",
  map_scale: "mapScale",
  map_transform: "mapTransform",
  map_opacity: "mapOpacity",
  description: "description",
  parent_id: "parentId",
};

export class Facility extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public snapshots?: Snapshot[] | null;
  public mapFile?: string | Blob | File | null;
  public mapScale?: number | null;
  public mapOpacity?: number | null;
  public mapTransform?: Matrix4 | null;
  public description?: string | null;
  public folderId?: string | null;
  public parentId?: string | null;

  constructor(facility?: Facility) {
    super();
    Object.assign(this, facility);
  }

  deserialize(json: any): void {
    deserializerHelper<Facility>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Facility>(this, serverToView);
    return request;
  }
}
