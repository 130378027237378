import { useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

import { useNotifyRef, useNotifyRefArray } from "./useNotifyRef";

export const useResizeObserver = (
  element: HTMLElement | null,
  onResize: ({ width, height }: { width: number; height: number }) => void
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        entries.forEach((entry) => onResize({ width: entry.contentRect.width, height: entry.contentRect.height }));
      });
    });

    if (element) {
      resizeObserver.observe(element);
      return () => resizeObserver.unobserve(element);
    }
  }, [element, onResize]);

  return null;
};

export const useRefResizeObserver = (onResize: ({ width, height }: { width: number; height: number }) => void) => {
  const [element, ref] = useNotifyRef<HTMLElement>();

  useResizeObserver(element, onResize);
  return { ref: ref, element: element };
};

const useOverflowObserverArray = (
  elements: HTMLDivElement[],
  setOverflowArrayOnResize: (overflowStates: boolean[] | ((prev: boolean[]) => boolean[])) => void
) => {
  useEffect(() => {
    const resizeObserverArray: ResizeObserver[] = [];
    elements.forEach((element: HTMLDivElement, idx: number) => {
      const setOverflowState = (overflowState: boolean) => {
        setOverflowArrayOnResize((prev: boolean[]) => {
          const newStates = prev.slice();
          newStates[idx] = overflowState;
          return newStates;
        });
      };
      const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          entries.forEach((entry) => setOverflowState(entry.target.clientWidth < entry.target.scrollWidth));
        });
      });
      resizeObserverArray[idx] = resizeObserver;

      if (element) {
        resizeObserver.observe(element);
      }
    });

    return () => {
      elements.forEach((element, idx) => {
        if (element) {
          resizeObserverArray[idx].unobserve(element);
        }
      });
    };
  }, [elements, setOverflowArrayOnResize]);

  return null;
};

export const useRefOverflowObserverArray = (
  setOverflowStateArray: (overflowStates: boolean[] | ((prev: boolean[]) => boolean[])) => void
) => {
  const [elements, addRef] = useNotifyRefArray<HTMLDivElement>();

  useOverflowObserverArray(elements, setOverflowStateArray);

  return addRef;
};
