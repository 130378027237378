import { matrix, subtract, add, divide, transpose, multiply, det, inv } from "mathjs";
import { SVD } from "svd-js";
import * as THREE from "three";

import { Matrix4 } from "services/ContentServer/Audit";

export function poseMatrixToPositionVector(pose: Matrix4 | undefined | null) {
  if (pose) {
    const tVec = [pose.m03, pose.m13, pose.m23];
    return tVec;
  } else {
    return [0, 0, 0];
  }
}

export function poseMatrixToRotationMatrix(pose: Matrix4 | undefined | null) {
  if (pose) {
    const rotationMatrix = [
      [pose.m00, pose.m01, pose.m02],
      [pose.m10, pose.m11, pose.m12],
      [pose.m20, pose.m21, pose.m22],
    ];
    return rotationMatrix;
  } else {
    return [
      [1, 0, 0],
      [0, 1, 0],
      [0, 0, 1],
    ];
  }
}

export function inverseMatrix(pose: Matrix4) {
  const matrix = [
    [pose.m00, pose.m01, pose.m02, pose.m03],
    [pose.m10, pose.m11, pose.m12, pose.m13],
    [pose.m20, pose.m21, pose.m22, pose.m23],
    [pose.m30, pose.m31, pose.m32, pose.m33],
  ];
  const reverse = inv(matrix);

  return {
    m00: reverse[0][0],
    m01: reverse[0][1],
    m02: reverse[0][2],
    m03: reverse[0][3],
    m10: reverse[1][0],
    m11: reverse[1][1],
    m12: reverse[1][2],
    m13: reverse[1][3],
    m20: reverse[2][0],
    m21: reverse[2][1],
    m22: reverse[2][2],
    m23: reverse[2][3],
    m30: reverse[3][0],
    m31: reverse[3][1],
    m32: reverse[3][2],
    m33: reverse[3][3],
  };
}

export const identityMatrix = matrix([
  [1, 0, 0, 0],
  [0, 1, 0, 0],
  [0, 0, 1, 0],
  [0, 0, 0, 1],
]);

export function clamp(value: number, min: number, max: number) {
  return value > max ? max : value < min ? min : value;
}

//Source for math : https://igl.ethz.ch/projects/ARAP/svd_rot.pdf
export function computeTransform(pointPairs: THREE.Vector3[][]): Matrix4 {
  const pVectors: number[][] = pointPairs.map((pointPair) => {
    return [pointPair[0].x, pointPair[0].y, pointPair[0].z];
  });
  const qVectors: number[][] = pointPairs.map((pointPair) => {
    return [pointPair[1].x, pointPair[1].y, pointPair[1].z];
  });

  let pCentroid: number[] = [0, 0, 0];
  pVectors.forEach((pVector: number[]) => {
    pCentroid = add(pCentroid, pVector) as number[];
  });
  pCentroid = divide(pCentroid, pVectors.length) as number[];
  let qCentroid: number[] = [0, 0, 0];
  qVectors.forEach((qVector: number[]) => {
    qCentroid = add(qCentroid, qVector) as number[];
  });
  qCentroid = divide(qCentroid, qVectors.length) as number[];

  const pVectorsCentered: number[][] = pVectors.map((pVector: number[]) => {
    return subtract(pVector, pCentroid) as number[];
  });
  const qVectorsCentered: number[][] = qVectors.map((qVector: number[]) => {
    return subtract(qVector, qCentroid) as number[];
  });

  const PMatrix = transpose(pVectorsCentered);
  const QMatrixTranspose = qVectorsCentered;
  const WMatrix = [];
  for (let i = 0; i < pointPairs.length; i++) {
    const row = [];
    for (let j = 0; j < pointPairs.length; j++) {
      row.push(j === i ? 1 / pointPairs.length : 0);
    }
    WMatrix.push(row);
  }
  const PWMatrix = multiply(PMatrix, WMatrix);
  const SMatrix = multiply(PWMatrix, QMatrixTranspose);
  const { u, v } = SVD(SMatrix);
  const sigma = [];
  for (let i = 0; i < 3; i++) {
    const row = [];
    for (let j = 0; j < 3; j++) {
      if (j === i) {
        if (j === 3) {
          row.push(det(multiply(u, transpose(v))));
        } else {
          row.push(1);
        }
      } else {
        row.push(0);
      }
    }
    sigma.push(row);
  }

  const rotRaw = multiply(multiply(v, sigma), transpose(u));
  const rotMatrix = new THREE.Matrix4();
  rotMatrix.set(
    rotRaw[0][0],
    rotRaw[0][1],
    rotRaw[0][2],
    0,
    rotRaw[1][0],
    rotRaw[1][1],
    rotRaw[1][2],
    0,
    rotRaw[2][0],
    rotRaw[2][1],
    rotRaw[2][2],
    0,
    0,
    0,
    0,
    1
  );
  const euler = new THREE.Euler().setFromRotationMatrix(rotMatrix);
  const theta = Math.abs(euler.x) > (3 * Math.PI) / 4 ? Math.PI - euler.y : euler.y;
  const rot = [
    [Math.cos(theta), 0, Math.sin(theta)],
    [0, 1, 0],
    [-Math.sin(theta), 0, Math.cos(theta)],
  ];

  const trans = subtract(qCentroid, multiply(rot, pCentroid)) as number[];

  const transform: Matrix4 = {
    m00: rot[0][0],
    m01: rot[0][1],
    m02: rot[0][2],
    m03: trans[0],
    m10: rot[1][0],
    m11: rot[1][1],
    m12: rot[1][2],
    m13: trans[1],
    m20: rot[2][0],
    m21: rot[2][1],
    m22: rot[2][2],
    m23: trans[2],
    m30: 0,
    m31: 0,
    m32: 0,
    m33: 1,
  };
  return transform;
}
