import { matrix, multiply, index } from "mathjs";

import { ConnectionUpdate, Status, MarkerData } from "./types";
import { identityMatrix } from "utils/MathUtils";

const getAnchorMatrix = (id: string) => {
  return identityMatrix;
};

export const getConnectionUpdateFromJSON = (connectionUpdate: string) => {
  try {
    const connectionUpdateJSON = JSON.parse(connectionUpdate);
    const userID = connectionUpdateJSON.user_id;
    const connectionID = connectionUpdateJSON.connection_id;
    const connectionType =
      connectionUpdateJSON.connection_type === "client_connected" ? Status.Active : Status.Inactive;
    return { userID: userID, connectionID: connectionID, connectionType: connectionType } as ConnectionUpdate;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMarkerDataFromJSON = (markerDataJSON: any) => {
  try {
    const obj = JSON.parse(markerDataJSON);
    const localToAnchor = matrix([
      [obj.localToAnchor.m00, obj.localToAnchor.m10, obj.localToAnchor.m20, obj.localToAnchor.m30],
      [obj.localToAnchor.m01, obj.localToAnchor.m11, obj.localToAnchor.m21, obj.localToAnchor.m31],
      [obj.localToAnchor.m02, obj.localToAnchor.m12, obj.localToAnchor.m22, obj.localToAnchor.m32],
      [obj.localToAnchor.m03, obj.localToAnchor.m13, obj.localToAnchor.m23, obj.localToAnchor.m33],
    ]);

    const globalMatrix = multiply(getAnchorMatrix(obj.relativeAnchorID), localToAnchor);
    return {
      id: obj.objectID,
      position: [
        globalMatrix.subset(index(3, 0)) as unknown as number,
        globalMatrix.subset(index(3, 1)) as unknown as number,
      ],
    } as MarkerData;
  } catch (error) {
    console.error(error);
    return null;
  }
};
