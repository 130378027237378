import { MoreHoriz, MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

export interface MenuOption {
  name: string;
  onClick: (e?: React.MouseEvent) => any;
  icon?: JSX.Element;
}

const ITEM_HEIGHT = 48;

const ContextButton = ({ items, vertical }: { items: MenuOption[]; vertical?: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="long-button"
        onClick={handleClick}
        sx={{ backgroundColor: anchorEl ? "rgba(212, 214, 217, 0.7)" : undefined, borderRadius: 0 }}
        size="large"
      >
        {vertical ? <MoreVert color="primary" /> : <MoreHoriz color="primary" />}
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        onBlur={handleClose}
        PaperProps={{
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            maxHeight: ITEM_HEIGHT * 4.5,
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
          },
        }}
        sx={{
          "&.MuiMenuItem-root": {
            fontWeight: 900,
          },
        }}
      >
        {Object.values(items).map((item) => (
          <MenuItem
            key={item.name}
            onClick={item.onClick}
            sx={{ display: "flex", alignItems: "center", py: 1, pl: 2.75, pr: 3.25, gap: 1, svg: { fontSize: "24px" } }}
          >
            {item.icon}
            <Typography variant="body1">{item.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ContextButton;
