export enum ConnectionState {
  CONNECTED,
  CONNECTING,
  RECONNECTING,
  DISCONNECTED,
}

export declare type ConnectionStateSubscription = (state: ConnectionState) => void;

export interface IConnectionStatusManager {
  getConnectionState(): ConnectionState;
  retryConnection(): Promise<void>;
  subscribeToConnectionState(callback: ConnectionStateSubscription): () => void;
}
