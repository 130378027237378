import { Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

function CustomCircularProgress({
  color,
  overflow,
  style,
}: {
  color?: string;
  overflow?: string;
  style?: React.CSSProperties;
}) {
  const theme = useTheme();
  return (
    <Box paddingLeft="48%" paddingTop="2%" overflow={overflow} style={style}>
      <CircularProgress
        color="primary"
        style={{
          color: color ? color : "",
          alignItems: "center",
          justifyContent: "center",
          zIndex: theme.zIndex.appBar + 1,
        }}
      />
    </Box>
  );
}
export default CustomCircularProgress;
