import { useMemo } from "react";

import useUsers from "./useUsers";
import { User } from "services/ContentServer/Identity";

const useUser = (id: string): { user: User | null; error: any } => {
  const { users, error } = useUsers();

  const user = useMemo(() => {
    if (users !== undefined && users !== null && Array.isArray(users) && users.length > 0) {
      return { user: users.find((u) => u.id === id) || null, error };
    }
    return { user: null, error };
  }, [users, error, id]);

  return user;
};

export default useUser;
