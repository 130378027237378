import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Supplier | undefined } = {
  id: "id",
  name: "name",
};

export class Supplier extends ISerialization {
  public id?: string | null;
  public name?: string | null;

  constructor(supplier?: Supplier) {
    super();
    if (supplier) {
      Object.assign(this, supplier);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Supplier>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Supplier>(this, serverToView);
    return request;
  }
}
