import { Icon, IconProps } from "@mui/material";
import React from "react";

import icon from "assets/icons/UserPlaceholder.svg";

export const UserPlaceholderIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <img src={icon} alt="User Placeholder Icon" />
    </Icon>
  );
};

export default UserPlaceholderIcon;
