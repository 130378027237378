import { Dialog, DialogTitle, Typography, DialogContent, Grid, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";

export default function UserAlreadyExistsDialog({ open }: { open: boolean }) {
  const theme = useTheme();
  const [secondsTillRedirect, setSecondsTillRedirect] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsTillRedirect((prev) => prev - 1);
    }, 1000);
    if (secondsTillRedirect === 0) {
      window.location.href = "/login";
    }
    return () => clearInterval(interval);
  }, [secondsTillRedirect]);
  return (
    <>
      <Dialog
        open={open}
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 0,
            boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px 24px 24px",
            gap: "24px",
          },
          width: "100%",
        }}
      >
        <DialogTitle style={{ width: "100%", padding: "24px 0px 0px 0px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <Typography
              variant="dalmatianBase"
              sx={{
                fontWeight: 800,
                fontSize: "16px",
                lineHeight: "19px",
                color: theme.palette.dalmatianDesign.dark,
              }}
            >
              {"User Already Exists"}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            padding: "0px",
          }}
        >
          <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Grid item xs={12} style={{ width: "100%", paddingBottom: "32px" }}>
              <Typography style={{ paddingBottom: "20px" }}>
                {
                  "The user you are trying to register already has an AptixAR account. Please use that account to sign-in."
                }
              </Typography>
              <Typography>
                {"You will be redirected to the login page in " +
                  secondsTillRedirect +
                  (secondsTillRedirect === 1 ? " second." : " seconds. ")}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={50} thickness={3} style={{ marginBottom: "5%" }} />
                <BaseButton
                  variant="outlined"
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >
                  Go to Login Page
                </BaseButton>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
