import { useCallback, useEffect, useState } from "react";

const useMedia = <T,>(queries: string[], values: T[], defaultValue: T) => {
  const mediaQueryLists = queries.map((query) => window.matchMedia(query));

  const getValue = useCallback(() => {
    const index = mediaQueryLists.findIndex((mql) => {
      if (mql) return mql.matches;
      return false;
    });
    return values?.[index] || defaultValue;
  }, [defaultValue, mediaQueryLists, values]);

  const [value, setValue] = useState<T>(getValue());

  useEffect(() => {
    const handler = () => setValue(getValue());
    mediaQueryLists.forEach((queryList) => {
      if (queryList) {
        queryList.addEventListener("change", handler);
      }
    });
    return () =>
      mediaQueryLists.forEach((queryList) => {
        if (queryList) {
          queryList.removeEventListener("change", handler);
        }
      });
  }, [mediaQueryLists, getValue]);

  return value;
};

export default useMedia;
