import { createApi } from "@reduxjs/toolkit/query/react";
import { unionBy } from "lodash";

import customFetchBase from "./customFetchBase";
import { ImageFile } from "components/Annotation/types";
import { IGoogleMapInfo } from "components/ARMap";
import { sortObjectsByString } from "utils/SortingUtils";

// move ScopeSelection from SearchBarWithSort due to some reference issue
export enum ScopeSelection {
  ENTIRE_ORGANIZATION = "all",
  THIS_FOLDER = "folder",
  THIS_FACILITY = "facility",
  ARCHIVE = "archive",
  FAVORITE = "favorite",
}

export enum FOLDERTYPE {
  SITE = "SITE",
  FACILITY = "FACILITY",
  ASSET = "ASSET",
  SNAPSHOT = "SNAPSHOT",
  NONE = "NONE",
}

export enum STATE {
  NORMAL = "NORMAL",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  ALL = "ALL",
}

export enum SORTBY {
  NAME = "name",
  ACTIVITY = "last_modified_at",
  CREATED = "created_at",
}

export enum SORTORDER {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export enum LOCATION_TYPE {
  REGION = "region",
  COUNTRY = "country",
  STATE = "state/province",
  CITY = "city",
  SITE = "site",
  BUILDING = "building",
  AREA = "area",
}

export interface IAssetMetadata {
  assetInfo: string;
  lastCreated: string;
}

export interface IFolderMetadata {
  company: string;
  contact: string;
  address: string;
  region: string;
  country: string;
  zipPostalCode: string;
  locationType: string;
}

export interface IFacilityMetadata {
  mapFile: string;
  mapScale: string;
  mapOpacity: string;
}

export interface IFolder {
  id: string;
  name: string;
  description?: string;
  parentId: string;
  folderPath: string;
  idPath: string;
  folderType: FOLDERTYPE;
  locationType?: LOCATION_TYPE;
  state: STATE;
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  teams: string[];
  addTeam?: string[];
  removeTeam?: string[];
  metadata?: IFolderMetadata | IFacilityMetadata | IAssetMetadata;
  thumbnail: string;
  thumbnailFile?: ImageFile;
  locationData?: IGoogleMapInfo;
}

const deserializeMetaData = (type: FOLDERTYPE, obj: any): IFolderMetadata | IFacilityMetadata | IAssetMetadata => {
  switch (type) {
    case FOLDERTYPE.SITE:
      return {
        company: obj.company,
        contact: obj.contact,
        address: obj.address,
        region: obj.region,
        country: obj.country,
        locationType: obj["location_type"] === "floor" ? LOCATION_TYPE.AREA : obj["location_type"],
        zipPostalCode: obj["zip_postal_code"],
      };
    case FOLDERTYPE.FACILITY:
      return {
        mapFile: obj["map_file"],
        mapScale: obj["map_scale"],
        mapOpacity: obj["map_opacity"],
      };
    case FOLDERTYPE.ASSET:
      return {
        assetInfo: obj["asset_info"],
        lastCreated: obj["last_created"],
      };
    default:
      return {
        company: obj.company,
        contact: obj.contact,
        address: obj.address,
        region: obj.region,
        country: obj.country,
        locationType: obj["location_type"] === "floor" ? LOCATION_TYPE.AREA : obj["location_type"],
        zipPostalCode: obj["zip_postal_code"],
      };
  }
};

const folderTypeMap = (type: string): FOLDERTYPE => {
  if (type === "FACILITY") return FOLDERTYPE.FACILITY;
  else if (type === "ASSET") return FOLDERTYPE.ASSET;
  else return FOLDERTYPE.SITE;
};

const deserializeFolder = (obj: any): IFolder => {
  const type = folderTypeMap(obj["folder_type"]);

  const objMap: IFolder = {
    id: obj.id,
    name: obj.name,
    description: obj.description,
    parentId: obj["parent_id"],
    folderPath: obj["folder_path"],
    idPath: obj["id_path"],
    state: obj["state"] as STATE,
    folderType: type,
    createdBy: obj["created_by"],
    createdAt: obj["created_at"],
    lastModifiedBy: obj["last_modified_by"],
    lastModifiedAt: obj["last_modified_at"],
    metadata: deserializeMetaData(type, obj.metadata),
    teams: obj["team"],
    thumbnail: obj["thumbnail"],
    locationData: obj["location_data"],
  };

  return objMap;
};

const serializeFolder = (folder: IFolder) => {
  const formData = new FormData();

  formData.append("id", folder.id);
  if (folder.name) formData.append("name", folder.name);
  if (folder.description !== undefined) formData.append("description", folder.description);
  if (folder.parentId !== undefined) formData.append("parent_id", folder.parentId ? folder.parentId : "");
  if (folder.addTeam) {
    folder.addTeam.forEach((item) => {
      formData.append("add_team", item);
    });
  }
  if (folder.removeTeam) {
    folder.removeTeam.forEach((item) => {
      formData.append("remove_team", item);
    });
  }
  if (folder.thumbnailFile) {
    formData.append("thumbnail", folder.thumbnailFile);
  }
  if (folder.locationData) {
    formData.append("location_data", JSON.stringify(folder.locationData));
  }

  return formData;
};

export type FolderParam = {
  paginate?: boolean;
  page?: number;
  pageSize?: number;
  state?: STATE;
  name?: string;
  path?: string;
  parentId?: string | null;
  sortBy?: SORTBY;
  sortOrder?: SORTORDER;
  type?: FOLDERTYPE;
  from?: "asset" | "archive";
};

const prevParam: FolderParam = {
  paginate: true,
  page: 1,
  pageSize: 50,
  sortBy: SORTBY.ACTIVITY,
  sortOrder: SORTORDER.ASCENDING,
  name: "",
  from: "asset",
};

const buildParamString = (param: FolderParam): string => {
  const obj: FolderParam = { ...prevParam, ...param };
  const arrTmp = [];

  for (const [key, value] of Object.entries(obj)) {
    switch (key) {
      case "paginate":
      case "page":
      case "state":
        if (value !== STATE.ALL) {
          arrTmp.push(`${key}=${value}`);
        } else if (value === STATE.ALL) {
          arrTmp.push(`${key}=` + STATE.NORMAL);
        }
        break;
      case "path":
        if (value !== "") arrTmp.push(`path=${value}`);
        break;
      case "type":
        if (value !== FOLDERTYPE.NONE) arrTmp.push(`${key}=${value}`);
        break;
      case "name":
        if (value && value.toString().length > 0) arrTmp.push(`${key}=${encodeURIComponent(value)}`);
        break;
      case "pageSize":
        arrTmp.push(`page_size=${value}`);
        break;
      case "parentId":
        if (value !== "") arrTmp.push(`parent_id=${value}`);
        break;
      case "sortBy":
        arrTmp.push(
          `order_by=${(obj.sortOrder === SORTORDER.ASCENDING ? "" : "-") + obj.sortBy?.toString().toLocaleLowerCase()}`
        );
        break;
      case "sortOrder":
        break;
      default:
        arrTmp.push(`${key}=${value?.toString()}`);
    }
  }

  return arrTmp.sort((a, b) => sortObjectsByString(a, b)).join("&");
};

export const convertURLtoFolderParam = (url: string): FolderParam => {
  if (url === null) {
    return {} as FolderParam;
  }

  const objUrl = new URL(url);
  const params = new URLSearchParams(objUrl.search);
  const newParam: FolderParam = {};

  for (const [key, value] of params.entries()) {
    switch (key) {
      case "page":
        newParam.page = +value;
        break;
      case "page_size":
        newParam.pageSize = +value;
        break;
      case "state":
        if (!newParam.state) {
          newParam.state = value as STATE;
        } else {
          newParam.state = STATE.ALL;
        }
        break;
      case "name":
        newParam.name = value;
        break;
      case "path":
        newParam.path = value;
        break;
      case "type":
        newParam.type = value as FOLDERTYPE;
        break;
      case "parent_id":
        newParam.parentId = value;
        break;
      case "order_by":
        if (value.charAt(0) == "+") {
          newParam.sortOrder = SORTORDER.ASCENDING;
          newParam.sortBy = value.substring(1) as SORTBY;
        } else if (value.charAt(0) == "-") {
          newParam.sortOrder = SORTORDER.DESCENDING;
          newParam.sortBy = value.substring(1) as SORTBY;
        } else {
          newParam.sortOrder = SORTORDER.ASCENDING;
          newParam.sortBy = value as SORTBY;
        }
        break;
    }
  }

  return newParam;
};

// new Param is diff than the current Param, reset the page to 1
// const checkParam = (param: FolderParam) => {};
const getArchiveType = (type: FOLDERTYPE) => {
  switch (type) {
    case FOLDERTYPE.SITE:
      return "sites";
    case FOLDERTYPE.FACILITY:
      return "facilities";
    case FOLDERTYPE.ASSET:
      return "assets";
    case FOLDERTYPE.SNAPSHOT:
      return "snapshots";
  }
};

interface IFolderResponse {
  count: number;
  pages: number;
  nextpage: number;
  folders: IFolder[];
  param: FolderParam;
}

type FolderByIdParam = {
  id: string;
  state: STATE;
};
export const folderApi = createApi({
  reducerPath: "folderApi",
  baseQuery: customFetchBase,
  tagTypes: ["Sites", "Folders", "UserProfiles", "Teams", "Facilities", "Assets"],
  endpoints: (builder) => ({
    getFolders: builder.query<IFolderResponse, FolderParam>({
      query(param: FolderParam) {
        return {
          url: `folders/?${buildParamString(param)}`,
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        if (queryArgs === undefined) return {};

        const { name, parentId, sortBy, sortOrder, state, type, from } = queryArgs;
        return { name, parentId, sortBy, sortOrder, state, type, from };
      },
      merge: (currentCacheData, responseData) => {
        currentCacheData.count = responseData.count;
        currentCacheData.nextpage = responseData.nextpage;
        currentCacheData.pages = responseData.pages;
        // fix duplicate record issue
        if (responseData.param.page === 1) {
          currentCacheData.folders = [...responseData.folders];
        } else {
          currentCacheData.folders = unionBy(currentCacheData.folders, responseData.folders, "id");
          // currentCacheData.folders.push(...responseData.folders);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (response: any, meta, arg) => {
        return {
          count: response.count,
          nextpage: response.next ? (convertURLtoFolderParam(response.next).page as number) : 0,
          pages: response["num_pages"],
          folders: response.results.map((item: any) => deserializeFolder(item)),
          param: arg,
        };
      },
      providesTags: (result) =>
        // is result available?
        result?.folders
          ? // successful query
            [...result.folders.map(({ id }) => ({ type: "Folders", id } as const)), { type: "Folders", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Folders", id: "LIST" }],
    }),
    getFolderById: builder.query<IFolder, FolderByIdParam>({
      query(param) {
        return {
          url: `folders/${param.id}/?state=${param.state}`,
        };
      },
      transformResponse: (response: any) => {
        return deserializeFolder(response);
      },
      providesTags: (result, error, param) => [{ type: "Folders", id: param.id }],
    }),
    getDirectArchive: builder.query<IFolder[], string>({
      query(rootId) {
        return {
          url: `folders/?state=archived&directly_archived=true&path=${rootId}`,
        };
      },
      transformResponse: (response: any) => {
        return response.map((item: any) => deserializeFolder(item));
      },
    }),
    addFolder: builder.mutation<any, IFolder>({
      query(body: IFolder) {
        return {
          url: "folders/",
          method: "POST",
          body: serializeFolder(body),
        };
      },
      invalidatesTags: [{ type: "Folders", id: "LIST" }],
    }),
    updateFolder: builder.mutation<any, Partial<IFolder>>({
      query(body: IFolder) {
        return {
          url: `folders/${body.id}/`,
          method: "PATCH",
          body: serializeFolder(body),
        };
      },
      invalidatesTags: (result, error, param) => [{ type: "Assets", id: "LIST" }],
    }),
    deleteFolder: builder.mutation<{ success: boolean; id: string }, string>({
      query(id) {
        return {
          url: `folders/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, id) => [{ type: "Folders", id }],
    }),
    archiveItem: builder.mutation<any, { type: FOLDERTYPE; id: string }>({
      query({ type, id }) {
        return {
          url: `${getArchiveType(type)}/${id}/archive/`,
          method: "GET",
          responseHandler: (response) => response.text(),
        };
      },
      // invalidatesTags: (result, error, id) => [{ type: "Folders", id: "LIST" }],
    }),
    unarchiveItem: builder.mutation<any, { type: FOLDERTYPE; id: string }>({
      query({ type, id }) {
        return {
          url: `${getArchiveType(type)}/${id}/unarchive/`,
          method: "GET",
          responseHandler: (response) => response.text(),
        };
      },
      // invalidatesTags: (result, error, id) => [{ type: "Folders", id: "LIST" }],
    }),
  }),
});

export const {
  useGetFoldersQuery,
  useLazyGetFoldersQuery,
  useGetFolderByIdQuery,
  useLazyGetFolderByIdQuery,
  useLazyGetDirectArchiveQuery,
  useAddFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation,
  useArchiveItemMutation,
  useUnarchiveItemMutation,
} = folderApi;
