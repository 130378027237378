import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { CaptureType, CaptureTypeServerToView } from "services/ContentServer/Audit/types";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof MediaHistory | undefined } = {
  history_id: "id",
  last_modified_by: "lastModifiedBy",
  last_modified_at: "lastModifiedAt",
  device: "device",
  timestamp: "timestamp",
  depth_map: "depthMap",
  rotate: "rotate",
};

export class MediaHistory extends ISerialization {
  public id?: string | null;
  public img?: any;
  public lowResImg?: any | null;
  public depthMap?: any;
  public video?: any;
  public timestamp?: any;
  public lastModifiedBy?: string | null;
  public lastModifiedAt?: string | null;
  public device?: string | null;
  public captureType?: CaptureType | null;
  public rotate?: number | null;
  public requestedImg?: any;

  constructor(mediaHistory?: MediaHistory) {
    super();
    if (mediaHistory) {
      Object.assign(this, mediaHistory);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<MediaHistory>(this, serverToView, json);
    this.img = json.media_history ? (json.media_history.image ? json.media_history.image : undefined) : undefined;
    this.lowResImg = json.media_history
      ? json.media_history.low_res_image
        ? json.media_history.low_res_image
        : undefined
      : undefined;
    this.video = json.media_history ? (json.media_history.video ? json.media_history.video : undefined) : undefined;
    this.captureType = json.capture_type ? CaptureTypeServerToView.get(json.capture_type) : CaptureType.None;
  }

  serialize(): any {
    const request = serializerHelper<MediaHistory>(this, serverToView);
    return request;
  }
}
