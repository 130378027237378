import { ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const ListButton = styled(ToggleButton)({
  textTransform: "none",
  padding: "0px",
  border: "0px",
  backgroundColor: "transparent",
  justifyContent: "flex-start",
  borderRadius: "0px",
  "&:hover": {
    backgroundColor: "#D4D6D9",
  },
  ".Mui-selected": {
    backgroundColor: "#D4D6D9",
  },
});

export default ListButton;
