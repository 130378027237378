import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";

import { DrawingPlane } from "utils/Drawing";

export declare type Point2D = number[];
export declare type Point3D = number[];

export enum PoseType {
  Request = "Requested Capture",
  Template = "Templated Capture",
  Manual = "Manual Capture",
  Automated = "Automated Capture",
  Deleted = "Deleted Capture",
}

export enum PoseTypeServer {
  Request = "REQUEST",
  Template = "TEMPLATE",
  Manual = "MANUAL",
  Automated = "AUTOMATED",
}

export enum ProgressType {
  PreStart = "PreStart",
  Processing = "Processing",
  Error = "Error",
  Complete = "Complete",
}

export enum ProgressTypeServer {
  PreStart = "PRE_START",
  Processing = "PROCESSING",
  Error = "ERROR",
  Complete = "COMPLETE",
}

export const poselessKey = "Poseless";

export enum CaptureType {
  None = "None",
  Image = "Image",
  Video = "Video",
}

export enum CaptureTypeServer {
  None = "NONE",
  Image = "IMAGE",
  Video = "VIDEO",
}

export const CaptureTypeServerToView = new Map<CaptureTypeServer, CaptureType>([
  [CaptureTypeServer.None, CaptureType.None],
  [CaptureTypeServer.Image, CaptureType.Image],
  [CaptureTypeServer.Video, CaptureType.Video],
]);

export const CaptureTypeViewToServer = new Map(
  [...CaptureTypeServerToView.entries()].map(([key, value]) => [value, key])
);

export interface Matrix4 {
  m00: number;
  m01: number;
  m02: number;
  m03: number;
  m10: number;
  m11: number;
  m12: number;
  m13: number;
  m20: number;
  m21: number;
  m22: number;
  m23: number;
  m30: number;
  m31: number;
  m32: number;
  m33: number;
}

export const identityMatrix: Matrix4 = {
  m00: 1,
  m01: 0,
  m02: 0,
  m03: 0,
  m10: 0,
  m11: 1,
  m12: 0,
  m13: 0,
  m20: 0,
  m21: 0,
  m22: 1,
  m23: 0,
  m30: 0,
  m31: 0,
  m32: 0,
  m33: 1,
};

export enum AnnotationAssociationType {
  Image,
  SnapshotTemplate,
  Snapshot,
}

export enum AnnotationActionType {
  CREATE,
  EDIT,
  DELETE,
}

export interface AnnotationBase {
  annotationGroupId?: number | null;
  id?: string | null;
  isActive?: boolean | null;
  type?: AnnotationType | null;
  plane?: DrawingPlane | null;
  pose?: Matrix4 | null;
  color?: string | null;
  staticFile?: boolean | null;
  object?: THREE.Object3D;
}

export interface FileWithPath extends File {
  readonly path: string;
}

export interface TemplatePoseObject {
  object: THREE.Mesh[];
  id: number;
}

export interface Offset {
  position: THREE.Vector3;
  rotation: THREE.Vector3;
}

export class DefaultOffset implements Offset {
  position: THREE.Vector3;
  rotation: THREE.Vector3;

  constructor(position = new THREE.Vector3(0, 0, 0), rotation = new THREE.Vector3(0, 0, 0)) {
    this.position = position;
    this.rotation = rotation;
  }
}

export enum AnnotationType {
  DRAWING = "DRAWING",
  IMAGE = "IMAGE",
  MODEL = "MODEL",
  VIDEO = "VIDEO",
  PDF = "PDF",
}

export interface OrderedSnapshot {
  id: string;
  step: number;
}

export enum ModelState {
  Normal = "NORMAL",
  Archived = "ARCHIVED",
  Deleted = "DELETED",
}

export enum ModelStateSearch {
  All = "ALL",
  Normal = "NORMAL",
  Archived = "ARCHIVED",
}

export type AnnotationObjectType =
  | THREE.Mesh<THREE.PlaneGeometry, THREE.Material | THREE.Material[]>
  | THREE.Scene
  | THREE.Group
  | THREE.Sprite
  | THREE.Points<THREE.BufferGeometry, THREE.Material | THREE.Material[]>
  | Line2
  | undefined;
