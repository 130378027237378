import { AxiosRequestConfig } from "axios";

import { PermissionDict, RoleDict } from ".";

import { ENDPOINTS } from "./constants";
import {
  deserializeProfileData,
  getPermissionDictFromPermissionList,
  getRoleDictFromRolesList,
  getUserDictFromJson,
  getUserFromJson,
  serializeProfileData,
  serializeRoleData,
  serializeUserData,
} from "./serializers";
import { IdentityInterface, User, Profile, Role, PaginatedUserResp, UserInviteRequest, UserPending } from "./types";
import { ImageFile } from "components/Annotation/types";
import { APIInstance } from "services/ContentServer/APIInstance";
import serialize from "utils/ObjectToFormData";

export const IdentitySWRKeys = {
  PROFILE_PICTURE: "profilePicture",
  ROLES: "roles",
};

interface PaginatedRequestParams {
  endpoint: string;
  nextPage?: string;
}

export class IdentityService implements IdentityInterface {
  private apiInstance: APIInstance;

  constructor({ apiInstance }: { apiInstance: APIInstance }) {
    this.apiInstance = apiInstance;
  }

  async updateUser(userId: string, user: User, updatedRoles = false) {
    const endpoint = `${ENDPOINTS.USERS}${userId}/`;
    const resp = await this.apiInstance.patch(endpoint, serializeUserData(userId, user, updatedRoles));
    return resp.data;
  }
  async updateRole(role: Role) {
    const endpoint = `${ENDPOINTS.USERROLES}${role.id}/`;
    const resp = await this.apiInstance.put(endpoint, serializeRoleData(role));
    return resp.data;
  }

  async createRole(role: Role) {
    const endpoint = `${ENDPOINTS.USERROLES}`;
    const resp = await this.apiInstance.post(endpoint, serializeRoleData(role));
    return resp.data;
  }

  async deleteRole(roleId: string) {
    const endpoint = `${ENDPOINTS.USERROLES}${roleId}/`;
    const resp = await this.apiInstance.delete(endpoint);
    return resp.data;
  }

  async createInvite(email: string, username: string, isAdmin: boolean, siteId: string, userInviteId?: string) {
    const replyUrl = `${window.location.origin}/login`;
    const role = isAdmin ? "admin" : "member";
    const resp = await this.apiInstance.post(`${ENDPOINTS.CREATEINVITE}`, {
      email: email,
      username: username,
      userInviteId: userInviteId,
      site: siteId,
      postRedeemUrl: replyUrl,
      additionalClaims: { role: role },
    } as AxiosRequestConfig);
    return resp.data;
  }

  async updateInvite(invId: string, inv: UserPending) {
    const endpoint = `${ENDPOINTS.USERINVITE}${invId}/`;
    const resp = await this.apiInstance.patch(endpoint, {
      id: invId,
      username: inv.name,
      createdAt: inv.createdAt,
      sites: inv.sites,
      roles: inv.roles ? Object.values(inv.roles).map((role) => role) : [],
      teams: inv.teams,
    });
    return resp.data;
  }

  async createBatchInvite(data: UserInviteRequest[]) {
    const resp = await this.apiInstance.post(`${ENDPOINTS.CREATEINVITE}`, data);
    return resp.data;
  }

  async createBulkInvite(file: any) {
    const data = { file: file };
    const dataStream = serialize(data);

    const resp = await this.apiInstance.post(`${ENDPOINTS.BULKINVITE}`, dataStream);
    return resp.data;
  }

  async updateUserProfile(userId: string, data: Profile) {
    const resp = await this.apiInstance.patch(
      `${ENDPOINTS.USERPROFILE}${data.id}/`,
      serializeProfileData(userId, data)
    );
    return resp.data;
  }

  async updateProfilePhoto(user: User, img: ImageFile) {
    const resp = await this.apiInstance.patch(
      `${ENDPOINTS.USERPROFILE}${user.userProfile.id}/`,
      serialize({
        id: user.userProfile.id,
        userId: user.id,
        site: user.userProfile.defaultSite.site,
        display_name: user.userProfile.displayName,
        active_until: user.userProfile.activeUntil,
        profile_picture: img,
        state: user.userProfile.state,
        read_terms: user.userProfile.readTerms,
      })
    );

    return deserializeProfileData(resp.data, user.name);
  }

  async setUserTermsRead(profileId: string, user: User) {
    const resp = await this.apiInstance.patch(
      `${ENDPOINTS.USERPROFILE}${profileId}/`,
      serialize({
        userId: user.id,
        read_terms: true,
      })
    );

    return deserializeProfileData(resp.data, user.name);
  }

  async getUser(id: string): Promise<User> {
    if (!id) {
      Promise.reject("No user ID provided.");
    }
    const endpoint = `${ENDPOINTS.USERSDISPLAY}${id}/`;
    const resp = await this.apiInstance.get(endpoint);
    return getUserFromJson(resp.data);
  }

  async getUsers(): Promise<User[]> {
    const endpoint = ENDPOINTS.USERSDISPLAY;
    const resp = await this.apiInstance.get(endpoint);
    return getUserDictFromJson(resp.data);
  }

  async getFullUser(id: string): Promise<User> {
    if (!id) {
      Promise.reject("No user ID provided.");
    }
    const endpoint = `${ENDPOINTS.USERS}${id}/`;
    const resp = await this.apiInstance.get(endpoint);
    return getUserFromJson(resp.data);
  }

  async exportUsersCsv(email: string) {
    const data = { email: email };
    const endpoint = ENDPOINTS.EXPORTCSV;
    const resp = await this.apiInstance.post(endpoint, data);
    return resp.data;
  }

  async deleteUser(id: string) {
    const endpoint = `${ENDPOINTS.USERS}${id}/`;
    const resp = await this.apiInstance.delete(endpoint);
    return resp.data;
  }

  async getFullUsers(): Promise<User[]> {
    const endpoint = ENDPOINTS.USERS;
    const resp = await this.apiInstance.get(endpoint);
    return getUserDictFromJson(resp.data);
  }

  async getPaginatedUsers({ endpoint, nextPage = "" }: PaginatedRequestParams): Promise<PaginatedUserResp> {
    const url = endpoint + "?" + nextPage + "paginate=true";
    const resp = await this.apiInstance.get(url);
    resp.data.results = getUserDictFromJson(resp.data.results);
    return resp.data;
  }

  async getRoles(): Promise<RoleDict> {
    const endpoint = ENDPOINTS.USERROLES;
    const resp = await this.apiInstance.get(endpoint);
    return getRoleDictFromRolesList(resp.data);
  }

  async getPermissions(): Promise<PermissionDict> {
    const endpoint = `${ENDPOINTS.PERMISSIONS}`;
    const resp = await this.apiInstance.get(endpoint);
    return getPermissionDictFromPermissionList(resp.data);
  }

  async getUserPermissions(userId: string): Promise<PermissionDict> {
    const endpoint = `${ENDPOINTS.PERMISSIONS}?username=${userId}`;
    const resp = await this.apiInstance.get(endpoint);
    return getPermissionDictFromPermissionList(resp.data);
  }

  async mailUserLogs(startDate: string, endDate: string, email: string) {
    const data = { startDate: startDate, endDate: endDate, email: email };
    const endpoint = ENDPOINTS.USERLOGS;
    const resp = await this.apiInstance.post(endpoint, data);
    return resp.data;
  }

  async initialUserSetup() {
    const data = {};
    const endpoint = ENDPOINTS.USERSETUP;
    const resp = await this.apiInstance.post(endpoint, data);
    return resp.data;
  }
}
