import PermIdentityOutlined from "@mui/icons-material/PermIdentityOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import React from "react";

import { PortalRoute } from "components/Routes";
import { PERMISSION_NAME } from "hooks/usePermission";
import { SettingRoutesDict, SettingTab } from "views/SettingsPage/SettingsBasePage";

export interface UserProfileRoute extends Omit<PortalRoute, "component" | "highLevel" | "subroutes"> {
  path: string;
  id: string;
  name: string;
  icon: JSX.Element;
  requiredPermissions: PERMISSION_NAME[];
}
const userProfileRoutes: UserProfileRoute[] = [
  {
    id: "profile-page",
    path: SettingRoutesDict.get(SettingTab.PROFILE)?.path || "",
    name: "Profile",
    icon: <PermIdentityOutlined />,
    requiredPermissions: [],
  },
  {
    path: SettingRoutesDict.get(SettingTab.PREFERENCES)?.path || "",
    name: "Settings",
    id: "profile-settings-page",
    icon: <SettingsOutlinedIcon fontSize="small" />,
    requiredPermissions: [
      PERMISSION_NAME.ADMIN,
      PERMISSION_NAME.USER,
      PERMISSION_NAME.ROLES,
      PERMISSION_NAME.SITE,
      PERMISSION_NAME.TEAM,
    ],
  },
];

export default userProfileRoutes;
