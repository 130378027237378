import { IInspectionUpdateHub, UpdateCallback } from "../types";
import { ICommServer } from "services/CommServer";

export class CommServerInspectionUpdateHub implements IInspectionUpdateHub {
  private commServer?: ICommServer;

  constructor({ commServer }: { commServer?: ICommServer }) {
    this.commServer = commServer;
  }

  subscribeToUpdates(callback: UpdateCallback, messageType: string): () => void {
    if (!this.commServer) {
      return () => {};
    }

    const wrapper = (jsonData: string) => {
      if (jsonData) {
        const updateJSON = JSON.parse(jsonData);
        callback(updateJSON);
      }
    };
    return this.commServer.subscribe(messageType, wrapper);
  }
}
