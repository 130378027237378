import { ILODFile, LODFile } from "./LODFile";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";
import { getExtension } from "utils/FileUtils";

export const serverToView: { [key: string]: keyof FileSegment | undefined } = {
  id: "id",
  file_path: "path",
  file_segment: "file",
  mesh_segment: "meshFile",
  segment_number: "segmentNumber",
  is_texturing: "isTexturing",
  is_touching_up: "isTouchingUp",
};

export interface IFileSegment {
  id?: string | null;
  path?: string | null;
  file?: string | Blob | File | null;
  meshFile?: string | Blob | File | null;
  segmentNumber?: number | null;
  isTexturing?: boolean | null;
  isTouchingUp?: boolean | null;
  hasTextured?: boolean | null;
  lodFiles?: ILODFile[] | null;
  externalFile?: boolean | null;
}

export class FileSegment extends ISerialization {
  public id?: string | null;
  public path?: string | null;
  public file?: string | Blob | File | null;
  public meshFile?: string | Blob | File | null;
  public segmentNumber?: number | null;
  public isTexturing?: boolean | null;
  public isTouchingUp?: boolean | null;
  public hasTextured?: boolean | null;
  public lodFiles?: LODFile[] | null;
  public externalFile?: boolean | null;

  constructor(segment?: FileSegment) {
    super();
    if (segment) {
      Object.assign(this, segment);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<FileSegment>(this, serverToView, json);
    // Preserved for backwards compatibility, remove when migrations applied to old data
    this.hasTextured = json.textured_file_segment ? true : false;
    this.file = json.textured_file_segment || json.file_segment || undefined;
    this.meshFile = json.file_segment || undefined;
    if (json.lod_files) {
      this.lodFiles = json.lod_files.map((lod: any) => {
        const lodFile = new LODFile();
        lodFile.deserialize(lod);
        if (lodFile.file && typeof lodFile.file === "string") {
          // Bad, bad, very bad. We have ductaped this twice now: https://github.com/Interaptix/aptixar-webportal/issues/2844 and, https://github.com/Interaptix/aptixar-webportal/issues/3172
          // We should not be tying the "external file" state to the file extension, if this feature is actually still useful lets add dedicated state for it so that we don't ever have this mixup happening again.
          const DUCTAPE_ON_DUCTAPE_ON_DUCTAPE =
            getExtension(lodFile.file) !== "drc" &&
            getExtension(lodFile.file) !== "obj" &&
            getExtension(lodFile.file) !== "fbx" &&
            getExtension(lodFile.file) !== "splat";

          this.externalFile = DUCTAPE_ON_DUCTAPE_ON_DUCTAPE;
        }
        if (lodFile.hasTextured) {
          this.hasTextured = true;
        }
        if (lodFile.meshFile) {
          this.meshFile = lodFile.meshFile;
        }
        return lodFile;
      });
    }
  }

  convertToJSON(): IFileSegment {
    return {
      id: this.id,
      path: this.path,
      file: this.file,
      meshFile: this.meshFile,
      segmentNumber: this.segmentNumber,
      isTexturing: this.isTexturing,
      isTouchingUp: this.isTouchingUp,
      hasTextured: this.hasTextured,
      lodFiles: this.lodFiles?.map((file) => file.convertToJSON()),
      externalFile: this.externalFile,
    };
  }

  serialize(): any {
    const request = serializerHelper<FileSegment>(this, serverToView);
    request.lod_files = this.lodFiles?.map((lodFile) => lodFile.serialize());
    return request;
  }
}
