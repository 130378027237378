import React, { useContext, useEffect, useMemo, useState } from "react";

import { SnapshotContext } from "./SnapshotContext";
import { Asset } from "services/ContentServer/Audit/serviceTypes/Asset";
import { SortSelection } from "views/Audit/SnapshotPicker";

export enum SelectionView {
  NotSelecting,
  Model,
  Grid,
  List,
  EditFacility,
  Archive,
  AlignSnapshot,
}

export interface SnapshotSelectionContextType {
  selectionView: SelectionView;
  filterId: string;
  sortBy: SortSelection;
  title: string;
  description: string;
  selectedAsset?: Asset;
  expandedDescription: boolean;
  createSnapshotDialogOpen: boolean;
  itemId: string;
  setItemId: (id: string) => void;
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setSelectedAsset: (asset: Asset | undefined) => void;
  setExpandedDescription: (bool: boolean | ((bool: boolean) => boolean)) => void;
  setSortBy: (sort: SortSelection) => void;
  setFilterId: (id: string) => void;
  setSelectionView: (view: SelectionView) => void;
  setCreateSnapshotDialogOpen: (open: boolean) => void;
}

const defaultContext: SnapshotSelectionContextType = {
  selectionView: SelectionView.List,
  filterId: "",
  sortBy: SortSelection.CREATED,
  title: "",
  description: "",
  selectedAsset: undefined,
  expandedDescription: false,
  createSnapshotDialogOpen: false,
  itemId: "",
  setItemId: (id: string) => {},
  setTitle: (title: string) => {},
  setDescription: (description: string) => {},
  setSelectedAsset: (asset: Asset | undefined) => {},
  setExpandedDescription: (bool: boolean | ((bool: boolean) => boolean)) => {},
  setSortBy: (sort: SortSelection) => {},
  setFilterId: (id: string) => {},
  setSelectionView: (view: SelectionView) => {},
  setCreateSnapshotDialogOpen: (open: boolean) => {},
};

export const ProvideSnapshotSelectionContext = (props: any) => {
  const [view, setView] = useState<SelectionView>(SelectionView.List);
  const [filterId, setFilterId] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortSelection>(SortSelection.CREATED);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedAsset, setSelectedAsset] = useState<Asset>();
  const [itemId, setItemId] = useState<string>("");
  const [expandedDescription, setExpandedDescription] = useState<boolean>(false);
  const [createSnapshotDialogOpen, setCreateSnapshotDialogOpen] = useState<boolean>(false);

  const { selectedSnapshot } = useContext(SnapshotContext);

  useEffect(() => {
    if (selectedSnapshot && view != SelectionView.AlignSnapshot && view != SelectionView.NotSelecting) {
      setView(SelectionView.NotSelecting);
    }
  }, [selectedSnapshot, view]);

  const contextValues: SnapshotSelectionContextType = useMemo(
    () => ({
      selectionView: view,
      filterId: filterId,
      sortBy: sortBy,
      title: title,
      description: description,
      selectedAsset: selectedAsset,
      expandedDescription: expandedDescription,
      createSnapshotDialogOpen: createSnapshotDialogOpen,
      itemId: itemId,
      setItemId: setItemId,
      setTitle: setTitle,
      setDescription: setDescription,
      setSelectedAsset: setSelectedAsset,
      setExpandedDescription: setExpandedDescription,
      setSortBy: setSortBy,
      setFilterId: setFilterId,
      setSelectionView: setView,
      setCreateSnapshotDialogOpen: setCreateSnapshotDialogOpen,
    }),
    [view, filterId, sortBy, title, description, selectedAsset, expandedDescription, createSnapshotDialogOpen, itemId]
  );
  return <SnapshotSelectionContext.Provider value={contextValues}>{props.children}</SnapshotSelectionContext.Provider>;
};

export const SnapshotSelectionContext = React.createContext(defaultContext);
