import { folderApi } from "./folderApi";
import { Matrix4 } from "services/ContentServer/Audit";

enum STATE {
  NORMAL = "NORMAL",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
  ALL = "ALL",
}

export interface AssetTransform {
  assetId: string;
  transform: Matrix4;
}

export interface IFacility {
  id: string;
  name: string;
  description?: string;
  parentId: string;
  folderPath: string;
  idPath: string;
  state: STATE;
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  assets: string[];
  addAsset?: AssetTransform[];
  removeAsset?: string[];
  assetTransforms?: AssetTransform[];
  mapFile?: string | Blob | File | null;
  mapScale?: number | null;
  mapOpacity?: number | null;
  mapTransform?: Matrix4 | null;
}

const deserializeAssetTransforms = (obj: any): AssetTransform => {
  return {
    assetId: obj["asset_id"],
    transform: obj["transformation"],
  };
};

const deserializeFacility = (obj: any): IFacility => {
  const objMap: IFacility = {
    id: obj.id,
    name: obj.name,
    description: obj.description,
    parentId: obj["parent_id"],
    folderPath: obj["folder_path"],
    idPath: obj["id_path"],
    state: obj["state"] as STATE,
    createdBy: obj["created_by"],
    createdAt: obj["created_at"],
    lastModifiedBy: obj["last_modified_by"],
    lastModifiedAt: obj["last_modified_at"],
    assets: obj["assets"],
    mapFile: obj["map_file"],
    mapScale: obj["map_scale"],
    mapOpacity: obj["map_opacity"],
    mapTransform: obj["map_transform"],
  };

  const tmp: AssetTransform[] = [];
  for (let i = 0; i < obj["asset_transformations"].length; i++) {
    tmp.push(deserializeAssetTransforms(obj["asset_transformations"][i]));
  }
  objMap.assetTransforms = tmp;

  return objMap;
};

const serializeFacility = (facility: IFacility) => {
  const obj: any = {};
  if (facility.name) obj.name = facility.name;
  if (facility.description !== undefined) obj.description = facility.description;
  if (facility.addAsset) {
    const tmp: any = [];
    facility.addAsset.forEach((item) => {
      tmp.push({
        asset_id: item.assetId,
        transformation: JSON.stringify(item.transform),
      });
    });

    obj.add_assets = tmp;
  }
  if (facility.removeAsset) {
    const removeIds: string[] = [];
    facility.removeAsset.forEach((item) => {
      removeIds.push(item);
    });

    obj.remove_assets = removeIds;
  }
  return obj;
};

const serializeFacilityFloorplan = (facility: IFacility) => {
  const formData = new FormData();
  if (facility.mapFile !== undefined) formData.append("map_file", facility.mapFile || "");
  if (facility.mapScale !== undefined) formData.append("map_scale", (facility.mapScale || "").toString());
  if (facility.mapTransform !== undefined) formData.append("map_transform", JSON.stringify(facility.mapTransform));
  return formData;
};

export const facilityApi = folderApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilityById: builder.query<IFacility, string>({
      query(id) {
        return {
          url: `facilities/${id}/`,
        };
      },
      transformResponse: (response: any) => {
        return deserializeFacility(response);
      },
      providesTags: (result, error, id) => [{ type: "Facilities", id }],
    }),
    updateFacility: builder.mutation<any, Partial<IFacility>>({
      query(body: IFacility) {
        return {
          url: `facilities/${body.id}/`,
          method: "PATCH",
          body: serializeFacility(body),
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Facilities", id },
        { type: "Assets", id: "LIST" },
      ],
    }),
    updateFacilityFloorplan: builder.mutation<any, Partial<IFacility>>({
      query(body: IFacility) {
        return {
          url: `facilities/${body.id}/`,
          method: "PATCH",
          body: serializeFacilityFloorplan(body),
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Facilities", id }],
    }),
  }),
});

export const {
  useGetFacilityByIdQuery,
  useLazyGetFacilityByIdQuery,
  useUpdateFacilityMutation,
  useUpdateFacilityFloorplanMutation,
} = facilityApi;
