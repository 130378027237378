import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";

function ErrorPage() {
  const homePage = window.origin + "/login";

  const currentLocation = useMemo(() => {
    return window.location.href;
  }, []);

  window.addEventListener("popstate", () => {
    if (currentLocation != window.location.href) {
      window.location.reload();
    }
  });

  return (
    <>
      <img
        src="/images/aptixAR-logov2.png"
        style={{
          top: "16px",
          left: "24px",
          width: "112px",
          height: "48px",
          position: "absolute",
        }}
      />

      <Container
        sx={{
          // border: "1px red solid",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "95vw",
        }}
      >
        <Grid container spacing={2} sx={{ flex: 1, ml: 0 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            order={{ xs: 2, sm: 1 }}
          >
            <Box>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: 20, lg: 30 },
                  lineHeight: { xs: "40px", lg: "57px" },
                  mt: -7,
                }}
              >
                <p>
                  {"Whoops..."} <br />
                </p>
                <br />
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 15, lg: 25 },
                  lineHeight: { xs: "40px", lg: "57px" },
                  mt: -7,
                }}
              >
                <p>
                  {
                    "Something went wrong. This is embarrassing. Our team is working to fix it. In the meantime, here is what you can do:\n\n"
                  }
                </p>
                <p>
                  <a href={window.location.href}>{"Refresh the page"}</a>
                </p>
                <p>
                  <a href={homePage}> {"Go back to the AptixAR homepage"}</a>
                </p>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} pl={4} order={{ xs: 1, sm: 2 }}>
            <img src="/images/error.png" style={{ width: "90%", margin: "0 auto", display: "block" }} />
          </Grid>
        </Grid>
      </Container>
      <a
        id="contact-help"
        href="mailto: support@interaptix.com"
        style={{ position: "absolute", right: "24px", bottom: "24px" }}
      >
        Contact Support
      </a>
    </>
  );
}

export default ErrorPage;
