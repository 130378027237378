import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, Grid, DialogTitle, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useMemo } from "react";

import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import { UseState } from "utils/TypeUtils/ReactTypeUtils";

export default function ConfirmationDialog({
  useOpen,
  dialogText,
  onConfirm,
  onDelete,
  title = undefined,
  confirmText = "Confirm",
  useSelected,
}: {
  useOpen: UseState<boolean>;
  dialogText: string;
  onConfirm?: () => void;
  onDelete?: (id: string) => void;
  title?: string;
  confirmText?: string;
  useSelected?: UseState<string | undefined>;
}) {
  const theme = useTheme();
  const { state: open, setState: setOpen } = useOpen;

  const useSelectedId: UseState<string | undefined> = useMemo(() => {
    if (useSelected) {
      return useSelected;
    } else {
      return { state: "", setState: () => {} };
    }
  }, [useSelected]);

  const { state: selectedId, setState: setSelectedId } = useSelectedId;

  const exitDialog = useCallback(() => {
    setOpen(false);
    if (useSelected) {
      setSelectedId(undefined);
    }
  }, [setOpen, setSelectedId, useSelected]);

  return (
    <Dialog
      onClose={exitDialog}
      sx={{
        ".MuiDialog-paper": {
          borderRadius: 0,
          boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px 24px 24px",
          gap: "24px",
        },
        width: "100%",
      }}
      aria-labelledby="confirm-dialog-title"
      open={open}
    >
      <DialogTitle style={{ width: "100%", padding: "24px 0px 0px 0px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <Typography
            variant="dalmatianBase"
            sx={{
              fontWeight: 800,
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={exitDialog} style={{ color: theme.palette.dalmatianDesign.dark }} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ width: "440px", padding: "0px" }}>
        <Grid container style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Grid item xs={12} style={{ width: "100%", paddingBottom: "32px" }}>
            <Typography variant="dalmatianP">{dialogText}</Typography>
          </Grid>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              padding: 0,
              gap: "8px",
              width: "65%",
            }}
          >
            <BaseButton
              id={`cancel-button`}
              variant="outlined"
              onClick={() => {
                exitDialog();
              }}
            >
              Cancel
            </BaseButton>
            <BaseButton
              id={`confirm-button`}
              variant="contained"
              color="primary"
              onClick={() => {
                if (onDelete) {
                  onDelete(selectedId || "");
                } else if (onConfirm) {
                  onConfirm();
                }
                exitDialog();
              }}
            >
              {confirmText}
            </BaseButton>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
