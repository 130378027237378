import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

import { AnnotationType, identityMatrix } from "..";
import { AnnotationBase, AnnotationObjectType, Matrix4, Point3D } from "../types";
import filePng from "assets/icons/file.png";
import imagePng from "assets/icons/image.png";
import { Matrix4ToThreeMatrix4 } from "components/CameraSelector/Common/ThreeUtils";
import { COLOR } from "components/CameraSelector/Constants";
import { ANNOTATION_NAME_PREFIX } from "services/ContentServer/Annotation/constants";
import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";
import { colorStringToInt } from "utils/ColorUtils";
import { getMonthDayTime } from "utils/DateUtils";
import { DrawingPlane } from "utils/Drawing";
import { getFileObjectFromURLAsync, isDrawingFile, readFileAsText } from "utils/FileUtils";
import ObjectLoader from "utils/Three/ObjectLoader";

export const serverToView: { [key: string]: keyof Annotation | undefined } = {
  id: "id",
  plane: "plane",
  color: "color",
  pose: "pose",
  static_file: "staticFile",
  file: "file",
  last_modified_by: "lastModifiedBy",
  last_modified_at: "lastModifiedAt",
  created_by: "createdBy",
  created_at: "createdAt",
  is_active: "isActive",
  name: "name",
  notes: "notes",
  description: "description",
  is_template: "isTemplate",
  associated_snapshot_id: "associatedSnapshot",
  type: "type",
};

const objectLoader = new ObjectLoader();

export class Annotation extends ISerialization implements AnnotationBase {
  public id?: string | null;
  public color?: string | null;
  public plane?: DrawingPlane | null;
  public pose?: Matrix4 | null;
  public staticFile?: boolean | null;
  public file?: string | File | null;
  public type?: AnnotationType;
  public lastModifiedBy?: string | null;
  public lastModifiedAt?: string | null;
  public createdBy?: string | null;
  public createdAt?: string | null;
  public associatedSnapshot?: number | string | null;
  public description?: string | null;
  public notes?: string | null;
  public name?: string | null;
  public isTemplate?: boolean | null;

  public points?: Point3D[] | null;
  public radius?: number | null;

  public isActive?: boolean | null;

  public object?: AnnotationObjectType;

  constructor(annotation?: Annotation) {
    super();
    if (annotation) {
      Object.assign(this, annotation);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Annotation>(this, serverToView, json);
    this.plane = json.plane
      ? new DrawingPlane(json.plane.origin_vertex, json.plane.up_vertex, json.plane.right_vertex)
      : new DrawingPlane();
    this.type = json.type;
    this.parseAnnotation();
  }

  serialize(): any {
    const request = serializerHelper<Annotation>(this, serverToView);
    if (this.plane !== undefined) {
      request.plane = JSON.stringify({
        origin_vertex: this.plane?.originVertex,
        right_vertex: this.plane?.rightVertex,
        up_vertex: this.plane?.upVertex,
      });
    }
    if (this.pose) {
      request.pose = JSON.stringify(this.pose);
    }
    request.type = this.type;
    return request;
  }

  async parseAnnotation() {
    if (this.file) {
      try {
        if (isDrawingFile(this.file)) {
          let file: File;

          if (typeof this.file === "string") {
            file = await getFileObjectFromURLAsync(this.file, "drawing.aptixdrawing");
          } else {
            file = this.file;
          }

          const fileText = await readFileAsText(file);
          const { points, radius } = JSON.parse(fileText);

          this.points = points;
          this.radius = radius;
          this.type = AnnotationType.DRAWING;
          const drawingLineMaterial = new LineMaterial({
            color: colorStringToInt(this.color ? this.color : COLOR.RED),
            linewidth: this.radius || 0.005,
            worldUnits: true,
          });

          const geometry = new LineGeometry();
          const qualifiedPoints: number[] = [];
          this.points?.forEach((point) => {
            qualifiedPoints.push(point[0], point[1], point[2]);
          });
          geometry.setPositions(qualifiedPoints);
          this.object = new Line2(geometry, drawingLineMaterial);
          this.object.computeLineDistances();
        } else if (typeof this.file === "string") {
          if (this.type === AnnotationType.MODEL) {
            const file = await getFileObjectFromURLAsync(this.file, this.file);
            this.object = await objectLoader.load(file, file.name);
            const matrix = Matrix4ToThreeMatrix4(this.pose ? this.pose : identityMatrix);

            this.object?.position.setFromMatrixPosition(matrix);
            this.object?.rotation.setFromRotationMatrix(matrix);
            this.object?.scale.setFromMatrixScale(matrix);
          } else {
            const matrix = Matrix4ToThreeMatrix4(this.pose ? this.pose : identityMatrix);
            const loader = new THREE.TextureLoader();
            let spriteIcon = filePng;

            if (this.type === AnnotationType.IMAGE || this.type === AnnotationType.VIDEO) {
              spriteIcon = imagePng;
            }
            const spriteMap = loader.load(spriteIcon, (texture) => {
              texture.magFilter = THREE.NearestFilter;
              const spriteMaterial = new THREE.SpriteMaterial({
                map: spriteMap,
                depthTest: false,
                depthWrite: false,
                transparent: true,
                opacity: 1,
              });

              this.object = new THREE.Sprite(spriteMaterial);
              this.object.position.setFromMatrixPosition(matrix);
              this.object.scale.set(0.5, 0.5, 0.5);
              this.object.name = ANNOTATION_NAME_PREFIX + getMonthDayTime(this.createdAt, true, true);
            });
          }
        }
        if (this.object) {
          this.object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              (child.material as THREE.Material).transparent = true;
            }
          });
          this.object.name = ANNOTATION_NAME_PREFIX + getMonthDayTime(this.createdAt, true, true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
}
