import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { UserInvite } from "../serviceTypes/UserInvite";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const UserInviteSWRKeys = {
  USER_INVITES: "user_invites",
};
export class UserInviteService extends ServiceBase<UserInvite> {
  constructor(apiInstance: APIInstance) {
    super(UserInvite, apiInstance, ENDPOINTS.USERINVITE);
  }
}
