export const DEFAULT_CONFIG = {
  auth: {
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // There are some security risks with this but it's the recommended approach for SSO
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: true,
  },
};
