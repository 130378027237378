import { useContext, useEffect } from "react";
import { userApi, useGetUsersQuery } from "redux/api/userApi";
import { useAppDispatch } from "redux/hooks";

import { useAuth } from "hooks/useAuth";
import { ConnectionUpdate } from "services/MessageHub";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";

const useConnectionUpdate = () => {
  const auth = useAuth();
  const { poseHub } = useContext(RequestContext);
  const { data: users } = useGetUsersQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const callback = (update: ConnectionUpdate) => {
      if (Object.keys(update).length > 0 && users) {
        const findUser = users.find((user) => user.id === update.userID);
        if (findUser && findUser.connection?.status !== update.connectionType) {
          // update cache
          dispatch(
            userApi.util.updateQueryData("getUsers", undefined, (draft) => {
              const updUser = draft.find((user) => user.id === findUser.id);
              if (updUser && updUser.connection) {
                updUser.connection.status = update.connectionType;
                updUser.status = update.connectionType;
              }
            })
          );
        }
      }
    };

    if (auth.user) {
      return poseHub.subscribeToClientConnections(callback);
    }
  }, [auth.user, users, poseHub, dispatch]);
};

export default useConnectionUpdate;
