import countryRegionData, { Country } from "country-region-data";

import { ImageFile } from "components/Annotation/types";
import { SingleSite } from "services/ContentServer/Map";

export interface SiteState {
  name: string;
  company: string;
  address: string;
  country: { data: string; displayVal: string };
  region: { data: string; displayVal: string };
  zipPostalCode: string;
  keyContact: { data: string; displayVal: string };
}

const REORDER_COUNTRIES = ["United States", "Canada"];

const reorderCountryData = (countryNames: string[]) => {
  const countries = countryNames
    .map((name) => countryRegionData.find((data) => data.countryName === name))
    .filter((country) => country) as Country[];

  return countries.concat(countryRegionData.filter((c) => !countryNames.includes(c.countryName)));
};

export const orderedCountryRegionData = reorderCountryData(REORDER_COUNTRIES);

export enum SiteActionType {
  RESET_ALL,
  UPDATE_NAME,
  UPDATE_IMAGE,
  UPDATE_COMPANY,
  UPDATE_ADDRESS,
  UPDATE_COUNTRY,
  UPDATE_REGION,
  UPDATE_ZIP_POSTAL_CODE,
  UPDATE_KEY_CONTACT,
}

export interface SiteAction {
  type: SiteActionType;
  site?: SingleSite;
  name?: string;
  image?: ImageFile | null;
  company?: string;
  address?: string;
  country?: { data: string; displayVal: string };
  region?: { data: string; displayVal: string };
  zipPostalCode?: string;
  keyContact?: { data: string; displayVal: string };
}

export const siteReducer = (state: SiteState, action: SiteAction): SiteState => {
  switch (action.type) {
    case SiteActionType.RESET_ALL:
      if (action.site) {
        return {
          name: action.site.name,
          company: action.site.company,
          address: action.site.address,
          country: {
            data: action.site.country,
            displayVal: action.site.country,
          },
          region: {
            data: action.site.region,
            displayVal: action.site.region,
          },
          zipPostalCode: action.site.zipPostalCode,
          keyContact: action.keyContact || initialSiteState.keyContact,
        };
      } else {
        return { ...initialSiteState, keyContact: action.keyContact || initialSiteState.keyContact };
      }
    case SiteActionType.UPDATE_NAME:
      return {
        ...state,
        name: action.name || "",
      };

    case SiteActionType.UPDATE_IMAGE:
      return {
        ...state,
      };
    case SiteActionType.UPDATE_ADDRESS:
      return {
        ...state,
        address: action.address || "",
      };
    case SiteActionType.UPDATE_COMPANY:
      return {
        ...state,
        company: action.company || "",
      };
    case SiteActionType.UPDATE_ZIP_POSTAL_CODE:
      return {
        ...state,
        zipPostalCode: action.zipPostalCode || "",
      };
    case SiteActionType.UPDATE_COUNTRY:
      return {
        ...state,
        country: action.country || initialSiteState.country,
        region: action.country
          ? {
              data:
                orderedCountryRegionData.find((country) => country.countryName === state.country.displayVal)?.regions[0]
                  .name || "",
              displayVal:
                orderedCountryRegionData.find((country) => country.countryName === state.country.displayVal)?.regions[0]
                  .name || "",
            }
          : initialSiteState.region,
      };
    case SiteActionType.UPDATE_REGION:
      return {
        ...state,
        region: action.region || initialSiteState.region,
      };
    case SiteActionType.UPDATE_KEY_CONTACT:
      return {
        ...state,
        keyContact: action.keyContact || initialSiteState.keyContact,
      };
    default:
      return state;
  }
};

export const initialSiteState: SiteState = {
  name: "",
  company: "",
  address: "",
  country: { data: "", displayVal: "" },
  region: { data: "", displayVal: "" },
  zipPostalCode: "",
  keyContact: { data: "", displayVal: "" },
};
