export enum MediaActionType {
  ENABLE_LOCAL_VIDEO,
  DISABLE_LOCAL_VIDEO,
  ENABLE_LOCAL_AUDIO,
  DISABLE_LOCAL_AUDIO,
  ALL_LOCAL_MEDIA,
  LOCAL_AUDIO_ONLY,
  NO_LOCAL_MEDIA,
  ENABLE_REMOTE_VIDEO,
  DISABLE_REMOTE_VIDEO,
  ENABLE_REMOTE_AUDIO,
  DISABLE_REMOTE_AUDIO,
  CALL_ENDED,
  START_SENDING_STATE,
}

interface MediaAction {
  type: MediaActionType;
  videoDeviceId?: string;
  audioDeviceId?: string;
}

export interface MediaState {
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  isPeerVideoEnabled: boolean;
  isPeerAudioEnabled: boolean;
  shouldSendMediaState: boolean;
  selectedVideoDevice?: string;
  selectedAudioDevice?: string;
}

export const mediaStateReducer = (state: MediaState, action: MediaAction): MediaState => {
  switch (action.type) {
    case MediaActionType.ENABLE_LOCAL_VIDEO:
      return {
        ...state,
        isVideoEnabled: true,
        selectedVideoDevice: action.videoDeviceId,
      };
    case MediaActionType.DISABLE_LOCAL_VIDEO:
      return {
        ...state,
        isVideoEnabled: false,
      };
    case MediaActionType.ENABLE_LOCAL_AUDIO:
      return {
        ...state,
        isAudioEnabled: true,
        selectedAudioDevice: action.audioDeviceId,
      };
    case MediaActionType.DISABLE_LOCAL_AUDIO:
      return {
        ...state,
        isAudioEnabled: false,
      };
    case MediaActionType.ALL_LOCAL_MEDIA:
      return {
        ...state,
        isVideoEnabled: true,
        isAudioEnabled: true,
        selectedVideoDevice: action.videoDeviceId,
        selectedAudioDevice: action.audioDeviceId,
      };
    case MediaActionType.LOCAL_AUDIO_ONLY:
      return {
        ...state,
        isVideoEnabled: false,
        isAudioEnabled: true,
        selectedAudioDevice: action.audioDeviceId,
      };
    case MediaActionType.NO_LOCAL_MEDIA:
      return {
        ...state,
        isVideoEnabled: false,
        isAudioEnabled: false,
      };
    case MediaActionType.ENABLE_REMOTE_VIDEO:
      return {
        ...state,
        isPeerVideoEnabled: true,
      };
    case MediaActionType.DISABLE_REMOTE_VIDEO:
      return {
        ...state,
        isPeerVideoEnabled: false,
      };
    case MediaActionType.ENABLE_REMOTE_AUDIO:
      return {
        ...state,
        isPeerAudioEnabled: true,
      };
    case MediaActionType.DISABLE_REMOTE_AUDIO:
      return {
        ...state,
        isPeerAudioEnabled: false,
      };
    case MediaActionType.CALL_ENDED:
      return {
        ...state,
        isVideoEnabled: false,
        isAudioEnabled: false,
        isPeerVideoEnabled: true,
        isPeerAudioEnabled: true,
        shouldSendMediaState: false,
      };
    case MediaActionType.START_SENDING_STATE:
      return {
        ...state,
        shouldSendMediaState: true,
      };
    default:
      throw new Error();
  }
};
