import packageJson from "../package.json";
import { AzureADConfig, ServerType } from "./services/AzureAD";

const SWRConfig = { revalidateOnFocus: false };

const local = {
  //TODO: these values should come from environment variables
  TENANT_ID: "efdb9e64-1e75-4adb-ac48-aa0e1b78889a",
  TENANT_NAME: "aptixar",
  POLICY: "B2C_1A_MT1Susi-FirstTenant",
  EDIT_POLICY: "B2C_1A_MT1ProfileEdit",
  RESET_PASSWORD_POLICY: "B2C_1A_MT1PasswordReset",
  INVITE_POLICY: "B2C_1A_MT1Invitation",

  BACKEND_BASE_URL: "http://localhost:8000",
  BACKEND_CLIENT_ID: "405adaf4-9f86-449d-9a56-ac040ba7dc17",
  // Use a valid-but-nonsense key to just have insights stay out of your way.
  INSIGHTS_CONNECTION_STRING: "InstrumentationKey=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx;",
  // testbed should not be used unless working on something insights related
  //INSIGHTS_CONNECTION_STRING: "",

  COMM_SERVER_BASE_URL: "ws://localhost:6061/ws/connect",

  AD_CLIENT_ID: "b9588772-589f-4213-8639-1eefa2fe3874",
  AD_REDIRECT_URL: "http://localhost:3000/.aad-callback",
  AD_LOGOUT_URL: "http://localhost:3000/logout",
  RESOURCE_GROUP: "",

  MESH_GEN_URL: "http://localhost:9003",
};

const testbed = {
  //TODO: these values should come from environment variables
  TENANT_ID: "efdb9e64-1e75-4adb-ac48-aa0e1b78889a",
  TENANT_NAME: "aptixar",
  POLICY: "B2C_1A_MT1Susi-FirstTenant",
  EDIT_POLICY: "B2C_1A_MT1ProfileEdit",
  RESET_PASSWORD_POLICY: "B2C_1A_MT1PasswordReset",
  INVITE_POLICY: "B2C_1A_MT1Invitation",

  BACKEND_BASE_URL: "https://content-server-mqbsd2w2vatna.azurewebsites.net",
  BACKEND_CLIENT_ID: "405adaf4-9f86-449d-9a56-ac040ba7dc17",
  INSIGHTS_CONNECTION_STRING: "",

  COMM_SERVER_BASE_URL: "wss://comm-server-mqbsd2w2vatna.azurewebsites.net/ws/connect",

  AD_CLIENT_ID: "b9588772-589f-4213-8639-1eefa2fe3874",
  AD_REDIRECT_URL: "https://mapp-web-portal-mqbsd2w2vatna.azurewebsites.net/.aad-callback",
  AD_LOGOUT_URL: "https://mapp-web-portal-mqbsd2w2vatna.azurewebsites.net/logout",
  RESOURCE_GROUP: "mqbsd2w2vatna",

  MESH_GEN_URL: "https://mesh-gen-server-pccqowupj3ywk.azurewebsites.net",
};

const dev = {
  //TODO: these values should come from environment variables
  TENANT_ID: "efdb9e64-1e75-4adb-ac48-aa0e1b78889a",
  TENANT_NAME: "aptixar",
  POLICY: "B2C_1A_MT1Susi-FirstTenant",
  EDIT_POLICY: "B2C_1A_MT1ProfileEdit",
  RESET_PASSWORD_POLICY: "B2C_1A_MT1PasswordReset",
  INVITE_POLICY: "B2C_1A_MT1Invitation",

  BACKEND_BASE_URL: "https://content-server-44hat4gnhrijq-guhud8hkbed8babv.z01.azurefd.net",
  BACKEND_CLIENT_ID: "405adaf4-9f86-449d-9a56-ac040ba7dc17",
  INSIGHTS_CONNECTION_STRING:
    "InstrumentationKey=341d45b6-73a0-4c22-b41e-32149a5ed052;IngestionEndpoint=https://canadacentral-0.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/;ApplicationId=e5fe68d7-8e07-4ce3-b68c-5d57f863110f",

  COMM_SERVER_BASE_URL: "wss://comm-server-44hat4gnhrijq.azurewebsites.net/ws/connect",

  AD_CLIENT_ID: "b9588772-589f-4213-8639-1eefa2fe3874",
  AD_REDIRECT_URL: "https://dev.aptixar.com/.aad-callback",
  AD_LOGOUT_URL: "https://dev.aptixar.com/logout",
  RESOURCE_GROUP: "44hat4gnhrijq",

  MESH_GEN_URL: "https://mesh-gen-server-pccqowupj3ywk.azurewebsites.net",
};

const stage = {
  //TODO: these values should come from environment variables
  TENANT_ID: "0716bd8a-226b-407b-b38c-48e40c1579fb",
  TENANT_NAME: "interaptixaptixarstage",
  POLICY: "B2C_1A_MT1Susi-FirstTenant",
  EDIT_POLICY: "B2C_1A_MT1ProfileEdit",
  RESET_PASSWORD_POLICY: "B2C_1A_MT1PasswordReset",
  INVITE_POLICY: "B2C_1A_MT1Invitation",

  BACKEND_BASE_URL: "https://content-server-av4kygrkiwdae-b6fhdsg4fzh0c0hp.z01.azurefd.net",
  BACKEND_CLIENT_ID: "53339c71-8651-4ee6-aee4-6734e116491c",
  INSIGHTS_CONNECTION_STRING:
    "InstrumentationKey=600777e1-f3ea-4aa4-bbbf-bc9cf62a5448;IngestionEndpoint=https://canadacentral-0.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/;ApplicationId=811b4fd0-5c44-4c38-b8dc-7e482815327a",

  COMM_SERVER_BASE_URL: "wss://comm-server-av4kygrkiwdae.azurewebsites.net/ws/connect",

  AD_CLIENT_ID: "76ba4198-5b7d-4d82-a90e-e170ba5b725e",
  AD_REDIRECT_URL: "https://stage.aptixar.com/.aad-callback",
  AD_LOGOUT_URL: "https://stage.aptixar.com/logout",
  RESOURCE_GROUP: "av4kygrkiwdae",

  MESH_GEN_URL: "https://mesh-gen-server-uy23wrcuzcs64.azurewebsites.net",
};

const smeprod = {
  //TODO: these values should come from environment variables
  TENANT_ID: "d306b47c-f862-42d9-973c-4d2c8610d6dd",
  TENANT_NAME: "aptixarsme",
  POLICY: "B2C_1A_MT1Susi-FirstTenant",
  EDIT_POLICY: "B2C_1A_MT1ProfileEdit",
  RESET_PASSWORD_POLICY: "B2C_1A_MT1PasswordReset",
  INVITE_POLICY: "B2C_1A_MT1Invitation",

  BACKEND_BASE_URL: "https://content-server-lfwhrpnmnovn2-atbxb5b5bubne4f6.z01.azurefd.net",
  BACKEND_CLIENT_ID: "d5ca56f9-dc2c-4782-af98-84c472f0bfda",
  INSIGHTS_INSTRUMENTATION_KEY: "dcd44ceb-e977-410f-95a5-64e1af0697ef",
  INSIGHTS_CONNECTION_STRING:
    "InstrumentationKey=dcd44ceb-e977-410f-95a5-64e1af0697ef;IngestionEndpoint=https://canadacentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/;ApplicationId=17d07bab-31e7-4e88-95db-0c78a52a0586",

  COMM_SERVER_BASE_URL: "wss://comm-server-lfwhrpnmnovn2.azurewebsites.net/ws/connect",

  AD_CLIENT_ID: "efa8d54e-1cd9-4df4-bec7-0cc09236cf9e",
  AD_REDIRECT_URL: "https://smeprod.aptixar.com/.aad-callback",
  AD_LOGOUT_URL: "https://smeprod.aptixar.com/logout",
  RESOURCE_GROUP: "lfwhrpnmnovn2",

  MESH_GEN_URL: "https://mesh-gen-server-uy23wrcuzcs64.azurewebsites.net",
};

const prod = {
  //TODO: these values should come from environment variables
  TENANT_ID: "f6acc3d4-f0ab-4945-b8a5-eb9a5fa17999",
  TENANT_NAME: "interaptixaptixar",
  POLICY: "B2C_1A_MT1Susi-FirstTenant",
  EDIT_POLICY: "B2C_1A_MT1ProfileEdit",
  RESET_PASSWORD_POLICY: "B2C_1A_MT1PasswordReset",
  INVITE_POLICY: "B2C_1A_MT1Invitation",

  BACKEND_BASE_URL: "https://content-server-akl7fuhes7ozg-dxd7bhh9d7dfchdv.z01.azurefd.net",
  BACKEND_CLIENT_ID: "3ef8b9cf-0ee1-46d9-b156-a7ffcef494ab",
  INSIGHTS_CONNECTION_STRING:
    "InstrumentationKey=9fbd9792-2f64-4f60-8ad4-3468781e63b7;IngestionEndpoint=https://canadacentral-0.in.applicationinsights.azure.com/;LiveEndpoint=https://canadacentral.livediagnostics.monitor.azure.com/;ApplicationId=56d6b021-1598-4161-9c47-ec1869111287",

  COMM_SERVER_BASE_URL: "wss://comm-server-akl7fuhes7ozg.azurewebsites.net/ws/connect",

  AD_CLIENT_ID: "6c2bd159-dfed-4991-a2e6-6154ad74b529",
  AD_REDIRECT_URL: "https://login.aptixar.com/.aad-callback",
  AD_LOGOUT_URL: "https://login.aptixar.com/logout",
  RESOURCE_GROUP: "akl7fuhes7ozg",

  MESH_GEN_URL: "https://mesh-gen-server-uy23wrcuzcs64.azurewebsites.net",
};

const config = (function () {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return local;
    case "testbed":
      return testbed;
    case "dev":
      return dev;
    case "stage":
      return stage;
    case "smeprod":
      return smeprod;
    case "prod":
      return prod;
    case "test":
      return local;
    default:
      throw new Error("Invalid environment!");
  }
})();

const STATUS_URLS: { [id: string]: string } = {};

if (process.env.REACT_APP_ENV === "local") {
  STATUS_URLS[ServerType.COMM_SERVER] = `http://localhost:6061`;
} else {
  STATUS_URLS[ServerType.COMM_SERVER] = `https://comm-server-${config.RESOURCE_GROUP}.azurewebsites.net`;
  STATUS_URLS[ServerType.VISION_SERVER] = `https://vision-server-vt4kofg2uvut4.azurewebsites.net`;
}

STATUS_URLS[ServerType.CONTENT_SERVER] = config.BACKEND_BASE_URL;

const AUTHORITY_BASE = `https://${config.TENANT_NAME}.b2clogin.com/tfp/${config.TENANT_NAME}.onmicrosoft.com/`;

const BACKEND_SCOPES = [`https://${config.TENANT_NAME}.onmicrosoft.com/${config.BACKEND_CLIENT_ID}/user_impersonation`];

const azureAdConfig: AzureADConfig = {
  clientId: config.AD_CLIENT_ID,
  authorities: {
    signUpSignIn: `${AUTHORITY_BASE}${config.POLICY}`,
    editProfile: `${AUTHORITY_BASE}${config.EDIT_POLICY}`,
    passwordReset: `${AUTHORITY_BASE}${config.RESET_PASSWORD_POLICY}`,
    invite: `${AUTHORITY_BASE}${config.INVITE_POLICY}`,
  },
  knownAuthorities: [`https://${config.TENANT_NAME}.b2clogin.com`],
  redirectUri: config.AD_REDIRECT_URL,
  postLogoutRedirectUri: config.AD_LOGOUT_URL,
  backendScopes: BACKEND_SCOPES,
};

const version = (function () {
  const semver = process.env.REACT_APP_SEMVER;
  if (process.env.REACT_APP_ENV !== "dev") {
    return packageJson.clientVersion;
  }
  const gitHash = process.env.REACT_APP_HASH;
  if (semver) {
    const firstIndex = semver.indexOf(".");
    const secondIndex = semver.indexOf(".", firstIndex + 1);
    const majorMinor = semver.substring(0, secondIndex);
    return `${majorMinor}.${gitHash}`;
  }
  return gitHash || "not available";
})();

export default {
  SWR_CONFIG: SWRConfig,
  MICROSOFT_GRAPH_BASE_URL: "https://graph.microsoft.com/v1.0",
  AZURE_AD_CONFIG: azureAdConfig,
  VERSION: version,
  STATUS_URLS: STATUS_URLS,
  GOOGLE_MAP_KEY: "AIzaSyB7wNHUccplGXDr5r59_Xfc9wWdAlvmhEg",
  GOOGLE_MAP_SELECTION_ID: "675d67608435c886",
  GOOGLE_MAP_THUMBNAIL_ID: "f78884e21f6c0a10",
  ...config,
};
