import moment from "moment";

export function descendingComparator(a: any, b: any, orderBy: string, secondaryKey?: string, order?: string) {
  //The Comparator function that results in a descending sort when used in array sort method

  const aVal = secondaryKey ? a[orderBy][secondaryKey] : a[orderBy];
  const bVal = secondaryKey ? b[orderBy][secondaryKey] : b[orderBy];

  const isAEmpty = aVal === '' || aVal === null || aVal === undefined;
  const isBEmpty = bVal === '' || bVal === null || bVal === undefined;

  if (!isAEmpty && isBEmpty) {
    return (order === 'asc' ? 1 : -1); 
  }
  if (isAEmpty && !isBEmpty) {
    return (order === 'asc' ? -1 : 1); 
  }

  const compFirst = parseInt(aVal) && parseInt(bVal) ? parseInt(aVal) : aVal?.toString().toUpperCase();
  const compSecond = parseInt(aVal) && parseInt(bVal) ? parseInt(bVal) : bVal?.toString().toUpperCase();

  if (compSecond < compFirst) {
    return -1;
  }
  if (compSecond > compFirst) {
    return 1;
  }
  return 0;
}

export function descendingDatesComparator(a: any, b: any, orderBy: string) {
  //The Comparator function that results in a descending sort when used in array sort method

  const aValid = moment(a[orderBy]).isValid();
  const bValid = moment(b[orderBy]).isValid();

  const compFirst = aValid && bValid ? moment(a[orderBy]) : a[orderBy];
  const compSecond = aValid && bValid ? moment(b[orderBy]) : b[orderBy];

  if (!aValid || !bValid) {
    return descendingComparator(a, b, orderBy);
  }

  if (moment(compFirst).isAfter(moment(compSecond))) {
    return -1;
  }
  if (moment(compSecond).isAfter(moment(compFirst))) {
    return 1;
  }
  return 0;
}
