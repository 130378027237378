import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof JobData | undefined } = {
  file_data: "file",
  key: "key",
  asset_file_id: "assetFileId",
  queue_id: "queueId",
};

export class JobData extends ISerialization {
  public file?: string | null;
  public key?: string | null;
  public assetFileId?: number | null;
  public queueId?: string | null;

  constructor(jobData?: JobData) {
    super();
    if (jobData) {
      Object.assign(this, jobData);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<JobData>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<JobData>(this, serverToView);
    return request;
  }
}
