import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import { Box, IconButton, Popover, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ClockPicker, ClockPickerView } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState, useCallback, useEffect, useMemo } from "react";

import BaseButton, { SquareToggleButton } from "components/DalmatianDesignComponents/BaseButton";
import { toTwoDigits } from "utils/DateUtils";

interface TimePickerProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: HTMLElement | null | ((prev: HTMLElement | null) => HTMLElement | null)) => void;
  hour: number;
  min: number;
  handleClick: (date: moment.Moment | undefined) => any;
}

enum PickerView {
  CLOCK_VIEW = 0,
  INPUT_VIEW = 1,
}

const TimePicker = ({ anchorEl, setAnchorEl, hour, min, handleClick }: TimePickerProps) => {
  const theme = useTheme();
  const [currTime, setCurrTime] = useState<moment.Moment | undefined>(moment().startOf("day"));
  const [pm, setPm] = useState(false);
  const [view, setView] = useState<ClockPickerView>("hours");
  const [pickerView, setPickerView] = useState<PickerView>(PickerView.CLOCK_VIEW);
  const [hourInput, setHourInput] = useState(hour);
  const [minInput, setMinInput] = useState(min);
  const [init, setInit] = useState(true);

  useEffect(() => {
    if (init === true) {
      const initTime = moment().startOf("day");
      initTime.hour(hour);
      initTime.minute(min);
      setCurrTime(initTime);
      setPm(hour >= 12);
      setPickerView(PickerView.CLOCK_VIEW);
      setView("hours");
      setHourInput(hour === 12 ? 12 : hour % 12 === 0 ? 12 : hour % 12);
      setMinInput(min);
      setInit(false);
    }
  }, [hour, min, init, setInit, setHourInput]);

  const showError = useMemo(() => {
    return hourInput === 0 || hourInput > 12 || minInput < 0 || minInput >= 60;
  }, [hourInput, minInput]);

  const handleHourChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = +event.target.value;
      if (!isNaN(val) && val <= 12 && val >= 0) {
        setHourInput(val);
      }
    },
    [setHourInput]
  );

  const handleMinChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = +event.target.value;
      if (!isNaN(val) && val < 60) {
        setMinInput(val);
      }
    },
    [setMinInput]
  );

  const handleFinalizeTime = useCallback(() => {
    if (!showError) {
      const updatedTime = currTime;
      updatedTime?.hour(hourInput);
      updatedTime?.minute(minInput);
      if (pm) {
        updatedTime?.add(12, "hours");
      }
      setCurrTime(updatedTime);
    }
  }, [hourInput, minInput, currTime, pm, showError]);

  const handleAMPMChange = useCallback(() => {
    setPm((prev) => {
      const updatedTime = currTime;
      if (!prev) {
        setCurrTime(updatedTime?.add(12, "hours"));
      } else {
        setCurrTime(updatedTime?.subtract(12, "hours"));
      }
      return !prev;
    });
  }, [currTime]);

  const handleClockChange = useCallback(
    (newDate: moment.Moment | null | undefined) => {
      if (newDate && newDate.isValid()) {
        if (view === "hours") {
          const isPM = newDate.hours() >= 12;
          setPm(isPM);
          setHourInput(newDate.hours() === 12 ? 12 : newDate.hours() % 12 === 0 ? 12 : newDate.hours() % 12);
        }
        setMinInput(newDate.minutes());
        setCurrTime(newDate);
      }
    },
    [setMinInput, view]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleTimeSubmit = useCallback(() => {
    handleClick(currTime);
  }, [handleClick, currTime]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      disableEnforceFocus
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      PaperProps={{
        style: {
          background: "white",
          border: "none",
          borderRadius: 0,
          boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
          padding: 0,
          width: "350px",
        },
      }}
    >
      <Box>
        <Box style={{ display: "flex", alignItems: "flex-start", padding: "24px", gap: "10px" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "10px",
              lineHeight: "16px",
              letterSpacing: "1.5px",
              textTransform: "uppercase",
              color: "#64676A",
            }}
          >
            Enter Time
          </Typography>
        </Box>
        <Box style={{ padding: "0 24px 8px 24px" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "12px",
              height: "100%",
            }}
          >
            <Box style={{ display: "flex", gap: "8px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: 0,
                  gap: "7px",
                  height: "100%",
                }}
              >
                <TextField
                  inputProps={{
                    style: {
                      color: pickerView === PickerView.CLOCK_VIEW ? "white" : theme.palette.dalmatianDesign.dark,
                      WebkitTextFillColor:
                        pickerView === PickerView.CLOCK_VIEW ? "white" : theme.palette.dalmatianDesign.dark,
                      cursor: pickerView === PickerView.CLOCK_VIEW ? "pointer" : "text",
                      caretColor: pickerView === PickerView.CLOCK_VIEW ? "transparent" : "auto",
                    },
                  }}
                  sx={{
                    width: "96px",
                    height: "80px",
                    border: "2px solid #1C1F22",
                    backgroundColor:
                      pickerView === PickerView.CLOCK_VIEW ? theme.palette.dalmatianDesign.dark : "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    ".MuiOutlinedInput-root": {
                      height: "100%",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "56px",
                      lineHeight: "64px",
                    },
                    ".MuiOutlinedInput-input": {
                      height: "100%",
                      paddingRight: 0,
                    },
                  }}
                  value={toTwoDigits(hourInput)}
                  onChange={(e) => {
                    pickerView === PickerView.INPUT_VIEW && handleHourChange(e as React.ChangeEvent<HTMLInputElement>);
                  }}
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    setView("hours");
                  }}
                  onBlur={handleFinalizeTime}
                />
                {pickerView === PickerView.INPUT_VIEW && (
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "140%",
                    }}
                  >
                    Hour
                  </Typography>
                )}
              </Box>
              <Typography
                variant="dalmatianBase"
                sx={{
                  fontWeight: 400,
                  fontSize: "56px",
                  lineHeight: "64px",
                }}
              >
                :
              </Typography>
              <Box
                style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: 0, gap: "7px" }}
              >
                <TextField
                  inputProps={{
                    style: {
                      color: theme.palette.dalmatianDesign.dark,
                      WebkitTextFillColor: theme.palette.dalmatianDesign.dark,
                      cursor: pickerView === PickerView.CLOCK_VIEW ? "pointer" : "text",
                      caretColor: pickerView === PickerView.CLOCK_VIEW ? "transparent" : "auto",
                    },
                  }}
                  sx={{
                    width: "96px",
                    height: "80px",
                    border: 0,
                    ".MuiOutlinedInput-notchedOutline": {
                      border: 0,
                    },
                    ".MuiOutlinedInput-root": {
                      height: "100%",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "56px",
                      lineHeight: "64px",
                      backgroundColor: "#EBEBEB",
                      borderRadius: 0,
                    },
                    "& .MuiInputBase-root.Mui-disabled": {
                      WebkitTextFillColor: "red!important",
                    },
                    ".MuiOutlinedInput-input": {
                      height: "100%",
                      paddingRight: 0,
                    },
                  }}
                  value={toTwoDigits(minInput)}
                  onChange={(e) => {
                    pickerView === PickerView.INPUT_VIEW && handleMinChange(e as React.ChangeEvent<HTMLInputElement>);
                  }}
                  onClick={() => setView("minutes")}
                  onBlur={handleFinalizeTime}
                />
                {pickerView === PickerView.INPUT_VIEW && (
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "140%",
                    }}
                  >
                    Minute
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                border: "1px solid rgba(212, 214, 217, 0.7)",
              }}
            >
              <SquareToggleButton selected={!pm} onClick={() => handleAMPMChange()}>
                AM
              </SquareToggleButton>
              <SquareToggleButton selected={pm} onClick={() => handleAMPMChange()}>
                PM
              </SquareToggleButton>
            </Box>
          </Box>
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "140%",
              padding: "8px 0 0 0",
              color: theme.palette.dalmatianDesign.error,
              visibility: showError ? "visible" : "hidden",
            }}
          >
            {hourInput === 0 || hourInput > 12 ? "Enter an hour between 1-12." : ""}
          </Typography>
        </Box>

        {pickerView === PickerView.CLOCK_VIEW && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              padding: "12px 36px",
              gap: "10px",
            }}
          >
            <ClockPicker
              date={currTime}
              onChange={handleClockChange}
              view={view}
              onViewChange={(view) => setView(view)}
            />
          </Box>
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "16px",
            paddingBottom: "24px",
            width: "98%",
          }}
        >
          <IconButton onClick={() => setPickerView((prev) => 1 - prev)} size="large">
            {pickerView === PickerView.INPUT_VIEW ? <AccessTimeOutlinedIcon /> : <KeyboardAltOutlinedIcon />}
          </IconButton>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 0,
              gap: "8px",
            }}
          >
            <BaseButton variant="text" onClick={onClose} sx={{ letterSpacing: "1.25px" }}>
              Cancel
            </BaseButton>
            <BaseButton variant="text" disabled={showError} onClick={handleTimeSubmit} sx={{ letterSpacing: "1.25px" }}>
              Ok
            </BaseButton>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default TimePicker;
