import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Typography, Box, useTheme, Button } from "@mui/material";
import React, { useMemo } from "react";
import { ITeam } from "redux/api/teamApi";

import { TeamState } from "./teamReducer";
import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";

interface ITeamInfoTabProps {
  team: TeamState;
  onChange: (team: ITeam) => void;
  onDelete: () => void;
  didModify?: boolean;
}

const TeamInfoTab: React.FC<ITeamInfoTabProps> = ({ team, onChange, onDelete, didModify }) => {
  const { featureAccess } = useUsers();

  const deleteAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.TEAM].delete;
  }, [featureAccess]);

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        pt: 3,
        px: matchesSM ? 6 : matchesMD ? 8 : 13,
        overflowY: "scroll",
        height: "100%",
        maxHeight: useWindowSize().height - (didModify ? 220 : 150),
      }}
    >
      <Box sx={{ pb: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            py: 0,
            gap: 1,
          }}
        >
          <Typography variant="subtitle1">Name</Typography>
          <FixedHeightTextField
            variant="outlined"
            required={true}
            placeholder={"Team Name"}
            value={team.name}
            sx={{ width: "100%" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ ...team, name: event.target.value });
            }}
          />
          <RequiredFieldMsg style={{ display: team.name.length > 0 ? "none" : "" }} />
        </Box>
      </Box>
      <Box sx={{ pb: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            py: 0,
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
            }}
          >
            <Typography variant="subtitle1">Description</Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#555758",
              }}
            >
              (Optional)
            </Typography>
          </Box>
          <FixedHeightTextField
            variant="outlined"
            required={true}
            placeholder={"E.g. New working group for the New York project"}
            value={team.description ?? ""}
            sx={{ width: "100%", height: "36px" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ ...team, description: event.target.value });
            }}
          />
        </Box>
      </Box>
      <Box sx={{ pb: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            py: 0,
            gap: 1,
          }}
        >
          {deleteAccess && team.id !== "" && (
            <Button
              variant="outlined"
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={onDelete}
              style={{ display: "flex" }}
            >
              Delete Team
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TeamInfoTab;
