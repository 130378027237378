export enum SnackbarOptions {
  OBJECT_NOACTION,
  OBJECT_SUBMITTED,
  OBJECT_ERROR,
}

export enum SnackbarNames {
  NONE = "None",
  ANNOTATION = "Annotation",
  ANNOTATION_EDIT = "Annotation changes",
  ANNOTATION_DELETION_ERROR = "Error deleting annotation",
  ANNOTATION_DELETION_SUCCESS = "Annotation successfully deleted",
  ANNOTATION_EDIT_ERROR = "Error editing annotation",
  ANNOTATION_UPLOAD_ERROR = "Error uploading annotation",
  USER_PROFILE = "User profile settings",
  ROLE = "Role Data",
  ROLE_DELETION_SUCCESS = "User Role successfully deleted",
  ROLE_DELETION_ERROR = "Error deleting user role",
  INSPECTION_DRAWING = "Inspection drawing",
  ARROW = "Arrow",
  ARROW_EDITING_ERROR = "Error editing arrow",
  ARROW_DELETION_SUCCESS = "Arrow successfully deleted",
  ARROW_DELETION_ERROR = "Error deleting arrow",
  INSPECTION_DELETION_ERROR = "Error deleting drawing",
  INSPECTION_DELETION_SUCCESS = "Drawing successfully deleted",
  DROP_ERROR_3D_EXISTS = "3D object file already exists",
  MAP = "Map",
  SITE = "Site",
  SUPPLIER = "Supplier",
  MANUFACTURER = "Manufacturer",
  OBJECT_INSTANCE_UPDATE = "Object instance update",
  OBJECT_INSTANCE_DELETE = "Object instance delete",
  OBJECT_INSTANCE_CREATE = "Object instance create",
  INVITE = "Sending invite",
  INVALID_INVITE_FORM = "Please fill out all fields.",
  CAPTURE_DELETION = "Capture delete",
  POSE_REQUEST_CREATE = "Pose request",
  ADD_AUTOMATED_IMAGE = "Automated image",
  CAPTURE_RETAKE = "Capture retake",
  CAPTURE_FLAG = "Flag Capture",
  ANNOTATION_GROUP_DELETION = "annotation group deletion",
  ANNOTATION_GROUP_EDIT = "annotation group edit",
  ANNOTATION_GROUP_CREATE = "annotation group creation",
  ASSET_INFO_DELETION = "Asset info deletion",
  ASSET_INFO_EDIT = "Asset info edit",
  ASSET_INFO_CREATE = "Asset info create",
  WORKORDER_DELETION = "Workorder deletion",
  WORKORDER_EDIT = "Workorder edit",
  WORKORDER_CREATE = "Workorder create",
  WORKORDER_REORDER = "Workorder reorder",
  SNAPSHOT_DELETION = "Snapshot deletion",
  SNAPSHOT_EDIT = "Snapshot edit",
  SNAPSHOT_CREATE = "Snapshot create",
  SNAPSHOT_SAVE_SUCCESS = "Snapshot successfully saved",
  SNAPSHOT_SAVE_ERROR = "Error saving snapshot",
  CAPTURE_SAVE_ERROR = "Error saving capture",
  ARCHIVE = "Archive",
  UNARCHIVE = "Unarchive",
  POSE_HEIGHT_LIMIT = "This pose may be out of reach of users. Please request another pose.",
  PROFILE_PICTURE_UPDATE = "Profile picture update",
  SNAPSHOT_LOADING = "Your snapshot is being updated...",
  SNAPSHOT = "Snapshot",
  CHECKLIST_REMOVE = "Checklist removed",
  CHECKLIST = "Checklist",
  DIFFERENCE_CALCULATION_SUCCESS = "Sucessfully submitted a request to calculate the historical changes, an annotation will be generated denoting the differences.",
  DIFFERENCE_CALCULATION_ERROR = "difference calculation request",
  CUSTOM_ANNOTATION_FILE_ERROR = "please ensure you do not upload more than one model file.",
  TENANT_COPY_SCHEDULED = "Tenant copying process scheduled.",
  TENANT_COPY_ERROR = "tenant copying process.",
  TENANT_PRIORITY_SUBMITTED = "Updating tenant priority...",
  TENANT_PRIORITY_UPDATED = "Tenant priority successfully updated",
  DOWNLOAD_USER_LOGS_SUCCESS = "User logs scheduled.",
  DOWNLOAD_USER_LOGS_ERROR = "Error mailing user logs.",
}
