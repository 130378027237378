import { List, Typography } from "@mui/material";
import React from "react";

import UserListItem from "./UserListItem";
import { User } from "services/ContentServer/Identity";
import { sortObjectsByString } from "utils/SortingUtils";

const UserList = ({
  users,
  filterUser,
}: {
  users: User[];
  filterUser: (user: User) => boolean;
  searchedUserID: string;
  handleSetSearchedUserID: (value: string) => void;
}) => {
  const filteredUsers = Object.values(users)
    .filter((user: User) => filterUser(user) && user.isActive)
    .sort((x, y) => sortObjectsByString(x.name, y.name));

  const items = filteredUsers.map((user: User) => <UserListItem key={user.id} userId={user.id} />);

  return (
    <List component="div" disablePadding style={{ maxHeight: "100%", overflow: "auto" }}>
      {items ? (
        items
      ) : (
        <Typography variant="h6" component="h6" gutterBottom style={{ textAlign: "center" }}>
          No contacts
        </Typography>
      )}
    </List>
  );
};

export default UserList;
