import { TypographyProps } from "@mui/material/Typography";
import React from "react";

import FieldMessage from "./FieldMessage";

export const RequiredFieldMsg = (props: TypographyProps) => {
  const { children, ...other } = props;

  return <FieldMessage {...other}>*Required field.</FieldMessage>;
};
