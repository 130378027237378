import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Avatar, Box, Typography, IconButton, Button, useTheme } from "@mui/material";
import UserPlaceholderIcon from "icons/UserPlaceholderIcon";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import AddUsersDialog from "./AddUsersDialog";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import useARMediaQuery from "hooks/useARMediaQuery";
import useUsers from "hooks/useUsers";
import useWindowSize from "hooks/useWindowSize";
import { User } from "services/ContentServer/Identity";
import { matchSorter } from "utils/SortRowsUtils";

interface IRoleUsersTabProps {
  itemLabel: string;
  itemsLabel: string;
  itemName: string;
  selectedUsers: User[];
  onChange: (users: User[]) => void;
  listModified?: boolean;
}
const UsersTab: React.FC<IRoleUsersTabProps> = ({
  itemLabel,
  itemsLabel,
  itemName,
  selectedUsers,
  onChange,
  listModified,
}) => {
  const [userList, setUserList] = useState<User[]>([]);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const { users } = useUsers();
  const [query, setQuery] = useState<string>("");
  const [removeAllConfirmOpen, setRemoveAllConfirmOpen] = useState(false);
  const [openAddTeam, setOpenAddTeam] = useState(false);

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const exitInSelectedUsers = (user: User) => !!selectedUsers.find((element) => element.id === user.id);
    setUserList(selectedUsers);
    setAvailableUsers(users.filter((user) => !exitInSelectedUsers(user)));
  }, [users, selectedUsers]);

  const applyFilters = useCallback(
    (rows: User[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["name"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const userRows = useMemo(() => {
    return applyFilters(Object.values(userList));
  }, [applyFilters, userList]);

  const removeAll = () => {
    onChange([]);
  };

  const removeButton = useCallback(
    (user: User) => {
      const removeUser = () => {
        onChange(userList.filter((item) => item.id !== user.id));
      };

      return (
        <span>
          <IconButton
            color="primary"
            size="large"
            onClick={removeUser}
            sx={{
              padding: "3px",
              svg: { width: "24px", height: "24px" },
            }}
          >
            <HighlightOffOutlinedIcon />
          </IconButton>
        </span>
      );
    },
    [onChange, userList]
  );

  const handleAdd = useCallback(
    (selUsers: "all" | User[]) => {
      if (selUsers === "all") {
        onChange(users);
      } else {
        onChange([...selectedUsers, ...selUsers]);
      }
    },
    [onChange, selectedUsers, users]
  );
  return (
    <>
      <ConfirmationDialog
        useOpen={{ state: removeAllConfirmOpen, setState: setRemoveAllConfirmOpen }}
        onConfirm={() => {
          removeAll();
        }}
        dialogText={`Are you sure you would like to remove all users from the ${itemName} ${itemLabel}?`}
        title={"Remove All Users"}
        confirmText={"Remove All Users"}
      />

      <AddUsersDialog
        key={openAddTeam + ""} // force component to re-render
        itemLabel={itemLabel}
        itemsLabel={itemsLabel}
        itemName={itemName}
        availableUsers={availableUsers}
        open={openAddTeam}
        onClose={() => setOpenAddTeam(false)}
        onAdd={handleAdd}
      />
      <Box sx={{ height: "auto", width: "100%", py: 3, px: matchesSM ? 6 : matchesMD ? 8 : 13 }}>
        <EllipsisTooltip variant="body1">{`Select which users have access to ${itemName} ${itemLabel}.`}</EllipsisTooltip>
      </Box>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          pb: 3,
          px: matchesSM ? 6 : matchesMD ? 8 : 13,
          display: "flex",
          alignItems: "center",
          gap: 3,
        }}
      >
        <SearchBar placeholderText={`Search ${itemsLabel}...`} query={query} setQuery={setQuery} />
        <Button
          variant={"outlined"}
          startIcon={<AddIcon />}
          onClick={() => {
            setOpenAddTeam(true);
          }}
          sx={{ minWidth: "135px", maxHeight: "36px" }}
        >
          Add Users
        </Button>
      </Box>
      <Box
        sx={{
          height: "auto",
          overflowY: "scroll",
          maxHeight: useWindowSize().height - (listModified ? 350 : 280),
          alignItems: "flexStart",
          px: matchesSM ? 6 : matchesMD ? 8 : 13,
          pb: 3,
        }}
      >
        {userList.length ? (
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 1,
              "&:hover": {
                backgroundColor: "#F6F6F6",
              },
            }}
          >
            <Box
              sx={{
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "3px",
                gap: 1,
              }}
            >
              <Avatar sx={{ bgcolor: theme.palette.primary.main, width: "30px", height: "30px" }}>
                <DeleteOutlineSharpIcon
                  sx={{ width: "20px", height: "20px", color: theme.palette.primary.contrastText }}
                />
              </Avatar>
              <Typography variant="body1">Remove All</Typography>
            </Box>
            <IconButton
              color="primary"
              sx={{ padding: "3px", svg: { width: "24px", height: "24px" } }}
              onClick={() => setRemoveAllConfirmOpen(true)}
              size="large"
            >
              <DeleteOutlineSharpIcon />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              px: matchesSM ? 6 : matchesMD ? 8 : 13,
            }}
          >
            <Typography variant="body1">No users is assigned to this {itemLabel}</Typography>
          </Box>
        )}
        {userRows.map((user) => {
          return (
            <Box
              key={user.id}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 1,
                borderRadius: "4px",
                height: "auto",
                "&:hover": {
                  backgroundColor: "#F6F6F6",
                },
              }}
            >
              <Box
                sx={{
                  height: "auto",
                  display: "flex",
                  alignItems: "center",
                  padding: "3px",
                  gap: 1,
                  maxWidth: "90%",
                }}
              >
                {user.userProfile.profilePicture !== "" ? (
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.secondary.dark,
                      color: "#404245",
                      width: "30px",
                      height: "30px",
                    }}
                    src={user.userProfile.profilePicture}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.secondary.dark,
                      color: "#404245",
                      width: "30px",
                      height: "30px",
                    }}
                  >
                    <UserPlaceholderIcon />
                  </Avatar>
                )}
                <EllipsisTooltip variant="body1">{user.name}</EllipsisTooltip>
              </Box>
              {removeButton(user)}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default UsersTab;
