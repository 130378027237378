import { deserializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

interface VisionPrediction {
  confidence: number;
}

export interface ObjectVision extends VisionPrediction {
  name: string;
  rectangle: { x: number; y: number; w: number; h: number };
}

export interface TagVision extends VisionPrediction {
  name: string;
}

export interface CaptionVision extends VisionPrediction {
  text: string;
}
export interface DescriptionVision {
  tags: string[];
  captions: CaptionVision[];
}

export interface MetadataVision {
  width: number;
  height: number;
  format: string | undefined;
}

export const jsonToView: { [key: string]: keyof VisualFeatures | undefined } = {
  objects: "objects",
  tags: "tags",
  description: "description",
  metadata: "metadata",
};

export class VisualFeatures extends ISerialization {
  public objects?: ObjectVision[] | null;
  public tags?: TagVision[] | null;
  public description?: DescriptionVision | null;
  public metadata?: MetadataVision | null;

  constructor(context?: VisualFeatures) {
    super();
    if (context) {
      Object.assign(this, context);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<VisualFeatures>(this, jsonToView, json);
  }
  serialize() {} // read-only for now
}
