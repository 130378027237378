import TooltipMui, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import React from "react";

const Tooltip = styled(
  React.forwardRef(function Tooltip(props: TooltipProps, ref?: React.ForwardedRef<HTMLDivElement>) {
    const { className, ...rest } = props;
    return <TooltipMui {...rest} arrow classes={{ popper: className }} ref={ref} />;
  })
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    borderColor: theme.palette.common.black,
  },
  "&[data-popper-reference-hidden]": {
    visibility: "hidden",
    pointerEvents: "none",
  },
}));

export default Tooltip;
