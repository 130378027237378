import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Divider, Grid } from "@mui/material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useContext, useState } from "react";

import LoadingDialog from "./LoadingDialog";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import TextFieldForm from "components/forms/TextFieldForm";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { validateEmail } from "utils/StringUtils";

export default function ExportUsersDialog({
  open,
  setOpen,
  disabled,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  disabled?: boolean;
}) {
  const theme = useTheme();
  const { contentServer } = useContext(RequestContext);
  const snackbar = useSnackbar();

  const [email, setEmail] = useState<string>("");
  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(false);
  const [showRequired, setShowRequired] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
    setProcessing(false);
    setEmail("");
    setShowRequired(false);
    setInvalidEmail(false);
    setComplete(false);
  }, [setOpen]);

  const handleExportUsers = useCallback(async () => {
    try {
      await contentServer.identityService.exportUsersCsv(email);
      setProcessing(false);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "CSV file sent to " + email,
      });
    } catch (e) {
      setProcessing(false);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "There was an error sending the CSV file to " + email,
      });
    }
    handleClose();
  }, [contentServer.identityService, snackbar, handleClose, email]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: { borderRadius: 0, maxWidth: 700 },
        }}
      >
        <DialogTitle>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "15px",
                  color: theme.palette.common.black,
                }}
              >
                {"Export Users and Roles to CSV"}
              </Typography>
            </Grid>
            <Grid item xs={1} container justifyContent="flex-end">
              <IconButton
                id="close-user-log-popup"
                onClick={handleClose}
                style={{ color: theme.palette.common.black }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <LoadingDialog processing={processing} msg={"Scheduling email..."}></LoadingDialog>
        <DialogContent>
          {!complete ? (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} id="email-form">
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    color: theme.palette.common.black,
                    paddingBottom: "10px",
                  }}
                >
                  Send to the following email:
                </Typography>
                <TextFieldForm
                  id="user-email"
                  value={email || ""}
                  label={""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                  type="text"
                  disabled={false}
                  required={true}
                  style={{ width: "100%" }}
                  sx={{
                    height: "36px",
                    ".MuiOutlinedInput-root": {
                      height: "100%!important",
                    },
                    ".MuiOutlinedInput-input": {
                      height: "100%!important",
                      padding: "0px 14px",
                    },
                  }}
                />
                {(showRequired || invalidEmail) && (
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: "inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                    }}
                  >
                    {invalidEmail ? "*Invalid email" : "*Required field"}.
                  </Typography>
                )}
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{
                    gap: "15px",
                    width: "45%",
                  }}
                >
                  <BaseButton variant="outlined" onClick={handleClose} style={{ width: "fit-content" }}>
                    Cancel
                  </BaseButton>
                  <BaseButton
                    variant="contained"
                    onClick={async () => {
                      if (email && validateEmail(email)) {
                        setInvalidEmail(false);
                        setShowRequired(false);
                        setProcessing(true);
                        await handleExportUsers();
                      } else if (!email) {
                        setShowRequired(true);
                        setInvalidEmail(false);
                      } else {
                        setInvalidEmail(true);
                        setShowRequired(false);
                      }
                    }}
                    style={{ width: "fit-content" }}
                  >
                    Retrieve CSV
                  </BaseButton>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} id="email-form">
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    color: theme.palette.common.black,
                    paddingBottom: "10px",
                  }}
                >
                  {"The users and roles CSV is being sent to " + email}
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{
                    width: "45%",
                  }}
                >
                  <BaseButton variant="outlined" onClick={handleClose} style={{ width: "fit-content" }}>
                    Close
                  </BaseButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
