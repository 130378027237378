import { useCallback, useMemo } from "react";
import { useGetRolesQuery, useGetUserQuery, useGetUsersQuery } from "redux/api/userApi";

import { PERMISSION_NAME, PERMISSION_TYPE } from "./usePermission";
import { useAuth } from "hooks/useAuth";
import { User, ProfileDict } from "services/ContentServer/Identity";

export const useUserName = () => {
  const { data: users } = useGetUsersQuery();

  const getUserName = useCallback(
    (userId: string | null | undefined) => {
      if (userId && users) {
        const user = Object.values(users).find((user: User) => user.userProfile.id === userId);
        return user ? user.name : "Unknown";
      } else {
        return "Unknown";
      }
    },
    [users]
  );

  return { getUserName };
};

const useUsers = () => {
  const { user, userPermissions, featureAccess } = useAuth();
  const {
    data: currentUser,
    error: userLoadingError,
    refetch: refetchCurrentUser,
  } = useGetUserQuery(user?.id ?? "", { skip: !user });
  const { data: users, error, isLoading: usersLoading, refetch: refetchUsers } = useGetUsersQuery();
  const {
    data: roles,
    error: rolesError,
    refetch: refetchRoles,
  } = useGetRolesQuery(undefined, {
    skip: !featureAccess[PERMISSION_NAME.ROLES].read,
  });

  const profiles = useMemo(() => {
    const profileDict: ProfileDict = {};

    if (users) {
      Object.values(users).forEach((user) => (profileDict[user.userProfile.id] = user.userProfile));
    }

    return profileDict;
  }, [users]);

  const getPermissionMessage = useCallback(
    (type: PERMISSION_TYPE): string => {
      const permissionType = () => {
        switch (type) {
          case PERMISSION_TYPE.READ:
            return "view";
          case PERMISSION_TYPE.ADD:
            return "add";
          case PERMISSION_TYPE.DELETE:
            return "delete";
          case PERMISSION_TYPE.UPDATE:
            return "edit";
          default:
            return "";
        }
      };
      if (userPermissions === undefined) {
        return "Loading...";
      } else {
        return "You do not have permission to " + permissionType() + ", please contact an administrator for access.";
      }
    },
    [userPermissions]
  );

  const usersContextValues = useMemo(() => {
    return {
      currentUser: currentUser,
      refetchCurrentUser: refetchCurrentUser,
      userLoadingError: userLoadingError,
      featureAccess: featureAccess,
      users: users || [],
      error: error,
      loaded: users !== undefined,
      profiles: profiles,
      roles: roles || {},
      rolesLoaded: roles !== undefined,
      rolesError: rolesError,
      usersLoading: usersLoading,
      refetchUsers: refetchUsers,
      refetchRoles: refetchRoles,
      getPermissionMessage: getPermissionMessage,
    };
  }, [
    usersLoading,
    userLoadingError,
    currentUser,
    refetchCurrentUser,
    users,
    featureAccess,
    error,
    profiles,
    roles,
    rolesError,
    refetchUsers,
    refetchRoles,
    getPermissionMessage,
  ]);

  return usersContextValues;
};

export default useUsers;
