import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import { Grid, Typography } from "@mui/material";
import React from "react";

const NotSupportedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "75vh",
        width: "100%",
      }}
    >
      <main>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item container xs={12} justifyContent="center" alignItems="center">
            <ImportantDevicesIcon
              color="secondary"
              sx={{
                width: 250,
                height: 250,
              }}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="center" alignItems="center">
            <Typography style={{ textAlign: "center" }}>
              We are sorry, but <b>mobile is currently not supported.</b> To enjoy AptixAR, please use a{" "}
              <b>desktop browser.</b>
            </Typography>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default NotSupportedPage;
