import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React from "react";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    gap: "24px",
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    color: "#1C1F22",
    boxShadow: "0px 4px 12px rgba(53, 55, 57, 0.1)",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
  },
}));

export default LightTooltip;
