import { Add, DeleteOutlineSharp } from "@mui/icons-material";
import { Box, Table, TableBody, TableContainer, Button } from "@mui/material";
import { Skeleton, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CopyPageIcon from "icons/CopyPageIcon";
import RoleUsersIcon from "icons/RoleUsersIcon";
import SharpEditIcon from "icons/SharpEditIcon";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { useGetUsersQuery, useGetRolesQuery } from "redux/api/userApi";
import { v4 as uuidv4 } from "uuid";

import { SettingRoutesDict, SettingTab } from "../SettingsBasePage";
import ARRowCell from "components/ARTable/ARRowCell";
import ContextButton, { MenuOption } from "components/DalmatianDesignComponents/ContextButton";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import SearchBar from "components/DalmatianDesignComponents/SearchBar";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import LoadingDialog from "components/Dialog/LoadingDialog";
import { EnhancedTableHead } from "components/table/EnhancedTable";
import useARMediaQuery from "hooks/useARMediaQuery";
import { PERMISSION_NAME } from "hooks/usePermission";
import { useRefResizeObserver } from "hooks/useResizeObserver";
import { useRouter } from "hooks/useRouter";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import useUsers from "hooks/useUsers";
import { ADMIN_ROLE, MEMBER_ROLE, Role, User } from "services/ContentServer/Identity";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { StableSort, GetComparatorFcn, SortDirection, matchSorter } from "utils/SortRowsUtils";
import { toTitleCase } from "utils/StringUtils";

export const getRoleUserCount = (roleId: string, users: User[]) => {
  return Object.values(users).filter((user) => {
    const userRoles = Object.values(user.roles).map((role) => role);
    return userRoles.includes(roleId);
  }).length;
};

interface RoleRowData {
  id: string;
  Role: string;
  Description: string;
  Users: number;
  ContextMenu: JSX.Element;
}

const SKELETON_ROWS = 8;
const headCells: { id: keyof RoleRowData; label: string }[] = [
  { id: "Role", label: "Role" },
  { id: "Description", label: "Description" },
  { id: "Users", label: "Users" },
  { id: "ContextMenu", label: "" },
];

const cellWidths = new Map<string, string>([
  ["Role", "23.24%"],
  ["Description", "61.81%"],
  ["Users", "10.31%"],
  ["ContextMenu", "5.31"],
]);

const RolesPage = ({ setSelected }: { setSelected: (value: SettingTab) => void }) => {
  const { contentServer } = useContext(RequestContext);
  const snackbar = useSnackbar();
  const { users, error, rolesLoaded, roles } = useUsers();
  const [roleToDelete, setRoleToDelete] = useState<string>("");
  const [order, setOrder] = useState<SortDirection | undefined>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState("None");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState(false);
  const [processingMsg, setProcessingMsg] = useState("");
  const [query, setQuery] = useState<string>("");
  const { featureAccess } = useUsers();
  const { history } = useRouter();
  const [tableSize, setTableSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { ref: tableRef } = useRefResizeObserver(setTableSize);
  const { refetch: refetchUsers } = useGetUsersQuery();
  const { refetch: refetchRoles } = useGetRolesQuery();

  const theme = useTheme();
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useARMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    requestAnimationFrame(() => setSelected(SettingTab.ROLES));
  }, [setSelected]);

  const roleAccess = useMemo(() => {
    return featureAccess[PERMISSION_NAME.ROLES];
  }, [featureAccess]);

  const navigateToEditPage = useCallback(
    (id: string) => {
      history.push(`${SettingRoutesDict.get(SettingTab.ROLE_EDIT)?.path}?roleId=${id}`);
    },
    [history]
  );

  const handleRequestSort = useCallback(
    (event: any, property: string) => {
      const isAsc = orderBy === property && order === SortDirection.ASC;
      setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const renderSkeletonRow = useCallback(() => {
    const cells = headCells.map((headCell, idx) => (
      <ARRowCell
        id={`cell-col-${idx}`}
        key={idx}
        index={idx}
        alwaysShow={headCell.id === "ContextMenu" || headCell.id === "Users"}
        align="left"
        sx={{
          width: cellWidths.get(headCell.id),
          border: 0,
        }}
      >
        <Skeleton key={idx} sx={{ borderRadius: "2px", width: "100%", height: "30px" }} />
      </ARRowCell>
    ));
    return cells;
  }, []);

  const onDeleteRoleClick = useCallback((roleId: string) => {
    setDeleteConfirmOpen(true);
    setRoleToDelete(roleId);
  }, []);

  const deleteRole = useCallback(async () => {
    try {
      setProcessingMsg("Deleting role...");
      setProcessing(true);
      await contentServer.identityService.deleteRole(roleToDelete);
      refetchRoles();
      refetchUsers();

      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Role successfully deleted.",
      });
    } catch (error) {
      console.error(error);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: "Error deleting role.",
      });
    }
    setProcessing(false);
    setProcessingMsg("");
  }, [contentServer.identityService, roleToDelete, snackbar, refetchRoles, refetchUsers]);

  const duplicateRole = useCallback(
    async (roleId: string) => {
      try {
        setProcessingMsg("Duplicating role...");
        setProcessing(true);

        const dupRole = { ...roles[roleId] };
        dupRole.id = uuidv4();
        dupRole.name = dupRole.name + " - Copy";
        await contentServer.identityService.createRole(dupRole);

        refetchRoles();
        refetchUsers();

        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Role successfully duplicated.",
        });
      } catch (error) {
        console.error(error);
        snackbar.dispatch({
          type: SnackbarActionType.OPEN,
          message: "Error duplicating role.",
        });
      }
      setProcessing(false);
      setProcessingMsg("");
    },
    [contentServer.identityService, roles, snackbar, refetchRoles, refetchUsers]
  );

  const contextMenuOptions = useCallback(
    (roleId: string, name: string, desc: string, usersCount: number) => {
      const options: MenuOption[] = [];
      if (roleAccess.update) {
        if (name !== ADMIN_ROLE) {
          if (name !== MEMBER_ROLE) {
            options.push({
              name: "Edit Role",
              onClick: () => {
                navigateToEditPage(roleId);
              },
              icon: <SharpEditIcon />,
            });
          }

          options.push({
            name: "Duplicate Role",
            onClick: async (e) => {
              if (e) e.stopPropagation();
              duplicateRole(roleId);
            },
            icon: <CopyPageIcon />,
          });
        }
      }

      if (roleAccess.delete && name !== ADMIN_ROLE && name !== MEMBER_ROLE) {
        options.push({
          name: "Delete Role",
          onClick: (e) => {
            if (e) e.stopPropagation();
            onDeleteRoleClick(roleId);
          },
          icon: <DeleteOutlineSharp />,
        });
      }
      return options;
    },
    [duplicateRole, navigateToEditPage, roleAccess, onDeleteRoleClick]
  );

  const roleRows = useMemo(() => {
    return Object.values(roles).map((role: Role) => {
      const options = contextMenuOptions(role.id, role.name, role.description, getRoleUserCount(role.id, users));
      return {
        id: role.id,
        Role: role.name,
        Description: role.description,
        Users: getRoleUserCount(role.id, users),
        ContextMenu: options && options.length > 0 && <ContextButton items={options} />,
      } as RoleRowData;
    });
  }, [roles, users, contextMenuOptions]);

  const applyFilters = useCallback(
    (rows: RoleRowData[]) => {
      if (query.length > 0) {
        return matchSorter(rows, query, { keys: ["Role"] });
      } else {
        return rows;
      }
    },
    [query]
  );

  const tableData = useMemo(() => {
    return applyFilters(roleRows);
  }, [applyFilters, roleRows]);

  const tableRows = useMemo(() => {
    return StableSort(tableData, GetComparatorFcn(order, orderBy, false));
  }, [order, orderBy, tableData]);

  const tableRow = useCallback(
    (rowIdx, row) => {
      return (
        <>
          {headCells.map((headCell, idx) => {
            const widthPercent = cellWidths.get(headCell.id);
            return (
              <ARRowCell
                id={`cell-col-${idx}`}
                index={headCell.id === "Description" ? 2 : idx}
                key={idx}
                alwaysShow={headCell.id === "ContextMenu" || headCell.id === "Users"}
                align="left"
                padding="normal"
                sx={{
                  width: widthPercent,
                  maxWidth: (tableSize.width - (matchesSM ? 6 : matchesMD ? 8 : 13) * 16) * 0.3,
                  border: 0,
                  paddingLeft: idx === 0 ? "16px" : "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  if (headCell.id !== "ContextMenu" && roleAccess.update) {
                    navigateToEditPage(row.id);
                  }
                }}
              >
                {headCell.id === "Users" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <RoleUsersIcon />
                      <Typography variant="body1">{row[headCell.id]}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ maxHeight: "24px", display: "flex", alignItems: "center" }}>
                    <EllipsisTooltip variant="body1">
                      {(headCell.id === "Role" && row[headCell.id] === ADMIN_ROLE) || row[headCell.id] === MEMBER_ROLE
                        ? toTitleCase(row[headCell.id] as string)
                        : row[headCell.id]}
                    </EllipsisTooltip>
                  </Box>
                )}
              </ARRowCell>
            );
          })}
        </>
      );
    },
    [navigateToEditPage, roleAccess.update, tableSize.width, matchesSM, matchesMD]
  );

  return (
    <>
      <LoadingDialog processing={processing} msg={processingMsg} />
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "flex-start" }}>
        <Box sx={{ py: 3, height: "auto", width: "100%", px: matchesSM ? 6 : matchesMD ? 8 : 13 }}>
          <Typography variant="h1">Roles</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            px: matchesSM ? 6 : matchesMD ? 8 : 13,
            py: 3,
            gap: "24px",
            width: "100%",
            height: "auto",
          }}
        >
          <SearchBar placeholderText={"Search roles..."} query={query} setQuery={setQuery} />
          {roleAccess.add && (
            <Button
              variant={"outlined"}
              startIcon={<Add />}
              onClick={() => {
                navigateToEditPage("");
              }}
              style={{ maxHeight: "36px", minWidth: "130px" }}
            >
              New Role
            </Button>
          )}
        </Box>
        <Box sx={{ height: "100%", width: "100%" }}>
          <TableContainer
            ref={tableRef}
            sx={{
              height: "100%",
              width: "100%",
              "&::-webkit-scrollbar": {
                backgroundColor: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#a2a4a6",
                width: "8px",
                borderRadius: "100px",
              },
              table: {
                px: matchesSM ? 6 : matchesMD ? 8 : 13,
              },
            }}
          >
            {tableData.length > 0 ? (
              <div style={{ width: "100%", height: "100%" }}>
                <TableVirtuoso
                  data={tableRows}
                  style={{ height: "100%", width: "100%", tableLayout: "fixed" }}
                  itemContent={(index, rowData) => tableRow(index, rowData)}
                  fixedHeaderContent={() => (
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      headCells={headCells}
                      cellWidths={cellWidths}
                      width={tableSize.width}
                    />
                  )}
                />
              </div>
            ) : (
              <Table
                size="small"
                sx={{
                  maxWidth: "100%",
                  width: "100%",
                  height: tableSize.height,
                  px: matchesSM ? 6 : matchesMD ? 8 : 13,
                }}
                stickyHeader
              >
                <TableBody style={{ width: "100%", paddingTop: "40px" }}>
                  {!rolesLoaded &&
                    Array(SKELETON_ROWS)
                      .fill(1)
                      .map((ele, idx) => {
                        return <TableRow key={idx}>{renderSkeletonRow()}</TableRow>;
                      })}
                </TableBody>
              </Table>
            )}

            {(error || tableData.length == 0) && rolesLoaded && (
              <Box
                sx={{
                  height: "90%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 2,
                  px: matchesSM ? 6 : matchesMD ? 8 : 13,
                  gap: "40px",
                }}
              >
                {error ? (
                  <Typography variant="body1" sx={{ paddingTop: 2 }}>
                    Error fetching roles.
                  </Typography>
                ) : (
                  <Typography variant="body1" sx={{ paddingTop: 2 }}>
                    {query ? "No roles matched this search." : "No roles loaded."}
                  </Typography>
                )}
              </Box>
            )}
          </TableContainer>
        </Box>
      </Box>
      <ConfirmationDialog
        useOpen={{ state: deleteConfirmOpen, setState: setDeleteConfirmOpen }}
        onConfirm={() => {
          deleteRole();
        }}
        dialogText={`Are you sure you want to delete this role? All users with this role will no longer have these permissions.`}
        title={"Delete Role"}
        confirmText={"Delete"}
      />
    </>
  );
};

export default RolesPage;
