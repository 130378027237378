import { useCallback } from "react";

import { I2DDrawer, StrokeOptions } from "./types";
import { Point2D } from "services/ContentServer/Audit/types";

export const useDrawing2D = (
  size: { width: number; height: number },
  context: CanvasRenderingContext2D | null
): I2DDrawer => {
  const drawLine = useCallback(
    (point1: Point2D, point2: Point2D, strokeOptions: StrokeOptions) => {
      if (context) {
        context.beginPath();
        context.moveTo(point1[0], point1[1]);
        context.lineTo(point2[0], point2[1]);
        context.strokeStyle = strokeOptions.strokeColor;
        context.lineWidth = strokeOptions.lineWidth;
        context.stroke();
      }
    },
    [context]
  );

  const drawMultiLine = useCallback(
    (points: Point2D[], strokeOptions: StrokeOptions) => {
      if (context && points.length) {
        context.beginPath();
        context.moveTo(points[0][0], points[0][1]);
        points.forEach((point) => context.lineTo(point[0], point[1]));
        context.strokeStyle = strokeOptions.strokeColor;
        context.lineWidth = strokeOptions.lineWidth;
        context.stroke();
      }
    },
    [context]
  );

  const erase = useCallback(() => {
    context?.clearRect(0, 0, size.width, size.height);
  }, [context, size]);

  return {
    drawLine,
    drawMultiLine,
    erase,
  };
};
