import axios, { AxiosInstance } from "axios";
import Config from "Config";

import { ServerType } from "services/AzureAD";

const VERSION_ENDPOINT = "/version/";

export class ServerVersion {
  readonly axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create();
  }

  async getServerVersion(serverType: ServerType) {
    const resp = await this.axiosInstance.get(Config.STATUS_URLS[serverType] + VERSION_ENDPOINT);

    return { data: resp.data };
  }
}
