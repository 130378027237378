import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Manufacturer } from "../serviceTypes/Manufacturer";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const ManufacturerSWRKeys = {
  MANUFACTURERS: "manufacturers",
};
export class ManufacturerService extends ServiceBase<Manufacturer> {
  constructor(apiInstance: APIInstance) {
    super(Manufacturer, apiInstance, ENDPOINTS.MANUFACTURERS);
  }
}
