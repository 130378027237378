import { FormControl, Grid, GridSize, TextField, TextFieldProps } from "@mui/material";
import React from "react";

export type TextFieldFormProps = Partial<TextFieldProps> & {
  value?: number | string | null;
  gridSize?: GridSize;
  noMargin?: boolean;
};

const TextFieldForm = (props: TextFieldFormProps) => {
  const { required, gridSize, noMargin, value, ...textFieldProps } = props;

  return (
    <Grid container item xs={gridSize}>
      <FormControl
        variant="outlined"
        required={required}
        sx={{
          width: "100%",
          paddingTop: "5px",
          paddingBottom: "10px",
        }}
      >
        <TextField
          required={required}
          value={value}
          variant="outlined"
          style={{ margin: noMargin ? 0 : 10, color: "black" }}
          {...textFieldProps}
        />
      </FormControl>
    </Grid>
  );
};

export default TextFieldForm;
