import { styled, Box } from "@mui/material";
import MenuMui from "@mui/material/Menu";
import React, { useRef, useState } from "react";

import ARNavItem from "./ARNavItem";
import { IARNavType, NavItemType } from "./ARNavType";

const Menu = styled(MenuMui)(({ theme }) => ({
  "&.MuiPaper-root": {
    boxShadow: "0px 4px 12px 0px #3537391A",
    width: "189px",
  },
}));

const ARNavMenu = ({
  item,
  subMenuItems,
  handleDrawerToggle,
}: {
  item: IARNavType;
  subMenuItems?: boolean;
  handleDrawerToggle?: () => void;
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const anchorEl = useRef(null);

  const menuBtn = {
    id: "more-menu-toggle",
    name: item.name || "",
    type: NavItemType.MENU,
    icon: item.icon,
    onClick: () => {
      setOpenMenu((prev) => !prev);
    },
    children: item.children || [],
  };

  const menuItems = item.children.map(
    (item: IARNavType) => {
      return (
        <ARNavItem
          key={item.id}
          item={item}
          subMenu={subMenuItems}
          handleDrawerToggle={() => {
            setOpenMenu(false);
            handleDrawerToggle && handleDrawerToggle();
          }}
        />
      );
    },
    [item]
  );
  return (
    <>
      <Box ref={anchorEl} sx={{ width: "100%" }}>
        <ARNavItem item={menuBtn} />
      </Box>
      <Menu
        anchorEl={anchorEl?.current}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        elevation={1}
      >
        {menuItems}
      </Menu>
    </>
  );
};
export default ARNavMenu;
