const SessionStorageKey = "requested-page-route";

export const storeCurrentPath = () => {
  const fullpath = window.location.pathname + window.location.search + window.location.hash;
  window.sessionStorage.setItem(SessionStorageKey, fullpath);
};

export const getStoredPath = () => {
  return window.sessionStorage.getItem(SessionStorageKey);
};

export const clearStoredPath = () => {
  window.sessionStorage.removeItem(SessionStorageKey);
};
