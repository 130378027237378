import {
  Phone,
  CallEnd,
  Clear,
  Undo,
  Mouse,
  Minimize,
  CheckBoxOutlineBlank,
  FilterNone,
  Call,
  DeleteForever,
  ArrowDropDown,
  ArrowDropUp,
  Videocam,
  Mic,
  VideocamOff,
  MicOff,
  KeyboardArrowDown,
  Brush,
} from "@mui/icons-material";
import {
  CardMedia,
  ButtonGroup,
  Tooltip,
  Button,
  Typography,
  Grid,
  Card,
  Paper,
  Box,
  Dialog,
  IconButton,
  MenuItem,
  Menu,
  Alert,
} from "@mui/material";
import "./VideoCall.css";
import { useTheme } from "@mui/material/styles";
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import useSound from "use-sound";

import { ScreenState, VideoOrientation } from "./types";
import ARAvatar from "components/ARAvatar";
import { DRAWING_COLORS } from "components/CameraSelector/Constants";
import CustomCircularProgress from "components/CustomProgress/CustomCircularProgress";
import { EllipsisTooltip } from "components/DalmatianDesignComponents/EllipsisToolTip";
import DrawingMenu from "components/drawer/DrawingMenu";
import { DrawingOptions } from "components/drawer/types";
import { useNotifyRef } from "hooks/useNotifyRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import useUser from "hooks/useUser";
import useUsers from "hooks/useUsers";
import useWebRTCSession, { ConnectionStatus } from "hooks/useWebRTCSession";
import useWindowSize from "hooks/useWindowSize";
import { Device } from "services/ContentServer/Identity";
import { DeviceType } from "services/WebRTC/constants";
import { clamp } from "utils/MathUtils";
import VideoCallCanvas from "views/VideoCallView/VideoCallCanvas";

const receivingSound = "/sounds/video_call_receiving_sound.mp3";
const callingSound = "/sounds/video_call_calling_sound.mp3";
const RECONNECTION_TIMEOUT = 30000;
const RINGING_VOLUME = 0.25;
const DRAWING_BUTTON_SIZE = "40px";
const DRAWING_MENU_BUTTON_SIZE = "80px";
const DRAWING_BUTTON_WIDTH = "40px";
const USER_MENU_ICON_RIGHT = 64;
const RECEIVING_MAKING_CALL_TIMEOUT = 20000;

const VideoCall = () => {
  const theme = useTheme();
  const { currentUser } = useUsers();
  const {
    callState,
    mediaState,
    localMediaStream,
    remoteMediaStream,
    answer,
    decline,
    hangup,
    sendData,
    selectVideoDevice,
    selectMicDevice,
    toggleVideo,
    toggleMic,
    mediaErrorDescription,
    setMediaErrorDescription,
    loadMediaDevices,
    mediaDeviceList,
    camState,
    micState,
  } = useWebRTCSession();
  const [open, setOpen] = useState(false);
  const [playReceiving, { stop: stopPlayReceiving }] = useSound(receivingSound, {
    volume: RINGING_VOLUME,
    interrupt: true,
    ...{ loop: true },
  });
  const [playCalling, { stop: stopPlayCalling }] = useSound(callingSound, {
    volume: RINGING_VOLUME,
    interrupt: true,
    ...{ loop: true },
  });
  const [localMediaElement, localVideoRef] = useNotifyRef<HTMLVideoElement>();
  const [remoteVideoElement, remoteVideoRef] = useNotifyRef<HTMLVideoElement>();
  const [remoteAudioElement, remoteAudioRef] = useNotifyRef<HTMLAudioElement>();

  const [videoDeviceListAnchor, setVideoDeviceListAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [videoDeviceListError, setVideoDeviceListError] = React.useState<string | null>(null);
  const videoDeviceListOpen = Boolean(videoDeviceListAnchor);
  const [audioDeviceListAnchor, setAudioDeviceListAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [audioDeviceListError, setAudioDeviceListError] = React.useState<string | null>(null);
  const audioDeviceListOpen = Boolean(audioDeviceListAnchor);

  const [drawingOptions, setDrawingOptions] = useState<DrawingOptions>({ selectedColor: 0, drawingSize: 0.02 });

  const [drawingMenuOpen, setDrawingMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [callWindowSize, setCallWindowSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const callWindowRef = useRef<HTMLElement>(null);
  const [titleBarSize, setTitleBarSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const titleBarRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  useResizeObserver(callWindowRef.current, setCallWindowSize);
  useResizeObserver(titleBarRef.current, setTitleBarSize);
  const { user: peer, error: errorPeer } = useUser(callState.peerId || "");
  const [screenType, setScreenType] = useState<ScreenState>(ScreenState.NORMAL);

  const [drawingCount, setDrawingCount] = useState(0);
  const [clearDisabled, setClearDisabled] = useState(true);
  const [undoDisabled, setUndoDisabled] = useState(true);
  const [cursorDisabled, setCursorDisabled] = useState(false);
  const [clearDrawingHandler, setClearDrawingHandler] = useState(() => () => {});
  const [undoDrawingHandler, setUndoDrawingHandler] = useState(() => () => {});
  const [reconnectionFailed, setReconnectionFailed] = useState<boolean>(false);
  const [permissionConfigured, setPermissionConfigured] = useState(false);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [remoteVideoSize, setRemoteVideoSize] = useState({ width: 1, height: 1 });
  useResizeObserver(remoteVideoElement, setRemoteVideoSize);

  const videoOrientation: VideoOrientation = useMemo(
    () => (remoteVideoSize.height > remoteVideoSize.width ? VideoOrientation.PORTRAIT : VideoOrientation.LANDSCAPE),
    [remoteVideoSize]
  );

  const callWindowNormalWidth = useMemo(() => {
    return callState.closedMsg ? "auto" : windowSize.width * 0.7;
  }, [windowSize.width, callState.closedMsg]);

  const callWindowNormalOrigin = useMemo(() => {
    return {
      left: (window.innerWidth - callWindowSize.width) / 2,
      top: (window.innerHeight - callWindowSize.height) / 2,
    };
  }, [callWindowSize]);

  const callWindowMinimizedOrigin = useMemo(() => {
    return { right: USER_MENU_ICON_RIGHT, bottom: 80 };
  }, []);

  useEffect(() => {
    if (callState.isReceivingCall) {
      playReceiving();
      const timer = setTimeout(() => {
        if (!callState.isInCall) {
          stopPlayReceiving();
          decline();
        }
      }, RECEIVING_MAKING_CALL_TIMEOUT);
      return () => clearTimeout(timer);
    } else {
      stopPlayReceiving();
    }
  }, [callState.isReceivingCall, playReceiving, stopPlayReceiving, callState.peerDevice, callState.isInCall, decline]);

  useEffect(() => {
    setPermissionConfigured(micState !== "prompt" && camState !== "prompt");
  }, [micState, camState]);

  useEffect(() => {
    if (callState.isCalling && permissionConfigured) {
      playCalling();
      const timer = setTimeout(() => {
        if (!callState.isInCall) {
          stopPlayCalling();
          setReconnectionFailed(true);
          hangup();
        }
      }, RECEIVING_MAKING_CALL_TIMEOUT);
      return () => clearTimeout(timer);
    } else {
      stopPlayCalling();
    }
  }, [callState.isCalling, playCalling, stopPlayCalling, callState.isInCall, hangup, permissionConfigured]);

  useEffect(() => {
    const resetDrawings = (): void => {
      setDrawingCount(0);
      setClearDisabled(true);
      setUndoDisabled(true);
    };

    if (callState.isCalling || callState.isReceivingCall || callState.isInCall) {
      setOpen(true);
    } else {
      resetDrawings();
    }
  }, [callState]);

  useEffect(() => {
    setPosition({ x: 0, y: 0 });
    setScreenType(ScreenState.NORMAL);
  }, [open]);

  useEffect(() => {
    if (screenType === ScreenState.MINIMIZED || screenType === ScreenState.NORMAL) {
      setPosition({ x: 0, y: 0 });
    }
  }, [screenType]);

  useEffect(() => {
    if (localMediaElement) {
      localMediaElement.srcObject = localMediaStream;
    }
  }, [localMediaStream, localMediaElement]);

  useEffect(() => {
    if (remoteVideoElement) {
      remoteVideoElement.srcObject = remoteMediaStream;
    }
  }, [remoteMediaStream, remoteVideoElement]);

  useEffect(() => {
    if (remoteAudioElement) {
      remoteAudioElement.srcObject = remoteMediaStream;
    }
  }, [remoteMediaStream, remoteAudioElement]);

  const handleToggle = () => {
    setDrawingMenuOpen((prev) => !prev);
  };

  const handleClose = () => {
    setDrawingMenuOpen(false);
  };

  const openVideoDeviceList = (event: React.MouseEvent<HTMLButtonElement>) => {
    loadMediaDevices();
    setVideoDeviceListAnchor(event.currentTarget);
  };

  const closeVideoDeviceList = () => {
    setVideoDeviceListAnchor(null);
  };

  const onSelectVideoDevice = (device: MediaDeviceInfo) => {
    selectVideoDevice(device);
    closeVideoDeviceList();
  };

  const openAudioDeviceList = (event: React.MouseEvent<HTMLButtonElement>) => {
    loadMediaDevices();
    setAudioDeviceListAnchor(event.currentTarget);
  };

  const closeAudioDeviceList = () => {
    setAudioDeviceListAnchor(null);
  };

  const onSelectAudioDevice = (device: MediaDeviceInfo) => {
    selectMicDevice(device);
    closeAudioDeviceList();
  };

  const videoDeviceList = useMemo(() => {
    if (mediaDeviceList) {
      const videoList = mediaDeviceList.filter((device) => device.kind === DeviceType.VideoInput);
      if (videoList.length === 0) {
        setVideoDeviceListError("No Camera Devices Found");
        return [];
      }
      const allowedVideoList = videoList.filter((device) => device.label.length > 0);
      if (allowedVideoList.length === 0) {
        setVideoDeviceListError("Camera Permission Denied");
        return [];
      }
      setVideoDeviceListError(null);
      return allowedVideoList;
    }
    setVideoDeviceListError("Finding Camera Devices...");
    return null;
  }, [mediaDeviceList]);

  const audioDeviceList = useMemo(() => {
    if (mediaDeviceList) {
      const audioList = mediaDeviceList.filter((device) => device.kind === DeviceType.AudioInput);
      if (audioList.length === 0) {
        setAudioDeviceListError("No Audio Devices Found");
        return [];
      }
      const allowedAudioList = audioList.filter((device) => device.label.length > 0);
      if (allowedAudioList.length === 0) {
        setAudioDeviceListError("Audio Permission Denied");
        return [];
      }
      setAudioDeviceListError(null);
      return allowedAudioList;
    }
    setAudioDeviceListError("Finding Audio Devices...");
    return null;
  }, [mediaDeviceList]);

  useEffect(() => {
    if (callState.closedMsg) {
      setPosition({ x: 0, y: 0 });
    }
  }, [callState]);

  useEffect(() => {
    if (callState.connectionStatus === ConnectionStatus.TIMEOUT) {
      const timer = setTimeout(() => {
        if (callState.connectionStatus === ConnectionStatus.TIMEOUT) {
          setReconnectionFailed(true);
          hangup();
        }
      }, RECONNECTION_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [callState.connectionStatus, hangup]);

  const connectionStatusOverlay = (
    <div
      style={{
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        opacity: 0.75,
        backgroundColor: "#D4D6D9",
        zIndex: 3,
      }}
    >
      <Grid
        container
        sx={{
          zIndex: 6,
          position: "absolute",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {permissionConfigured ? (
          <>
            <Typography
              variant="body1"
              sx={{
                userSelect: "none",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                borderRadius: "25px",
                py: 1,
                px: 2,
                zIndex: 4,
              }}
            >
              {callState.connectionStatus === ConnectionStatus.CONNECTING ? "Connecting..." : "Reconnecting..."}
            </Typography>
            <div style={{ zIndex: 4, marginRight: "30px", marginTop: "10px" }}>
              <CustomCircularProgress />
            </div>
          </>
        ) : (
          <Typography
            variant="body1"
            sx={{
              userSelect: "none",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "25px",
              py: 1,
              px: 2,
              zIndex: 4,
            }}
          >
            Calling will start once you configure camera & mic permissions
          </Typography>
        )}
      </Grid>
    </div>
  );

  const callWindow = (
    <Box
      m={screenType === ScreenState.MINIMIZED ? 1 : 3}
      width={
        screenType === ScreenState.MINIMIZED ? 300 : screenType === ScreenState.NORMAL ? callWindowNormalWidth : "auto"
      }
      paddingTop={screenType === ScreenState.MINIMIZED ? 5 : 7}
      {...({ ref: callWindowRef } as any)}
    >
      {open && (
        <ButtonGroup variant="contained" style={{ width: "100%", boxShadow: "none" }}>
          {callState.closedMsg && (
            <Tooltip title={"Dismiss"}>
              <Button
                onClick={() => {
                  setOpen(false);
                  setReconnectionFailed(false);
                }}
                sx={{
                  zIndex: 1,
                  backgroundColor: "transparent",
                  color: "white",
                  position: "absolute",
                  top: "8px",
                  right: "3px",
                  transform: screenType === ScreenState.MINIMIZED ? "scale(0.6)" : "scale(0.75)",
                }}
              >
                <Clear sx={{ color: theme.palette.primary.contrastText }} />
              </Button>
            </Tooltip>
          )}
          {screenType !== ScreenState.FULLSCREEN && !callState.closedMsg && (
            <Tooltip title={screenType === ScreenState.MINIMIZED ? "Maximize" : "Minimize"}>
              <Button
                onClick={() => {
                  if (screenType === ScreenState.MINIMIZED) {
                    setScreenType(ScreenState.NORMAL);
                  } else {
                    setScreenType(ScreenState.MINIMIZED);
                  }
                }}
                sx={{
                  zIndex: 1,
                  backgroundColor: "transparent",
                  color: "white",
                  position: "absolute",
                  top: "8px",
                  right: screenType === ScreenState.MINIMIZED ? "3px" : "45px",
                  transform: "scale(0.75)",
                }}
              >
                {screenType === ScreenState.MINIMIZED ? (
                  <CheckBoxOutlineBlank sx={{ color: theme.palette.primary.contrastText }} />
                ) : (
                  <Minimize sx={{ color: theme.palette.primary.contrastText }} />
                )}
              </Button>
            </Tooltip>
          )}
          {screenType !== ScreenState.MINIMIZED && !callState.closedMsg && (
            <Tooltip title={screenType === ScreenState.FULLSCREEN ? "Reduce" : "Full Screen"}>
              <Button
                onClick={() => {
                  if (screenType === ScreenState.FULLSCREEN) {
                    setScreenType(ScreenState.NORMAL);
                  } else {
                    setScreenType(ScreenState.FULLSCREEN);
                  }
                }}
                sx={{
                  zIndex: 1,
                  backgroundColor: "transparent",
                  color: "white",
                  position: "absolute",
                  top: "8px",
                  right: "3px",
                  transform: "scale(0.75)",
                }}
              >
                {screenType === ScreenState.FULLSCREEN ? (
                  <FilterNone sx={{ color: theme.palette.primary.contrastText }} />
                ) : (
                  <CheckBoxOutlineBlank sx={{ color: theme.palette.primary.contrastText }} />
                )}
              </Button>
            </Tooltip>
          )}
        </ButtonGroup>
      )}

      <Grid
        container
        spacing={screenType !== ScreenState.MINIMIZED ? 3 : 0}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          container
          item
          xs={12}
          id="draggable-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            right: 0,
            padding: 1,
            textAlign: "center",
            cursor: "move",
            background: theme.palette.primary.main,
            marginTop: screenType !== ScreenState.MINIMIZED ? -3 : -1,
          }}
          spacing={1}
          ref={titleBarRef}
        >
          <Grid item>
            <ARAvatar small={true} user={peer} contrast={true} />
          </Grid>
          <Grid item>
            <EllipsisTooltip
              variant={screenType !== ScreenState.MINIMIZED ? "h5" : "h6"}
              sx={{
                color: "white",
                paddingRight: "40px",
                textAlign: "left",
                margin: "0 auto",
                maxWidth: titleBarSize.width * 0.7,
              }}
            >
              {peer?.name ?? (errorPeer ? "Error fetching caller information" : "Fetching caller information...")}
            </EllipsisTooltip>
          </Grid>
        </Grid>
        {callState.closedMsg ? (
          <Grid
            container
            item
            direction="column"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">
                {reconnectionFailed ? "Call ended. Reconnection Failed." : callState.closedMsg}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setReconnectionFailed(false);
                  setOpen(false);
                }}
                sx={{
                  textTransform: "none",
                  width: 100,
                  paddingInline: "5%",
                  backgroundColor: "#bdbdbd",
                  color: theme.palette.info.contrastText,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                disableRipple
              >
                Dismiss
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Menu open={videoDeviceListOpen} anchorEl={videoDeviceListAnchor} onClose={closeVideoDeviceList}>
              {videoDeviceListError && (
                <Typography variant="body1" sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  {videoDeviceListError}
                </Typography>
              )}
              {videoDeviceList &&
                videoDeviceList.map((device) => (
                  <MenuItem
                    key={device.deviceId}
                    selected={device.deviceId === mediaState.selectedVideoDevice && mediaState.isVideoEnabled}
                    onClick={() => onSelectVideoDevice(device)}
                  >
                    {device.label}
                  </MenuItem>
                ))}
            </Menu>
            <Menu open={audioDeviceListOpen} anchorEl={audioDeviceListAnchor} onClose={closeAudioDeviceList}>
              {audioDeviceListError && (
                <Typography variant="body1" sx={{ paddingLeft: 1, paddingRight: 1 }}>
                  {audioDeviceListError}
                </Typography>
              )}
              {audioDeviceList &&
                audioDeviceList.map((device) => (
                  <MenuItem
                    key={device.deviceId}
                    selected={device.deviceId === mediaState.selectedAudioDevice && mediaState.isAudioEnabled}
                    onClick={() => onSelectAudioDevice(device)}
                  >
                    {device.label}
                  </MenuItem>
                ))}
            </Menu>
            {mediaErrorDescription && screenType === ScreenState.MINIMIZED && (
              <Alert
                severity="warning"
                onClose={() => setMediaErrorDescription(null)}
                style={{
                  zIndex: 2,
                  position: "relative",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  opacity: 1,
                  fontSize: "14px",
                }}
              >
                {mediaErrorDescription}
              </Alert>
            )}
            <Grid
              container
              item
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={screenType !== ScreenState.MINIMIZED ? 12 : 7}
                md={screenType !== ScreenState.MINIMIZED ? 6 : 7}
                lg={screenType !== ScreenState.MINIMIZED ? 6 : 7}
              >
                <Card
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    display: "block",
                    height: "auto",
                    maxHeight: `${remoteVideoSize.height}px`,
                    margin: "0 auto",
                  }}
                >
                  {mediaErrorDescription && screenType !== ScreenState.MINIMIZED && (
                    <Alert
                      severity="warning"
                      onClose={() => setMediaErrorDescription(null)}
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        width: "100%",
                        opacity: 0.9,
                        fontSize: "14px",
                      }}
                    >
                      {mediaErrorDescription}
                    </Alert>
                  )}
                  <CardMedia
                    sx={{
                      display: screenType === ScreenState.MINIMIZED ? "none" : "block",
                      height: "auto",
                      maxHeight: `${remoteVideoSize.height}px`,
                    }}
                    autoPlay
                    muted
                    ref={localVideoRef}
                    component="video"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#D4D6D9",
                      zIndex: 1,
                      display: mediaState.isVideoEnabled || screenType === ScreenState.MINIMIZED ? "none" : "block",
                    }}
                  >
                    <div style={{ position: "absolute", transform: "translate(-50%, -50%)", top: "50%", left: "50%" }}>
                      <ARAvatar user={currentUser} noBadge={true} large={true} />
                    </div>
                  </div>
                  <div
                    style={{
                      position: screenType === ScreenState.MINIMIZED ? "relative" : "absolute",
                      bottom: screenType === ScreenState.MINIMIZED ? "0px" : "10px",
                      left: screenType === ScreenState.MINIMIZED ? "15px" : `calc(25% - 17px)`,
                      width: "65px",
                    }}
                  >
                    <Button
                      onClick={toggleVideo}
                      startIcon={mediaState.isVideoEnabled ? <Videocam /> : <VideocamOff />}
                      sx={{
                        zIndex: 2,
                        backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "#eeeeee",
                        },
                        paddingRight: "25px",
                        width: "60px",
                        height: "35px",
                      }}
                    />
                    <IconButton
                      onClick={openVideoDeviceList}
                      sx={{
                        zIndex: 3,
                        position: "absolute",
                        right: 5,
                        top: 0,
                        height: "35px",
                        width: "35px",
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      position: screenType === ScreenState.MINIMIZED ? "absolute" : "absolute",
                      bottom: screenType === ScreenState.MINIMIZED ? "0px" : "10px",
                      right: screenType === ScreenState.MINIMIZED ? "5px" : `calc(20% - 5px)`,
                      width: "65px",
                    }}
                  >
                    <Button
                      onClick={toggleMic}
                      sx={{
                        zIndex: 2,
                        backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "#eeeeee",
                        },
                        paddingRight: "25px",
                        width: "60px",
                        height: "35px",
                      }}
                      startIcon={mediaState.isAudioEnabled ? <Mic /> : <MicOff />}
                    />
                    <IconButton
                      onClick={openAudioDeviceList}
                      sx={{
                        zIndex: 3,
                        position: "absolute",
                        right: 5,
                        top: 0,
                        height: "35px",
                        width: "35px",
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </div>
                </Card>
              </Grid>
              <Grid
                item
                xs={callState.peerDevice !== Device.Desktop ? 11 : 12}
                md={
                  screenType !== ScreenState.MINIMIZED
                    ? callState.peerDevice !== Device.Desktop
                      ? callState.isCalling || callState.isReceivingCall
                        ? 6
                        : 5
                      : 6
                    : 12
                }
                lg={
                  screenType !== ScreenState.MINIMIZED
                    ? callState.peerDevice !== Device.Desktop
                      ? callState.isCalling || callState.isReceivingCall
                        ? 6
                        : 5
                      : 6
                    : 12
                }
              >
                <Card
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    display: "block",
                    margin: "0 auto",
                    width:
                      videoOrientation === VideoOrientation.PORTRAIT
                        ? screenType === ScreenState.MINIMIZED
                          ? "60%"
                          : screenType === ScreenState.FULLSCREEN
                          ? "65%"
                          : "85%"
                        : screenType === ScreenState.MINIMIZED
                        ? "80%"
                        : "auto",
                    height: "auto",
                  }}
                >
                  {(callState.isCalling && permissionConfigured) || callState.isReceivingCall ? (
                    <Grid
                      container
                      item
                      sx={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        left: "50%",
                        top: screenType === ScreenState.MINIMIZED ? "30%" : "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <div
                        className="circleGroup"
                        style={{ transform: screenType === ScreenState.MINIMIZED ? "scale(0.5)" : "scale(0.8)" }}
                      >
                        <div
                          className="circle1"
                          style={{
                            height: screenType === ScreenState.MINIMIZED ? "10px" : "20px",
                            width: screenType === ScreenState.MINIMIZED ? "10px" : "20px",
                          }}
                        ></div>
                        <div
                          className="circle2"
                          style={{
                            height: screenType === ScreenState.MINIMIZED ? "10px" : "20px",
                            width: screenType === ScreenState.MINIMIZED ? "10px" : "20px",
                          }}
                        ></div>
                        <Call
                          className="phoneIcon"
                          style={{ width: screenType === ScreenState.MINIMIZED ? "10px" : "20px" }}
                        />
                      </div>
                    </Grid>
                  ) : (
                    callState.connectionStatus !== ConnectionStatus.CONNECTED && connectionStatusOverlay
                  )}

                  <CardMedia muted autoPlay ref={remoteVideoRef} component="video" />
                  <CardMedia autoPlay ref={remoteAudioRef} component="audio" />
                  {callState.isInCall && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#D4D6D9",
                          zIndex: 1,
                          display: mediaState.isPeerVideoEnabled ? "none" : "block",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            top: screenType === ScreenState.MINIMIZED ? "55%" : "50%",
                            left: "50%",
                          }}
                        >
                          <ARAvatar user={peer} noBadge={true} large={true} />
                          <Typography
                            variant="body1"
                            sx={{
                              textAlign: "center",
                              marginTop: "20px",
                            }}
                          >
                            {screenType !== ScreenState.MINIMIZED && callState.peerDevice === Device.iOS
                              ? "Device Video is not available; AptixAR App user must grant access to record screen when prompted"
                              : ""}
                          </Typography>
                        </div>
                      </div>
                      <MicOff
                        sx={{
                          position: "absolute",
                          bottom: "10px",
                          left: "15px",
                          width: "35px",
                          height: "35px",
                          borderRadius: "5px",
                          padding: "8px",
                          opacity: 0.75,
                          backgroundColor: "#bdbdbd",
                          zIndex: 2,
                          display: mediaState.isPeerAudioEnabled ? "none" : "block",
                        }}
                      />
                    </>
                  )}
                  {remoteVideoElement &&
                    callState.isInCall &&
                    callState.peerDevice !== Device.Desktop &&
                    screenType !== ScreenState.MINIMIZED && (
                      <VideoCallCanvas
                        video={remoteVideoElement}
                        drawingCount={drawingCount}
                        setDrawingCount={setDrawingCount}
                        setClearDisabledHandler={setClearDisabled}
                        setUndoDisabledHandler={setUndoDisabled}
                        setClearHandler={setClearDrawingHandler}
                        setUndoHandler={setUndoDrawingHandler}
                        cursorDisabled={cursorDisabled}
                        sendData={sendData}
                        drawingOptions={drawingOptions}
                      />
                    )}
                </Card>
              </Grid>
              {screenType !== ScreenState.MINIMIZED &&
                callState.isInCall &&
                callState.peerDevice !== Device.Desktop && (
                  <Grid
                    container
                    item
                    xs={1}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      padding={3.5}
                      border={2}
                      borderColor="grey.300"
                      borderRadius={10}
                    >
                      <Grid
                        container
                        item
                        direction="column"
                        spacing={2}
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: -7,
                        }}
                      >
                        <Grid item>
                          <Tooltip title={(cursorDisabled ? "Enable" : "Disable") + " Cursor"}>
                            <Button
                              onClick={() => setCursorDisabled(!cursorDisabled)}
                              sx={{
                                color: cursorDisabled ? theme.palette.primary.main : "white",
                                backgroundColor: cursorDisabled ? "#eeeeee" : theme.palette.primary.main,
                                "&:hover": {
                                  backgroundColor: cursorDisabled ? "#eeeeee" : "#eeeeee",
                                },
                                minWidth: DRAWING_BUTTON_WIDTH,
                                width: DRAWING_BUTTON_WIDTH,
                                height: DRAWING_BUTTON_SIZE,
                              }}
                            >
                              <Mouse sx={{ color: theme.palette.primary.contrastText }} />
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title={"Drawing menu"}>
                            <>
                              <Button
                                style={{
                                  backgroundColor: DRAWING_COLORS[drawingOptions.selectedColor].code,
                                  color: theme.palette.secondary.contrastText,
                                  verticalAlign: "middle",
                                  minWidth: DRAWING_BUTTON_WIDTH,
                                  width: DRAWING_BUTTON_WIDTH,
                                  height: DRAWING_MENU_BUTTON_SIZE,
                                }}
                                ref={anchorRef}
                              >
                                <Box flexDirection="column">
                                  <Brush sx={{ color: theme.palette.primary.contrastText }} />
                                  <IconButton
                                    size="small"
                                    onClick={handleToggle}
                                    style={{
                                      color: theme.palette.secondary.contrastText,
                                    }}
                                  >
                                    {!drawingMenuOpen ? (
                                      <ArrowDropDown sx={{ color: theme.palette.primary.contrastText }} />
                                    ) : (
                                      <ArrowDropUp sx={{ color: theme.palette.primary.contrastText }} />
                                    )}
                                  </IconButton>
                                </Box>
                              </Button>
                              <DrawingMenu
                                open={drawingMenuOpen}
                                anchorEl={anchorRef.current}
                                drawingOptions={drawingOptions}
                                setDrawingOptions={setDrawingOptions}
                                handleClose={handleClose}
                                isVideoCall={true}
                              />
                            </>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Undo">
                            <Button
                              onClick={undoDrawingHandler}
                              disabled={undoDisabled}
                              sx={{
                                color: theme.palette.primary.main,
                                backgroundColor: "white",
                                "&:hover": {
                                  color: theme.palette.info.contrastText,
                                  backgroundColor: theme.palette.primary.main,
                                },
                                minWidth: DRAWING_BUTTON_WIDTH,
                                width: DRAWING_BUTTON_WIDTH,
                                height: DRAWING_BUTTON_SIZE,
                              }}
                            >
                              <Undo />
                            </Button>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Clear Drawing">
                            <Button
                              onClick={clearDrawingHandler}
                              disabled={clearDisabled}
                              sx={{
                                color: theme.palette.primary.main,
                                backgroundColor: "white",
                                "&:hover": {
                                  color: theme.palette.info.contrastText,
                                  backgroundColor: theme.palette.primary.main,
                                },
                                minWidth: DRAWING_BUTTON_WIDTH,
                                width: DRAWING_BUTTON_WIDTH,
                                height: DRAWING_BUTTON_SIZE,
                              }}
                            >
                              <DeleteForever />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              <Grid item xs={12}>
                <ButtonGroup variant="contained" style={{ width: "100%", boxShadow: "none" }}>
                  {callState.isReceivingCall && (
                    <Tooltip title="Answer">
                      <Button
                        onClick={answer}
                        sx={{
                          color: theme.palette.error.contrastText,
                          backgroundColor: "#66bb6a",
                          width: "100%",
                          "&:hover": {
                            backgroundColor: "#43a047",
                          },
                        }}
                      >
                        <Phone sx={{ color: theme.palette.primary.contrastText }} />
                      </Button>
                    </Tooltip>
                  )}
                  {callState.isReceivingCall && (
                    <Tooltip title="Decline">
                      <Button
                        onClick={decline}
                        sx={{
                          width: "100%",
                          backgroundColor: "#f31e0d",
                          color: theme.palette.error.contrastText,
                          "&:hover": {
                            backgroundColor: "#c1170a",
                          },
                        }}
                      >
                        <CallEnd sx={{ color: theme.palette.primary.contrastText }} />
                      </Button>
                    </Tooltip>
                  )}
                  {(callState.isInCall || callState.isCalling) && (
                    <Tooltip title="Hang Up">
                      <Button
                        onClick={() => {
                          hangup();
                          setOpen(false);
                        }}
                        sx={{
                          width: "100%",
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.error.contrastText,
                          "&:hover": {
                            backgroundColor: theme.palette.error.dark,
                          },
                          position: "relative",
                          bottom: 0,
                          left: 0,
                          height: "5%",
                        }}
                      >
                        <CallEnd sx={{ color: theme.palette.primary.contrastText }} />
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );

  const positionTrack = useCallback(
    (e: DraggableEvent, ui: DraggableData): void => {
      setPosition({ x: position.x + ui.deltaX, y: position.y + ui.deltaY });
    },
    [position]
  );

  const clampPosition = useCallback(() => {
    setPosition((prevPosition) => ({
      x: clamp(prevPosition.x, -callWindowNormalOrigin.left, callWindowNormalOrigin.left),
      y: clamp(prevPosition.y, -callWindowNormalOrigin.top, callWindowNormalOrigin.top - titleBarSize.height),
    }));
  }, [callWindowNormalOrigin, titleBarSize]);

  if (!open) {
    return <></>;
  } else if (screenType === ScreenState.FULLSCREEN) {
    return (
      <Dialog fullScreen open={true}>
        {callWindow}
      </Dialog>
    );
  } else {
    return (
      <Draggable
        onDrag={positionTrack}
        onStop={clampPosition}
        handle="#draggable-dialog-title"
        cancel='[class*="MuiDialogContent-root"]'
        position={position}
        bounds="parent"
      >
        <Paper
          sx={{
            position: "absolute",
            zIndex: 1504,
            overflow: "hidden",
            bottom: ScreenState.NORMAL ? "auto" : callWindowMinimizedOrigin.bottom,
            right: ScreenState.NORMAL ? "auto" : callWindowMinimizedOrigin.right,
            top: ScreenState.NORMAL ? callWindowNormalOrigin.top : "auto",
            left: ScreenState.NORMAL ? callWindowNormalOrigin.left : "auto",
          }}
        >
          {callWindow}
        </Paper>
      </Draggable>
    );
  }
};

export default VideoCall;
