import { PermissionDict, Role, User } from "services/ContentServer/Identity";

export interface RoleState {
  id: string;
  name: string;
  desc: string;
  users: User[];
  addedUsers: User[];
  removedUsers: User[];
  permissions: PermissionDict;
}

export enum RoleActionType {
  RESET_ALL,
  UPDATE_ROLE_NAME,
  UPDATE_ADDED_REMOVED_USERS,
  UPDATE_USERS,
  UPDATE_USERS_COUNT,
  UPDATE_ROLE,
  UPDATE_PERMISSIONS,
}

export interface RoleAction {
  type: RoleActionType;
  role?: Role;
  id?: string;
  name?: string;
  desc?: string;
  users?: User[];
  addedUsers?: User[];
  removedUsers?: User[];
  permissions?: PermissionDict;
}

export const roleReducer = (state: RoleState, action: RoleAction): RoleState => {
  switch (action.type) {
    case RoleActionType.RESET_ALL:
      if (action.role) {
        return {
          ...initialRoleState,
          id: action.role.id,
          name: action.role.name,
          desc: action.role.description,
          permissions: action.role.permissions,
          users: action.users || [],
        };
      } else {
        return { ...initialRoleState };
      }
    case RoleActionType.UPDATE_ROLE_NAME:
      return {
        ...state,
        name: action.name || "",
      };
    case RoleActionType.UPDATE_ADDED_REMOVED_USERS:
      return {
        ...state,
        addedUsers: action.addedUsers || [],
        removedUsers: action.removedUsers || [],
      };
    case RoleActionType.UPDATE_USERS:
      return {
        ...state,
        users: action.users || [],
      };
    case RoleActionType.UPDATE_ROLE:
      return {
        ...state,
        id: action.id || "",
        name: action.name || "",
        desc: action.desc || "",
        users: action.users || [],
      };

    case RoleActionType.UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions || {},
      };
    default:
      return state;
  }
};

export const initialRoleState: RoleState = {
  id: "",
  name: "New Role",
  desc: "",
  users: [],
  addedUsers: [],
  removedUsers: [],
  permissions: {},
};
