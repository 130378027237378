import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Divider, Grid } from "@mui/material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback, useContext, useRef, useState, useEffect } from "react";

import LoadingDialog from "./LoadingDialog";
import BaseButton from "components/DalmatianDesignComponents/BaseButton";
import DatePicker from "components/DalmatianDesignComponents/DatePicker";
import TextFieldForm from "components/forms/TextFieldForm";
import { SnackbarNames } from "components/snackbar/Constants";
import FieldMessage from "components/Typography/FieldMessage";
import { RequiredFieldMsg } from "components/Typography/RequiredField";
import useSnackbar, { SnackbarActionType } from "hooks/useSnackbar";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { validateEmail } from "utils/StringUtils";

const today = new Date(); // current date and time
const amountWeeks = 5;
const amountWeeksAgo = new Date(today.getTime() - amountWeeks * 7 * 24 * 60 * 60 * 1000 - 24 * 60 * 60 * 1000); // subtract weeks worth of milliseconds and an extra day

enum Msg {
  FutureDate = "Start date cannot be a future date.",
  SameDate = "Start date cannot be the same as end date.",
  StartGreaterEnd = "Start date cannot be greater end date.",
  StartTooFarBack = "Please select a start date within {0} weeks of today; for audit logs from prior to {0} weeks, contact your system administrator.",
}

export default function UserLogDialog({
  open,
  setOpen,
  disabled,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  disabled?: boolean;
}) {
  const theme = useTheme();
  const { contentServer } = useContext(RequestContext);
  const snackbar = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null | undefined>(null);
  const [endDate, setEndDate] = useState<Date | null | undefined>(null);
  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(false);
  const [showReqEmail, setShowReqEmail] = useState(false);
  const [showReqStart, setShowReqStart] = useState(false);
  const [showReqEnd, setShowReqEnd] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [startDateMsg, setStartDateMsg] = useState("");
  const startTimePickerRef = useRef<HTMLButtonElement>(null);
  const endTimePickerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (startDate) {
      if (moment(startDate).isAfter(Date.now())) {
        setStartDateMsg(Msg.FutureDate);
      } else {
        setStartDateMsg("");
      }
    }

    if (startDate && endDate) {
      if (moment(startDate).isSame(endDate)) {
        setStartDateMsg(Msg.SameDate);
      } else if (moment(startDate).isAfter(endDate)) {
        setStartDateMsg(Msg.StartGreaterEnd);
      } else if (moment(startDate).isBefore(amountWeeksAgo)) {
        setStartDateMsg(Msg.StartTooFarBack.replaceAll("{0}", amountWeeks.toString()));
      } else if (moment(startDate).isAfter(Date.now())) {
        setStartDateMsg(Msg.FutureDate);
      } else {
        setStartDateMsg("");
      }
    }
  }, [startDate, endDate]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setProcessing(false);
    setEmail("");
    setStartDate(null);
    setEndDate(null);
    setShowReqEmail(false);
    setShowReqStart(false);
    setShowReqEnd(false);
    setComplete(false);
    setInvalidEmail(false);
    setStartDateMsg("");
  }, [setOpen]);

  const handleUserLogs = useCallback(async () => {
    try {
      if (startDate && endDate && email && startDate < endDate) {
        await contentServer.identityService.mailUserLogs(startDate.toISOString(), endDate.toISOString(), email);
        setComplete(true);
      }
      setProcessing(false);
    } catch (e) {
      console.error(e);
      snackbar.dispatch({
        type: SnackbarActionType.OPEN,
        message: SnackbarNames.DOWNLOAD_USER_LOGS_ERROR,
      });
      handleClose();
    }
  }, [contentServer.identityService, email, endDate, handleClose, snackbar, startDate]);

  const validateData = async () => {
    let invalidCounter = 0;

    setShowReqStart(false);
    setShowReqEnd(false);
    setShowReqEmail(false);
    setInvalidEmail(false);
    setStartDateMsg("");

    if (!startDate) {
      setShowReqStart(true);
      invalidCounter++;
    } else if (moment(startDate).isAfter(Date.now())) {
      setStartDateMsg(Msg.FutureDate);
      invalidCounter++;
    }

    if (!endDate) {
      setShowReqEnd(true);
      invalidCounter++;
    }

    if (invalidCounter == 0) {
      if (moment(startDate).isSame(endDate)) {
        setStartDateMsg(Msg.SameDate);
        invalidCounter++;
      } else if (moment(startDate).isAfter(endDate)) {
        setStartDateMsg(Msg.StartGreaterEnd);
        invalidCounter++;
      }
    }

    if (email.trim().length === 0) {
      setShowReqEmail(!email);
      invalidCounter++;
    } else if (!validateEmail(email)) {
      setInvalidEmail(true);
      invalidCounter++;
    }

    if (invalidCounter === 0) {
      setProcessing(true);
      await handleUserLogs();
    }
  };

  return (
    <>
      <Dialog
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: { borderRadius: 0, maxWidth: 700 },
        }}
      >
        <DialogTitle>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11}>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "15px",
                  color: theme.palette.common.black,
                }}
              >
                {"Retrieve User Sign-in Logs"}
              </Typography>
            </Grid>
            <Grid item xs={1} container justifyContent="flex-end">
              <IconButton
                id="close-user-log-popup"
                onClick={handleClose}
                style={{ color: theme.palette.common.black }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <LoadingDialog processing={processing} msg={"Scheduling email..."}></LoadingDialog>
        <DialogContent>
          {!complete ? (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6} id="start-date" style={{ padding: "20px 8px 0px 16px" }}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "12px",
                    color: theme.palette.common.black,
                    textTransform: "uppercase",
                  }}
                >
                  Start Date
                </Typography>
              </Grid>
              <Grid item xs={6} id="end-date" style={{ padding: "20px 8px 0px 20px" }}>
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "12px",
                    color: theme.palette.common.black,
                    textTransform: "uppercase",
                  }}
                >
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ paddingRight: "20px" }}>
                <Box
                  id="date-picker-box-start"
                  style={{ display: "flex", alignItems: "flex-start", gap: "16px", height: "36px" }}
                  ref={startTimePickerRef}
                >
                  <DatePicker value={startDate} setValue={setStartDate} timePickerRef={startTimePickerRef} />
                </Box>
                <FieldMessage>{startDateMsg}</FieldMessage>
                <RequiredFieldMsg style={{ display: showReqStart ? "" : "none" }} />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: "20px" }}>
                <Box
                  id="date-picker-box-end"
                  style={{ display: "flex", alignItems: "flex-start", gap: "16px", height: "36px" }}
                  ref={endTimePickerRef}
                >
                  <DatePicker value={endDate} setValue={setEndDate} timePickerRef={endTimePickerRef} />
                </Box>
                <RequiredFieldMsg style={{ display: showReqEnd ? "" : "none" }} />
              </Grid>
              <Grid item xs={12} id="email-form">
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    color: theme.palette.common.black,
                    paddingBottom: "10px",
                  }}
                >
                  Send to the following email:
                </Typography>
                <TextFieldForm
                  id="user-email"
                  value={email || ""}
                  label={""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                  type="text"
                  disabled={false}
                  required={true}
                  style={{ width: "100%" }}
                  sx={{
                    height: "36px",
                    ".MuiOutlinedInput-root": {
                      height: "100%!important",
                    },
                    ".MuiOutlinedInput-input": {
                      height: "100%!important",
                      padding: "0px 14px",
                    },
                  }}
                />
                <Typography
                  style={{
                    color: theme.palette.dalmatianDesign.error,
                    fontFamily: "inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    display: invalidEmail ? "" : "none",
                  }}
                >
                  *Invalid Email.
                </Typography>
                <RequiredFieldMsg style={{ display: showReqEmail ? "" : "none" }} />
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{
                    gap: "15px",
                    width: "45%",
                  }}
                >
                  <BaseButton variant="outlined" onClick={handleClose} style={{ width: "fit-content" }}>
                    Cancel
                  </BaseButton>
                  <BaseButton
                    disabled={startDateMsg == "" ? false : true}
                    variant="contained"
                    onClick={validateData}
                    style={{ width: "fit-content" }}
                  >
                    Retrieve Logs
                  </BaseButton>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} id="email-form">
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    color: theme.palette.common.black,
                    paddingBottom: "10px",
                  }}
                >
                  {"The user sign-in logs are being sent to " + email}
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{
                    width: "45%",
                  }}
                >
                  <BaseButton variant="outlined" onClick={handleClose} style={{ width: "fit-content" }}>
                    Close
                  </BaseButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
