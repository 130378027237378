import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import Title from "../charts/Title";
import { ARRowCell } from "components/ARTable";
import useARMediaQuery from "hooks/useARMediaQuery";
import { StableSort, GetComparatorFcn, SortDirection } from "utils/SortRowsUtils";
import { percentToDecimal } from "utils/StringUtils";
export function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
  headCells,
  cellWidths,
  width,
  dark,
}: {
  order: SortDirection | undefined;
  orderBy: string;
  onRequestSort: CallableFunction;
  headCells: any[];
  cellWidths?: Map<string, string>;
  width?: number;
  dark?: boolean;
}) {
  const theme = useTheme();
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };
  const matchesMD = useARMediaQuery(theme.breakpoints.down("md"));
  return (
    <TableRow
      sx={{
        backgroundColor: "#FFFFFF",
      }}
    >
      {headCells.map((headCell, idx) => {
        const widthPercent = cellWidths ? cellWidths.get(headCell.id) : 0;
        return (
          <ARRowCell
            key={headCell.id}
            index={headCell.id === "Description" || headCell.id === "UserTeam" || headCell.id === "UserRole" ? 2 : idx}
            alwaysShow={headCell.id === "ContextMenu" || headCell.id === "Users" || headCell.id === "LastLogin"}
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.main}`,
              "&.MuiTableCell-stickyHeader": {
                backgroundColor: "white",
              },
              py: 2,
              pl: idx === 0 ? 2 : 0,
              pr: idx === headCells.length - 1 ? 2 : 0,
              width: matchesMD
                ? headCell.id === "User"
                  ? "60%"
                  : "100%"
                : cellWidths
                ? width
                  ? width * (widthPercent ? percentToDecimal(widthPercent) : 0)
                  : widthPercent
                : "",
            }}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
            >
              <Typography variant="subtitle1">{headCell.label}</Typography>
            </TableSortLabel>
          </ARRowCell>
        );
      })}
    </TableRow>
  );
}

export default function EnhancedTable(rows: any[], headCells: any[], title: string, width?: number) {
  const [order, setOrder] = useState<SortDirection | undefined>(SortDirection.ASC);
  const [orderBy, setOrderBy] = useState("ID");

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === SortDirection.ASC;
    setOrder(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setOrderBy(property);
  };

  return (
    <>
      <Title variant="h4">{title}</Title>
      <TableContainer>
        <Table
          stickyHeader
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          style={{ width: width }}
        >
          <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
          <TableBody>
            {StableSort(rows, GetComparatorFcn(order, orderBy, orderBy === "Time")).map((row) => {
              const tableCells = headCells.map((headCell, idx) => {
                return (
                  <TableCell
                    sx={{
                      borderBottom: "1px solid grey",
                      padding: "6px 4px",
                    }}
                    key={idx}
                    align="center"
                    padding="normal"
                  >
                    {row[headCell.id]}
                  </TableCell>
                );
              });
              return <TableRow key={row.ID}>{tableCells}</TableRow>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
