import * as THREE from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { $enum } from "ts-enum-util";

import FrustumBufferGeometry from "./ThreeWrappers/Frustum";
import { FILTER_BOX_HEIGHT } from "assets/Constants";
import { ARtheme as theme } from "assets/theme";
import { DeviceCameraInfo } from "hooks/useDeviceCamera";
import { colorStringToInt } from "utils/ColorUtils";

const COPYRIGHT_HEIGHT = 10;
const VIEWPORT_FOV_V = 66;
const VIEWPORT_ASPECT_RATIO = 1.54;
const DEFAULT_FRUSTUM_LENGTH_MULTIPLIER = 0.5;
const MAX_ZOOM = 2;
const MIN_ZOOM = 1;
const MAX_WIDTH_MARGIN_RATIO = 2;
const CAMERA_OBJECT_TO_MODEL_RATIO = 0.15;
const CAMERA_OBJECT_TO_PLY_RATIO = 0.02;
const SPATIAL_CAMERA_OBJECT_SIZE = 0.1;
const MAX_CAMERA_OBJECT_SIZE = 0.6;
const MIN_CAMERA_OBJECT_SIZE = 0.001;
const DEFAULT_CAMERA_OBJECT_SIZE = 0.05;
const SPRITE_SIZE = 0.3;
const LINE_SELECTION_EPSILON = 0.1;
const DRAWING_PLANE_SLIDER_STEPS = 5000;
const ARROW_LENGTH = 0.14;
const TRANSLUCENT_SUFFIX = "TRANSLUCENT";
const INSPECTION_PAGE_OFFSET = 2 * FILTER_BOX_HEIGHT + COPYRIGHT_HEIGHT + theme.spacing(5);
const FLOORPLAN_NAME = "FLOORPLAN";
const FLOORPLAN_GROUP = "FLOORPLAN_GROUP";
const VIEW_PATH_NAME = "VIEW_PATH";
const TOUCHUP_SPHERE_RADIUS = 0.1;
const SPLAT_MASK_SPHERE_HEIGHT = 0.9;
const SPLAT_MASK_SPHERE_SCALAR = 1.75;

enum RenderLayer {
  ALL_LAYERS,
  FRUSTUM_LAYER,
  SELECTOR_LAYER,
  ANNOTATION_LAYER,
  BLANK_LAYER,
  OCCLUSSION_LAYER,
}

enum COLOR {
  RED = "#ff0000",
  LIGHT_CORAL = "#f08080",
  GREEN = "#00ff00",
  BLUE = "#0000ff",
  WHITE = "#ffffff",
  YELLOW = "#ffff00",
  CYAN = "#00838f",
  PURPLE = "#33165F",
  PINK = "#ff3fa0",
  ORANGE = "#ff9100",
  DARK_GREY = "#333333",
  LIGHT_GREY = "#cccccc",
  HIGHLIGHT_BLUE = "#000AFF",
  BACKGROUND_GREY = "#F4F4F4",
  BLACK = "#000000",
  LIGHT_YELLOW = "#fffec8",
}

const COLORS = $enum(COLOR).getValues();

const DRAWING_COLORS: { id: number; code: COLOR }[] = [
  { id: 0, code: theme.palette.annotations.red as COLOR },
  { id: 1, code: theme.palette.annotations.orange as COLOR },
  { id: 2, code: theme.palette.annotations.blue as COLOR },
  { id: 3, code: theme.palette.annotations.green as COLOR },
  { id: 4, code: theme.palette.annotations.black as COLOR },
];

enum DRAWING_SIZE {
  ONE = 0.025,
  TWO = 0.035,
  THREE = 0.05,
}

const selectionMaterial = new THREE.MeshBasicMaterial({
  color: COLOR.GREEN,
  opacity: 0.5,
  transparent: true,
  side: THREE.DoubleSide,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
});

const previewMaterial = new THREE.MeshBasicMaterial({
  color: COLOR.BLUE,
  opacity: 0.5,
  transparent: true,
  side: THREE.DoubleSide,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
});

const newPoseFrustumMaterial = new THREE.MeshBasicMaterial({
  color: COLOR.CYAN,
  opacity: 0.5,
  transparent: true,
  side: THREE.DoubleSide,
  polygonOffset: true,
  polygonOffsetFactor: 1,
  polygonOffsetUnits: 1,
});

const defaultComponentMaterial = new THREE.MeshStandardMaterial({
  color: COLOR.DARK_GREY,
  roughness: 0.3,
  metalness: 0.98,
});

const defaultSpatialMaterial = new THREE.MeshStandardMaterial({
  color: COLOR.DARK_GREY,
  roughness: 0.3,
  metalness: 0.2,
});

const defaultFrustumMaterial = new THREE.MeshBasicMaterial({
  color: COLOR.YELLOW,
  opacity: 0.5,
  transparent: true,
  side: THREE.DoubleSide,
});

const previewFrustumMaterial = new THREE.MeshBasicMaterial({
  color: COLOR.YELLOW,
  opacity: 0.1,
  transparent: true,
  side: THREE.DoubleSide,
});

const transparentFrustumMaterial = new THREE.MeshBasicMaterial({
  color: COLOR.YELLOW,
  opacity: 0,
  transparent: true,
  side: THREE.DoubleSide,
});

const arrowHandleMaterial = new THREE.MeshStandardMaterial({
  color: COLOR.WHITE,
});

const pointCloudMaterial = new THREE.PointsMaterial({
  size: 0.01,
  sizeAttenuation: true,
  alphaTest: 0,
  opacity: 1,
  vertexColors: true,
});

const invisibleMaterial = new THREE.MeshBasicMaterial({
  colorWrite: false,
});

const frustumGeometryLandscape = (camera: DeviceCameraInfo) =>
  new FrustumBufferGeometry(2, 2 / camera.aspectRatio, camera.projectionMatrix.m00, camera.cx, camera.cy, false);

const frustumGeometryPortrait = (camera: DeviceCameraInfo) =>
  new FrustumBufferGeometry(2 / camera.aspectRatio, 2, camera.projectionMatrix.m00, camera.cx, camera.cy, false);

const previousDrawingLineMaterial = new LineMaterial({
  color: colorStringToInt(COLOR.RED),
  linewidth: 0.005,
  worldUnits: true,
});

const textureEncoding = THREE.LinearEncoding;

const createDirectionalLight = (x: number, y: number, z: number, color = COLOR.WHITE) => {
  const directionalLight = new THREE.DirectionalLight(colorStringToInt(COLOR.WHITE), 0.5);
  directionalLight.target.position.set(x, y, z);
  directionalLight.add(directionalLight.target);
  return directionalLight;
};

const createHemisphereLight = () =>
  new THREE.HemisphereLight(colorStringToInt(COLOR.LIGHT_GREY), colorStringToInt(COLOR.BLACK), 0.05);
const createAmbientLight = () => new THREE.AmbientLight(colorStringToInt(COLOR.WHITE), 1);
const createDirectionalDownLight = () => createDirectionalLight(1, -1, 0);
const createDirectionalUpLight = () => createDirectionalLight(-1, 1, 0);

export {
  COLOR,
  COLORS,
  DRAWING_COLORS,
  DRAWING_SIZE,
  VIEWPORT_FOV_V,
  VIEWPORT_ASPECT_RATIO,
  DEFAULT_FRUSTUM_LENGTH_MULTIPLIER,
  MAX_ZOOM,
  MIN_ZOOM,
  CAMERA_OBJECT_TO_MODEL_RATIO,
  CAMERA_OBJECT_TO_PLY_RATIO,
  MIN_CAMERA_OBJECT_SIZE,
  MAX_CAMERA_OBJECT_SIZE,
  DEFAULT_CAMERA_OBJECT_SIZE,
  MAX_WIDTH_MARGIN_RATIO,
  LINE_SELECTION_EPSILON,
  DRAWING_PLANE_SLIDER_STEPS,
  ARROW_LENGTH,
  TRANSLUCENT_SUFFIX,
  SPATIAL_CAMERA_OBJECT_SIZE,
  RenderLayer,
  selectionMaterial,
  previewMaterial,
  newPoseFrustumMaterial,
  defaultComponentMaterial,
  defaultSpatialMaterial,
  previousDrawingLineMaterial,
  defaultFrustumMaterial,
  previewFrustumMaterial,
  transparentFrustumMaterial,
  pointCloudMaterial,
  arrowHandleMaterial,
  invisibleMaterial,
  frustumGeometryLandscape,
  frustumGeometryPortrait,
  textureEncoding,
  createHemisphereLight,
  createAmbientLight,
  createDirectionalDownLight,
  createDirectionalUpLight,
  INSPECTION_PAGE_OFFSET,
  FLOORPLAN_NAME,
  FLOORPLAN_GROUP,
  VIEW_PATH_NAME,
  TOUCHUP_SPHERE_RADIUS,
  SPLAT_MASK_SPHERE_HEIGHT,
  SPLAT_MASK_SPHERE_SCALAR,
  SPRITE_SIZE,
};
