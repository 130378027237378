import { APIInstance } from "../../APIInstance";
import { ENDPOINTS } from "../constants";
import { Facility } from "../serviceTypes/Facility";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export const FacilitySWRKeys = {
  FACILITY: "facility",
};
export class FacilityService extends ServiceBase<Facility> {
  constructor(apiInstance: APIInstance) {
    super(Facility, apiInstance, ENDPOINTS.FACILITY);
  }
}
