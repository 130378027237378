export enum WebRTCMessageType {
  SDPOffer = 1,
  SDPAnswer = 2,
  ICECandidate = 3,
}

export enum VideoCallMessageType {
  Call = "call",
  Hangup = "hangup",
  Busy = "busy",
  Decline = "decline",
}

export enum DataChannelMessageType {
  Annotation = "annotation",
  Media = "media",
  Ping = "ping",
  Location = "location",
}

export enum TrackType {
  Video = "video",
  Audio = "audio",
}

export enum DeviceType {
  VideoInput = "videoinput",
  AudioInput = "audioinput",
  AudioOutput = "audiooutput",
}
