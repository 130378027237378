import { LinearProgress as LinearProgressMui } from "@mui/material";
import { styled } from "@mui/system";

const LinearProgress = styled(LinearProgressMui)(({ theme }) => ({
  width: "100%",
  position: "relative",
  zIndex: (theme as any).zIndex.appBar,
}));

export default LinearProgress;
