import { Icon, IconProps } from "@mui/material";
import React from "react";

import icon from "assets/icons/ArchiveSharp.svg";

export const ArchiveSharp = (props: IconProps) => {
  return (
    <Icon {...props}>
      <img src={icon} alt="Archive sharp icon" />
    </Icon>
  );
};

export default ArchiveSharp;
