import { useEffect, useState } from "react";
import { SplatProgress, useLazyGetSplatProgressQuery } from "redux/api/uploadDataApi";

export const useSplatProgress = (relatedObjectId?: string | null) => {
  const [splatProgress, setSplatProgress] = useState<SplatProgress | undefined>(undefined);
  const [getSplatProgress] = useLazyGetSplatProgressQuery();

  useEffect(() => {
    if (relatedObjectId) {
      getSplatProgress(relatedObjectId)
        .unwrap()
        .then((data: SplatProgress | undefined) => {
          setSplatProgress(data);
        });
    } else {
      setSplatProgress(undefined);
    }
  }, [relatedObjectId, getSplatProgress]);

  return splatProgress;
};
