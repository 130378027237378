import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ChangeEvent } from "react";

import { FixedHeightTextField } from "components/TextField/FixedHeightTextField";

export const ALL_RESULTS = "ALL";
export const CLEAR_ALL = "CLEAR ALL";

export interface SearchOption {
  id: string;
  label: string;
  category?: string;
}

export function getUniqueValues(values: SearchOption[]) {
  const optionIds = values.map((val) => val.id);
  const duplicateIds = values.filter(({ id }, idx) => optionIds.includes(id, idx + 1)).map((val) => val.id);
  return values.filter((val) => !duplicateIds.includes(val.id));
}

const SearchBar = ({
  placeholderText,
  query,
  setQuery,
}: {
  placeholderText: string;
  query: string;
  setQuery: (value: string | ((prev: string) => string)) => void;
}) => {
  const theme = useTheme();
  return (
    <FixedHeightTextField
      id="search-bar"
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minWidth: "50px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "140%",
        color: "#64676A",
        ".MuiOutlinedInput-root": {
          height: "40px",
        },
      }}
      value={query}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: theme.palette.dalmatianDesign.dark }} />
          </InputAdornment>
        ),
        endAdornment: query && (
          <InputAdornment position="end">
            <IconButton onClick={() => setQuery("")} size="large">
              <CloseIcon style={{ color: theme.palette.dalmatianDesign.dark }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: false,
      }}
      placeholder={placeholderText}
      onChange={(event: ChangeEvent<any>) => {
        setQuery(event.target.value);
      }}
    />
  );
};

export default SearchBar;
