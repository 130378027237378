import { colors, PaletteOptions } from "@mui/material";

const white = "#FFFFFF";
const black = "#282828";

const palette: PaletteOptions = {
  common: { black, white },
  primary: {
    dark: "#D4D6D9",
    main: "#1C1F22",
    light: white,
  },
  secondary: {
    contrastText: white,
    dark: "#373737",
    main: "#6F6F6F",
    light: "#9a9a9a",
  },
  info: {
    contrastText: white,
    dark: colors.cyan[900],
    main: colors.cyan[900],
    light: colors.cyan[800],
  },
  error: {
    light: "#FEF3F2",
    main: "#C1170A",
    dark: "#C1170A",
    contrastText: white,
  },
  neutral: {
    defaultBackground: "#F4F6F8",
    modelBackground: "#F2F3F3",
    bordersDividers: "#D4D6D9",
    topNavigationSeparator: "#D4D6D9",
    defaultTextIcons: "#64676A",
    secondaryTextButtons: "#404245",
    emphasizedTextIcons: "#1C1F22",
    activeButton: "#D4D6D9B2",
    scrollBars: "#64676A99",
    titleText: "#404245",
    darkGreyText: "#424242",
  },
  accent: {
    light: "#EFF8FF",
    main: "#9ACFF9",
    contrastText: "#0F70BC",
  },
  success: {
    main: "#69E032",
    light: "#69E032",
    dark: "#69E032",
    contrastText: white,
  },
  annotations: {
    red: "#FF2616",
    orange: "#FF9B18",
    blue: "#0D95FF",
    green: "#3DDC06",
    black: "#000000",
  },
  frustum: {
    selected: {
      main: "#0000FF",
      light: "#0000FF80",
    },
    capture: {
      main: "#FFFF00",
      light: "#FFFF0080",
    },
    request: {
      main: "#00838F",
      light: "#00838F80",
    },
  },
  model: {
    modelHighlight: {
      edge: "#0000FF",
      hidden: "#FF9B18",
    },
    boundingBox: {
      main: "#FF9B18",
      light: "#FF9B184D",
    },
  },
  dalmatianDesign: {
    contrastText: white,
    dark: "#1C1F22",
    main: white,
    accent: "#EBEBEB",
    error: "#C1170A",
  },
  legacy: {
    contrastText: white,
    dark: "#00597E",
    main: "#3477A5",
    light: "#229CD0",
  },
};

export default palette;
