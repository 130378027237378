import { MediaCapture } from "../serviceTypes/MediaCapture";
import { APIInstance } from "services/ContentServer/APIInstance";
import { ENDPOINTS } from "services/ContentServer/Audit/constants";
import { ServiceBase } from "services/ContentServer/ServiceBase";

export class TexturingImageService extends ServiceBase<MediaCapture> {
  constructor(apiInstance: APIInstance) {
    super(MediaCapture, apiInstance, ENDPOINTS.TEXTURING_IMAGES);
  }
}
