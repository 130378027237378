import { useCallback, useContext, useMemo } from "react";
import useSWR from "swr";

import { Matrix4 } from "services/ContentServer/Audit/types";
import { RequestContext } from "utils/Contexts/Requests/RequestContext";
import { SnapshotContext } from "utils/Contexts/SnapshotContext";

export const defaultProjectionMatrixVertical = {
  m00: 2.22621,
  m01: 0,
  m02: 0.017044,
  m03: 0,
  m10: 0,
  m11: 1.6696,
  m12: 0.016806,
  m13: 0,
  m20: 0,
  m21: 0,
  m22: -1,
  m23: -0.02,
  m30: 0,
  m31: 0,
  m32: -1,
  m33: 0,
};

export const defaultProjectionMatrixHorizontal = {
  m00: 1.6696,
  m01: 0,
  m02: 0.016806,
  m03: 0,
  m10: 0,
  m11: 2.2262,
  m12: 0.017044,
  m13: 0,
  m20: 0,
  m21: 0,
  m22: -1,
  m23: -0.02,
  m30: 0,
  m31: 0,
  m32: -1,
  m33: 0,
};

export const defaultCamera = {
  projectionMatrix: defaultProjectionMatrixHorizontal,
  aspectRatio: defaultProjectionMatrixHorizontal.m11 / defaultProjectionMatrixHorizontal.m00,
  verticalCameraFOV:
    Math.atan(defaultProjectionMatrixHorizontal.m00 / defaultProjectionMatrixHorizontal.m11) * (180 / Math.PI) * 2,
  cx: defaultProjectionMatrixHorizontal.m02,
  cy: defaultProjectionMatrixHorizontal.m12,
  rotation: 0,
};

export interface DeviceCameraInfo {
  aspectRatio: number;
  verticalCameraFOV: number;
  cx: number;
  cy: number;
  projectionMatrix: Matrix4;
  rotation: number;
}
export const useDeviceCamera = (deviceIds?: string[] | null): ((rotate?: number | null) => DeviceCameraInfo) => {
  const { contentServer } = useContext(RequestContext);
  const { selectedSnapshot: snapshot } = useContext(SnapshotContext);
  const { data: allDevices } = useSWR(snapshot && snapshot.id ? ["devices", "snapshot_id", snapshot.id] : null, () =>
    snapshot && snapshot.id
      ? contentServer.deviceService.list([["captures_snapshot", snapshot.id]], ["id", "projection_matrix"])
      : undefined
  );

  const devices = useMemo(() => {
    if (allDevices && deviceIds) {
      return allDevices.filter((device) => (device.id ? deviceIds.includes(device.id) : false));
    } else {
      return [];
    }
  }, [allDevices, deviceIds]);

  const getCameraIntrinsics = useCallback(
    (rotate?: number | null, deviceId?: string): DeviceCameraInfo => {
      const device = deviceId ? devices.find((d) => d.id === deviceId) : devices.length > 0 ? devices[0] : undefined;
      let projectionMatrixVertical;
      let projectionMatrixHorizontal;
      if (device && device.projectionMatrix) {
        projectionMatrixVertical = {
          m00: device.projectionMatrix.m11,
          m01: device.projectionMatrix.m01,
          m02: device.projectionMatrix.m12,
          m03: device.projectionMatrix.m03,
          m10: device.projectionMatrix.m10,
          m11: device.projectionMatrix.m00,
          m12: device.projectionMatrix.m02,
          m13: device.projectionMatrix.m13,
          m20: device.projectionMatrix.m20,
          m21: device.projectionMatrix.m21,
          m22: device.projectionMatrix.m22,
          m23: device.projectionMatrix.m23,
          m30: device.projectionMatrix.m30,
          m31: device.projectionMatrix.m31,
          m32: device.projectionMatrix.m32,
          m33: device.projectionMatrix.m33,
        };
        projectionMatrixHorizontal = device.projectionMatrix;
      } else {
        projectionMatrixVertical = defaultProjectionMatrixVertical;
        projectionMatrixHorizontal = defaultProjectionMatrixHorizontal;
      }
      const projectionMatrix = (rotate ?? 0) % 2 === 0 ? projectionMatrixHorizontal : projectionMatrixVertical;
      const aspectRatio = projectionMatrix.m11 / projectionMatrix.m00;
      const verticalCameraFOV = Math.atan(projectionMatrix.m00 / projectionMatrix.m11) * (180 / Math.PI) * 2;
      const cx = projectionMatrix.m02;
      const cy = projectionMatrix.m12;
      const rotation = rotate || 0;
      return { aspectRatio, verticalCameraFOV, cx, cy, projectionMatrix, rotation };
    },
    [devices]
  );

  return getCameraIntrinsics;
};
