import React, { useState, useMemo } from "react";

export interface MeshToggleContextType {
  textureEnabled: boolean;
  toggleTexture: (prev: boolean | ((prev: boolean) => boolean)) => void;
}

const defaultContext: MeshToggleContextType = {
  textureEnabled: true,
  toggleTexture: (prev: boolean | ((prev: boolean) => boolean)) => {},
};

export const ProvideMeshToggleContext = (props: any) => {
  const [textureEnabled, toggleTexture] = useState(true);

  const contextValues: MeshToggleContextType = useMemo(
    () => ({ textureEnabled: textureEnabled, toggleTexture: toggleTexture }),
    [textureEnabled]
  );

  return <MeshToggleContext.Provider value={contextValues}>{props.children}</MeshToggleContext.Provider>;
};

export const MeshToggleContext = React.createContext(defaultContext);
