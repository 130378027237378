import { deserializerHelper, serializerHelper } from "services/ContentServer/APITypeBase";
import { ISerialization } from "services/ContentServer/ISerialization";

export const serverToView: { [key: string]: keyof Label | undefined } = {
  id: "id",
  name: "name",
  description: "description",
  guidance: "guidance",
  group: "group",
  created_at: "createdAt",
  state: "state",
  last_modified_by: "lastModifiedBy",
};

export class Label extends ISerialization {
  public id?: string | null;
  public name?: string | null;
  public description?: string | null;
  public guidance?: string | null;
  public group?: string | null;
  public createdAt?: string | null;
  public state?: string | null;
  public lastModifiedBy?: string | null;

  constructor(label?: Label) {
    super();
    if (label) {
      Object.assign(this, label);
    }
  }

  deserialize(json: any): void {
    deserializerHelper<Label>(this, serverToView, json);
  }

  serialize(): any {
    const request = serializerHelper<Label>(this, serverToView);
    return request;
  }
}
