import { Typography } from "@mui/material";
import React from "react";

import UserList from "./UserList";
import useUsers from "hooks/useUsers";
import { Status, User } from "services/ContentServer/Identity";

const UserStatusList = ({
  searchedUserID,
  handleSetSearchedUserID,
}: {
  searchedUserID: string;
  handleSetSearchedUserID: (value: string) => void;
}) => {
  const filterActive = (user: User) => {
    return user.status === Status.Active;
  };

  const filterInactive = (user: User) => {
    return user.status === Status.Inactive;
  };

  const { users, error } = useUsers();

  return Object.values(users).length > 0 ? (
    <>
      <UserList
        users={users}
        key={1}
        searchedUserID={searchedUserID}
        filterUser={filterActive}
        handleSetSearchedUserID={handleSetSearchedUserID}
      />
      <UserList
        users={users}
        key={2}
        searchedUserID={searchedUserID}
        filterUser={filterInactive}
        handleSetSearchedUserID={handleSetSearchedUserID}
      />
    </>
  ) : error ? (
    <Typography variant="h6" component="h6" gutterBottom style={{ textAlign: "center", paddingTop: "20px" }}>
      Error fetching users ...
    </Typography>
  ) : (
    <Typography variant="h6" component="h6" gutterBottom style={{ textAlign: "center", paddingTop: "20px" }}>
      Loading users ...
    </Typography>
  );
};

export default UserStatusList;
