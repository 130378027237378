import * as THREE from "three";

import { transformObject } from "components/CameraSelector/Common/ThreeUtils";
import {
  CAMERA_OBJECT_TO_MODEL_RATIO,
  DEFAULT_CAMERA_OBJECT_SIZE,
  MAX_CAMERA_OBJECT_SIZE,
  MIN_CAMERA_OBJECT_SIZE,
  SPATIAL_CAMERA_OBJECT_SIZE,
} from "components/CameraSelector/Constants";
import { Matrix4 } from "services/ContentServer/Audit/types";

export const getCameraObjectScale = (maxObjectWidth?: number, isPLYOrSpatial?: boolean) => {
  if (maxObjectWidth) {
    if (isPLYOrSpatial) {
      return SPATIAL_CAMERA_OBJECT_SIZE;
    }
    const cameraObjScale = CAMERA_OBJECT_TO_MODEL_RATIO * maxObjectWidth;
    return cameraObjScale > MAX_CAMERA_OBJECT_SIZE
      ? MAX_CAMERA_OBJECT_SIZE
      : cameraObjScale < MIN_CAMERA_OBJECT_SIZE
      ? MIN_CAMERA_OBJECT_SIZE
      : cameraObjScale;
  } else {
    return DEFAULT_CAMERA_OBJECT_SIZE;
  }
};

export const getCameraObjectFromMatrix = (
  pose: Matrix4,
  object: THREE.Mesh,
  isPLY?: boolean,
  maxObjectWidth?: number
) => {
  // Scale
  const cameraObjScale = getCameraObjectScale(maxObjectWidth, isPLY);
  object.scale.set(cameraObjScale, cameraObjScale, cameraObjScale);
  transformObject(object, pose);

  return object;
};
