import Typography, { TypographyProps } from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
  padded: {
    paddingTop: "1px",
    paddingBottom: "2px",
  },
});

export default function Title(props: TypographyProps) {
  const { children, ...other } = props;
  const classes = useStyles(props);
  return (
    <Typography align="center" gutterBottom className={classes.padded} {...other}>
      {children}
    </Typography>
  );
}
